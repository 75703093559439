import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AUTH, AUTHH, BASE_URL, IMAGE_URL } from '../../config/axios';
import { toast, ToastContainer } from 'react-toastify';
import { formatDate, formattedDateLong } from '../Date';
import { Modal } from 'react-bootstrap';
import Dashboardnavbar from '../../layout/Dashboardnavbar';
import { useNavigate } from 'react-router-dom';
import { formatCurrencyIndian, formatDateTime, formatRoundoff2D, handleErrorToast } from '../CustomFunctions';
import { AccountBExpenseFinalize } from './ExpenseSheet/ExpenseSlipComponents';
import usePermission from '../../config/permissions';
import { CheckGreen, WarnYellow, Eye, Filter, Office, Active, CrossRed, Attachment, Employee } from '../AllSvg';
import { Projects } from '../FilterDropdown';


const RequestImprestApproved = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            const approved_other_details = i.other_details.map((other) => ({
                other_name: other.other_name,
                amount: other.amount,
                approved_other: other.amount,
                remaining_other: other.remaining_other,
            }));
            let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, {
                authority_approval_status: "approved",
                admin_approval_status: "pending",
                approved_stationary_item: i.stationary_item,
                approved_electronic_item: i.electronic_item,
                approved_grocery: i.grocery,
                // approved_other: i.other,
                other_details: approved_other_details,
                amount_change_reason: "Confirmed",
                authority_update_datetime: new Date().toISOString(),
            });

            if (res.status === 200) {
                await getImprestname();
                // setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err, loadingToastId);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-edit-button" title="TL Imprest Approved" onClick={handleShow}>
                <CheckGreen />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Approval of Imprest "{i.imprest_name}" </h6>
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.project_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Total Expense -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {
                                        Number(i.stationary_item) +
                                        Number(i.electronic_item) +
                                        Number(i.grocery) +
                                        // Number(i.other)
                                        (i.other_details?.length > 0
                                            ? i.other_details.reduce((total, other) => total + Number(other.amount), 0)
                                            : 0)
                                    }
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="transportation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Stationary -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.stationary_item}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="accomodation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Electronics -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.electronic_item}
                                </div>

                            </div>

                            <div className="flex-row">
                                <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Grocery -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.grocery}
                                </div>
                            </div>

                            {/* <div className="flex-row">
                  <label htmlFor="other" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    {i.other_name ? `${i.other_name} Expenses - ` : 'Other Expenses - '}
  
                  </label>
                  <div className="form-labels announce-date font-size-heading">
                    {i.other}
                  </div>
                </div> */}

                            <div className="flex-column">
                                <label className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                                    Other Expenses:
                                </label>
                                {i.other_details?.length > 0 ? (
                                    <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                        {i.other_details.map((other, index) => (
                                            <li key={index} className="form-labels announce-date font-size-heading">
                                                <span className="form-text-trasformation-uppercase">
                                                    {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.amount}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <span>No Other Expense <br /></span>
                                )}
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="no_of_days"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Number's of days :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.no_of_days}
                                </div>
                            </div>


                            <div className="flex-column">
                                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Remarks :
                                </label>
                                {i.remarks}
                                <div className="form-labels announce-date font-size-heading">
                                </div>
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
};

const RequestImprestPartialApprove = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        approved_stationary_item: i.stationary_item,
        approved_electronic_item: i.electronic_item,
        approved_grocery: i.grocery,
        // approved_other: i.other,
        other_details: i.other_details.map((other) => ({
            other_name: other.other_name,
            amount: other.amount,
            approved_other: other.amount,
            remaining_other: other.remaining_other,
        })),
        amount_change_reason: "",
    });

    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["amount_change_reason"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name.startsWith('approved_')) {

            // For individual expenses like transportation, accommodation, fooding, etc.
            if (!name.startsWith('approved_other_')) {
                const itemAmountKey = name.replace('approved_', '');
                const currentValue = i[itemAmountKey] !== undefined && i[itemAmountKey] !== null ? i[itemAmountKey] : 0;
                const maxLength = getNumberLength(currentValue);

                // Ensure the input value does not exceed the allowed length
                if (value.length <= maxLength) {
                    const numericValue = value.trim() ? parseInt(value, 10) : 0;

                    // Check if the numeric value is less than or equal to the current value
                    if (numericValue <= currentValue) {
                        setInputState({
                            ...inputState,
                            [name]: value.trim() ? "success" : "",
                        });

                        setFormData({
                            ...formData,
                            [name]: value,
                        });

                        // Clear any previous error
                        setErrors({
                            ...errors,
                            [name]: "",
                        });
                    } else {
                        // Handle case where the numeric value exceeds the current value
                        setErrors({
                            ...errors,
                            [name]: `Value must be less than or equal to ${currentValue}`,
                        });
                    }
                } else {
                    // Handle case where input length exceeds the allowed length
                    setErrors({
                        ...errors,
                        [name]: `Maximum allowed length is ${maxLength} digits`,
                    });
                }

            } else {
                // For entries in approved_other_details
                const index = parseInt(name.replace('approved_other_', ''), 10);
                const currentOther = i.other_details[index];
                const currentValue = currentOther.amount !== undefined && currentOther.amount !== null ? currentOther.amount : 0;
                const maxLength = getNumberLength(currentValue);

                // Ensure the input value does not exceed the allowed length
                if (value.length <= maxLength) {
                    const numericValue = value.trim() ? parseInt(value, 10) : 0;

                    // Check if the numeric value is less than or equal to the current value
                    if (numericValue <= currentValue) {
                        const newOtherDetails = [...formData.other_details];
                        newOtherDetails[index].approved_other = value;

                        setInputState({
                            ...inputState,
                            [name]: value.trim() ? "success" : "",
                        });

                        setFormData({
                            ...formData,
                            other_details: newOtherDetails,
                        });

                        // Clear any previous error
                        setErrors({
                            ...errors,
                            [name]: "",
                        });
                    } else {
                        // Handle case where the numeric value exceeds the current value
                        setErrors({
                            ...errors,
                            [name]: `Value must be less than or equal to ${currentValue}`,
                        });
                    }
                } else {
                    // Handle case where input length exceeds the allowed length
                    setErrors({
                        ...errors,
                        [name]: `Maximum allowed length is ${maxLength} digits`,
                    });
                }
            }

        } else {
            // For textarea or other fields without length validation
            setInputState({
                ...inputState,
                [name]: value.trim() ? "success" : "",
            });

            setFormData({
                ...formData,
                [name]: value,
            });
            setErrors({
                ...errors,
                [name]: "",
            });
        }
    };

    // Helper function to get the length of a number
    function getNumberLength(value) {
        return value.toString().length;
    }
    console.log("formData")
    console.log(formData)



    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, {
                    authority_approval_status: "approved",
                    admin_approval_status: "pending",
                    amount_change_reason: formData.amount_change_reason,
                    approved_stationary_item: formData.approved_stationary_item,
                    approved_electronic_item: formData.approved_electronic_item,
                    approved_grocery: formData.approved_grocery,
                    approved_other: formData.approved_other,
                    other_details: formData.other_details,
                    authority_update_datetime: new Date().toISOString(),

                });

                if (res.status === 200) {
                    await getImprestname();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId);
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    function getNumberLength(value) {
        return value.toString().length;
    }

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-partial-button" title="TL Imprest Partial Approved" onClick={handleShow}>
                <WarnYellow />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <h1 className="task-Tab-heading align-center font-weight500 font-size-heading">Give Approvable Amount for Imprest : "{i.imprest_name}" </h1>
                            <div className="flex-column">
                                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Total Expense
                                </label>
                                <input
                                    id="total"
                                    type="number"
                                    placeholder="0"
                                    value={
                                        Number(formData.approved_stationary_item) +
                                        Number(formData.approved_electronic_item) +
                                        Number(formData.approved_grocery) +
                                        // Number(formData.approved_other)
                                        (formData.other_details?.length > 0
                                            ? formData.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                            : 0)
                                    }
                                    readOnly
                                    className="form-input   font-weight500   font-size-subheading"
                                />
                            </div>
                            <div className="flex-column">
                                <label
                                    htmlFor="approved_stationary_item"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Stationary
                                </label>
                                <input
                                    id="approved_stationary_item"
                                    type="number"
                                    name="approved_stationary_item"
                                    placeholder="0"
                                    min={0}
                                    maxLength={getNumberLength(i.stationary_item)}
                                    max={i.stationary_item}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={handleInputChange}
                                    value={formData.approved_stationary_item}
                                    className={`form-input ${errors.approved_stationary_item
                                        ? "error"
                                        : inputState.approved_stationary_item
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.approved_stationary_item && (
                                    <span className="error-message font-size-text ">{errors.approved_stationary_item}</span>
                                )}
                            </div>

                            <div className="flex-column">
                                <label
                                    htmlFor="approved_electronic_item"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Electronics
                                </label>
                                <input
                                    id="approved_electronic_item"
                                    type="number"
                                    name="approved_electronic_item"
                                    placeholder="0"
                                    min={0}
                                    max={i.electronic_item}
                                    maxLength={getNumberLength(i.electronic_item)}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={handleInputChange}
                                    value={formData.approved_electronic_item}
                                    className={`form-input ${errors.approved_electronic_item
                                        ? "error"
                                        : inputState.approved_electronic_item
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.approved_electronic_item && (
                                    <span className="error-message font-size-text ">{errors.approved_electronic_item}</span>
                                )}
                            </div>

                            <div className="flex-column">
                                <label htmlFor="approved_grocery" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Grocery
                                </label>
                                <input
                                    id="approved_grocery"
                                    type="number"
                                    name="approved_grocery"
                                    placeholder="0"
                                    min={0}
                                    max={i.grocery}
                                    maxLength={getNumberLength(i.grocery)}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={handleInputChange}
                                    value={formData.approved_grocery}
                                    className={`form-input ${errors.approved_grocery
                                        ? "error"
                                        : inputState.approved_grocery
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.approved_grocery && (
                                    <span className="error-message font-size-text ">{errors.approved_grocery}</span>
                                )}
                            </div>

                            {/* <div className="flex-column">
                                <label htmlFor="approved_other" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    {i.other_name ? `${i.other_name} Expenses` : 'Other Expenses'}
                                </label>
                                <input
                                    id="approved_other"
                                    type="number"
                                    name="approved_other"
                                    placeholder="0"
                                    min={0}
                                    max={i.other}
                                    maxLength={getNumberLength(i.other)}
                                    onChange={handleInputChange}
                                    value={formData.approved_other}
                                    className={`form-input ${errors.approved_other ? "error" : inputState.approved_other ? "success" : ""
                                        }`}
                                />
                                {errors.approved_other && (
                                    <span className="error-message font-size-text ">{errors.approved_other}</span>
                                )}
                            </div> */}

                            <div className="flex-column">
                                <label className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                                    Other Expenses:
                                </label>
                                {
                                    i.other_details?.length > 0 ? (formData.other_details?.map((other, index) => (
                                        <div key={index} className="flex-column">
                                            <label htmlFor={`approved_other_${index}`} className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                                                {other.other_name ? other.other_name : `Other-${index + 1}`} :
                                            </label>
                                            <input
                                                id={`approved_other_${index}`}
                                                type="number"
                                                name={`approved_other_${index}`}
                                                placeholder="0"
                                                min={0}
                                                max={other.amount}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={handleInputChange}
                                                value={other.approved_other}
                                                className={`form-input ${errors[`approved_other_${index}`] ? "error" : inputState[`approved_other_${index}`] ? "success" : ""}`}
                                            />
                                            {errors[`approved_other_${index}`] && <span className="error-message font-size-text">{errors[`approved_other_${index}`]}</span>}
                                        </div>
                                    )))
                                        : (<span>No Other Expense <br /></span>)
                                }
                            </div>


                            <div className="flex-column">
                                <label htmlFor="amount_change_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Amount Change Reason<span className="required">*</span>
                                </label>
                                <textarea
                                    id="amount_change_reason"
                                    type="text"
                                    name="amount_change_reason"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.amount_change_reason}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.amount_change_reason
                                        ? "error"
                                        : inputState.amount_change_reason
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.amount_change_reason && (
                                    <span className="error-message font-size-text ">{errors.amount_change_reason}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-submit    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                </Modal.Body>
            </Modal>
        </>
    );
};

const RequestImprestReject = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        rejection_reason: "",
    });

    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["rejection_reason"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, {
                    authority_approval_status: "rejected",
                    rejection_reason: formData.rejection_reason,
                });

                if (res.status === 200) {
                    await getImprestname();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" title="Authority Imprest Reject" onClick={handleShow}>
                <CrossRed />
            </button >
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                {/* <Modal.Header closeButton>
            <Modal.Title>Rejection Reason</Modal.Title>
          </Modal.Header> */}
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">

                            <div className="flex-column">
                                <label htmlFor="rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Reporting Head Rejection Reason
                                </label>
                                <textarea
                                    id="rejection_reason"
                                    type="text"
                                    name="rejection_reason"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.rejection_reason}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.rejection_reason
                                        ? "error"
                                        : inputState.rejection_reason
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.rejection_reason && (
                                    <span className="error-message font-size-text ">{errors.rejection_reason}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-delete    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                </Modal.Body>
            </Modal>
        </>
    );
};


const AdminapprovedHR = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, {
                admin_approval_status: "approved",
                admin_update_datetime: new Date().toISOString(),
                admin_update_by: sessionStorage.getItem('sitemanagement_emp_id'),
            }, AUTH);

            if (res.status === 200) {
                await getImprestname();
                // setShow(false);
            } else {
                alert(res);
            }
        } catch (err) {
            handleErrorToast(err, loadingToastId)
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-edit-button" title="Admin Imprest Approved" onClick={handleShow}>
                <CheckGreen />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Admin Approval of Imprest "{i.imprest_name}" </h6>
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.project_name}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Total Expense -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {
                                        Number(i.approved_stationary_item) +
                                        Number(i.approved_electronic_item) +
                                        Number(i.approved_grocery) +
                                        (i.other_details?.length > 0
                                            ? i.other_details.reduce((total, other) => total + Number(other.approved_other), 0)
                                            : 0)
                                    }
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="transportation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Stationary -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.stationary_item}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="accomodation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Electronics -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.electronic_item}
                                </div>

                            </div>

                            <div className="flex-row">
                                <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Grocery -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.grocery}
                                </div>
                            </div>

                            <div className="flex-column">
                                <label className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                                    Other Expenses:
                                </label>
                                {i.other_details?.length > 0 ? (
                                    <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                        {i.other_details.map((other, index) => (
                                            <li key={index} className="form-labels announce-date font-size-heading">
                                                <span className="form-text-trasformation-uppercase">
                                                    {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <span>No Other Expense <br /></span>
                                )}
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="no_of_days"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Number's of days :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.no_of_days}
                                </div>
                            </div>

                            <div className="flex-column">
                                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Remarks :
                                </label>
                                {i.remarks}
                                <div className="form-labels announce-date font-size-heading">
                                </div>
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >
        </>
    );
};

const AdminDeleteHR = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        admin_rejection_reason: "",
    });

    console.log(formData);
    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["admin_rejection_reason"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, {
                    admin_approval_status: "rejected",
                    authority_approval_status: "pending",
                    admin_rejection_reason: formData.admin_rejection_reason,
                    admin_update_datetime: new Date().toISOString(),
                    admin_update_by: sessionStorage.getItem('sitemanagement_emp_id'),
                });

                if (res.status === 200) {
                    await getImprestname();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" title="Admin Imprest Reject" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M13.5 4.5L4.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M4.5 4.5L13.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-column">
                                <label htmlFor="admin_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Admin Rejection Reason
                                </label>
                                <textarea
                                    id="admin_rejection_reason"
                                    type="text"
                                    name="admin_rejection_reason"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.admin_rejection_reason}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.admin_rejection_reason
                                        ? "error"
                                        : inputState.admin_rejection_reason
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.admin_rejection_reason && (
                                    <span className="error-message font-size-text ">{errors.admin_rejection_reason}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-delete    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};


const ImprestApprovedAccount = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [fileName, setFileName] = useState("");
    const [formData, setFormData] = useState({
        account_approval_status: "approved",
        account_approved_final_details: "",
        account_transaction_proof: null,
        account_update_datetime: new Date().toISOString(),
        account_update_by: sessionStorage.getItem('sitemanagement_emp_id'),
        imprest_open: true,
    });

    const totalApproved =
        (Number(i.approved_stationary_item) +
            Number(i.approved_electronic_item) +
            Number(i.approved_grocery) +
            // Number(i.approved_other)
            (i.other_details?.length > 0
                ? i.other_details.reduce((total, other) => total + Number(other.approved_other), 0)
                : 0));

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const validateForm = () => {
        const newErrors = {};
        const requiredFields =
            totalApproved === 0 ? [] :
                [
                    "account_approved_final_details",
                    "account_transaction_proof"
                ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            setFormData({ ...formData, [name]: file, });
            setFileName(file.name);
        } else {
            setFileName("");
        }
    };

    const handleClear = () => {
        setFormData(prevState => ({
            ...prevState,
            account_transaction_proof: null
        }));
        setFileName("");

    }

    const handleFormSubmitPut = async (e) => {

        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            const formDataa = new FormData();

            const symbols = '!@#$%^&*()_-+=';
            const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
            const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const numbers = '0123456789';
            const now = new Date();
            const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
            const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = '';
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            if (formData.account_transaction_proof && formData.account_approved_final_details) {
                const originalFile = formData?.account_transaction_proof;
                const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile?.name}`;
                const customFile = new File([originalFile], customFileName, { type: originalFile.type });

                formDataa.append("account_approved_final_details", formData.account_approved_final_details);
                formDataa.append("account_transaction_proof", customFile);
            }
            formDataa.append("account_update_datetime", formData.account_update_datetime);
            formDataa.append("account_update_by", formData.account_update_by);
            formDataa.append("account_approval_status", formData.account_approval_status);
            formDataa.append("imprest_open", formData.imprest_open);
            try {
                let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, formDataa, AUTHH, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (res.status === 200) {
                    await getImprestname();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-edit-button" title="Account Imprest Approved" onClick={handleShow}>
                <CheckGreen />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Account Approval of Imprest "{i.project_name}" </h6>
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.project_name}
                                </div>
                            </div>


                            <div className="flex-row">
                                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Total Expense -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {
                                        Number(i.approved_stationary_item) +
                                        Number(i.approved_electronic_item) +
                                        Number(i.approved_grocery) +
                                        // Number(i.approved_other)
                                        (i.other_details?.length > 0
                                            ? i.other_details.reduce((total, other) => total + Number(other.approved_other), 0)
                                            : 0)
                                    }
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="transportation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Stationary -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.stationary_item}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="accomodation"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Electronics -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.electronic_item}
                                </div>

                            </div>

                            <div className="flex-row">
                                <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Grocery -
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.grocery}
                                </div>
                            </div>


                            <div className="flex-column">
                                <label className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                                    Other Expenses:
                                </label>
                                {i.other_details?.length > 0 ? (
                                    <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                        {i.other_details.map((other, index) => (
                                            <li key={index} className="form-labels announce-date font-size-heading">
                                                <span className="form-text-trasformation-uppercase">
                                                    {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <span>No Other Expense <br /></span>
                                )}
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="no_of_days"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Number's of days :
                                </label>
                                <div className="form-labels announce-date font-size-heading">
                                    {i.no_of_days}
                                </div>
                            </div>

                            <div className="flex-column">
                                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Remarks :
                                </label>
                                {i.remarks}
                                <div className="form-labels announce-date font-size-heading">
                                </div>
                            </div>


                            {totalApproved === 0 ? null :
                                <>
                                    <div className="flex-column">
                                        <label
                                            htmlFor="account_approved_final_details"
                                            className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                        >
                                            Final Approval Details (Transaction / Cheque no.)
                                        </label>
                                        <input
                                            id="account_approved_final_details"
                                            name="account_approved_final_details"
                                            placeholder="Final Approval Details (Transaction / Cheque no.)"
                                            onChange={handleInputChange}
                                            value={formData.account_approved_final_details}
                                            className={`form-input ${errors.account_approved_final_details
                                                ? "error"
                                                : inputState.account_approved_final_details
                                                    ? "success"
                                                    : ""
                                                }`}
                                        />
                                        {errors.account_approved_final_details && (
                                            <span className="error-message font-size-text ">{errors.account_approved_final_details}</span>
                                        )}
                                    </div>

                                    <div className="flex-row">
                                        <label htmlFor="" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                            Account Transaction Proof :
                                        </label>
                                        <div className="flex-column" >
                                            <label
                                                htmlFor="account_transaction_proof"
                                                className={`align-center  form-inputss custom-file-upload 
                                                    ${errors.account_transaction_proof
                                                        ? "error"
                                                        : inputState.account_transaction_proof
                                                            ? "success"
                                                            : ""
                                                    }`}
                                            >
                                                <div className="svg-field form-input align-center">
                                                    <Attachment />
                                                </div>
                                            </label>
                                            <input
                                                id="account_transaction_proof"
                                                name="account_transaction_proof"
                                                type="file"
                                                onChange={handleFileChange}
                                                onFocus={handleClear}
                                                className="file-inputs align-center"
                                                style={{ display: "none", position: "relative" }}
                                            />
                                            <br />
                                            <div className="flex-row justify-evenly">
                                                <div>
                                                    {fileName && <p style={{ margin: "0px" }}>{fileName}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        {formData.account_transaction_proof ?
                                            <div>
                                                <button className="model-button" onClick={handleClear}>Clear</button>
                                            </div>
                                            : null
                                        }
                                        {errors.account_transaction_proof && (
                                            <span className="error-message font-size-text ">{errors.account_transaction_proof}</span>
                                        )}
                                    </div>
                                </>
                            }
                            <br />

                            <div className="button-models">
                                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >
        </>
    );
};

const ImprestDeleteAccount = ({ i, getImprestname }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        account_rejection_reason: "",
    });

    console.log(formData);
    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["account_rejection_reason"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/imprest/${i.id}/`, {
                    account_approval_status: "rejected",
                    account_rejection_reason: formData.account_rejection_reason,
                });

                if (res.status === 200) {
                    await getImprestname();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" title="Accounts Imprest Reject" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M13.5 4.5L4.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M4.5 4.5L13.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                {/* <Modal.Header closeButton>
            <Modal.Title>Rejection Reason</Modal.Title>
          </Modal.Header> */}
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">

                            <div className="flex-column">
                                <label htmlFor="account_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Account Rejection Reason
                                </label>
                                <textarea
                                    id="account_rejection_reason"
                                    type="text"
                                    name="account_rejection_reason"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.account_rejection_reason}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.account_rejection_reason
                                        ? "error"
                                        : inputState.account_rejection_reason
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.account_rejection_reason && (
                                    <span className="error-message font-size-text ">{errors.account_rejection_reason}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-delete    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                </Modal.Body>
            </Modal>
        </>
    );
};


const RequestImpresttable = () => {
    const [checkedItems, setCheckedItems] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState("pending");

    const [selectedProject, setProject] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const getimprestname = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/imprestbyAuthority/${sessionStorage.getItem(
                    "sitemanagement_emp_id"
                )}/${selectedStatus}/${fromdate}/${todate}/${selectedProject}/`
            );
            setOutdutyName(res.data);
            console.log(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getimprestname();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    // *********** Employee Details Api End ***********


    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">

                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">

                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">

                            {/* <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedProject}
                                onChange={(e) => setProject(e.target.value)}
                            >
                                <option value="null">Select Employee Code </option>
                                {allemployee
                                    .sort((a, b) => a.emp_code - b.emp_code)
                                    .map((i, index) => (
                                        <option value={i.id}>
                                            {i.emp_code} - {i.name}
                                        </option>
                                    ))}
                                ;
                            </select> */}
                            <Projects toggleProject={selectedProject} setToggleProject={setProject} />

                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background scroll-container-table">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Imprest </div>
                        {/* <div className="flex-row">
                <button classname="accept  font-weight300font-size-text " onClick={handleFormSubmitApprovePut}>Accept</button>
                <button classname="accept-rej   font-weight300 font-size-text " onClick={handleFormSubmitRejectedPut}>Rejected</button>
              </div> */}
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-lefttt">S. No.</th>
                                <th className="table-heading-text" style={{ textAlign: "center" }}>Project</th>
                                <th className="table-heading-text">Imprest Date</th>
                                <th className="table-heading-text">Dates</th>
                                <th className="table-heading-text">Imprest Name</th>
                                <th className="table-heading-text" style={{ textAlign: "center" }}>Total Amount</th>
                                <th className="table-heading-text">Days</th>
                                <th className="table-heading-text">Remarks</th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text">Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="6"></td>
                                        </tr>
                                        <tr className="custom-table-head-td" title={`Team Leader Status : ${i.authority_approval_status}`}>
                                            <td className="table-body">{index + 1}</td>
                                            <td className="table-body"> {i.project_code}/{i.project_short_name}</td>
                                            <td className="table-body">{formatDate(i.date)}</td>
                                            <td title="Approval Dates" className="table-body">
                                                <div>
                                                    {i.authority_approval_status === "approved" ?
                                                        <span
                                                            request-status={i.authority_approval_status}
                                                            className="request-status "
                                                        >
                                                            Authority Approved
                                                            {i.authority_update_datetime ? <><br />At: {formatDateTime(i.authority_update_datetime).date}</> : ''}
                                                        </span>
                                                        : ""}
                                                    <br />
                                                    {i.admin_approval_status === "approved" ?
                                                        <span
                                                            request-status={i.admin_approval_status}
                                                            className="request-status "
                                                        >
                                                            Admin{i.admin_update_by ? `(${i.admin_update_by})` : ''} Approved
                                                            {i.admin_update_datetime ? <><br />At: {formatDateTime(i.admin_update_datetime).date}</> : ''}
                                                        </span>
                                                        : ""}
                                                    <br />
                                                    {i.account_approval_status === "approved" ?
                                                        <span
                                                            request-status={i.account_approval_status}
                                                            className="request-status "
                                                        >
                                                            Accounts{i.account_update_by ? `(${i.account_update_by})` : ''} Approved
                                                            {i.account_update_datetime ? <><br />At: {formatDateTime(i.account_update_datetime).date}</> : ''}
                                                        </span>
                                                        : ""}
                                                </div>
                                            </td>

                                            <td className="table-body">{i.imprest_name}</td>

                                            {i.authority_approval_status === "approved" ?
                                                <td
                                                    className="align-leftt"
                                                    title={`Reason For Change : \n${i.amount_change_reason}`}
                                                >
                                                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Stationary</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    {formatCurrencyIndian(i.approved_stationary_item || 0)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Electronics</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    {formatCurrencyIndian(i.approved_electronic_item || 0)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Grocery</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    {formatCurrencyIndian(i.approved_grocery || 0)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <b>
                                                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                                                </b>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end table-body" style={{ paddingBottom: "5px" }}>
                                                                    <b>
                                                                        {formatCurrencyIndian(
                                                                            i.other_details?.reduce(
                                                                                (sum, other) =>
                                                                                    sum + (parseFloat(other.approved_other) || 0),
                                                                                0
                                                                            )
                                                                        )}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            {i.other_details?.length > 0 ? (
                                                                <>
                                                                    {i.other_details.map((other, index) => (
                                                                        <tr key={index}>
                                                                            <td className="table-body" style={{ paddingLeft: '5px', paddingBottom: "5px" }}>
                                                                                {other.other_name
                                                                                    ? other.other_name
                                                                                    : `Other-${index + 1}`}
                                                                            </td>
                                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                                            <td className="text-end" style={{ paddingBottom: "5px" }}>
                                                                                {formatCurrencyIndian(other.approved_other || 0)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <tr>
                                                                    <td style={{ paddingBottom: "5px", paddingLeft: "5px" }} colSpan={3}>No Other Amount</td>
                                                                </tr>
                                                            )}
                                                            <tr>
                                                                <td>
                                                                    <b>Total</b>
                                                                </td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    <b>
                                                                        {formatCurrencyIndian(
                                                                            Number(i.approved_stationary_item) +
                                                                            Number(i.approved_electronic_item) +
                                                                            Number(i.approved_grocery) +
                                                                            (i.other_details?.length > 0
                                                                                ? i.other_details.reduce(
                                                                                    (total, other) =>
                                                                                        total + Number(other.approved_other || 0),
                                                                                    0
                                                                                )
                                                                                : 0)
                                                                        )}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                :
                                                <td className="table-body">
                                                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Stationary</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">{formatCurrencyIndian(i.stationary_item)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Electronics</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">{formatCurrencyIndian(i.electronic_item)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Grocery</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">{formatCurrencyIndian(i.grocery)}</td>
                                                            </tr>
                                                            <tr>
                                                                <b>
                                                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                                                                </b>
                                                                <td style={{ padding: "0px 4px" }}>
                                                                    :
                                                                </td>
                                                                <td className="text-end">
                                                                    <b>
                                                                        {i.other_details
                                                                            .reduce((sum, other) => sum + (parseFloat(other.amount) || 0), 0)
                                                                            .toLocaleString()}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            {i.other_details?.length > 0 ? (
                                                                <>
                                                                    {i.other_details.map((other, index) => (
                                                                        <>
                                                                            <tr>
                                                                                <td style={{ paddingLeft: "5px", paddingBottom: "5px" }} className="table-body" >
                                                                                    {other.other_name ? other.other_name : `Other-${index + 1}`}
                                                                                </td>
                                                                                <td style={{ padding: "0px 4px" }}>:</td>

                                                                                <td className="text-end">
                                                                                    {formatCurrencyIndian(other.amount || 0)}
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <td style={{ paddingBottom: "5px", paddingLeft: "5px" }}>
                                                                    No Other Amount
                                                                </td>
                                                            )}
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }} >
                                                                    <b>Total </b>
                                                                </td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    <b>
                                                                        {formatCurrencyIndian(
                                                                            Number(i.stationary_item) +
                                                                            Number(i.electronic_item) +
                                                                            Number(i.grocery) +
                                                                            (i.other_details?.length > 0
                                                                                ? i.other_details.reduce((total, other) => total + Number(other.amount || 0), 0)
                                                                                : 0)
                                                                        )}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>}

                                            <td className="table-body"> <b>{i.no_of_days}</b> </td>
                                            <td className="table-body">{i.remarks}</td>
                                            <td title='Status' className="table-body">
                                                <span
                                                    request-status={i.authority_approval_status}
                                                    className="request-status align-center"
                                                    title={`${i.authority_approval_status === "rejected" ? `TL Reason For Rejection : \n${i.rejection_reason}` : ''}`}
                                                >
                                                    {i.authority_approval_status === "pending"
                                                        ? "Pending For Authority Approval ⌛"
                                                        : i.authority_approval_status === "rejected"
                                                            ? <>Authority ✖,<br />Authority Reject Reason:<br />"{i.rejection_reason}"</>
                                                            : "Authority ✔,"}
                                                </span>
                                                {/* <br /> */}
                                                <br />
                                                <span
                                                    request-status={i.admin_approval_status}
                                                    className="request-status align-center"
                                                    title={`${i.admin_approval_status === "rejected" ? `Admin Reason: ${i.admin_rejection_reason}` : ''}`}
                                                >
                                                    {i.admin_approval_status === "rejected"
                                                        ? <>Admin ✖,<br />Admin Reject Reason:<br />"{i.admin_rejection_reason}"</>
                                                        :
                                                        i.admin_approval_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            :
                                                            i.admin_approval_status === "approved"
                                                                ? "Admin ✔,"
                                                                : "Admin Status: " + i.admin_approval_status}
                                                </span>
                                                {/* <br /> */}
                                                <br />
                                                <span
                                                    request-status={i.account_approval_status}
                                                    className="request-status align-center"
                                                    title={`${i.account_approval_status === "rejected" ? `Account Reason: ${i.account_rejection_reason}` : ''}`}
                                                >
                                                    {i.account_approval_status === "rejected"
                                                        ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.account_rejection_reason}"</>
                                                        : i.account_approval_status === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            :
                                                            i.account_approval_status === "approved"
                                                                ? "Accounts ✔,"
                                                                : "Accounts Status : " + i.account_approval_status}
                                                </span>
                                            </td>
                                            <td className="table-body" style={{ display: 'flex' }}>
                                                {i.authority_approval_status === 'approved' ? null :
                                                    <div>
                                                        <RequestImprestApproved
                                                            i={i}
                                                            getImprestname={getimprestname}
                                                        />
                                                        <RequestImprestPartialApprove
                                                            i={i}
                                                            getImprestname={getimprestname}
                                                        />
                                                    </div>}
                                                {i.authority_approval_status === 'rejected' ? null :
                                                    <div>
                                                        <RequestImprestReject
                                                            i={i}
                                                            getImprestname={getimprestname}
                                                        />
                                                    </div>}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div >
        </>
    );
};

const RequestExpensetable = () => {
    const [checkedItems, setCheckedItems] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const getexpensename = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                // `${BASE_URL}/siteproject/expensebyrh/${sessionStorage.getItem("sitemanagement_emp_id")}/${selectedStatus}/${fromdate}/${todate}/${selectedProject}/`
                `${BASE_URL}/siteproject/expensebyAuthority/${sessionStorage.getItem("sitemanagement_emp_id")}/${selectedStatus}/${fromdate}/${todate}/${selectedProject}/`
            );
            setOutdutyName(res.data);
            console.log(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getexpensename();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    // *********** Employee Details Api End ***********

    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const baseUrl = "/" + urlParts[3] + `/requestExpenseSlip/`;
    const opener = "tl"
    const openExenseSlip = (i) => {
        navigate(baseUrl + i.id + '/', { state: { i, opener } });
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value === "" ? "null" : e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">

                            <div className="  date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>
                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>


                        <div className="field-cont-div">
                            <Projects toggleProject={selectedProject} setToggleProject={setProject} />
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background scroll-container-table">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500 font-size-heading">Expense </div>
                        {/* <div className="flex-row">
                <button classname="accept  font-weight300font-size-text " onClick={handleFormSubmitApprovePut}>Accept</button>
                <button classname="accept-rej   font-weight300 font-size-text " onClick={handleFormSubmitRejectedPut}>Rejected</button>
              </div> */}
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text">S. No.</th>
                                <th className="table-heading-text">Date</th>
                                <th className="table-heading-text">Expense <b>Against</b> Imprest</th>
                                <th className="table-heading-text" style={{ textAlign: "center" }}>Imprest Amount</th>
                                <th className="table-heading-text">Utilized Money</th>
                                <th className="table-heading-text">Remaining Money</th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text">Remarks</th>
                                <th className="table-heading-text">View/Action</th>
                                {/* <th className="text-end  ">Action</th> */}
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="6"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="table-body">{index + 1}</td>

                                            <td className="table-body">{formatDate(i.date)}</td>
                                            <td className="table-body"><span>{i.name}</span>
                                                <br />
                                                <b> Against </b>
                                                <br />
                                                <span>{i.project_name}</span></td>
                                            <td
                                                className=""
                                                title={`Reason For Change : \n${i.amount_change_reason}`}
                                            >
                                                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Stationary</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end">
                                                                {formatCurrencyIndian(i.approved_stationary_item || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Electronics</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end">
                                                                {formatCurrencyIndian(i.approved_electronic_item || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Grocery</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end">
                                                                {formatCurrencyIndian(i.approved_grocery || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <b>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                                            </b>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end table-body" style={{ paddingBottom: "5px" }}>
                                                                <b>
                                                                    {formatCurrencyIndian(
                                                                        i.other_details?.reduce(
                                                                            (sum, other) =>
                                                                                sum + (parseFloat(other.approved_other) || 0),
                                                                            0
                                                                        )
                                                                    )}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        {i.other_details?.length > 0 ? (
                                                            <>
                                                                {i.other_details.map((other, index) => (
                                                                    <tr key={index}>
                                                                        <td className="table-body" style={{ paddingLeft: '5px', paddingBottom: "5px" }}>
                                                                            {other.other_name
                                                                                ? other.other_name
                                                                                : `Other-${index + 1}`}
                                                                        </td>
                                                                        <td style={{ padding: "0px 4px" }}>:</td>
                                                                        <td className="text-end" style={{ paddingBottom: "5px" }}>
                                                                            {formatCurrencyIndian(other.approved_other || 0)}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <tr>
                                                                <td style={{ paddingBottom: "5px", paddingLeft: "5px" }} colSpan={3}>No Other Amount</td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td>
                                                                <b>Total</b>
                                                            </td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end">
                                                                <b>
                                                                    {formatCurrencyIndian(
                                                                        Number(i.approved_stationary_item) +
                                                                        Number(i.approved_electronic_item) +
                                                                        Number(i.approved_grocery) +
                                                                        (i.other_details?.length > 0
                                                                            ? i.other_details.reduce(
                                                                                (total, other) =>
                                                                                    total + Number(other.approved_other || 0),
                                                                                0
                                                                            )
                                                                            : 0)
                                                                    )}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td className="table-body align-center">{i.utilized_amount ? formatRoundoff2D(i.utilized_amount) : '-'} Rs</td>
                                            <td className="table-body align-center">{i.remaining_amount ? formatRoundoff2D(i.remaining_amount) : '-'} Rs</td>
                                            <td className="table-body ">
                                                <span
                                                    request-status={i.expense_authority_approval_status}
                                                    className="request-status "
                                                    title={i.expense_authority_approval_status === "rejected" ? `Authority Reject Reason : ${i.authority_rejection_reason}` : ""}
                                                >
                                                    {i.expense_authority_approval_status === "pending"
                                                        ? "Pending For Authority Approval ⌛"
                                                        : i.expense_authority_approval_status === "rejected"
                                                            ? "Authority ✖, "
                                                            : "Authority ✔, "}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status "
                                                    title={i.account_status_a === "rejected" ? `Account Reject Reason : ${i.account1_rejection_reason}` : ""}
                                                >
                                                    {i.expense_authority_approval_status === "rejected" &&
                                                        i.expense_admin_approval_status === "rejected" &&
                                                        i.account_status_a === "rejected"
                                                        ? "Account ✖, "
                                                        : i.expense_authority_approval_status === "approved" &&
                                                            i.expense_admin_approval_status === "approved" &&
                                                            i.account_status_a === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.expense_authority_approval_status === "approved" &&
                                                                i.expense_admin_approval_status === "approved" &&
                                                                i.account_status_a === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.expense_authority_approval_status === "approved" &&
                                                                    i.expense_admin_approval_status === "approved" &&
                                                                    i.account_status_a === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 1: " + i.account_status_a}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.expense_admin_approval_status}
                                                    className="request-status "
                                                    title={i.expense_admin_approval_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.expense_authority_approval_status === "rejected" &&
                                                        i.expense_admin_approval_status === "rejected"
                                                        ? "Admin ✖, "
                                                        : i.expense_authority_approval_status === "approved" &&
                                                            i.expense_admin_approval_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            : i.expense_authority_approval_status === "approved" &&
                                                                i.expense_admin_approval_status === "rejected"
                                                                ? "Admin ✖, "
                                                                : i.expense_authority_approval_status === "approved" &&
                                                                    i.expense_admin_approval_status === "approved"
                                                                    ? "Admin ✔, "
                                                                    : "Admin Status: " + i.expense_admin_approval_status}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_b}
                                                    className="request-status"
                                                >
                                                    {i.expense_authority_approval_status === "rejected" &&
                                                        i.expense_admin_approval_status === "rejected" &&
                                                        i.account_status_b === "rejected"
                                                        ? "Account ✖, "
                                                        : i.expense_authority_approval_status === "approved" &&
                                                            i.expense_admin_approval_status === "approved" &&
                                                            i.account_status_b === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.expense_authority_approval_status === "approved" &&
                                                                i.expense_admin_approval_status === "approved" &&
                                                                i.account_status_b === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.expense_authority_approval_status === "approved" &&
                                                                    i.expense_admin_approval_status === "approved" &&
                                                                    i.account_status_b === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 2: " + i.account_status_b}
                                                </span>
                                            </td>

                                            <td title="Remarks and Approval Dates" className="table-body">
                                                <span
                                                    request-status={i.expense_authority_approval_status}
                                                    className="request-status "
                                                >
                                                    {i.expense_authority_approval_status === "pending"
                                                        ? "-"
                                                        : i.expense_authority_approval_status === "rejected"
                                                            ? `Authority Reject Reason : ${i.authority_rejection_reason}`
                                                            : `Authority${i.authority_engineer ? `(${i.authority_engineer}) ` : ''}: Verified Amounts for Bills ${i.authority_update_datetime ? `At: ${formatDateTime(i.authority_update_datetime).date}` : ''}`}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status "
                                                >
                                                    {i.account_status_a === "pending"
                                                        ? "-"
                                                        : i.account_status_a === "rejected"
                                                            ? `Accounts Reject Reason : ${i.account1_rejection_reason}`
                                                            : `Accounts${i.account_update_by ? `(${i.account_update_by}) ` : ''}: Bills Verified ${i.account_update_datetime ? `At: ${formatDateTime(i.account_update_datetime).date}` : ''}`}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.expense_admin_approval_status}
                                                    className="request-status "
                                                    title={i.expense_admin_approval_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.expense_admin_approval_status === "pending"
                                                        ? "-"
                                                        : i.expense_admin_approval_status === "rejected"
                                                            ? `Admin Rejection Reason : ${i.admin_rejection_reason}`
                                                            : `Admin${i.admin_update_by ? `(${i.admin_update_by}) ` : ''}: Approved After Final Verification  ${i.admin_update_datetime ? `At: ${formatDateTime(i.admin_update_datetime).date}` : ''}`}
                                                </span>
                                                {/* <br />
                                                <span
                                                request-status={i.account_status_b}
                                                className="request-status "
                                                >
                                                {i.account_status_b === "pending"
                                                    ? "-"
                                                    : i.account_status_b === "rejected"
                                                    ? `Final Rejection Reason : ${i.account2_rejection_reason}`
                                                    : `Final${i.final_update_by ? `(${i.final_update_by}) ` : ''}: Approved And Settled ${i.final_update_datetime ? `At: ${formatDateTime(i.final_update_datetime).date}` : ''}`}
                                                </span> */}
                                            </td>

                                            <td className="align-center">
                                                <button
                                                    className="allproject-dashboard"
                                                    title="View using Navigate"
                                                    onClick={(e) => openExenseSlip(i)}
                                                >
                                                    <Eye />
                                                </button>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};


const RequestImpresttableAdmin = () => {
    // *********** Employee Details Api Start ***********

    const [buffer, setBuffering] = useState(true); //buffering logic

    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("sitemanagement_project_sub_company") || null}`
    );


    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getimprestname = async () => {
        try {
            setBuffering(true); //buffering logic // Start Buffering
            const res = await axios.get(
                `${BASE_URL}/siteproject/imprestbyadmin/${selectedStatus}/${fromdate}/${todate}/${selectedProject}/${selectedDivision}/`
            );
            setOutdutyName(res.data);
            console.log(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getSubCompany();
        getimprestname();
    }, [fromdate, todate, selectedStatus, selectedProject, selectedDivision]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    //! *********** Employee Details Api End ***********

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        {/* // <div className="field-cont-div">
                        //  <Employee />

                        //     <select
                        //         className="attendance-input-field width-10vw   date-field"
                        //         type="text"
                        //         value={employeeGet}
                        //         onChange={(e) => setEmployeeGet(e.target.value)}
                        //     >
                        //         <option value="null">Select Employee Code </option>
                        //         {allemployee
                        //             .sort((a, b) => a.emp_code - b.emp_code)
                        //             .map((i, index) => (
                        //                 <option value={i.emp_code}>
                        //                     {i.emp_code} - {i.name}
                        //                 </option>
                        //             ))}
                        //         ;
                        //     </select>
                        //     <hr className="field-cont-hr" />
                        // </div> */}

                        <div className="field-cont-div">
                            <Office />

                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedDivision}
                                onChange={(e) => setDivision(e.target.value)}
                            >
                                <option value="null"> All Sub Companies</option>
                                {SubCompany.sort((a, b) => a.title - b.title).map(
                                    (i, index) => (
                                        <option value={i.id}>{i.title}</option>
                                    )
                                )}
                                ;
                            </select>

                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <Projects toggleProject={selectedProject} setToggleProject={setProject} subcompany={selectedDivision} />
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Imprest </div>
                        {/* <div className="flex-row">
                <button classname="accept  font-weight300font-size-text " onClick={handleFormSubmitApprovePut}>Accept</button>
                <button classname="accept-rej   font-weight300 font-size-text " onClick={handleFormSubmitRejectedPut}>Rejected</button>
              </div> */}
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-lefttt">S. No.</th>
                                <th className="table-heading-text" style={{ textAlign: "center" }}>Project</th>
                                <th className="table-heading-text">Imprest Date</th>
                                <th className="table-heading-text">Dates</th>
                                <th className="table-heading-text">Imprest Name</th>
                                <th className="table-heading-text" style={{ textAlign: "center" }}>Total Amount</th>
                                <th className="table-heading-text">Days</th>
                                <th className="table-heading-text">Remarks</th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text text-end">Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>

                                        <tr className="custom-table-head-td" title={`Team Leader Status : ${i.authority_approval_status}`}>
                                            <td className="table-body text-lefttt">{index + 1}</td>
                                            <td className="table-body"> {i.project_code}/{i.project_short_name}</td>
                                            <td className="table-body">{formatDate(i.date)}</td>
                                            <td title="Approval Dates" className="table-body">
                                                <div>
                                                    {i.authority_approval_status === "approved" ?
                                                        <span
                                                            request-status={i.authority_approval_status}
                                                            className="request-status "
                                                        >
                                                            Authority Approved
                                                            {i.authority_update_datetime ? <><br />At: {formatDateTime(i.authority_update_datetime).date}</> : ''}
                                                        </span>
                                                        : ""}
                                                    <br />
                                                    {i.admin_approval_status === "approved" ?
                                                        <span
                                                            request-status={i.admin_approval_status}
                                                            className="request-status "
                                                        >
                                                            Admin{i.admin_update_by ? `(${i.admin_update_by})` : ''} Approved
                                                            {i.admin_update_datetime ? <><br />At: {formatDateTime(i.admin_update_datetime).date}</> : ''}
                                                        </span>
                                                        : ""}
                                                    <br />
                                                    {i.account_approval_status === "approved" ?
                                                        <span
                                                            request-status={i.account_approval_status}
                                                            className="request-status "
                                                        >
                                                            Accounts{i.account_update_by ? `(${i.account_update_by})` : ''} Approved
                                                            {i.account_update_datetime ? <><br />At: {formatDateTime(i.account_update_datetime).date}</> : ''}
                                                        </span>
                                                        : ""}
                                                </div>
                                            </td>

                                            <td className="table-body">{i.imprest_name}</td>

                                            {i.authority_approval_status === "approved" ?
                                                <td
                                                    className="table-body"
                                                    title={`Reason For Change : \n${i.amount_change_reason}`}
                                                >
                                                    <table className="table-css">
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Stationary</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    {formatCurrencyIndian(i.approved_stationary_item || 0)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Electronics</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    {formatCurrencyIndian(i.approved_electronic_item || 0)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Grocery</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    {formatCurrencyIndian(i.approved_grocery || 0)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <b>
                                                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                                                </b>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end table-body" style={{ paddingBottom: "5px" }}>
                                                                    <b>
                                                                        {formatCurrencyIndian(
                                                                            i.other_details?.reduce(
                                                                                (sum, other) =>
                                                                                    sum + (parseFloat(other.approved_other) || 0),
                                                                                0
                                                                            )
                                                                        )}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            {i.other_details?.length > 0 ? (
                                                                <>
                                                                    {i.other_details.map((other, index) => (
                                                                        <tr key={index}>
                                                                            <td className="table-body" style={{ paddingLeft: '5px', paddingBottom: "5px" }}>
                                                                                {other.other_name
                                                                                    ? other.other_name
                                                                                    : `Other-${index + 1}`}
                                                                            </td>
                                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                                            <td className="text-end" style={{ paddingBottom: "5px" }}>
                                                                                {formatCurrencyIndian(other.approved_other || 0)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <tr>
                                                                    <td style={{ paddingBottom: "5px", paddingLeft: "5px" }} colSpan={3}>No Other Amount</td>
                                                                </tr>
                                                            )}
                                                            <tr>
                                                                <td>
                                                                    <b>Total</b>
                                                                </td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    <b>
                                                                        {formatCurrencyIndian(
                                                                            Number(i.approved_stationary_item) +
                                                                            Number(i.approved_electronic_item) +
                                                                            Number(i.approved_grocery) +
                                                                            (i.other_details?.length > 0
                                                                                ? i.other_details.reduce(
                                                                                    (total, other) =>
                                                                                        total + Number(other.approved_other || 0),
                                                                                    0
                                                                                )
                                                                                : 0)
                                                                        )}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                :
                                                <td className="table-body">
                                                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Stationary</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">{formatCurrencyIndian(i.stationary_item)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Electronics</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">{formatCurrencyIndian(i.electronic_item)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Grocery</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">{formatCurrencyIndian(i.grocery)}</td>
                                                            </tr>
                                                            <tr>
                                                                <b>
                                                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                                                                </b>
                                                                <td style={{ padding: "0px 4px" }}>
                                                                    :
                                                                </td>
                                                                <td className="text-end">
                                                                    <b>
                                                                        {i.other_details
                                                                            .reduce((sum, other) => sum + (parseFloat(other.amount) || 0), 0)
                                                                            .toLocaleString()}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            {i.other_details?.length > 0 ? (
                                                                <>
                                                                    {i.other_details.map((other, index) => (
                                                                        <>
                                                                            <tr>
                                                                                <td style={{ paddingLeft: "5px", paddingBottom: "5px" }} className="table-body" >
                                                                                    {other.other_name ? other.other_name : `Other-${index + 1}`}
                                                                                </td>
                                                                                <td style={{ padding: "0px 4px" }}>:</td>

                                                                                <td className="text-end">
                                                                                    {formatCurrencyIndian(other.amount || 0)}
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <td style={{ paddingBottom: "5px", paddingLeft: "5px" }}>
                                                                    No Other Amount
                                                                </td>
                                                            )}
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }} >
                                                                    <b>Total </b>
                                                                </td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    <b>
                                                                        {formatCurrencyIndian(
                                                                            Number(i.stationary_item) +
                                                                            Number(i.electronic_item) +
                                                                            Number(i.grocery) +
                                                                            (i.other_details?.length > 0
                                                                                ? i.other_details.reduce((total, other) => total + Number(other.amount || 0), 0)
                                                                                : 0)
                                                                        )}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>}

                                            <td className="table-body"> <b>{i.no_of_days}</b> </td>
                                            <td className="table-body">{i.remarks}</td>
                                            <td title='Status' className="table-body">
                                                <span
                                                    request-status={i.authority_approval_status}
                                                    className="request-status align-center"
                                                    title={`${i.authority_approval_status === "rejected" ? `TL Reason For Rejection : \n${i.rejection_reason}` : ''}`}
                                                >
                                                    {i.authority_approval_status === "pending"
                                                        ? "Pending For Authority Approval ⌛"
                                                        : i.authority_approval_status === "rejected"
                                                            ? <>Authority ✖,<br />Authority Reject Reason:<br />"{i.rejection_reason}"</>
                                                            : "Authority ✔,"}
                                                </span>
                                                {/* <br /> */}
                                                <br />
                                                <span
                                                    request-status={i.admin_approval_status}
                                                    className="request-status align-center"
                                                    title={`${i.admin_approval_status === "rejected" ? `Admin Reason: ${i.admin_rejection_reason}` : ''}`}
                                                >
                                                    {i.admin_approval_status === "rejected"
                                                        ? <>Admin ✖,<br />Admin Reject Reason:<br />"{i.admin_rejection_reason}"</>
                                                        :
                                                        i.admin_approval_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            :
                                                            i.admin_approval_status === "approved"
                                                                ? "Admin ✔,"
                                                                : "Admin Status: " + i.admin_approval_status}
                                                </span>
                                                {/* <br /> */}
                                                <br />
                                                <span
                                                    request-status={i.account_approval_status}
                                                    className="request-status align-center"
                                                    title={`${i.account_approval_status === "rejected" ? `Account Reason: ${i.account_rejection_reason}` : ''}`}
                                                >
                                                    {i.account_approval_status === "rejected"
                                                        ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.account_rejection_reason}"</>
                                                        : i.account_approval_status === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            :
                                                            i.account_approval_status === "approved"
                                                                ? "Accounts ✔,"
                                                                : "Accounts Status : " + i.account_approval_status}
                                                </span>
                                            </td>
                                            <td className="table-body">
                                                <div className="flex-row">
                                                    <>
                                                        {sessionStorage.getItem("role") === "Admin" ? (
                                                            i.admin_approval_status === "approved" ? (
                                                                <></>
                                                            ) : (
                                                                <AdminapprovedHR
                                                                    i={i}
                                                                    getImprestname={getimprestname}
                                                                />
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                    {/* <RequestOutdutyapproved i={i} getimprestname={getimprestname} /> */}
                                                    <>
                                                        {sessionStorage.getItem("role") === "Admin" ? (
                                                            i.admin_approval_status === "rejected" ? (
                                                                <></>
                                                            ) : (
                                                                <AdminDeleteHR
                                                                    i={i}
                                                                    getImprestname={getimprestname}
                                                                />
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};

const RequestExpensetableAdmin = () => {
    const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");
    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("sitemanagement_project_sub_company") || null}`
    );

    const [active, setActive] = useState("active");

    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getexpansename = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            // const res = await axios.get(`${BASE_URL}/siteproject/expense/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`);
            const res = await axios.get(
                `${BASE_URL}/siteproject/expensebyadmin/${selectedStatus}/${fromdate}/${todate}/${selectedProject}/${selectedDivision}/`
            );
            setOutdutyName(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getexpansename();
        getSubCompany();
    }, [fromdate, todate, selectedStatus, selectedProject, selectedDivision]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    //! *********** Employee Details Api End ***********

    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const baseUrl = "/" + urlParts[3] + `/requestExpenseSlip/`;
    const opener = "admin"
    const openExenseSlip = (i) => {
        navigate(baseUrl + i.id + '/', { state: { i, opener } });
    };
    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value === "" ? "null" : e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Office />
                            <select
                                className="attendance-input-field width-10vw date-field"
                                type="text"
                                value={selectedDivision}
                                onChange={(e) => setDivision(e.target.value)}
                            >
                                <option value="null">All Sub Companies</option>
                                {SubCompany.sort((a, b) => a.title - b.title).map(
                                    (i, index) => (
                                        <option value={i.id}>{i.title}</option>
                                    )
                                )}
                                ;
                            </select>

                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Projects toggleProject={selectedProject} setToggleProject={setProject} subcompany={selectedDivision} />
                        </div>


                        <div className="field-cont-div">
                            <Active />
                            <select
                                className="attendance-input-field width-10vw  "
                                type="text"
                                value={active}
                                onChange={(e) => setActive(e.target.value)}
                            >
                                <option value="null">All</option>
                                <option value="active">Active</option>
                                <option value="inactive">In Active</option>
                            </select>
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Expense</div>
                        <div className="flex-row">
                        </div>
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-lefttt">S. No.</th>
                                <th className="table-heading-text">Date</th>
                                <th className="table-heading-text">Expense <b>Against</b> Imprest</th>
                                <th className="table-heading-text text-center">Imprest Amount</th>
                                <th className="table-heading-text">Utilized Money</th>
                                <th className="table-heading-text">Remaining Money</th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text">Remarks</th>
                                <th className="table-heading-text text-end">View/Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>

                                        <tr className="custom-table-head-td">
                                            <td className="table-body text-lefttt">{index + 1}</td>

                                            <td className="table-body">{formatDate(i.date)}</td>
                                            <td className="table-body"><span>{i.name}</span>
                                                <br />
                                                <b> Against </b>
                                                <br />
                                                <span>{i.project_name}</span></td>
                                            <td
                                                className="table-body"
                                                title={`Reason For Change : \n${i.amount_change_reason}`}
                                            >
                                                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Stationary</td>
                                                            <td className="table-body">:</td>
                                                            <td className="table-body text-end">
                                                                {formatCurrencyIndian(i.approved_stationary_item || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Electronics</td>
                                                            <td className="table-body" style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="table-body text-end">
                                                                {formatCurrencyIndian(i.approved_electronic_item || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Grocery</td>
                                                            <td className="table-body" style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="table-body text-end">
                                                                {formatCurrencyIndian(i.approved_grocery || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <b>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                                            </b>
                                                            <td className="table-body" style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="table-body text-end" style={{ paddingBottom: "5px" }}>
                                                                <b>
                                                                    {formatCurrencyIndian(
                                                                        i.other_details?.reduce(
                                                                            (sum, other) =>
                                                                                sum + (parseFloat(other.approved_other) || 0),
                                                                            0
                                                                        )
                                                                    )}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        {i.other_details?.length > 0 ? (
                                                            <>
                                                                {i.other_details.map((other, index) => (
                                                                    <tr key={index}>
                                                                        <td className="table-body" style={{ paddingLeft: '5px', paddingBottom: "5px" }}>
                                                                            {other.other_name
                                                                                ? other.other_name
                                                                                : `Other-${index + 1}`}
                                                                        </td>
                                                                        <td className="table-body" style={{ padding: "0px 4px" }}>:</td>
                                                                        <td className="table-body text-end"
                                                                            style={{ paddingBottom: "5px" }}>
                                                                            {formatCurrencyIndian(other.approved_other || 0)}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px", paddingLeft: "5px" }} colSpan={3}>No Other Amount</td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td className="table-body">
                                                                <b>Total</b>
                                                            </td>
                                                            <td className="table-body" style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="table-body text-end">
                                                                <b>
                                                                    {formatCurrencyIndian(
                                                                        Number(i.approved_stationary_item) +
                                                                        Number(i.approved_electronic_item) +
                                                                        Number(i.approved_grocery) +
                                                                        (i.other_details?.length > 0
                                                                            ? i.other_details.reduce(
                                                                                (total, other) =>
                                                                                    total + Number(other.approved_other || 0),
                                                                                0
                                                                            )
                                                                            : 0)
                                                                    )}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td className="table-body text-center">{i.utilized_amount ? formatRoundoff2D(i.utilized_amount) : '-'} Rs</td>
                                            <td className="table-body text-center">{i.remaining_amount ? formatRoundoff2D(i.remaining_amount) : '-'} Rs</td>
                                            <td className="table-body">
                                                <span
                                                    request-status={i.expense_authority_approval_status}
                                                    className="request-status "
                                                    title={i.expense_authority_approval_status === "rejected" ? `Authority Reject Reason : ${i.authority_rejection_reason}` : ""}
                                                >
                                                    {i.expense_authority_approval_status === "pending"
                                                        ? "Pending For Authority Approval ⌛"
                                                        : i.expense_authority_approval_status === "rejected"
                                                            ? "Authority ✖, "
                                                            : "Authority ✔, "}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status "
                                                    title={i.account_status_a === "rejected" ? `Account Reject Reason : ${i.account1_rejection_reason}` : ""}
                                                >
                                                    {i.expense_authority_approval_status === "rejected" &&
                                                        i.expense_admin_approval_status === "rejected" &&
                                                        i.account_status_a === "rejected"
                                                        ? "Account ✖, "
                                                        : i.expense_authority_approval_status === "approved" &&
                                                            i.expense_admin_approval_status === "approved" &&
                                                            i.account_status_a === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.expense_authority_approval_status === "approved" &&
                                                                i.expense_admin_approval_status === "approved" &&
                                                                i.account_status_a === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.expense_authority_approval_status === "approved" &&
                                                                    i.expense_admin_approval_status === "approved" &&
                                                                    i.account_status_a === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 1: " + i.account_status_a}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.expense_admin_approval_status}
                                                    className="request-status "
                                                    title={i.expense_admin_approval_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.expense_authority_approval_status === "rejected" &&
                                                        i.expense_admin_approval_status === "rejected"
                                                        ? "Admin ✖, "
                                                        : i.expense_authority_approval_status === "approved" &&
                                                            i.expense_admin_approval_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            : i.expense_authority_approval_status === "approved" &&
                                                                i.expense_admin_approval_status === "rejected"
                                                                ? "Admin ✖, "
                                                                : i.expense_authority_approval_status === "approved" &&
                                                                    i.expense_admin_approval_status === "approved"
                                                                    ? "Admin ✔, "
                                                                    : "Admin Status: " + i.expense_admin_approval_status}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_b}
                                                    className="request-status"
                                                >
                                                    {i.expense_authority_approval_status === "rejected" &&
                                                        i.expense_admin_approval_status === "rejected" &&
                                                        i.account_status_b === "rejected"
                                                        ? "Account ✖, "
                                                        : i.expense_authority_approval_status === "approved" &&
                                                            i.expense_admin_approval_status === "approved" &&
                                                            i.account_status_b === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.expense_authority_approval_status === "approved" &&
                                                                i.expense_admin_approval_status === "approved" &&
                                                                i.account_status_b === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.expense_authority_approval_status === "approved" &&
                                                                    i.expense_admin_approval_status === "approved" &&
                                                                    i.account_status_b === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 2: " + i.account_status_b}
                                                </span>
                                            </td>

                                            <td title="Remarks and Approval Dates" className="table-body">
                                                <span
                                                    request-status={i.expense_authority_approval_status}
                                                    className="request-status "
                                                >
                                                    {i.expense_authority_approval_status === "pending"
                                                        ? "-"
                                                        : i.expense_authority_approval_status === "rejected"
                                                            ? `Authority Reject Reason : ${i.authority_rejection_reason}`
                                                            : `Authority${i.authority_engineer ? `(${i.authority_engineer}) ` : ''}: Verified Amounts for Bills ${i.authority_update_datetime ? `At: ${formatDateTime(i.authority_update_datetime).date}` : ''}`}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status "
                                                >
                                                    {i.account_status_a === "pending"
                                                        ? "-"
                                                        : i.account_status_a === "rejected"
                                                            ? `Accounts Reject Reason : ${i.account1_rejection_reason}`
                                                            : `Accounts${i.account_update_by ? `(${i.account_update_by}) ` : ''}: Bills Verified ${i.account_update_datetime ? `At: ${formatDateTime(i.account_update_datetime).date}` : ''}`}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.expense_admin_approval_status}
                                                    className="request-status "
                                                    title={i.expense_admin_approval_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.expense_admin_approval_status === "pending"
                                                        ? "-"
                                                        : i.expense_admin_approval_status === "rejected"
                                                            ? `Admin Rejection Reason : ${i.admin_rejection_reason}`
                                                            : `Admin${i.admin_update_by ? `(${i.admin_update_by}) ` : ''}: Approved After Final Verification  ${i.admin_update_datetime ? `At: ${formatDateTime(i.admin_update_datetime).date}` : ''}`}
                                                </span>
                                                {/* <br />
                                                <span
                                                request-status={i.account_status_b}
                                                className="request-status "
                                                >
                                                {i.account_status_b === "pending"
                                                    ? "-"
                                                    : i.account_status_b === "rejected"
                                                    ? `Final Rejection Reason : ${i.account2_rejection_reason}`
                                                    : `Final${i.final_update_by ? `(${i.final_update_by}) ` : ''}: Approved And Settled ${i.final_update_datetime ? `At: ${formatDateTime(i.final_update_datetime).date}` : ''}`}
                                                </span> */}
                                            </td>
                                            <td className="table-body text-center">
                                                <button
                                                    className="allproject-dashboard"
                                                    title="View using Navigate"
                                                    onClick={(e) => openExenseSlip(i)}
                                                >
                                                    <Eye />
                                                </button>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};

const RequestImpresttableAcc = () => {
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");

    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    //! ******************************API start***************************//
    const getimprestname = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/imprestbyaccount/${selectedStatus}/${fromdate}/${todate}/${selectedProject}/`
            );
            setOutdutyName(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getimprestname();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    //! *********** Employee Details Api End ***********

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <Projects toggleProject={selectedProject} setToggleProject={setProject} />
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Imprest </div>
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text">S. No.</th>
                                <th className="table-heading-text" style={{ textAlign: "center" }}>Project</th>
                                <th className="table-heading-text">Imprest Date</th>
                                <th className="table-heading-text">Dates</th>
                                <th className="table-heading-text">Imprest Name</th>
                                <th className="table-heading-text" style={{ textAlign: "center" }}>Total Amount</th>
                                <th className="table-heading-text">Days</th>
                                <th className="table-heading-text">Remarks</th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text">Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="6"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="table-body">{index + 1}</td>
                                            <td className="table-body"> {i.project_code}/{i.project_short_name}</td>
                                            <td className="table-body">{formatDate(i.date)}</td>
                                            <td title="Approval Dates" className="table-body">
                                                <div>
                                                    {i.authority_approval_status === "approved" ?
                                                        <span
                                                            request-status={i.authority_approval_status}
                                                            className="request-status "
                                                        >
                                                            Authority Approved
                                                            {i.authority_update_datetime ? <><br />At: {formatDateTime(i.authority_update_datetime).date}</> : ''}
                                                        </span>
                                                        : ""}
                                                    <br />
                                                    {i.admin_approval_status === "approved" ?
                                                        <span
                                                            request-status={i.admin_approval_status}
                                                            className="request-status "
                                                        >
                                                            Admin{i.admin_update_by ? `(${i.admin_update_by})` : ''} Approved
                                                            {i.admin_update_datetime ? <><br />At: {formatDateTime(i.admin_update_datetime).date}</> : ''}
                                                        </span>
                                                        : ""}
                                                    <br />
                                                    {i.account_approval_status === "approved" ?
                                                        <span
                                                            request-status={i.account_approval_status}
                                                            className="request-status "
                                                        >
                                                            Accounts{i.account_update_by ? `(${i.account_update_by})` : ''} Approved
                                                            {i.account_update_datetime ? <><br />At: {formatDateTime(i.account_update_datetime).date}</> : ''}
                                                        </span>
                                                        : ""}
                                                </div>
                                            </td>

                                            <td className="table-body">{i.imprest_name}</td>

                                            {i.authority_approval_status === "approved" ?
                                                <td
                                                    className="align-leftt"
                                                    title={`Reason For Change : \n${i.amount_change_reason}`}
                                                >
                                                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Stationary</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    {formatCurrencyIndian(i.approved_stationary_item || 0)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Electronics</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    {formatCurrencyIndian(i.approved_electronic_item || 0)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Grocery</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    {formatCurrencyIndian(i.approved_grocery || 0)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <b>
                                                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                                                </b>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end table-body" style={{ paddingBottom: "5px" }}>
                                                                    <b>
                                                                        {formatCurrencyIndian(
                                                                            i.other_details?.reduce(
                                                                                (sum, other) =>
                                                                                    sum + (parseFloat(other.approved_other) || 0),
                                                                                0
                                                                            )
                                                                        )}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            {i.other_details?.length > 0 ? (
                                                                <>
                                                                    {i.other_details.map((other, index) => (
                                                                        <tr key={index}>
                                                                            <td className="table-body" style={{ paddingLeft: '5px', paddingBottom: "5px" }}>
                                                                                {other.other_name
                                                                                    ? other.other_name
                                                                                    : `Other-${index + 1}`}
                                                                            </td>
                                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                                            <td className="text-end" style={{ paddingBottom: "5px" }}>
                                                                                {formatCurrencyIndian(other.approved_other || 0)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <tr>
                                                                    <td style={{ paddingBottom: "5px", paddingLeft: "5px" }} colSpan={3}>No Other Amount</td>
                                                                </tr>
                                                            )}
                                                            <tr>
                                                                <td>
                                                                    <b>Total</b>
                                                                </td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    <b>
                                                                        {formatCurrencyIndian(
                                                                            Number(i.approved_stationary_item) +
                                                                            Number(i.approved_electronic_item) +
                                                                            Number(i.approved_grocery) +
                                                                            (i.other_details?.length > 0
                                                                                ? i.other_details.reduce(
                                                                                    (total, other) =>
                                                                                        total + Number(other.approved_other || 0),
                                                                                    0
                                                                                )
                                                                                : 0)
                                                                        )}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                :
                                                <td className="table-body">
                                                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Stationary</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">{formatCurrencyIndian(i.stationary_item)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Electronics</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">{formatCurrencyIndian(i.electronic_item)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Grocery</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">{formatCurrencyIndian(i.grocery)}</td>
                                                            </tr>
                                                            <tr>
                                                                <b>
                                                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                                                                </b>
                                                                <td style={{ padding: "0px 4px" }}>
                                                                    :
                                                                </td>
                                                                <td className="text-end">
                                                                    <b>
                                                                        {i.other_details
                                                                            .reduce((sum, other) => sum + (parseFloat(other.amount) || 0), 0)
                                                                            .toLocaleString()}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            {i.other_details?.length > 0 ? (
                                                                <>
                                                                    {i.other_details.map((other, index) => (
                                                                        <>
                                                                            <tr>
                                                                                <td style={{ paddingLeft: "5px", paddingBottom: "5px" }} className="table-body" >
                                                                                    {other.other_name ? other.other_name : `Other-${index + 1}`}
                                                                                </td>
                                                                                <td style={{ padding: "0px 4px" }}>:</td>

                                                                                <td className="text-end">
                                                                                    {formatCurrencyIndian(other.amount || 0)}
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <td style={{ paddingBottom: "5px", paddingLeft: "5px" }}>
                                                                    No Other Amount
                                                                </td>
                                                            )}
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }} >
                                                                    <b>Total </b>
                                                                </td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="text-end">
                                                                    <b>
                                                                        {formatCurrencyIndian(
                                                                            Number(i.stationary_item) +
                                                                            Number(i.electronic_item) +
                                                                            Number(i.grocery) +
                                                                            (i.other_details?.length > 0
                                                                                ? i.other_details.reduce((total, other) => total + Number(other.amount || 0), 0)
                                                                                : 0)
                                                                        )}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>}

                                            <td className="table-body"> <b>{i.no_of_days}</b> </td>
                                            <td className="table-body">{i.remarks}</td>
                                            <td title='Status' className="table-body">
                                                <span
                                                    request-status={i.authority_approval_status}
                                                    className="request-status align-center"
                                                    title={`${i.authority_approval_status === "rejected" ? `TL Reason For Rejection : \n${i.rejection_reason}` : ''}`}
                                                >
                                                    {i.authority_approval_status === "pending"
                                                        ? "Pending For Authority Approval ⌛"
                                                        : i.authority_approval_status === "rejected"
                                                            ? <>Authority ✖,<br />Authority Reject Reason:<br />"{i.rejection_reason}"</>
                                                            : "Authority ✔,"}
                                                </span>
                                                {/* <br /> */}
                                                <br />
                                                <span
                                                    request-status={i.admin_approval_status}
                                                    className="request-status align-center"
                                                    title={`${i.admin_approval_status === "rejected" ? `Admin Reason: ${i.admin_rejection_reason}` : ''}`}
                                                >
                                                    {i.admin_approval_status === "rejected"
                                                        ? <>Admin ✖,<br />Admin Reject Reason:<br />"{i.admin_rejection_reason}"</>
                                                        :
                                                        i.admin_approval_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            :
                                                            i.admin_approval_status === "approved"
                                                                ? "Admin ✔,"
                                                                : "Admin Status: " + i.admin_approval_status}
                                                </span>
                                                {/* <br /> */}
                                                <br />
                                                <span
                                                    request-status={i.account_approval_status}
                                                    className="request-status align-center"
                                                    title={`${i.account_approval_status === "rejected" ? `Account Reason: ${i.account_rejection_reason}` : ''}`}
                                                >
                                                    {i.account_approval_status === "rejected"
                                                        ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.account_rejection_reason}"</>
                                                        : i.account_approval_status === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            :
                                                            i.account_approval_status === "approved"
                                                                ? "Accounts ✔,"
                                                                : "Accounts Status : " + i.account_approval_status}
                                                </span>
                                            </td>

                                            <td className="table-body">
                                                <div className="flex-row ">
                                                    <>
                                                        {
                                                            i.account_approval_status === "approved" ? (
                                                                <></>
                                                            ) : (
                                                                <ImprestApprovedAccount
                                                                    i={i}
                                                                    getImprestname={getimprestname}
                                                                />
                                                            )
                                                        }
                                                    </>
                                                    <>
                                                        {
                                                            i.account_approval_status === "rejected" ? (
                                                                <></>
                                                            ) : (
                                                                // <ImprestDeleteAccount
                                                                //   i={i}
                                                                //   getImprestname={getimprestname}
                                                                // />
                                                                <></>
                                                            )
                                                        }
                                                    </>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};

const RequestExpensetableAccStage1 = () => {
    const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    //! ******************************API start***************************//
    const getexpansename = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            // const res = await axios.get(`${BASE_URL}/siteproject/expense/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`);
            const res = await axios.get(
                `${BASE_URL}/siteproject/expensebyaccount_a/${selectedStatus}/${fromdate}/${todate}/${selectedProject}/`
            );
            setOutdutyName(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getexpansename();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    //! *********** Employee Details Api End ***********

    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const baseUrl = "/" + urlParts[3] + `/requestExpenseSlip/`;
    const opener = "acc"
    const openExenseSlip = (i) => {
        navigate(baseUrl + i.id + '/', { state: { i, opener } });
    };
    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <Projects toggleProject={selectedProject} setToggleProject={setProject} />
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500 font-size-heading">Expense Requests</div>
                        <div className="flex-row">
                        </div>
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text">S. No.</th>
                                <th className="table-heading-text">Date</th>
                                <th className="table-heading-text">Expense <b>Against</b> Imprest</th>
                                <th className="table-heading-text" style={{ textAlign: "center" }}>Imprest Amount</th>
                                <th className="table-heading-text">Utilized Money</th>
                                <th className="table-heading-text">Remaining Money</th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text width-15vw">Remarks</th>
                                <th className="table-heading-text">View/Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="9"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="table-body">{index + 1}</td>

                                            <td className="table-body">{formatDate(i.date)}</td>
                                            <td className="table-body"><span>{i.name}</span>
                                                <br />
                                                <b> Against </b>
                                                <br />
                                                <span>{i.project_name}</span></td>
                                            <td
                                                className=""
                                                title={`Reason For Change : \n${i.amount_change_reason}`}
                                            >
                                                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Stationary</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end">
                                                                {formatCurrencyIndian(i.approved_stationary_item || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Electronics</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end">
                                                                {formatCurrencyIndian(i.approved_electronic_item || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Grocery</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end">
                                                                {formatCurrencyIndian(i.approved_grocery || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <b>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                                            </b>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end table-body" style={{ paddingBottom: "5px" }}>
                                                                <b>
                                                                    {formatCurrencyIndian(
                                                                        i.other_details?.reduce(
                                                                            (sum, other) =>
                                                                                sum + (parseFloat(other.approved_other) || 0),
                                                                            0
                                                                        )
                                                                    )}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        {i.other_details?.length > 0 ? (
                                                            <>
                                                                {i.other_details.map((other, index) => (
                                                                    <tr key={index}>
                                                                        <td className="table-body" style={{ paddingLeft: '5px', paddingBottom: "5px" }}>
                                                                            {other.other_name
                                                                                ? other.other_name
                                                                                : `Other-${index + 1}`}
                                                                        </td>
                                                                        <td style={{ padding: "0px 4px" }}>:</td>
                                                                        <td className="text-end" style={{ paddingBottom: "5px" }}>
                                                                            {formatCurrencyIndian(other.approved_other || 0)}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <tr>
                                                                <td style={{ paddingBottom: "5px", paddingLeft: "5px" }} colSpan={3}>No Other Amount</td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td>
                                                                <b>Total</b>
                                                            </td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end">
                                                                <b>
                                                                    {formatCurrencyIndian(
                                                                        Number(i.approved_stationary_item) +
                                                                        Number(i.approved_electronic_item) +
                                                                        Number(i.approved_grocery) +
                                                                        (i.other_details?.length > 0
                                                                            ? i.other_details.reduce(
                                                                                (total, other) =>
                                                                                    total + Number(other.approved_other || 0),
                                                                                0
                                                                            )
                                                                            : 0)
                                                                    )}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td className="table-body align-center">{i.utilized_amount ? formatRoundoff2D(i.utilized_amount) : '-'} Rs</td>
                                            <td className="table-body align-center">{i.remaining_amount ? formatRoundoff2D(i.remaining_amount) : '-'} Rs</td>
                                            <td className="table-body ">
                                                <span
                                                    request-status={i.expense_authority_approval_status}
                                                    className="request-status "
                                                    title={i.expense_authority_approval_status === "rejected" ? `Authority Reject Reason : ${i.authority_rejection_reason}` : ""}
                                                >
                                                    {i.expense_authority_approval_status === "pending"
                                                        ? "Pending For Authority Approval ⌛"
                                                        : i.expense_authority_approval_status === "rejected"
                                                            ? "Authority ✖, "
                                                            : "Authority ✔, "}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status "
                                                    title={i.account_status_a === "rejected" ? `Account Reject Reason : ${i.account1_rejection_reason}` : ""}
                                                >
                                                    {i.expense_authority_approval_status === "rejected" &&
                                                        i.expense_admin_approval_status === "rejected" &&
                                                        i.account_status_a === "rejected"
                                                        ? "Account ✖, "
                                                        : i.expense_authority_approval_status === "approved" &&
                                                            i.expense_admin_approval_status === "approved" &&
                                                            i.account_status_a === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.expense_authority_approval_status === "approved" &&
                                                                i.expense_admin_approval_status === "approved" &&
                                                                i.account_status_a === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.expense_authority_approval_status === "approved" &&
                                                                    i.expense_admin_approval_status === "approved" &&
                                                                    i.account_status_a === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 1: " + i.account_status_a}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.expense_admin_approval_status}
                                                    className="request-status "
                                                    title={i.expense_admin_approval_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.expense_authority_approval_status === "rejected" &&
                                                        i.expense_admin_approval_status === "rejected"
                                                        ? "Admin ✖, "
                                                        : i.expense_authority_approval_status === "approved" &&
                                                            i.expense_admin_approval_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            : i.expense_authority_approval_status === "approved" &&
                                                                i.expense_admin_approval_status === "rejected"
                                                                ? "Admin ✖, "
                                                                : i.expense_authority_approval_status === "approved" &&
                                                                    i.expense_admin_approval_status === "approved"
                                                                    ? "Admin ✔, "
                                                                    : "Admin Status: " + i.expense_admin_approval_status}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_b}
                                                    className="request-status"
                                                >
                                                    {i.expense_authority_approval_status === "rejected" &&
                                                        i.expense_admin_approval_status === "rejected" &&
                                                        i.account_status_b === "rejected"
                                                        ? "Account ✖, "
                                                        : i.expense_authority_approval_status === "approved" &&
                                                            i.expense_admin_approval_status === "approved" &&
                                                            i.account_status_b === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.expense_authority_approval_status === "approved" &&
                                                                i.expense_admin_approval_status === "approved" &&
                                                                i.account_status_b === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.expense_authority_approval_status === "approved" &&
                                                                    i.expense_admin_approval_status === "approved" &&
                                                                    i.account_status_b === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 2: " + i.account_status_b}
                                                </span>
                                            </td>

                                            <td title="Remarks and Approval Dates" className="table-body">
                                                <span
                                                    request-status={i.expense_authority_approval_status}
                                                    className="request-status "
                                                >
                                                    {i.expense_authority_approval_status === "pending"
                                                        ? "-"
                                                        : i.expense_authority_approval_status === "rejected"
                                                            ? `Authority Reject Reason : ${i.authority_rejection_reason}`
                                                            : `Authority${i.authority_engineer ? `(${i.authority_engineer}) ` : ''}: Verified Amounts for Bills ${i.authority_update_datetime ? `At: ${formatDateTime(i.authority_update_datetime).date}` : ''}`}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status "
                                                >
                                                    {i.account_status_a === "pending"
                                                        ? "-"
                                                        : i.account_status_a === "rejected"
                                                            ? `Accounts Reject Reason : ${i.account1_rejection_reason}`
                                                            : `Accounts${i.account_update_by ? `(${i.account_update_by}) ` : ''}: Bills Verified ${i.account_update_datetime ? `At: ${formatDateTime(i.account_update_datetime).date}` : ''}`}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.expense_admin_approval_status}
                                                    className="request-status "
                                                    title={i.expense_admin_approval_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.expense_admin_approval_status === "pending"
                                                        ? "-"
                                                        : i.expense_admin_approval_status === "rejected"
                                                            ? `Admin Rejection Reason : ${i.admin_rejection_reason}`
                                                            : `Admin${i.admin_update_by ? `(${i.admin_update_by}) ` : ''}: Approved After Final Verification  ${i.admin_update_datetime ? `At: ${formatDateTime(i.admin_update_datetime).date}` : ''}`}
                                                </span>
                                                {/* <br />
                                                <span
                                                request-status={i.account_status_b}
                                                className="request-status "
                                                >
                                                {i.account_status_b === "pending"
                                                    ? "-"
                                                    : i.account_status_b === "rejected"
                                                    ? `Final Rejection Reason : ${i.account2_rejection_reason}`
                                                    : `Final${i.final_update_by ? `(${i.final_update_by}) ` : ''}: Approved And Settled ${i.final_update_datetime ? `At: ${formatDateTime(i.final_update_datetime).date}` : ''}`}
                                                </span> */}
                                            </td>

                                            <td className="align-center">
                                                <button
                                                    className="allproject-dashboard"
                                                    title="View using Navigate"
                                                    onClick={(e) => openExenseSlip(i)}
                                                >
                                                    <Eye />
                                                </button>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};

const RequestExpensetableAccStage2 = () => {
    const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [outdutyname, setOutdutyName] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");

    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    //! ******************************API start***************************//
    const getexpansename = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/expensebyaccount_b/${selectedStatus}/${fromdate}/${todate}/${selectedProject}/`
            );
            setOutdutyName(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getexpansename();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    //! *********** Employee Details Api End ***********

    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    const baseUrl = "/" + urlParts[3] + `/requestExpenseSlip/`;
    const opener = "accb"
    const openExenseSlip = (i) => {
        navigate(baseUrl + i.id + '/', { state: { i, opener } });
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <div className=" date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={todate}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div className="field-cont-div">
                            <Projects toggleProject={selectedProject} setToggleProject={setProject} />
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Expense Settlement</div>
                        <div className="flex-row">
                        </div>
                    </div>
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text">S. No.</th>
                                <th className="table-heading-text">Date</th>
                                <th className="table-heading-text">Expense <b>Against</b> Imprest</th>
                                <th className="table-heading-text" style={{ textAlign: "center" }}>Imprest Amount</th>
                                <th className="table-heading-text">Utilized Money</th>
                                <th className="table-heading-text">Remaining Money</th>
                                <th className="table-heading-text">View</th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text width-15vw">Remarks</th>
                                <th className="table-heading-text">View/Action</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {outdutyname.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr className="custom-table-head-td">
                                            <td className="table-body">{index + 1}</td>

                                            <td className="table-body">{formatDate(i.date)}</td>
                                            <td className="table-body"><span>{i.name}</span>
                                                <br />
                                                <b> Against </b>
                                                <br />
                                                <span>{i.project_name}</span></td>
                                            <td
                                                className=""
                                                title={`Reason For Change : \n${i.amount_change_reason}`}
                                            >
                                                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Stationary</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end">
                                                                {formatCurrencyIndian(i.approved_stationary_item || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Electronics</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end">
                                                                {formatCurrencyIndian(i.approved_electronic_item || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Grocery</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end">
                                                                {formatCurrencyIndian(i.approved_grocery || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <b>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                                            </b>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end table-body" style={{ paddingBottom: "5px" }}>
                                                                <b>
                                                                    {formatCurrencyIndian(
                                                                        i.other_details?.reduce(
                                                                            (sum, other) =>
                                                                                sum + (parseFloat(other.approved_other) || 0),
                                                                            0
                                                                        )
                                                                    )}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                        {i.other_details?.length > 0 ? (
                                                            <>
                                                                {i.other_details.map((other, index) => (
                                                                    <tr key={index}>
                                                                        <td className="table-body" style={{ paddingLeft: '5px', paddingBottom: "5px" }}>
                                                                            {other.other_name
                                                                                ? other.other_name
                                                                                : `Other-${index + 1}`}
                                                                        </td>
                                                                        <td style={{ padding: "0px 4px" }}>:</td>
                                                                        <td className="text-end" style={{ paddingBottom: "5px" }}>
                                                                            {formatCurrencyIndian(other.approved_other || 0)}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <tr>
                                                                <td style={{ paddingBottom: "5px", paddingLeft: "5px" }} colSpan={3}>No Other Amount</td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td>
                                                                <b>Total</b>
                                                            </td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="text-end">
                                                                <b>
                                                                    {formatCurrencyIndian(
                                                                        Number(i.approved_stationary_item) +
                                                                        Number(i.approved_electronic_item) +
                                                                        Number(i.approved_grocery) +
                                                                        (i.other_details?.length > 0
                                                                            ? i.other_details.reduce(
                                                                                (total, other) =>
                                                                                    total + Number(other.approved_other || 0),
                                                                                0
                                                                            )
                                                                            : 0)
                                                                    )}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td className="table-body align-center">{i.utilized_amount ? formatRoundoff2D(i.utilized_amount) : '-'} Rs</td>
                                            <td className="table-body align-center">{i.remaining_amount ? formatRoundoff2D(i.remaining_amount) : '-'} Rs</td>
                                            <td className="align-center">
                                                <button
                                                    className="allproject-dashboard"
                                                    title="View using Navigate"
                                                    onClick={(e) => openExenseSlip(i)}
                                                >
                                                    <Eye />
                                                </button>
                                            </td>
                                            <td className="table-body ">
                                                <span
                                                    request-status={i.expense_authority_approval_status}
                                                    className="request-status "
                                                    title={i.expense_authority_approval_status === "rejected" ? `Authority Reject Reason : ${i.authority_rejection_reason}` : ""}
                                                >
                                                    {i.expense_authority_approval_status === "pending"
                                                        ? "Pending For Authority Approval ⌛"
                                                        : i.expense_authority_approval_status === "rejected"
                                                            ? "Authority ✖, "
                                                            : "Authority ✔, "}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status "
                                                    title={i.account_status_a === "rejected" ? `Account Reject Reason : ${i.account1_rejection_reason}` : ""}
                                                >
                                                    {i.expense_authority_approval_status === "rejected" &&
                                                        i.expense_admin_approval_status === "rejected" &&
                                                        i.account_status_a === "rejected"
                                                        ? "Account ✖, "
                                                        : i.expense_authority_approval_status === "approved" &&
                                                            i.expense_admin_approval_status === "approved" &&
                                                            i.account_status_a === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.expense_authority_approval_status === "approved" &&
                                                                i.expense_admin_approval_status === "approved" &&
                                                                i.account_status_a === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.expense_authority_approval_status === "approved" &&
                                                                    i.expense_admin_approval_status === "approved" &&
                                                                    i.account_status_a === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 1: " + i.account_status_a}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.expense_admin_approval_status}
                                                    className="request-status "
                                                    title={i.expense_admin_approval_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.expense_authority_approval_status === "rejected" &&
                                                        i.expense_admin_approval_status === "rejected"
                                                        ? "Admin ✖, "
                                                        : i.expense_authority_approval_status === "approved" &&
                                                            i.expense_admin_approval_status === "pending"
                                                            ? "Pending For Admin Approval ⌛,"
                                                            : i.expense_authority_approval_status === "approved" &&
                                                                i.expense_admin_approval_status === "rejected"
                                                                ? "Admin ✖, "
                                                                : i.expense_authority_approval_status === "approved" &&
                                                                    i.expense_admin_approval_status === "approved"
                                                                    ? "Admin ✔, "
                                                                    : "Admin Status: " + i.expense_admin_approval_status}
                                                </span>
                                                <br />
                                                <span
                                                    request-status={i.account_status_b}
                                                    className="request-status"
                                                >
                                                    {i.expense_authority_approval_status === "rejected" &&
                                                        i.expense_admin_approval_status === "rejected" &&
                                                        i.account_status_b === "rejected"
                                                        ? "Account ✖, "
                                                        : i.expense_authority_approval_status === "approved" &&
                                                            i.expense_admin_approval_status === "approved" &&
                                                            i.account_status_b === "pending"
                                                            ? "Pending For Account Approval ⌛"
                                                            : i.expense_authority_approval_status === "approved" &&
                                                                i.expense_admin_approval_status === "approved" &&
                                                                i.account_status_b === "rejected"
                                                                ? "Accounts ✖, "
                                                                : i.expense_authority_approval_status === "approved" &&
                                                                    i.expense_admin_approval_status === "approved" &&
                                                                    i.account_status_b === "approved"
                                                                    ? "Accounts ✔, "
                                                                    : "Accounts Stage 2: " + i.account_status_b}
                                                </span>
                                            </td>

                                            <td title="Remarks and Approval Dates" className="table-body">
                                                <span
                                                    request-status={i.expense_authority_approval_status}
                                                    className="request-status "
                                                >
                                                    {i.expense_authority_approval_status === "pending"
                                                        ? "-"
                                                        : i.expense_authority_approval_status === "rejected"
                                                            ? `Authority Reject Reason : ${i.authority_rejection_reason}`
                                                            : `Authority${i.authority_engineer ? `(${i.authority_engineer}) ` : ''}: Verified Amounts for Bills ${i.authority_update_datetime ? `At: ${formatDateTime(i.authority_update_datetime).date}` : ''}`}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.account_status_a}
                                                    className="request-status "
                                                >
                                                    {i.account_status_a === "pending"
                                                        ? "-"
                                                        : i.account_status_a === "rejected"
                                                            ? `Accounts Reject Reason : ${i.account1_rejection_reason}`
                                                            : `Accounts${i.account_update_by ? `(${i.account_update_by}) ` : ''}: Bills Verified ${i.account_update_datetime ? `At: ${formatDateTime(i.account_update_datetime).date}` : ''}`}
                                                </span>
                                                <br />
                                                {/* <br /> */}
                                                <span
                                                    request-status={i.expense_admin_approval_status}
                                                    className="request-status "
                                                    title={i.expense_admin_approval_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                                                >
                                                    {i.expense_admin_approval_status === "pending"
                                                        ? "-"
                                                        : i.expense_admin_approval_status === "rejected"
                                                            ? `Admin Rejection Reason : ${i.admin_rejection_reason}`
                                                            : `Admin${i.admin_update_by ? `(${i.admin_update_by}) ` : ''}: Approved After Final Verification  ${i.admin_update_datetime ? `At: ${formatDateTime(i.admin_update_datetime).date}` : ''}`}
                                                </span>
                                                {/* <br />
                                                    <span
                                                    request-status={i.account_status_b}
                                                    className="request-status "
                                                    >
                                                    {i.account_status_b === "pending"
                                                        ? "-"
                                                        : i.account_status_b === "rejected"
                                                        ? `Final Rejection Reason : ${i.account2_rejection_reason}`
                                                        : `Final${i.final_update_by ? `(${i.final_update_by}) ` : ''}: Approved And Settled ${i.final_update_datetime ? `At: ${formatDateTime(i.final_update_datetime).date}` : ''}`}
                                                    </span> */}
                                            </td>
                                            <td className="text-end">
                                                <AccountBExpenseFinalize
                                                    i={i}
                                                    getExpanseData={getexpansename}
                                                />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};


export {
    RequestImpresttable,
    RequestExpensetable,

    RequestImpresttableAdmin,
    RequestExpensetableAdmin,

    RequestImpresttableAcc,
    RequestExpensetableAccStage1,
    RequestExpensetableAccStage2,
}