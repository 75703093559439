import React, { useState, useEffect, useRef } from "react";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from "react-toastify";
import { formattedDateLong } from "../Date";
import {
  AddCircular,
  ApproveCircular,
  DeleteCircular,
  RejectCircular
} from "./ProjectDetailsCrudButtons";

import { handleErrorToast } from "../CustomFunctions";

import { toTitleCase } from '../FormdataComponent';
import usePermission from "../../config/permissions";
import { DocumentIcon } from "../AllSvg"


const SiteManagement = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic


  const [toggleCompany, setToggleCompany] = useState("null");
  const [sectorData, setSectorData] = useState([]);


  const { PERMISSION_AUTHORITY } = usePermission();

  const getSiteCirculars = async () => {
    setBuffering(true); // Start Buffering
    let url;
    try {
      // Set URL based on role
      url = sessionStorage.getItem('sitemanagement_role') === "Admin"
        ? `${BASE_URL}/siteproject/circularuploadbyadminaprroval/${toggleCompany}/`
        : `${BASE_URL}/siteproject/circularupload/`;

      // Make API request
      const sub = await axios.get(url);

      // Update state with the fetched data
      setSectorData(sub.data);
    } catch (err) {
      // Handle different error scenarios here if needed
      handleErrorToast(err); // Toast Logic
    } finally {
      setBuffering(false); // End Buffering
    }
  };



  useEffect(() => {
    getSiteCirculars();
  }, [toggleCompany]);






  return (
    <>
      <Dashboardnavbar name="Circular Table" url="Circular Table" />
      <div className="content-tabs">  <div className="toggle-button-area" style={{ justifyContent: 'flex-end' }}>


        {sessionStorage.getItem('sitemanagement_role') === "Admin" ? (
          <div className="table-searchh dropdown-container field-cont-div">
            <select
              onChange={(e) => setToggleCompany(e.target.value)}
              value={toggleCompany}
              className="dropdown font-size-subheading  "
            >
              <option value="null">All</option>
              <option value="pending">Pending For Approval</option>
              <option value="approved">Approved Circular</option>
              <option value="rejected">Rejected Circular</option>




            </select>
          </div>
        ) : null}


        <AddCircular
          getSiteCirculars={getSiteCirculars}
          sectorList={sectorData}
        />
      </div></div>
      <div className="content-tabs">
        <div className="table-css-white-background">
          <div className="table-heading-flex">
            {/* <div className="repo-heading font-weight500 font-size-heading">
              Circular Table
            </div> */}
            {/* <div className="field-cont">


              {sessionStorage.getItem('sitemanagement_role') === "Admin" ? (
                <div className="table-searchh dropdown-container field-cont-div">
                  <select
                    onChange={(e) => setToggleCompany(e.target.value)}
                    value={toggleCompany}
                    className="dropdown font-size-subheading  "
                  >
                    <option value="null">All</option>
                    <option value="pending">Pending For Approval</option>
                    <option value="approved">Approved Circular</option>
                    <option value="rejected">Rejected Circular</option>




                  </select>
                </div>
              ) : null}


              <AddCircular
                getSiteCirculars={getSiteCirculars}
                sectorList={sectorData}
              />
            </div> */}
          </div>
          {/* <SectorsDetailForm /> */}
          <div className="table-box  scroll-container-table">
            <table className="table-css">
              <thead className="table-heading">
                <tr className="custom-table-head-tr ">
                  <th className="table-heading-text align-left">S.No</th>
                  <th className="table-heading-text text-leftt">Title</th>
                  <th className="table-heading-text text-center">Publish Date</th>
                  <th className="table-heading-text text-center" style={{ width: "20vw" }}>Description</th>

                  <th className="table-heading-text text-center">Attachment</th>
                  {/* <th className="align-center">Created By</th> */}
                  {/* <th className="align-center">Created At</th> */}
                  {sessionStorage.getItem('sitemanagement_role') === "Admin" ? <> <th className="table-heading-text text-center width-5vw">Action</th>
                    <th className="table-heading-text text-right" style={{ justifyContent: 'right' }}>Delete</th> </> : ""}
                </tr>
              </thead>
              {buffer ? (
                <div className="spinner"></div>
              ) : (
                <tbody>
                  {sectorData
                    // ?.filter((e) =>
                    //     e.title.toLowerCase().includes(searchQuery.toLowerCase())
                    // )
                    // .sort((a, b) =>
                    //     a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
                    // )
                    .map((i, index) => (
                      <React.Fragment key={index}>



                        <tr key={index} className="custom-table-head-td">
                          <td class="table-body text-left-sr">{index + 1}</td>
                          <td className="table-body text-leftt">
                            {i.title}
                          </td>

                          <td className="table-body text-center">
                            {i.publish_date ? formattedDateLong(i.publish_date) : "-"}
                          </td>

                          <td className="table-body text-justify "><div style={{
                            width: "20vw", whiteSpace: "wrap"
                          }}>{i.description}</div></td>
                          <td className="table-body text-center ">
                            <a href={i.file} target="blank">
                              <DocumentIcon />
                            </a>
                          </td>


                          {sessionStorage.getItem('sitemanagement_role') === "Admin" ?
                            <>
                              <td className="table-body text-center">

                                {i.admin_approval_status === "approved" ? "" : <ApproveCircular i={i} getCircular={getSiteCirculars} />}

                                {i.admin_approval_status === "rejected" ? "" : <RejectCircular i={i} getCircular={getSiteCirculars} />}
                              </td><td className="table-body text-right" style={{ justifyContent: 'right' }}>
                                <DeleteCircular i={i} getCircular={getSiteCirculars} />
                              </td> </> : ""}


                        </tr>

                      </React.Fragment>
                    ))}
                </tbody>
              )}
            </table>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>



      </div>
    </>
  );
};





export default SiteManagement;
