import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../config/axios";
import { formatDate, formattedDateLong } from "../Date";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import ExpenseDetailsAdd from "../settings/model/Expense";
import { handleErrorToast, formatDateTime, formatCurrencyIndian } from "../CustomFunctions";
import Select from "react-select";
import usePermission from "../../config/permissions";
import { ExpenseAdd } from "../settings/model/ExpenseAddbutton";
import { AddwithWhiteCircle, AddwithBlueCircle, CrosswithGreyCircle, DeleteDustbin, CheckGreen, Eye, DropdownArrow } from '../AllSvg';

const ImprestAdd = ({ getImprest }) => {
  const [show, setShow] = useState(false);
  const projectId = sessionStorage.getItem("sitemanagement_project")
  const handleClose = () => {
    setFormData({
      date: "",
      emp_account_detail: sessionStorage.getItem("sitemanagement_emp_id"),
      project: projectId ? projectId : "",
      stationary_item: "",
      electronic_item: "",
      grocery: "",
      other_name: "",
      other: "",
      no_of_days: "",
      remarks: "",
      imprest_open: false,
      other_details: [],
    })
    setShow(false)
  };
  const handleShow = () => {
    getFilterProjects()

    setShow(true)
  };

  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  const [formData, setFormData] = useState({
    date: "",
    emp_account_detail: sessionStorage.getItem("sitemanagement_emp_id"),
    project: projectId ? projectId : "",
    stationary_item: 0,
    electronic_item: 0,
    grocery: 0,
    other_name: "",
    other: "",
    no_of_days: "",
    remarks: "",
    imprest_name: sessionStorage.getItem("sitemanagement_project_short_name"),
    imprest_open: false,
    other_details: [],
  });

  console.log(formData);
  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "project",
      "date",
      "no_of_days",
      "imprest_name",
      // "person"
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleProjectInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "project") {
      const selectedProject = projectList.find((project) => project.id === value);

      if (selectedProject) {
        const fullProjectName = `${selectedProject.site_prcode}-${selectedProject.project_short_name}`;
        let projectName = fullProjectName;
        if (fullProjectName.length > 200) {
          const maxShortNameLength = 200 - selectedProject.site_prcode.length - 4;
          const truncatedShortName = selectedProject.project_short_name.slice(0, Math.max(maxShortNameLength, 0));
          projectName = `${selectedProject.site_prcode}-${truncatedShortName}...`;
        }

        setFormData({
          ...formData,
          project: value,
          imprest_name: projectName,
        });

        setInputState({
          ...inputState,
          project: "green",
          imprest_name: "green",
        });
      } else {
        setFormData({
          ...formData,
          project: value,
          imprest_name: "",
        });

        setInputState({
          ...inputState,
          project: value.trim() ? "green" : "",
          imprest_name: "",
        });
      }
    } else {
      setInputState({
        ...inputState,
        [name]: value.trim() ? "green" : "",
      });

      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleOtherInputChange = (index, e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.other_details];
      newOthers[index] = {
        ...newOthers[index],
        [name]: value
      };
      return {
        ...prevFormData,
        other_details: newOthers
      };
    });
  };

  const handleAddOthers = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      other_details: [
        ...prevFormData.other_details,
        {
          other_name: "",
          amount: "",
          approved_other: 0,
          remaining_other: 0
        },
      ]
    }));
  };

  const handleRemoveOthers = (index) => {
    setFormData((prevFormData) => {
      const newOthers = [...prevFormData.other_details];
      newOthers.splice(index, 1);
      return {
        ...prevFormData,
        other_details: newOthers
      };
    });
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,
      person: formData.multipleperson?.length,
      imprest_open: false,
    };

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(
          `${BASE_URL}/siteproject/imprest/`,
          updatedFormData
        );

        if (res.status === 200) {
          await getImprest();
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };


  const [projectList, setProjectList] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic
  const getFilterProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      setProjectList(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false);// End Buffering
    }
  };


  return (
    <>
      <button title="Add Imperest" className="upload-svg" onClick={handleShow}>
        <AddwithWhiteCircle /> Imperest
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Imprest Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {
                !projectId &&
                <div className="flex-column form-group-select">
                  <label
                    htmlFor="project_name"
                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                  >
                    For Projects<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    name="project"
                    onChange={handleProjectInputChange}
                    value={formData.project}
                    className={`form-input form-group-selection form-input-background ${errors.imprest ? "error" : inputState.imprest ? "success" : ""
                      }`}>
                    <option value="">Select Project</option>
                    {projectList?.sort((a, b) => a.project_short_name?.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                      <option key={index} value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                    ))}
                  </select>
                  <DropdownArrow />
                  {errors.project && (
                    <span className="error-message font-size-text ">{errors.project}</span>
                  )}
                </div>
              }

              <div className="flex-column">
                <label
                  htmlFor="imprest_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Imprest Name<span className="required">*</span>
                </label>
                <input
                  id="imprest_name"
                  type="text"
                  name="imprest_name"
                  placeholder="Imprest Name"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.imprest_name}
                  className={`form-input ${errors.imprest_name
                    ? "error"
                    : inputState.imprest_name
                      ? "success"
                      : ""
                    }`}
                />
                {errors.imprest_name && (
                  <span className="error-message font-size-text ">{errors.imprest_name}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="date" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Date<span className="required">*</span>
                </label>
                <input
                  id="date"
                  type="date"
                  name="date"
                  onChange={handleInputChange}
                  value={formData.date}
                  className={`form-input ${errors.date ? "error" : inputState.date ? "success" : ""
                    }`}
                />
                {errors.date && (
                  <span className="error-message font-size-text ">{errors.date}</span>
                )}
              </div>


              <div className="flex-column">
                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Total Expense (Auto)
                </label>
                <input
                  id="total"
                  type="number"
                  placeholder="5000"
                  value={
                    Number(formData.stationary_item) +
                    Number(formData.electronic_item) +
                    Number(formData.grocery) +
                    formData.other_details?.reduce((total, other) => total + Number(other.amount || 0), 0)
                  }
                  readOnly
                  className="form-input   font-weight500   font-size-subheading"
                />
              </div>

              <div className="flex-column">
                <label
                  htmlFor="stationary_item"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Stationary
                </label>
                <input
                  id="stationary_item"
                  type="number"
                  name="stationary_item"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.stationary_item}
                  className={`form-input ${errors.stationary_item
                    ? "error"
                    : inputState.stationary_item
                      ? "success"
                      : ""
                    }`}
                />
                {errors.stationary_item && (
                  <span className="error-message font-size-text ">{errors.stationary_item}</span>
                )}
              </div>

              <div className="flex-column">
                <label
                  htmlFor="electronic_item"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Electronics
                </label>
                <input
                  id="electronic_item"
                  type="number"
                  name="electronic_item"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.electronic_item}
                  className={`form-input ${errors.electronic_item
                    ? "error"
                    : inputState.electronic_item
                      ? "success"
                      : ""
                    }`}
                />
                {errors.electronic_item && (
                  <span className="error-message font-size-text ">{errors.electronic_item}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="grocery" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Grocery
                </label>
                <input
                  id="grocery"
                  type="number"
                  name="grocery"
                  placeholder="0"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.grocery}
                  className={`form-input ${errors.grocery
                    ? "error"
                    : inputState.grocery
                      ? "success"
                      : ""
                    }`}
                />
                {errors.grocery && (
                  <span className="error-message font-size-text ">{errors.grocery}</span>
                )}
              </div>



              <label htmlFor="other_name" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                Other Expenses
              </label>
              {formData.other_details.map((other, index) => (

                <div className="content-tabs" key={index}>
                  <div className="flex-column">
                    <div className="expense-req">
                      <div className="flex-column">
                        <label htmlFor={`other_name_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Other Expense Name
                        </label>
                      </div >
                      {index !== 0 && (
                        <div >
                          <button type='button' title="Remove" className="align-center flex-row action-div" onClick={() => handleRemoveOthers(index)}>
                            {/*  <button type='button' onClick={() => handleSRemoveDocument(index)}>Remove</button> */}
                            <CrosswithGreyCircle />
                          </button>
                        </div>
                      )}
                    </div>


                    <input
                      id={`other_name_${index}`}
                      type="text"
                      name="other_name"
                      placeholder="Other Expense Name"
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={other.other_name}
                      className={`form-input`}
                    />

                  </div>

                  <div className="flex-column">
                    <label htmlFor={`amount_${index}`} className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                      {other.other_name ? `${other.other_name} Expenses` : 'Other Expenses'}

                    </label>
                    <input
                      id={`amount_${index}`}
                      type="number"
                      name="amount"
                      placeholder="0"
                      min={0}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => handleOtherInputChange(index, e)}
                      value={other.amount}
                      className={`form-input`}
                    />

                  </div>

                </div>

              ))}
              <button type='button' title="Add Document" className="jd-heading-main font-weight600 -right-button" onClick={handleAddOthers}>
                <AddwithBlueCircle />
                Add An Other Expense
              </button>


              <div className="flex-column">
                <label
                  htmlFor="no_of_days"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Number's of days<span className="required">*</span>
                </label>
                <input
                  id="no_of_days"
                  type="number"
                  placeholder="Days"
                  name="no_of_days"
                  min={0}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.no_of_days}
                  className={`form-input ${errors.no_of_days
                    ? "error"
                    : inputState.no_of_days
                      ? "success"
                      : ""
                    }`}
                />
                {errors.no_of_days && (
                  <span className="error-message font-size-text ">{errors.no_of_days}</span>
                )}
              </div>



              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Remarks
                </label>
                <textarea
                  id="remarks"
                  type="text"
                  name="remarks"
                  placeholder="Your feedback"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.remarks}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.remarks
                    ? "error"
                    : inputState.remarks
                      ? "success"
                      : ""
                    }`}
                />
                {errors.remarks && (
                  <span className="error-message font-size-text ">{errors.remarks}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};


const ImprestModelDelete = ({ id, getImprest }) => {
  const [show, setShow] = useState(false);

  console.log(id);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/imprest/${id.id}/`);

      if (res.status === 200) {
        await getImprest();
        handleClose()
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
      setLoading(false); //loading logic
    }
  };

  return (
    <>
      <button title="Delete Imprest Request" className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Imprest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete this Imprest Request
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
                disabled={loading}
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const ExpenseSubmit = ({ i, getExpansee, total }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    getSpecificExpenses()
    setShow(true)
  };

  const [updatedlist, setExpenseDetails] = useState([]);
  const [sum, setSum] = useState("");
  const getSpecificExpenses = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/expensedetailsbyexpense/${i.id}/`
      );

      const summ = res.data.reduce(
        (acc, item) => acc + parseFloat(item.item_amount),
        0
      );
      setSum(summ);
      const uniqueDatesSet = new Set();
      res.data.forEach((item) => {
        uniqueDatesSet.add(item.date);
      });

      // console.log(uniqueDatesSet)
      setExpenseDetails(Array.from(uniqueDatesSet));
    } catch (err) { }
  };


  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/siteproject/expense/${i.id}/`, {
        utilized_amount: sum,
        remaining_amount: Number(total) - Number(sum),
        submit_for_approval: true,
        partially_change: false,
        authority_approval_status: "pending",
      });

      if (res.status === 200) {
        await getExpansee();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Submit Expense for Approval" onClick={handleShow}>
        <CheckGreen />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Expense Submission of "{i.name}" </h6>
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>
              </div>

              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Expense against Imprest :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>

              {/* <hr className="line" /> */}
              <hr className="line" />
              <div className="children">
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Utilized Amount
                  </label>
                  <p className="utilize   font-weight500   -amount align-center" style={{ color: "red" }}>
                    {sum}
                  </p>
                </div>

                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Remaining Amount
                  </label>
                  <p className="remaining-amount  font-weight500    align-center" style={{ color: "#2576bc" }}>
                    {Number(total) - Number(sum)}
                  </p>
                </div>
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Confirm
                </button>
              </div>

            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal >
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const ExpensePartialSubmit = ({ i, getExpansee, total }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    getSpecificExpenses()
    setShow(true)
  };
  console.log("i")
  console.log(i)

  const [updatedlist, setExpenseDetails] = useState([]);
  const [sum, setSum] = useState("");
  const getSpecificExpenses = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/expensedetailsbyexpense/${i.id}/`
      );

      const summ = res.data.reduce(
        (acc, item) => acc + parseFloat(item.bill_approved_amt),
        0
      );
      setSum(summ);
      const uniqueDatesSet = new Set();
      res.data.forEach((item) => {
        uniqueDatesSet.add(item.date);
      });

      // console.log(uniqueDatesSet)
      setExpenseDetails(Array.from(uniqueDatesSet));
    } catch (err) { }
  };


  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/siteproject/expense/${i.id}/`, {
        utilized_amount: sum,
        remaining_amount: Number(total) - Number(sum),
        submit_for_approval: true,
        partially_change: false,
        // authority_approval_status: "pending",

      });

      if (res.status === 200) {
        await getExpansee();
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Partial Submit Expense" onClick={handleShow}>
        <CheckGreen />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Expense Submission of "{i.name}" </h6>
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Expense against Imprest :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>
              <hr className="line" />
              <div className="children">
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Utilized Amount
                  </label>
                  <p className="utilize   font-weight500   -amount align-center" style={{ color: "red" }}>
                    {sum}
                  </p>
                </div>
                <div>
                  <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                    Remaining Amount
                  </label>
                  <p className="remaining-amount  font-weight500    align-center" style={{ color: "#2576bc" }}>
                    {Number(total) - Number(sum)}
                  </p>
                </div>
              </div>
              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal >
    </>
  );
};

const ExpenseModelDelete = ({ id, getExpansee }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/expense/${id.id}/`);

      if (res.status === 200) {

        try {
          let imp = await axios.put(`${BASE_URL}/siteproject/imprest/${id.imprest}/`, {
            imprest_open: true
          });
          if (imp.status === 200) {
            await getExpansee();
            setShow(false);
          } else {
            console.log("Imprest Opening Error");
            alert("Error Opening imprest: " + imp.statusText);
          }
        } catch (error) {
          console.error('Error updating imprest:', error);
          alert("Error Opening imprest: " + error.message);
        }
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Expense Request
            <div className="button-models">
              <button className="model-button  model-button-cancel font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const Expenses = ({ toggleexpense, expensetogglemanagenet }) => {
  const [imprest, setImprest] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getImprest = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/imprestbyproject/${sessionStorage.getItem(
          "sitemanagement_project"
        )}/`
      );
      setImprest(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getImprest();
  }, []);

  const [expansee, setexpansee] = useState([]);

  const getExpansee = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/expensebyproject/${sessionStorage.getItem(
          "sitemanagement_project"
        )}/`
      );
      setexpansee(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getExpansee();
  }, []);

  const [toggleState, setToggleState] = useState(toggleexpense);

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + `/expenseSlip`;
  let opener = "profile"
  if (expensetogglemanagenet) {
    opener = "request"
  }
  const openExenseSlip = (i) => {
    navigate(baseUrl, { state: { i, opener } });
  };
  return (
    <div>
      <div className="expense-table-cont expense-bloc-tabs">
        <div className="left-expense-bloc-tab wfm-margin">
          <button
            className={
              "expense-tab " +
              (toggleState === 1
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs dashboardcomponent-tabss expense-active-tab"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss expense-inactive-tab")
            }
            onClick={() => {
              getImprest();
              setToggleState(1)
            }}
          >
            Imprest
          </button>
          <button
            className={
              "expense-tab " +
              (toggleState === 2
                ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs expense-active-tab"
                : "dashboardcomponent-tabs  font-weight400  font-size-subheading   expense-inactive-tab")
            }
            onClick={() => {
              getExpansee();
              setToggleState(2)
            }}
          >
            Expense
          </button>
        </div>
        {toggleState === 1 ? (
          <>
            <div className="right-expense-bloc-tab">
              <div>
                <ImprestAdd getImprest={getImprest} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="right-expense-bloc-tab">
              <div>
                <ExpenseAdd getExpansee={getExpansee} />
              </div>
            </div>
          </>
        )}
      </div>
      <br />
      {toggleState === 1 ? (
        <>
          {/* Imprest History Details */}
          <div className="table-css-white-background expense-table-cont">
            <table className="table-css">
              <thead>
                <tr className="custom-table-head-tr">
                  <th className="table-heading-text">S. No.</th>
                  <th className="table-heading-text">Imprest Date</th>
                  <th className="table-heading-text">Dates</th>
                  <th className="table-heading-text">Imprest Name</th>
                  <th className="table-heading-text">Project</th>
                  <th className="table-heading-text" style={{ textAlign: "center" }}>Proposed Amount</th>
                  <th className="table-heading-text" style={{ textAlign: "center" }}>Approved Amount</th>
                  <th className="table-heading-text">Days</th>
                  <th className="table-heading-text">Remarks</th>
                  <th className="table-heading-text">Status</th>
                  <th className="table-heading-text">Action</th>
                </tr>
              </thead>
              {buffer ? <div className="spinner"></div> :
                <tbody>
                  <tr className="tr-border-bottom">
                    <td colSpan={6}></td>
                  </tr>
                  {imprest.map((item, index) => {
                    return (
                      <>
                        <tr className="custom-table-head-td">
                          <td className="table-body">{index + 1}</td>
                          {/* <td className="align-left">{item.empcode}</td> */}
                          <td className="table-body">
                            {formatDate(item.date)}
                          </td>

                          <td title="Approval Dates" className="table-body">
                            <div>
                              {item.authority_approval_status === "approved" ?
                                <span
                                  request-status={item.authority_approval_status}
                                  className="request-status "
                                >
                                  Authority Approved
                                  {item.authority_update_datetime ? <><br />At: {formatDateTime(item.authority_update_datetime).date}</> : ''}
                                </span>
                                : ""}
                              <br />
                              {item.admin_approval_status === "approved" ?
                                <span
                                  request-status={item.admin_approval_status}
                                  className="request-status "
                                >
                                  Admin{item.admin_update_by ? `(${item.admin_update_by})` : ''} Approved
                                  {item.admin_update_datetime ? <><br />At: {formatDateTime(item.admin_update_datetime).date}</> : ''}
                                </span>
                                : ""}
                              <br />
                              {item.account_approval_status === "approved" ?
                                <span
                                  request-status={item.account_approval_status}
                                  className="request-status "
                                >
                                  Accounts{item.account_update_by ? `(${item.account_update_by})` : ''} Approved
                                  {item.account_update_datetime ? <><br />At: {formatDateTime(item.account_update_datetime).date}</> : ''}
                                </span>
                                : ""}
                            </div>
                          </td>

                          <td className="table-body"> {item.imprest_name}</td>
                          <td className="table-body"> {item.project_code}/{item.project_short_name}</td>

                          {/* <td className="table-body">
                            <b>
                              {Number(item.stationary_item) +
                                Number(item.electronic_item) +
                                Number(item.grocery) +
                                (item.other_details?.length > 0
                                  ? item.other_details.reduce((total, other) => total + Number(other.amount || 0), 0)
                                  : 0)}{" "}
                              Rs
                            </b>{" "}<br />
                            Stationary :Rs. {item.stationary_item} <br />
                            Electronics :Rs. {item.electronic_item} <br />
                            Grocery :Rs. {item.grocery} <br />
                            Others : <br />
                            {item.other_details?.length > 0 ? (
                              <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                                {item.other_details.map((other, index) => (
                                  <li key={index}>
                                    <span className="form-text-trasformation-uppercase">
                                      {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.amount || 0}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <span>No Other Amount <br /></span>
                            )}
                          </td> */}


                          <td className="table-body">
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Stationary</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{formatCurrencyIndian(item.stationary_item)}</td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Electronics</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{formatCurrencyIndian(item.electronic_item)}</td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Grocery</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{formatCurrencyIndian(item.grocery)}</td>
                                </tr>
                                <tr>
                                  <b>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                                  </b>
                                  <td style={{ padding: "0px 4px" }}>
                                    :
                                  </td>
                                  <td className="align-right">
                                    <b>
                                      {item.other_details
                                        .reduce((sum, other) => sum + (parseFloat(other.amount) || 0), 0)
                                        .toLocaleString()}
                                    </b>
                                  </td>
                                </tr>
                                {item.other_details?.length > 0 ? (
                                  <>
                                    {item.other_details.map((other, index) => (
                                      <>
                                        <tr>
                                          <td style={{ paddingLeft: "5px", paddingBottom: "5px" }} className="table-body" >
                                            {other.other_name ? other.other_name : `Other-${index + 1}`}
                                          </td>
                                          <td style={{ padding: "0px 4px" }}>:</td>

                                          <td className="align-right">
                                            {formatCurrencyIndian(other.amount || 0)}
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <td style={{ paddingBottom: "5px", paddingLeft: "5px" }}>
                                    No Other Amount
                                  </td>
                                )}
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }} >
                                    <b>Total </b>
                                  </td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    <b>
                                      {formatCurrencyIndian(
                                        Number(item.stationary_item) +
                                        Number(item.electronic_item) +
                                        Number(item.grocery) +
                                        (item.other_details?.length > 0
                                          ? item.other_details.reduce((total, other) => total + Number(other.amount || 0), 0)
                                          : 0)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>

                          {item.amount_change_reason ? (
                            <td
                              className="align-leftt"
                              title={`Reason For Change : \n${item.amount_change_reason}`}
                            >
                              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <tbody>
                                  <tr>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Stationary</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {formatCurrencyIndian(item.approved_stationary_item || 0)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Electronics</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {formatCurrencyIndian(item.approved_electronic_item || 0)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Grocery</td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {formatCurrencyIndian(item.approved_grocery || 0)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <b>
                                      <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                    </b>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right table-body" style={{ paddingBottom: "5px" }}>
                                      <b>
                                        {formatCurrencyIndian(
                                          item.other_details?.reduce(
                                            (sum, other) =>
                                              sum + (parseFloat(other.approved_other) || 0),
                                            0
                                          )
                                        )}
                                      </b>
                                    </td>
                                  </tr>
                                  {item.other_details?.length > 0 ? (
                                    <>
                                      {item.other_details.map((other, index) => (
                                        <tr key={index}>
                                          <td className="table-body" style={{ paddingLeft: '5px', paddingBottom: "5px" }}>
                                            {other.other_name
                                              ? other.other_name
                                              : `Other-${index + 1}`}
                                          </td>
                                          <td style={{ padding: "0px 4px" }}>:</td>
                                          <td className="align-right" style={{ paddingBottom: "5px" }}>
                                            {formatCurrencyIndian(other.approved_other || 0)}
                                          </td>
                                        </tr>
                                      ))}
                                    </>
                                  ) : (
                                    <tr>
                                      <td style={{ paddingBottom: "5px", paddingLeft: "5px" }} colSpan={3}>No Other Amount</td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td>
                                      <b>Total</b>
                                    </td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      <b>
                                        {formatCurrencyIndian(
                                          Number(item.approved_stationary_item) +
                                          Number(item.approved_electronic_item) +
                                          Number(item.approved_grocery) +
                                          (item.other_details?.length > 0
                                            ? item.other_details.reduce(
                                              (total, other) =>
                                                total + Number(other.approved_other || 0),
                                              0
                                            )
                                            : 0)
                                        )}
                                      </b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          ) : (
                            <td className="table-body" style={{ textAlign: "center" }}>-</td>
                          )}

                          <td className="table-body"> <b>{item.no_of_days}</b> </td>

                          <td className="table-body">{item.remarks}</td>

                          <td className="table-body">
                            <span
                              request-status={item.authority_approval_status}
                              className="request-status align-center"
                              title={`${item.authority_approval_status === "rejected" ? `TL Reason For Rejection : \n${item.rejection_reason}` : ''}`}
                            >
                              {item.authority_approval_status === "pending"
                                ? "Pending For Authority Approval ⌛"
                                : item.authority_approval_status === "rejected"
                                  ? <>Authority ✖,<br />Authority Reject Reason:<br />"{item.rejection_reason}"</>
                                  : "Authority ✔,"}
                            </span>
                            {/* <br /> */}
                            <br />
                            <span
                              request-status={item.admin_approval_status}
                              className="request-status align-center"
                              title={`${item.admin_approval_status === "rejected" ? `Admin Reason: ${item.admin_rejection_reason}` : ''}`}
                            >
                              {item.admin_approval_status === "rejected"
                                ? <>Admin ✖,<br />Admin Reject Reason:<br />"{item.admin_rejection_reason}"</>
                                :
                                item.admin_approval_status === "pending"
                                  ? "Pending For Admin Approval ⌛,"
                                  :
                                  item.admin_approval_status === "approved"
                                    ? "Admin ✔,"
                                    : "Admin Status: " + item.admin_approval_status}
                            </span>
                            {/* <br /> */}
                            <br />
                            <span
                              request-status={item.account_approval_status}
                              className="request-status align-center"
                              title={`${item.account_approval_status === "rejected" ? `Account Reason: ${item.account_rejection_reason}` : ''}`}
                            >
                              {item.account_approval_status === "rejected"
                                ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{item.account_rejection_reason}"</>
                                : item.account_approval_status === "pending"
                                  ? "Pending For Account Approval ⌛"
                                  :
                                  item.account_approval_status === "approved"
                                    ? "Accounts ✔,"
                                    : "Accounts Status : " + item.account_approval_status}
                            </span>
                          </td>
                          <td className="table-body">
                            <span
                              request-status={item.authority_approval_status === "rejected" || item.admin_approval_status === "rejected" || item.account_approval_status === "rejected" ? "rejected" : item.account_approval_status === "pending" ? "pending" : item.account_approval_status}
                              className="request-status align-center"
                            >
                              {" "}
                              {item.authority_approval_status === "pending" && item.admin_approval_status === "pending" && item.account_approval_status === "pending" || item.authority_approval_status === "rejected" ? (
                                <ImprestModelDelete
                                  getImprest={getImprest}
                                  id={item}
                                />
                              ) :
                                item.account_approval_status !== "pending" ?

                                  <span title={`Transaction/Cheque No.:\n${item.account_approval_status !== "pending" ? item.account_approved_final_details : null}`}>

                                    {item.account_approval_status === "approved" ? "Process Completed" : item.authority_approval_status === "rejected" ? "Process Terminated" : ""}

                                    <br />
                                    {item.account_approved_final_details ?
                                      (`Transaction Id : ${item.account_approved_final_details}`)
                                      :
                                      ("No Transaction Id")
                                    }
                                    <br />
                                    {item.account_transaction_proof ? (
                                      <a className="modal-button-black" href={item.account_transaction_proof} target="blank">
                                        View
                                      </a>
                                    ) :
                                      ("No Proof Attached")
                                    }
                                  </span>
                                  : ("In Process")}
                            </span>
                          </td>
                        </tr>
                        <tr className="tr-border-bottom">
                          <td colSpan={6}></td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              }
            </table>
          </div>
        </>
      ) : null}

      {toggleState === 2 ? (
        <>
          {/* Expense History Details */}
          <div className="table-css-white-background expense-table-cont">
            <table className="table-css">
              <thead>
                <tr className="custom-table-head-tr">
                  <th className="table-heading-text">S. No.</th>
                  <th className="table-heading-text">Date</th>
                  <th className="table-heading-text">Expense <b>Against</b> Imprest</th>
                  <th className="table-heading-text">Approved Imprest Amount</th>
                  <th className="table-heading-text">Spent Money</th>
                  <th className="table-heading-text">View</th>
                  <th className="table-heading-text">Status</th>
                  <th className="table-heading-text width-15vw">Remarks</th>
                  <th className="table-heading-text width-15vw">Action</th>
                </tr>
              </thead>
              {buffer ? <div className="spinner"></div> :
                <tbody>
                  <tr className="tr-border-bottom">
                    <td colSpan={7}></td>
                  </tr>
                  {expansee.map((item, index) => {
                    return (
                      <>
                        <tr className="custom-table-head-td">
                          <td className="table-body">{index + 1}</td>
                          <td className="table-body">{formatDate(item.date)}</td>
                          <td className="table-body"><span>{item.name}</span>
                            <br />
                            <b> Against </b>
                            <br />
                            <span>{item.project_name}</span></td>
                          <td className="table-body">
                            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                              <tbody>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Stationary</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    {formatCurrencyIndian(item.approved_stationary_item || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Electronics</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    {formatCurrencyIndian(item.approved_electronic_item || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Grocery</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    {formatCurrencyIndian(item.approved_grocery || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <b>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                  </b>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    <b>
                                      {formatCurrencyIndian(
                                        item.other_details?.reduce(
                                          (sum, other) => sum + Number(other.approved_other || 0),
                                          0
                                        )
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                {item.other_details?.length > 0 ? (
                                  <>
                                    {item.other_details.map((other, index) => (
                                      <tr key={index}>
                                        <td className="table-body" style={{ paddingLeft: "5px", paddingBottom: "5px" }} >
                                          {other.other_name ? other.other_name : `Other-${index + 1}`}
                                        </td>
                                        <td style={{ padding: "0px 4px" }}>:</td>
                                        <td className="align-right">
                                          {formatCurrencyIndian(other.approved_other || 0)}
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                ) : (
                                  <tr>
                                    <td style={{ padding: "0px 4px", paddingBottom: "5px" }} colSpan={3}>No Other Expense</td>
                                  </tr>
                                )}
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>
                                    <b>Total</b>
                                  </td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    <b>
                                      {formatCurrencyIndian(
                                        Number(item.approved_stationary_item) +
                                        Number(item.approved_electronic_item) +
                                        Number(item.approved_grocery) +
                                        (item.other_details?.length > 0
                                          ? item.other_details.reduce(
                                            (total, other) => total + Number(other.approved_other || 0),
                                            0
                                          )
                                          : 0)
                                        || 0
                                      )}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>

                          <td className="table-body align-center">
                            {item.imprest_account_leave_status === "pending"
                              ? <spam request-status={item.account_status_b}
                                className="request-status align-center">
                                Imprest Approval Pending ⌛
                              </spam>
                              : <ExpenseDetailsAdd
                                i={item}
                                expansee={getExpansee}
                                totall={
                                  Number(item.approved_stationary_item) +
                                  Number(item.approved_electronic_item) +
                                  Number(item.approved_grocery) +
                                  (item.other_details?.length > 0
                                    ? item.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                    : 0)
                                }
                                utilize={item.utilized_amount}
                                remain={item.remaining_amount}
                              />}
                          </td>
                          <td className="table-body align-center">
                            <button
                              className="allproject-dashboard"
                              title="View using Navigate"
                              onClick={(e) => openExenseSlip(item)}
                            >
                              <Eye />
                            </button>
                          </td>

                          <td className="table-body ">
                            <span
                              request-status={item.expense_authority_approval_status}
                              className="request-status "
                              title={item.expense_authority_approval_status === "rejected" ? `Authority Reject Reason : ${item.authority_rejection_reason}` : ""}
                            >
                              {item.expense_authority_approval_status === "pending"
                                ? "Pending For Authority Approval ⌛"
                                : item.expense_authority_approval_status === "rejected"
                                  ? "Authority ✖, "
                                  : "Authority ✔, "}
                            </span>
                            <br />
                            <span
                              request-status={item.account_status_a}
                              className="request-status "
                              title={item.account_status_a === "rejected" ? `Account Reject Reason : ${item.account1_rejection_reason}` : ""}
                            >
                              {item.expense_authority_approval_status === "rejected" &&
                                item.expense_admin_approval_status === "rejected" &&
                                item.account_status_a === "rejected"
                                ? "Account ✖, "
                                : item.expense_authority_approval_status === "approved" &&
                                  item.expense_admin_approval_status === "approved" &&
                                  item.account_status_a === "pending"
                                  ? "Pending For Account Approval ⌛"
                                  : item.expense_authority_approval_status === "approved" &&
                                    item.expense_admin_approval_status === "approved" &&
                                    item.account_status_a === "rejected"
                                    ? "Accounts ✖, "
                                    : item.expense_authority_approval_status === "approved" &&
                                      item.expense_admin_approval_status === "approved" &&
                                      item.account_status_a === "approved"
                                      ? "Accounts ✔, "
                                      : "Accounts Stage 1: " + item.account_status_a}
                            </span>
                            <br />
                            <span
                              request-status={item.expense_admin_approval_status}
                              className="request-status "
                              title={item.expense_admin_approval_status === "rejected" ? `Admin Reject Reason : ${item.admin_rejection_reason}` : ""}
                            >
                              {item.expense_authority_approval_status === "rejected" &&
                                item.expense_admin_approval_status === "rejected"
                                ? "Admin ✖, "
                                : item.expense_authority_approval_status === "approved" &&
                                  item.expense_admin_approval_status === "pending"
                                  ? "Pending For Admin Approval ⌛,"
                                  : item.expense_authority_approval_status === "approved" &&
                                    item.expense_admin_approval_status === "rejected"
                                    ? "Admin ✖, "
                                    : item.expense_authority_approval_status === "approved" &&
                                      item.expense_admin_approval_status === "approved"
                                      ? "Admin ✔, "
                                      : "Admin Status: " + item.expense_admin_approval_status}
                            </span>
                            <br />
                            <span
                              request-status={item.account_status_b}
                              className="request-status"
                            >
                              {item.expense_authority_approval_status === "rejected" &&
                                item.expense_admin_approval_status === "rejected" &&
                                item.account_status_b === "rejected"
                                ? "Account ✖, "
                                : item.expense_authority_approval_status === "approved" &&
                                  item.expense_admin_approval_status === "approved" &&
                                  item.account_status_b === "pending"
                                  ? "Pending For Account Approval ⌛"
                                  : item.expense_authority_approval_status === "approved" &&
                                    item.expense_admin_approval_status === "approved" &&
                                    item.account_status_b === "rejected"
                                    ? "Accounts ✖, "
                                    : item.expense_authority_approval_status === "approved" &&
                                      item.expense_admin_approval_status === "approved" &&
                                      item.account_status_b === "approved"
                                      ? "Accounts ✔, "
                                      : "Accounts Stage 2: " + item.account_status_b}
                            </span>
                          </td>

                          <td title="Remarks and Approval Dates" className="table-body">
                            <span
                              request-status={item.expense_authority_approval_status}
                              className="request-status "
                            >
                              {item.expense_authority_approval_status === "pending"
                                ? "-"
                                : item.expense_authority_approval_status === "rejected"
                                  ? `Authority Reject Reason : ${item.authority_rejection_reason}`
                                  : `Authority${item.authority_engineer ? `(${item.authority_engineer}) ` : ''}: Verified Amounts for Bills ${item.authority_update_datetime ? `At: ${formatDateTime(item.authority_update_datetime).date}` : ''}`}
                            </span>
                            <br />
                            {/* <br /> */}
                            <span
                              request-status={item.account_status_a}
                              className="request-status "
                            >
                              {item.account_status_a === "pending"
                                ? "-"
                                : item.account_status_a === "rejected"
                                  ? `Accounts Reject Reason : ${item.account1_rejection_reason}`
                                  : `Accounts${item.account_update_by ? `(${item.account_update_by}) ` : ''}: Bills Verified ${item.account_update_datetime ? `At: ${formatDateTime(item.account_update_datetime).date}` : ''}`}
                            </span>
                            <br />
                            {/* <br /> */}
                            <span
                              request-status={item.expense_admin_approval_status}
                              className="request-status "
                              title={item.expense_admin_approval_status === "rejected" ? `Admin Reject Reason : ${item.admin_rejection_reason}` : ""}
                            >
                              {item.expense_admin_approval_status === "pending"
                                ? "-"
                                : item.expense_admin_approval_status === "rejected"
                                  ? `Admin Rejection Reason : ${item.admin_rejection_reason}`
                                  : `Admin${item.admin_update_by ? `(${item.admin_update_by}) ` : ''}: Approved After Final Verification  ${item.admin_update_datetime ? `At: ${formatDateTime(item.admin_update_datetime).date}` : ''}`}
                            </span>
                            {/* <br />
                            <span
                              request-status={item.account_status_b}
                              className="request-status "
                            >
                              {item.account_status_b === "pending"
                                ? "-"
                                : item.account_status_b === "rejected"
                                  ? `Final Rejection Reason : ${item.account2_rejection_reason}`
                                  : `Final${item.final_update_by ? `(${item.final_update_by}) ` : ''}: Approved And Settled ${item.final_update_datetime ? `At: ${formatDateTime(item.final_update_datetime).date}` : ''}`}
                            </span> */}
                          </td>

                          <td className="table-body align-center" key={item.action}>
                            <span className="">
                              {" "}
                              {
                                item.submit_for_approval === false
                                  ? (
                                    <span
                                      request-status={item.imprest_data.account_approval_status}
                                      className="request-status "
                                      title={`${item.imprest_data.account_approval_status === "rejected" ? `Reason: ${item.account_rejection_reason}` : ''}`}
                                    > {item.imprest_data.account_approval_status === "rejected"
                                      ? "Imprest Account ✖, "
                                      :
                                      item.imprest_data.account_approval_status === "pending"
                                        ? "Imprest Approval Pending ⌛"
                                        :
                                        item.imprest_data.account_approval_status === "rejected"
                                          ? "Imprest Accounts ✖, "
                                          : item.imprest_data.account_approval_status === "approved"
                                            ? <ExpenseSubmit
                                              getExpansee={getExpansee}
                                              i={item}
                                              total={
                                                Number(item.approved_stationary_item) +
                                                Number(item.approved_electronic_item) +
                                                Number(item.approved_grocery) +
                                                (item.other_details?.length > 0
                                                  ? item.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                                  : 0)
                                              }
                                            />
                                            : "Accounts Status: " + item.imprest_data.account_approval_status}
                                      <br />
                                      <ExpenseModelDelete
                                        getExpansee={getExpansee}
                                        id={item}
                                      />
                                    </span>
                                  ) :
                                  item.partially_change ?
                                    <span
                                      request-status={"rejected"}
                                      className="request-status "
                                    >Update Bills
                                      <br />
                                      <ExpensePartialSubmit
                                        getExpansee={getExpansee}
                                        i={item}
                                        total={
                                          Number(item.approved_stationary_item) +
                                          Number(item.approved_electronic_item) +
                                          Number(item.approved_grocery) +
                                          (item.other_details?.length > 0
                                            ? item.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                                            : 0)
                                        }
                                      />
                                    </span> :
                                    item.account_status_b === "pending" ?
                                      <span
                                        request-status={"pending"}
                                        className="request-status"
                                      > In Process...</span> : <span
                                        request-status={"approved"}
                                        className="request-status"
                                      >Process Completed.</span>
                              }
                            </span>
                          </td>
                        </tr >
                        <tr className="tr-border-bottom">
                          <td colSpan={7}></td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              }
            </table>
          </div>
        </>
      ) : null}
    </div >
  );
};

export default Expenses;
