import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL } from '../../config/axios';
// import { BASE_URL } from '../config/axios';
import Dashboardnavbar from '../../layout/Dashboardnavbar';
import * as XLSX from 'xlsx';
import { Modal } from 'react-bootstrap';
import { DownloadTableExcel } from 'react-export-table-to-excel';

import { formatCurrencyIndian, getMonthName, handleErrorToast, sortProjects, inputMaxLimit } from '../CustomFunctions';

import { AddwithWhiteCircle, Calender, CircularGrid, DeleteDustbin, DropdownArrow, Location, UpdatePencil, HourGlass, Filter, SearchBig, AddwithBlueCircle, DropdownArrowOption, DownloadIcon, UploadIcon, Attachment, Refresh } from '../AllSvg';
// import { DeleteSiteEmployee, UpdateSiteEmployee } from './Wfm/WorkforceCrudButton';
import usePermission from '../../config/permissions';
import { Projects } from '../FilterDropdown';
// import usePermission from '../config/permissions';
// import { Projects } from './FilterDropdown';
import { UploadOfficeRentBulk } from '../SiteManagement/BulkModals';
// import { UploadOfficeRentBulk } from './SiteManagement/BulkModals';
import { DocumentModel } from '../SiteManagement/ProjectDetailsCrudButtons';
const AddOfficeRent = ({ getRent, siteProjectList }) => {
  const [show, setShow] = useState(false);
  const { projectID } = usePermission();

  const handleClose = () => {
    setFormData({
      project: "",
      rent_amt: "",
      agreement_duration: "",
      agreement_date: "",
      end_date: "null",
      owner_name: "",
      bank_name: "",
      account_holder_name: "",
      account_num: "",
      ifsc_code: "",
      pan_card: "",
      gst_num: "",
      address: "",
      mobile: "",
      BOQOfficeRent: "",
    })
    setErrors({});
    setShow(false);
  }



  const [fileNames, setFileNames] = useState("");

  const [formData, setFormData] = useState({
    project: projectID ? projectID : "",
    rent_amt: "",
    agreement_duration: "",
    agreement_date: "",
    end_date: null,
    owner_name: "",
    bank_name: "",
    account_holder_name: "",
    account_num: "",
    ifsc_code: "",
    pan_card: "",
    gst_num: "",
    address: "",
    mobile: "",
    BOQOfficeRent: '',

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [boqList, setBoqList] = useState([]);
  const [loadingboq, setLoadingboq] = useState(false);
  const getBoq = async () => {

    try {
      const ress = await axios.get(`${BASE_URL}/siteproject/boqofficerentfilters/${formData.project}/`);
      setBoqList(ress.data)
      setLoadingboq(true);
    } catch (err) {
      //toast Logic
      // handleErrorToast(err);
    } finally {

    }
  }
  const handleShow = () => {
    setShow(true);
    getproject();
    // getBoq();
  }

  useEffect(() => {
    console.log("useffect running")
    if (formData.project != "") {
      getBoq();
    }
  }, [formData.project])
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["project", "rent_amt", "agreement_duration", "agreement_date", "owner_name", "bank_name", "account_holder_name", "account_num", "ifsc_code", "pan_card", "gst_num", "address", "mobile", "end_date", "BOQOfficeRent"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic
  const [projectList, setProjectList] = useState([]);

  const getproject = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/project/`);

      const splitCodeParts = (code) => {
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/");
        const lastPart = parts.pop();
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
        if (match) {
          return {
            firstPart,
            numericPart: match[1] ? parseInt(match[1], 10) : 0,
            alphaPart: match[2]
          };
        }
        return { firstPart, numericPart: 0, alphaPart: "" };
      };

      const sortedList = response.data.sort((a, b) => {
        const aParts = splitCodeParts(a.site_prcode);
        const bParts = splitCodeParts(b.site_prcode);
        if (aParts.firstPart !== bParts.firstPart) {
          return aParts.firstPart.localeCompare(bParts.firstPart);
        }
        if (aParts.alphaPart !== bParts.alphaPart) {
          return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }
        return aParts.numericPart - bParts.numericPart;
      });

      // setProjectList(response.data)
      setProjectList(sortedList)

    } catch (error) {
      alert(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();
      formDataa.append("project", formData.project);
      formDataa.append("rent_amt", formData.rent_amt);
      formDataa.append("agreement_duration", formData.agreement_duration);
      formDataa.append("agreement_date", formData.agreement_date);
      formDataa.append("owner_name", formData.owner_name);
      formDataa.append("bank_name", formData.bank_name);
      formDataa.append("account_holder_name", formData.account_holder_name);
      formDataa.append("account_num", formData.account_num);
      formDataa.append("ifsc_code", formData.ifsc_code);
      formDataa.append("pan_card", formData.pan_card);
      formDataa.append("gst_num", formData.gst_num);
      formDataa.append("address", formData.address);
      formDataa.append("mobile", formData.mobile);
      formDataa.append("BOQOfficeRent", formData.BOQOfficeRent);

      try {
        let res = await axios.post(
          `${BASE_URL}/siteproject/officerent/`,
          formDataa,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status === 200) {
          await getRent();
          handleClose();
          toast.dismiss(loadingToastId);
          toast.success("Office rent  added successfully!");

        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to add data!");
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedData = { ...formData, [name]: value };

    if (name === "rent_amt") {
      const rentAmount = parseFloat(value).toFixed(2);

      const boqItem = boqList.find((attendance) => attendance.id === formData.BOQOfficeRent);

      if (boqItem) {
        const maxlimit = boqItem.rate_per_month;
        const { success, error } = inputMaxLimit(name, value, maxlimit);
        if (!success) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
          }));
          return;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
          }));
          if (rentAmount >= 20000) {
            const tdsPercentage = 10;
            const tdsAmount = parseFloat(((rentAmount * tdsPercentage) / 100).toFixed(2));
            updatedData = {
              ...updatedData,
              tds_percentage: tdsPercentage,
              tds_amt: tdsAmount,
              total_amt: parseFloat((parseFloat(rentAmount) - tdsAmount).toFixed(2)),
            };
          } else {
            updatedData = {
              ...updatedData,
              tds_percentage: "",
              tds_amt: 0,
              total_amt: parseFloat(rentAmount),
            };
          }
        }
      } else {
        if (rentAmount >= 20000) {
          const tdsPercentage = 10;
          const tdsAmount = parseFloat(((rentAmount * tdsPercentage) / 100).toFixed(2));
          updatedData = {
            ...updatedData,
            tds_percentage: tdsPercentage,
            tds_amt: tdsAmount,
            total_amt: parseFloat((parseFloat(rentAmount) - tdsAmount).toFixed(2)),
          };
        } else {
          updatedData = {
            ...updatedData,
            tds_percentage: "",
            tds_amt: 0,
            total_amt: parseFloat(rentAmount),
          };
        }
      }



    }

    if (name === "tds_percentage" && Number(formData.rent_amt) >= 20000) {
      const tdsPercentage = parseFloat(value).toFixed(2);
      const tdsAmount = parseFloat(((Number(formData.rent_amt) * tdsPercentage) / 100).toFixed(2));
      updatedData = {
        ...updatedData,
        tds_amt: tdsAmount,
        total_amt: parseFloat((Number(formData.rent_amt) - tdsAmount).toFixed(2)),
      };
    }

    setFormData(updatedData);

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    if (errors[name]) {
      setErrors((prevErrors) => {
        const { [name]: removedError, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };


  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      const validFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ];

      if (validFileTypes.includes(file.type)) {
        setFormData({
          ...formData,
          [name]: file,
        });
        setErrors({
          ...errors,
          [name]: "", // Clear any previous errors for this field
        });
        setFileNames(file.name); // Store the selected file name
      } else {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please select a PDF or DOC file.",
        });
        setFileNames(""); // Reset file name if the selected file is invalid
      }
    } else {
      setFormData({
        ...formData,
        [name]: null,
      });
      setFileNames(""); // Reset file name if no file is selected
    }
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
    setFileNames(""); // Clear the file name when the file is cleared
  };

  return (
    <>
      <button title="Add OfficeRent" className="upload-svg" onClick={handleShow}>
        <AddwithWhiteCircle />
        {" "}Office Rent
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        {/* <Modal.Header closeButton>
            <Modal.Title>Request Leave</Modal.Title>
          </Modal.Header> */}
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title> Add Office Rent </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading font-weight500    font-size-heading">Circular</h6> */}

              <form className="register-form">

                <div className="form-flex-wrap">

                  {sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ? <></> :

                    <div title="Site Projects" className="flex-column form-group-selectt">
                      <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                        Site Projects<span className="required">*</span>
                      </label>
                      <br />
                      <select
                        id="project"
                        name="project"
                        value={formData.project}
                        onChange={handleInputChange}
                        className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""
                          }`}
                      >
                        <option value="">Select a Project</option>
                        {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                          <option value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                        </>))
                        }
                      </select>
                      {/* {formData.project !== "" ? "" :  */}
                      <div className="form-group-selection-arrow">
                        <DropdownArrowOption />
                      </div>
                      {/* } */}
                      <div>
                        {errors.project && (
                          <span className="error-message font-size-text ">{errors.project}</span>
                        )}
                      </div>
                    </div>
                  }
                  <div title="BOQOfficeRent" className="flex-column form-group-selectt">
                    <label htmlFor="BOQOfficeRent" className="form-labels  font-weight500    font-size-subheading">
                      Boq List <span className="required">*</span>
                    </label>

                    {!loadingboq ?
                      <div className="form-loader">
                        <div className="bar-loader"></div>
                      </div> : (<>
                        <br />
                        <select
                          id="BOQOfficeRent"
                          name="BOQOfficeRent"
                          value={formData.BOQOfficeRent}
                          onChange={handleInputChange}
                          className={`form-input form-group-selection ${errors.BOQOfficeRent ? "error" : inputState.BOQOfficeRent ? "success" : ""
                            }`}
                        >
                          <option value="">Select a Boq</option>
                          {boqList?.sort((a, b) => a?.rate_per_month.toString().localeCompare(b?.rate_per_month.toString(), 'en', { ignorePunctuation: true })).map((i, index) => (<>
                            <option value={i.id}>Rate per Month -{i.rate_per_month}</option>
                          </>))
                          }
                        </select>

                        <div className="form-group-selection-arrow">
                          <DropdownArrowOption />
                        </div>

                        <div>
                          {errors.BOQOfficeRent && (
                            <span className="error-message font-size-text ">{errors.BOQOfficeRent}</span>
                          )}
                        </div></>)}
                  </div>
                  <div title="Rent Amount" className="flex-column">
                    <label htmlFor="rent_amt" className="form-labels  font-weight500    font-size-subheading">
                      Basic Amount<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="rent_amt"
                      name="rent_amt"
                      placeholder="Basic Amount."
                      onChange={handleInputChange}
                      value={formData.rent_amt}
                      className={`form-input ${errors.rent_amt
                        ? "error"
                        : inputState.rent_amt
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.rent_amt && (
                      <span className="error-message font-size-text ">{errors.rent_amt}</span>
                    )}
                  </div>
                  {Number(formData.rent_amt) < 20000
                    ? null :
                    <>
                      <div title="TDS Percentage" className="flex-column">
                        <label htmlFor="tds_percentage" className="form-labels  font-weight500    font-size-subheading">
                          TDS Percentage<span className="required">*</span>
                        </label>
                        <input
                          type="number"
                          min={0}
                          maxLength={10}
                          id="tds_percentage"
                          name="tds_percentage"
                          placeholder="TDS Percentage"
                          onChange={handleInputChange}
                          value={formData.tds_percentage}
                          className={`form-input ${errors.tds_percentage
                            ? "error"
                            : inputState.tds_percentage
                              ? "success"
                              : ""
                            }`}
                        />
                        {errors.tds_percentage && (
                          <span className="error-message font-size-text ">{errors.tds_percentage}</span>
                        )}
                      </div>
                      <div title="TDS Amount" className="flex-column">
                        <label htmlFor="tds_amt" className="form-labels  font-weight500    font-size-subheading">
                          TDS Amount<span className="required">(Read Only)</span>
                        </label>
                        <input
                          type="number"
                          min={0}
                          maxLength={10}
                          id="tds_amt"
                          name="tds_amt"
                          placeholder="TDS Amount."
                          // onChange={handleInputChange}
                          value={formData.tds_amt}
                          disabled
                          readOnly
                          style={{ cursor: 'not-allowed' }}
                          className={`form-input ${errors.tds_amt
                            ? "error"
                            : inputState.tds_amt
                              ? "success"
                              : ""
                            }`}
                        />
                        {errors.tds_amt && (
                          <span className="error-message font-size-text ">{errors.tds_amt}</span>
                        )}
                      </div>
                    </>
                  }

                  <div title="Total Amount" className="flex-column">
                    <label htmlFor="total_amt" className="form-labels  font-weight500    font-size-subheading">
                      Total Amount<span className="required">(Auto Calculated)</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="total_amt"
                      name="total_amt"
                      placeholder="Total Amount"
                      // onChange={handleInputChange}
                      value={formData.total_amt}
                      disabled
                      readOnly
                      style={{ cursor: 'not-allowed' }}
                      className={`form-input ${errors.total_amt
                        ? "error"
                        : inputState.total_amt
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.total_amt && (
                      <span className="error-message font-size-text ">{errors.total_amt}</span>
                    )}
                  </div>
                  <div title="Agreement Duration" className="flex-column">
                    <label htmlFor="agreement_duration" className="form-labels  font-weight500    font-size-subheading">
                      Agreement Duration<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="agreement_duration"
                      name="agreement_duration"
                      placeholder="Agreement Duration."
                      onChange={handleInputChange}
                      value={formData.agreement_duration}
                      className={`form-input ${errors.agreement_duration
                        ? "error"
                        : inputState.agreement_duration
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.agreement_duration && (
                      <span className="error-message font-size-text ">{errors.agreement_duration}</span>
                    )}
                  </div>
                  <div title="Agreement Date" className="flex-column">
                    <label htmlFor="agreement_date" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                      Agreement Date<span className="required">*</span>
                    </label>
                    <input
                      id="agreement_date"
                      type="date"
                      name="agreement_date"
                      placeholder="date"
                      onChange={handleInputChange}
                      value={formData.agreement_date}
                      className={`form-input ${errors.agreement_date ? "error" : inputState.agreement_date ? "success" : ""
                        }`}
                    />
                    {errors.agreement_date && (
                      <span className="error-message font-size-text ">{errors.agreement_date}</span>
                    )}
                  </div>

                  <div title="End Date" className="flex-column">
                    <label htmlFor="end_date" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                      End Date<span className="required">*</span>
                    </label>
                    <input
                      id="end_date"
                      type="date"
                      name="end_date"
                      placeholder="date"
                      min={formData.agreement_date}
                      onChange={handleInputChange}
                      value={formData.end_date}
                      className={`form-input ${errors.end_date ? "error" : inputState.end_date ? "success" : ""
                        }`}

                    />
                    {errors.end_date && (
                      <span className="error-message font-size-text ">{errors.end_date}</span>
                    )}

                  </div>

                  <div title="Owner name" className="flex-column">
                    <label htmlFor="owner_name" className="form-labels  font-weight500    font-size-subheading">
                      Owner Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      min={0}
                      maxLength={10}
                      id="owner_name"
                      name="owner_name"
                      placeholder="Owner name."
                      onChange={handleInputChange}
                      value={formData.owner_name}
                      className={`form-input ${errors.owner_name
                        ? "error"
                        : inputState.owner_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.owner_name && (
                      <span className="error-message font-size-text ">{errors.owner_name}</span>
                    )}
                  </div>



                  <div title="Bank name" className="flex-column">
                    <label htmlFor="bank_name" className="form-labels  font-weight500    font-size-subheading">
                      Bank Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="bank_name"
                      name="bank_name"
                      placeholder="Bank name."
                      onChange={handleInputChange}
                      value={formData.bank_name}
                      className={`form-input ${errors.bank_name
                        ? "error"
                        : inputState.bank_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.bank_name && (
                      <span className="error-message font-size-text ">{errors.bank_name}</span>
                    )}
                  </div>

                  <div title="Account holder name" className="flex-column">
                    <label htmlFor="account_holder_name" className="form-labels  font-weight500    font-size-subheading">
                      Account Holder Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="account_holder_name"
                      name="account_holder_name"
                      placeholder="Account holder name."
                      onChange={handleInputChange}
                      value={formData.account_holder_name}
                      className={`form-input ${errors.account_holder_name
                        ? "error"
                        : inputState.account_holder_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.account_holder_name && (
                      <span className="error-message font-size-text ">{errors.account_holder_name}</span>
                    )}
                  </div>

                  <div title="Account number" className="flex-column">
                    <label htmlFor="account_num" className="form-labels  font-weight500    font-size-subheading">
                      Account Number<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="account_num"
                      name="account_num"
                      placeholder="Account number."
                      onChange={handleInputChange}
                      value={formData.account_num}
                      className={`form-input ${errors.account_num
                        ? "error"
                        : inputState.account_num
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.account_num && (
                      <span className="error-message font-size-text ">{errors.account_num}</span>
                    )}
                  </div>

                  <div title="Ifsc code" className="flex-column">
                    <label htmlFor="ifsc_code" className="form-labels  font-weight500    font-size-subheading">
                      Ifsc Code<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="ifsc_code"
                      name="ifsc_code"
                      placeholder="Ifsc Code."
                      onChange={handleInputChange}
                      value={formData.ifsc_code}
                      className={`form-input ${errors.ifsc_code
                        ? "error"
                        : inputState.ifsc_code
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.ifsc_code && (
                      <span className="error-message font-size-text ">{errors.ifsc_code}</span>
                    )}
                  </div>


                  <div title="Pan Card" className="flex-column">
                    <label htmlFor="pan_card" className="form-labels  font-weight500    font-size-subheading">
                      PAN Card<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="pan_card"
                      name="pan_card"
                      placeholder="PAN Card."
                      onChange={handleInputChange}
                      value={formData.pan_card}
                      className={`form-input ${errors.pan_card
                        ? "error"
                        : inputState.pan_card
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.pan_card && (
                      <span className="error-message font-size-text ">{errors.pan_card}</span>
                    )}
                  </div>

                  <div title="GSt Num" className="flex-column">
                    <label htmlFor="gst_num" className="form-labels  font-weight500    font-size-subheading">
                      GST Number<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="gst_num"
                      name="gst_num"
                      placeholder="GST Number."
                      onChange={handleInputChange}
                      value={formData.gst_num}
                      className={`form-input ${errors.gst_num
                        ? "error"
                        : inputState.gst_num
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.gst_num && (
                      <span className="error-message font-size-text ">{errors.gst_num}</span>
                    )}
                  </div>

                  <div title="Phone number" className="flex-column">
                    <label htmlFor="mobile" className="form-labels  font-weight500    font-size-subheading">
                      Phone Number<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="mobile"
                      name="mobile"
                      placeholder="Phone number."
                      onChange={handleInputChange}
                      value={formData.mobile}
                      className={`form-input ${errors.mobile
                        ? "error"
                        : inputState.mobile
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.mobile && (
                      <span className="error-message font-size-text ">{errors.mobile}</span>
                    )}
                  </div>

                  <div title="Address" className="flex-column">
                    <label htmlFor="address" className="form-labels  font-weight500    font-size-subheading">
                      Address<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      placeholder="Address."
                      onChange={handleInputChange}
                      value={formData.address}
                      className={`form-input ${errors.address
                        ? "error"
                        : inputState.address
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.address && (
                      <span className="error-message font-size-text ">{errors.address}</span>
                    )}
                  </div>


                </div>
              </form>
              <div className="button-models">
                <button className="model-button font-weight500 model-button-cancel" type='button' onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
              <p className="error-message font-size-text">
                {isSubmitted && Object.keys(errors).length > 0 && (
                  Object.keys(errors).length > 5 ? (
                    <h5 className="text-center">Please fill all mandatory fields!</h5>
                  ) : (

                    <h6 className="text-center">
                      {Object.keys(errors).map((field) =>
                        field.charAt(0).toUpperCase() + field.slice(1)
                      ).join(', ') + ' are required!'}
                    </h6>
                  )
                )}
              </p>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};




const DeleteOfficeRent = ({ i, getRent }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/officerentupdate/${i.id}/`);

      if (res.status === 200) {
        await getRent();
        setShow(false);
        toast.dismiss(loadingToastId);
        toast.success("Data deleted successfully!");
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete" className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Office Rent "{i.account_holder_name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete {i.account_holder_name}?
            <div className="button-models">
              <button className="model-button   font-weight500   model-button-cancel " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};


const UpdateOfficeRent = ({ getRent, projectId, siteProjectList, i }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {

    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
    getproject();

    const filteredData = { ...i };
    delete filteredData.agreement_upload;
    setFormData(filteredData);
  }



  const [formData, setFormData] = useState({
    project: "",
    rent_amt: "",
    agreement_duration: "",
    agreement_date: "",
    end_date: null,
    owner_name: "",
    agreement_upload: i.agreement_upload,
    bank_name: "",
    account_holder_name: "",
    account_num: "",
    ifsc_code: "",
    pan_card: "",
    gst_num: "",
    address: "",
    mobile: "",
    BOQOfficeRent: ''
  });

  // !  ************** Validation start **************  ! //
  const [boqList, setBoqList] = useState([]);
  const [loadingboq, setLoadingboq] = useState(false);
  const getBoq = async () => {

    try {
      const ress = await axios.get(`${BASE_URL}/siteproject/boqofficerentfilters/${formData.project}/`);
      setBoqList(ress.data)
      setLoadingboq(true)
    } catch (err) {
      //toast Logic
      // handleErrorToast(err);
    } finally {

    }
  }


  useEffect(() => {

    if (formData.project != "") {
      getBoq();
    }
  }, [formData.project])
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["project", "rent_amt", "agreement_duration", "agreement_date", "owner_name", "bank_name", "account_holder_name", "account_num", "ifsc_code", "pan_card", "address", "mobile", "end_date", "BOQOfficeRent"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic
  const [projectList, setProjectList] = useState([]);

  const getproject = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/project/`);

      const splitCodeParts = (code) => {
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/");
        const lastPart = parts.pop();
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
        if (match) {
          return {
            firstPart,
            numericPart: match[1] ? parseInt(match[1], 10) : 0,
            alphaPart: match[2]
          };
        }
        return { firstPart, numericPart: 0, alphaPart: "" };
      };

      const sortedList = response.data.sort((a, b) => {
        const aParts = splitCodeParts(a.site_prcode);
        const bParts = splitCodeParts(b.site_prcode);
        if (aParts.firstPart !== bParts.firstPart) {
          return aParts.firstPart.localeCompare(bParts.firstPart);
        }
        if (aParts.alphaPart !== bParts.alphaPart) {
          return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }
        return aParts.numericPart - bParts.numericPart;
      });

      // setProjectList(response.data)
      setProjectList(sortedList)

    } catch (error) {
      alert(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();
      const updatedfromdata = {
        ...formData,
      };

      // formDataa.append("project", formData.project);
      // formDataa.append("rent_amt", formData.rent_amt);
      // formDataa.append("agreement_duration", formData.agreement_duration);
      // formDataa.append("agreement_date", formData.agreement_date);
      // formDataa.append("owner_name", formData.owner_name);
      // formDataa.append("agreement_upload", formData.agreement_upload);
      // formDataa.append("bank_name", formData.bank_name);
      // formDataa.append("account_holder_name", formData.account_holder_name);
      // formDataa.append("account_num", formData.account_num);
      // formDataa.append("ifsc_code", formData.ifsc_code);
      // formDataa.append("pan_card", formData.pan_card);
      // formDataa.append("gst_num", formData.gst_num);
      // formDataa.append("address", formData.address);
      // formDataa.append("mobile", formData.mobile);

      // Append general form fields
      Object.keys(updatedfromdata).forEach((key) => {
        if (updatedfromdata[key] && key !== "agreement_upload") {
          formDataa.append(key, updatedfromdata[key]);
        }
      });
      if (updatedfromdata.agreement_upload) {
        formDataa.append("agreement_upload", updatedfromdata.agreement_upload);
      }


      try {
        let res = await axios.put(
          `${BASE_URL}/siteproject/officerentupdate/${i.id}/`,
          formDataa,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status === 200) {
          await getRent();
          handleClose();
          toast.dismiss(loadingToastId);
          toast.success("Office Rent Updated Successfully!");

        } else {
          toast.dismiss(loadingToastId);
          toast.error("Failed to update office rent!");
        }
      } catch (err) {
        //toast Logic
        handleErrorToast(err)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedData = { ...formData, [name]: value };

    if (name === "rent_amt") {
      const rentAmount = parseFloat(value).toFixed(2);

      const boqItem = boqList.find((attendance) => attendance.id === formData.BOQOfficeRent);

      if (boqItem) {
        const maxlimit = boqItem.rate_per_month;
        const { success, error } = inputMaxLimit(name, value, maxlimit);
        if (!success) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
          }));
          return;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
          }));
          if (rentAmount >= 20000) {
            const tdsPercentage = 10;
            const tdsAmount = parseFloat(((rentAmount * tdsPercentage) / 100).toFixed(2));
            updatedData = {
              ...updatedData,
              tds_percentage: tdsPercentage,
              tds_amt: tdsAmount,
              total_amt: parseFloat((parseFloat(rentAmount) - tdsAmount).toFixed(2)),
            };
          } else {
            updatedData = {
              ...updatedData,
              tds_percentage: "",
              tds_amt: 0,
              total_amt: parseFloat(rentAmount),
            };
          }
        }
      } else {
        if (rentAmount >= 20000) {
          const tdsPercentage = 10;
          const tdsAmount = parseFloat(((rentAmount * tdsPercentage) / 100).toFixed(2));
          updatedData = {
            ...updatedData,
            tds_percentage: tdsPercentage,
            tds_amt: tdsAmount,
            total_amt: parseFloat((parseFloat(rentAmount) - tdsAmount).toFixed(2)),
          };
        } else {
          updatedData = {
            ...updatedData,
            tds_percentage: "",
            tds_amt: 0,
            total_amt: parseFloat(rentAmount),
          };
        }
      }



    }

    if (name === "tds_percentage" && Number(formData.rent_amt) >= 20000) {
      const tdsPercentage = parseFloat(value).toFixed(2);
      const tdsAmount = parseFloat(((Number(formData.rent_amt) * tdsPercentage) / 100).toFixed(2));
      updatedData = {
        ...updatedData,
        tds_amt: tdsAmount,
        total_amt: parseFloat((Number(formData.rent_amt) - tdsAmount).toFixed(2)),
      };
    }

    setFormData(updatedData);

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    if (errors[name]) {
      setErrors((prevErrors) => {
        const { [name]: removedError, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      const validFileTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ];

      if (validFileTypes.includes(file.type)) {
        setFormData({
          ...formData,
          [name]: file,
        });
        setErrors({
          ...errors,
          [name]: "", // Clear any previous errors for this field
        });
      } else {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please select a PDF or DOC file.",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: null,
      });
    }
  };


  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  return (
    <>
      <button title="Update Office Rent" className="model-edit-button" onClick={handleShow}>
        <UpdatePencil />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        {/* <Modal.Header closeButton>
            <Modal.Title>Request Leave</Modal.Title>
          </Modal.Header> */}
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title> Update Office Rent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="task-Tab-heading font-weight500    font-size-heading">Circular</h6> */}

              <form className="register-form">

                <div className="form-flex-wrap">


                  <div title="Site Projects" className="flex-column form-group-selectt">
                    <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                      Site Projects
                    </label>
                    <br />
                    <select
                      id="project"
                      name="project"
                      value={formData.project}
                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""
                        }`}
                    >
                      <option value="">Select a Project</option>
                      {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                        <option value={i.id}>{i.site_prcode}-{i.project_short_name}</option>
                      </>))
                      }
                    </select>
                    {/* {formData.project !== "" ? "" :  */}
                    <div className="form-group-selection-arrow">
                      <DropdownArrowOption />
                    </div>
                    {/* } */}
                    <div>
                      {errors.project && (
                        <span className="error-message font-size-text ">{errors.project}</span>
                      )}
                    </div>
                  </div>
                  <div title="BOQOfficeRent" className="flex-column form-group-selectt">
                    <label htmlFor="BOQOfficeRent" className="form-labels  font-weight500    font-size-subheading">
                      Boq List <span className="required">*</span>
                    </label>

                    {!loadingboq ?
                      <div className="form-loader">
                        <div className="bar-loader"></div>
                      </div> : (<>
                        <br />
                        <select
                          id="BOQOfficeRent"
                          name="BOQOfficeRent"
                          value={formData.BOQOfficeRent}
                          onChange={handleInputChange}
                          className={`form-input form-group-selection ${errors.BOQOfficeRent ? "error" : inputState.BOQOfficeRent ? "success" : ""
                            }`}
                        >
                          <option value="">Select a Boq</option>
                          {boqList?.sort((a, b) => a?.rate_per_month.toString().localeCompare(b?.rate_per_month.toString(), 'en', { ignorePunctuation: true })).map((i, index) => (<>
                            <option value={i.id}>Rate per Month -{i.rate_per_month}</option>
                          </>))
                          }
                        </select>

                        <div className="form-group-selection-arrow">
                          <DropdownArrowOption />
                        </div>

                        <div>
                          {errors.BOQOfficeRent && (
                            <span className="error-message font-size-text ">{errors.BOQOfficeRent}</span>
                          )}
                        </div></>)}
                  </div>
                  <div title="Rent Amount" className="flex-column">
                    <label htmlFor="rent_amt" className="form-labels  font-weight500    font-size-subheading">
                      Basic Amount<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="rent_amt"
                      name="rent_amt"
                      placeholder="Basic Amount."
                      onChange={handleInputChange}
                      value={formData.rent_amt}
                      className={`form-input ${errors.rent_amt
                        ? "error"
                        : inputState.rent_amt
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.rent_amt && (
                      <span className="error-message font-size-text ">{errors.rent_amt}</span>
                    )}
                  </div>
                  {Number(formData.rent_amt) < 20000
                    ? null :
                    <>
                      <div title="TDS Percentage" className="flex-column">
                        <label htmlFor="tds_percentage" className="form-labels  font-weight500    font-size-subheading">
                          TDS Percentage<span className="required">*</span>
                        </label>
                        <input
                          type="number"
                          min={0}
                          maxLength={10}
                          id="tds_percentage"
                          name="tds_percentage"
                          placeholder="TDS Percentage"
                          onChange={handleInputChange}
                          value={formData.tds_percentage}
                          className={`form-input ${errors.tds_percentage
                            ? "error"
                            : inputState.tds_percentage
                              ? "success"
                              : ""
                            }`}
                        />
                        {errors.tds_percentage && (
                          <span className="error-message font-size-text ">{errors.tds_percentage}</span>
                        )}
                      </div>

                      <div title="TDS Amount" className="flex-column">
                        <label htmlFor="tds_amt" className="form-labels  font-weight500    font-size-subheading">
                          TDS Amount<span className="required">(Read Only)</span>
                        </label>
                        <input
                          type="number"
                          min={0}
                          maxLength={10}
                          id="tds_amt"
                          name="tds_amt"
                          placeholder="TDS Amount."
                          // onChange={handleInputChange}
                          value={formData.tds_amt}
                          disabled
                          readOnly
                          style={{ cursor: 'not-allowed' }}
                          className={`form-input ${errors.tds_amt
                            ? "error"
                            : inputState.tds_amt
                              ? "success"
                              : ""
                            }`}
                        />
                        {errors.tds_amt && (
                          <span className="error-message font-size-text ">{errors.tds_amt}</span>
                        )}
                      </div>
                    </>
                  }

                  <div title="Total Amount" className="flex-column">
                    <label htmlFor="total_amt" className="form-labels  font-weight500    font-size-subheading">
                      Total Amount<span className="required">(Auto Calculated)</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="total_amt"
                      name="total_amt"
                      placeholder="Total Amount"
                      // onChange={handleInputChange}
                      value={formData.total_amt}
                      disabled
                      readOnly
                      style={{ cursor: 'not-allowed' }}
                      className={`form-input ${errors.total_amt
                        ? "error"
                        : inputState.total_amt
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.total_amt && (
                      <span className="error-message font-size-text ">{errors.total_amt}</span>
                    )}
                  </div>

                  <div title="Agreement Duration" className="flex-column">
                    <label htmlFor="agreement_duration" className="form-labels  font-weight500    font-size-subheading">
                      Agreement Duration
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="agreement_duration"
                      name="agreement_duration"
                      placeholder="Agreement Duration."
                      onChange={handleInputChange}
                      value={formData.agreement_duration}
                      className={`form-input ${errors.agreement_duration
                        ? "error"
                        : inputState.agreement_duration
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.agreement_duration && (
                      <span className="error-message font-size-text ">{errors.agreement_duration}</span>
                    )}
                  </div>

                  <div title="Agreement Date" className="flex-column">
                    <label htmlFor="agreement_date" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                      Agreement Date
                    </label>
                    <input
                      id="agreement_date"
                      type="date"
                      name="agreement_date"
                      placeholder="date"
                      onChange={handleInputChange}
                      value={formData.agreement_date}
                      className={`form-input ${errors.agreement_date ? "error" : inputState.agreement_date ? "success" : ""
                        }`}
                    />
                    {errors.agreement_date && (
                      <span className="error-message font-size-text ">{errors.agreement_date}</span>
                    )}
                  </div>

                  <div title="End Date" className="flex-column">
                    <label htmlFor="end_date" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                      End Date
                    </label>
                    <input
                      id="end_date"
                      type="date"
                      name="end_date"
                      placeholder="date"
                      min={formData.agreement_date}
                      onChange={handleInputChange}
                      value={formData.end_date}
                      className={`form-input ${errors.end_date ? "error" : inputState.end_date ? "success" : ""
                        }`}
                    />
                    {errors.end_date && (
                      <span className="error-message font-size-text ">{errors.end_date}</span>
                    )}
                  </div>

                  <div title="Owner name" className="flex-column">
                    <label htmlFor="owner_name" className="form-labels  font-weight500    font-size-subheading">
                      Owner Name
                    </label>
                    <input
                      type="text"
                      min={0}
                      maxLength={10}
                      id="owner_name"
                      name="owner_name"
                      placeholder="Owner name."
                      onChange={handleInputChange}
                      value={formData.owner_name}
                      className={`form-input ${errors.owner_name
                        ? "error"
                        : inputState.owner_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.owner_name && (
                      <span className="error-message font-size-text ">{errors.owner_name}</span>
                    )}
                  </div>



                  <div title="Bank name" className="flex-column">
                    <label htmlFor="bank_name" className="form-labels  font-weight500    font-size-subheading">
                      Bank Name
                    </label>
                    <input
                      type="text"
                      id="bank_name"
                      name="bank_name"
                      placeholder="Bank name."
                      onChange={handleInputChange}
                      value={formData.bank_name}
                      className={`form-input ${errors.bank_name
                        ? "error"
                        : inputState.bank_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.bank_name && (
                      <span className="error-message font-size-text ">{errors.bank_name}</span>
                    )}
                  </div>

                  <div title="Account holder name" className="flex-column">
                    <label htmlFor="account_holder_name" className="form-labels  font-weight500    font-size-subheading">
                      Account Holder Name
                    </label>
                    <input
                      type="text"
                      id="account_holder_name"
                      name="account_holder_name"
                      placeholder="Account holder name."
                      onChange={handleInputChange}
                      value={formData.account_holder_name}
                      className={`form-input ${errors.account_holder_name
                        ? "error"
                        : inputState.account_holder_name
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.account_holder_name && (
                      <span className="error-message font-size-text ">{errors.account_holder_name}</span>
                    )}
                  </div>

                  <div title="Account number" className="flex-column">
                    <label htmlFor="account_num" className="form-labels  font-weight500    font-size-subheading">
                      Account Number
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="account_num"
                      name="account_num"
                      placeholder="Account number."
                      onChange={handleInputChange}
                      value={formData.account_num}
                      className={`form-input ${errors.account_num
                        ? "error"
                        : inputState.account_num
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.account_num && (
                      <span className="error-message font-size-text ">{errors.account_num}</span>
                    )}
                  </div>

                  <div title="Ifsc code" className="flex-column">
                    <label htmlFor="ifsc_code" className="form-labels  font-weight500    font-size-subheading">
                      Ifsc Code
                    </label>
                    <input
                      type="text"
                      id="ifsc_code"
                      name="ifsc_code"
                      placeholder="Ifsc Code."
                      onChange={handleInputChange}
                      value={formData.ifsc_code}
                      className={`form-input ${errors.ifsc_code
                        ? "error"
                        : inputState.ifsc_code
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.ifsc_code && (
                      <span className="error-message font-size-text ">{errors.ifsc_code}</span>
                    )}
                  </div>


                  <div title="Pan Card" className="flex-column">
                    <label htmlFor="pan_card" className="form-labels  font-weight500    font-size-subheading">
                      PAN Card
                    </label>
                    <input
                      type="text"
                      id="pan_card"
                      name="pan_card"
                      placeholder="PAN Card."
                      onChange={handleInputChange}
                      value={formData.pan_card}
                      className={`form-input ${errors.pan_card
                        ? "error"
                        : inputState.pan_card
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.pan_card && (
                      <span className="error-message font-size-text ">{errors.pan_card}</span>
                    )}
                  </div>

                  <div title="GSt Num" className="flex-column">
                    <label htmlFor="gst_num" className="form-labels  font-weight500    font-size-subheading">
                      GST Number
                    </label>
                    <input
                      type="text"
                      id="gst_num"
                      name="gst_num"
                      placeholder="GST Number."
                      onChange={handleInputChange}
                      value={formData.gst_num}
                      className={`form-input ${errors.gst_num
                        ? "error"
                        : inputState.gst_num
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.gst_num && (
                      <span className="error-message font-size-text ">{errors.gst_num}</span>
                    )}
                  </div>

                  <div title="Phone number" className="flex-column">
                    <label htmlFor="mobile" className="form-labels  font-weight500    font-size-subheading">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      min={0}
                      maxLength={10}
                      id="mobile"
                      name="mobile"
                      placeholder="Phone number."
                      onChange={handleInputChange}
                      value={formData.mobile}
                      className={`form-input ${errors.mobile
                        ? "error"
                        : inputState.mobile
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.mobile && (
                      <span className="error-message font-size-text ">{errors.mobile}</span>
                    )}
                  </div>

                  <div title="Address" className="flex-column">
                    <label htmlFor="address" className="form-labels  font-weight500    font-size-subheading">
                      Address
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      placeholder="Address."
                      onChange={handleInputChange}
                      value={formData.address}
                      className={`form-input ${errors.address
                        ? "error"
                        : inputState.address
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.address && (
                      <span className="error-message font-size-text ">{errors.address}</span>
                    )}
                  </div>
                </div>
              </form>
              <div className="button-models">
                <button className="model-button font-weight500 model-button-cancel" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const OfficeRent = (i) => {
  const [project, setProject] = useState("null");
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [subCompanyList, setSubCompanyList] = useState([]);
  const [subCompany, setSubCompany] = useState("null")
  const [subbuffer, setSubBuffering] = useState(true); //buffering logic
  const [projectList, setProjectList] = useState([]);
  const { projectID } = usePermission();
  const [selectedName, setName] = useState("");
  const [selectedFromDate, setFromDate] = useState('null');
  const [selectedToDate, setToDate] = useState('null');
  const [officeRentList, setOfficeRentList] = useState([]);




  useEffect(() => {
    getSubCompany();
    getRent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectID, project]);
  useEffect(() => {
    getproject();
  }, [subCompany])

  const getRent = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/officerentbyproj/${projectID ? projectID : project}/`);
      setOfficeRentList(res.data);

    } catch (err) {
      handleErrorToast(err);

    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const getproject = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/${subCompany}/`);

      const splitCodeParts = (code) => {
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/");
        const lastPart = parts.pop();
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
        if (match) {
          return {
            firstPart,
            numericPart: match[1] ? parseInt(match[1], 10) : 0,
            alphaPart: match[2]
          };
        }
        return { firstPart, numericPart: 0, alphaPart: "" };
      };

      const sortedList = response.data.sort((a, b) => {
        const aParts = splitCodeParts(a.site_prcode);
        const bParts = splitCodeParts(b.site_prcode);
        if (aParts.firstPart !== bParts.firstPart) {
          return aParts.firstPart.localeCompare(bParts.firstPart);
        }
        if (aParts.alphaPart !== bParts.alphaPart) {
          return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }
        return aParts.numericPart - bParts.numericPart;
      });

      // setProjectList(response.data)
      setProjectList(sortedList)

    } catch (error) {
      alert(error);
    }
  }
  const getSubCompany = async () => {
    setSubBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSubCompanyList(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setSubBuffering(false);// End Buffering
    }
  };
  const filterByOwnerNameAndDate = () => {
    return officeRentList.filter((item) => {
      const matchesName = selectedName
        ? item.owner_name.toLowerCase().includes(selectedName.toLowerCase())
        : true;

      const itemDate = Date.parse(item.agreement_date);
      const fromDate = selectedFromDate ? Date.parse(selectedFromDate) : null;
      const toDate = selectedToDate ? Date.parse(selectedToDate) : null;

      const matchesFromDate = fromDate ? itemDate >= fromDate : true;
      const matchesToDate = toDate ? itemDate <= toDate : true;

      return matchesName && matchesFromDate && matchesToDate;
    });
  };

  return (
    <>


      {/* <Dashboardnavbar name={"Office Rent"} url="Office Rent" /> */}
      <div className="attendance-subcont">

        <div className="field-cont">
          {!projectID &&
            <>
              <div className="field-cont-div">
                <CircularGrid />
                <select
                  className="attendance-input-field width-15vw   "
                  type="text"
                  value={subCompany}
                  onChange={(e) => setSubCompany(e.target.value)}
                >
                  <option value="">Sub Company</option>

                  {subCompanyList.sort((a, b) => a.title - b.title).map((i, index) => (
                    <option value={i.id}>{i.title}</option>
                  ))}
                  ;
                </select>
                <hr className="field-cont-hr" />
              </div>
              <Projects toggleProject={project} subcompany={subCompany} setToggleProject={setProject} />
            </>
          }

          <div className="field-cont-div">
            <CircularGrid />
            <input
              className="attendance-input-field width-10vw  "
              placeholder="Owner Name"
              type="text"
              value={selectedName}
              onChange={(e) => setName(e.target.value)}
            />
            <hr className="field-cont-hr" />
          </div>
          <div title="From Date Filter" className="field-cont-div">
            <input
              className="attendance-input-field width-10vw   date-field"
              placeholder="From Date"
              type="date"
              value={selectedFromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
            <hr className="field-cont-hr" />
          </div>

          <div title="To Date Filter" className="field-cont-div ">
            <input
              className="attendance-input-field width-10vw   date-field"
              placeholder="To Date"
              type="date"
              value={selectedToDate}
              onChange={(e) => setToDate(e.target.value)}
            />
            <hr className="field-cont-hr" />
          </div>
        </div>
        <div>
          <AddOfficeRent getRent={getRent} />
          <UploadOfficeRentBulk getOfficeRent={getRent} />
        </div>

      </div>


      <div className='table-css-white-background'>
        <div class="form-flex-wrap table-box height-73vh scroll-container-table ">
          <table className="table-css">

            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading-text text-left-sr">S.no.</th>
                <th className="table-heading-text text-left">Project</th>
                <th className="table-heading-text text-center">Basic Amount</th>
                <th className="table-heading-text text-center">TDS Percentage</th>
                <th className="table-heading-text text-center">TDS Amount</th>
                <th className="table-heading-text text-center">Total Amount</th>
                <th className="table-heading-text text-center">Agreement Date</th>
                <th className="table-heading-text text-center">End Date</th>
                <th className="table-heading-text text-center">Agreement Duration <br /> <span className='text-center'>(in Month)</span></th>
                <th className="table-heading-text text-left">Owner Name</th>
                <th className="table-heading-text">Documents</th>
                <th className="table-heading-text text-start">Bank Name</th>
                <th className="table-heading-text">Account Holder Name</th>
                <th className="table-heading-text">Account number</th>
                <th className="table-heading-text">IFSC Code</th>
                <th className="table-heading-text">PAN Number</th>
                <th className="table-heading-text">GST Number</th>
                <th className="table-heading-text text-left">Address</th>
                <th className="table-heading-text">Mobile no.</th>
                <th className="table-heading-text text-center" style={{ justifyContent: 'right' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {filterByOwnerNameAndDate().map((i, index) => (
                <tr key={index} className="custom-table-head-td">
                  <td className="table-body text-left-sr">{index + 1}</td>
                  <td className="table-body"><div style={{ width: "200px", whiteSpace: "normal" }}>{i.project_code}:<br />{i.project_short_name}</div></td>
                  <td className="table-body text-center">
                    {i.rent_amt ? `₹ ${formatCurrencyIndian(i.rent_amt)}` : "-"}
                  </td>
                  <td className="table-body text-center">
                    {i.tds_percentage ? `${i.tds_percentage}%` : "-"}
                  </td>
                  <td className="table-body text-center">
                    {i.tds_amt ? `₹ ${formatCurrencyIndian(i.tds_amt)}` : "-"}
                  </td>
                  <td className="table-body text-center">
                    {i.total_amt ? `₹ ${formatCurrencyIndian(i.total_amt)}` : "-"}
                  </td>
                  <td className="table-body text-center">{i.agreement_date}</td>
                  <td className="table-body text-center">
                    {i.end_date ? ` ${i.end_date}` : "-"}</td>
                  <td className="table-body text-center">{i.agreement_duration}</td>
                  <td className="table-body text-left">{i.owner_name}</td>
                  <td className="table-body text-center">
                    <DocumentModel officerent={i.id} title={"Office Rent Document"} rentData={officeRentList} getDocument={getRent} />
                  </td>
                  <td className="table-body">{i.bank_name}</td>
                  <td className="table-body">{i.account_holder_name}</td>
                  <td className="table-body">{i.account_num}</td>
                  <td className="table-body">{i.ifsc_code}</td>
                  <td className="table-body">{i.pan_card}</td>
                  <td className="table-body">{i.gst_num}</td>
                  <td className="table-body text-left"><div style={{ width: "200px", whiteSpace: "normal" }}>{i.address}</div></td>
                  <td className="table-body">{i.mobile}</td>
                  <td className="table-body flex-row  text-right" style={{ justifyContent: 'right' }} >
                    <UpdateOfficeRent i={i} getRent={getRent} />
                    <DeleteOfficeRent i={i} getRent={getRent} />
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>
      </div>

    </>)

}

export default OfficeRent;