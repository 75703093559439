import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL } from '../config/axios';
import * as XLSX from 'xlsx';
import { Modal } from 'react-bootstrap';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { formattedDateLong } from './Date';
import { formatCurrencyIndian, getMonthName, handleErrorToast, sortProjects } from './CustomFunctions';
import { AddwithWhiteCircle, Calender, CircularGrid, DeleteDustbin, DropdownArrow, Location, UpdatePencil, HourGlass, Filter, SearchBig, AddwithBlueCircle, DropdownArrowOption, DownloadIcon, UploadIcon, Attachment, Refresh } from './AllSvg';
import { DeleteSiteEmployee, UpdateSiteEmployee } from './Wfm/WorkforceCrudButton';
import usePermission from '../config/permissions';
import { Projects } from './FilterDropdown';
import { UploadOfficeRentBulk } from './SiteManagement/BulkModals';
import { DocumentModel } from './SiteManagement/ProjectDetailsCrudButtons';

const UploadAttendance = ({ getVehicleBillingDetail }) => {

  const [show, setShow] = useState(false);


  const [buffer, setBuffering] = useState(false); //buffering logic
  const initialProject = "null";
  const [project, setProject] = useState(initialProject);
  const [projectList, setProjectList] = useState([]);

  const handleShow = () => {
    // getVehicle();

    setShow(true);
  }

  const handleClose = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(false);

  }


  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

  // ? *********** Handle File UPload ***********


  const [fileData, setFileData] = useState(null);
  const [jsonData, setJsonData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming the sheet with attendance data is named "Attendance"
        const worksheet = workbook.Sheets['Attendance'];
        const rawData = XLSX.utils.sheet_to_json(worksheet);

        // Transform rawData into the required format for uploading
        const transformedData = transformAttendanceData(rawData);
        setJsonData(transformedData);

        console.log(transformedData)
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const transformAttendanceData = (rawData) => {
    let transformedData = [];

    rawData.forEach((entry) => {
      const emp_code = entry.emp_code;


      // Loop through days of the month (assuming 1 to 31)
      for (let day = 1; day <= 31; day++) {
        if (entry[day]) {
          transformedData.push({
            emp_code: emp_code,
            date: `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
            status: entry[day],  // 'P', 'A', 'L', etc.
            in_time: null,  // Add if available in the data
            out_time: null  // Add if available in the data
          });
        }
      }
    });

    return transformedData;
  };
  const [error, setErrors] = useState()
  const [errormonth, setErrorsmonth] = useState()
  const [erroryear, setErrorsyear] = useState()
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!jsonData) {
      setErrors(' Please upload an Excel file first!');
      return;
    }

    if (!selectedMonth) {
      setErrorsmonth("Please select month")
      return;
    }


    if (!selectedYear) {
      setErrorsyear("Please select Year")
      return;
    }




    try {
      const response = await axios.post(`${BASE_URL}/siteproject/attendancepostbulk/`, { data: jsonData });
      alert('Attendance uploaded successfully!');
    } catch (error) {
      console.error('Error uploading attendance:', error);
      alert('Error uploading attendance.');
    }
  };


  return (


    <div>
      <>
        <button title='Upload Attendance' className='models-button model-add float-right' style={{ margin: 0 }} onClick={handleShow}>
          <AddwithBlueCircle />
        </button>

        <Modal show={show} onHide={handleClose} dialogClassName="request-leave  width-40vw">
          <Modal.Header closeButton>
            <Modal.Title>Upload Attendance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <div className="parent-div">
              <div className="bdy-div">
                <form>
                  {/*   
                    <div title="Project" className="flex-column form-group-selectt">
                      <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                        Project<span className="required">*</span>
                      </label>
                      <br />
                      <select
                        type="text"
                        id="project"
                        name="project"
                        placeholder="project"
                        value={project}
                        onChange={(e) => setProject(e.target.value)}
                        className={`form-input form-group-selection ${errors.project ? "error" : inputState.project ? "success" : ""}`}
                      >
  
                        <option value="null">Select a Project</option>
                        {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                          <option key={index} value={i.id}>{i.project_short_name}</option>
                        ))}
                      </select>
                      <div className="form-group-selection-arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="25"
                          fill="currentColor"
                          className="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </div>
                      {errors.project && <span className="error-message font-size-text ">{errors.project}</span>}
                    </div> */}


                  <div title="Month" className="flex-column form-group-selectt">
                    <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                      Month<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="month"
                      name="month"
                      placeholder="month"
                      value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}
                      className={`form-input form-group-selection}`}
                    >

                      <option value=""> Month</option>
                      {monthOptions.map((month) => (
                        <option key={month.id} value={month.id}>{month.name}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <DropdownArrowOption />

                    </div>
                    {errormonth && <span className="error-message font-size-text ">{errormonth}</span>}
                  </div>
                  <div title="Year" className="flex-column form-group-selectt">
                    <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                      Year<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      type="text"
                      id="year"
                      name="year"
                      placeholder="year"
                      value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}
                      className={`form-input form-group-selection }`}
                    >

                      <option value=""> Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                    <div className="form-group-selection-arrow">
                      <DropdownArrowOption />

                    </div>
                    {erroryear && <span className="error-message font-size-text ">{erroryear}</span>}
                  </div>

                  <div className="flex-column field-cont-div">
                    <label htmlFor="document_file" className="form-labels font-weight500 font-size-heading" title="Upload Bulk Attendance">
                      Upload Excel File
                    </label>
                    <label
                      htmlFor="document_file"
                      className="custom-file-upload"

                    >
                      {/* <div className="align-center pdf-input">
                        <Attachment />
                      </div> */}
                    </label>
                    <input type="file" accept=".xlsx, .xls" name="file" onChange={handleFileUpload} />
                    {error && <span className="error-message font-size-text ">{error}</span>}
                  </div>

                  <div className="button-models">

                    <button className="model-button-download model-button font-weight500" onClick={(e) => handleSubmit(e)}>Upload</button>
                  </div>


                </form>
              </div></div>
          </Modal.Body>
        </Modal>
      </>

    </div>


  )
}




const Attendanceee = () => {
  const [VehicleBillingDetails, setVehicleBillingDetails] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [project, setProject] = useState("null");
  const [search, setSearch] = useState("")
  const [projectList, setProjectList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterMonth, setFilterMonth] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/project/`
      );
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setProjectList(sortedProjects);

    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const getVehicleBillingDetail = async () => {
    setBuffering(true);
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/filterbyprojvehiclebilling/${project}/`);
      setVehicleBillingDetails(response.data);
    }
    catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  }
  useEffect(() => {
    getVehicleBillingDetail();
    getProjects();
  }, [project]);

  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];
  const getMonthName = (monthNumber) => {
    const month = monthOptions.find(m => m.id.toString() === monthNumber);
    return month ? month.name : '';
  };

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);



  useEffect(() => {
    applyFilters();
  }, [search, filterMonth, filterYear, VehicleBillingDetails])


  const applyFilters = () => {
    let filteredData = VehicleBillingDetails;
    if (search !== "") {
      const lowercaseSelectedName = search.toLowerCase();
      filteredData = filteredData.filter(project => {
        const name = project.vehicle_name.toString().toLowerCase().includes(lowercaseSelectedName);
        const owner_name = project.vehicleownerdetails.toString().toLowerCase().includes(lowercaseSelectedName);
        return name || owner_name;
      });
    }
    if (filterMonth) {
      filteredData = filteredData.filter(project => project.month === filterMonth);

    }
    if (filterYear) {
      filteredData = filteredData.filter(project => project.year === filterYear);

    }






    setFilteredData(filteredData);
  };
  return (
    <div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-subcontainer margin">
        <div className="field-cont width-100vw justify-between">
          <div className="flex-row">
            <div title='Month Filter' className="field-cont-div">
              <Calender />

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={filterMonth}
                onChange={(e) => setFilterMonth(e.target.value)}
              >
                <option value=""> Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div title='Year Filter' className="field-cont-div">
              <HourGlass />

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={filterYear}
                onChange={(e) => setFilterYear(e.target.value)}
              >
                <option value="">Select Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div title="Project Filter " className="field-cont-div ">
              <Filter />
              <select
                id="project"
                name="project"
                placeholder="Project"
                value={project}
                onChange={(e) => setProject(e.target.value)}
                className={`attendance-input-field width-15vw`}
              >

                <option value="null">Select Project</option>
                {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                  <option key={index} value={i.id}>{i.project_short_name}</option>
                ))}
              </select>
              <hr className="field-cont-hr" />

            </div>

            <div title='Search Filter' className="field-cont-div">
              <SearchBig />
              <input className="attendance-input-field"
                placeholder='Search Name'
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)} />
              <hr className="field-cont-hr" />
            </div>
          </div>

          <div>
            <UploadAttendance getVehicleBillingDetail={getVehicleBillingDetail} />

          </div>
        </div>
      </div>

    </div>
  )

}


const AddSiteAttendence = ({ getAttendance, i }) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [buffer, setBuffering] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);


  const [formData, setFormData] = useState({
    employee: "",
    total_days: "",
    man_months: "",
    month: "",
    year: "",
  })
  const handleShow = () => {
    setShow(true);
    getEmployee();
  }
  const handleClose = () => {
    setFormData({
      employee: "",
      total_days: "",
      man_months: "",
      month: "",
      year: "",
    })
    setErrors({});
    setShow(false);
  }

  const getEmployee = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const ress = await axios.get(`${BASE_URL}/siteproject/employee/`);

      setEmployeeData(ress.data)


    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  }


  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const [isSubmitted, setIsSubmitted] = useState(false);
  const validateAttendenceForm = () => {
    const newErrors = {};
    const requiredFields = [
      "employee",
      "total_days",
      "man_months",
      "month",
      "year",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value) {
        delete updatedErrors[name];
      }
      return updatedErrors;
    });

  };
  const handlePost = async (e) => {
    e.preventDefault();
    if (validateAttendenceForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {

        const res = await axios.post(`${BASE_URL}/siteproject/attendancesites/`, formData);

        if (res.status === 200) {
          toast.success("Attendance Added Successfully");
          await getAttendance();
          handleClose();
        } else {
          toast.error(`Error: ${res.statusText}`);
        }
      } catch (err) {
        handleErrorToast(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const years = Array.from({ length: 20 }, (v, i) => new Date().getFullYear() - i);

  return (
    <>
      <button title="Add Site Attendance" className="upload-svg" onClick={handleShow}>
        <AddwithWhiteCircle />
        {" "} Site Attendance
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Add Attendance Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <div className="parent-div">
            <div className="bdy-div">
              <form onSubmit={handlePost}>
                <div title="Employee" className="flex-column form-group-selectt">
                  <label htmlFor="employee" className="form-labels  font-weight500    font-size-subheading">
                    Employee<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="employee"
                    title="Employee"
                    name="employee"
                    placeholder="employee"
                    value={formData.employee}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.employee ? "error" : inputState.employee ? "success" : ""}`}
                  >
                    <option value="">Select an Employee</option>
                    {employeeData?.sort((a, b) => a.emp_code.localeCompare(b.emp_code, 'en', { ignorePunctuation: true })).map((i, index) => (
                      <option key={index} value={i.id}>{i.emp_code}-{i.name}</option>
                    ))}
                  </select>
                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                  {errors.employee && <span className="error-message font-size-text ">{errors.employee}</span>}
                </div>
                <div title="Total Days" className="flex-column">
                  <label htmlFor="total_days" className="form-labels  font-weight500    font-size-subheading">
                    Total Days<span className="required">*</span>
                  </label>
                  <input
                    maxLength={20}
                    type="text"
                    id="total_days"
                    name="total_days"
                    placeholder="Total Days"
                    onChange={handleInputChange}
                    value={formData.total_days}
                    className={`form-input ${errors.total_days
                      ? "error"
                      : inputState.total_days
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.total_days && (
                    <span className="error-message font-size-text ">{errors.total_days}</span>
                  )}
                </div>
                <div title="Man Months" className="flex-column">
                  <label htmlFor="man_months" className="form-labels  font-weight500    font-size-subheading">
                    Man Months<span className="required">*</span>
                  </label>
                  <input
                    maxLength={20}
                    type="text"
                    id="man_months"
                    name="man_months"
                    placeholder="Man Months"
                    onChange={handleInputChange}
                    value={formData.man_months}
                    className={`form-input ${errors.man_months
                      ? "error"
                      : inputState.man_months
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.man_months && (
                    <span className="error-message font-size-text ">{errors.man_months}</span>
                  )}
                </div>
                <div title="Month" className="flex-column form-group-selectt">
                  <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                    Month<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="month"
                    name="month"
                    value={formData.month}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.month ? "error" : inputState.month ? "success" : ""}`}
                  >
                    <option value="">Select a Month</option>
                    {months.map((month, index) => (
                      <option key={index} value={month.value}>
                        {month.name}
                      </option>
                    ))}
                  </select>
                  <DropdownArrow />
                  <div>
                    {errors.month && (
                      <span className="error-message font-size-text ">{errors.month}</span>
                    )}
                  </div>
                </div>
                <div title="Year" className="flex-column form-group-selectt">
                  <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                    Year<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="year"
                    name="year"
                    value={formData.year}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.year ? "error" : inputState.year ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Year</option>
                    {years.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <DropdownArrow />
                  <div>
                    {errors.year && (
                      <span className="error-message font-size-text ">{errors.year}</span>
                    )}
                  </div>
                </div>



                <div className="button-models">
                  <button className="model-button  font-weight500    model-button-leave font-size-heading" type="submit" disabled={loading}>
                    {loading ? 'Adding...' : 'Add'}
                  </button>
                </div>
                <p className="error-message font-size-text">
                  {isSubmitted && Object.keys(errors).length > 0 && (
                    Object.keys(errors).length > 5 ? (
                      <h5 className="text-center">Please fill all mandatory fields!</h5>
                    ) : (

                      <h6 className="text-center">
                        {Object.keys(errors).map((field) =>
                          field.charAt(0).toUpperCase() + field.slice(1)
                        ).join(', ') + ' are required!'}
                      </h6>
                    )
                  )}
                </p>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

// Projects toggleProject={project}


const GetAttendance = () => {

  const companyId = sessionStorage.getItem("sitemanagement_project_sub_company");
  const projectID = sessionStorage.getItem("sitemanagement_project_site_prcode")


  const [project, setProject] = useState(projectID ? projectID : null);
  const [subCompany, setSubCompany] = useState(companyId ? companyId : null);

  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [projectList, setProjectList] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [siteAttendanceList, setSiteAttendanceList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const getAttendance = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/attendancesites/`);
      setSiteAttendanceList(res.data);

    } catch (err) {
      handleErrorToast(err);

    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const getproject = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/${subCompany}/`);

      const splitCodeParts = (code) => {
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/");
        const lastPart = parts.pop();
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
        if (match) {
          return {
            firstPart,
            numericPart: match[1] ? parseInt(match[1], 10) : 0,
            alphaPart: match[2]
          };
        }
        return { firstPart, numericPart: 0, alphaPart: "" };
      };

      const sortedList = response.data.sort((a, b) => {
        const aParts = splitCodeParts(a.site_prcode);
        const bParts = splitCodeParts(b.site_prcode);
        if (aParts.firstPart !== bParts.firstPart) {
          return aParts.firstPart.localeCompare(bParts.firstPart);
        }
        if (aParts.alphaPart !== bParts.alphaPart) {
          return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }
        return aParts.numericPart - bParts.numericPart;
      });

      // setProjectList(response.data)
      setProjectList(sortedList)

    } catch (error) {
      alert(error);
    }
  }
  useEffect(() => {
    getproject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCompany]);
  // const getEmployee=()=>{
  //   try{
  //     const response=await axios.get(`/siteproject/employee/`);
  //   }
  // }
  const applyFilters = () => {
    let filteredData = siteAttendanceList;

    // Filter by name or code
    if (selectedName !== "") {
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((employee) => {
        const nameMatch = employee.employee_name
          ? employee.employee_name.toLowerCase().includes(lowercaseSelectedName)
          : false;
        const empCodeMatch = employee.emp_code
          ? employee.emp_code.toString().includes(selectedName)
          : false;

        return nameMatch || empCodeMatch;
      });
    }

    // **Fix: Filter by site_prcode (selected project)**
    if (project && project !== "null") {
      filteredData = filteredData.filter((attendance) => attendance.project_code === project);
    }

    // Filter by selected month and year
    if (selectMonth) {
      filteredData = filteredData.filter((employee) => parseInt(employee.month) === parseInt(selectMonth));
    }

    if (yearr) {
      filteredData = filteredData.filter((employee) => parseInt(employee.year) === parseInt(yearr));
    }

    // Set the filtered data
    setFilteredData(filteredData);
  };

  const handleProjectChange = (e) => setProject(e.target.value);

  console.log("project")
  console.log(project)



  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 10; // starting 20 years ago
  const endYear = currentYear; // up to 20 years in the future
  const years = (Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index));
  // Update the useEffect to call applyFilters whenever selectedName or siteAttendanceList changes
  useEffect(() => {
    applyFilters();
  }, [selectedName, siteAttendanceList, project]);
  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const [selectMonth, setSelectedMonth] = useState("");
  const day = today.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[selectMonth - 1];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);
  const [yearr, setYearr] = useState();

  useEffect(() => {
    getAttendance();
    getproject();
  }, [selectMonth, yearr, monthName, project, subCompany]);

  const [subcompanyList, setSubCompanyList] = useState([]);
  const getSubCompany = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSubCompanyList(response.data);
    } catch (error) {
      alert(error);
    }
  }

  const siteRole = sessionStorage.getItem("sitemanagement_role")


  return (
    <>
      <div >
        <div className="attendance-subcont">

          <div className="field-cont">
            <div className="field-cont-div">


              {siteRole === "SiteManagement" ? "" :
                <>
                  {/* <Projects toggleProject={project} setToggleProject={setProject} subcompany={subCompany} /> */}
                  <div title="Project Dropdown " className="field-cont-div">
                    <CircularGrid />
                    <select
                      onChange={handleProjectChange}
                      value={project || 'null'}
                      className={`attendance-input-field width-10vw`}
                    >
                      <option value="null">Project</option>
                      {projectList
                        // ?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true }))
                        .map((i, index) => (
                          <option key={index} value={i.site_prcode}>
                            {i.site_prcode}-{i.project_short_name}
                          </option>
                        ))}
                    </select>
                    <hr className="field-cont-hr" />
                  </div>
                </>
              }

              <div className="field-cont-div">
                <CircularGrid />
                <input
                  className="attendance-input-field width-10vw  "
                  placeholder="Name or Code"
                  type="text"
                  value={selectedName}
                  onChange={(e) => setSelectedName(e.target.value)}
                />
                <hr className="field-cont-hr" />
              </div>
              <div title='Year Filter' className="field-cont-div">
                <CircularGrid />

                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={yearr}
                  onChange={(e) => setYearr(e.target.value)}
                >
                  <option value="">Select Year</option>
                  {years.reverse().map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>

                <hr className="field-cont-hr" />
              </div>
              <div className="field-cont-div">
                <CircularGrid />
                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={selectMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  <option value="">Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                <hr className="field-cont-hr" />
              </div>
            </div>
          </div>
          <div className="field-cont">
            <AddSiteAttendence getAttendance={getAttendance} />
            <AddBulkboqAttendance getAttendance={getAttendance} />
          </div>
        </div>
      </div>
      <div className="table-css-white-background">

        <div className="form-flex-wrap table-box height-73vh scroll-container-table ">
          <table className="table-css">
            <thead className='table-heading'>
              <tr className="custom-table-head-tr">
                <th className="table-heading-text text-left-sr">S.no.</th>
                <th className="table-heading-text">Employee Name</th>
                <th className="table-heading-text">Project Name</th>
                <th className="table-heading-text text-center">Month</th>
                <th className="table-heading-text">Year</th>
                <th className="table-heading-text text-center">Total Days</th>
                <th className="table-heading-text text-center">Man Months</th>

                <th className="table-heading-text text-right" style={{ justifyContent: 'right' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData?.map((i, index) => (
                <React.Fragment key={index}>
                  {/* <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr> */}
                  <tr className="custom-table-head-td">
                    <td className="table-body">{index + 1}</td>
                    <td className="table-body">{i.employee_name}</td>
                    <td className="table-body">{i.project_short_name}</td>
                    <td className="table-body text-center">{i.month}</td>
                    <td className="table-body">{i.year}</td>
                    <td className="table-body text-center">{i.total_days}</td>
                    <td className="table-body text-center">{i.man_months}</td>
                    <td className="table-body text-right" style={{ justifyContent: 'right' }}>
                      <DeleteboqAttandance getAttendance={getAttendance} i={i} />
                    </td>



                    {/* <td className="align-right flex-row ">
                    <UpdateSiteEmployee
                      i={i}
                      getSiteEmployee={getSiteEmployee}
                      siteProjectList={siteProjectList}
                      siteDepartmentList={siteDepartmentList}
                      siteSubCompanyList={siteSubCompanyList}
                      projectId={projectId}
                    />
                    <DeleteSiteEmployee
                      i={i}
                      getSiteEmployee={getSiteEmployee}

                    />
                  </td> */}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>


      </div>
    </>
  )

}










const UploadAttendanceBulk2 = ({ id, getAttendanceData }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);



  const [fileName, setFileName] = useState("");
  const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
  const [docErrors, setDocErrors] = useState({});
  const [docInputState, setDocInputState] = useState({});

  const [jsonData, setJsonData] = useState(null);


  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const [monthh, setMonthh] = useState(`${month}`);
  const day = today.getDate();

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);


  const bulktableRef = useRef(null);
  const handleDownload = () => {
    const table = bulktableRef.current;
    const wb = XLSX.utils.table_to_book(table, { sheet: "Attendance Sheet" });
    const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

    const blob = new Blob([s2ab(wbout)], {
      type: "application/vnd.ms-excel",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Attendance Sheet Format.xls";
    a.click();
    window.URL.revokeObjectURL(url);
  };


  // Utility function to convert binary string to ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };


  const handleClose = () => {
    setFileName("")
    setExcelJson(null)
    setDocErrors({})
    setShow(false)
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    console.log(file)

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);

        const workbook = XLSX.read(data, { type: 'array' });

        console.log(workbook)

        // Assuming the sheet with attendance data is named "Attendance"
        const worksheet = workbook.Sheets['Attendance Sheet'];

        const rawData = XLSX.utils.sheet_to_json(worksheet);
        console.log(rawData)
        // Transform rawData into the required format for uploading
        const transformedData = transformAttendanceData(rawData);
        setJsonData(transformedData);
        console.log("demo")

        console.log(transformedData)
      };
      reader.readAsArrayBuffer(file);
      setFileName(file.name);
    }
  };

  const transformAttendanceData = (rawData) => {
    let transformedData = [];

    rawData.forEach((entry) => {
      const emp_code = entry.emp_code;


      // Loop through days of the month (assuming 1 to 31)
      for (let day = 1; day <= 31; day++) {
        if (entry[day]) {
          transformedData.push({
            emp_code: emp_code,
            date: `${yearr}-${monthh.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
            status: entry[day],  // 'P', 'A', 'L', etc.
            in_time: null,  // Add if available in the data
            out_time: null  // Add if available in the data
          });
        }
      }
    });

    return transformedData;
  };

  console.log(jsonData)

  const handleSubmit = async () => {
    if (!jsonData) {
      alert("Please upload an Excel file first!");
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/siteproject/attendancepostbulk/`, { data: jsonData });
      alert('Attendance uploaded successfully!');
    } catch (error) {
      console.error('Error uploading attendance:', error);
      alert('Error uploading attendance.');
    }
  };

  // Error handling function for API errors
  const handleError = (err, loadingToastId) => {
    toast.dismiss(loadingToastId);
    handleErrorToast(err);
  };





  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  // /attandance/employattandancessheet/222/2/2023/

  // const [monthName, setMonthName] = useState(`${month}`);

  const [yearr, setYearr] = useState(`${year}`);


  const [project, setProject] = useState("null");

  useEffect(() => {
    getAllattendancedata();
  }, [monthh, yearr]);


  const [allattendance, setAllattendance] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic




  // ?  ************** Validation End **************  ! //
  const [projectList, setProjectList] = useState([]);

  const getproject = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/null/`);

      const splitCodeParts = (code) => {
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/");
        const lastPart = parts.pop();
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
        if (match) {
          return {
            firstPart,
            numericPart: match[1] ? parseInt(match[1], 10) : 0,
            alphaPart: match[2]
          };
        }
        return { firstPart, numericPart: 0, alphaPart: "" };
      };

      const sortedList = response.data.sort((a, b) => {
        const aParts = splitCodeParts(a.site_prcode);
        const bParts = splitCodeParts(b.site_prcode);
        if (aParts.firstPart !== bParts.firstPart) {
          return aParts.firstPart.localeCompare(bParts.firstPart);
        }
        if (aParts.alphaPart !== bParts.alphaPart) {
          return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }
        return aParts.numericPart - bParts.numericPart;
      });

      // setProjectList(response.data)
      setProjectList(sortedList)

    } catch (error) {
      alert(error);
    }
  }

  let getAllattendancedata = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/monthlyattendnaceall/${monthh}/${yearr}/${project}/`
      );

      const getAlphaNumericParts = (code) => {
        const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
        if (match) {
          return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
        } else {
          return { alphaPart: '', numericPart: 0 };
        }
      };

      const sortedList = res.data.sort((a, b) => {
        const { alphaPart: alphaA, numericPart: numericA } = getAlphaNumericParts(a.emp_code);
        const { alphaPart: alphaB, numericPart: numericB } = getAlphaNumericParts(b.emp_code);

        if (alphaA !== alphaB) {
          return alphaA.localeCompare(alphaB); // Sort alphabetically
        } else {
          return numericA - numericB; // Then sort numerically
        }
      });
      setAllattendance(sortedList);
      // setAllattendance(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);

    } finally {
      setBuffering(false); //buffering logic
    }
  };

  const refreshdata = async () => {
    setBuffering(true); //buffering logic
    await getAllattendancedata();
    setBuffering(false); //buffering logic
  };

  const getAllDatesOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const dates = [];
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      dates.push({ date: formattedDate });
    }

    return dates;
  };

  const dates = getAllDatesOfMonth(monthh, yearr).map((date, index) => ({
    ...date,
    index,
  }));

  const getAllDatessOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
    }

    return datees;
  };

  const datees = getAllDatessOfMonth(monthh, yearr); // get all dates for March 2023

  // *********** Filter Logic ***********




  useEffect(() => {
    getproject()
  }, [])



  useEffect(() => {
    getAllattendancedata()
  }, [project, yearr, monthh])



  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  const tableRef = useRef(null);





  return (
    <>
      <button
        title="Upload Bulk Attendance"
        className="model-button font-weight500 model-button-print"
        onClick={handleShow}
        style={{ margin: "2px" }}
      >
        <UploadIcon />
      </button>

      <Modal show={show} dialogClassName="ourcompany-model">
        <Modal.Header className="task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Upload Bulk Attendance Data in format:</Modal.Title>

          <button onClick={handleDownload} className="model-button-download model-button font-weight500" style={{ marginLeft: "35vw" }}>
            <DownloadIcon />
            {"  "} Format
          </button>
        </Modal.Header>
        <Modal.Body>



          <form className="register-form">
            <div className="form-flex-wrap">

              <div title="Project" className="flex-column form-group-selectt">
                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                  Project<span className="required">*</span>
                </label>
                <br />
                <select
                  type="text"
                  id="project"
                  name="project"
                  placeholder="project"
                  value={project}
                  onChange={(e) => setProject(e.target.value)}
                  className='form-input form-group-selection'
                >

                  <option value="null">Select a Project</option>
                  {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.project_short_name}</option>
                  ))}
                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
              </div>


              <div title="Month" className="flex-column form-group-selectt">
                <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                  Month<span className="required">*</span>
                </label>
                <br />
                <select
                  type="text"
                  id="month"
                  name="month"
                  placeholder="month"
                  value={monthh} onChange={(e) => setMonthh(e.target.value)}
                  className={`form-input-date width-15vw`}
                >

                  <option value="">Select a month</option>
                  {monthOptions.map((month) => (
                    <option key={month.id} value={month.id}>{month.name}</option>
                  ))}
                </select>


              </div>

              <div title="Year" className="flex-column form-group-selectt ">
                <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                  Year<span className="required">*</span>
                </label>
                <br />
                <select
                  type="text"
                  id="year"
                  name="year"
                  placeholder="year"
                  value={yearr} onChange={(e) => setYearr(e.target.value)}
                  className={`form-input-date width-10vw`}
                >

                  <option value="">Select a Year</option>
                  {years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
              </div>
            </div>
          </form>





          <div className="table-css-white-background" style={{ display: "none" }}>
            <table
              ref={bulktableRef}
              id="my-table"
              style={{
                borderColor: "#323232",
                borderRadius: "5px",
                padding: "0px 5px",
                borderWidth: "1px",
                // marginLeft: "10px",
                // zoom: "70%",
              }}
            >
              <thead

              >

                <tr>
                  <th className="summary-excel-tab br-5">emp_code</th>
                  <th className="summary-excel-tab br-5">Name</th>
                  <th className="summary-excel-tab br-5">Joining Date</th>


                  <th className="summary-excel-tab br-5"> Designation Name</th>




                  {datees.map((date) => (
                    <th className="summary-excel-tab br-5" key={date.date}>
                      {date.date}
                    </th>
                  ))}



                </tr>
              </thead>

              {
                // buffer ? <div className="spinner"></div> :
                <tbody>
                  {allattendance
                    .sort((a, b) => a.emp_code - b.emp_code)
                    .map((data, index) => {


                      const inactivename = data.employee_status === "inactive" ? "#ff1212" : "black";


                      return (
                        <>
                          <tr
                            style={{
                              backgroundColor:
                                index === selectedIndex ? "yellow" : "white",
                              color: inactivename,
                            }}
                            onClick={() => handleClick(index)}
                          >
                            <td className="summary-excel-tab br-5">{data.emp_code}</td>
                            <td className="summary-excel-tab br-5">{data.name}</td>
                            <td className="summary-excel-tab br-5">{data.joining_date}</td>
                            <td className="summary-excel-tab br-5">{data.desigantion_name}</td>

                            {dates.map((date) => {
                              // Find the attendance record for the given date
                              const attendanceForDate = data.attendances.find(
                                (attendance) => attendance.date === date.date
                              );

                              const attendanceStatus = attendanceForDate
                                ? attendanceForDate.status === "P"
                                  ? "present"
                                  : attendanceForDate.status === "A"
                                    ? "absent"
                                    : attendanceForDate.status === "L"
                                      ? "leave"
                                      : attendanceForDate.status === "W"
                                        ? "weeklyoff"
                                        : attendanceForDate.status === "H"
                                          ? "holiday"
                                          : attendanceForDate.status.slice(0, 2)
                                : "-";


                              // Define the variables for In Time, Out Time, and Status
                              const inTime = attendanceForDate?.in_time ?? "-";
                              const outTime = attendanceForDate?.out_time ?? "-";
                              const attstat = attendanceForDate?.status ?? "-";

                              return (
                                <td
                                  key={date.date}
                                  style={{
                                    backgroundColor: index === selectedIndex ? "yellow" : "",
                                    color: index === selectedIndex ? "black" : "",
                                  }}
                                  data-status={attendanceStatus}
                                  className="behavior summary-excel-tab"
                                  title={`Date: ${date.date}\nIn Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\n`}
                                >
                                  {attstat}
                                </td>
                              );
                            })}



                            {/* <td
                          className="summary-excel-tab br-5"
                          style={{ backgroundColor: bgtotalpaiddays }}
                        >
                          {data.total}
                        </td> */}
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              }
            </table>

          </div>



          <div className="flex-column field-cont-div">
            <label htmlFor="document_file" className="form-labels font-weight500 font-size-heading" title="Upload Bulk Attendance">
              Upload File
            </label>
            <label
              htmlFor="document_file"
              className={` custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                }`}
            >
              <div className={`align-center pdf-input  ${docErrors.document_file ? 'error' : ''}`}>
                <Attachment />
              </div>
            </label>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              id="document_file"
              name="document_file"
              className="file-inputs align-center"
              style={{ display: "none", position: "relative" }} />
            {fileName && <p className="file-name align-center">{fileName}</p>}
            <br />
            {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
          </div>

          <div className="button-models">
            <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
              Cancel
            </button>
            <button className="model-button-download model-button font-weight500" onClick={handleSubmit}>
              <UploadIcon />
              {"  "} Upload
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};






const AddBulkboqAttendance = ({ getAttendance, i }) => {

  const [show, setShow] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [employeeList, setEmployeeData] = useState([]);
  const [project, setProject] = useState("null");
  const [jsonData, setJsonData] = useState([]);
  const [fileName, setFileName] = useState("");
  const today = new Date();
  const [docErrors, setDocErrors] = useState({});
  const [docInputState, setDocInputState] = useState({});
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const [monthh, setMonthh] = useState(`${month}`);
  const [yearr, setYearr] = useState(`${year}`);
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 20; // starting 20 years ago
  const endYear = currentYear + 20; // up to 20 years in the future
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
  const [formData, setFormData] = useState([
    {

    }
  ])


  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" }
  ];

  const handleShow = () => {
    setShow(true);
    getProject();
  }
  const handleClose = () => {
    setShow(false);
    setProject("null");
    setMonthh(month);
    setYearr(year);
    setFileName("");
  }



  const getProject = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/project/`);
      setProjectList(res.data);

    } catch (err) {
      handleErrorToast(err);
    }
  }
  const getEmployee = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/employeefilterbyproject/${project}/null/`);
      setEmployeeData(res.data);

    } catch (err) {

    }
  }
  useEffect(() => {
    getEmployee();
  }, [project]);

  const empIds = employeeList.map((item) => item.id);


  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const bulktableRef = useRef(null);
  const handleDownload = () => {
    const table = bulktableRef.current;
    const wb = XLSX.utils.table_to_book(table, { sheet: "BOQ Attendance Sheet" });
    const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

    const blob = new Blob([s2ab(wbout)], {
      type: "application/vnd.ms-excel",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "BOQ Attendance Sheet Format.xls";
    a.click();
    window.URL.revokeObjectURL(url);
  };



  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Get the first sheet name dynamically, if needed
        const sheetName = workbook.SheetNames[0];
        console.log(`Sheet name: ${sheetName}`);

        // Access the sheet dynamically or by name, if known
        const worksheet = workbook.Sheets[sheetName];
        if (!worksheet) {
          console.error("Worksheet not found. Please check the sheet name.");
          return;
        }

        // Convert worksheet to JSON
        const rawData = XLSX.utils.sheet_to_json(worksheet);
        console.log("Raw data from sheet:", rawData);

        // Transform rawData as needed
        const transformedData = rawData.map(row => ({
          emp_code: row['emp_code'],
          name: row['Name'],
          total_days: row['Days'],
          man_months: row['Man Months'],
          month: row['Month'],
          year: row['Year'],
        }));

        const combinedData = transformedData.map((item, index) => ({
          ...item,
          employee: empIds[index] || null,  // Ensure each row gets a unique emp_id from empIds array
        }));
        // Update state with transformed data
        setJsonData(combinedData);
        setFileName(file.name);

      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        alert("There was an error reading the file.");
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const [loading, setLoading] = useState(false); //loading logic
  const [errors, SetErrors] = useState('');
  const handleSubmit = async (e) => {

    e.preventDefault();
    console.log(project, project === "null")
    if (!project || project === "null") {
      SetErrors("Please select Project")
      return;
    }
    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait...");

    try {
      const res = await axios.post(`${BASE_URL}/siteproject/attendancsitebulk/`, jsonData
      );
      if (res.status === 201) {
        await getAttendance();

        handleClose();
        toast.dismiss(loadingToastId);
        SetErrors('');
        toast.success("BOQ Attendance added successfully!");

      } else {
        toast.dismiss(loadingToastId);
        toast.error("Failed to add Data!");
        SetErrors('');
      }

    } catch (err) {
      handleErrorToast(err);
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };



  return (
    <>
      <button
        title='Add Bulk BOQ Attendance'
        onClick={handleShow}
        className='upload-svg'
      >
        <UploadIcon />
      </button>

      <Modal show={show} dialogClassName='ourcompany-model'>
        <Modal.Header className="task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Upload BOQ Bulk Attendance Data in format:</Modal.Title>
          {project === "null" ? <></> :
            <button onClick={handleDownload} className="model-button-download model-button font-weight500" style={{ marginLeft: "35vw" }}>
              <DownloadIcon />
              {"  "} Format
            </button>
          }
        </Modal.Header>
        <Modal.Body>
          <form className='register-form'>
            <div className="form-flex-wrap">
              <div title="Project" className="flex-column form-group-selectt">
                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                  Project<span className="required">*</span>   {errors && <span className="error-message font-size-text ">{errors}</span>}
                </label>
                <br />
                <select
                  type="text"
                  id="project"
                  name="project"
                  placeholder="project"
                  value={project}
                  onChange={(e) => setProject(e.target.value)}
                  className='form-input form-group-selection'
                >

                  <option value="null">Select a Project</option>
                  {projectList?.sort((a, b) => a.project_short_name.localeCompare(b.project_short_name, 'en', { ignorePunctuation: true })).map((i, index) => (
                    <option key={index} value={i.id}>{i.project_short_name}</option>
                  ))}
                </select>

                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>

              </div>

              <div title="Month" className="flex-column form-group-selectt">
                <label htmlFor="month" className="form-labels  font-weight500    font-size-subheading">
                  Month<span className="required">*</span>
                </label>
                <br />
                <select
                  type="text"
                  id="month"
                  name="month"
                  placeholder="month"
                  value={monthh} onChange={(e) => setMonthh(e.target.value)}
                  className={`form-input-date width-15vw`}
                >

                  <option value="">Select a month</option>
                  {monthOptions.map((month) => (
                    <option key={month.id} value={month.id}>{month.name}</option>
                  ))}
                </select>


              </div>

              <div title="Year" className="flex-column form-group-selectt ">
                <label htmlFor="year" className="form-labels  font-weight500    font-size-subheading">
                  Year<span className="required">*</span>
                </label>
                <br />
                <select
                  type="text"
                  id="year"
                  name="year"
                  placeholder="year"
                  value={yearr} onChange={(e) => setYearr(e.target.value)}
                  className={`form-input-date width-10vw`}
                >

                  <option value="">Select a Year</option>
                  {years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
              </div>
            </div>
          </form>

          <div className="table-css-white-background" style={{ display: "none" }}>
            <table
              ref={bulktableRef}
              id="my-table"
              style={{
                borderColor: "#323232",
                borderRadius: "5px",
                padding: "0px 5px",
                borderWidth: "1px",
                // marginLeft: "10px",
                // zoom: "70%",
              }}
            >
              <thead

              >

                <tr>
                  <th className="summary-excel-tab br-5">emp_code</th>
                  <th className="summary-excel-tab br-5">Name</th>
                  <th className="summary-excel-tab br-5">Month</th>
                  <th className="summary-excel-tab br-5">Year</th>
                  <th className="summary-excel-tab br-5">Days</th>
                  <th className="summary-excel-tab br-5">Man Months</th>






                  {/* {datees.map((date) => (
                    <th className="summary-excel-tab br-5" key={date.date}>
                      {date.date}
                    </th>
                  ))} */}



                </tr>
              </thead>

              {
                // buffer ? <div className="spinner"></div> :
                <tbody>
                  {employeeList
                    .sort((a, b) => a.emp_code - b.emp_code)
                    .map((data, index) => {


                      const inactivename = data.employee_status === "inactive" ? "#ff1212" : "black";


                      return (
                        <>
                          <tr
                          // style={{
                          //   backgroundColor:
                          //     index === selectedIndex ? "yellow" : "white",
                          //   color: inactivename,
                          // }}
                          // onClick={() => handleClick(index)}
                          >
                            <td className="summary-excel-tab br-5">{data.emp_code}</td>
                            <td className="summary-excel-tab br-5">{data.name}</td>
                            <td className="summary-excel-tab br-5 text-center">{monthh}</td>
                            <td className="summary-excel-tab br-5 text-center">{yearr}</td>
                            <td className="summary-excel-tab br-5 text-center">{"-"}</td>
                            <td className="summary-excel-tab br-5 text-center">{"-"}</td>


                            {/* {employeeList.map((date) => {
                              // Find the attendance record for the given date
                              const attendanceForDate = data.attendances.find(
                                (attendance) => attendance.date === date.date
                              );

                              const attendanceStatus = attendanceForDate
                                ? attendanceForDate.status === "P"
                                  ? "present"
                                  : attendanceForDate.status === "A"
                                    ? "absent"
                                    : attendanceForDate.status === "L"
                                      ? "leave"
                                      : attendanceForDate.status === "W"
                                        ? "weeklyoff"
                                        : attendanceForDate.status === "H"
                                          ? "holiday"
                                          : attendanceForDate.status.slice(0, 2)
                                : "-";


                              // Define the variables for In Time, Out Time, and Status
                              const inTime = attendanceForDate?.in_time ?? "-";
                              const outTime = attendanceForDate?.out_time ?? "-";
                              const attstat = attendanceForDate?.status ?? "-";

                              return (
                                <td
                                  // key={date.date}
                                  // style={{
                                  //   backgroundColor: index === selectedIndex ? "yellow" : "",
                                  //   color: index === selectedIndex ? "black" : "",
                                  // }}
                                  // data-status={attendanceStatus}
                                  // className="behavior summary-excel-tab"
                                  // title={`Date: ${date.date}\nIn Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\n`}
                                >
                                  {attstat}
                                </td>
                              );
                            })} */}
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              }
            </table>

          </div>
          <div className="flex-column field-cont-div">
            <label htmlFor="document_file" className="form-labels font-weight500 font-size-heading" title="Upload Bulk Attendance">
              Upload File
            </label>
            <label
              htmlFor="document_file"
              className={`custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                }`}
            >
              <div className={`align-center pdf-input  ${docErrors.document_file ? 'error' : ''}`}>
                <Attachment />
              </div>
            </label>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              id="document_file"
              name="document_file"
              className="file-inputs align-center"
              style={{ display: "none", position: "relative" }} />
            {fileName && <p className="file-name align-center">{fileName}</p>}
            <br />
            {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
          </div>
          <div className="button-models">
            <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
              Cancel
            </button>
            <button className="model-button-download model-button font-weight500" onClick={handleSubmit}>
              <UploadIcon />
              {"  "} Upload
            </button>
          </div>

        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </>
  )
}

const DeleteboqAttandance = ({ i, getAttendance }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/attendancesitesupdate/${i.id}/`
      );

      if (res.status === 200) {
        await getAttendance();
        handleClose();
        toast.dismiss(loadingToastId);
        toast.success("Data deleted successfully!");
      } else {
        toast.dismiss(loadingToastId);
        toast.error("Failed to delete data!");
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button
        title="Delete Boq Attendance"
        className="model-delete-button"
        onClick={handleShow}
      >
        <DeleteDustbin />
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete BOQ Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete attandance data of {i.employee_name}
            <div className="button-models">
              <button
                className="model-button   font-weight500    "
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>

        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

const Attendance = () => {

  // console.log(indexRow)
  const siteRole = sessionStorage.getItem("sitemanagement_role")

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const [monthh, setMonthh] = useState(`${month}`);
  const day = today.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[monthh - 1];

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate);

  // /attandance/employattandancessheet/222/2/2023/

  // const [monthName, setMonthName] = useState(`${month}`);

  const [yearr, setYearr] = useState(`${year}`);

  const projectID = sessionStorage.getItem("sitemanagement_project")
  const [project, setProject] = useState(projectID ? projectID : null);

  useEffect(() => {

    getAllattendancedata();
  }, [monthh, yearr, monthName]);
  useEffect(() => {
    getSubCompany();
  }, []);

  const [subCompany, setSubCompany] = useState("null");
  const [allattendance, setAllattendance] = useState([]);
  const [subbuffer, setSubBuffering] = useState(true); //buffering logic
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [subcompanyList, setSubCompanyList] = useState([]);
  const getSubCompany = async () => {
    setSubBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSubCompanyList(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setSubBuffering(false);// End Buffering
    }
  };





  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic
  const [projectList, setProjectList] = useState([]);

  const getproject = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/null/`);

      const splitCodeParts = (code) => {
        const parts = code.trim().split("/");
        const firstPart = parts.slice(0, -1).join("/");
        const lastPart = parts.pop();
        const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
        if (match) {
          return {
            firstPart,
            numericPart: match[1] ? parseInt(match[1], 10) : 0,
            alphaPart: match[2]
          };
        }
        return { firstPart, numericPart: 0, alphaPart: "" };
      };

      const sortedList = response.data.sort((a, b) => {
        const aParts = splitCodeParts(a.site_prcode);
        const bParts = splitCodeParts(b.site_prcode);
        if (aParts.firstPart !== bParts.firstPart) {
          return aParts.firstPart.localeCompare(bParts.firstPart);
        }
        if (aParts.alphaPart !== bParts.alphaPart) {
          return aParts.alphaPart.localeCompare(bParts.alphaPart);
        }
        return aParts.numericPart - bParts.numericPart;
      });

      // setProjectList(response.data)
      setProjectList(sortedList)

    } catch (error) {
      alert(error);
    }
  }

  let getAllattendancedata = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/monthlyattendnaceall/${monthh}/${yearr}/${project}/`
      );

      const getAlphaNumericParts = (code) => {
        const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
        if (match) {
          return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
        } else {
          return { alphaPart: '', numericPart: 0 };
        }
      };

      const sortedList = res.data.sort((a, b) => {
        const { alphaPart: alphaA, numericPart: numericA } = getAlphaNumericParts(a.emp_code);
        const { alphaPart: alphaB, numericPart: numericB } = getAlphaNumericParts(b.emp_code);

        if (alphaA !== alphaB) {
          return alphaA.localeCompare(alphaB); // Sort alphabetically
        } else {
          return numericA - numericB; // Then sort numerically
        }
      });
      setAllattendance(sortedList);
      // setAllattendance(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);

    } finally {
      setBuffering(false); //buffering logic
    }
  };

  const refreshdata = async () => {
    setBuffering(true); //buffering logic
    await getAllattendancedata();
    setBuffering(false); //buffering logic
  };
  useEffect(() => {
    getAllattendancedata();
  }, [project, month, year])

  const getAllDatesOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const dates = [];
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      dates.push({ date: formattedDate });
    }

    return dates;
  };

  const dates = getAllDatesOfMonth(monthh, yearr).map((date, index) => ({
    ...date,
    index,
  }));



  const getAllDatessOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
    }

    return datees;
  };

  const datees = getAllDatessOfMonth(monthh, yearr); // get all dates for March 2023

  // *********** Filter Logic ***********
  const [filteredEmployee, setFilteredEmployee] = useState([]);

  // const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedName, setName] = useState("");
  const [selectedDep, setDep] = useState("");

  const [selectedStatus, setSelectedStatus] = useState("active");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company")}`
  );


  const applyFilters = () => {
    // Filter employees based on selectedStatus and selectedName
    let filteredData = allattendance;

    if (selectedStatus !== "") {
      filteredData = filteredData.filter(
        (employee) => employee.employee_status === selectedStatus
      );
    }

    if (selectedName) {
      // Use includes for a case-insensitive search for the name only
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter((employee) => {
        const nameMatch = employee.name?.toLowerCase().includes(lowercaseSelectedName);
        const empCodeMatch = employee.emp_code
          .toString()
          .includes(selectedName);

        const depMatch = employee.desigantion_name?.toLowerCase()
          .includes(lowercaseSelectedName);
        return nameMatch || empCodeMatch || depMatch;
      });
    }





    setFilteredEmployee(filteredData);
  };

  useEffect(() => {
    getproject()
  }, [])

  useEffect(() => {
    applyFilters();

  }, [
    selectedName,
    selectedStatus,
    // selectedDep,
    allattendance,
  ]);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  const tableRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 10; // starting 20 years ago
  const endYear = currentYear; // up to 20 years in the future
  const years = (Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index));
  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"></div>

        <div className="attendance-subcont">

          <div className="field-cont">

            {siteRole === "SiteManagement" ? "" :
              <div title="Company Filter" className="field-cont-div">
                <CircularGrid />
                <select
                  onChange={(e) => setSubCompany(e.target.value)}
                  value={subCompany}
                  className="attendance-input-field width-10vw   date-field"
                // className="dropdown font-size-subheading  "
                >
                  <option value="null">Sub Company</option>
                  {subcompanyList.map((company) => (
                    <option value={company.id}>{company.title}</option>
                  ))}
                  ;
                </select>

                <hr className="field-cont-hr" />
              </div>

            }
            {siteRole === "SiteManagement" ? "" :
              <Projects toggleProject={project} setToggleProject={setProject} subcompany={subCompany} />
            }

            <div className="field-cont-div">
              <Location />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">All</option>

                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
              <hr className="field-cont-hr" />
            </div>



            <div className="field-cont-div">
              <SearchBig />
              <input
                className="attendance-input-field width-25vw  "
                placeholder="Name/Emp Code/Dep"
                type="text"
                value={selectedName}
                onChange={(e) => setName(e.target.value)}
              />

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={monthh}
                onChange={(e) => setMonthh(e.target.value)}
              >
                {/* <option value="">All</option> */}
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              <hr className="field-cont-hr" />
            </div>
            <div title='Year Filter' className="field-cont-div">
              <CircularGrid />

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={yearr}
                onChange={(e) => setYearr(e.target.value)}
              >
                <option value="">Select Year</option>
                {years?.reverse().map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>

              <hr className="field-cont-hr" />
            </div>




          </div>

          {/* <div className="btn-cont">
                </div> */}
          <div className="btn-cont gap3">
            {/* <button className='attendance-btn' onClick={handleSearch}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 11 12" fill="none">
                  <circle cx="5" cy="5" r="4.3" stroke="#FFFFFF" stroke-width="1.4" />
                  <line x1="10.0101" y1="11" x2="8" y2="8.98995" stroke="#FFFFFF" stroke-width="1.4" stroke-linecap="round" />
                </svg>Search</button> */}
            <DownloadTableExcel
              filename={
                selectedDivision === ""
                  ? `${monthName}-AttendanceSheet`
                  : `${monthName}-AttendanceSheet-${selectedDivision}`
              }
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <button className=" model-button-print">
                <DownloadIcon />
              </button>

            </DownloadTableExcel>

            <UploadAttendance />

            <UploadAttendanceBulk2 />
          </div>
        </div>
      </div>

      <div className="custom-table-width">
        <div className='flex-row justify-between'>

          <div className='table-heading'>
            <div className="repo-heading font-weight500    font-size-heading">
              Attendance ({filteredEmployee.length} of {allattendance.length})
              {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="loading"><g><path fill="#414042" d="M29.89 15.81a2.51 2.51 0 1 0-5 .45 9.65 9.65 0 0 1-1.68 6.34 10.24 10.24 0 0 1-5.74 4 10.71 10.71 0 0 1-7.38-.7 11.44 11.44 0 0 1-5.48-5.62A12.07 12.07 0 0 0 9.46 27 12.58 12.58 0 0 0 17.9 29a13.31 13.31 0 0 0 8.18-4 14 14 0 0 0 3.81-8.75v-.08A2.29 2.29 0 0 0 29.89 15.81zM7.11 15.74A9.65 9.65 0 0 1 8.79 9.4a10.24 10.24 0 0 1 5.74-4 10.71 10.71 0 0 1 7.38.7 11.44 11.44 0 0 1 5.48 5.62A12.07 12.07 0 0 0 22.54 5 12.58 12.58 0 0 0 14.1 3 13.31 13.31 0 0 0 5.92 7a14 14 0 0 0-3.81 8.75v.08a2.29 2.29 0 0 0 0 .37 2.51 2.51 0 1 0 5-.45z"></path></g></svg> */}
            </div>
          </div>
          <div className="table-heading-flex ">


            {buffer || subbuffer ?
              <div className="spinner-vsmall"></div> :
              <div
                className="refresh-button"
                onClick={refreshdata}
                disabled={buffer} //buffering logic
              >
                <Refresh />
              </div>}

            {/* <div>
    
              </div> */}
          </div>
        </div>
        <div className='paddingTop-0px' style={{ height: "60vh" }}>

          <div className='table-box  '>
            <table
              ref={tableRef}
              id="my-table"
              style={{
                borderColor: "#323232",
                borderRadius: "5px",
                padding: "0px 5px",
                borderWidth: "1px",
                // marginLeft: "10px",
                // zoom: "70%",
              }}
            >
              <thead className='table-heading '>

                <tr className='custom-table-head-th'>
                  <th rowSpan="2" className="summary-excel-tab br-5 table-heading-text">S.no.</th>
                  <th rowSpan="2" className="summary-excel-tab br-5 table-heading-text">Emp. Code</th>
                  <th rowSpan="2" className="summary-excel-tab br-5 table-heading-text">Name</th>
                  <th rowSpan="2" className="summary-excel-tab br-5 table-heading-text">Joining Date</th>


                  <th rowSpan="2" className="summary-excel-tab br-5 table-heading-text"> Designation Name</th>
                  <th colSpan={datees.length} className="summary-excel-tab br-5 table-heading-text align-center">Attendance</th>



                  <th colSpan="5" className="summary-excel-tab br-5 table-heading-text">Attendance Stats</th>
                </tr>

                <tr className='padding-vertical-25px'>

                  {datees.map((date) => (
                    <th className="summary-excel-tab br-5 table-heading-text" key={date.date}>
                      {date.date}
                    </th>
                  ))}

                  <th className="summary-excel-tab br-5 table-heading-text">P</th>
                  <th className="summary-excel-tab br-5 table-heading-text">A</th>
                  <th className="summary-excel-tab br-5 table-heading-text">L</th>
                  <th className="summary-excel-tab br-5 table-heading-text">H</th>
                  <th className="summary-excel-tab br-5 table-heading-text">W</th>

                </tr>
              </thead>

              {
                // buffer ? <div className="spinner"></div> :
                <tbody>
                  {filteredEmployee
                    .sort((a, b) => a.emp_code - b.emp_code)
                    .map((data, index) => {
                      const background = data.late === 0 ? "white" : "#f9935f";

                      const inactivename = data.employee_status === "inactive" ? "#ff1212" : "black";
                      const present = data.present === 0 ? "white" : "#a3fc92";
                      const bgabsent = data.absent === 0 ? "white" : "#fccab1";
                      const holiday = data.holiday === 0 ? "white" : "#83B8E6CC";
                      const weeklyoff = data.weeklyoff === 0 ? "white" : "#83B8E6CC";
                      const late = data.late === 0 ? "white" : "#ffea72";
                      const leave = data.leave === 0 ? "white" : "#a3fc92";

                      return (
                        <>
                          <React.Fragment key={index}>
                            <tr
                              style={{
                                backgroundColor:
                                  index === selectedIndex ? "yellow" : "white",
                                color: inactivename,
                              }}
                              className="padding-vertical-25px"
                              onClick={() => handleClick(index)}
                            >
                              <td className="summary-excel-tab br-5 table-body">{index + 1}</td>
                              <td className="summary-excel-tab br-5 table-body">{data.emp_code}</td>
                              <td className="summary-excel-tab br-5 table-body">{data.name}</td>
                              <td className="summary-excel-tab br-5 table-body">{data.joining_date}</td>
                              <td className="summary-excel-tab br-5 table-body">{data.desigantion_name}</td>

                              {dates.map((date) => {
                                // Find the attendance record for the given date
                                const attendanceForDate = data.attendances.find(
                                  (attendance) => attendance.date === date.date
                                );

                                const attendanceStatus = attendanceForDate
                                  ? attendanceForDate.status === "P"
                                    ? "present"
                                    : attendanceForDate.status === "A"
                                      ? "absent"
                                      : attendanceForDate.status === "L"
                                        ? "leave"
                                        : attendanceForDate.status === "W"
                                          ? "weeklyoff"
                                          : attendanceForDate.status === "H"
                                            ? "holiday"
                                            : attendanceForDate.status.slice(0, 2)
                                  : "-";


                                // Define the variables for In Time, Out Time, and Status
                                const inTime = attendanceForDate?.in_time ?? "-";
                                const outTime = attendanceForDate?.out_time ?? "-";
                                const attstat = attendanceForDate?.status ?? "-";

                                return (
                                  <td
                                    key={date.date}
                                    style={{
                                      backgroundColor: index === selectedIndex ? "yellow" : "",
                                      color: index === selectedIndex ? "black" : "",
                                    }}
                                    data-status={attendanceStatus}
                                    className="behavior summary-excel-tab table-body"
                                    title={`Date: ${date.date}\nIn Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\n`}
                                  >
                                    {attstat}
                                  </td>
                                );
                              })}

                              <td
                                className="summary-excel-tab br-5"
                                style={{ backgroundColor: present }}
                              >
                                {data.present}
                              </td>


                              <td
                                className="summary-excel-tab br-5"
                                style={{ backgroundColor: bgabsent }}
                              >
                                {data.absent}
                              </td>
                              <td
                                className="summary-excel-tab br-5"
                                style={{ backgroundColor: holiday }}
                              >
                                {data.holiday}
                              </td>
                              <td
                                className="summary-excel-tab br-5"
                                style={{ backgroundColor: weeklyoff }}
                              >
                                {data.weeklyoff}
                              </td>
                              <td
                                className="summary-excel-tab br-5"
                                style={{ backgroundColor: leave }}
                              >
                                {data.leave}
                              </td>


                              {/* <td
                          className="summary-excel-tab br-5"
                          style={{ backgroundColor: bgtotalpaiddays }}
                        >
                          {data.total}
                        </td> */}
                            </tr>
                          </React.Fragment>
                        </>
                      );
                    })}
                </tbody>
              }
            </table>
            <div className="legend">
              <p>S = Sick</p>
              <p>L = Leave or Absent</p>
              <p>T = Travel / Tour</p>
              <p>HO = Head Office Input</p>
              <p>H =Holiday or Sunday</p>
            </div>
          </div>
        </div>





      </div>
    </>
  );
};


export { Attendance, DeleteboqAttandance, AddBulkboqAttendance, AddSiteAttendence, GetAttendance, }
