import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AUTH, AUTHH, BASE_URL, IMAGE_URL } from '../../config/axios';
import { toast, ToastContainer } from 'react-toastify';
import { formatDate, formattedDateLong } from '../Date';
import { Modal } from 'react-bootstrap';
import Dashboardnavbar from '../../layout/Dashboardnavbar';
import { useNavigate } from 'react-router-dom';
import { formatCurrencyIndian, formatDateTime, formatRoundoff2D, handleErrorToast, ViewRequestStatus, ViewRequestRemarks, getMonthName, ViewDocumnetsbilling } from '../CustomFunctions';
import usePermission from '../../config/permissions';
import { CheckGreen, WarnYellow, Eye, Filter, Office, CrossRed, Attachment, Employee } from '../AllSvg';
import { Projects } from '../FilterDropdown';
import {
    ApprovedRequestOfficeBillingAccStage1,
    RejectRequestOfficeBillingAccStage1,
    ApprovedRequestOfficeBillingAccStage2,
    ApprovedOfficeBillingAdminC1,
    ApprovedOfficeBillingAdminC2,
    ApprovedOfficeBillingAdminC3,
    RejectOfficeBillingAdminC1,
    RejectOfficeBillingAdminC2,
    RejectOfficeBillingAdminC3,
    ApprovedRequestOfficeBillingAuthority,
    RejectRequestOfficeBillingAuthority,
    ApprovedRequestOfficeBillingChecker,
    RejectRequestOfficeBillingChecker,
} from "./OfficeRentBillingTables";

const RequestOfficeBillingAdmin = () => {
    const { PERMISSION_AUTHORITY } = usePermission();
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [officeBillings, setOfficeBillings] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus1, setSelectedStatus1] = useState("pending");
    const [selectedStatus2, setSelectedStatus2] = useState("pending");
    const [selectedProject, setProject] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("sitemanagement_project_sub_company") || null}`
    );


    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getofficebillingdata = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/officerentbillingapi3adminsfilter/${selectedStatus1}/${fromdate}/${todate}/${selectedProject}/`
            );
            setOfficeBillings(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getofficebillingdata();
        getSubCompany();
    }, [fromdate, todate, selectedStatus1, selectedStatus2, selectedProject]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };



    useEffect(() => {
        getEmpcode();

    }, []);
    //! *********** Employee Details Api End ***********


    return (
        <>
            <Dashboardnavbar name={"Admin Office Billing"} url="Admin Office Billing" />
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className='content-tabs'>
                <div className="attendance-history-cont">
                    <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                    <div className="attendance-subcont">
                        <div className="field-cont">
                            <div className="field-cont-div">
                                <div className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw   date-field date-field-filter"
                                        placeholder="From Date"
                                        type="date"
                                        value={fromdate}
                                        onChange={(e) => setFromDate(e.target.value === "" ? "null" : e.target.value)}
                                    />
                                </div>

                                <hr className="field-cont-hr" />
                            </div>

                            <div className="field-cont-div">
                                <div className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw   date-field date-field-filter"
                                        placeholder="From Date"
                                        type="date"
                                        value={todate}
                                        onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                                    />
                                </div>
                                <hr className="field-cont-hr" />
                            </div>
                            <div className="field-cont-div">
                                <Filter />
                                <select
                                    className="attendance-input-field width-10vw   date-field"
                                    type="text"
                                    value={selectedStatus1}
                                    onChange={(e) => setSelectedStatus1(e.target.value)}
                                >
                                    <option value="null">All</option>

                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                                <hr className="field-cont-hr" />
                            </div>
                            {/* <div className="field-cont-div">
                                <Filter />
                                <select
                                    className="attendance-input-field width-10vw   date-field"
                                    type="text"
                                    value={selectedStatus2}
                                    onChange={(e) => setSelectedStatus2(e.target.value)}
                                >
                                    <option value="null">All</option>

                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                                <hr className="field-cont-hr" />
                            </div> */}
                            <div className="field-cont-div">
                                <Office />
                                <select
                                    className="attendance-input-field width-10vw date-field"
                                    type="text"
                                    value={selectedDivision}
                                    onChange={(e) => setDivision(e.target.value)}
                                >
                                    <option value="null">All Sub Companies</option>
                                    {SubCompany.sort((a, b) => a.title - b.title).map(
                                        (i, index) => (
                                            <option value={i.id}>{i.title}</option>
                                        )
                                    )}
                                    ;
                                </select>

                                <hr className="field-cont-hr" />
                            </div>
                            <div className="field-cont-div">
                                <Projects toggleProject={selectedProject} setToggleProject={setProject} subcompany={selectedDivision} />
                            </div>


                        </div>
                        <div className="btn-cont"></div>
                    </div>

                    <div className="table-css-white-background scroll-container-table">
                        {/* <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Expense</div>
                        <div className="flex-row">
                        </div>
                    </div> */}
                        <table className="table-css">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="table-heading-text text-lefttt">S. No.</th>
                                    <th className="table-heading-text">Date</th>
                                    <th className="table-heading-text">Project</th>
                                    <th className="table-heading-text">Owner Name</th>
                                    <th className="table-heading-text">Days</th>
                                    <th className="table-heading-text">Months</th>
                                    <th className="table-heading-text">Year</th>
                                    <th className="table-heading-text text-center">Office Rent Amount</th>
                                    <th className="table-heading-text">Status</th>
                                    <th className="table-heading-text">Remarks</th>
                                    <th className="table-heading-text text-center">Actions</th>
                                </tr>
                            </thead>
                            {buffer ? <div className="spinner"></div> : //buffering logic
                                <tbody>
                                    {officeBillings.map((i, index) => (
                                        <React.Fragment key={index}>
                                            <tr className="custom-table-head-td">
                                                <td className="table-body text-lefttt">{index + 1}</td>
                                                <td className="table-body">{formatDate(i.date)}</td>
                                                <td className="table-body">{i.project_short_name}</td>
                                                <td className="table-body">{i.owner_name}</td>
                                                <td className="table-body">{i.days}</td>
                                                <td className="table-body">{getMonthName(i.month)}</td>
                                                <td className="table-body">{i.year}</td>
                                                <td className="table-body">
                                                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Basic Amount</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(i.rent_amt || 0)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>TDS</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(i.tds_amt || 0)} ({i.tds_percentage}%)
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Total Amount</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(i.total_amt || 0)}
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </td>
                                                <ViewRequestStatus i={i} />

                                                <ViewRequestRemarks i={i} />

                                                <td className="table-body">
                                                    <div className="flex-row " style={{ justifyContent: 'center' }}>
                                                        {sessionStorage.getItem('email') === 'manoj.rathi@saptagon.in' ?
                                                            <div className='flex-row'>
                                                                {i.admin_approval_status_c1 === 'approved' ? null :
                                                                    <ApprovedOfficeBillingAdminC1
                                                                        i={i}
                                                                        getofficebillingdata={getofficebillingdata}
                                                                        documentlist={i.office_rent.documents}
                                                                    />}
                                                                {i.admin_approval_status_c1 === 'rejected' ? null :
                                                                    <RejectOfficeBillingAdminC1
                                                                        i={i}
                                                                        getofficebillingdata={getofficebillingdata}
                                                                    />}
                                                            </div>

                                                            : ''}
                                                        {sessionStorage.getItem('email') === 'megha.garg@g-eng.in' || sessionStorage.getItem('email') === 'abhishekprakash489@gmail.com' ?
                                                            i.admin_approval_status_c2 === 'approved' ?
                                                                <div className='flex-row'>
                                                                    {/* {i.admin_approval_status_c3 === 'approved' ? null :
                                                                        <ApprovedOfficeBillingAdminC3
                                                                            i={i}
                                                                            getofficebillingdata={getofficebillingdata}
                                                                        />}
                                                                    {i.admin_approval_status_c3 === 'approved' ? null :
                                                                        <RejectOfficeBillingAdminC3
                                                                            i={i}
                                                                            getofficebillingdata={getofficebillingdata}
                                                                        />} */}
                                                                </div>
                                                                :

                                                                <div className='flex-row'>
                                                                    {i.admin_approval_status_c2 === 'approved' ? null :
                                                                        <ApprovedOfficeBillingAdminC2
                                                                            i={i}
                                                                            getofficebillingdata={getofficebillingdata}
                                                                            documentlist={i.office_rent.documents}
                                                                        />}
                                                                    {i.admin_approval_status_c2 === 'approved' ? null :
                                                                        <RejectOfficeBillingAdminC2
                                                                            i={i}
                                                                            getofficebillingdata={getofficebillingdata}
                                                                        />}
                                                                </div>

                                                            : ''}
                                                    </div>
                                                </td>

                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

const RequestOfficeBillingAuthority = () => {
    const { PERMISSION_AUTHORITY } = usePermission();
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [officeBillings, setOfficeBillings] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("sitemanagement_project_sub_company") || null}`
    );


    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getofficebillingdata = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/officerentbillingapiauthorityfilter/${selectedStatus}/${fromdate}/${selectedProject}/`
            );
            setOfficeBillings(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getofficebillingdata();
        getSubCompany();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    const [documentlist, SetDocumentlist] = useState([]);


    useEffect(() => {
        getEmpcode();

    }, []);
    //! *********** Employee Details Api End ***********


    return (
        <>
            <Dashboardnavbar name={"Authority Office Billing"} url="Authority Office Billing" />
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="content-tabs">
                <div className="attendance-history-cont">
                    <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                    <div className="attendance-subcont">
                        <div className="field-cont">
                            <div className="field-cont-div">
                                <div className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw   date-field date-field-filter"
                                        placeholder="From Date"
                                        type="date"
                                        value={fromdate}
                                        onChange={(e) => setFromDate(e.target.value === "" ? "null" : e.target.value)}
                                    />
                                </div>

                                <hr className="field-cont-hr" />
                            </div>

                            {/* // <div className="field-cont-div">
                        //     <div className="date-uppercase ">
                        //         <input
                        //             className="attendance-input-field width-10vw   date-field date-field-filter"
                        //             placeholder="From Date"
                        //             type="date"
                        //             value={todate}
                        //             onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                        //         />
                        //     </div>
                        //     <hr className="field-cont-hr" />
                        // </div> */}
                            <div className="field-cont-div">
                                <Filter />
                                <select
                                    className="attendance-input-field width-10vw   date-field"
                                    type="text"
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e.target.value)}
                                >
                                    <option value="null">All</option>

                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                                <hr className="field-cont-hr" />
                            </div>
                            <div className="field-cont-div">
                                <Office />
                                <select
                                    className="attendance-input-field width-10vw date-field"
                                    type="text"
                                    value={selectedDivision}
                                    onChange={(e) => setDivision(e.target.value)}
                                >
                                    <option value="null">All Sub Companies</option>
                                    {SubCompany.sort((a, b) => a.title - b.title).map(
                                        (i, index) => (
                                            <option value={i.id}>{i.title}</option>
                                        )
                                    )}
                                    ;
                                </select>

                                <hr className="field-cont-hr" />
                            </div>
                            <div className="field-cont-div">
                                <Projects toggleProject={selectedProject} setToggleProject={setProject} subcompany={selectedDivision} />
                            </div>


                        </div>
                        <div className="btn-cont"></div>
                    </div>

                    <div className="table-css-white-background scroll-container-table">
                        {/* <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Expense</div>
                        <div className="flex-row">
                        </div>
                    </div> */}
                        <table className="table-css">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="table-heading-text text-lefttt">S. No.</th>
                                    <th className="table-heading-text">Date</th>
                                    <th className="table-heading-text">Project</th>
                                    <th className="table-heading-text">Owner Name</th>
                                    <th className="table-heading-text">Days</th>
                                    <th className="table-heading-text">Months</th>
                                    <th className="table-heading-text">Year</th>
                                    <th className="table-heading-text text-center">Office Rent Amount</th>
                                    <th className="table-heading-text">Status</th>
                                    <th className="table-heading-text">Remarks</th>
                                    <th className="table-heading-text text-center">Actions</th>
                                </tr>
                            </thead>
                            {buffer ? <div className="spinner"></div> : //buffering logic
                                <tbody>
                                    {officeBillings.map((i, index) => (
                                        <React.Fragment key={index}>
                                            <tr className="custom-table-head-td">
                                                <td className="table-body text-lefttt">{index + 1}</td>
                                                <td className="table-body">{formatDate(i.date)}</td>
                                                <td className="table-body">{i.project_short_name}</td>
                                                <td className="table-body">{i.owner_name}</td>
                                                <td className="table-body">{i.days}</td>
                                                <td className="table-body">{getMonthName(i.month)}</td>
                                                <td className="table-body">{i.year}</td>
                                                <td className="table-body">
                                                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Basic Amount</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(i.rent_amt || 0)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>TDS</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(i.tds_amt || 0)} ({i.tds_percentage}%)
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Total Amount</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(i.total_amt || 0)}
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </td>

                                                <ViewRequestStatus i={i} />

                                                <ViewRequestRemarks i={i} />
                                                <td className="table-body">
                                                    <div className="flex-row " style={{ justifyContent: 'center' }}>
                                                        <>
                                                            {
                                                                i.authority_approval_status === "approved" ? (
                                                                    <></>
                                                                ) : (
                                                                    <ApprovedRequestOfficeBillingAuthority
                                                                        i={i}
                                                                        getofficebillingdata={getofficebillingdata}
                                                                        documentlist={i.office_rent.documents}
                                                                    />
                                                                )
                                                            }
                                                        </>
                                                        <>
                                                            {
                                                                i.authority_approval_status === "rejected" ? (
                                                                    <></>
                                                                ) : (
                                                                    <RejectRequestOfficeBillingAuthority
                                                                        i={i}
                                                                        getofficebillingdata={getofficebillingdata}
                                                                        documentlist={i.office_rent.documents}
                                                                    />
                                                                )
                                                            }
                                                        </>
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};
const RequestOfficeBillingAccStage1 = () => {
    const { PERMISSION_AUTHORITY } = usePermission();
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [officeBillings, setOfficeBillings] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("sitemanagement_project_sub_company") || null}`
    );


    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getofficebillingdata = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/officerentbillingapiaccountstatusafilter/${selectedStatus}/${fromdate}/${selectedProject}/`
            );
            setOfficeBillings(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getofficebillingdata();
        getSubCompany();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };



    useEffect(() => {
        getEmpcode();

    }, []);
    //! *********** Employee Details Api End ***********


    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value === "" ? "null" : e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        {/* // <div className="field-cont-div">
                        //     <div className="date-uppercase ">
                        //         <input
                        //             className="attendance-input-field width-10vw   date-field date-field-filter"
                        //             placeholder="From Date"
                        //             type="date"
                        //             value={todate}
                        //             onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                        //         />
                        //     </div>
                        //     <hr className="field-cont-hr" />
                        // </div> */}
                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Office />
                            <select
                                className="attendance-input-field width-10vw date-field"
                                type="text"
                                value={selectedDivision}
                                onChange={(e) => setDivision(e.target.value)}
                            >
                                <option value="null">All Sub Companies</option>
                                {SubCompany.sort((a, b) => a.title - b.title).map(
                                    (i, index) => (
                                        <option value={i.id}>{i.title}</option>
                                    )
                                )}
                                ;
                            </select>

                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Projects toggleProject={selectedProject} setToggleProject={setProject} subcompany={selectedDivision} />
                        </div>


                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background scroll-container-table">
                    {/* <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Expense</div>
                        <div className="flex-row">
                        </div>
                    </div> */}
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-lefttt">S. No.</th>
                                <th className="table-heading-text">Date</th>
                                <th className="table-heading-text">Project</th>
                                <th className="table-heading-text">Owner Name</th>
                                <th className="table-heading-text">Days</th>
                                <th className="table-heading-text">Months</th>
                                <th className="table-heading-text">Year</th>
                                <th className="table-heading-text text-center">Office Rent Amount</th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text">Remarks</th>
                                <th className="table-heading-text">Actions</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {officeBillings.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="custom-table-head-td">
                                            <td className="table-body text-lefttt">{index + 1}</td>
                                            <td className="table-body">{formatDate(i.date)}</td>
                                            <td className="table-body">{i.project_short_name}</td>
                                            <td className="table-body">{i.owner_name}</td>
                                            <td className="table-body">{i.days}</td>
                                            <td className="table-body">{getMonthName(i.month)}</td>
                                            <td className="table-body">{i.year}</td>
                                            <td className="table-body">
                                                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Basic Amount</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {formatCurrencyIndian(i.rent_amt || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>TDS</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {formatCurrencyIndian(i.tds_amt || 0)} ({i.tds_percentage}%)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Total Amount</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {formatCurrencyIndian(i.total_amt || 0)}
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </td>
                                            <ViewRequestStatus i={i} />

                                            <ViewRequestRemarks i={i} />

                                            <td className="table-body">
                                                <div className="flex-row " style={{ justifyContent: 'center' }}>
                                                    <>
                                                        {
                                                            i.account_status_a === "approved" ? (
                                                                <></>
                                                            ) : (
                                                                <ApprovedRequestOfficeBillingAccStage1
                                                                    i={i}
                                                                    getofficebillingdata={getofficebillingdata}
                                                                    documentlist={i.office_rent.documents}
                                                                />
                                                            )
                                                        }
                                                    </>
                                                    <>
                                                        {
                                                            i.account_status_a === "rejected" ? (
                                                                <></>
                                                            ) : (
                                                                <RejectRequestOfficeBillingAccStage1
                                                                    i={i}
                                                                    getofficebillingdata={getofficebillingdata}
                                                                    documentlist={i.office_rent.documents}
                                                                />
                                                            )
                                                        }
                                                    </>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};


const RequestOfficeBillingAccStage2 = () => {
    const { PERMISSION_AUTHORITY } = usePermission();
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [officeBillings, setOfficeBillings] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("sitemanagement_project_sub_company") || null}`
    );


    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getofficebillingdata = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/officerentbillingapiaccountstatusbfilter/${selectedStatus}/${fromdate}/${selectedProject}/`
            );
            setOfficeBillings(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getofficebillingdata();
        getSubCompany();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };



    useEffect(() => {
        getEmpcode();

    }, []);
    //! *********** Employee Details Api End ***********


    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) => setFromDate(e.target.value === "" ? "null" : e.target.value)}
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        {/* // <div className="field-cont-div">
                        //     <div className="date-uppercase ">
                        //         <input
                        //             className="attendance-input-field width-10vw   date-field date-field-filter"
                        //             placeholder="From Date"
                        //             type="date"
                        //             value={todate}
                        //             onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                        //         />
                        //     </div>
                        //     <hr className="field-cont-hr" />
                        // </div> */}
                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Office />
                            <select
                                className="attendance-input-field width-10vw date-field"
                                type="text"
                                value={selectedDivision}
                                onChange={(e) => setDivision(e.target.value)}
                            >
                                <option value="null">All Sub Companies</option>
                                {SubCompany.sort((a, b) => a.title - b.title).map(
                                    (i, index) => (
                                        <option value={i.id}>{i.title}</option>
                                    )
                                )}
                                ;
                            </select>

                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Projects toggleProject={selectedProject} setToggleProject={setProject} subcompany={selectedDivision} />
                        </div>


                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background scroll-container-table">
                    {/* <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Expense</div>
                        <div className="flex-row">
                        </div>
                    </div> */}
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-lefttt">S. No.</th>
                                <th className="table-heading-text">Date</th>
                                <th className="table-heading-text">Project</th>
                                <th className="table-heading-text">Owner Name</th>
                                <th className="table-heading-text">Days</th>
                                <th className="table-heading-text">Months</th>
                                <th className="table-heading-text">Year</th>
                                <th className="table-heading-text text-center">Office Rent Amount</th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text">Remarks</th>
                                <th className="table-heading-text">Actions</th>
                            </tr>
                        </thead>
                        {buffer ? <div className="spinner"></div> : //buffering logic
                            <tbody>
                                {officeBillings.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="custom-table-head-td">
                                            <td className="table-body text-lefttt">{index + 1}</td>
                                            <td className="table-body">{formatDate(i.date)}</td>
                                            <td className="table-body">{i.project_short_name}</td>
                                            <td className="table-body">{i.owner_name}</td>
                                            <td className="table-body">{i.days}</td>
                                            <td className="table-body">{getMonthName(i.month)}</td>
                                            <td className="table-body">{i.year}</td>
                                            <td className="table-body">
                                                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Basic Amount</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {formatCurrencyIndian(i.rent_amt || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>TDS</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {formatCurrencyIndian(i.tds_amt || 0)} ({i.tds_percentage}%)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Total Amount</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {formatCurrencyIndian(i.total_amt || 0)}
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </td>
                                            <ViewRequestStatus i={i} />

                                            <ViewRequestRemarks i={i} />

                                            <td className="table-body">
                                                <div className="flex-row " style={{ justifyContent: 'center' }}>
                                                    <>
                                                        {
                                                            i.account_status_b === "approved" ? (
                                                                <></>
                                                            ) : (
                                                                <ApprovedRequestOfficeBillingAccStage2
                                                                    i={i}
                                                                    getofficebillingdata={getofficebillingdata}
                                                                    documentlist={i.office_rent.documents}
                                                                />
                                                            )
                                                        }
                                                    </>
                                                </div>
                                            </td>

                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>}
                    </table>
                </div>
            </div>
        </>
    );
};

const OfficeAccstage = () => {
    const [toggleState, setToggleState] = useState(1);

    const gettitles = () => {
        switch (toggleState) {
            case 1:
                return "Office Billing Stage1";
            case 2:
                return "Office Billing Stage2";

            default:
                return "Office Billing Stage1"
        }
    }


    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <Dashboardnavbar name={gettitles()} url="Admin Requests Management" />
            <div className="content-tabs">
                {/* <TeamRequestManagment secondtoggle={secondtoggle} /> */}
                <>
                    <div>
                        <div className="bloc-tabs-with-white-bg">


                            <button
                                className={
                                    toggleState === 1
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(1)}
                            >
                                Office Billing AccStage1
                            </button>

                            <button
                                className={
                                    toggleState === 2
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(2)}
                            >
                                Office Billing AccStage2
                            </button>






                        </div>

                        <div className="content-tabs-without-margin">


                            {toggleState === 1 ? <RequestOfficeBillingAccStage1 /> : null}
                            {toggleState === 2 ? <RequestOfficeBillingAccStage2 /> : null}

                        </div>
                    </div>
                </>
            </div >
        </>
    )
}

const RequestOfficeBillingChecker = () => {
    const { PERMISSION_AUTHORITY } = usePermission();
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [officeBillings, setOfficeBillings] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("sitemanagement_project_sub_company") || null}`
    );


    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };
    const [documentlist, SetDocumentlist] = useState([]);
    //! ******************************API start***************************//
    const getofficebillingdata = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/officerentbillingapicheckerfilter/${selectedStatus}/${fromdate}/${selectedProject}/`
            );
            setOfficeBillings(res.data);
            console.log(res.data.office_rent, ">>>>>>>response")
            // SetDocumentlist(res.data.office_rent)
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getofficebillingdata();
        getSubCompany();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };




    useEffect(() => {
        getEmpcode();

    }, []);
    //! *********** Employee Details Api End ***********


    return (
        <>
            <Dashboardnavbar name={"Office Billing Checker"} url="Office Billing Checker" />
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className='content-tabs'>
                <div className="attendance-history-cont">
                    <div className="attendance-heading  font-weight500    font-size-heading"> </div>
                    <div className="attendance-subcont">
                        <div className="field-cont">
                            <div className="field-cont-div">
                                <div className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw   date-field date-field-filter"
                                        placeholder="From Date"
                                        type="date"
                                        value={fromdate}
                                        onChange={(e) => setFromDate(e.target.value === "" ? "null" : e.target.value)}
                                    />
                                </div>

                                <hr className="field-cont-hr" />
                            </div>

                            {/* // <div className="field-cont-div">
                        //     <div className="date-uppercase ">
                        //         <input
                        //             className="attendance-input-field width-10vw   date-field date-field-filter"
                        //             placeholder="From Date"
                        //             type="date"
                        //             value={todate}
                        //             onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                        //         />
                        //     </div>
                        //     <hr className="field-cont-hr" />
                        // </div> */}
                            <div className="field-cont-div">
                                <Filter />
                                <select
                                    className="attendance-input-field width-10vw   date-field"
                                    type="text"
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e.target.value)}
                                >
                                    <option value="null">All</option>

                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                                <hr className="field-cont-hr" />
                            </div>
                            <div className="field-cont-div">
                                <Office />
                                <select
                                    className="attendance-input-field width-10vw date-field"
                                    type="text"
                                    value={selectedDivision}
                                    onChange={(e) => setDivision(e.target.value)}
                                >
                                    <option value="null">All Sub Companies</option>
                                    {SubCompany.sort((a, b) => a.title - b.title).map(
                                        (i, index) => (
                                            <option value={i.id}>{i.title}</option>
                                        )
                                    )}
                                    ;
                                </select>

                                <hr className="field-cont-hr" />
                            </div>
                            <div className="field-cont-div">
                                <Projects toggleProject={selectedProject} setToggleProject={setProject} subcompany={selectedDivision} />
                            </div>


                        </div>
                        <div className="btn-cont"></div>
                    </div>

                    <div className="table-css-white-background scroll-container-table">
                        {/* <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Expense</div>
                        <div className="flex-row">
                        </div>
                    </div> */}
                        <table className="table-css">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="table-heading-text text-lefttt">S. No.</th>
                                    <th className="table-heading-text">Date</th>
                                    <th className="table-heading-text">Project</th>
                                    <th className="table-heading-text">Owner Name</th>
                                    <th className="table-heading-text">Days</th>
                                    <th className="table-heading-text">Months</th>
                                    <th className="table-heading-text">Year</th>
                                    <th className="table-heading-text text-center">Office Rent Amount</th>
                                    <th className="table-heading-text">Status</th>
                                    <th className="table-heading-text">Remarks</th>
                                    <th className="table-heading-text text-center">Actions</th>
                                </tr>
                            </thead>
                            {buffer ? <div className="spinner"></div> : //buffering logic
                                <tbody>
                                    {officeBillings.map((i, index) => (
                                        <React.Fragment key={index}>
                                            <tr className="custom-table-head-td">
                                                <td className="table-body text-lefttt">{index + 1}</td>
                                                <td className="table-body">{formatDate(i.date)}</td>
                                                <td className="table-body">{i.project_short_name}</td>
                                                <td className="table-body">{i.owner_name}</td>
                                                <td className="table-body">{i.days}</td>
                                                <td className="table-body">{getMonthName(i.month)}</td>
                                                <td className="table-body">{i.year}</td>
                                                <td className="table-body">
                                                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Basic Amount</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(i.rent_amt || 0)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>TDS</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(i.tds_amt || 0)} ({i.tds_percentage}%)
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body" style={{ paddingBottom: "5px" }}>Total Amount</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(i.total_amt || 0)}
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </td>
                                                <ViewRequestStatus i={i} />

                                                <ViewRequestRemarks i={i} />
                                                <td className="table-body">
                                                    <div className="flex-row " style={{ justifyContent: 'center' }}>
                                                        <>
                                                            {
                                                                i.checker_approval_status === "approved" ? (
                                                                    <></>
                                                                ) : (
                                                                    <ApprovedRequestOfficeBillingChecker
                                                                        i={i}
                                                                        getofficebillingdata={getofficebillingdata}
                                                                        documentlist={i.office_rent.documents}
                                                                    />
                                                                )
                                                            }
                                                        </>
                                                        <>
                                                            {i.checker_approval_status === "rejected" ? (
                                                                <></>
                                                            ) : (
                                                                <RejectRequestOfficeBillingChecker
                                                                    i={i}
                                                                    getofficebillingdata={getofficebillingdata}
                                                                    documentlist={i.office_rent.documents}
                                                                />
                                                            )}
                                                        </>
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export {
    RequestOfficeBillingAdmin,
    RequestOfficeBillingAuthority,
    OfficeAccstage,
    RequestOfficeBillingChecker,
}
