import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { formattedDateLong } from "../Date";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { AddwithBlueCircle, UpdatePencil, DeleteDustbin, DropdownArrowOption } from "../AllSvg";
import Select from 'react-select';
import { handleErrorToast } from "../CustomFunctions";

const AddApprovalStages = ({ getStagesData }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setFormData({
      approved_for: "",
      checker: false,
      authority: false,
      account: false,
      admin1: false,
      admin1_emp: "",
      admin2: false,
      admin2_emp: "",
      admin3: false,
      admin3_emp: "",
      payment_settle: true,
    })
    setShow(false)
  };
  const handleShow = () => {
    getEmployeeList();
    setFormData({
      approved_for: "",
      checker: false,
      authority: false,
      account: false,
      admin1: false,
      admin1_emp: "",
      admin2: false,
      admin2_emp: "",
      admin3: false,
      admin3_emp: "",
      payment_settle: true,
    })
    setShow(true)
  };


  const [employeeList, setEmployeeList] = useState([]);
  const [buffer1, setBuffering1] = useState(false);

  const getEmployeeList = async (emp_code) => {
    setBuffering1(true); //buffering logic
    try {
      const employeeList = await axios.get(`${BASE_URL}/siteproject/getemprole/`);
      setEmployeeList(employeeList.data);
    } catch (err) {
      console.error("Error fetching Employee Data", err);
    } finally {
      setBuffering1(false); //buffering logic
    }
  };


  const [formData, setFormData] = useState({
    approved_for: "",
    checker: false,
    authority: false,
    account: false,
    admin1: false,
    admin1_emp: "",
    admin2: false,
    admin2_emp: "",
    admin3: false,
    admin3_emp: "",
    payment_settle: false,
  });

  //**************************valedation Start**************** */
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "approved_for",
      formData.admin1 ? "admin1_emp" : null,
      formData.admin2 ? "admin2_emp" : null,
      formData.admin3 ? "admin3_emp" : null,
    ].filter(Boolean);

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (errors[name]) {
      setErrors((prevErrors) => {
        const { [name]: removedError, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => {
        const { [name]: removedError, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  //**************************valedation End**************** */
  const [loading, setLoading] = useState(false); //loading logic
  const [buffer, setBuffering] = useState(true); //buffering logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      setBuffering(true); //buffering logic // Start Buffering
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(`${BASE_URL}/siteproject/approvalget/`, formData);

        if (res.status === 200) {
          await getStagesData();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        handleErrorToast(err)
        //toast Logic
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const options = employeeList
    .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
    .map((e) => ({
      value: e.emp_code,
      label: `${e.emp_code} - ${e.name}`,
    }));


  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <AddwithBlueCircle />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>Add Approval Stages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column form-group-select">
                <label htmlFor="approved_for " className="form-labels  font-weight500    font-size-subheading">
                  Approval For<span className="required">*</span>
                </label>
                <br />
                <select
                  id="approved_for"
                  name="approved_for"
                  value={formData.approved_for}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.approved_for ? "error" : inputState.approved_for ? "success" : ""}`}
                >
                  <option value="">Select Salary Approval For Type</option>
                  <option value="Imprest">Imprest</option>
                  <option value="Expense">Expense</option>
                  <option value="Office">Office</option>
                  <option value="Vehicle">Vehicle</option>
                  <option value="Invoice">Invoice</option>
                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                <div>
                  {errors.approved_for && (
                    <span className="error-message font-size-text ">{errors.approved_for}</span>
                  )}
                </div>
              </div>

              <ol>
                <li>
                  <div className="flex-column align-center">
                    <div className="flex-row ">
                      <div className="checkbox-toggle">
                        <input
                          type="checkbox"
                          id="toggle_checker"
                          name="checker"
                          checked={formData.checker}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="toggle_checker"></label>
                      </div>
                      <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                      <label htmlFor="checker" className="form-labels  font-weight500    font-size-subheading">
                        {formData.checker ? "Need" : "No"} Checker Approval {" "}
                      </label>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="flex-column align-center">
                    <div className="flex-row ">
                      <div className="checkbox-toggle">
                        <input
                          type="checkbox"
                          id="toggle_authority"
                          name="authority"
                          checked={formData.authority}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="toggle_authority"></label>
                      </div>
                      <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                      <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                        {formData.authority ? "Need" : "No"} Authority Approval {" "}
                      </label>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="flex-column align-center">
                    <div className="flex-row ">
                      <div className="checkbox-toggle">
                        <input
                          type="checkbox"
                          id="toggle_account"
                          name="account"
                          checked={formData.account}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="toggle_account"></label>
                      </div>
                      <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                      <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                        {formData.account ? "Need" : "No"} Account Approval {" "}
                      </label>
                    </div>
                  </div>
                </li>

                <li >
                  <div className={`flex-row ${formData.admin1 ? 'justify-evenly' : ''}`}>
                    <div className="flex-column align-center">
                      <div className="flex-row ">
                        <div className="checkbox-toggle">
                          <input
                            type="checkbox"
                            id="toggle_admin1"
                            name="admin1"
                            checked={formData.admin1}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="toggle_admin1"></label>
                        </div>
                        <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                        <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                          {formData.admin1 ? "Need" : "No"} Admin 1 Approval {" "}
                        </label>
                      </div>
                    </div>

                    {formData.admin1 ?
                      <div className="flex-column align-centerform-group-selectt">
                        <label htmlFor="admin1_emp" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Assigned Admin 1{formData.admin1 ? <span className="required">*</span> : null}
                        </label>
                        {buffer1 ?
                          <div className="form-loader-date">
                            <div className="bar-loader"></div>
                          </div> :
                          <Select
                            id='admin1_emp'
                            name='admin1_emp'
                            options={options}
                            isClearable
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                handleInputChange({
                                  target: {
                                    name: 'admin1_emp',
                                    value: selectedOption.value,
                                  }
                                });
                              } else {
                                handleInputChange({
                                  target: {
                                    name: 'admin1_emp',
                                    value: '',
                                  }
                                });
                              }
                            }}
                            value={formData.admin1_emp ? { value: formData.admin1_emp, label: formData.admin1_emp } : null}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '20vw',
                                minHeight: '50px',
                                borderRadius: '10px',
                                border: '1px solid #e3e3e3',
                                margin: '10px 0px 20px 0px',
                                color: '#4a4a4a',
                                backgroundColor: '#f7f8fa',
                                padding: '0px 10px',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              menu: (provided) => ({
                                ...provided,
                                borderRadius: '10px',
                                marginTop: '0',
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                            }}
                          />}
                        {errors.admin1_emp && (
                          <span className="error-message font-size-text ">{errors.admin1_emp}</span>
                        )}
                      </div>
                      : null}
                  </div>
                </li>

                <li >
                  <div className={`flex-row ${formData.admin2 ? 'justify-evenly' : ''}`}>
                    <div className="flex-column align-center">
                      <div className="flex-row ">
                        <div className="checkbox-toggle">
                          <input
                            type="checkbox"
                            id="toggle_admin2"
                            name="admin2"
                            checked={formData.admin2}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="toggle_admin2"></label>
                        </div>
                        <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                        <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                          {formData.admin2 ? "Need" : "No"} Admin 2 Approval {" "}
                        </label>
                      </div>
                    </div>
                    {formData.admin2 ?
                      <div className="flex-column align-centerform-group-selectt">
                        <label htmlFor="admin2_emp" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Assigned Admin 2{formData.admin2 ? <span className="required">*</span> : null}
                        </label>
                        {buffer1 ?
                          <div className="form-loader-date">
                            <div className="bar-loader"></div>
                          </div> :
                          <Select
                            id='admin2_emp'
                            name='admin2_emp'
                            options={options}
                            isClearable
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                handleInputChange({
                                  target: {
                                    name: 'admin2_emp',
                                    value: selectedOption.value,
                                  }
                                });
                              } else {
                                handleInputChange({
                                  target: {
                                    name: 'admin2_emp',
                                    value: '',
                                  }
                                });
                              }
                            }}
                            value={formData.admin2_emp ? { value: formData.admin2_emp, label: formData.admin2_emp } : null}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '20vw',
                                minHeight: '50px',
                                borderRadius: '10px',
                                border: '1px solid #e3e3e3',
                                margin: '10px 0px 20px 0px',
                                color: '#4a4a4a',
                                backgroundColor: '#f7f8fa',
                                padding: '0px 10px',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              menu: (provided) => ({
                                ...provided,
                                borderRadius: '10px',
                                marginTop: '0',
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                            }}
                          />}
                        {errors.admin2_emp && (
                          <span className="error-message font-size-text ">{errors.admin2_emp}</span>
                        )}
                      </div>
                      : null}
                  </div>
                </li>

                <li >
                  <div className={`flex-row ${formData.admin3 ? 'justify-evenly' : ''}`}>
                    <div className="flex-column align-center">
                      <div className="flex-row ">
                        <div className="checkbox-toggle">
                          <input
                            type="checkbox"
                            id="toggle_admin3"
                            name="admin3"
                            checked={formData.admin3}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="toggle_admin3"></label>
                        </div>
                        <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                        <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                          {formData.admin3 ? "Need" : "No"} Admin 3 Approval {" "}
                        </label>
                      </div>
                    </div>
                    {formData.admin3 ?
                      <div className="flex-column align-centerform-group-selectt">
                        <label htmlFor="admin3_emp" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Assigned Admin 3{formData.admin3 ? <span className="required">*</span> : null}
                        </label>
                        {buffer1 ?
                          <div className="form-loader-date">
                            <div className="bar-loader"></div>
                          </div> :
                          <Select
                            id='admin3_emp'
                            name='admin3_emp'
                            options={options}
                            isClearable
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                handleInputChange({
                                  target: {
                                    name: 'admin3_emp',
                                    value: selectedOption.value,
                                  }
                                });
                              } else {
                                handleInputChange({
                                  target: {
                                    name: 'admin3_emp',
                                    value: '',
                                  }
                                });
                              }
                            }}
                            value={formData.admin3_emp ? { value: formData.admin3_emp, label: formData.admin3_emp } : null}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '20vw',
                                minHeight: '50px',
                                borderRadius: '10px',
                                border: '1px solid #e3e3e3',
                                margin: '10px 0px 20px 0px',
                                color: '#4a4a4a',
                                backgroundColor: '#f7f8fa',
                                padding: '0px 10px',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              menu: (provided) => ({
                                ...provided,
                                borderRadius: '10px',
                                marginTop: '0',
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                            }}
                          />}
                        {errors.admin3_emp && (
                          <span className="error-message font-size-text ">{errors.admin3_emp}</span>
                        )}
                      </div>
                      : null}
                  </div>
                </li>
                <li>
                  <div className="flex-column align-center">
                    <div className="flex-row ">
                      <div className="checkbox-toggle">
                        <input
                          type="checkbox"
                          id="toggle_payment_settlement"
                          name="payment_settlement"
                          checked={formData.payment_settlement}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="toggle_payment_settlement"></label>
                      </div>
                      <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                      <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                        {formData.payment_settlement ? "Need" : "No"} Payment Settlement Approval {" "}
                      </label>
                    </div>
                  </div>
                </li>
              </ol>

              <div className="button-models">
                <button className="model-button model-button-cancel font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
              <p className="error-message font-size-text">
                {isSubmitted && Object.keys(errors).length > 0 && (
                  Object.keys(errors).length > 5 ? (
                    <h5 className="text-center">Please fill all mandatory fields!</h5>
                  ) : (

                    <h6 className="text-center">
                      {Object.keys(errors).map((field) =>
                        field.charAt(0).toUpperCase() + field.slice(1)
                      ).join(', ') + ' are required!'}
                    </h6>
                  )
                )}
              </p>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const UpdateApprovalStage = ({ i, getStagesData }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setFormData({
      approved_for: "",
      checker: false,
      authority: false,
      account: false,
      admin1: false,
      admin1_emp: "",
      admin2: false,
      admin2_emp: "",
      admin3: false,
      admin3_emp: "",
      payment_settle: true,
    })
    setShow(false)
  };

  const handleShow = () => {
    setFormData(i);
    getEmployeeList();
    setShow(true)
  };


  const [employeeList, setEmployeeList] = useState([]);
  const [buffer1, setBuffering1] = useState(false);

  const getEmployeeList = async (emp_code) => {
    setBuffering1(true); //buffering logic
    try {
      const employeeList = await axios.get(`${BASE_URL}/siteproject/getemprole/`);
      setEmployeeList(employeeList.data);
    } catch (err) {
      console.error("Error fetching Employee Data", err);
    } finally {
      setBuffering1(false); //buffering logic
    }
  };


  const [formData, setFormData] = useState({
    approved_for: "",
    checker: false,
    authority: false,
    account: false,
    admin1: false,
    admin1_emp: "",
    admin2: false,
    admin2_emp: "",
    admin3: false,
    admin3_emp: "",
    payment_settle: false,
  });

  //**************************valedation Start**************** */
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "approved_for",
      formData.admin1 ? "admin1_emp" : null,
      formData.admin2 ? "admin2_emp" : null,
      formData.admin3 ? "admin3_emp" : null,
    ].filter(Boolean);

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });

    if (errors[name]) {
      setErrors((prevErrors) => {
        const { [name]: removedError, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => {
        const { [name]: removedError, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  //**************************valedation End**************** */
  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.put(
          `${BASE_URL}/siteproject/approvalupdate/${i.id}/`,
          formData
        );
        if (res.status === 200) {
          await getStagesData();
          handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        handleErrorToast(err)
        //toast Logic
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const options = employeeList
    .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
    .map((e) => ({
      value: e.emp_code,
      label: `${e.emp_code} - ${e.name}`,
    }));


  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <UpdatePencil />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton>
          <Modal.Title>Update Approval Stages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column form-group-selectt">
                <label htmlFor="approved_for " className="form-labels  font-weight500    font-size-subheading">
                  Approval For<span className="required">*</span>
                </label>
                <br />
                <select
                  id="approved_for"
                  name="approved_for"
                  value={formData.approved_for}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.approved_for ? "error" : inputState.approved_for ? "success" : ""}`}
                >
                  <option value="">Select Salary Approval For Type</option>
                  <option value="Imprest">Imprest</option>
                  <option value="Expense">Expense</option>
                  <option value="Office">Office</option>
                  <option value="Vehicle">Vehicle</option>
                  <option value="Invoice">Invoice</option>
                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                {errors.approved_for && (
                  <span className="error-message font-size-text ">{errors.approved_for}</span>
                )}
              </div>

              <ol>
                <li>
                  <div className="flex-column align-center">
                    <div className="flex-row ">
                      <div className="checkbox-toggle">
                        <input
                          type="checkbox"
                          id="toggle_checker"
                          name="checker"
                          checked={formData.checker}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="toggle_checker"></label>
                      </div>
                      <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                      <label htmlFor="checker" className="form-labels  font-weight500    font-size-subheading">
                        {formData.checker ? "Need" : "No"} Checker Approval {" "}
                      </label>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="flex-column align-center">
                    <div className="flex-row ">
                      <div className="checkbox-toggle">
                        <input
                          type="checkbox"
                          id="toggle_authority"
                          name="authority"
                          checked={formData.authority}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="toggle_authority"></label>
                      </div>
                      <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                      <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                        {formData.authority ? "Need" : "No"} Authority Approval {" "}
                      </label>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="flex-column align-center">
                    <div className="flex-row ">
                      <div className="checkbox-toggle">
                        <input
                          type="checkbox"
                          id="toggle_account"
                          name="account"
                          checked={formData.account}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="toggle_account"></label>
                      </div>
                      <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                      <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                        {formData.account ? "Need" : "No"} Account Approval {" "}
                      </label>
                    </div>
                  </div>
                </li>

                <li >
                  <div className={`flex-row ${formData.admin1 ? 'justify-evenly' : ''}`}>
                    <div className="flex-column align-center">
                      <div className="flex-row ">
                        <div className="checkbox-toggle">
                          <input
                            type="checkbox"
                            id="toggle_admin1"
                            name="admin1"
                            checked={formData.admin1}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="toggle_admin1"></label>
                        </div>
                        <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                        <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                          {formData.admin1 ? "Need" : "No"} Admin 1 Approval {" "}
                        </label>
                      </div>
                    </div>

                    {formData.admin1 ?
                      <div className="flex-column align-centerform-group-selectt">
                        <label htmlFor="admin1_emp" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Assigned Admin 1{formData.admin1 ? <span className="required">*</span> : null}
                        </label>
                        {buffer1 ?
                          <div className="form-loader-date">
                            <div className="bar-loader"></div>
                          </div> :
                          <Select
                            id='admin1_emp'
                            name='admin1_emp'
                            options={options}
                            isClearable
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                handleInputChange({
                                  target: {
                                    name: 'admin1_emp',
                                    value: selectedOption.value,
                                  }
                                });
                              } else {
                                handleInputChange({
                                  target: {
                                    name: 'admin1_emp',
                                    value: '',
                                  }
                                });
                              }
                            }}
                            value={formData.admin1_emp ? { value: formData.admin1_emp, label: formData.admin1_emp } : null}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '20vw',
                                minHeight: '50px',
                                borderRadius: '10px',
                                border: '1px solid #e3e3e3',
                                margin: '10px 0px 20px 0px',
                                color: '#4a4a4a',
                                backgroundColor: '#f7f8fa',
                                padding: '0px 10px',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              menu: (provided) => ({
                                ...provided,
                                borderRadius: '10px',
                                marginTop: '0',
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                            }}
                          />}
                        {errors.admin1_emp && (
                          <span className="error-message font-size-text ">{errors.admin1_emp}</span>
                        )}
                      </div>
                      : null}
                  </div>
                </li>

                <li >
                  <div className={`flex-row ${formData.admin2 ? 'justify-evenly' : ''}`}>
                    <div className="flex-column align-center">
                      <div className="flex-row ">
                        <div className="checkbox-toggle">
                          <input
                            type="checkbox"
                            id="toggle_admin2"
                            name="admin2"
                            checked={formData.admin2}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="toggle_admin2"></label>
                        </div>
                        <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                        <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                          {formData.admin2 ? "Need" : "No"} Admin 2 Approval {" "}
                        </label>
                      </div>
                    </div>
                    {formData.admin2 ?
                      <div className="flex-column align-centerform-group-selectt">
                        <label htmlFor="admin2_emp" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Assigned Admin 2{formData.admin2 ? <span className="required">*</span> : null}
                        </label>
                        {buffer1 ?
                          <div className="form-loader-date">
                            <div className="bar-loader"></div>
                          </div> :
                          <Select
                            id='admin2_emp'
                            name='admin2_emp'
                            options={options}
                            isClearable
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                handleInputChange({
                                  target: {
                                    name: 'admin2_emp',
                                    value: selectedOption.value,
                                  }
                                });
                              } else {
                                handleInputChange({
                                  target: {
                                    name: 'admin2_emp',
                                    value: '',
                                  }
                                });
                              }
                            }}
                            value={formData.admin2_emp ? { value: formData.admin2_emp, label: formData.admin2_emp } : null}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '20vw',
                                minHeight: '50px',
                                borderRadius: '10px',
                                border: '1px solid #e3e3e3',
                                margin: '10px 0px 20px 0px',
                                color: '#4a4a4a',
                                backgroundColor: '#f7f8fa',
                                padding: '0px 10px',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              menu: (provided) => ({
                                ...provided,
                                borderRadius: '10px',
                                marginTop: '0',
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                            }}
                          />}
                        {errors.admin2_emp && (
                          <span className="error-message font-size-text ">{errors.admin2_emp}</span>
                        )}
                      </div>
                      : null}
                  </div>
                </li>

                <li >
                  <div className={`flex-row ${formData.admin3 ? 'justify-evenly' : ''}`}>
                    <div className="flex-column align-center">
                      <div className="flex-row ">
                        <div className="checkbox-toggle">
                          <input
                            type="checkbox"
                            id="toggle_admin3"
                            name="admin3"
                            checked={formData.admin3}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="toggle_admin3"></label>
                        </div>
                        <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                        <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                          {formData.admin3 ? "Need" : "No"} Admin 3 Approval {" "}
                        </label>
                      </div>
                    </div>
                    {formData.admin3 ?
                      <div className="flex-column align-centerform-group-selectt">
                        <label htmlFor="admin3_emp" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                          Assigned Admin 3{formData.admin3 ? <span className="required">*</span> : null}
                        </label>
                        {buffer1 ?
                          <div className="form-loader-date">
                            <div className="bar-loader"></div>
                          </div> :
                          <Select
                            id='admin3_emp'
                            name='admin3_emp'
                            options={options}
                            isClearable
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                handleInputChange({
                                  target: {
                                    name: 'admin3_emp',
                                    value: selectedOption.value,
                                  }
                                });
                              } else {
                                handleInputChange({
                                  target: {
                                    name: 'admin3_emp',
                                    value: '',
                                  }
                                });
                              }
                            }}
                            value={formData.admin3_emp ? { value: formData.admin3_emp, label: formData.admin3_emp } : null}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '20vw',
                                minHeight: '50px',
                                borderRadius: '10px',
                                border: '1px solid #e3e3e3',
                                margin: '10px 0px 20px 0px',
                                color: '#4a4a4a',
                                backgroundColor: '#f7f8fa',
                                padding: '0px 10px',
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              menu: (provided) => ({
                                ...provided,
                                borderRadius: '10px',
                                marginTop: '0',
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: '#4a4a4a',
                              }),
                            }}
                          />}
                        {errors.admin3_emp && (
                          <span className="error-message font-size-text ">{errors.admin3_emp}</span>
                        )}
                      </div>
                      : null}
                  </div>
                </li>
                <li>
                  <div className="flex-column align-center">
                    <div className="flex-row ">
                      <div className="checkbox-toggle">
                        <input
                          type="checkbox"
                          id="toggle_payment_settlement"
                          name="payment_settlement"
                          checked={formData.payment_settlement}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="toggle_payment_settlement"></label>
                      </div>
                      <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                      <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                        {formData.payment_settlement ? "Need" : "No"} Payment Settlement Approval {" "}
                      </label>
                    </div>
                  </div>
                </li>
              </ol>

              <div className="button-models">
                <button className="model-button model-button-cancel font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
              <p className="error-message font-size-text">
                {isSubmitted && Object.keys(errors).length > 0 && (
                  Object.keys(errors).length > 5 ? (
                    <h5 className="text-center">Please fill all mandatory fields!</h5>
                  ) : (

                    <h6 className="text-center">
                      {Object.keys(errors).map((field) =>
                        field.charAt(0).toUpperCase() + field.slice(1)
                      ).join(', ') + ' are required!'}
                    </h6>
                  )
                )}
              </p>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


const DeleteApprovalStage = ({ i, getStagesData }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(
        `${BASE_URL}/siteproject/approvalupdate/${i.id}/`
      );

      if (res.status === 200) {
        await getStagesData();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };
  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Approval Stage for "{i.approved_for}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Approval Stage for "{i.approved_for}""
            <div className="button-models">
              <button className="model-button model-button-cancel font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button font-weight500 model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const ApprovalStagesTable = () => {
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [message, setMessagename] = useState([]);

  const getStagesData = async () => {
    try {
      setBuffering(true); //buffering logic // Start Buffering
      const res = await axios.get(
        `${BASE_URL}/siteproject/approvalget/`
      );
      setMessagename(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getStagesData();
  }, []);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">Approval Stages</div>
          <div>
            <AddApprovalStages getStagesData={getStagesData} />
          </div>
        </div>
        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr no.</th>
              <th className="align-center">Approved For</th>
              <th className="align-center">Checker</th>
              <th className="align-center">Authority</th>
              <th className="align-center">Account</th>
              <th className="align-center">Admin 1</th>
              <th className="align-center">Admin 2</th>
              <th className="align-center">Admin 3</th>
              <th className="align-center">Payment Settlement</th>
              <th className="align-right width-5vw">Action</th>
            </tr>
          </thead>
          {buffer ? (
            <div className="spinner"></div> // buffering logic
          ) : (
            <tbody>
              {message.map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="10"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-center">{i.approved_for}</td>
                    <td className="align-center">{i.checker ? "Yes" : "No"}</td>
                    <td className="align-center">{i.authority ? "Yes" : "No"}</td>
                    <td className="align-center">{i.account ? "Yes" : "No"}</td>
                    <td className="align-center">{i.admin1 ? "Yes" : "No"} {i.admin1_emp ? `(${i.admin1_emp})` : null}</td>
                    <td className="align-center">{i.admin2 ? "Yes" : "No"} {i.admin2_emp ? `(${i.admin2_emp})` : null}</td>
                    <td className="align-center">{i.admin3 ? "Yes" : "No"} {i.admin3_emp ? `(${i.admin3_emp})` : null}</td>
                    <td className="align-center">{i.payment_settlement ? "Yes" : "No"}</td>
                    <td className="align-right width-5vw">
                      <UpdateApprovalStage i={i} getStagesData={getStagesData} />
                      <DeleteApprovalStage i={i} getStagesData={getStagesData} />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default ApprovalStagesTable;
