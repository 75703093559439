import { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import * as XLSX from 'xlsx';
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { handleErrorToast } from "../CustomFunctions";
import { Modal } from "react-bootstrap";
import { UploadIcon } from "../AllSvg"

const UploadSiteProject = ({ id, getProject, ProjectList, getContractorProjects }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Project Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Project Detail Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const checkForDuplicates = (data) => {
        const sitecodes = new Map();
        const duplicates = [];

        // Track positions of each insurance number
        data.forEach((i, index) => {
            const projectcode = i.site_prcode;
            if (sitecodes.has(projectcode)) {
                // If the insurance number already exists, add the current position to its list
                sitecodes.get(projectcode).push(index + 1); // index + 1 for 1-based position
            } else {
                // If it's the first time seeing this insurance number, initialize its position list
                sitecodes.set(projectcode, [index + 1]);
            }
        });

        // Now, filter out only the numbers that appear more than once
        sitecodes.forEach((positions, projectcode) => {
            if (positions.length > 1) {
                duplicates.push(`\Site Project Code :${projectcode} is found in position(s) ${positions.join(", ")}`);
            }
        });

        return duplicates;
    };


    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };

            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {

                    if (["agreement_date", "commencement_date", "loa_award_date", "actual_completion_date", "schedule_completion_date", "actual_construction_completion_date", "schedule_construction_completion_date", "bid_due_date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                return obj;
            });


            // Ensure the input to checkForDuplicates is an array
            const duplicates = checkForDuplicates(formattedJson);

            if (duplicates.length > 0) {
                // Toast notification with line breaks for each duplicate
                toast.error(
                    `Duplicate Projects found:\n${duplicates.join("\n")}`,
                    {
                        autoClose: 5000, // Close toast after 5 seconds
                    }
                );
            }


            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);

    };

    // Function to handle the upload button click
    const handleUpload = async () => {

        if (excelJson) {
            const duplicates = checkForDuplicates(excelJson);

            if (duplicates.length > 0) {
                toast.error(`Duplicate Data found:\n ${duplicates.join(", ")}`, {
                    autoClose: 5000,
                });
            }
            const loadingToastId = toast.loading("Uploading: Please wait...");
            try {
                const res = await axios.post(`${BASE_URL}/siteproject/projectbulkuploadadd/`, excelJson);
                if (res.status === 200) {
                    await getProject();
                    await getContractorProjects();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Projects uploaded successfully.");

                } else {
                    toast.dismiss(loadingToastId);
                    toast.error("Failed to add data!");
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Project Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>

                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-left">site_prcode</th>
                                    <th className="table-heading-text">project_name</th>
                                    <th className="table-heading-text">project_short_name</th>
                                    <th className="table-heading-text">location</th>
                                    <th className="table-heading-text">sub_company</th>
                                    <th className="table-heading-text">work_type</th>
                                    <th className="table-heading-text">bid_due_date</th>
                                    <th className="table-heading-text">commencement_date</th>
                                    <th className="table-heading-text">loa_award_date</th>
                                    <th className="table-heading-text">actual_construction_completion_date</th>
                                    <th className="table-heading-text">schedule_construction_completion_date</th>
                                    <th className="table-heading-text">agreement_date</th>
                                    <th className="table-heading-text">concern_person_name</th>
                                    <th className="table-heading-text">tender_id</th>
                                    <th className="table-heading-text">actual_completion_date</th>
                                    <th className="table-heading-text">schedule_completion_date</th>
                                    <th className="table-heading-text">remark</th>
                                    <th className="table-heading-text">project_length</th>
                                    <th className="table-heading-text">consultancy_fees</th>
                                    <th className="table-heading-text">contract_mode</th>
                                    <th className="table-heading-text">jv</th>
                                    <th className="table-heading-text">lead</th>
                                    <th className="table-heading-text">our_share</th>
                                    <th className="table-heading-text">association</th>
                                    <th className="table-heading-text">client_name</th>
                                    <th className="table-heading-text">project_key_point</th>
                                    <th className="table-heading-text">ro</th>
                                    <th className="align-right">ro_address</th>
                                    <th className="align-right">piu_address</th>
                                    <th className="align-right">project_duration</th>
                                    <th className="align-right">our_role</th>
                                    <th className="align-right">site_office_address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="align-left">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background scroll-container"
                    >
                        <div className="table-container scrollbar" style={{ width: "100%" }}>
                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead className="table-heading">
                                    <tr className="custom-table-head-tr modal-padding-heading">
                                        <th className="table-heading-text">Project Code</th>
                                        <th className="table-heading-text">Project Name</th>
                                        <th className="table-heading-text">Project Short Name</th>
                                        <th className="table-heading-text">Location</th>
                                        <th className="table-heading-text">Sub Company</th>
                                        <th className="table-heading-text">Work Type</th>
                                        <th className="table-heading-text">Bid Due Date</th>
                                        <th className="table-heading-text">Commencement Date</th>
                                        <th className="table-heading-text">LOA Award Date</th>
                                        <th className="table-heading-text">Actual Construction Completion Date</th>
                                        <th className="table-heading-text">Schedule Construction Completion Date</th>
                                        <th className="table-heading-text">Agreement Date</th>
                                        <th className="table-heading-text">Concern Person Name</th>
                                        <th className="table-heading-text">Tender ID</th>
                                        <th className="table-heading-text">Actual Completion Date</th>
                                        <th className="table-heading-text">Schedule Completion Date</th>
                                        <th className="table-heading-text">Remark</th>
                                        <th className="table-heading-text">Project Length</th>
                                        <th className="table-heading-text">Consultancy Fees</th>
                                        <th className="table-heading-text">Contract Mode</th>
                                        <th className="table-heading-text">JV</th>
                                        <th className="table-heading-text">Lead</th>
                                        <th className="table-heading-text">Our Share</th>
                                        <th className="table-heading-text">Association</th>
                                        <th className="table-heading-text">Client Name</th>
                                        <th className="table-heading-text">Project Key Point</th>
                                        <th className="table-heading-text">RO</th>
                                        <th className="table-heading-text">RO Address</th>
                                        <th className="table-heading-text">PIU Address</th>
                                        <th className="table-heading-text">Project Duration</th>
                                        <th className="table-heading-text">Our Role</th>
                                        <th className="table-heading-text">Site Office Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((project, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            <th className="table-heading-text">{project.site_prcode}</th>
                                            <th className="table-heading-text">{project.project_name}</th>
                                            <th className="table-heading-text">{project.project_short_name}</th>
                                            <th className="table-heading-text">{project.location}</th>
                                            <th className="table-heading-text">{project.sub_company}</th>
                                            <th className="table-heading-text">{project.work_type}</th>
                                            <th className="table-heading-text">{project.bid_due_date}</th>
                                            <th className="table-heading-text">{project.commencement_date}</th>
                                            <th className="table-heading-text">{project.loa_award_date}</th>
                                            <th className="table-heading-text">{project.actual_construction_completion_date}</th>
                                            <th className="table-heading-text">{project.schedule_construction_completion_date}</th>
                                            <th className="table-heading-text">{project.agreement_date}</th>
                                            <th className="table-heading-text">{project.concern_person_name}</th>
                                            <th className="table-heading-text">{project.tender_id}</th>
                                            <th className="table-heading-text">{project.actual_completion_date}</th>
                                            <th className="table-heading-text">{project.schedule_completion_date}</th>
                                            <th className="table-heading-text">{project.remark}</th>
                                            <th className="table-heading-text">{project.project_length}</th>
                                            <th className="table-heading-text">{project.consultancy_fees}</th>
                                            <th className="table-heading-text">{project.contract_mode}</th>
                                            <th className="table-heading-text">{project.jv}</th>
                                            <th className="table-heading-text">{project.lead}</th>
                                            <th className="table-heading-text">{project.our_share}</th>
                                            <th className="table-heading-text">{project.association}</th>
                                            <th className="table-heading-text">{project.client_name}</th>
                                            <th className="table-heading-text">{project.project_key_point}</th>
                                            <th className="table-heading-text">{project.ro}</th>
                                            <th className="table-heading-text">{project.ro_address}</th>
                                            <th className="table-heading-text">{project.piu_address}</th>
                                            <th className="table-heading-text">{project.project_duration}</th>
                                            <th className="table-heading-text">{project.our_role}</th>
                                            <th className="table-heading-text">{project.site_office_address}</th>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>

                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">General:</p>
                            <ul className="arrow-list">
                                <li>Site prcode should be unique</li>

                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">For Work Type use one of these:</p>
                            <ul className="arrow-list">
                                <li>construction consultant</li>
                                <li>operations & maintenance consultant</li>
                                <li>safety consultant</li>
                                <li>dpr</li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">For Our Role use one of these:</p>
                            <ul className="arrow-list">
                                <li>active</li>
                                <li>partially</li>
                                <li>silent</li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">For Contract Mode use one of these:</p>
                            <ul className="arrow-list">
                                <li>epc</li>
                                <li>ham</li>
                                <li>bot</li>
                            </ul>
                        </div>
                        <div className="date-section">
                            <p className="date-text">All Dates should be in DD-MM-YYYY format</p>
                        </div>
                    </div>


                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};
const UploadLetterBulk = ({ id, getLetters, site_prcode, contractor_name, letterBy }) => {




    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Letter Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Letter Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null);
        setDocErrors({});
        setShow(false);
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const checkForDuplicates = (data) => {
        const lettersData = new Map();
        const duplicates = [];

        data.forEach((i, index) => {
            const letterNumber = i.letter_num;
            if (lettersData.has(letterNumber)) {
                lettersData.get(letterNumber).push(index + 1); // index + 1 for 1-based position
            } else {
                lettersData.set(letterNumber, [index + 1]);
            }
        });

        lettersData.forEach((positions, letterNumber) => {
            if (positions.length > 1) {
                duplicates.push(`\n Letter Number:${letterNumber} is found in position(s) ${positions.join(", ")}`);
            }
        });

        return duplicates;
    };


    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };
            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {
                    if (["letter_date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });

                // If project_id exists, inject it into each row
                if (site_prcode) {
                    obj["project"] = site_prcode;
                }

                if (letterBy) {
                    obj["workperformedby"] = letterBy;
                }
                if (letterBy === "contractor") {
                    if (contractor_name) {
                        obj["contractor"] = contractor_name;
                    }
                }

                return obj;
            });

            const duplicates = checkForDuplicates(formattedJson);

            if (duplicates.length > 0) {
                toast.error(
                    `Duplicate Letter Numbers found:\n${duplicates.join("\n")}`,
                    {
                        autoClose: 5000, // Close toast after 5 seconds
                    }
                );
            }


            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };




    const handleUpload = async () => {

        if (excelJson) {

            const duplicates = checkForDuplicates(excelJson);

            if (duplicates.length > 0) {
                toast.error(`Duplicate Data found! Policy Number ${duplicates.join(", ")}`, {
                    autoClose: 5000,
                });
            }

            const loadingToastId = toast.loading("Uploading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/siteproject/letterbulkuploadadd/`, excelJson);
                if (res.status === 200) {
                    await getLetters();
                    handleClose();
                    toast.success("Letters Data Added Successfully");
                    toast.dismiss(loadingToastId);
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };






    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print equal-hieght" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Letter Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    {site_prcode ? "" :
                                        <th className="align-left">project</th>
                                    }
                                    <th className="align-center">letter_num</th>
                                    <th className="align-center">reply_by</th>
                                    <th className="align-center">subject</th>
                                    <th className="align-center">letter_date</th>
                                    <th className="align-center">status</th>
                                    {letterBy ? "" :
                                        <th className="align-center">workperformedby</th>
                                    }
                                    <th className="align-center">lettertype</th>
                                    <th className="align-center">letterExchange</th>
                                    <th className="align-center">letterdepartment</th>
                                    <th className="align-center">letter_from</th>
                                    <th className="align-center">letter_to</th>
                                    {contractor_name ? "" :
                                        <th className="align-center">contractor</th>
                                    }

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {site_prcode ? "" :
                                        <th className="align-left">-</th>
                                    }
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    {letterBy ? "" :
                                        <th className="align-center">-</th>
                                    }
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    {contractor_name ? "" :
                                        <th className="align-center">-</th>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-css-white-background scroll-container">
                        <div className="table-container scrollbar" style={{ width: "100%" }}>
                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead className="table-heading">
                                    <tr className="modal-padding-heading custom-table-head-tr">
                                        {!site_prcode && <th className="table-heading-text">Project Code</th>}
                                        <th className="table-heading-text">Letter Number</th>
                                        <th className="table-heading-text">Reply By</th>
                                        <th className="table-heading-text">Subject</th>
                                        <th className="table-heading-text">Letter Date</th>
                                        <th className="table-heading-text">Status</th>
                                        {!letterBy && <th className="table-heading-text">Work Performed By</th>}
                                        <th className="table-heading-text">Letter Type</th>
                                        <th className="table-heading-text">Letter Exchange</th>
                                        <th className="table-heading-text">Letter Department</th>
                                        <th className="table-heading-text">Letter From</th>
                                        <th className="table-heading-text">Letter To</th>
                                        {!contractor_name && <th className="table-heading-text">Contractor</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((letter, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            {!site_prcode && <th className="table-heading-text">{letter.project}</th>}
                                            <th className="table-heading-text">{letter.letter_num}</th>
                                            <th className="table-heading-text">{letter.reply_by}</th>
                                            <th className="table-heading-text">{letter.subject}</th>
                                            <th className="table-heading-text">{letter.letter_date}</th>
                                            <th className="table-heading-text">{letter.status}</th>
                                            {!letterBy && <th className="table-heading-text">{letter.workperformedby}</th>}
                                            <th className="table-heading-text">{letter.lettertype}</th>
                                            <th className="table-heading-text">{letter.letterExchange}</th>
                                            <th className="table-heading-text">{letter.letterdepartment}</th>
                                            <th className="table-heading-text">{letter.letter_from}</th>
                                            <th className="table-heading-text">{letter.letter_to}</th>
                                            {!contractor_name && <th className="table-heading-text">{letter.contractor}</th>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>


                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">General</p>
                            <ul className="arrow-list">
                                <li>Letter number should be unique.</li>
                                {letterBy ? "" : <>
                                    {!site_prcode &&
                                        (
                                            <>
                                                <li>Fill existing project only.</li>
                                                <li>In project field fill site prcode only.</li>
                                            </>
                                        )
                                    }
                                    <li>Insert existing contractor only.</li>
                                    <li>In contractor fill contractor name.</li>
                                    <li>Add contractor name only if the value of work performed by is "contractor"</li>
                                </>
                                }
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">For Letter Exchange use one of these:</p>
                            <ul className="arrow-list">
                                <li>send</li>
                                <li>receive</li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">For Reply By use one of these:</p>
                            <ul className="arrow-list">
                                <li>HO</li>
                                <li>SO</li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">For Letter Type use one of these:</p>
                            <ul className="arrow-list">
                                <li>show-cause</li>
                                <li>informative</li>
                                <li>eot</li>
                                <li>cos</li>
                                <li>billing</li>
                                <li>design</li>
                                <li>ncr</li>
                                <li>other</li>
                            </ul>
                        </div>
                        {letterBy ? "" :
                            <div className="reference-section">
                                <p className="reference-title">For Work Performed By use one of these:</p>
                                <ul className="arrow-list">
                                    <li>contractor</li>
                                    <li>consultant</li>
                                </ul>
                            </div>
                        }
                        <div className="date-section">
                            <p className="date-text">All Dates should be in DD-MM-YYYY format</p>
                        </div>
                    </div>

                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};
const VehicleModelBulk = ({ id, getVehicle, site_prcode }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Vehicle Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Vehicle Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };



    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);



            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {

                    obj[header] = row[index] !== undefined ? row[index] : null;
                });
                if (site_prcode) {
                    obj["project"] = site_prcode;
                }
                return obj;
            });


            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };


    // Function to handle the upload button click
    const handleUpload = async () => {
        if (excelJson) {
            const loadingToastId = toast.loading("Uploading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/siteproject/vehiclebulkupload/`, excelJson);
                if (res.status === 200) {
                    await getVehicle();
                    handleClose();
                    toast.success("Vehicles Data Added Successfully");
                    toast.dismiss(loadingToastId);
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };







    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print eqqual-hieght" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Vehicle Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    {site_prcode ? "" :
                                        <th className="align-left">project</th>
                                    }
                                    <th className="align-center">name</th>
                                    <th className="align-center">vehicle_num</th>
                                    <th className="align-center">fixed_km</th>
                                    <th className="align-center">fixed_bill_amt</th>
                                    <th className="align-center">extra_amt_above_fix_km</th>
                                    <th className="align-center">bank_name</th>
                                    <th className="align-center">account_holder_name</th>
                                    <th className="align-center">account_num</th>
                                    <th className="align-center">ifsc_code</th>
                                    <th className="align-center">pan_card</th>
                                    <th className="align-center">gst_num</th>
                                    <th className="align-center">address</th>
                                    <th className="align-center">mobile</th>
                                    <th className="align-center">km_per_lit</th>
                                    <th className="align-center">fuel_price</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {site_prcode ? "" :
                                        <th className="align-left">-</th>
                                    }
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background scroll-container">
                        <div className="table-container scrollbar" style={{ width: "100%" }}>
                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead>
                                    <tr className="custom-table-head-tr  modal-padding-heading">
                                        {site_prcode ? "" :
                                            <th className="table-heading-text">Project</th>
                                        }
                                        <th className="table-heading-text">Vehicle Name</th>
                                        <th className="table-heading-text">Vehicle Num</th>
                                        <th className="table-heading-text">Fixed Km</th>
                                        <th className="table-heading-text">Fixed Bill Amt</th>
                                        <th className="table-heading-text">Extra Amt Above Fix Km</th>
                                        <th className="table-heading-text">Bank Name</th>
                                        <th className="table-heading-text">Account Holder Name</th>
                                        <th className="table-heading-text">Account Num</th>
                                        <th className="table-heading-text">IFSC Code</th>
                                        <th className="table-heading-text">PAN Card</th>
                                        <th className="table-heading-text">GST Num</th>
                                        <th className="table-heading-text">Address</th>
                                        <th className="table-heading-text">Mobile</th>
                                        <th className="table-heading-text">Km Per Lit</th>
                                        <th className="table-heading-text">Fuel Price</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((vehicle, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            {site_prcode ? "" :
                                                <th className="table-body">{vehicle.project}</th>
                                            }
                                            <th className="table-body">{vehicle.name}</th>
                                            <th className="table-body">{vehicle.vehicle_num}</th>
                                            <th className="table-body">{vehicle.fixed_km}</th>
                                            <th className="table-body">{vehicle.fixed_bill_amt}</th>
                                            <th className="table-body">{vehicle.extra_amt_above_fix_km}</th>
                                            <th className="table-body">{vehicle.bank_name}</th>
                                            <th className="table-body">{vehicle.account_holder_name}</th>
                                            <th className="table-body">{vehicle.account_num}</th>
                                            <th className="table-body">{vehicle.ifsc_code}</th>
                                            <th className="table-body">{vehicle.pan_card}</th>
                                            <th className="table-body">{vehicle.gst_num}</th>
                                            <th className="table-body">{vehicle.address}</th>
                                            <th className="table-body">{vehicle.mobile}</th>
                                            <th className="table-body">{vehicle.km_per_lit}</th>
                                            <th className="table-body">{vehicle.fuel_price}</th>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>
                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            {/* <p className="reference-title">General:</p> */}
                            <ul className="arrow-list">
                                {site_prcode ? "" :
                                    <li>In project field use valid site prcode.</li>
                                }

                                <li>In name field fill the name of the vehicle.</li>
                            </ul>
                        </div>

                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const UploadOfficeRentBulk = ({ id, getOfficeRent }) => {




    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Office Rent Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Offcie Rent Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null);
        setDocErrors({});
        setShow(false);
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };



    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };
            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {
                    if (["agreement_date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });

                // If project_id exists, inject it into each row


                return obj;
            });
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };




    const handleUpload = async () => {

        if (excelJson) {

            const loadingToastId = toast.loading("Uploading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/siteproject/officerentbulkapi/`, excelJson);
                if (res.status === 200) {
                    await getOfficeRent();
                    handleClose();
                    toast.success("Offiece rent Data Added Successfully");
                    toast.dismiss(loadingToastId);
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };






    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print equal-hieght" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Office Rent Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">

                                    <th className="align-left">project</th>
                                    <th className="align-center">rent_amt</th>
                                    <th className="align-center">agreement_duration</th>
                                    <th className="align-center">agreement_date</th>
                                    <th className="align-center">owner_name</th>
                                    <th className="align-center">bank_name</th>
                                    <th className="align-center">account_holder_name</th>
                                    <th className="align-center">account_num</th>
                                    <th className="align-center">ifsc_code</th>
                                    <th className="align-center">pan_card</th>
                                    <th className="align-center">gst_num</th>
                                    <th className="align-center">address</th>
                                    <th className="align-center">mobile</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <th className="align-left">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-css-white-background scroll-container">
                        <div className="table-container scrollbar" style={{ width: "100%" }}>
                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead className="table-heading">
                                    <tr className="modal-padding-heading custom-table-head-tr">
                                        <th className="align-left">project</th>
                                        <th className="align-center">rent_amt</th>
                                        <th className="align-center">agreement_duration</th>
                                        <th className="align-center">agreement_date</th>
                                        <th className="align-center">owner_name</th>
                                        <th className="align-center">bank_name</th>
                                        <th className="align-center">account_holder_name</th>
                                        <th className="align-center">account_num</th>
                                        <th className="align-center">ifsc_code</th>
                                        <th className="align-center">pan_card</th>
                                        <th className="align-center">gst_num</th>
                                        <th className="align-center">address</th>
                                        <th className="align-center">mobile</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((officerent, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            <th className="table-heading-text">{officerent.project}</th>
                                            <th className="table-heading-text">{officerent.rent_amt}</th>
                                            <th className="table-heading-text">{officerent.agreement_duration}</th>
                                            <th className="table-heading-text">{officerent.agreement_date}</th>
                                            <th className="table-heading-text">{officerent.owner_name}</th>
                                            <th className="table-heading-text">{officerent.bank_name}</th>
                                            <th className="table-heading-text">{officerent.account_holder_name}</th>
                                            <th className="table-heading-text">{officerent.account_num}</th>
                                            <th className="table-heading-text">{officerent.ifsc_code}</th>
                                            <th className="table-heading-text">{officerent.pan_card}</th>
                                            <th className="table-heading-text">{officerent.gst_num}</th>
                                            <th className="table-heading-text">{officerent.address}</th>
                                            <th className="table-heading-text">{officerent.mobile}</th>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>


                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">General</p>
                            <ul className="arrow-list">
                                <li>In project field fill site prcode only.</li>
                                <li>Fill existing project only.</li>
                                <li>Agreement Duration should be number of months.</li>
                            </ul>

                        </div>
                        <p className="date-text">All Dates should be in DD-MM-YYYY format</p>

                    </div>

                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};
const UploadDesignation = ({ id, getDesignation }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Designation Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Designation Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };
    const checkForDuplicates = (data) => {
        const designations = new Map();
        const duplicates = [];

        // Track positions of each insurance number
        data.forEach((i, index) => {
            const designation = i.title;
            if (designations.has(designation)) {
                // If the insurance number already exists, add the current position to its list
                designations.get(designation).push(index + 1); // index + 1 for 1-based position
            } else {
                // If it's the first time seeing this insurance number, initialize its position list
                designations.set(designation, [index + 1]);
            }
        });

        // Now, filter out only the numbers that appear more than once
        designations.forEach((positions, designation) => {
            if (positions.length > 1) {
                duplicates.push(`\n Designation :${designation} is found in position(s) ${positions.join(", ")}`);
            }
        });

        return duplicates;
    };







    const readExcell = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows
                .map((row) => {
                    const obj = {};
                    headers.forEach((header, index) => {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    });
                    return obj;
                })
                .filter((row) =>
                    // Filter out rows where all values are null
                    Object.values(row).some((value) => value !== null)
                );

            // Check for rows where 'sub_company' is null
            const invalidRows = formattedJson.filter((row) => row['sub_company'] === null);
            if (invalidRows.length > 0) {
                // Show an error message and stop further processing
                toast.error(
                    "Upload failed: Some rows have 'sub_company' as null. Please fix the data and try again.",
                    {
                        autoClose: 3000, // Close toast after 3 seconds
                    }
                );
                return; // Stop execution if validation fails
            }

            const duplicates = checkForDuplicates(formattedJson);

            if (duplicates.length > 0) {
                // Toast notification with line breaks for each duplicate
                toast.error(
                    `Duplicate Designations found:\n${duplicates.join("\n")}`,
                    {
                        autoClose: 5000, // Close toast after 5 seconds
                    }
                );
            }

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };



    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows
                .map((row) => {
                    const obj = {};
                    headers.forEach((header, index) => {
                        obj[header] = row[index] !== undefined && row[index] !== null
                            ? String(row[index]).trim() // Remove whitespace
                            : null;
                    });
                    return obj;
                })
                .filter((row) =>
                    // Filter out rows where all values are null
                    Object.values(row).some((value) => value !== null)
                );

            // Check for rows where 'sub_company' is null
            const invalidRows = formattedJson.filter((row) => row['sub_company'] === null);
            if (invalidRows.length > 0) {
                // Show an error message and stop further processing
                toast.error(
                    "Upload failed: Some rows have 'sub_company' as null. Please fix the data and try again.",
                    {
                        autoClose: 3000, // Close toast after 3 seconds
                    }
                );
                return; // Stop execution if validation fails
            }

            const duplicates = checkForDuplicates(formattedJson);

            if (duplicates.length > 0) {
                // Toast notification with line breaks for each duplicate
                toast.error(
                    `Duplicate Designations found:\n${duplicates.join("\n")}`,
                    {
                        autoClose: 5000, // Close toast after 5 seconds
                    }
                );
            }

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };




    // Function to handle the upload button click
    const handleUpload = async () => {
        if (excelJson) {
            const duplicates = checkForDuplicates(excelJson);

            if (duplicates.length > 0) {
                toast.error(`Duplicate Data found:\n ${duplicates.join(", ")}`, {
                    autoClose: 5000,
                });
            }
            const loadingToastId = toast.loading("Uploading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/siteproject/designationuploadbulk/`, excelJson);
                if (res.status === 200) {
                    await getDesignation();
                    handleClose();
                    toast.success("Designation Data Added Successfully")
                    toast.dismiss(loadingToastId);

                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Site Designation Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-left">title</th>
                                    <th className="align-center">sub_company</th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="align-left">-</th>
                                    <th className="align-center">-</th>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background scroll-container">
                        <div className="table-container scrollbar" style={{ width: "100%" }}>

                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead>
                                    <tr className="custom-table-head-tr  modal-padding-heading">
                                        <th className="table-heading-text">Title</th>
                                        <th className="table-heading-text">Sub Company</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((designation, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            <th className="table-body">{designation.title}</th>
                                            <th className="table-body">{designation.sub_company}</th>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>
                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">General:</p>
                            <ul className="arrow-list">
                                <li>In title site designation name should be filled.</li>
                                <li>Title should be unique.</li>

                            </ul>
                        </div>
                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                    <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                </Modal.Body>
            </Modal>
        </>
    );
};
const UploadDepartment = ({ id, getdepartment }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Department Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Department Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const checkForDuplicates = (data) => {
        const title = new Map();
        const duplicates = [];

        // Track positions of each insurance number
        data.forEach((i, index) => {
            const department = i.title;
            if (title.has(department)) {
                // If the insurance number already exists, add the current position to its list
                title.get(department).push(index + 1); // index + 1 for 1-based position
            } else {
                // If it's the first time seeing this insurance number, initialize its position list
                title.set(department, [index + 1]);
            }
        });

        // Now, filter out only the numbers that appear more than once
        title.forEach((positions, department) => {
            if (positions.length > 1) {
                duplicates.push(`\n "${department}" Department is found in position(s) ${positions.join(", ")}`);
            }
        });

        return duplicates;
    };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);



            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {

                    obj[header] = row[index] !== undefined ? row[index] : null;
                });
                return obj;
            });
            const duplicates = checkForDuplicates(formattedJson);

            if (duplicates.length > 0) {
                // Toast notification with line breaks for each duplicate
                toast.error(
                    `Duplicate Departments found:\n${duplicates.join("\n")}`,
                    {
                        autoClose: 5000, // Close toast after 5 seconds
                    }
                );
            }

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };


    // Function to handle the upload button click
    const handleUpload = async () => {
        if (excelJson) {
            const loadingToastId = toast.loading("Uploading: Please wait...");
            const duplicates = checkForDuplicates(excelJson);

            if (duplicates.length > 0) {
                toast.error(`Duplicate Data found:\n ${duplicates.join(", ")}`, {
                    autoClose: 5000,
                });
            }
            try {
                const res = await axios.post(`${BASE_URL}/siteproject/departmentbulkuploadadd/`, excelJson);
                if (res.status === 200) {
                    await getdepartment();
                    handleClose();
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Site Department Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-left">title</th>
                                    <th className="align-center">sub_company</th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="align-left">-</th>
                                    <th className="align-center">-</th>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background scroll-container">
                        <div className="table-container scrollbar" style={{ width: "100%" }}>

                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead>
                                    <tr className="custom-table-head-tr modal-padding-heading">
                                        <th className="table-heading-text">Title</th>
                                        <th className="table-heading-text">Sub Company</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((department, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            <th className="table-heading-text">{department.title}</th>
                                            <th className="table-heading-text">{department.sub_company}</th>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>
                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">General:</p>
                            <ul className="arrow-list">
                                <li>In title site department name should be filled.</li>
                                <li>Title should be unique.</li>

                            </ul>
                        </div>
                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};
const UploadInventory = ({ id, getInventory, site_prcode }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Inventory Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Inventory Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };

            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {

                    if (["purchase_date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                if (site_prcode) {
                    obj["project"] = site_prcode;
                }
                return obj;
            });

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };


    // Function to handle the upload button click
    const handleUpload = async () => {
        if (excelJson) {
            const loadingToastId = toast.loading("Uploading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/siteproject/bulksiteinventory/`, excelJson);
                if (res.status === 200) {
                    await getInventory();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Inventory Details uploaded successfully.");

                } else {
                    toast.dismiss(loadingToastId);
                    toast.error("Failed to add data!");
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Site Inventory Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    {site_prcode ? "" :
                                        <th className="align-left">project</th>
                                    }
                                    <th className="align-left">emp_code</th>
                                    <th className="align-left">item_name</th>
                                    <th className="align-center">description</th>
                                    <th className="align-center">quantity</th>
                                    <th className="align-center">rate_per_item</th>
                                    <th className="align-center">purchase_date</th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {site_prcode ? "" :
                                        <th className="align-left">-</th>
                                    }
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background scroll-container">
                        <div className="table-container scrollbar" style={{ width: "100%" }}>

                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead>
                                    <tr className="custom-table-head-tr">
                                        {site_prcode ? "" :
                                            <th className="align-left">project</th>
                                        }
                                        <th className="align-left">emp_code</th>
                                        <th className="align-center">item_name</th>
                                        <th className="align-center">description</th>
                                        <th className="align-center">quantity</th>
                                        <th className="align-center">rate_per_item</th>
                                        <th className="align-center">purchase_date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((inventory, index) => (
                                        <tr key={index}>
                                            {site_prcode ? "" :
                                                <th className="align-center">{inventory.project}</th>
                                            }
                                            <th className="align-left">{inventory.emp_code}</th>
                                            <th className="align-left">{inventory.item_name}</th>
                                            <th className="align-center">{inventory.description}</th>
                                            <th className="align-center">{inventory.quantity}</th>
                                            <th className="align-center">{inventory.rate_per_item}</th>
                                            <th className="align-center">{inventory.purchase_date}</th>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>
                    <h4>References:</h4>
                    <div className="reference-container">

                    </div>
                    <div className="flex-row justify-between" style={{ alignItems: "baseline" }}>
                        <div>
                            <p className="reference-title">General:</p>

                            <ul className="arrow-list">

                                <li>In project field site project code should be filled. </li>
                                <li>Employee Code should be Valid </li>
                                <li>Employee Code should be Valid </li>
                                <li>All Dates should be in DD-MM-YYYY format</li>
                                <li>The item name should match exactly with the names in the Item List.</li>

                            </ul>
                        </div>

                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};
const UploadEotBulk = ({ id, getEotdata, site_prcode, workperformedby, contractor_name, letterData, projectId }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});
    const [trackRecordList, setTrackRecordList] = useState([]);


    const getLetters = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/letterrecordbclosestatus/${projectId}/${workperformedby}/eot/`);
            setTrackRecordList(res.data);
        } catch (error) {
            handleErrorToast(error);
        }
    }
    useEffect(() => {
        getLetters();
    }, [projectId, workperformedby])



    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "EOT Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "EOT Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const checkForDuplicates = (data) => {
        const EOT = new Map();
        const duplicates = [];

        // Track positions of each insurance number
        data.forEach((i, index) => {
            const EotCode = i.eot_code;
            if (EOT.has(EotCode)) {
                // If the insurance number already exists, add the current position to its list
                EOT.get(EotCode).push(index + 1); // index + 1 for 1-based position
            } else {
                // If it's the first time seeing this insurance number, initialize its position list
                EOT.set(EotCode, [index + 1]);
            }
        });

        // Now, filter out only the numbers that appear more than once
        EOT.forEach((positions, EotCode) => {
            if (positions.length > 1) {
                duplicates.push(`\n Eot Code :${EotCode} is found in position(s) ${positions.join(", ")}`);
            }
        });

        return duplicates;
    };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);
            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };
            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {

                    if (["process_initiate_date", "approved_date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                if (site_prcode) {
                    obj["project"] = site_prcode;
                }

                if (workperformedby) {
                    obj["workperformedby"] = workperformedby;

                }
                if (workperformedby === "contractor") {
                    if (contractor_name) {
                        obj["contractor"] = contractor_name;
                    }
                }
                if (workperformedby === "consultant") {
                    obj["contractor"] = null;
                }

                return obj;
            });

            const duplicates = checkForDuplicates(formattedJson);

            if (duplicates.length > 0) {
                // Toast notification with line breaks for each duplicate
                toast.error(
                    `Duplicate EOT found:\n${duplicates.join("\n")}`,
                    {
                        autoClose: 5000, // Close toast after 5 seconds
                    }
                );
            }

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);

    };

    // Function to handle the upload button click
    const handleUpload = async () => {
        if (!excelJson) {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
            return;
        }

        // Validate letter numbers in excelJson against trackRecordList
        const invalidRecords = excelJson.filter(
            (item) => !trackRecordList.some(
                (record) => String(record.letter_num).trim() === String(item.letter).trim()
            )
        );

        if (invalidRecords.length > 0) {
            // Handle invalid letter numbers
            const invalidLetters = invalidRecords.map((record) => record.letter).join(", ");
            toast.error(`The following letter numbers do not exist: ${invalidLetters}`);
            return;
        }

        const loadingToastId = toast.loading("Uploading: Please wait...");

        try {
            const res = await axios.post(`${BASE_URL}/siteproject/eotbulkupload/`, excelJson);
            if (res.status === 200) {
                await getEotdata(); // Refresh data after upload

                handleClose(); // Close modal or reset form
                toast.success("EOT Data added Successfully");
                toast.dismiss(loadingToastId);
            }
        } catch (err) {
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print equal-hieght" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk EOT Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">

                                    <th className="align-left">eot_code</th>
                                    <th className="align-left">process_initiate_date</th>
                                    <th className="align-left">approved_date</th>
                                    <th className="align-center">approved_days</th>
                                    <th className="align-center">letter</th>
                                    <th className="align-center">eot_status</th>



                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background scroll-container">
                        <div className="table-container scrollbar" style={{ width: "100%" }}>

                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead>
                                    <tr className="custom-table-head-tr  modal-padding-heading">
                                        <th className="tablle-heading-text">EOT Code</th>
                                        <th className="tablle-heading-text">Process Initiate Date</th>
                                        <th className="table-heading-text">Approved Date</th>
                                        <th className="table-heading-text">Approved Days</th>
                                        <th className="table-heading-text">Letter</th>
                                        <th className="table-heading-text">EOT Status</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((inventory, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            <th className="tablle-heading-text">{inventory.eot_code}</th>
                                            <th className="tablle-heading-text">{inventory.process_initiate_date}</th>
                                            <th className="tablle-heading-text">{inventory.approved_date}</th>
                                            <th className="table-heading-text">{inventory.approved_days}</th>
                                            <th className="table-heading-text">{inventory.letter}</th>
                                            <th className="table-heading-text">{inventory.eot_status}</th>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>

                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">   For eot status use one of these:</p>
                            <ul className="arrow-list">
                                <li>pending </li>
                                <li>Approved </li>
                                <li>rejected </li>
                            </ul>
                        </div>

                        <div className="reference-section">
                            <p className="reference-title">   For Letters:</p>
                            <ul className="arrow-list">
                                <li>Add Letter number in letter field </li>
                                <li>Insert existing letter number only </li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">   For EOT:</p>
                            <ul className="arrow-list">
                                <li>EOT code should be unique </li>
                            </ul>
                        </div>
                        <div className="date-section">
                            <p className="date-text">All Dates should be in DD-MM-YYYY format</p>
                        </div>
                    </div>

                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};
const UploadcosBulk = ({ id, getCOSData, site_prcode, workperformedby, contractor_name, letterData, projectId }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});
    const [trackRecordList, setTrackRecordList] = useState([]);


    const getLetters = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/letterrecordbclosestatus/${projectId}/${workperformedby}/cos/`);
            setTrackRecordList(res.data);
        } catch (error) {
            handleErrorToast(error);
        }
    }
    useEffect(() => {
        getLetters();
    }, [projectId, workperformedby])

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "COS Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "COS Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);
            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };


            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {

                    if (["process_initiate_date", "approval_date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                if (site_prcode) {
                    obj["project"] = site_prcode;
                }

                if (workperformedby) {
                    obj["workperformedby"] = workperformedby;

                }
                if (workperformedby === "contractor") {
                    if (contractor_name) {
                        obj["contractor"] = contractor_name;
                    }
                }
                return obj;
            });

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };
    const existingLetters = trackRecordList.map(record => record.letter_num);
    console.log(existingLetters);
    console.log(excelJson?.letter)
    // Function to handle the upload button click
    const handleUpload = async () => {
        if (!excelJson) {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
            return;
        }

        // Validate letter numbers in excelJson against trackRecordList
        const invalidRecords = excelJson.filter(
            (item) => !trackRecordList.some(
                (record) => String(record.letter_num).trim() === String(item.letter).trim()
            )
        );

        if (invalidRecords.length > 0) {
            // Handle invalid letter numbers
            const invalidLetters = invalidRecords.map((record) => record.letter).join(", ");
            toast.error(`The following letter numbers do not exist: ${invalidLetters}`);
            return;
        }

        const loadingToastId = toast.loading("Uploading: Please wait...");

        try {
            const res = await axios.post(`${BASE_URL}/siteproject/cosbulkupload/`, excelJson);
            if (res.status === 200) {
                await getCOSData(); // Refresh data after upload
                handleClose(); // Close modal or reset form
            }
        } catch (err) {
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print equal-hieght" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk COS Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-left">cos_code</th>
                                    <th className="align-center">letter</th>
                                    <th className="align-center">process_initiate_date</th>
                                    <th className="align-left">approval_date</th>
                                    <th className="align-left">amount</th>
                                    <th className="align-left">cos_status</th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background">
                        <table className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">

                                    <th className="align-left">cos_code</th>
                                    <th className="align-center">letter</th>
                                    <th className="align-center">process_initiate_date</th>
                                    <th className="align-left">approval_date</th>
                                    <th className="align-center">amount</th>
                                    <th className="align-center">cos_status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {excelJson?.map((inventory, index) => (
                                    <tr key={index}>
                                        <th className="align-left">{inventory.cos_code}</th>
                                        <th className="align-center">{inventory.letter}</th>
                                        <th className="align-center">{inventory.process_initiate_date}</th>
                                        <th className="align-left">{inventory.approval_date}</th>
                                        <th className="align-left">{inventory.amount}</th>
                                        <th className="align-left">{inventory.cos_status}</th>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>
                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">   For cos status use one of these:</p>
                            <ul className="arrow-list">
                                <li>pending </li>
                                <li>Approved </li>
                                <li>rejected </li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">   For Letters:</p>
                            <ul className="arrow-list">
                                <li>Add Letter number in letter field </li>
                                <li>Insert existing letter number only </li>
                            </ul>
                        </div>
                        <div className="reference-section">
                            <p className="reference-title">   For COS:</p>
                            <ul className="arrow-list">
                                <li>COS code should be unique </li>
                            </ul>
                        </div>
                        <div className="date-section">
                            <p className="date-text">All Dates should be in DD-MM-YYYY format</p>
                        </div>
                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};
const UploadMilestoneBulk = ({ id, getMileStoneData, site_prcode, workperformedby, contractor_name, projectId }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});
    const [trackRecordList, setTrackRecordList] = useState([]);

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "MileStones Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "MileStones Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const readExcel = (file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            // Function to convert Excel dates or general date formats to YYYY-MM-DD
            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };

            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};

                headers.forEach((header, index) => {
                    if (["milestone_as_per_actual", "milestone_as_per_reschedule", "milestone_date"].includes(header) && row[index]) {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });

                if (typeof site_prcode !== "undefined") {
                    obj["project"] = site_prcode;
                }

                if (typeof workperformedby !== "undefined") {
                    obj["workperformedby"] = workperformedby;

                    if (workperformedby === "contractor" && typeof contractor_name !== "undefined") {
                        obj["contractor"] = contractor_name;
                    }
                }

                return obj;
            });

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };

        reader.readAsArrayBuffer(file);
    };



    const handleUpload = async () => {
        if (!excelJson) {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
            return;
        }

        // // Validate letter numbers in excelJson against trackRecordList
        // const invalidRecords = excelJson.filter(
        //     (item) => !trackRecordList.some(
        //         (record) => String(record.letter_num).trim() === String(item.letter).trim()
        //     )
        // );

        // if (invalidRecords.length > 0) {
        //     // Handle invalid letter numbers
        //     const invalidLetters = invalidRecords.map((record) => record.letter).join(", ");
        //     toast.error(`The following letter numbers do not exist: ${invalidLetters}`);
        //     return;
        // }

        const loadingToastId = toast.loading("Uploading: Please wait...");

        try {
            const res = await axios.post(`${BASE_URL}/siteproject/milestoneupload/`, excelJson);
            if (res.status === 200) {
                await getMileStoneData(); // Refresh data after upload
                handleClose(); // Close modal or reset form
            }
        } catch (err) {
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk MileStone Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-left">name</th>
                                    <th className="align-center">milestone_date</th>
                                    <th className="align-center">milestone_as_per_actual</th>
                                    <th className="align-left">milestone_as_per_reschedule</th>
                                    <th className="align-left">day</th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background scroll-container">
                        <div className="table-container scrollbar" style={{ width: "100%" }}>

                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead>
                                    <tr className="custom-table-head-tr  modal-padding-heading">

                                        <th className="table-heading-text">name</th>
                                        <th className="table-heading-text">milestone_date</th>
                                        <th className="table-heading-text">milestone_as_per_actual</th>
                                        <th className="table-heading-text">milestone_as_per_reschedule</th>
                                        <th className="table-heading-text">day</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((i, index) => (
                                        <tr key={index} className="modal-padding-heading">
                                            <th className="table-heading-text">{i.name}</th>
                                            <th className="table-heading-text">{i.milestone_date}</th>
                                            <th className="table-heading-text">{i.milestone_as_per_actual}</th>
                                            <th className="table-heading-text">{i.milestone_as_per_reschedule}</th>
                                            <th className="table-heading-text">{i.day}</th>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>
                    <h4>References:</h4>
                    <div className="reference-container">

                        <div className="reference-section">
                            <ul className="arrow-list">
                                <li>All Dates should be in DD-MM-YYYY format</li>

                            </ul>
                        </div>

                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};
const UploadItemsBulk = ({ id, getItem }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Items Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Items Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);



            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {

                    obj[header] = row[index] !== undefined ? row[index] : null;
                });
                return obj;
            });

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };


    // Function to handle the upload button click
    const handleUpload = async () => {
        if (excelJson) {
            const loadingToastId = toast.loading("Uploading: Please wait...");

            try {
                const res = await axios.post(`${BASE_URL}/siteproject/bulksiteinventoryitems/`, excelJson);
                if (res.status === 200) {
                    await getItem();
                    handleClose();
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                toast.dismiss(loadingToastId);
            }
        } else {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
        }
    };





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <UploadIcon />
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Site Item List Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-center">sub_company</th>
                                    <th className="align-left">item_name</th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="align-left">-</th>
                                    <th className="align-center">-</th>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background">
                        <table className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-center">sub_company</th>
                                    <th className="align-left">item_name</th>

                                </tr>
                            </thead>
                            <tbody>
                                {excelJson?.map((item, index) => (
                                    <tr key={index}>
                                        <th className="align-center">{item.sub_company}</th>
                                        <th className="align-left">{item.item_name}</th>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};

const UploadBillingBulk = ({ id, getBilling, site_prcode, workperformedby, contractor_name, projectId }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});
    const [trackRecordList, setTrackRecordList] = useState([]);


    const getLetters = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/letterrecordbclosestatus/${projectId}/${workperformedby}/billing/`);
            setTrackRecordList(res.data);
        } catch (error) {
            handleErrorToast(error);
        }
    }
    useEffect(() => {
        getLetters();
    }, [projectId, workperformedby])

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Billing Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Billing Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };

            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {

                    if (["submitted_bill_date", "bill_period_start", "bill_period_end", "site_recomended_bill_date", "recomended_bill_date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                if (site_prcode) {
                    obj["project"] = site_prcode;
                }



                if (contractor_name) {
                    obj["contractor"] = contractor_name;
                }

                return obj;
            });

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };

    // Function to handle the upload button click
    const handleUpload = async () => {
        if (!excelJson) {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
            return;
        }



        const loadingToastId = toast.loading("Uploading: Please wait...");

        try {
            const res = await axios.post(`${BASE_URL}/siteproject/bulkbillingdetail/`, excelJson);
            if (res.status === 200) {
                await getBilling(); // Refresh data after upload
                handleClose(); // Close modal or reset form
            }
        } catch (err) {
            handleErrorToast(err);
            console.log(err)
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    console.log(site_prcode);
    console.log(contractor_name)
    console.log(excelJson)





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Billing Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-left">bill_num</th>
                                    <th className="align-center">bill_type</th>
                                    <th className="align-center">bill_catagory</th>
                                    <th className="align-center">bill_period_start</th>
                                    <th className="align-center">bill_period_end</th>
                                    <th className="align-center">submitted_bill_date</th>
                                    <th className="align-center">work_done_amt</th>
                                    <th className="align-center">escalation</th>
                                    <th className="align-center">gst</th>
                                    <th className="align-center">contractor_deduction_amt</th>
                                    <th className="align-center">submitted_bill_amount</th>
                                    <th className="align-center">submitted_percentage</th>
                                    <th className="align-center">site_percentage_amt</th>
                                    <th className="align-center">submitted_letter_num</th>
                                    <th className="align-center">site_recomended_bill_date</th>
                                    <th className="align-center">site_ld_amount</th>
                                    <th className="align-center">site_withheld_amt</th>
                                    <th className="align-center">site_work_done_amt</th>
                                    <th className="align-center">site_escalation</th>
                                    <th className="align-center">site_gst</th>
                                    <th className="align-center">site_deduction_amount</th>
                                    <th className="align-center">site_recommended_bill_amount</th>
                                    <th className="align-center">site_recommended_percentage</th>
                                    <th className="align-center">site_percentage_amt</th>
                                    <th className="align-center">site_released_withheld_amt</th>
                                    <th className="align-center">remark</th>
                                    <th className="align-center">recomended_bill_date</th>
                                    <th className="align-right">ho_work_done_amt</th>
                                    <th className="align-right">ho_escalation</th>
                                    <th className="align-right">ho_gst</th>
                                    <th className="align-right">deduction_amount</th>
                                    <th className="align-right">recommended_bill_amount</th>
                                    <th className="align-right">recommended_percentage</th>
                                    <th className="align-right">ho_percentage_amt</th>
                                    <th className="align-right">ld_amount</th>
                                    <th className="align-right">withheld_amt</th>
                                    <th className="align-right">released_withheld_amt</th>
                                    <th className="align-right">recomended_letter_num</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="align-left">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                    <th className="align-right">-</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background">
                        <table className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="align-left">bill_num</th>
                                    <th className="align-center">bill_type</th>
                                    <th className="align-center">bill_catagory</th>
                                    <th className="align-center">bill_period_start</th>
                                    <th className="align-center">bill_period_end</th>
                                    <th className="align-center">submitted_bill_date</th>
                                    <th className="align-center">work_done_amt</th>
                                    <th className="align-center">escalation</th>
                                    <th className="align-center">gst</th>
                                    <th className="align-center">contractor_deduction_amt</th>
                                    <th className="align-center">submitted_bill_amount</th>
                                    <th className="align-center">submitted_percentage</th>
                                    <th className="align-center">site_percentage_amt</th>
                                    <th className="align-center">submitted_letter_num</th>
                                    <th className="align-center">site_recomended_bill_date</th>
                                    <th className="align-center">site_ld_amount</th>
                                    <th className="align-center">site_withheld_amt</th>
                                    <th className="align-center">site_work_done_amt</th>
                                    <th className="align-center">site_escalation</th>
                                    <th className="align-center">site_gst</th>
                                    <th className="align-center">site_deduction_amount</th>
                                    <th className="align-center">site_recommended_bill_amount</th>
                                    <th className="align-center">site_recommended_percentage</th>
                                    <th className="align-center">site_percentage_amt</th>
                                    <th className="align-center">site_released_withheld_amt</th>
                                    <th className="align-center">remark</th>
                                    <th className="align-center">recomended_bill_date</th>
                                    <th className="align-right">ho_work_done_amt</th>
                                    <th className="align-right">ho_escalation</th>
                                    <th className="align-right">ho_gst</th>
                                    <th className="align-right">deduction_amount</th>
                                    <th className="align-right">recommended_bill_amount</th>
                                    <th className="align-right">recommended_percentage</th>
                                    <th className="align-right">ho_percentage_amt</th>
                                    <th className="align-right">ld_amount</th>
                                    <th className="align-right">withheld_amt</th>
                                    <th className="align-right">released_withheld_amt</th>
                                    <th className="align-right">recomended_letter_num</th>
                                </tr>
                            </thead>
                            <tbody>
                                {excelJson?.map((bills, index) => (
                                    <tr key={index}>
                                        <th className="align-left">{bills.bill_num}</th>
                                        <th className="align-center">{bills.bill_type}</th>
                                        <th className="align-center">{bills.bill_catagory}</th>
                                        <th className="align-center">{bills.bill_period_start}</th>
                                        <th className="align-center">{bills.bill_period_end}</th>
                                        <th className="align-center">{bills.submitted_bill_date}</th>
                                        <th className="align-center">{bills.work_done_amt}</th>
                                        <th className="align-center">{bills.escalation}</th>
                                        <th className="align-center">{bills.gst}</th>
                                        <th className="align-center">{bills.contractor_deduction_amt}</th>
                                        <th className="align-center">{bills.submitted_bill_amount}</th>
                                        <th className="align-center">{bills.submitted_percentage}</th>
                                        <th className="align-center">{bills.site_percentage_amt}</th>
                                        <th className="align-center">{bills.submitted_letter_num}</th>
                                        <th className="align-center">{bills.site_recomended_bill_date}</th>
                                        <th className="align-center">{bills.site_ld_amount}</th>
                                        <th className="align-center">{bills.site_withheld_amt}</th>
                                        <th className="align-center">{bills.site_work_done_amt}</th>
                                        <th className="align-center">{bills.site_escalation}</th>
                                        <th className="align-center">{bills.site_gst}</th>
                                        <th className="align-center">{bills.site_deduction_amount}</th>
                                        <th className="align-center">{bills.site_recommended_bill_amount}</th>
                                        <th className="align-center">{bills.site_recommended_percentage}</th>
                                        <th className="align-center">{bills.site_percentage_amt}</th>
                                        <th className="align-center">{bills.site_released_withheld_amt}</th>
                                        <th className="align-center">{bills.remark}</th>
                                        <th className="align-center">{bills.recomended_bill_date}</th>
                                        <th className="align-right">{bills.ho_work_done_amt}</th>
                                        <th className="align-right">{bills.ho_escalation}</th>
                                        <th className="align-right">{bills.ho_gst}</th>
                                        <th className="align-right">{bills.deduction_amount}</th>
                                        <th className="align-right">{bills.recommended_bill_amount}</th>
                                        <th className="align-right">{bills.recommended_percentage}</th>
                                        <th className="align-right">{bills.ho_percentage_amt}</th>
                                        <th className="align-right">{bills.ld_amount}</th>
                                        <th className="align-right">{bills.withheld_amt}</th>
                                        <th className="align-right">{bills.released_withheld_amt}</th>
                                        <th className="align-right">{bills.recomended_letter_num}</th>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>
                    {/* <h4>References:</h4>
                    <div className="flex-row justify-between" style={{ alignItems: "baseline" }}>
                        <div>
                            <ul>
                                <li>Employee Code should be Valid </li>
                                <li>All Dates should be in YYYY/MM/DD format</li>
                                <li>The item name should match exactly with the names in the Item List.</li>

                            </ul>
                        </div>

                    </div> */}
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};

const UploadInsuranceBulk = ({ getInsurance, site_prcode, workperformedby, projectData }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null);
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});
    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Insurance Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Insurance Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    // The checkForDuplicates function outside
    const checkForDuplicates = (data) => {
        const insuranceNumbers = new Map();
        const duplicates = [];

        // Track positions of each insurance number
        data.forEach((i, index) => {
            const policyNumber = i.insurance_policy_num;
            if (insuranceNumbers.has(policyNumber)) {
                // If the insurance number already exists, add the current position to its list
                insuranceNumbers.get(policyNumber).push(index + 1); // index + 1 for 1-based position
            } else {
                // If it's the first time seeing this insurance number, initialize its position list
                insuranceNumbers.set(policyNumber, [index + 1]);
            }
        });

        // Now, filter out only the numbers that appear more than once
        insuranceNumbers.forEach((positions, policyNumber) => {
            if (positions.length > 1) {
                duplicates.push(`\nPolicy Number:${policyNumber} is found in position(s) ${positions.join(", ")}`);
            }
        });

        return duplicates;
    };

    // The readExcel function
    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            // Date conversion function
            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };

            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {

                    if (["insurance_policy_start_date", "insurance_policy_valid_upto_date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                // Optional additional fields if available
                if (site_prcode) {
                    obj["project"] = site_prcode;
                }

                if (workperformedby) {
                    obj["workperformedby"] = workperformedby;
                }

                return obj;
            });

            // Check for duplicates using the external function
            const duplicates = checkForDuplicates(formattedJson);

            if (duplicates.length > 0) {
                // Toast notification with line breaks for each duplicate
                toast.error(
                    `Duplicate Insurance Numbers found:\n${duplicates.join("\n")}`,
                    {
                        autoClose: 5000, // Close toast after 5 seconds
                    }
                );
            }

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };

    const existingPolicy = projectData?.insurance?.map(record => record.insurance_policy_num);


    const handleUpload = async () => {
        if (!excelJson) {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
            return;
        }
        const duplicates = checkForDuplicates(excelJson);

        if (duplicates.length > 0) {
            toast.error(`Duplicate Data found! Policy Number ${duplicates.join(", ")}`, {
                autoClose: 5000,
            });
        }


        const duplicatePolicies = excelJson.filter(
            (item) => existingPolicy.includes(String(item.insurance_policy_num).trim())
        );

        if (duplicatePolicies.length > 0) {
            const duplicateNumbers = duplicatePolicies
                .map((record) => record.insurance_policy_num)
                .join(", ");
            toast.error(<>The  policy <b>{duplicateNumbers}</b> already exist in <b>{site_prcode}</b> </>);
            return;
        }

        const loadingToastId = toast.loading("Uploading: Please wait...");

        try {
            const res = await axios.post(`${BASE_URL}/siteproject/insurancebulk/`, excelJson);
            if (res.status === 200) {
                await getInsurance(); // Refresh data after upload
                handleClose(); // Close modal or reset form
                toast.success("Insurance data uploaded successfully.");
                toast.dismiss(loadingToastId);

            }
        } catch (err) {
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };


    console.log(site_prcode)





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Insurance Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">

                                    <th className="align-left">insurance_policy_num</th>
                                    <th className="align-left">insurance_name</th>
                                    <th className="align-center">insurance_company_name</th>
                                    <th className="align-left">insurance_policy_start_date</th>
                                    <th className="align-center">insurance_policy_valid_upto_date</th>
                                    <th className="align-center">insurance_amount</th>



                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background scroll-container"
                    >
                        <div className="table-container scrollbar" style={{ width: "100%" }}>
                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead className="table-heading">

                                    <tr className="custom-table-head-tr modal-padding-heading">

                                        <th className="table-heading-text">Insurance Policy Number</th>
                                        <th className="table-heading-text">Insurance Name</th>
                                        <th className="table-heading-text">Insurance Company Name</th>
                                        <th className="table-heading-text">Policy Start Date</th>
                                        <th className="table-heading-text">Policy Valid Upto</th>
                                        <th className="table-heading-text">Insurance Amount</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((inventory, index) => (
                                        <tr key={index} className="modal-padding-heading">

                                            <th className="table-heading-text">{inventory.insurance_policy_num}</th>
                                            <th className="table-heading-text">{inventory.insurance_name}</th>
                                            <th className="table-heading-text">{inventory.insurance_company_name}</th>
                                            <th className="table-heading-text">{inventory.insurance_policy_start_date}</th>
                                            <th className="table-heading-text">{inventory.insurance_policy_valid_upto_date}</th>
                                            <th className="table-heading-text">{inventory.insurance_amount}</th>


                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>
                    <h4>References:</h4>
                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">General:</p>
                            <ul className="arrow-list">

                                <li>Insurance policy number should be unique</li>
                                <li>All Dates should be in DD-MM-YYYY format</li>

                            </ul>
                        </div>
                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>

        </>
    );
};
const UploadBGBulk = ({ getBankGuarantee, site_prcode, workperformedby, projectData }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null);
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});




    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Bank Guarantee Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Bank Guarantee Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };

            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {

                    if (["bg_date", "bg_expiry_date", "bg_release_date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                if (site_prcode) {
                    obj["project"] = site_prcode;
                }

                if (workperformedby) {
                    obj["workperformedby"] = workperformedby;

                }
                return obj;
            });

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };
    // Function to handle the upload button click

    const existingBG = projectData?.bgdetails?.map(record => record.bg_num);

    const handleUpload = async () => {
        if (!excelJson) {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
            return;
        }
        const duplicateBG = excelJson.filter(
            (item) => existingBG.includes(String(item.bg_num).trim())
        );

        if (duplicateBG.length > 0) {
            const duplicateNumbers = duplicateBG
                .map((record) => record.bg_num)
                .join(", ");
            toast.error(<>The  BG <b>{duplicateNumbers}</b> already exist in <b>{site_prcode}</b> </>);
            return;
        }




        const loadingToastId = toast.loading("Uploading: Please wait...");

        try {
            const res = await axios.post(`${BASE_URL}/siteproject/bgbulkupload/`, excelJson);
            if (res.status === 200) {
                await getBankGuarantee(); // Refresh data after upload
                handleClose(); // Close modal or reset form
                toast.success("BG data uploaded successfully.");
                toast.dismiss(loadingToastId);
            }
        } catch (err) {
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    console.log(site_prcode)





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Bank Guarantee Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">

                                    <th className="align-left">bg_num</th>
                                    <th className="align-left">bank_name</th>
                                    <th className="align-center">bg_date</th>
                                    <th className="align-left">bg_expiry_date</th>
                                    <th className="align-center">bg_release_date</th>
                                    <th className="align-center">bg_amount</th>
                                    <th className="align-center">bg_remark</th>



                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background">
                        <table className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">

                                    <th className="align-center">bg_num</th>
                                    <th className="align-left">bank_name</th>
                                    <th className="align-center">bg_date</th>
                                    <th className="align-left">bg_expiry_date</th>
                                    <th className="align-center">bg_release_date</th>
                                    <th className="align-center">bg_amount</th>
                                    <th className="align-center">bg_remark</th>

                                </tr>
                            </thead>
                            <tbody>
                                {excelJson?.map((inventory, index) => (
                                    <tr key={index}>

                                        <th className="align-left">{inventory.bg_num}</th>
                                        <th className="align-left">{inventory.bank_name}</th>
                                        <th className="align-center">{inventory.bg_date}</th>
                                        <th className="align-left">{inventory.bg_expiry_date}</th>
                                        <th className="align-center">{inventory.bg_release_date}</th>
                                        <th className="align-center">{inventory.bg_amount}</th>
                                        <th className="align-center">{inventory.bg_remark}</th>


                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>
                    <h4>References:</h4>
                    <div className="flex-row justify-between" style={{ alignItems: "baseline" }}>
                        <div>
                            <ul>

                                <li>Bank Guarantee Number Should be Unique</li>
                                <li>All Dates should be in YYYY/MM/DD format</li>

                            </ul>
                        </div>

                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};
const UploadBulkContractor = ({ getdetails, getContractor, site_prcode, getProject }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null);
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});




    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Contractor Details Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Contractor Details Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };

            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {

                    if (["contractor_bid_due_date", "loa_award_date", "agreement_date", "commencement_date", "actual_completion_date", "schedule_completion_date", "actual_construction_completion_date", "schedule_construction_completion_date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                if (site_prcode) {
                    obj["project"] = site_prcode;
                }


                return obj;
            });

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };
    // Function to handle the upload button click


    const handleUpload = async () => {
        if (!excelJson) {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
            return;
        }





        const loadingToastId = toast.loading("Uploading: Please wait...");

        try {
            const res = await axios.post(`${BASE_URL}/siteproject/bulkcontractor/`, excelJson);
            if (res.status === 200) {
                await getContractor(); // Refresh data after upload
                handleClose(); // Close modal or reset form
                toast.success("Contractor data uploaded successfully.");
                toast.dismiss(loadingToastId);
            }
        } catch (err) {
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    console.log(site_prcode)





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Contractor Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">

                                    <th className="align-left">name</th>
                                    <th className="align-left">cost</th>
                                    <th className="align-center">project_length</th>
                                    <th className="align-left">contractor_bid_due_date</th>
                                    <th className="align-center">loa_award_date</th>
                                    <th className="align-center">agreement_date</th>
                                    <th className="align-center">project_duration</th>
                                    <th className="align-center">commencement_date</th>
                                    <th className="align-center">actual_completion_date</th>
                                    <th className="align-center">schedule_completion_date</th>
                                    <th className="align-center">actual_construction_completion_date</th>
                                    <th className="align-center">schedule_construction_completion_date</th>
                                    <th className="align-center">contractor_address</th>



                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background">
                        <table className="nested-table-lightblue">
                            <thead>
                                <tr className="custom-table-head-tr">

                                    <th className="align-center">name</th>
                                    <th className="align-left">cost</th>
                                    <th className="align-center">project_length</th>
                                    <th className="align-left">contractor_bid_due_date</th>
                                    <th className="align-center">loa_award_date</th>
                                    <th className="align-center">agreement_date</th>
                                    <th className="align-center">commencement_date</th>
                                    <th className="align-center">actual_completion_date</th>
                                    <th className="align-center">schedule_completion_date</th>
                                    <th className="align-center">actual_construction_completion_date</th>
                                    <th className="align-center">schedule_construction_completion_date</th>
                                    <th className="align-center">contractor_address</th>

                                </tr>
                            </thead>
                            <tbody>
                                {excelJson?.map((inventory, index) => (
                                    <tr key={index}>

                                        <th className="align-left">{inventory.name}</th>
                                        <th className="align-left">{inventory.cost}</th>
                                        <th className="align-center">{inventory.project_length}</th>
                                        <th className="align-left">{inventory.contractor_bid_due_date}</th>
                                        <th className="align-center">{inventory.loa_award_date}</th>
                                        <th className="align-center">{inventory.agreement_date}</th>
                                        <th className="align-center">{inventory.commencement_date}</th>
                                        <th className="align-center">{inventory.actual_completion_date}</th>
                                        <th className="align-center">{inventory.schedule_completion_date}</th>
                                        <th className="align-center">{inventory.actual_construction_completion_date}</th>
                                        <th className="align-center">{inventory.schedule_construction_completion_date}</th>
                                        <th className="align-center">{inventory.contractor_address}</th>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>
                    <h4>References:</h4>
                    <div className="flex-row justify-between" style={{ alignItems: "baseline" }}>
                        <div>
                            <ul>

                                <li>All Dates should be in YYYY/MM/DD format</li>

                            </ul>
                        </div>

                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};
const UploadSiteProgressBulk = ({ getProgress, site_prcode, contractor_name }) => {



    const [show, setShow] = useState(false);
    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null);
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});




    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Site Progress Details Details" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Site Progress Details Details Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };
    const handleShow = () => setShow(true);

    // Function to handle file change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check for valid file type
            const validFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
            if (!validFileTypes.includes(file.type)) {
                setDocErrors({ document_file: "Please upload a valid Excel file (.xlsx or .xls)." });
                setFileName("");
                return;
            }

            setFileName(file.name);
            readExcel(file); // Convert the file to JSON
        } else {
            setFileName("");
        }
    };

    const readExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0]; // Use the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });

            const headers = json[0];
            const rows = json.slice(1);

            const convertToYYYYMMDD = (input) => {
                try {
                    let date;

                    // Handle Excel serial dates
                    if (typeof input === "number") {
                        const excelEpoch = new Date(1900, 0, 1); // Excel epoch starts on Jan 1, 1900
                        const daysOffset = input - 1; // Subtract 1 because of leap year bug
                        date = new Date(excelEpoch.getTime() + daysOffset * 86400000);
                    } else if (/^\d{4}\.\d{2}\.\d{2}$/.test(input)) {
                        // Format: YYYY.MM.DD
                        const [year, month, day] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else if (/^\d{2}\.\d{2}\.\d{4}$/.test(input)) {
                        // Format: DD.MM.YYYY
                        const [day, month, year] = input.split('.');
                        date = new Date(`${year}-${month}-${day}`);
                    } else {
                        // Default parsing for other formats
                        date = new Date(input);
                    }

                    if (isNaN(date.getTime())) {
                        throw new Error('Invalid date format');
                    }

                    // Format the date as YYYY-MM-DD
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                } catch (error) {
                    return null; // Return null for invalid dates
                }
            };

            // Create a formatted JSON object based on headers and row values
            const formattedJson = rows.map((row) => {
                const obj = {};
                headers.forEach((header, index) => {

                    if (["bg_date", "bg_expiry_date", "bg_release_date"].includes(header) && row[index] && typeof row[index] === "number") {
                        obj[header] = convertToYYYYMMDD(row[index]); // Convert date
                    } else {
                        obj[header] = row[index] !== undefined ? row[index] : null;
                    }
                });
                if (site_prcode) {
                    obj["project"] = site_prcode;
                }
                if (contractor_name) {
                    obj["contractor"] = contractor_name;
                }


                return obj;
            });

            // Set the formatted JSON data to state
            setExcelJson(formattedJson);
        };
        reader.readAsArrayBuffer(file);
    };
    // Function to handle the upload button click


    const handleUpload = async () => {
        if (!excelJson) {
            setDocErrors({ document_file: "Please upload a valid Excel file." });
            return;
        }




        const loadingToastId = toast.loading("Uploading: Please wait...");

        try {
            const res = await axios.post(`${BASE_URL}/siteproject/progressbulkupload/`, excelJson);
            if (res.status === 200) {
                await getProgress(); // Refresh data after upload
                handleClose(); // Close modal or reset form
                toast.success("Progress uploaded successfully.");
                toast.dismiss(loadingToastId);
            }
        } catch (err) {
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    console.log(site_prcode)





    return (
        <>

            <button title="Upload" className="model-button   font-weight500   model-button-print" onClick={handleShow} style={{ margin: "2px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">

                <Modal.Body>
                    <div className="flex-row align-center">

                        <h1 className="font-size-text task-Tab-heading align-center  font-weight500 "><Modal.Title>Upload Bulk Site Progress Data in format :</Modal.Title> </h1>


                        <button onClick={handleDownload} className="model-button-download model-button font-weight500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Format
                        </button>

                    </div>
                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table ref={bulktableRef} className="nested-table-lightblue" style={{ margin: "0px" }}>
                            <thead>
                                <tr className="custom-table-head-tr">

                                    <th className="align-left">month</th>
                                    <th className="align-left">year</th>
                                    <th className="align-left">physical_progress</th>
                                    <th className="align-center">financial_progress</th>



                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>
                                    <th className="align-center">-</th>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-css-white-background scroll-container">
                        <div className="table-container scrollbar" style={{ width: "100%" }}>

                            <table className="nested-table-lightblue" style={{ margin: "0px" }}>
                                <thead>
                                    <tr className="custom-table-head-tr modal-padding-heading">


                                        <th className="table-heading-text">month</th>
                                        <th className="table-heading-text">year</th>
                                        <th className="table-heading-text">physical_progress</th>
                                        <th className="table-heading-text">financial_progress</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {excelJson?.map((inventory, index) => (
                                        <tr key={index} className="modal-padding-heading">

                                            <th className="table-heading-text">{inventory.month}</th>
                                            <th className="table-heading-text">{inventory.year}</th>
                                            <th className="table-heading-text">{inventory.physical_progress}</th>
                                            <th className="table-heading-text">{inventory.financial_progress}</th>


                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>



                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels announce-date font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            id="document_file"
                            name="document_file"
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }}
                        />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>
                    <h4>References:</h4>

                    <div className="reference-container">
                        <div className="reference-section">
                            <p className="reference-title">General:</p>
                            <ul className="arrow-list">
                                <li>Month should be in number</li>
                                <li>All progress percentage should be in ascending order</li>

                            </ul>
                        </div>
                    </div>
                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleUpload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} Upload
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={false} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};



export { UploadBulkContractor, UploadOfficeRentBulk, UploadSiteProgressBulk, UploadBGBulk, UploadItemsBulk, UploadInsuranceBulk, UploadBillingBulk, UploadInventory, UploadSiteProject, UploadLetterBulk, UploadDepartment, VehicleModelBulk, UploadDesignation, UploadEotBulk, UploadcosBulk, UploadMilestoneBulk }