import { DownloadTableExcel } from "react-export-table-to-excel";
import { toast, ToastContainer } from "react-toastify";
import Dashboardnavbar from "../../../layout/Dashboardnavbar";
import { useEffect, useRef, useState } from "react";
import usePermission from "../../../config/permissions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../config/axios";
import { handleErrorToast, sortProjects } from "../../../components/CustomFunctions";
import InvoiceList from "../../../components/SiteManagement/AdminSiteManagement/InvoiceList";
import { AddBOQInvoice } from "../../../components/SiteManagement/ProjectDetailsCrudButtons";
import { CircularGrid, Eye, SearchBig, SearchSmall, Sort } from "../../../components/AllSvg";
import { SubCompany } from "../../../components/FilterDropdown";

const Boqinvoices = () => {
  const tableRef = useRef(null);
  const siteRole = sessionStorage.getItem("sitemanagement_role");
  const { PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState("null");
  const [filteredData, setFilteredData] = useState([]);
  const [sorting, setSorting] = useState("asc_pr");




  const [projectsList, setProjectsList] = useState([]);
  const [selectedName, setName] = useState("");
  const [error, setError] = useState(null);



  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const navigate = useNavigate();

  const [buffer, setBuffering] = useState(true); //buffering logic

  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start BufferingDD
    try {

      const res = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/projectfilterbysubcompany/${toggleCompany}/`
      );
      const sortedList = sortProjects(res.data, "site_prcode");

      setProjectsList(sortedList);




    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false); // End Buffering
    }
  };

  useEffect(() => {
    getProjects();
  }, [toggleCompany, sorting]);

  useEffect(() => {
    applyFilters();
  }, [projectsList, selectedName, sorting]);

  const applyFilters = () => {
    let tempFilteredData = projectsList;

    if (selectedName) {
      const lowercaseSelectedName = selectedName.toLowerCase();
      tempFilteredData = tempFilteredData.filter((employee) => {
        const nameMatch = employee.site_prcode.toString().toLowerCase().includes(lowercaseSelectedName);
        const empCodeMatch = employee.project_short_name.toLowerCase().includes(lowercaseSelectedName);
        return nameMatch || empCodeMatch;
      });
    }

    switch (sorting) {
      case "asc_pr":
        tempFilteredData = sortProjects(tempFilteredData, "site_prcode");
        break;
      case "desc_pr":
        tempFilteredData = sortProjects(tempFilteredData, "site_prcode").reverse();
        break;
      case "asc_short_name":
        tempFilteredData = tempFilteredData.sort((a, b) =>
          a.project_short_name.localeCompare(b.project_short_name)
        );
        break;
      case "desc_short_name":
        tempFilteredData = tempFilteredData.sort((a, b) =>
          b.project_short_name.localeCompare(a.project_short_name)
        );
        break;
      default:
        break;
    }

    setFilteredData(tempFilteredData);
  };



  const ViewBOQ = (project, sub_company) => {
    navigate(`/${urlParts[3]}/Invoiceformate/${project}/${sub_company}/`
    );
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dashboardnavbar name={siteRole === "BD" ? "BOQ" : "BOQ & Invoices"} url={siteRole === "BD" ? "BOQ" : "BOQ & Invoices"} />

      <div className="content-tabs ">
        {/* {loading || buffer ? ( */}

        <>
          <div>
            <div className="attendance-heading  font-weight500    font-size-heading">
              {" "}
            </div>
            <div className="attendance-subcont">
              <div className="field-cont">
                {PERMISSION_AUTHORITY ? (
                  <SubCompany toggleCompany={toggleCompany} setToggleCompany={setToggleCompany} />
                ) : null}




                <div title="Sort Filter" className="field-cont-div">
                  <Sort />
                  <select
                    className="attendance-input-field width-15vw   date-field"
                    type="text"
                    value={sorting}
                    onChange={(e) => setSorting(e.target.value)}
                  >
                    <option value="asc_pr">Asc. Pr Code</option>
                    <option value="desc_pr">Desc. Pr Code</option>
                    <option value="asc_short_name">Asc. Project Name</option>
                    <option value="desc_short_name">Desc. Project Name</option>
                  </select>
                  <hr className="field-cont-hr" />
                </div>

                <div title="Search Filter" className="field-cont-div">
                  <SearchSmall />
                  <input
                    className="attendance-input-field width-15vw  "
                    placeholder=" Code/ Project Name"
                    type="text"
                    value={selectedName}
                    // </svg><input className="attendance-input-field width-10vw  " placeholder='Email / Code / RH' type="text" value={selectedName}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <hr className="field-cont-hr" />
                </div>



              </div>
              <div className="btn-cont">
                {siteRole === "BD" ? "" :
                  <AddBOQInvoice />
                }
                <DownloadTableExcel
                  filename="Custom-ProjectDetails"
                  sheet="projectDetails"
                  currentTableRef={tableRef.current}
                >
                  <button className="model-button font-weight500 model-button-print">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="22"
                      id="download"
                      fill="#fff"
                    >
                      <g
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      >
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                      </g>
                    </svg>
                  </button>
                </DownloadTableExcel>
              </div>
            </div>
          </div>


          <div className="table-box  scroll-container-table">
            <div className="table-css-white-background">
              <table ref={tableRef} className="table-css">
                <thead className="table-heading">
                  <tr className='custom-table-head-tr'>
                    <th className='table-heading-text'>Sr. No.</th>
                    {/* <th className='align-left'>Project Code & Name</th> */}
                    <th className='table-heading-text text-left'>Project Code</th>
                    <th className='table-heading-text text-left'>Project Name</th>

                    <th className='table-heading-text text-end width-5vw'>View BOQ</th>
                    {siteRole === "BD" ? "" :
                      <th className='table-heading-text text-end width-5vw'>View Invoice</th>
                    }
                  </tr>
                </thead>
                {buffer ? (
                  <div className="spinner-small"> </div> //buffering logic 
                ) : error ? (
                  <p>Error fetching data: {error.message}</p>
                ) :
                  <tbody>
                    {filteredData.map((project, index) => (
                      <tr className="custom-table-head-td">
                        <td className='table-body'>{index + 1}</td>
                        {/* <td className='align-left'>{project?.site_prcode}-{project.project_short_name}</td> */}
                        <td className='table-body text-left'>{project?.site_prcode}</td>
                        <td className='table-body text-left'>{project.project_short_name}</td>


                        <td className="table-body text-end">

                          <button
                            onClick={() => ViewBOQ(project.id, project.sub_company)}
                            title="View BOQ Details"
                          >
                            <Eye />
                          </button>
                        </td>
                        {siteRole === "BD" ? "" :
                          <td className="table-body text-end">
                            <InvoiceList project={project} />
                          </td>}
                      </tr>
                    ))}
                  </tbody>}
              </table>
            </div>
          </div>


        </>
      </div>
    </>
  );
};


export default Boqinvoices
