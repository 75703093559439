import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "../../config/axios";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import { useLocation } from "react-router-dom";
import { UpdateProjectAllDetails, AddContractor } from "../SiteManagement/ProjectDetailsCrudButtons";
import { BillingDetailsTable, BillTable, ConsultancyDetails, ContractorDetails, COSTable, EmployeeTable, EOTTable, LetterTracking, MilestoneTable, MiscellaneousDocument, Progress } from "../SiteManagement/SiteProjectManagement";
import usePermission from "../../config/permissions";
import { VehicleModelTable } from "../SiteManagement/VehicleManagement/VehicleManagementComponenets";
import { SiteInventory } from "../SiteManagement/InventoryManagement/InventoryManagementComponent";
import { UploadBulkContractor } from "../SiteManagement/BulkModals";
import { toast } from 'react-toastify';

import ReactToPrint from 'react-to-print';
import { formatDateDD_MM_YYYY, formattedDateLong, datearray } from "../Date";
import { AddProjectBill, AddEOTDetails, AddCOSDetails, AddMilstoneDetails, AddTrackRecordDetails, UpdateTrackRecordDetails, AddProgressdetails, DeleteBillingDetails, UpdateBill, UpdateProjectBill, DeleteBg, DeleteInsurance, AddInsurance, AddBankGuarantee, UpdateContractor, DeleteContractor, UpdateBankGuarantee, UpdateBank, UpdateInsurance, UpdateEOTDetails, DeleteEOT, DeleteTrackAndLetterDetails, DeleteCOS, UpdateTrackRecordDetailwithIDonly, UpdateConsultantDetails, AssociatedLetters, DocumentModel, DeleteDocuments, UpdateProgressdetails, UpdateCOSDetails, UpdateMilestone, DeleteMileStone } from "../SiteManagement/ProjectDetailsCrudButtons";
import { AddSiteEmployee, UpdateSiteEmployee, DeleteSiteEmployee, UploadSiteEmployee } from "../Wfm/WorkforceCrudButton";
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, YAxis, LineChart } from 'recharts';
import { DownloadTableExcel } from "react-export-table-to-excel";
import { formatCurrencyIndian, sortProjects, handleErrorToast, formatRoundoffCrores, formatRoundoff } from "../CustomFunctions";
import { CircularGrid, DeleteDustbin, DocumentIcon, SearchBig, SearchSmall, AddwithWhiteCircle, Reciept, UpdatePencil, Employee } from '../AllSvg';
import { LetterStatus, LetterType, Projects } from '../FilterDropdown';
import { UploadBGBulk, UploadBillingBulk, UploadcosBulk, UploadEotBulk, UploadInsuranceBulk, UploadLetterBulk, UploadMilestoneBulk, UploadSiteProgressBulk, VehicleModelBulk } from '../SiteManagement/BulkModals';
import { Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { AddLetterLinked } from '../../pages/sitedashboard/Main/LetterTracking';
import Item from 'antd/es/list/Item';

import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { height } from '@mui/system';
import Select from 'react-select';
import SkeletonLoader from '../Skeletonloader';
import { formatDate } from '../../components/Date.jsx'


import { SubCompany } from "../FilterDropdown";


const Progressreport = () => {
    const [progress, setProgress] = useState([]);
    const [contractorlist, setContractorList] = useState([]);
    const [buffer, setBuffering] = useState(false); // Buffering logic
    const [loading, setLoading] = useState(false); // Loading logic
    const role = sessionStorage.getItem('sitemanagement_role');
    const [toggleCompany, setToggleCompany] = useState(null);
    const [filterMonth, setFilterMonth] = useState("null");
    const [filterYear, setFilterYear] = useState("null");
    const today = new Date();
    const [project, setProject] = useState("null");
    const [contractorId, setcontractorId] = useState("null");
    const [subcompany, setSubCompany] = useState("null");
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 20; // starting 20 years ago
    const endYear = currentYear + 20; // up to 20 years in the future
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

    console.log("contractorId")
    console.log(contractorId);



    const [physicalProgress, setPhysicalProgress] = useState([]);


    const [progressdata, setProgressData] = useState([]);


    const getProgress = async () => {
        setBuffering(true); // Start buffering

        try {
            const response = await axios.get(`${BASE_URL}/siteproject/progressbyproject/${project}/${contractorId}/${filterMonth}/${filterYear}/`);

            setProgressData(response.data)

            const filteredData = response.data.map(item => ({
                physical_progress: item.physical_progress,
                estimated_physical_progress: item.estimated_physical_progress,
                month: item.month
            }));

            setPhysicalProgress(filteredData);


            const sortedData = response.data.sort((a, b) => {
                if (a.year === b.year) {
                    return b.month - a.month;
                }
                return b.year - a.year;
            });


            setProgress(sortedData);
        } catch (err) {
            console.log("err");
            console.log(err);
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End buffering
        }
    };
    useEffect(() => {
        getProgress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project, contractorId, filterMonth, filterYear]);


    const handleProgressDelete = async (e, i, getProgress) => {
        e.preventDefault();


        setLoading(true); // loading logic
        const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

        try {
            let res = await axios.delete(`${BASE_URL}/siteproject/progressupdate/${i.id}/`);

            if (res.status === 200) {
                await getProgress();
            } else {
                alert(res);
            }
        } catch (err) {
            // toast logic
            handleErrorToast(err);
        } finally {
            setLoading(false); // loading logic
            toast.dismiss(loadingToastId);
        }
    }

    const getMonthName = (monthNumber) => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return months[monthNumber - 1];
    };


    const getContractor = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/contractorbyproject/${project}/`);
            setContractorList(res.data);
        } catch (error) {
            console.error(error);

        }
    }

    useEffect(() => {
        getContractor();
    }, [project]);



    return (
        <>
            {role === "SiteManagement" ? ("") : (

                <div className="attendance-subcontainer margin">
                    <div className="field-cont width-100vw justify-between">



                        <div className="flex-row">

                            <SubCompany toggleCompany={toggleCompany} setToggleCompany={setToggleCompany} />


                            <Projects toggleProject={project} setToggleProject={setProject} subcompany={toggleCompany} />


                            <div title='Month Filter' className="field-cont-div">
                                <CircularGrid />

                                <select
                                    className="attendance-input-field width-10vw   date-field"
                                    type="text"
                                    value={filterMonth}
                                    onChange={(e) => setFilterMonth(e.target.value)}
                                >
                                    <option value="">Select Month</option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>

                                <hr className="field-cont-hr" />
                            </div>

                            <div title='Year Filter' className="field-cont-div">
                                <CircularGrid />

                                <select
                                    className="attendance-input-field width-10vw   date-field"
                                    type="text"
                                    value={filterYear}
                                    onChange={(e) => setFilterYear(e.target.value)}
                                >
                                    <option value="">Select Year</option>
                                    {years.reverse().map((year) => (
                                        <option key={year} value={year}>{year}</option>
                                    ))}
                                </select>

                                <hr className="field-cont-hr" />
                            </div>


                            <div title="Company Filter" className="field-cont-div">
                                <CircularGrid />
                                <select
                                    onChange={(e) => setcontractorId(e.target.value)}
                                    value={contractorId}
                                    className="attendance-input-field width-10vw   date-field"
                                // className="dropdown font-size-subheading  "
                                >
                                    <option value="null">Contractor</option>
                                    {contractorlist?.map((i) => (
                                        <option value={i.id}>{i.name}</option>
                                    ))}
                                    ;
                                </select>

                                <hr className="field-cont-hr" />
                            </div>


                            {/* <div title="Vehicle Filter" className="field-cont-div ">
                                <CircularGrid />
                                <select
                                    id="vehicle"
                                    name="vehicle"
                                    placeholder="vehicle"
                                    value={vehicle}
                                    onChange={(e) => setVehicle(e.target.value)}
                                    className={`attendance-input-field width-10vw`}
                                >

                                    <option value="null">Select Vehicle</option>
                                    {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                        <option key={index} value={i.id}>{i.name}-{i.vehicle_num}</option>
                                    ))}
                                </select>


                            </div> */}
                        </div>

                        <div>


                        </div>
                    </div>
                </div>
            )}
            <div className="info-box-div-bg">

                <div>
                    <div className="jd-heading-outer-flex">


                    </div>
                    {/* <div>
                        <div className="jd-heading-bottom-bold"></div>
                        <div className="jd-heading-bottom-light"></div>
                    </div> */}
                </div>

                <div className="form-flex-wrap  table-box paddingTop-0px scroll-container-table child-table-scroll-progres">
                    <table className="table-css">
                        <thead className='table-heading'>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-lefttt">S. No.</th>
                                <th className="table-heading-text">Month</th>
                                <th className="table-heading-text">Year</th>
                                <th className="table-heading-text text-center">Submitted Progress (%)</th>
                                <th className="table-heading-text text-center">Approved Progress (%)</th>
                                <th className="table-heading-text text-center ">Contractor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {progress.map((i, index) => {
                                const contractorData = contractorlist.find(contractor => contractor.id === i.contractor);
                                return (
                                    <React.Fragment key={index}>
                                        <tr className="custom-table-head-td">
                                            <td className="table-body text-lefttt">{index + 1}.</td>
                                            <td className="table-body">{getMonthName(i.month)}</td>
                                            <td className="table-body">{i.year}</td>
                                            {!(i.submitted_physical_progress && i.submitted_financial_progress && i.submitted_letter) ?
                                                <td className="table-body width-15vw text-center">-</td> :
                                                <td className="table-body width-15vw">
                                                    <tr>
                                                        <td className="padding-bottom-5px">Physical Progress</td>
                                                        <td style={{ padding: "0px 10px" }}>:</td>
                                                        {i.submitted_physical_progress ?
                                                            <td className="text-end">{i.submitted_physical_progress} %</td> :
                                                            <td className="text-end">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="padding-bottom-5px">Financial Progress</td>
                                                        <td style={{ padding: "0px 10px" }}>:</td>
                                                        {i.submitted_financial_progress ?
                                                            <td className="text-end">{i.submitted_financial_progress} %</td> :
                                                            <td className="text-end">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="padding-bottom-5px">Progress Letter</td>
                                                        <td style={{ padding: "0px 10px" }}>:</td>
                                                        {i.submitted_letter ?
                                                            <td className="text-end"><AssociatedLetters id={i.submitted_letter} /></td> :
                                                            <td className="text-end">-</td>
                                                        }
                                                    </tr>
                                                </td>
                                            }
                                            {!(i.approved_letter && i.physical_progress && i.financial_progress) ?
                                                <td className="table-body width-15vw text-center">-</td> :
                                                <td className="table-body width-15vw">
                                                    <tr>
                                                        <td className="padding-bottom-5px">Physical Progress</td>
                                                        <td style={{ padding: "0px 10px" }}>:</td>
                                                        {i.physical_progress ?
                                                            <td className="text-end">{i.physical_progress} %</td> :
                                                            <td className="text-end">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="padding-bottom-5px">Financial Progress</td>
                                                        <td style={{ padding: "0px 10px" }}>:</td>
                                                        {i.financial_progress ?
                                                            <td className="text-end">{i.financial_progress} %</td> :
                                                            <td className="text-end">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="padding-bottom-5px">Progress Letter</td>
                                                        <td style={{ padding: "0px 10px" }}>:</td>
                                                        {i.approved_letter ?
                                                            <td className="text-end"><AssociatedLetters id={i.approved_letter} /></td> :
                                                            <td className="text-end">-</td>
                                                        }
                                                    </tr>
                                                </td>
                                            }

                                            <td className="table-body text-center">{contractorData ? contractorData.name : "-"}</td>

                                            {/* <td className="table-body text-center">
                                                <UpdateProgressdetails i={i} project={projectId} getprogressDetails={getProgress} progressdata={progress} contractor={contractor} />
                                                <button className="model-delete-button" onClick={(e) => handleProgressDelete(e, i, getProgress)}>
                                                    <DeleteDustbin />
                                                </button>
                                            </td> */}
                                        </tr>
                                    </React.Fragment>
                                );
                            })}
                        </tbody>

                    </table>
                </div>

            </div>
        </>
    );
};

const EOTDetails = () => {
    const [eotData, setEotData] = useState([]);
    const [buffer, setbuffer] = useState(false);
    const siteRole = sessionStorage.getItem("sitemanagement_role");

    const [contractorlist, setContractorList] = useState([]);
    const [filterMonth, setFilterMonth] = useState("null");
    const [filterYear, setFilterYear] = useState("null");
    const [project, setProject] = useState("null");
    const [contractorId, setcontractorId] = useState("null");
    const [subcompany, setSubCompany] = useState("null");
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 20; // starting 20 years ago
    const endYear = currentYear + 20; // up to 20 years in the future
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
    const [toggleCompany, setToggleCompany] = useState(null);
    const [workperformedby, setWorkperformedby] = useState("consultant");

    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [approvefromdate, setApproveFromDate] = useState("null");
    const [approvetodate, setApproveToDate] = useState("null");









    const [error, setError] = useState(null);
    const [status, setStatus] = useState('pending'); // Default to 'pending'

    const fetchEOTData = async () => {
        setbuffer(true);
        try {
            const response = await axios.get(`${BASE_URL}/siteproject/eotfilter/${project}/${workperformedby}/${contractorId}/${status}/${fromdate}/${todate}/${approvefromdate}/${approvetodate}/`);
            setEotData(response.data);
        } catch (err) {
            setError('Failed to fetch EOT data');
        } finally {
            setbuffer(false);
        }

    };

    const getContractor = async () => {
        setbuffer(true);
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/contractorbyproject/${project}/`);
            setContractorList(res.data)
        } catch (error) {
            console.error(error);

        } finally {
            setbuffer(false);
        }
    };

    useEffect(() => {
        if (workperformedby === "contractor") {
            getContractor();
        };
    }, [project, workperformedby]);

    useEffect(() => {
        if (workperformedby === "consultant" || status === "pending") {
            setApproveFromDate("null");
            setApproveToDate("null");
            setcontractorId("null");
        }
        if (status === "Approved") {
            setFromDate("null");
            setToDate("null");
        }

        else {
            setFromDate("null");
            setToDate("null");
        }

    }, [workperformedby, status]);







    // Fetch data from the API based on the selected status
    useEffect(() => {
        fetchEOTData();
    }, [project, status, contractorId, fromdate, todate, approvefromdate, approvetodate, workperformedby]);

    // Handle status change from the dropdown
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };


    return (
        <div>
            <div className="attendance-subcontainer margin">
                <div className="field-cont width-100vw justify-between">

                    <div className="flex-row flex-wrap">
                        <SubCompany toggleCompany={toggleCompany} setToggleCompany={setToggleCompany} />


                        <Projects toggleProject={project} setToggleProject={setProject} subcompany={toggleCompany} />

                        <div title="Status Filter" className="field-cont-div">
                            <CircularGrid />
                            <select
                                id="status"
                                name="status"
                                placeholder="Status"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                className={`attendance-input-field width-10vw `}
                            >
                                {/* <option value="null">Status</option> */}

                                <option value="pending">Pending</option>

                                <option value="Approved">Approved</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div title="Letter For" className="field-cont-div">
                            <CircularGrid />
                            <select
                                onChange={(e) => setWorkperformedby(e.target.value)}
                                value={workperformedby}
                                className="attendance-input-field width-10vw date-field"
                            >
                                {/* <option value="null">Work Done By</option> */}
                                <option value="consultant">Consultant</option>
                                <option value="contractor">Contractor</option>

                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        {/* Show the contractor field only if "contractor" is selected */}
                        {workperformedby === "contractor" && (
                            <div title="Company Filter" className="field-cont-div">
                                <CircularGrid />
                                <select
                                    onChange={(e) => setcontractorId(e.target.value)}
                                    value={contractorId}
                                    className="attendance-input-field width-10vw date-field"
                                >
                                    <option value="null">Contractor Name</option>
                                    {contractorlist?.map((i) => (
                                        <option key={i.id} value={i.id}>{i.name}</option>
                                    ))}
                                </select>
                                <hr className="field-cont-hr" />
                            </div>
                        )}

                        {status === "pending" ? (
                            <>
                                <div title='From Date' className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw date-field date-field-filter"
                                        placeholder="From Date"
                                        type="date"
                                        value={fromdate}
                                        onChange={(e) => setFromDate(e.target.value || "null")}
                                    />
                                </div>
                                <hr className="field-cont-hr" />
                                <div title='To Date' className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw date-field date-field-filter"
                                        placeholder="To Date"
                                        type="date"
                                        min={fromdate}
                                        value={todate}
                                        onChange={(e) => setToDate(e.target.value || "null")}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div title='From Date' className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw date-field date-field-filter"
                                        placeholder="From Date"
                                        type="date"
                                        value={approvefromdate}
                                        onChange={(e) => setApproveFromDate(e.target.value || "null")}
                                    />
                                </div>
                                <hr className="field-cont-hr" />
                                <div title='To Date' className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw date-field date-field-filter"
                                        placeholder="To Date"
                                        type="date"
                                        min={approvefromdate}
                                        value={approvetodate}
                                        onChange={(e) => setApproveToDate(e.target.value || "null")}
                                    />
                                </div>
                            </>
                        )}


                        <hr className="field-cont-hr" />


                    </div>

                    <div>


                    </div>
                </div>
            </div>


            <div className='invoice-cont    bgwhite transaction-cont' >
                <div className='global-table-cont scroll-container-table no-max-height' style={{ minHeight: "450px" }}>
                    <table className='custom-table' style={{ minHeight: "311px" }}>
                        <thead className='heading'>
                            <tr className='custom-table-head-tr'>
                                <th className='table-heading-text'>S.no.</th>
                                <th className='table-heading-text'>PR NO & Project Name</th>
                                <th className='table-heading-text'>EOT Code</th>
                                <th className='table-heading-text'>Letter No.</th>
                                {/* <th className='align-center font-size-text font-weightbold'>Name</th> */}
                                <th className='table-heading-text'>Contractor</th>
                                <th className='table-heading-text text-center'>No. of Days</th>
                                {status === "pending" ?
                                    <th className='table-heading-text '>Initiated Date</th>
                                    :
                                    <th className='table-heading-text '>Approval Date</th>
                                }
                                {/* <th className='table-heading-text'>Remark</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {eotData.map((project, index) => (
                                <React.Fragment key={index}>
                                    <tr className='custom-table-head-td global-table-td'>
                                        <td className='table-body'>{index + 1}.</td>
                                        <td className='table-body'><div className='whitespace-normal'>{project.project_code} &
                                            {project.project_short_name}</div></td>
                                        <td className='table-body'>{project.eot_code}</td>
                                        <td className='table-body'>{project.letter_number}</td>
                                        <td className='table-body '>{project.contractor_name ? project.contractor_name : "-"}</td>
                                        <td className='table-body text-center'>{project.approved_days ? project.approved_days : "-"}</td>
                                        {status === "pending" ?
                                            <td className='table-body '>{project.process_initiate_date ? formatDate(project.process_initiate_date) : "-"}</td>
                                            :
                                            <td className='table-body '>{project.approved_date ? formatDate(project.approved_date) : "-"}</td>
                                        }
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    );
};

const COSDetails = () => {
    const [cosData, setcosData] = useState([]);
    const [buffer, setbuffer] = useState(false);


    const [error, setError] = useState(null);
    const [status, setStatus] = useState('pending'); // Default to 'pending'
    const siteRole = sessionStorage.getItem("sitemanagement_role");

    const [contractorlist, setContractorList] = useState([]);
    const [filterMonth, setFilterMonth] = useState("null");
    const [filterYear, setFilterYear] = useState("null");
    const [project, setProject] = useState("null");
    const [contractorId, setcontractorId] = useState("null");
    const [subcompany, setSubCompany] = useState("null");
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 20; // starting 20 years ago
    const endYear = currentYear + 20; // up to 20 years in the future
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
    const [toggleCompany, setToggleCompany] = useState(null);
    const [selectedLetterStatus, setLetterStatus] = useState('null');
    const [workperformedby, setWorkperformedby] = useState("null");
    const today = new Date();
    const yesterday = new Date();
    const startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [approvefromdate, setApproveFromDate] = useState("null");
    const [approvetodate, setApproveToDate] = useState("null");


    // Fetch data from the API based on the selected status
    const fetchCOSData = async () => {
        setbuffer(true);
        try {
            const response = await axios.get(`${BASE_URL}/siteproject/cosfilter/${project}/${workperformedby}/${contractorId}/${status}/${fromdate}/${todate}/${approvefromdate}/${approvetodate}/`);
            setcosData(response.data);
            setbuffer(false);
        } catch (err) {
            setError('Failed to fetch EOT data');
            setbuffer(false);
        }
    };

    const getContractor = async () => {
        setbuffer(true);
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/contractorbyproject/${project}/`);
            setContractorList(res.data)
        } catch (error) {
            console.error(error);

        } finally {
            setbuffer(false);
        }
    };

    useEffect(() => {
        if (workperformedby === "contractor") {
            getContractor();
        };
    }, [project, workperformedby]);

    useEffect(() => {
        if (workperformedby === "consultant" || status === "pending") {
            setApproveFromDate("null");
            setApproveToDate("null");
            setcontractorId("null");
        }
        if (status === "Approved") {
            setFromDate("null");
            setToDate("null");
        }

        else {
            setFromDate("null");
            setToDate("null");
        }

    }, [workperformedby, status]);

    useEffect(() => {
        fetchCOSData()
    }, [project, status, contractorId, fromdate, todate, approvefromdate, approvetodate, workperformedby])

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };


    console.log(cosData)


    return (
        <div>
            <div className="attendance-subcontainer margin">
                <div className="field-cont width-100vw justify-between">

                    <div className="flex-row flex-wrap">
                        <SubCompany toggleCompany={toggleCompany} setToggleCompany={setToggleCompany} />


                        <Projects toggleProject={project} setToggleProject={setProject} subcompany={toggleCompany} />

                        <div title="Status Filter" className="field-cont-div">
                            <CircularGrid />
                            <select
                                id="status"
                                name="status"
                                placeholder="Status"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                className={`attendance-input-field width-10vw `}
                            >
                                {/* <option value="null">Status</option> */}

                                <option value="pending">Pending</option>

                                <option value="Approved">Approved</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div title="Letter For" className="field-cont-div">
                            <CircularGrid />
                            <select
                                onChange={(e) => setWorkperformedby(e.target.value)}
                                value={workperformedby}
                                className="attendance-input-field width-10vw date-field"
                            >
                                {/* <option value="null">Work Done By</option> */}
                                <option value="consultant">Consultant</option>
                                <option value="contractor">Contractor</option>

                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        {/* Show the contractor field only if "contractor" is selected */}
                        {workperformedby === "contractor" && (
                            <div title="Company Filter" className="field-cont-div">
                                <CircularGrid />
                                <select
                                    onChange={(e) => setcontractorId(e.target.value)}
                                    value={contractorId}
                                    className="attendance-input-field width-10vw date-field"
                                >
                                    <option value="null">Contractor Name</option>
                                    {contractorlist?.map((i) => (
                                        <option key={i.id} value={i.id}>{i.name}</option>
                                    ))}
                                </select>
                                <hr className="field-cont-hr" />
                            </div>
                        )}

                        {status === "pending" ? (
                            <>
                                <div title='From Date' className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw date-field date-field-filter"
                                        placeholder="From Date"
                                        type="date"
                                        value={fromdate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                    />
                                </div>
                                <hr className="field-cont-hr" />
                                <div title='To Date' className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw date-field date-field-filter"
                                        placeholder="To Date"
                                        type="date"
                                        min={fromdate}
                                        value={todate}
                                        onChange={(e) => setToDate(e.target.value)}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div title='From Date' className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw date-field date-field-filter"
                                        placeholder="From Date"
                                        type="date"
                                        value={approvefromdate}
                                        onChange={(e) => setApproveFromDate(e.target.value)}
                                    />
                                </div>
                                <hr className="field-cont-hr" />
                                <div title='To Date' className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw date-field date-field-filter"
                                        placeholder="To Date"
                                        type="date"
                                        min={approvefromdate}
                                        value={approvetodate}
                                        onChange={(e) => setApproveToDate(e.target.value)}
                                    />
                                </div>
                            </>
                        )}


                        <hr className="field-cont-hr" />


                    </div>

                    <div>


                    </div>
                </div>
            </div>
            <div className='invoice-cont    bgwhite transaction-cont ' >

                <div className='global-table-cont scroll-container-table no-max-height' style={{ minHeight: "450px" }}>
                    <table className='custom-table' style={{ minHeight: "311px" }}>
                        <thead className='heading'>
                            <tr className='custom-table-head-tr'>
                                <th className='table-heading-text'>S.no.</th>
                                <th className='table-heading-text'>PR NO & Project Name</th>
                                <th className='table-heading-text'>COS Code</th>
                                <th className='table-heading-text'>Letter No.</th>
                                {/* <th className='align-center font-size-text font-weightbold'>Name</th> */}
                                <th className='table-heading-text'>Contractor</th>
                                <th className='table-heading-text text-end'>Amount</th>
                                {status === "pending" ?
                                    <th className='table-heading-text '>Initiated Date</th>
                                    :
                                    <th className='table-heading-text '>Approval Date</th>
                                }
                                {/* <th className='table-heading-text'>Remark</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {cosData.map((project, index) => (
                                <React.Fragment key={index}>
                                    <tr className='custom-table-head-td global-table-td'>
                                        <td className='table-body'>{index + 1}.</td>
                                        {/* <td className='table-body'><div className='whitespace-normal'>{project.project_code} &
                      {project.project_short_name}</div></td> */}
                                        <td className='table-body'>{project.project_code}:<br />{project.project_short_name}</td>
                                        <td className='table-body'>{project.cos_code}</td>
                                        <td className='table-body'>{project.letter_number}</td>
                                        <td className='table-body '>{project.contractor_name ? project.contractor_name : "-"}</td>
                                        <td className='table-body text-end'>₹{project.amount ? formatCurrencyIndian(project.amount) : "-"}</td>
                                        {status === "pending" ?
                                            <td className='table-body '>{project.process_initiate_date ? formatDate(project.process_initiate_date) : "-"}</td>
                                            :
                                            <td className='table-body '>{project.approval_date ? formatDate(project.approval_date) : "-"}</td>
                                        }
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const MilestoneDetails = ({ projectId, contractor, contractor_name, site_prcode }) => {
    const [progress, setProgress] = useState([]);
    const [buffer, setBuffering] = useState(false); // Buffering logic
    const [loading, setLoading] = useState(false); // Loading logic
    const role = sessionStorage.getItem('sitemanagement_role');
    const [filterMonth, setFilterMonth] = useState("");
    const [filterYear, setFilterYear] = useState("");
    const years = Array.from("");
    const [project, setProject] = useState("null");
    const [subcompany, setSubCompany] = useState("null");
    const [projectList, setProjectList] = useState([]);
    const [vehicleList, setVehicleList] = useState([]);

    const [vehicle, setVehicle] = useState("null");






    const [physicalProgress, setPhysicalProgress] = useState([]);


    const [progressdata, setProgressData] = useState([]);


    const getProgress = async () => {
        setBuffering(true); // Start buffering

        try {
            const response = await axios.get(`${BASE_URL}/siteproject/progressbyproject/${projectId ? projectId : "null"}/${contractor ? contractor : "null"}/`);

            setProgressData(response.data)

            const filteredData = response.data.map(item => ({
                physical_progress: item.physical_progress,
                estimated_physical_progress: item.estimated_physical_progress,
                month: item.month
            }));

            setPhysicalProgress(filteredData);


            const sortedData = response.data.sort((a, b) => {
                if (a.year === b.year) {
                    return b.month - a.month;
                }
                return b.year - a.year;
            });


            setProgress(sortedData);
        } catch (err) {
            console.log("err");
            console.log(err);
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End buffering
        }
    };
    console.log(projectId)
    useEffect(() => {
        getProgress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, contractor]);


    const handleProgressDelete = async (e, i, getProgress) => {
        e.preventDefault();


        setLoading(true); // loading logic
        const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

        try {
            let res = await axios.delete(`${BASE_URL}/siteproject/progressupdate/${i.id}/`);

            if (res.status === 200) {
                await getProgress();
            } else {
                alert(res);
            }
        } catch (err) {
            // toast logic
            handleErrorToast(err);
        } finally {
            setLoading(false); // loading logic
            toast.dismiss(loadingToastId);
        }
    }

    const getMonthName = (monthNumber) => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return months[monthNumber - 1];
    };


    return (
        <>
            {role === "SiteManagement" ? ("") : (

                <div className="attendance-subcontainer margin">
                    <div className="field-cont width-100vw justify-between">
                        <div className="flex-row">
                            <div title='Month Filter' className="field-cont-div">
                                <CircularGrid />

                                <select
                                    className="attendance-input-field width-10vw   date-field"
                                    type="text"
                                    value={filterMonth}
                                    onChange={(e) => setFilterMonth(e.target.value)}
                                >
                                    <option value="">Select Month</option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>

                                <hr className="field-cont-hr" />
                            </div>

                            <div title='Year Filter' className="field-cont-div">
                                <CircularGrid />

                                <select
                                    className="attendance-input-field width-10vw   date-field"
                                    type="text"
                                    value={filterYear}
                                    onChange={(e) => setFilterYear(e.target.value)}
                                >
                                    <option value="">Select Year</option>
                                    {years.reverse().map((year) => (
                                        <option key={year} value={year}>{year}</option>
                                    ))}
                                </select>

                                <hr className="field-cont-hr" />
                            </div>

                            <Projects toggleProject={project} setToggleProject={setProject} subcompany={subcompany} />

                            {/* <div title="Vehicle Filter" className="field-cont-div ">
                                <CircularGrid />
                                <select
                                    id="vehicle"
                                    name="vehicle"
                                    placeholder="vehicle"
                                    value={vehicle}
                                    onChange={(e) => setVehicle(e.target.value)}
                                    className={`attendance-input-field width-10vw`}
                                >

                                    <option value="null">Select Vehicle</option>
                                    {vehicleList?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                        <option key={index} value={i.id}>{i.name}-{i.vehicle_num}</option>
                                    ))}
                                </select>


                            </div> */}
                        </div>

                        <div>


                        </div>
                    </div>
                </div>
            )}
            <div className="info-box-div-bg">

                <div>
                    <div className="jd-heading-outer-flex">


                    </div>
                    {/* <div>
                        <div className="jd-heading-bottom-bold"></div>
                        <div className="jd-heading-bottom-light"></div>
                    </div> */}
                </div>

                <div className="form-flex-wrap table-box paddingTop-0px scroll-container-table child-table-scroll">
                    <table className="table-css">
                        <thead className='table-heading'>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-lefttt">S. No.</th>
                                <th className="table-heading-text">Month</th>
                                <th className="table-heading-text">Year</th>
                                <th className="table-heading-text text-center">Submitted Progress (%)</th>
                                <th className="table-heading-text text-center">Approved Progress (%)</th>
                                <th className="table-heading-text text-center ">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {progress.map((i, index) => (
                                <React.Fragment key={index}>

                                    <tr className="custom-table-head-td">
                                        <td className="table-body text-lefttt">{index + 1}.</td>
                                        <td className="table-body">{getMonthName(i.month)}</td>
                                        <td className="table-body">{i.year}</td>
                                        <td className="table-body width-15vw">
                                            <tr>
                                                <td className="padding-bottom-5px">
                                                    Physical Progress
                                                </td>
                                                <td style={{ padding: "0px 10px" }}>:</td>
                                                <td className="text-end">{i.submitted_physical_progress} %</td>
                                            </tr>
                                            <tr>
                                                <td className="padding-bottom-5px">
                                                    Financial Progress
                                                </td>
                                                <td style={{ padding: "0px 10px" }}>:</td>
                                                <td className="text-end">{i.submitted_financial_progress} %</td>
                                            </tr>
                                            <tr>
                                                <td className="padding-bottom-5px">
                                                    Progress Letter
                                                </td>
                                                <td style={{ padding: "0px 10px" }}>:</td>
                                                <td className="text-end"><AssociatedLetters id={i.submitted_letter} /></td>
                                            </tr>
                                        </td>
                                        <td className="table-body width-15vw">
                                            <tr>
                                                <td className="padding-bottom-5px">
                                                    Physical Progress
                                                </td>
                                                <td style={{ padding: "0px 10px" }}>:</td>
                                                <td className="text-end">{i.physical_progress} %</td>
                                            </tr>
                                            <tr>
                                                <td className="padding-bottom-5px">
                                                    Financial Progress
                                                </td>
                                                <td style={{ padding: "0px 10px" }}>:</td>
                                                <td className="text-end">{i.financial_progress} %</td>
                                            </tr>
                                            <tr>
                                                <td className="padding-bottom-5px">
                                                    Progress Letter
                                                </td>
                                                <td style={{ padding: "0px 10px" }}>:</td>
                                                <td className=""><AssociatedLetters id={i.approved_letter} /></td>
                                            </tr>
                                        </td>
                                        <td className="table-body  text-center">
                                            <UpdateProgressdetails i={i} project={projectId} getprogressDetails={getProgress} progressdata={progress} contractor={contractor} />
                                            <button className="model-delete-button"
                                                onClick={(e) => handleProgressDelete(e, i, getProgress)}
                                            >
                                                <DeleteDustbin />
                                            </button>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    );
};

const BillingDetailsProgress = ({ projectId, contractor, contractor_name, site_prcode }) => {
    const [progress, setProgress] = useState([]);
    const [buffer, setBuffering] = useState(false); // Buffering logic
    const [loading, setLoading] = useState(false); // Loading logic
    const role = sessionStorage.getItem('sitemanagement_role');
    const [bufferr, setbuffer] = useState(false);

    const [filterMonth, setFilterMonth] = useState("");
    const [filterYear, setFilterYear] = useState("");
    const years = Array.from("");
    const [project, setProject] = useState("null");
    const [subcompany, setSubCompany] = useState("null");
    const [projectList, setProjectList] = useState([]);
    const [vehicleList, setVehicleList] = useState([]);

    const [vehicle, setVehicle] = useState("null");

    const [workperformedby, setWorkperformedby] = useState("null");
    const [contractorId, setcontractorId] = useState("null");
    const [contractorlist, setContractorList] = useState([]);
    const [toggleCompany, setToggleCompany] = useState(null);
    const [ststartdate, setStStartDate] = useState("null");
    const [stenddate, setStEndDate] = useState("null");

    const [enstartdate, setEnStartDate] = useState("null");
    const [enenddate, setEnEndDate] = useState("null");
    const [billData, setBillData] = useState([]);
    const [error, setError] = useState(null);
    const [BillingData, setBillingData] = useState([]);





    const [physicalProgress, setPhysicalProgress] = useState([]);


    const [progressdata, setProgressData] = useState([]);

    useEffect(() => {
        const fetchBillingData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${BASE_URL}/siteproject/billingfilterss/${project}/${contractorId}/${ststartdate}/${stenddate}/"null"/"null"/`);
                setBillData(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch EOT data');
                setLoading(false);
            }
        };

        fetchBillingData();
    },);


    const getProgress = async () => {
        setBuffering(true); // Start buffering

        try {
            const response = await axios.get(`${BASE_URL}/siteproject/billingfilterss/${project}/${contractorId}/${ststartdate}/${stenddate}/"null"/"null"/`);

            setProgressData(response.data)

            const filteredData = response.data.map(item => ({
                physical_progress: item.physical_progress,
                estimated_physical_progress: item.estimated_physical_progress,
                month: item.month
            }));

            setPhysicalProgress(filteredData);


            const sortedData = response.data.sort((a, b) => {
                if (a.year === b.year) {
                    return b.month - a.month;
                }
                return b.year - a.year;
            });


            setProgress(sortedData);
        } catch (err) {
            console.log("err");
            console.log(err);
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End buffering
        }
    };
    console.log(projectId)
    useEffect(() => {
        getProgress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, contractor]);


    const handleProgressDelete = async (e, i, getProgress) => {
        e.preventDefault();


        setLoading(true); // loading logic
        const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

        try {
            let res = await axios.delete(`${BASE_URL}/siteproject/progressupdate/${i.id}/`);

            if (res.status === 200) {
                await getProgress();
            } else {
                alert(res);
            }
        } catch (err) {
            // toast logic
            handleErrorToast(err);
        } finally {
            setLoading(false); // loading logic
            toast.dismiss(loadingToastId);
        }
    }

    const getContractor = async () => {
        setbuffer(true);
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/contractorbyproject/${project}/`);
            setContractorList(res.data)
        } catch (error) {
            console.error(error);

        } finally {
            setbuffer(false);
        }
    };

    useEffect(() => {
        getContractor();
    }, [project])


    const getMonthName = (monthNumber) => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return months[monthNumber - 1];
    };





    return (
        <>
            {role === "SiteManagement" ? ("") : (

                <div className="attendance-subcontainer margin">
                    <div className="field-cont width-100vw justify-between">
                        <div className="flex-row">

                            <SubCompany toggleCompany={toggleCompany} setToggleCompany={setToggleCompany} />

                            <Projects toggleProject={project} setToggleProject={setProject} subcompany={toggleCompany} />

                            <div title="Company Filter" className="field-cont-div">
                                <CircularGrid />
                                <select
                                    onChange={(e) => setcontractorId(e.target.value)}
                                    value={contractorId}
                                    className="attendance-input-field width-10vw date-field"
                                >
                                    <option value="null">Contractor Name</option>
                                    {contractorlist?.map((i) => (
                                        <option key={i.id} value={i.id}>{i.name}</option>
                                    ))}
                                </select>
                                <hr className="field-cont-hr" />
                            </div>

                            <div title='From Date' className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={ststartdate}
                                    onChange={(e) => setStStartDate(e.target.value)}
                                />
                            </div>
                            <hr className="field-cont-hr" />
                            <div title='To Date' className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw date-field date-field-filter"
                                    placeholder="To Date"
                                    type="date"
                                    min={ststartdate}
                                    value={stenddate}
                                    onChange={(e) => setStEndDate(e.target.value)}
                                />
                            </div>

                        </div>

                        <div>


                        </div>
                    </div>
                </div>
            )}
            <div className="info-box-div-bg">

                <div>


                </div>

                <div className="form-flex-wrap table-box paddingTop-0px scroll-container-table child-table-scroll" style={{ minHeight: "450px" }}>
                    <table className='custom-table' style={{ minHeight: "311px" }}>
                        <thead className='heading'>
                            <tr className='custom-table-head-tr global-table-tr'>
                                <th className='table-heading-text'>S no.</th>
                                <th className='table-heading-text'>PR NO & Project Name</th>
                                <th className='table-heading-text'>Contractor</th>
                                <th className='table-heading-text '>Bill Details</th>
                                <th className='table-heading-text text-left-sr'>Submitted</th>
                                <th className='table-heading-text text-center'>Recommended</th>
                                <th className='table-heading-text text-left-sr'>Other Amount</th>

                            </tr>
                        </thead>
                        <tbody>
                            {billData.map((project, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="8"></td>
                                    </tr>
                                    <tr className='custom-table-head-td global-table-td'>
                                        <td className='table-body text-left-sr'>{index + 1}.</td>
                                        <td className='table-body'>{project.site_prcode} <br /><div className='whitespace-normal'>{project.project_short_name}</div>

                                        </td>
                                        <td className='table-body'><div className='whitespace-normal'>{project.contractorname ? project.contractorname : <p className=''>-</p>}</div></td>
                                        <td className='table-body' >
                                            <div >
                                                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                    <tbody>
                                                        <tr>

                                                            <td className="align-right table-body">
                                                                <div className='whitespace-normal'>
                                                                    {project?.bill_num
                                                                        ? project?.bill_num
                                                                        : "-"}, {" "} {project?.bill_type
                                                                            ? project?.bill_type
                                                                            : "-"},{" "}  {project?.bill_catagory
                                                                                ? project?.bill_catagory === "balancebills" ? "Bal Bill" : project?.bill_catagory
                                                                                : "-"}
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            {/* <td className="padding-bottom-5px table-body">Start date</td> */}
                                                            {/* <td className='table-body' style={{ padding: "0px 4px" }}>:</td> */}
                                                            <td className="table-body">
                                                                {project?.bill_period_start
                                                                    ? formatDate(project?.bill_period_start)
                                                                    : "-"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            {/* <td className="padding-bottom-5px table-body">End date</td> */}
                                                            {/* <td className='table-body' style={{ padding: "0px 4px" }}>:</td> */}
                                                            <td className="table-body">
                                                                {project?.bill_period_end
                                                                    ? formatDate(project?.bill_period_end)
                                                                    : "-"}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                        <td className='table-body'>
                                            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td className="padding-bottom-5px table-body">Date</td>
                                                        <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                                                        <td className="text-end table-body">
                                                            {project?.submitted_bill_date
                                                                ? formatDate(project?.submitted_bill_date)
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="padding-bottom-5px table-body">Amount</td>
                                                        <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                                                        <td className="table-body text-end" >
                                                            {project?.submitted_bill_amount
                                                                ? `₹ ${formatRoundoffCrores(project?.submitted_bill_amount)}`
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td className='table-body'>
                                            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td className="padding-bottom-5px table-body">Date</td>
                                                        <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                                                        <td className="text-end table-body">
                                                            {project?.recomended_bill_date
                                                                ? formatDate(project?.recomended_bill_date)
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="padding-bottom-5px table-body">Amount</td>
                                                        <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                                                        <td className="table-body text-end" >
                                                            {project?.recommended_bill_amount
                                                                ? `₹ ${formatRoundoffCrores(project?.recommended_bill_amount)}`
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td className='table-body'>
                                            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td className="padding-bottom-5px table-body">Ld </td>
                                                        <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                                                        <td className="text-end table-body">
                                                            {project?.ld_amount
                                                                ? `₹ ${formatRoundoffCrores(project?.ld_amount)}`
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="padding-bottom-5px table-body">Withheld</td>
                                                        <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                                                        <td className="table-body text-end">
                                                            {project?.withheld_amt
                                                                ? `₹ ${formatRoundoffCrores(project?.withheld_amt)}`
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="padding-bottom-5px table-body">Released Withheld </td>
                                                        <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                                                        <td className="table-body text-end">
                                                            {project?.released_withheld_amt
                                                                ? `₹ ${formatRoundoffCrores(project?.released_withheld_amt)}`
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="padding-bottom-5px table-body">Deduction </td>
                                                        <td className='table-body' style={{ padding: "0px 4px" }}>:</td>
                                                        <td className="table-body text-end">
                                                            {project?.deduction_amount
                                                                ? `₹ ${formatRoundoffCrores(project?.deduction_amount)}`
                                                                : "-"}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    );
};

const BGDetails = () => {
    const [eotData, setEotData] = useState([]);
    const [buffer, setbuffer] = useState(false);
    const siteRole = sessionStorage.getItem("sitemanagement_role");

    const [contractorlist, setContractorList] = useState([]);
    const [filterMonth, setFilterMonth] = useState("null");
    const [filterYear, setFilterYear] = useState("null");
    const [project, setProject] = useState("null");
    const [contractorId, setcontractorId] = useState("null");
    const [subcompany, setSubCompany] = useState("null");
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 20; // starting 20 years ago
    const endYear = currentYear + 20; // up to 20 years in the future
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
    const [toggleCompany, setToggleCompany] = useState(null);
    const [workperformedby, setWorkperformedby] = useState("consultant");

    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [approvefromdate, setApproveFromDate] = useState("null");
    const [approvetodate, setApproveToDate] = useState("null");









    const [error, setError] = useState(null);
    const [status, setStatus] = useState('pending'); // Default to 'pending'

    const fetchEOTData = async () => {
        setbuffer(true);
        try {
            const response = await axios.get(`${BASE_URL}/siteproject/eotfilter/${project}/${workperformedby}/${contractorId}/${status}/${fromdate}/${todate}/${approvefromdate}/${approvetodate}/`);
            setEotData(response.data);
        } catch (err) {
            setError('Failed to fetch EOT data');
        } finally {
            setbuffer(false);
        }

    };

    const getContractor = async () => {
        setbuffer(true);
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/contractorbyproject/${project}/`);
            setContractorList(res.data)
        } catch (error) {
            console.error(error);

        } finally {
            setbuffer(false);
        }
    };

    useEffect(() => {
        if (workperformedby === "contractor") {
            getContractor();
        };
    }, [project, workperformedby]);

    useEffect(() => {
        if (workperformedby === "consultant" || status === "pending") {
            setApproveFromDate("null");
            setApproveToDate("null");
            setcontractorId("null");
        }
        if (status === "Approved") {
            setFromDate("null");
            setToDate("null");
        }

        else {
            setFromDate("null");
            setToDate("null");
        }

    }, [workperformedby, status]);







    // Fetch data from the API based on the selected status
    useEffect(() => {
        fetchEOTData();
    }, [project, status, contractorId, fromdate, todate, approvefromdate, approvetodate, workperformedby]);

    // Handle status change from the dropdown
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };


    return (
        <div>
            <div className="attendance-subcontainer margin">
                <div className="field-cont width-100vw justify-between">

                    <div className="flex-row flex-wrap">
                        <SubCompany toggleCompany={toggleCompany} setToggleCompany={setToggleCompany} />


                        <Projects toggleProject={project} setToggleProject={setProject} subcompany={toggleCompany} />

                        <div title="Status Filter" className="field-cont-div">
                            <CircularGrid />
                            <select
                                id="status"
                                name="status"
                                placeholder="Status"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                className={`attendance-input-field width-10vw `}
                            >
                                {/* <option value="null">Status</option> */}

                                <option value="pending">Pending</option>

                                <option value="Approved">Approved</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        <div title="Letter For" className="field-cont-div">
                            <CircularGrid />
                            <select
                                onChange={(e) => setWorkperformedby(e.target.value)}
                                value={workperformedby}
                                className="attendance-input-field width-10vw date-field"
                            >
                                {/* <option value="null">Work Done By</option> */}
                                <option value="consultant">Consultant</option>
                                <option value="contractor">Contractor</option>

                            </select>
                            <hr className="field-cont-hr" />
                        </div>

                        {/* Show the contractor field only if "contractor" is selected */}
                        {workperformedby === "contractor" && (
                            <div title="Company Filter" className="field-cont-div">
                                <CircularGrid />
                                <select
                                    onChange={(e) => setcontractorId(e.target.value)}
                                    value={contractorId}
                                    className="attendance-input-field width-10vw date-field"
                                >
                                    <option value="null">Contractor Name</option>
                                    {contractorlist?.map((i) => (
                                        <option key={i.id} value={i.id}>{i.name}</option>
                                    ))}
                                </select>
                                <hr className="field-cont-hr" />
                            </div>
                        )}

                        {status === "pending" ? (
                            <>
                                <div title='From Date' className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw date-field date-field-filter"
                                        placeholder="From Date"
                                        type="date"
                                        value={fromdate}
                                        onChange={(e) => setFromDate(e.target.value || "null")}
                                    />
                                </div>
                                <hr className="field-cont-hr" />
                                <div title='To Date' className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw date-field date-field-filter"
                                        placeholder="To Date"
                                        type="date"
                                        min={fromdate}
                                        value={todate}
                                        onChange={(e) => setToDate(e.target.value || "null")}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div title='From Date' className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw date-field date-field-filter"
                                        placeholder="From Date"
                                        type="date"
                                        value={approvefromdate}
                                        onChange={(e) => setApproveFromDate(e.target.value || "null")}
                                    />
                                </div>
                                <hr className="field-cont-hr" />
                                <div title='To Date' className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw date-field date-field-filter"
                                        placeholder="To Date"
                                        type="date"
                                        min={approvefromdate}
                                        value={approvetodate}
                                        onChange={(e) => setApproveToDate(e.target.value || "null")}
                                    />
                                </div>
                            </>
                        )}


                        <hr className="field-cont-hr" />


                    </div>

                    <div>


                    </div>
                </div>
            </div>


            <div className='invoice-cont    bgwhite transaction-cont' >
                <div className='global-table-cont scroll-container-table no-max-height' style={{ minHeight: "450px" }}>
                    <table className='custom-table' style={{ minHeight: "311px" }}>
                        <thead className='heading'>
                            <tr className='custom-table-head-tr'>
                                <th className='table-heading-text'>S.no.</th>
                                <th className='table-heading-text'>PR NO & Project Name</th>
                                <th className='table-heading-text'>EOT Code</th>
                                <th className='table-heading-text'>Letter No.</th>
                                {/* <th className='align-center font-size-text font-weightbold'>Name</th> */}
                                <th className='table-heading-text'>Contractor</th>
                                <th className='table-heading-text text-center'>No. of Days</th>
                                {status === "pending" ?
                                    <th className='table-heading-text '>Initiated Date</th>
                                    :
                                    <th className='table-heading-text '>Approval Date</th>
                                }
                                {/* <th className='table-heading-text'>Remark</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {eotData.map((project, index) => (
                                <React.Fragment key={index}>
                                    <tr className='custom-table-head-td global-table-td'>
                                        <td className='table-body'>{index + 1}.</td>
                                        <td className='table-body'><div className='whitespace-normal'>{project.project_code} &
                                            {project.project_short_name}</div></td>
                                        <td className='table-body'>{project.eot_code}</td>
                                        <td className='table-body'>{project.letter_number}</td>
                                        <td className='table-body '>{project.contractor_name ? project.contractor_name : "-"}</td>
                                        <td className='table-body text-center'>{project.approved_days ? project.approved_days : "-"}</td>
                                        {status === "pending" ?
                                            <td className='table-body '>{project.process_initiate_date ? formatDate(project.process_initiate_date) : "-"}</td>
                                            :
                                            <td className='table-body '>{project.approved_date ? formatDate(project.approved_date) : "-"}</td>
                                        }
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    );
};



const InsuranceDetails = () => {
    const [eotData, setEotData] = useState([]);
    const [buffer, setbuffer] = useState(false);
    const siteRole = sessionStorage.getItem("sitemanagement_role");

    const [contractorlist, setContractorList] = useState([]);
    const [filterMonth, setFilterMonth] = useState("null");
    const [filterYear, setFilterYear] = useState("null");
    const [project, setProject] = useState("null");
    const [contractorId, setcontractorId] = useState("null");
    const [subcompany, setSubCompany] = useState("null");
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 20; // starting 20 years ago
    const endYear = currentYear + 20; // up to 20 years in the future
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
    const [toggleCompany, setToggleCompany] = useState(null);
    const [workperformedby, setWorkperformedby] = useState("consultant");

    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [approvefromdate, setApproveFromDate] = useState("null");
    const [approvetodate, setApproveToDate] = useState("null");









    const [error, setError] = useState(null);
    const [status, setStatus] = useState('pending'); // Default to 'pending'

    const fetchEOTData = async () => {
        setbuffer(true);
        try {
            const response = await axios.get(`${BASE_URL}/siteproject/eotfilter/${project}/${workperformedby}/${contractorId}/${status}/${fromdate}/${todate}/${approvefromdate}/${approvetodate}/`);
            setEotData(response.data);
        } catch (err) {
            setError('Failed to fetch EOT data');
        } finally {
            setbuffer(false);
        }

    };

    const getContractor = async () => {
        setbuffer(true);
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/contractorbyproject/${project}/`);
            setContractorList(res.data)
        } catch (error) {
            console.error(error);

        } finally {
            setbuffer(false);
        }
    };

    useEffect(() => {
        if (workperformedby === "contractor") {
            getContractor();
        };
    }, [project, workperformedby]);

    useEffect(() => {
        if (workperformedby === "consultant" || status === "pending") {
            setApproveFromDate("null");
            setApproveToDate("null");
            setcontractorId("null");
        }
        if (status === "Approved") {
            setFromDate("null");
            setToDate("null");
        }

        else {
            setFromDate("null");
            setToDate("null");
        }

    }, [workperformedby, status]);







    // Fetch data from the API based on the selected status
    useEffect(() => {
        fetchEOTData();
    }, [project, status, contractorId, fromdate, todate, approvefromdate, approvetodate, workperformedby]);

    // Handle status change from the dropdown
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };


    return (
        <div>
            <div className="attendance-subcontainer margin">
                <div className="field-cont width-100vw justify-between">

                    <div className="flex-row flex-wrap">
                        <SubCompany toggleCompany={toggleCompany} setToggleCompany={setToggleCompany} />


                        <Projects toggleProject={project} setToggleProject={setProject} subcompany={toggleCompany} />


                        <div title='From Date' className="date-uppercase ">
                            <input
                                className="attendance-input-field width-10vw date-field date-field-filter"
                                placeholder="From Date"
                                type="date"
                                value={approvefromdate}
                                onChange={(e) => setApproveFromDate(e.target.value || "null")}
                            />
                        </div>
                        <hr className="field-cont-hr" />
                        <div title='To Date' className="date-uppercase ">
                            <input
                                className="attendance-input-field width-10vw date-field date-field-filter"
                                placeholder="To Date"
                                type="date"
                                min={approvefromdate}
                                value={approvetodate}
                                onChange={(e) => setApproveToDate(e.target.value || "null")}
                            />
                        </div>



                        <hr className="field-cont-hr" />


                    </div>

                    <div>


                    </div>
                </div>
            </div>


            <div className='invoice-cont    bgwhite transaction-cont' >
                <div className='global-table-cont scroll-container-table no-max-height' style={{ minHeight: "450px" }}>
                    <table className='custom-table' style={{ minHeight: "311px" }}>
                        <thead className='heading'>
                            <tr className='custom-table-head-tr'>
                                <th className="table-heading-text text-lefttt ">S.No.</th>
                                <th className="table-heading-text text-center">Policy Number</th>
                                <th className="table-heading-text text-center">Insurance Name</th>
                                <th className="table-heading-text text-center">Amount</th>
                                <th className="table-heading-text text-center">Start Date </th>
                                <th className="table-heading-text text-center">Valid Upto</th>
                                <th className="table-heading-text text-center">File</th>
                            </tr>
                        </thead>
                        <tbody>
                            {eotData.map((project, index) => (
                                <React.Fragment key={index}>
                                    <tr className='custom-table-head-td global-table-td'>
                                        <td className='table-body'>{index + 1}.</td>
                                        <td className='table-body'><div className='whitespace-normal'>{project.project_code} &
                                            {project.project_short_name}</div></td>
                                        <td className='table-body'>{project.eot_code}</td>
                                        <td className='table-body'>{project.letter_number}</td>
                                        <td className='table-body '>{project.contractor_name ? project.contractor_name : "-"}</td>
                                        <td className='table-body text-center'>{project.approved_days ? project.approved_days : "-"}</td>
                                        {status === "pending" ?
                                            <td className='table-body '>{project.process_initiate_date ? formatDate(project.process_initiate_date) : "-"}</td>
                                            :
                                            <td className='table-body '>{project.approved_date ? formatDate(project.approved_date) : "-"}</td>
                                        }
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    );
};



export { Progressreport, EOTDetails, COSDetails, MilestoneDetails, BillingDetailsProgress, BGDetails, InsuranceDetails }