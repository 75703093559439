import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AUTH, AUTHH, BASE_URL, IMAGE_URL } from '../../config/axios';
import { toast, ToastContainer } from 'react-toastify';
import { formatDate, formattedDateLong } from '../Date';
import { Modal } from 'react-bootstrap';
import Dashboardnavbar from '../../layout/Dashboardnavbar';
import { useNavigate } from 'react-router-dom';
import { formatCurrencyIndian, formatDateTime, formatRoundoff2D, handleErrorToast, ViewRequestStatus, ViewRequestRemarks, getMonthName, ViewDocumnetsbilling, inputMaxLimit } from '../CustomFunctions';
import usePermission from '../../config/permissions';
import { CheckGreen, WarnYellow, Eye, Filter, Office, CrossRed, Attachment, Employee } from '../AllSvg';
import { Projects } from '../FilterDropdown';

const ApprovedRequestOfficeBillingAccStage1 = ({ i, getofficebillingdata, documentlist }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const [formData, setFormData] = useState({});

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const validateForm = () => {
        const newErrors = {};
        const requiredFields = []

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };


    const handleFormSubmitPut = async (e) => {

        e.preventDefault();
        console.log('clicked')
        console.log(validateForm())
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            const updatedFormData = {
                account_status_a: "approved",
                account_update_datetime: new Date().toISOString(),
                account_update_by: sessionStorage.getItem('sitemanagement_emp_code'),
            };

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, updatedFormData);

                if (res.status === 200) {
                    await getofficebillingdata();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            {documentlist.length === 0 ? (<> <span className="error-message font-size-text " style={{ marginTop: '0px', marginBottom: '0px', paddingRight: '5px' }}>No Documents Attached</span></>) : (<>
                <button className="model-edit-button" title="Account Stage 1 Approval" onClick={handleShow}>
                    <CheckGreen />
                </button>
            </>)}

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">
                                Confirm Account Approval  "{i.project_short_name}"
                            </h6>

                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.project_short_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Owner Name:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.owner_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Days:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Months:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {getMonthName(i.month)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Year:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.year}
                                </div>
                            </div>



                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Basic Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.rent_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Percentage:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_percentage)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Total Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.total_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <ViewDocumnetsbilling documentlist={documentlist} />
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button font-weight500 model-button-leave font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Approve
                                </button>
                            </div>
                        </div>


                    </div>

                </Modal.Body>
            </Modal >
        </>
    );
};

const RejectRequestOfficeBillingAccStage1 = ({ i, getofficebillingdata, documentlist }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        account1_rejection_reason: "",
    });

    console.log(formData);
    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["account1_rejection_reason"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, {
                    account_status_a: "rejected",

                    account1_rejection_reason: formData.account1_rejection_reason,
                    account_update_datetime: new Date().toISOString(),
                    account_update_by: sessionStorage.getItem('sitemanagement_emp_code'),
                });

                if (res.status === 200) {
                    await getofficebillingdata();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" title="Account Stage 1 Rejection" onClick={handleShow}>
                <CrossRed />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.project_short_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Owner Name:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.owner_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Days:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Months:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {getMonthName(i.month)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Year:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.year}
                                </div>
                            </div>



                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Basic Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.rent_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Percentage:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_percentage)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Total Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.total_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                {/* <ViewDocumnetsbilling documentlist={documentlist} /> */}
                            </div>
                            <div className="flex-column">
                                <label htmlFor="admin_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Account stage1 Rejection Reason <span className="required">*</span>
                                </label>
                                <textarea
                                    id="account1_rejection_reason"
                                    type="text"
                                    name="account1_rejection_reason"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.account1_rejection_reason}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.account1_rejection_reason
                                        ? "error"
                                        : inputState.account1_rejection_reason
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.account1_rejection_reason && (
                                    <span className="error-message font-size-text ">{errors.account1_rejection_reason}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-delete    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const ApprovedRequestOfficeBillingAccStage2 = ({ i, getofficebillingdata, documentlist }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [fileName, setFileName] = useState("");
    const [formData, setFormData] = useState({
    });


    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "settlement_transaction_id",
            "settlement_bill"
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];

        if (file) {
            setFormData({ ...formData, [name]: file, });
            setFileName(file.name);
        } else {
            setFileName("");
        }
    };

    const handleClear = () => {
        setFormData(prevState => ({
            ...prevState,
            settlement_bill: null
        }));
        setFileName("");

    }

    const handleFormSubmitPut = async (e) => {

        e.preventDefault();

        // const updatedFormData = {
        //     account_status_b: "approved",
        //     final_update_datetime: new Date().toISOString(),
        //     final_update_by: sessionStorage.getItem('sitemanagement_emp_code'),
        // };


        const formDataa = new FormData();

        const symbols = '!@#$%^&*()_-+=';
        const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
        const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numbers = '0123456789';
        const now = new Date();
        const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
        const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
        const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
        let randomCode = '';
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * allChars.length);
            randomCode += allChars[randomIndex];
        }
        if (formData.settlement_bill && formData.settlement_transaction_id) {
            const originalFile = formData?.settlement_bill;
            const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile?.name}`;
            const customFile = new File([originalFile], customFileName, { type: originalFile.type });

            formDataa.append("settlement_transaction_id", formData.settlement_transaction_id);
            formDataa.append("settlement_bill", customFile);
        }
        formDataa.append("account_status_b", "approved");
        formDataa.append("final_update_by", sessionStorage.getItem('sitemanagement_emp_code'));
        formDataa.append("final_update_datetime", new Date().toISOString());

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, formDataa, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (res.status === 200) {
                    await getofficebillingdata();
                    setShow(false);
                } else {
                    alert(res);
                }

            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            {documentlist.length === 0 ? (<> <span className="error-message font-size-text " style={{ marginTop: '0px', marginBottom: '0px', paddingRight: '5px' }}>No Documents Attached</span></>) : (<>
                <button className="model-edit-button" title="Account Stage 1 Approval" onClick={handleShow}>
                    <CheckGreen />
                </button>
            </>)}
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>

                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">
                                Account Finalize for "{i.project_short_name}"
                            </h6>
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.project_short_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Owner Name:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.owner_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Days:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Months:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {getMonthName(i.month)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Year:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.year}
                                </div>
                            </div>


                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Basic Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.rent_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Percentage:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_percentage)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Total Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.total_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <ViewDocumnetsbilling documentlist={documentlist} />
                            </div>
                            <>
                                <div className="flex-column">
                                    <label
                                        htmlFor="settlement_transaction_id"
                                        className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                    >
                                        Final Approval Details (Transaction / Cheque no.)
                                    </label>
                                    <input
                                        id="settlement_transaction_id"
                                        name="settlement_transaction_id"
                                        placeholder="Final Approval Details (Transaction / Cheque no.)"
                                        onChange={handleInputChange}
                                        value={formData.settlement_transaction_id}
                                        className={`form-input ${errors.settlement_transaction_id
                                            ? "error"
                                            : inputState.settlement_transaction_id
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.settlement_transaction_id && (
                                        <span className="error-message font-size-text ">{errors.settlement_transaction_id}</span>
                                    )}
                                </div>

                                <div className="flex-row">
                                    <label htmlFor="" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                        Account Transaction Proof :
                                    </label>
                                    <div className="flex-column" >
                                        <label
                                            htmlFor="settlement_bill"
                                            className={`align-center  form-inputss custom-file-upload 
                                                    ${errors.settlement_bill
                                                    ? "error"
                                                    : inputState.settlement_bill
                                                        ? "success"
                                                        : ""
                                                }`}
                                        >
                                            <div className="svg-field form-input align-center">
                                                <Attachment />
                                            </div>
                                        </label>
                                        <input
                                            id="settlement_bill"
                                            name="settlement_bill"
                                            type="file"
                                            onChange={handleFileChange}
                                            onFocus={handleClear}
                                            className="file-inputs align-center"
                                            style={{ display: "none", position: "relative" }}
                                        />
                                        <br />
                                        <div className="flex-row justify-evenly">
                                            <div>
                                                {fileName && <p style={{ margin: "0px" }}>{fileName}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    {formData.settlement_bill ?
                                        <div>
                                            <button className="model-button" onClick={handleClear}>Clear</button>
                                        </div>
                                        : null
                                    }
                                    {errors.settlement_bill && (
                                        <span className="error-message font-size-text ">{errors.settlement_bill}</span>
                                    )}
                                </div>
                            </>

                            <div className="button-models">
                                <button className="model-button model-button-cancel font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button font-weight500 model-button-leave font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Approve
                                </button>
                            </div>
                        </div>


                    </div>

                </Modal.Body>
            </Modal >
        </>
    );
};



const ApprovedOfficeBillingAdminC1 = ({ i, getofficebillingdata, documentlist }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [formData, setFormData] = useState({});


    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const validateForm = () => {
        const newErrors = {};
        const requiredFields = []
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleFormSubmitPut = async (e) => {

        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            const updatedFormData = {
                admin_approval_status_c1: "approved",
                admin_update_datetime_c1: new Date().toISOString(),
                admin_update_by_c1: sessionStorage.getItem('sitemanagement_emp_code'),
            };
            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, updatedFormData);

                if (res.status === 200) {
                    await getofficebillingdata();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            {documentlist.length === 0 ? (<> <span className="error-message font-size-text " style={{ marginTop: '0px', marginBottom: '0px', paddingRight: '5px' }}>No Documents Attached</span></>) : (<>
                <button className="model-edit-button" title="Account Stage 1 Approval" onClick={handleShow}>
                    <CheckGreen />
                </button>
            </>)}
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Admin Approval for "{i.project_short_name}" </h6>
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.project_short_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Owner Name:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.owner_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Days:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Months:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {getMonthName(i.month)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Year:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.year}
                                </div>
                            </div>


                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Basic Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.rent_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Percentage:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_percentage)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Total Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.total_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <ViewDocumnetsbilling documentlist={documentlist} />
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >
        </>
    );
};

const RejectOfficeBillingAdminC1 = ({ i, getofficebillingdata, documentlist }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        admin_rejection_reason_c1: "",
    });

    console.log(formData);
    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["admin_rejection_reason_c1"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, {
                    admin_approval_status_c1: "rejected",
                    admin_rejection_reason_c1: formData.admin_rejection_reason_c1,
                    admin_update_datetime_c1: new Date().toISOString(),
                    admin_update_by_c1: sessionStorage.getItem('sitemanagement_emp_code'),
                });

                if (res.status === 200) {
                    await getofficebillingdata();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" title="Admin 1 Rejection" onClick={handleShow}>
                <CrossRed />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.project_short_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Owner Name:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.owner_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Days:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Months:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {getMonthName(i.month)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Year:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.year}
                                </div>
                            </div>



                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Basic Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.rent_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Percentage:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_percentage)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Total Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.total_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                {/* <ViewDocumnetsbilling documentlist={documentlist} /> */}
                            </div>
                            <div className="flex-column">
                                <label htmlFor="admin_rejection_reason_c1" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Admin Rejection Reason  <span className="required">*</span>
                                </label>
                                <textarea
                                    id="admin_rejection_reason_c1"
                                    type="text"
                                    name="admin_rejection_reason_c1"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.admin_rejection_reason_c1}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.admin_rejection_reason_c1
                                        ? "error"
                                        : inputState.admin_rejection_reason_c1
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.admin_rejection_reason_c1 && (
                                    <span className="error-message font-size-text ">{errors.admin_rejection_reason_c1}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-delete    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};


const ApprovedOfficeBillingAdminC2 = ({ i, getofficebillingdata, documentlist }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [formData, setFormData] = useState({});


    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const validateForm = () => {
        const newErrors = {};
        const requiredFields = []
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleFormSubmitPut = async (e) => {

        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            const updatedFormData = {
                admin_approval_status_c2: "approved",
                admin_update_datetime_c2: new Date().toISOString(),
                admin_update_by_c2: sessionStorage.getItem('sitemanagement_emp_code'),
            };
            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, updatedFormData);

                if (res.status === 200) {
                    await getofficebillingdata();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            {documentlist.length === 0 ? (<> <span className="error-message font-size-text " style={{ marginTop: '0px', marginBottom: '0px', paddingRight: '5px' }}>No Documents Attached</span></>) : (<>
                <button className="model-edit-button" title="Account Stage 1 Approval" onClick={handleShow}>
                    <CheckGreen />
                </button>
            </>)}
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Admin Approval for "{i.project_short_name}" </h6>
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.project_short_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Owner Name:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.owner_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Days:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Months:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {getMonthName(i.month)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Year:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.year}
                                </div>
                            </div>


                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Basic Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.rent_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Percentage:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_percentage)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Total Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.total_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <ViewDocumnetsbilling documentlist={documentlist} />
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >
        </>
    );
};

const RejectOfficeBillingAdminC2 = ({ i, getofficebillingdata, documentlist }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        admin_rejection_reason_c2: "",
    });

    console.log(formData);
    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["admin_rejection_reason_c2"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, {
                    admin_approval_status_c2: "rejected",
                    admin_rejection_reason_c2: formData.admin_rejection_reason_c2,
                    admin_update_datetime_c2: new Date().toISOString(),
                    admin_update_by_c2: sessionStorage.getItem('sitemanagement_emp_code'),
                });

                if (res.status === 200) {
                    await getofficebillingdata();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" title="Admin 2 Rejection" onClick={handleShow}>
                <CrossRed />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.project_short_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Owner Name:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.owner_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Days:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Months:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {getMonthName(i.month)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Year:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.year}
                                </div>
                            </div>



                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Basic Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.rent_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Percentage:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_percentage)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Total Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.total_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                {/* <ViewDocumnetsbilling documentlist={documentlist} /> */}
                            </div>
                            <div className="flex-column">
                                <label htmlFor="admin_rejection_reason_c2" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Admin Rejection Reason  <span className="required">*</span>
                                </label>
                                <textarea
                                    id="admin_rejection_reason_c2"
                                    type="text"
                                    name="admin_rejection_reason_c2"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.admin_rejection_reason_c2}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.admin_rejection_reason_c2
                                        ? "error"
                                        : inputState.admin_rejection_reason_c2
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.admin_rejection_reason_c2 && (
                                    <span className="error-message font-size-text ">{errors.admin_rejection_reason_c2}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-delete    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};


const ApprovedOfficeBillingAdminC3 = ({ i, getofficebillingdata }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [formData, setFormData] = useState({});


    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const validateForm = () => {
        const newErrors = {};
        const requiredFields = []
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleFormSubmitPut = async (e) => {

        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            const updatedFormData = {
                admin_approval_status_c3: "approved",
                admin_update_datetime_c3: new Date().toISOString(),
                admin_update_by_c3: sessionStorage.getItem('sitemanagement_emp_code'),
            };
            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, updatedFormData);

                if (res.status === 200) {
                    await getofficebillingdata();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-edit-button" title="Admin 3 Approval" onClick={handleShow}>
                <CheckGreen />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Admin Approval for "{i.project_short_name}" </h6>
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.project_short_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Owner Name:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.owner_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Days:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Months:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {getMonthName(i.month)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Year:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.year}
                                </div>
                            </div>


                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Basic Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.rent_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Percentage:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_percentage)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Total Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.total_amt)}
                                </div>
                            </div>


                            <div className="button-models">
                                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >
        </>
    );
};

const RejectOfficeBillingAdminC3 = ({ i, getofficebillingdata, documentlist }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        admin_rejection_reason_c3: "",
    });

    console.log(formData);
    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["admin_rejection_reason_c3"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, {
                    admin_approval_status_c3: "rejected",
                    admin_rejection_reason_c3: formData.admin_rejection_reason_c3,
                    admin_update_datetime_c3: new Date().toISOString(),
                    admin_update_by_c3: sessionStorage.getItem('sitemanagement_emp_code'),
                });

                if (res.status === 200) {
                    await getofficebillingdata();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" title="Admin 3 Rejection" onClick={handleShow}>
                <CrossRed />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-column">
                                <label htmlFor="admin_rejection_reason_c3" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Admin Rejection Reason  <span className="required">*</span>
                                </label>
                                <textarea
                                    id="admin_rejection_reason_c3"
                                    type="text"
                                    name="admin_rejection_reason_c3"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.admin_rejection_reason_c3}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.admin_rejection_reason_c3
                                        ? "error"
                                        : inputState.admin_rejection_reason_c3
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.admin_rejection_reason_c3 && (
                                    <span className="error-message font-size-text ">{errors.admin_rejection_reason_c3}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-delete    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const ApprovedRequestOfficeBillingAuthority = ({ i, getofficebillingdata, documentlist }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [formData, setFormData] = useState({
        rent_amt: '',
        tds_percentage: '',
        tds_amt: '',
        total_amt: '',
    });

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const validateForm = () => {
        const newErrors = {};
        const requiredFields = []
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleFormSubmitPut = async (e) => {

        e.preventDefault();

        const updatedFormData = {
            authority_approval_status: 'approved',
            authority_engineer: sessionStorage.getItem('sitemanagement_emp_code'),
            authority_update_datetime: new Date().toISOString(),
        };
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic


            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, updatedFormData);

                if (res.status === 200) {
                    await getofficebillingdata();
                    setShow(false);
                } else {
                    alert(res);
                }


            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            {documentlist.length === 0 ? (<> <span className="error-message font-size-text " style={{ marginTop: '0px', marginBottom: '0px', paddingRight: '5px' }}>No Documents Attached</span></>) : (<>
                <button className="model-edit-button" title="Account Stage 1 Approval" onClick={handleShow}>
                    <CheckGreen />
                </button>
            </>)}
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Authority Approval </h6>
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.project_short_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Owner Name:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.owner_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Days:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Months:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {getMonthName(i.month)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Year:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.year}
                                </div>
                            </div>



                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Basic Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.rent_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Percentage:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_percentage)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Total Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.total_amt)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <ViewDocumnetsbilling documentlist={documentlist} />
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >
        </>
    );
};

const RejectRequestOfficeBillingAuthority = ({ i, getofficebillingdata, documentlist }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        authority_rejection_reason: "",
    });

    console.log(formData);
    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["authority_rejection_reason"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, {
                    authority_approval_status: "rejected",
                    authority_engineer: sessionStorage.getItem('emp_code'),
                    authority_rejection_reason: formData.authority_rejection_reason,
                    authority_update_datetime: new Date().toISOString(),
                });

                if (res.status === 200) {
                    await getofficebillingdata();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" title="Authority Imprest Reject" onClick={handleShow}>
                <CrossRed />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.project_short_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Owner Name:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.owner_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Days:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Months:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {getMonthName(i.month)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Year:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.year}
                                </div>
                            </div>



                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Basic Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.rent_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Percentage:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_percentage)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Total Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.total_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                {/* <ViewDocumnetsbilling documentlist={documentlist} /> */}
                            </div>
                            <div className="flex-column">
                                <label htmlFor="authority_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Authority Rejection Reason <span className="required">*</span>
                                </label>
                                <textarea
                                    id="authority_rejection_reason"
                                    type="text"
                                    name="authority_rejection_reason"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.authority_rejection_reason}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.authority_rejection_reason
                                        ? "error"
                                        : inputState.authority_rejection_reason
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.authority_rejection_reason && (
                                    <span className="error-message font-size-text ">{errors.authority_rejection_reason}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-delete    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const ApprovedRequestOfficeBillingChecker = ({ i, getofficebillingdata, documentlist }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [maxlimit, setMaxlimit] = useState('')
    const [maxlimittds, setMaxlimittds] = useState('')
    const handleShow = () => {
        setMaxlimittds(parseFloat(i.tds_percentage).toFixed(2))
        setMaxlimit(parseFloat(i.rent_amt).toFixed(2));
        setFormData(i)
        setShow(true)
    };

    const [formData, setFormData] = useState({
        rent_amt: '',
        tds_percentage: '',
        tds_amt: '',
        total_amt: '',
    });

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ['rent_amt', 'total_amt']
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let updatedData = { ...formData, [name]: value };

        if (name === "rent_amt") {
            // const maxLimit = parseFloat(formData.rent_amt).toFixed(2);
            console.log(maxlimit)
            const rentAmount = parseFloat(value).toFixed(2);
            const { success, error } = inputMaxLimit(name, rentAmount, maxlimit);
            if (!success) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: error,
                }));
                return updatedData;
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "",
                }));
                if (rentAmount >= 20000) {
                    const tdsPercentage = 10;
                    const tdsAmount = parseFloat(((rentAmount * tdsPercentage) / 100).toFixed(2));
                    updatedData = {
                        ...updatedData,
                        tds_percentage: tdsPercentage,
                        tds_amt: tdsAmount,
                        total_amt: parseFloat((parseFloat(rentAmount) - tdsAmount).toFixed(2)),
                    };
                } else {
                    updatedData = {
                        ...updatedData,
                        tds_percentage: "",
                        tds_amt: 0,
                        total_amt: parseFloat(rentAmount),
                    };
                }
            }

        }

        if (name === "tds_percentage" && Number(formData.rent_amt) >= 20000) {
            const tdsPercentage = parseFloat(value).toFixed(2);
            const { success, error } = inputMaxLimit(name, tdsPercentage, maxlimittds);
            if (!success) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: error,
                }));
                return updatedData;
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "",
                }));
                const tdsAmount = parseFloat(((Number(formData.rent_amt) * tdsPercentage) / 100).toFixed(2));
                updatedData = {
                    ...updatedData,
                    tds_amt: tdsAmount,
                    total_amt: parseFloat((Number(formData.rent_amt) - tdsAmount).toFixed(2)),
                };
            }

        }

        setFormData(updatedData);

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        if (errors[name]) {
            setErrors((prevErrors) => {
                const { [name]: removedError, ...restErrors } = prevErrors;
                return restErrors;
            });
        }
    };

    const handleFormSubmitPut = async (e) => {

        e.preventDefault();

        const updatedFormData = {
            ...formData,
            checker_approval_status: 'approved',
            checker: sessionStorage.getItem('sitemanagement_emp_code'),
            checker_update_datetime: new Date().toISOString(),
        };


        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, updatedFormData);

                if (res.status === 200) {
                    await getofficebillingdata();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            {documentlist.length === 0 ? (<> <span className="error-message font-size-text " style={{ marginTop: '0px', marginBottom: '0px', paddingRight: '5px' }}>No Documents Attached</span></>) : (<>
                <button className="model-edit-button" title="Account Stage 1 Approval" onClick={handleShow}>
                    <CheckGreen />
                </button>
            </>)}
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">

                        <div className="bdy-div">
                            <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Checker Approval </h6>
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.project_short_name}
                                </div>
                            </div>
                            {/* owner_name */}

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Owner Name:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.owner_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Days:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Months:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {getMonthName(i.month)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Year:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.year}
                                </div>
                            </div>


                            <div title="Rent Amount" className="flex-column">
                                <label htmlFor="rent_amt" className="form-labels  font-weight500    font-size-subheading">
                                    Basic Amount<span className="required">*</span>
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    max={i.rent_amt}
                                    maxLength={10}
                                    id="rent_amt"
                                    name="rent_amt"
                                    placeholder="Basic Amount."
                                    onChange={handleInputChange}
                                    value={formData.rent_amt}
                                    className={`form-input ${errors.rent_amt
                                        ? "error"
                                        : inputState.rent_amt
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.rent_amt && (
                                    <span className="error-message font-size-text ">{errors.rent_amt}</span>
                                )}
                            </div>
                            {Number(formData.rent_amt) < 20000
                                ? null :
                                <>
                                    <div title="TDS Percentage" className="flex-column">
                                        <label htmlFor="tds_percentage" className="form-labels  font-weight500    font-size-subheading">
                                            TDS Percentage<span className="required">*</span>
                                        </label>
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={10}
                                            id="tds_percentage"
                                            name="tds_percentage"
                                            placeholder="TDS Percentage"
                                            onChange={handleInputChange}
                                            value={formData.tds_percentage}
                                            className={`form-input ${errors.tds_percentage
                                                ? "error"
                                                : inputState.tds_percentage
                                                    ? "success"
                                                    : ""
                                                }`}
                                        />
                                        {errors.tds_percentage && (
                                            <span className="error-message font-size-text ">{errors.tds_percentage}</span>
                                        )}
                                    </div>
                                    <div title="TDS Amount" className="flex-column">
                                        <label htmlFor="tds_amt" className="form-labels  font-weight500    font-size-subheading">
                                            TDS Amount<span className="required">(Read Only)</span>
                                        </label>
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={10}
                                            id="tds_amt"
                                            name="tds_amt"
                                            placeholder="TDS Amount."
                                            // onChange={handleInputChange}
                                            value={formData.tds_amt}
                                            disabled
                                            readOnly
                                            style={{ cursor: 'not-allowed' }}
                                            className={`form-input ${errors.tds_amt
                                                ? "error"
                                                : inputState.tds_amt
                                                    ? "success"
                                                    : ""
                                                }`}
                                        />
                                        {errors.tds_amt && (
                                            <span className="error-message font-size-text ">{errors.tds_amt}</span>
                                        )}
                                    </div>
                                </>
                            }
                            <div title="Total Amount" className="flex-column">
                                <label htmlFor="total_amt" className="form-labels  font-weight500    font-size-subheading">
                                    Total Amount<span className="required">(Auto Calculated)</span>
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    maxLength={10}
                                    id="total_amt"
                                    name="total_amt"
                                    placeholder="Total Amount"
                                    // onChange={handleInputChange}
                                    value={formData.total_amt}
                                    disabled
                                    readOnly
                                    style={{ cursor: 'not-allowed' }}
                                    className={`form-input ${errors.total_amt
                                        ? "error"
                                        : inputState.total_amt
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.total_amt && (
                                    <span className="error-message font-size-text ">{errors.total_amt}</span>
                                )}
                            </div>
                            <div className="flex-row">
                                <ViewDocumnetsbilling documentlist={documentlist} />
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >
        </>
    );
};
const RejectRequestOfficeBillingChecker = ({ i, getofficebillingdata, documentlist }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formData, setFormData] = useState({
        checker_rejection_reason: "",
    });

    console.log(formData);
    //******************Valedation Start********************* */

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["checker_rejection_reason"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                let res = await axios.put(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`, {
                    checker_approval_status: "rejected",
                    checker_rejection_reason: formData.checker_rejection_reason,
                    checker_update_datetime: new Date().toISOString(),
                    checker: sessionStorage.getItem('sitemanagement_emp_code'),
                });

                if (res.status === 200) {
                    await getofficebillingdata();
                    // setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId)
            } finally {
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <button className="model-delete-button" title="Checker Rejection" onClick={handleShow}>
                <CrossRed />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-row">
                                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                                    Date :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formattedDateLong(i.date)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Project :
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.project_short_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Owner Name:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.owner_name}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Days:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.days}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Months:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {getMonthName(i.month)}
                                </div>
                            </div>

                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Year:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {i.year}
                                </div>
                            </div>



                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Basic Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.rent_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Percentage:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_percentage)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    TDS Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.tds_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                <label
                                    htmlFor="project_name"
                                    className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                                >
                                    Total Amount:
                                </label>
                                <div className="form-labels announce-date font-size-heading padding-lefttt">
                                    {formatCurrencyIndian(i.total_amt)}
                                </div>
                            </div>
                            <div className="flex-row">
                                {/* <ViewDocumnetsbilling documentlist={documentlist} /> */}
                            </div>
                            <div className="flex-column">
                                <label htmlFor="checker_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                                    Checker Rejection Reason <span className="required">*</span>
                                </label>
                                <textarea
                                    id="checker_rejection_reason"
                                    type="text"
                                    name="checker_rejection_reason"
                                    placeholder="Your Reason"
                                    onChange={handleInputChange}
                                    maxLength={100}
                                    value={formData.checker_rejection_reason}
                                    className={`form-input-textarea   font-weight400  font-size-subheading ${errors.checker_rejection_reason
                                        ? "error"
                                        : inputState.checker_rejection_reason
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.checker_rejection_reason && (
                                    <span className="error-message font-size-text ">{errors.checker_rejection_reason}</span>
                                )}
                            </div>

                            <div className="button-models">
                                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="model-button   font-weight500    model-button-delete    font-size-heading"
                                    onClick={handleFormSubmitPut}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export {
    ApprovedRequestOfficeBillingAccStage1,
    RejectRequestOfficeBillingAccStage1,
    ApprovedRequestOfficeBillingAccStage2,
    ApprovedOfficeBillingAdminC1,
    ApprovedOfficeBillingAdminC2,
    ApprovedOfficeBillingAdminC3,
    RejectOfficeBillingAdminC1,
    RejectOfficeBillingAdminC2,
    RejectOfficeBillingAdminC3,
    ApprovedRequestOfficeBillingAuthority,
    RejectRequestOfficeBillingAuthority,
    ApprovedRequestOfficeBillingChecker,
    RejectRequestOfficeBillingChecker,

}