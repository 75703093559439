import ReactToPrint from 'react-to-print';
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { formatDateDD_MM_YYYY, formattedDateLong } from "../Date";
import { BASE_URL } from "../../config/axios";
import { AddProjectBill, AddEOTDetails, AddCOSDetails, AddMilstoneDetails, AddTrackRecordDetails, UpdateTrackRecordDetails, AddProgressdetails, DeleteBillingDetails, UpdateBill, UpdateProjectBill, DeleteBg, DeleteInsurance, AddInsurance, AddBankGuarantee, UpdateContractor, DeleteContractor, UpdateBankGuarantee, UpdateBank, UpdateInsurance, UpdateEOTDetails, DeleteEOT, DeleteTrackAndLetterDetails, DeleteCOS, UpdateTrackRecordDetailwithIDonly, UpdateConsultantDetails, AssociatedLetters, DocumentModel, DeleteDocuments, UpdateProgressdetails, UpdateCOSDetails, UpdateMilestone, DeleteMileStone } from "./ProjectDetailsCrudButtons";
import { AddSiteEmployee, UpdateSiteEmployee, DeleteSiteEmployee, UploadSiteEmployee } from "../Wfm/WorkforceCrudButton";
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, YAxis, LineChart } from 'recharts';
import usePermission from "../../config/permissions";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { formatCurrencyIndian, sortProjects, handleErrorToast, formatRoundoffCrores, formatRoundoff } from "../CustomFunctions";
import { CircularGrid, DeleteDustbin, DocumentIcon, SearchBig, SearchSmall } from '../AllSvg';
import { LetterStatus, LetterType } from '../FilterDropdown';
import { UploadBGBulk, UploadBillingBulk, UploadcosBulk, UploadEotBulk, UploadInsuranceBulk, UploadLetterBulk, UploadMilestoneBulk, UploadSiteProgressBulk } from './BulkModals';
import { Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { AddLetterLinked } from '../../pages/sitedashboard/Main/LetterTracking';
import Item from 'antd/es/list/Item';


const ConsultancyDetails = ({ projectId, siteSubCompanyList, projectData, getProjects, buffer, site_prcode }) => {
  // console.log(projectData, projectId, siteSubCompanyList, 'projetcs')
  const role = sessionStorage.getItem('sitemanagement_role');

  return (
    <>

      <div className="info-box-div-bg">
        <div>
          <div className="jd-heading-outer-flex ">
            <h2 className="jd-heading-main font-weight600  font-size-heading">Consultant Details</h2>
            <div className="field-cont">
              <UpdateConsultantDetails i={projectData} getProjects={getProjects} siteSubCompanyList={siteSubCompanyList} />

            </div>

          </div>
          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>
          </div>
        </div>

        <div className="info-box-div">
          <div className="personal-detail">

            {buffer ? <div className="spinner-small"></div> :
              <table className="width-40vw">
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Tender Id</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.tender_id ? projectData?.tender_id : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Lead</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.lead ? projectData?.lead : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">JV</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.jv ? projectData?.jv : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Our Share (%)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.our_share ? projectData?.our_share : "-"}</th>
                </tr >


                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Our Role</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.our_role ? projectData?.our_role : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Association</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.association ? projectData?.association : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Work Type</td>
                  <th className="personal-detail-2 font-weight500 font-size-text form-text-trasformation-alluppercase">{projectData?.work_type ? projectData?.work_type : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contract Mode</td>
                  <th className="personal-detail-2 font-weight500 font-size-text form-text-trasformation-alluppercase ">{projectData?.contract_mode ? projectData?.contract_mode : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Authority Engineer</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.authority_engineer ? projectData?.authority_engineer : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Coordinator</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.com_operators ? projectData?.com_operators : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Project Length (in Km)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_length ? projectData?.project_length : "-"}</th>
                </tr >
                <tr>

                  <td className="personal-detail-1 font-weight500 font-size-text ">Project Duration (in Months)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_duration ? projectData?.project_duration : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Consultancy Fees</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">Rs. {formatRoundoffCrores(projectData?.consultancy_fees)}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Client</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.client_name ? projectData?.client_name : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Regional Office Name (RO Name)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.ro ? projectData?.ro : "-"}</th>
                </tr >
              </table >
            }
          </div>
          <div className="personal-detail">
            {buffer ? <div className="spinner-small"></div> :
              <table className="width-40vw">
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Bid Due Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.bid_due_date ? formattedDateLong(projectData?.bid_due_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Consultant LOA Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.loa_award_date ? formattedDateLong(projectData?.loa_award_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Agreement Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.agreement_date ? formattedDateLong(projectData?.agreement_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Commencement Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.commencement_date ? formattedDateLong(projectData?.commencement_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Schedule Construction Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.schedule_construction_completion_date ? formattedDateLong(projectData?.schedule_construction_completion_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Actual Construction Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.actual_construction_completion_date ? formattedDateLong(projectData?.actual_construction_completion_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Schedule Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.schedule_completion_date ? formattedDateLong(projectData?.schedule_completion_date) : "-"}</th>
                </tr >
                {/* <tr>
                    <td className="personal-detail-1 font-weight500 font-size-text ">Project Completion Date</td>
                    <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.project_completion_date ? formattedDateLong(projectData?.project_completion_date) : "-"}</th>
                  </tr > */}
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Actual Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.actual_completion_date ? formattedDateLong(projectData?.actual_completion_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Employee Count</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.employees?.length}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Extension of Time Count</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.eots?.filter(eot => eot.workperformedby === "consultant").length}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Remark</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.remark ? projectData?.remark : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Location (State)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.location ? projectData?.location : "-"}</th>
                </tr >
                {/* <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Site Sub Company</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.sub_company ? projectData?.sub_company : "-"}</th>
                </tr > */}
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Site Address</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{projectData?.site_office_address ? projectData?.site_office_address : "-"}</th>
                </tr >
              </table >
            }
          </div >

        </div >

        <div>
          <div>
            <div className="jd-heading-outer-flex ">
              <h2 className="jd-heading-main font-weight600  font-size-heading">Insurance</h2>
              <div className="field-cont">
                <AddInsurance project={projectId} getProjects={getProjects} />
                {role === "Admin" && <UploadInsuranceBulk getInsurance={getProjects} projectData={projectData} site_prcode={site_prcode} />}
              </div>
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
            <br />
          </div>

          <>
            <div className="form-flex-wrap scroll-container-table child-table-scroll">
              <table className="table-css ">
                <thead className='table-heading'>
                  <tr className="custom-table-head-tr">
                    <th className="table-heading-text text-lefttt ">S.No.</th>
                    <th className="table-heading-text text-center">Policy Number</th>
                    <th className="table-heading-text text-center">Insurance Name</th>
                    <th className="table-heading-text text-center">Amount</th>
                    <th className="table-heading-text text-center">Start Date </th>
                    <th className="table-heading-text text-center">Valid Upto</th>
                    <th className="table-heading-text text-center">File</th>
                    <th className="table-heading-text text-end">Action</th>
                  </tr>
                </thead>
                {buffer ? (
                  <div className="spinner"></div>
                ) : (
                  <tbody>
                    {projectData?.insurance
                      .map((billData, monthIndex) => (
                        <React.Fragment key={monthIndex}>
                          <tr className="custom-table-head-td">
                            <td className="table-body text-lefttt">{`${monthIndex + 1}.`}</td>
                            <td className="table-body text-center">{billData.insurance_policy_num}</td>
                            <td className="table-body text-center">{billData.insurance_name}</td>
                            <td className="table-body text-center">{billData.insurance_amount}</td>
                            <td className="table-body text-center">{formattedDateLong(billData.insurance_policy_start_date)}</td>
                            <td className="table-body text-center">{formattedDateLong(billData.insurance_policy_valid_upto_date)}</td>
                            <td className="table-body text-center">
                              {!billData.insurance_file_upload ? <>File Not Attacted</> : <a href={billData.insurance_file_upload} target="blank" className="">
                                < DocumentIcon />
                              </a>}
                            </td>
                            {role === "SiteManagement" ?
                              <td className="table-body text-end">
                                <UpdateInsurance i={billData} getProjects={getProjects} />
                              </td> :
                              <td className="table-body text-end">
                                <UpdateInsurance i={billData} getProjects={getProjects} />
                                <DeleteInsurance i={billData} getInsuranceData={getProjects} />
                              </td>
                            }
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                )}
              </table>
            </div>

          </>
        </div>

        <div>
          <div>
            <div className="jd-heading-outer-flex ">
              <h2 className="jd-heading-main font-weight600  font-size-heading">Bank / Performance Guarantee</h2>
              <div className="field-cont">
                <AddBankGuarantee project={projectId} getbg={getProjects} />
                {role === "Admin" && <UploadBGBulk getBankGuarantee={getProjects} projectData={projectData} site_prcode={site_prcode} />}

              </div>
            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>
            </div>
            <br />
          </div>

          <>
            <div className='form-flex-wrap scroll-container-table child-table-scroll'>
              <table className="table-css ">
                <thead className='table-heading'>
                  <tr className="custom-table-head-tr">
                    <th className="table-heading-text text-lefttt">S.No.</th>
                    <th className="table-heading-text text-center">Bank Name</th>
                    <th className="table-heading-text text-center">Bank Number</th>
                    <th className="table-heading-text text-center">Amount</th>
                    <th className="table-heading-text text-center">Start Date</th>
                    <th className="table-heading-text text-center">Expiry Date</th>
                    <th className="table-heading-text text-center">Remarks</th>
                    <th className="table-heading-text text-center">Document </th>
                    <th className="table-heading-text text-end">Action</th>
                  </tr>
                </thead>
                {buffer ? (
                  <div className="spinner"></div>
                ) : (
                  <tbody>
                    {projectData?.bgdetails
                      .map((billData, monthIndex) => (
                        <React.Fragment key={monthIndex}>
                          <tr className="custom-table-head-td">
                            <td className="table-body text-lefttt">{`${monthIndex + 1}.`}</td>
                            <td className="table-body text-center">{billData.bank_name}</td>
                            <td className="table-body text-center">{billData.bg_num}</td>
                            <td className="table-body text-center">{billData.bg_amount}</td>

                            <td className="table-body text-center">{formattedDateLong(billData.bg_date)}</td>
                            <td className="table-body text-center">{formattedDateLong(billData.bg_expiry_date)}</td>

                            <td className="table-body text-center">{billData.bg_remark}</td>
                            <td className="table-body text-center">
                              {!billData.bg_document ? <>File Not Attached</> : <a href={billData.bg_document} target="blank" className="">
                                < DocumentIcon />
                              </a>}
                            </td>

                            {role === "SiteManagement" ?
                              <td className="table-body text-end">
                                <UpdateBankGuarantee i={billData} getbg={getProjects} />

                              </td> :
                              <td className="table-body text-end">
                                <UpdateBankGuarantee i={billData} getbg={getProjects} />
                                <DeleteBg i={billData} getbg={getProjects} />
                              </td>
                            }


                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                )}
              </table>
            </div>

          </>
        </div>


      </div>
    </>
  )
}

const ContractorDetails = ({ contractor, getProjects, getContractorDetails }) => {

  const role = sessionStorage.getItem('sitemanagement_role');

  const [buffer, setBuffering] = useState(false);
  const [consultancyDetailsDetails, setConsultancyDetailsData] = useState([]);


  useEffect(() => {

    getConsultancyDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor]);

  const getConsultancyDetails = async () => {
    setBuffering(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/siteproject/contractor/${contractor}/`
      );
      setConsultancyDetailsData(response.data);
    } catch (err) {
    } finally {
      setBuffering(false);
    }
  };



  const formatRoundoff = (amount) => {
    if (amount === null || amount === undefined) return "-";

    // Function to format the number with Indian number system
    const formatIndianStyle = (num) => {
      let numStr = num.toString();
      let lastThreeDigits = numStr.slice(-3);
      let otherDigits = numStr.slice(0, -3);

      if (otherDigits) {
        lastThreeDigits = ',' + lastThreeDigits;
      }

      // Split other digits into groups of two and add commas
      const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',');

      return formattedOtherDigits + lastThreeDigits;
    };



    return formatIndianStyle(amount)
  };



  return (
    <>

      <div className="info-box-div-bg">

        <div className="heading-bg-color-white">
          <div className="jd-heading-outer-flex ">
            <h2 className="jd-heading-main font-weight600  font-size-heading">Contractors</h2>
            <div className="field-cont">
              {role === "SiteManagement" ?

                <UpdateContractor contractor={contractor} getContractorDetails={getConsultancyDetails} projectId={consultancyDetailsDetails?.project} i={consultancyDetailsDetails} />

                :
                <>
                  <UpdateContractor contractor={contractor} getContractorDetails={getConsultancyDetails} projectId={consultancyDetailsDetails?.project} i={consultancyDetailsDetails} />
                  <DeleteContractor contractor={contractor} getProjectContractorDetails={getContractorDetails} getproject={getProjects} />
                </>
              }
            </div>
          </div>
          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>
          </div>
        </div>


        <div className="info-box-div">
          <div className="personal-detail">

            {buffer ? <div className="spinner-small"></div> :
              <table className="width-40vw">
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Name</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.name ? consultancyDetailsDetails?.name : "-"}</th>
                </tr >

                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor cost</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">Rs. {consultancyDetailsDetails?.cost ? formatRoundoff(consultancyDetailsDetails.cost) : "-"}</th>
                </tr >


                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Project Length (in Km)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.project_length ? consultancyDetailsDetails?.project_length : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Project Duration (in Months)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.project_duration ? consultancyDetailsDetails?.project_duration : "-"}</th>
                </tr >

                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Project Duration With EOT (in Months)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.project_duration_with_eot ? consultancyDetailsDetails?.project_duration_with_eot : "-"}</th>
                </tr >

                {/* <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Consultancy Fees with COS (in Crs.)</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">Rs. {formatRoundoffCrores(consultancyDetailsDetails?.cost_with_cos)}</th>
                </tr > */}


                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Extension of Time Count</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.eots ? consultancyDetailsDetails?.eots?.filter(eot => eot.workperformedby === "consultant").length : "-"}</th>
                </tr >

                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">COS of Count</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.employees ? consultancyDetailsDetails?.employees?.length : "-"}</th>
                </tr >

                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor address</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.contractor_address ? consultancyDetailsDetails?.contractor_address : "-"}</th>
                </tr >



              </table >
            }
          </div >
          <div className="personal-detail">
            {buffer ? <div className="spinner-small"></div> :
              <table className="width-40vw">
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Bid Due Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.contractor_bid_due_date ? formattedDateLong(consultancyDetailsDetails?.contractor_bid_due_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor LOA Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.loa_award_date ? formattedDateLong(consultancyDetailsDetails?.loa_award_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Agreement Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.agreement_date ? formattedDateLong(consultancyDetailsDetails?.agreement_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Commencement Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.commencement_date ? formattedDateLong(consultancyDetailsDetails?.commencement_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Schedule Construction Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.schedule_construction_completion_date ? formattedDateLong(consultancyDetailsDetails?.schedule_construction_completion_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Actual Construction Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.actual_construction_completion_date ? formattedDateLong(consultancyDetailsDetails?.actual_construction_completion_date) : "-"}</th>
                </tr >
                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Schedule Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.schedule_completion_date ? formattedDateLong(consultancyDetailsDetails?.schedule_completion_date) : "-"}</th>
                </tr >

                <tr>
                  <td className="personal-detail-1 font-weight500 font-size-text ">Contractor Actual Completion Date</td>
                  <th className="personal-detail-2 font-weight500 font-size-text ">{consultancyDetailsDetails?.actual_completion_date ? formattedDateLong(consultancyDetailsDetails?.actual_completion_date) : "-"}</th>
                </tr >



              </table >
            }
          </div >




        </div >
      </div>

    </>
  )
}

const BillTable = ({ projectId, contractor, contractorlist, site_prcode, contractor_name }) => {


  const role = sessionStorage.getItem('sitemanagement_role');

  const [buffer, setBuffering] = useState(false); //buffering logic
  const [bills, setBills] = useState([]);
  const getProjectBillDetails = async () => {
    setBuffering(true); //buffering logic // Start Buffering 
    try {
      const bill = await axios.get(
        `${BASE_URL}/siteproject/billingbycontractor/${contractor}/`
      );
      setBills(bill.data);



    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString("default", { month: "long" });
  };

  useEffect(() => {
    getProjectBillDetails();

  }, [contractor]);

  return (

    <div className="info-box-div-bg attendance-history-cont" style={{ margin: "0px" }}>

      <div className="jd-heading-outer-flex ">
        <h2 className="jd-heading-main font-weight600  font-size-heading">Contractor Bills</h2>
        {
          contractor === undefined ? <></>
            :
            <>
              <div className="field-cont">
                <AddProjectBill project={projectId} getProjects={getProjectBillDetails} contractor={contractor} contractorlist={contractorlist} />
                {role === "Admin" && <UploadBillingBulk projectId={projectId} getBilling={getProjectBillDetails} contractor={contractor} site_prcode={site_prcode} contractor_name={contractor_name} workperformedby={"contractor"} />}
              </div>
            </>
        }

      </div>
      <div>
        <div className="jd-heading-bottom-bold"></div>
        <div className="jd-heading-bottom-light"></div>
      </div>

      <div className='table-box height-40vh paddingTop-0px scroll-container-table child-table-scroll '>


        <table className="table-css">
          <thead className='table-heading'>
            <tr className="custom-table-head-tr">
              <th className="table-heading-text text-lefttt">S.No.</th>
              <th className="table-heading-text">Period</th>
              <th className="table-heading-text">Bill Details</th>
              <th className="table-heading-text ">Submited Details</th>
              <th className="table-heading-text">Site Recommended Details</th>
              <th className="table-heading-text">Site Deduction Details</th>

              <th className="table-heading-text">HO Recommended Details</th>
              <th className="table-heading-text">HO Deduction Details</th>
              {/* <th className="table-heading-text">Bill Date</th> */}
              <th className="table-heading-text">Letters</th>
              <th className="table-heading-text">Action</th>
            </tr>
          </thead>
          {buffer ? (
            <div className="spinner"></div>
          ) : (
            <tbody>
              {bills
                .map((billData, monthIndex) => (
                  <tr className="custom-table-head-td" key={monthIndex}>

                    <td className="table-body text-lefttt">{`${monthIndex + 1} .`}</td>
                    <td className="table-body text-center">{formatDateDD_MM_YYYY(billData.bill_period_start)}<br />To  <br />{formatDateDD_MM_YYYY(billData.bill_period_end)}</td>

                    <td className="table-body">
                      <tr>
                        <td className="padding-bottom-5px">Number</td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">{billData.bill_num}</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">Type</td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">{billData.bill_type}</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">Catergory</td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">{billData.bill_catagory}</td>
                      </tr>
                    </td>

                    <td className="table-body ">
                      <tr>
                        <td className="padding-bottom-5px">Date</td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">{formatDateDD_MM_YYYY(billData.submitted_bill_date)}</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Amount
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">₹ {formatCurrencyIndian(billData.submitted_bill_amount)}</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Percentage
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">{formatCurrencyIndian(billData.submitted_percentage)}%</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Percentage Amount
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">₹ {formatCurrencyIndian(billData.percentage_amt)}</td>
                      </tr>

                    </td>
                    <td className="table-body ">
                      <tr>
                        <td className="padding-bottom-5px">
                          Date
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        {billData.site_recomended_bill_date === null ?
                          <td className="text-end">-</td>
                          :
                          <td className="text-end">{formatDateDD_MM_YYYY(billData.site_recomended_bill_date)}</td>
                        }
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Amount
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">₹ {formatCurrencyIndian(billData.site_recommended_bill_amount)}</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Percentage
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">{billData.site_recommended_percentage}%</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Percentage Amount
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">₹ {formatCurrencyIndian(billData.site_percentage_amt)}</td>
                      </tr>
                      <div className="flex-column">
                        {billData.site_attachment && <span className="align-leftt "> SO Attachment
                          <a href={billData.site_attachment} target="blank" className="document-download-button  width-5vw">
                            View
                          </a>
                        </span>}
                      </div>
                    </td>

                    <td className="table-body width-15vw">
                      <tr>
                        <td className="padding-bottom-5px">
                          LD Amount
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">₹{formatCurrencyIndian(billData.site_ld_amount ? billData.site_ld_amount : "-")}</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Withheld Amount
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">₹{formatCurrencyIndian(billData.site_withheld_amt ? billData.site_withheld_amt : "-")}</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Released Withheld Amount
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">₹{formatCurrencyIndian(billData.site_released_withheld_amt ? billData.site_released_withheld_amt : "-")}</td>
                      </tr>
                    </td>

                    <td className="table-body ">
                      <td className="table-body ">
                        <tr>
                          <td className="padding-bottom-5px">
                            Date
                          </td>
                          <td style={{ padding: "0px 4px" }}>:</td>

                          <td className="text-end">{formatDateDD_MM_YYYY(billData.recomended_bill_date)}</td>

                        </tr>
                        <tr>
                          <td className="padding-bottom-5px">
                            Amount
                          </td>
                          <td style={{ padding: "0px 4px" }}>:</td>
                          <td className="text-end">₹ {formatCurrencyIndian(billData.recommended_bill_amount)}</td>
                        </tr>
                        <tr>
                          <td className="padding-bottom-5px">
                            Percentage
                          </td>
                          <td style={{ padding: "0px 4px" }}>:</td>
                          <td className="text-end">{billData.recommended_percentage}%</td>
                        </tr>
                        <tr>
                          <td className="padding-bottom-5px">
                            Percentage Amount
                          </td>
                          <td style={{ padding: "0px 4px" }}>:</td>
                          <td className="text-end">₹ {formatCurrencyIndian(billData.ho_percentage_amt)}</td>
                        </tr>
                        <div className="flex-column">
                          {billData.ho_attachment && <span className="align-leftt "> HO Attachment
                            <a href={billData.ho_attachment} target="blank" className="document-download-button  width-5vw">
                              View
                            </a>
                          </span>}
                        </div>
                      </td>
                    </td>




                    <td className="table-body width-15vw">
                      <tr>
                        <td className="padding-bottom-5px">
                          LD Amount
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">₹{formatCurrencyIndian(billData.ld_amount ? billData.ld_amount : "-")}</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Withheld Amount
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">₹{formatCurrencyIndian(billData.withheld_amt ? billData.withheld_amt : "-")}</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Released Withheld Amount
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">₹{formatCurrencyIndian(billData.released_withheld_amt ? billData.released_withheld_amt : "-")}</td>
                      </tr>
                    </td>

                    <td className="table-body width-15vw">

                      <tr>
                        <td className="padding-bottom-5px">
                          Submitted Letter
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end" >
                          <AssociatedLetters id={billData.submitted_letter_num} />
                          {/* <UpdateTrackRecordDetailwithIDonly
                            i={billData.submitted_letter_num}
                            projectId={projectId} /> */}
                        </td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Recommended Letter
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">
                          {/* <UpdateTrackRecordDetailwithIDonly
                            i={billData.recomended_letter_num}
                            projectId={projectId} /> */}
                          <AssociatedLetters id={billData.recomended_letter_num} />
                        </td>
                      </tr>

                    </td>

                    <td className="table-body width-5vw">
                      <div className="flex-row justify-center">
                        <UpdateBill i={billData} project={projectId} getProjects={getProjectBillDetails} />
                        {role === "Account" ? "" :
                          <DeleteBillingDetails i={billData} getbillingDetail={getProjectBillDetails} />
                        }
                      </div>
                    </td>




                  </tr>
                ))}
            </tbody>
          )}
        </table>


      </div>

    </div>
  );
}


const BillingDetailsTable = ({ projectId }) => {
  const [buffer, setBuffering] = useState(false);
  const [billingDetails, setBillingData] = useState([]);
  const ref = useRef();

  const getProjectBillDetails = async () => {
    setBuffering(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/siteproject/billingdetailfilter/${projectId}/`
      );
      setBillingData(response.data);
    } catch (err) {
    } finally {
      setBuffering(false);
    }
  };

  useEffect(() => {
    getProjectBillDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedDateLong = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const renderBillsAndBalanceBills = (contractorData) => {
    const { bill, balancebill } = contractorData;

    // Handle cases when only one of bill or balancebill exists
    const maxLength = Math.max(bill.length, balancebill.length);
    const rows = [];

    for (let i = 0; i < maxLength; i++) {
      const billDetails = bill[i] || {};
      const balanceBillDetails = balancebill[i] || {};

      rows.push(
        <tr key={i}>
          <td>Bill No.</td>
          <td>{`${billDetails.bill_type || ""}-${billDetails.bill_num || ""} (${billDetails.submitted_percentage || ""}%), Letter No. ${billDetails.submitted_letter_num || "-"}`}</td>
          <td>{`${billDetails.bill_type || ""}-${billDetails.bill_num || ""} (${billDetails.recommended_percentage || ""}%), Letter No. ${billDetails.recomended_letter_num || "-"}`}</td>
          <td>{`${balanceBillDetails.bill_type || ""}-${balanceBillDetails.bill_num || ""} (${balanceBillDetails.submitted_percentage || ""}%), Letter No. ${balanceBillDetails.submitted_letter_num || "-"}`}</td>
          <td>{`${balanceBillDetails.bill_type || ""}-${balanceBillDetails.bill_num || ""} (${balanceBillDetails.recommended_percentage || ""}%), Letter No. ${balanceBillDetails.recomended_letter_num || "-"}`}</td>
        </tr>,
        <tr key={`date-${i}`}>
          <td>Bill Date</td>
          <td>{formattedDateLong(billDetails.submitted_bill_date) || "-"}</td>
          <td>{formattedDateLong(billDetails.recomended_bill_date) || "-"}</td>
          <td>{formattedDateLong(balanceBillDetails.submitted_bill_date) || "-"}</td>
          <td>{formattedDateLong(balanceBillDetails.recomended_bill_date) || "-"}</td>
        </tr>,
        <tr key={`period-${i}`}>
          <td>Bill Period</td>
          <td>
            {billDetails.bill_period_start && billDetails.bill_period_end
              ? `${formattedDateLong(billDetails.bill_period_start)} - ${formattedDateLong(billDetails.bill_period_end)}`
              : "-"}
          </td>
          <td>
            {billDetails.bill_period_start && billDetails.bill_period_end
              ? `${formattedDateLong(billDetails.bill_period_start)} - ${formattedDateLong(billDetails.bill_period_end)}`
              : "-"}
          </td>
          <td>
            {balanceBillDetails.bill_period_start && balanceBillDetails.bill_period_end
              ? `${formattedDateLong(balanceBillDetails.bill_period_start)} - ${formattedDateLong(balanceBillDetails.bill_period_end)}`
              : "-"}
          </td>
          <td>
            {balanceBillDetails.bill_period_start && balanceBillDetails.bill_period_end
              ? `${formattedDateLong(balanceBillDetails.bill_period_start)} - ${formattedDateLong(balanceBillDetails.bill_period_end)}`
              : "-"}
          </td>
        </tr>,
        <tr key={`amount-${i}`}>
          <td>Bill Amt.</td>
          <td>{formatRoundoffCrores(billDetails.submitted_bill_amount || "-")}</td>
          <td>{formatRoundoffCrores(billDetails.recommended_bill_amount || "-")}</td>
          <td>{formatRoundoffCrores(balanceBillDetails.submitted_bill_amount || "-")}</td>
          <td>{formatRoundoffCrores(balanceBillDetails.recommended_bill_amount || "-")}</td>
        </tr>,

        <tr key={`amount-${i}`}>
          <td>Withheld Amt.</td>
          <td>{formatCurrencyIndian(billDetails.withheld_amt || "-")}</td>
          <td>{"-"}</td>
          <td>{formatCurrencyIndian(balanceBillDetails.withheld_amt || "-")}</td>
          <td>{"-"}</td>
        </tr>,
        <tr key={`amount-${i}`}>
          <td>Released withheld Amt</td>
          <td>{"-"}</td>
          <td>{formatCurrencyIndian(billDetails.released_withheld_amt || "-")}</td>
          <td>{"-"}</td>
          <td>{formatCurrencyIndian(balanceBillDetails.released_withheld_amt || "-")}</td>
        </tr>,
        <tr key={`amount-${i}`}>
          <td>LD Amt</td>
          <td>{"-"}</td>
          <td>{formatCurrencyIndian(billDetails.ld_amount || "-")}</td>
          <td>{"-"}</td>
          <td>{formatCurrencyIndian(balanceBillDetails.ld_amount || "-")}</td>
        </tr>
        ,
        <tr key={`amount-${i}`}>
          <td>Deduction Amt</td>
          <td>{formatCurrencyIndian(billDetails.deduction_amount || "-")}</td>
          <td>{"-"}</td>
          <td>{formatCurrencyIndian(balanceBillDetails.deduction_amount || "-")}</td>
          <td>{"-"}</td>
        </tr>

      );
    }
    return rows;
  };

  return (
    <div ref={ref} className="info-box-div-bg">
      <div className="heading-bg-color-white">
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600 font-size-heading">Contractor Billing Data</h2>
          <div className="field-cont">
            <ReactToPrint
              bodyClass="print-agreement"
              content={() => ref.current}
              trigger={() => (
                <button className="model-button model-button-black hide-on-print">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
                    <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
                  </svg> Print
                </button>
              )}
            />
          </div>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div  >
        <table className="nested-table-lightblue">
          <thead >
            <tr className="custom-table-head-tr ">
              <th className="table-heading-text">Month</th>
              <th className="table-heading-text">Contractor</th>
              <th className="table-heading-text text-center">Details</th>
            </tr>
          </thead>
          {buffer ? (
            <div className="spinner"></div>
          ) : (
            <tbody>
              {billingDetails.map((billingData, yearIndex) => {
                let totalRowsForMonth = 0;

                billingData.contractors.forEach(contractor => {
                  totalRowsForMonth += Math.max(contractor.bill.length, contractor.balancebill.length);
                });

                return (
                  <React.Fragment key={yearIndex}>
                    {billingData.contractors.map((contractorData, contractorIndex) => (
                      <tr className="custom-table-head-td" key={contractorIndex}>
                        {contractorIndex === 0 && (
                          <td className="align-left" rowSpan={totalRowsForMonth}>
                            {formattedDateLong(billingData.bill_period_start)}-{formattedDateLong(billingData.bill_period_end)}
                          </td>
                        )}
                        <td className="align-left">{contractorData.contractor}</td>
                        <td className="text-center">
                          {contractorData.bill.length || contractorData.balancebill.length ? (
                            <table className="nested-table font-size-subheading">
                              <thead>
                                <tr>
                                  <th rowSpan="2" className="text-center">Project Details</th>
                                  <th colSpan="2" className="text-center">Bill</th>
                                  <th colSpan="2" className="text-center">Balance Bill</th>
                                </tr>
                                <tr>
                                  <th>Submitted</th>
                                  <th>Recommended (%)</th>
                                  <th>Bal Submitted</th>
                                  <th>Bal Recommended (%)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {renderBillsAndBalanceBills(contractorData)}
                              </tbody>
                            </table>
                          ) : (
                            <p>No Billing Data Available.</p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};



const EOTTable = ({ projectId, toggleState, contractor, contractorlist, site_prcode, contractor_name }) => {
  const role = sessionStorage.getItem('sitemanagement_role');
  const [eotList, setEotList] = useState([]);
  const [eotStatus, seteotStatus] = useState("null")

  const getEot = async () => {

    try {
      // let eot = await axios.get(`${BASE_URL}/siteproject/eot/`);
      let eot = await axios.get(`${BASE_URL}/siteproject/eotfilter/${projectId}/${toggleState}/${contractor}/${eotStatus}/`);
      setEotList(eot.data);


    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {

    }
  };
  // Handle status change from the dropdown
  const handleStatusChange = (event) => {
    seteotStatus(event.target.value);
  };





  useEffect(() => {
    getEot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor, projectId, toggleState, eotStatus]);



  return (

    <div className="info-box-div-bg ">
      <div >
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Extention of Time</h2>

          {
            contractor === undefined ?
              <></>
              :
              <>
                <div className="field-cont">
                  <select className='status-dropdown' value={eotStatus} onChange={handleStatusChange} style={{ borderColor: "#9ec2e1" }}>
                    <option value='null'>All</option>
                    <option value='pending'>Pending</option>
                    <option value='Approved'>Approved</option>
                    <option value='rejected'>Rejected</option>
                  </select>
                  <AddEOTDetails project={projectId} getEot={getEot} toggleState={toggleState} contractor={contractor} contractorlist={contractorlist} />
                  {role === "Admin" && <UploadEotBulk projectId={projectId} site_prcode={site_prcode} workperformedby={toggleState} contractor_name={contractor_name} getEotdata={getEot} />}
                </div>
              </>

          }

        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div className="form-flex-wrap table-box paddingTop-0px  scroll-container-table child-table-scroll">

        <table className="table-css">
          <thead className='table-heading'>
            <tr className="custom-table-head-tr">
              <th className="table-heading-text text-lefttt">S. No.</th>
              <th className="table-heading-text">EOT Code</th>
              <th className="table-heading-text">Initiate Date</th>

              <th className="table-heading-text">Close Date</th>
              <th className="table-heading-text text-center">Approved Duration</th>
              <th className="table-heading-text">Letters</th>
              <th className="table-heading-text">Status</th>
              <th className="table-heading-text width-5vw">Action</th>
            </tr>
          </thead>
          <tbody>
            {eotList?.map((i, index) => (
              <React.Fragment key={index}>

                <tr className="custom-table-head-td">
                  <td className="table-body text-lefttt">{`${index + 1} .`}</td>
                  <td className="table-body">{i.eot_code}</td>
                  <td className="table-body form-text-trasformation-uppercase">
                    {i.process_initiate_date ? formattedDateLong(i.process_initiate_date) : "-"}
                  </td>
                  <td className="table-body text-center form-text-trasformation-uppercase">
                    {i.approved_date ? formattedDateLong(i.approved_date) : "-"}
                  </td>
                  <td className="table-body text-center">{i.approved_days ? i.approved_days : "-"}</td>
                  {i.letter || i.close_letter ?
                    <td className="table-body ">

                      <tr>
                        <td className="padding-bottom-5px">
                          Initiate
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end" >
                          {i.letter ? <AssociatedLetters id={i.letter} /> : <div className="text-center">No Letter Attached</div>}
                        </td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Close
                        </td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-end">
                          {i.close_letter ? <AssociatedLetters id={i.close_letter} /> : <div className="text-center">No Letter Attached</div>}
                        </td>
                      </tr>

                    </td> :
                    <td className="table-body ">No Letters Attached</td>
                  }

                  <td className="table-body" style={{ textTransform: "capitalize" }}>{i.eot_status}</td>



                  <td className="table-body width-5vw flex-row " >
                    <UpdateEOTDetails i={i} getEot={getEot} project={projectId} contractor={contractor} toggleState={toggleState} />
                    {role === "SiteManagement" ? "" :
                      <DeleteEOT i={i} getEOT={getEot} />
                    }

                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>


    </div>
  )


}



const COSTable = ({ projectId, toggleState, contractor, contractorlist, site_prcode, contractor_name }) => {
  const [cosList, setCosList] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [toggleShow, setToggleShow] = useState("null");
  const role = sessionStorage.getItem('sitemanagement_role');
  const [cosStatus, setCosStatus] = useState('null'); // Default to 'pending'


  const getCOS = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      let cos = await axios.get(`${BASE_URL}/siteproject/cosfilter/${projectId}/${toggleState}/${contractor}/${cosStatus}/`);
      setCosList(cos.data);


    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getCOS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor, cosStatus]);



  const handleStatusChange = (event) => {
    setCosStatus(event.target.value);
  };


  return (

    <div className="info-box-div-bg">
      <div >
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Change of Scope</h2>
          <>

            {
              contractor === undefined ?
                <></>
                :
                <>
                  <div className="field-cont">
                    <select className='status-dropdown' value={cosStatus} onChange={handleStatusChange} style={{ borderColor: "#9ec2e1" }}>
                      <option value='null'>All</option>
                      <option value='pending'>Pending</option>
                      <option value='Approved'>Approved</option>
                      <option value='rejected'>Rejected</option>
                    </select>
                    <AddCOSDetails project={projectId} contractor={contractor} getProjects={getCOS} toggleState={toggleState} />
                    {role === "Admin" && <UploadcosBulk projectId={projectId} getCOSData={getCOS} site_prcode={site_prcode} workperformedby={toggleState} contractor_name={contractor_name} />}

                  </div>
                </>
            }

          </>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div className="form-flex-wrap table-box paddingTop-0px scroll-container-table child-table-scroll">
        <table className="table-css">
          <thead className='table-heading'>
            <tr className="custom-table-head-tr">
              <th className="table-heading-text text-lefttt">S. No.</th>
              <th className="table-heading-text">COS Code</th>
              <th className="table-heading-text">Dates</th>
              <th className="table-heading-text">Amount</th>
              <th className="table-heading-text">Letters</th>
              <th className="table-heading-text" >Status</th>

              <th className="table-heading-text ">Action</th>

            </tr>
          </thead>
          <tbody>
            {cosList?.map((i, index) => (
              <React.Fragment key={index}>

                <tr className="custom-table-head-td">
                  <td className="table-body text-lefttt">{`${index + 1}`}</td>
                  <td className="table-body">{i.cos_code}</td>
                  <td className="table-body text-center">
                    <tr>
                      <td className="padding-bottom-5px table-body">Initiate</td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="text-start">
                        {i.process_initiate_date ? formattedDateLong(i.process_initiate_date) : <div className="text-center">-</div>}
                      </td>
                    </tr>
                    <tr>
                      <td className="padding-bottom-5px table-body">Approval</td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="text-start">
                        {i.approval_date ? formattedDateLong(i.approval_date) : <div className="text-center">-</div>}
                      </td>
                    </tr>


                  </td>
                  <td className="table-body text-center">
                    <tr>
                      <td className="padding-bottom-5px table-body">Initiate</td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="text-start">
                        {i.amount ? i.amount : <div className="text-center">-</div>}
                      </td>
                    </tr>
                    <tr>
                      <td className="padding-bottom-5px table-body">Approved</td>
                      <td style={{ padding: "0px 4px" }}>:</td>
                      <td className="text-start">
                        {i.approved_amount ? i.approved_amount : <div className="text-center">-</div>}
                      </td>
                    </tr>


                  </td>
                  <td className="table-body">
                    {i.letter || i.close_letter ?
                      <td className="table-body ">

                        <tr>
                          <td className="padding-bottom-5px">
                            Initiate
                          </td>
                          <td style={{ padding: "0px 4px" }}>:</td>
                          <td className="text-end" >
                            {i.letter ? <AssociatedLetters id={i.letter} /> : <div className="text-center">No Letter Attached</div>}
                          </td>
                        </tr>
                        <tr>
                          <td className="padding-bottom-5px">
                            Close
                          </td>
                          <td style={{ padding: "0px 4px" }}>:</td>
                          <td className="text-end">
                            {i.close_letter ? <AssociatedLetters id={i.close_letter} /> : <div className="text-center">No Letter Attached</div>}
                          </td>
                        </tr>

                      </td> :
                      <td className="table-body ">No Letters Attached</td>
                    }
                  </td>
                  <td className="table-body" style={{ textTransform: "capitalize" }}>{i.cos_status}</td>

                  <td className="table-body" style={{ display: 'flex', gap: '5px' }}>

                    <UpdateCOSDetails
                      toggleState={toggleState}
                      project={projectId}
                      getCos={getCOS}
                      contractor={contractor}
                      i={i}
                    />
                    {role === "SiteManagement" ? "" :
                      <DeleteCOS i={i} getCOS={getCOS} />
                    }

                  </td>

                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

    </div >
  )


}





const EmployeeTable = ({ projectId, companyId, site_prcode }) => {

  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [toggleShow, setToggleShow] = useState("null");
  const role = sessionStorage.getItem('sitemanagement_role');


  const [siteEmployeeList, setSiteEmployeeList] = useState([]);
  const [siteDepartmentList, setSiteDepartmentList] = useState([]);
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
  const [siteProjectList, setSiteProjectList] = useState([]);

  const getSiteEmployee = async () => {
    setBuffering(true); // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/employeefilterbyproject/${projectId}/null/`);
      const dep = await axios.get(`${BASE_URL}/siteproject/designation/`);
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            // for general(admin)
            : `${BASE_URL}/siteproject/project/`
      );

      setSiteEmployeeList(res.data);
      setSiteDepartmentList(dep.data);
      setSiteSubCompanyList(sub.data);
      const sortedProjects = sortProjects(pro.data, 'site_prcode');
      setSiteProjectList(sortedProjects);
    } catch (err) {
      handleErrorToast(err);

    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getSiteEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <div className="info-box-div-bg">
      <div>
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Employee</h2>
          <div className="field-cont" style={{ gap: '2px' }}>
            {/* <div className="table-searchh dropdown-container field-cont-div">
              <select
                onChange={(e) => setToggleShow(e.target.value)}
                value={toggleShow}
                className="dropdown font-size-subheading  ">
                <option value="null">All</option>
                <option value="Contractor">Contractor</option>
                <option value="Consultant">Consultant</option>
              </select>
            </div> */}
            {role === "Account" ? "" :
              <>
                <AddSiteEmployee
                  projectId={projectId}
                  getSiteEmployee={getSiteEmployee}
                  siteProjectList={siteProjectList}
                  siteDepartmentList={siteDepartmentList}
                  siteSubCompanyList={siteSubCompanyList}
                  companyId={companyId}


                />
                {role === "Admin" &&
                  <UploadSiteEmployee site_prcode={site_prcode} />
                }
              </>

            }
          </div>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div className="form-flex-wrap table-box  paddingTop-0px scroll-container-table child-table-scroll">


        <table className="table-css">
          <thead className='table-heading'>
            <tr className="custom-table-head-tr">
              <th className="table-heading-text">ID</th>
              <th className="table-heading-text text-center">Employee</th>
              <th className="table-heading-text text-center">Email</th>
              <th className="table-heading-text text-center">Mobile</th>
              <th className="table-heading-text text-center">Designation</th>
              <th className="table-heading-text text-center">Proffesional</th>
              <th className="table-heading-text text-center">Address</th>
              <th className="table-heading-text text-center">Join Date</th>
              <th className="table-heading-text text-center">Sub Company</th>
              {role === "Account" ? "" :
                <th className="table-heading-text text-center text-end width-5vw flex-row justify-center">Action</th>
              }
            </tr>
          </thead>
          <tbody>
            {siteEmployeeList?.map((i, index) => (
              <React.Fragment key={index}>

                <tr className="custom-table-head-td">
                  <td className="table-body">{index + 1}</td>
                  <td className="table-body text-center">{i.emp_code}-{i.name}</td>
                  <td className="table-body text-center">{i.email}</td>
                  <td className="table-body text-center">{i.mobile}</td>
                  <td className="table-body text-center">{i.designation_name}</td>
                  <td className="table-body text-center">{i.professional_type}</td>
                  <td className="table-body text-center">{i.current_address}</td>
                  <td className="table-body text-center">{formattedDateLong(i.joining_date)}</td>
                  <td className="table-body text-center">{i.sub_company_name}</td>

                  {role === "Account" ? "" :
                    <td className="text-end flex-row justify-center ">
                      <UpdateSiteEmployee
                        i={i}
                        getSiteEmployee={getSiteEmployee}
                        siteProjectList={siteProjectList}
                        siteDepartmentList={siteDepartmentList}
                        siteSubCompanyList={siteSubCompanyList}
                        projectId={projectId}
                      />
                      {role === "BD" ? "" :
                        <DeleteSiteEmployee
                          i={i}
                          getSiteEmployee={getSiteEmployee}
                        />
                      }
                    </td>
                  }
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>


      </div>

    </div>
  )

}

const MilestoneTable = ({ projectId, contractor, site_prcode, contractor_name, toggleState }) => {

  const [projectData, setProjectsData] = useState({});
  const [milestoneList, setMilestoneList] = useState([]);
  const [buffer, setBuffering] = useState(false); //buffering logic
  const [loading, setLoading] = useState(false); //loading logic
  const [toggleShow, setToggleShow] = useState("null");
  const role = sessionStorage.getItem('sitemanagement_role');
  const [contractordata, setContractorData] = useState({});

  const getProjects = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      let mil = await axios.get(`${BASE_URL}/siteproject/milestonebycontractor/${contractor}/`);
      let proj = await axios.get(`${BASE_URL}/siteproject/project/${projectId}/`);
      const response = await axios.get(`${BASE_URL}/siteproject/contractor/${contractor}/`);
      setContractorData(response.data)
      setProjectsData(proj.data);

      setMilestoneList(mil.data);
    } catch (err) {
      console.log("err")
      console.log(err)
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };



  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleShow, projectId, contractor]);



  const calculateDays = (startDate, endDate) => {
    if (!startDate || !endDate) return "-";

    const start = new Date(startDate);
    const end = new Date(endDate);


    const timeDifference = end - start;


    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };

  return (

    <div className="info-box-div-bg">
      <div >
        <div className="jd-heading-outer-flex ">
          <h2 className="jd-heading-main font-weight600  font-size-heading"> Contractor Milestone </h2>
          <>
            {
              contractor === undefined ?
                <></>
                :
                <>
                  <div className="field-cont">
                    <AddMilstoneDetails project={projectId} getProjects={getProjects} contractor={contractor} appointed_date={contractordata.commencement_date} />
                    {role === "Admin" && <UploadMilestoneBulk projectId={projectId} getMileStoneData={getProjects} site_prcode={site_prcode} workperformedby={toggleState} contractor_name={contractor_name} />}
                  </div>
                </>
            }

          </>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
        <br />
      </div>

      <div className="form-flex-wrap table-box height-40vh paddingTop-0px scroll-container-table child-table-scroll">


        <table className="table-css ">
          <thead className='table-heading'>
            <tr className="custom-table-head-tr">
              <th className="table-heading-text text-lefttt">S. No.</th>
              <th className="table-heading-text ">Name</th>
              <th className="table-heading-text ">Dates</th>
              <th className="table-heading-text ">Days</th>
              <th className="table-heading-text">View Letters</th>
              <th className="table-heading-text text-center">Action</th>

            </tr>
          </thead>
          <tbody>
            {milestoneList?.sort(
              (a, b) =>
                new Date(a.milestone_date) - new Date(b.milestone_date)
            )
              .map((i, index) => (
                <React.Fragment key={index}>

                  <tr className="custom-table-head-td">
                    <td className="table-body text-lefttt">{index + 1}.</td>
                    <td className="table-body ">{i.name}</td>
                    <td className="table-body text-center">
                      <tr>
                        <td className="padding-bottom-5px table-body">Appointed</td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-start">
                          {contractordata.commencement_date ? formattedDateLong(contractordata.commencement_date) : <div className="text-center">-</div>}
                        </td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px table-body">Milestone</td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-start">
                          {i.milestone_date ? formattedDateLong(i.milestone_date) : <div className="text-center">-</div>}
                        </td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px table-body">Actual</td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-start">
                          {i.milestone_as_per_actual ? formattedDateLong(i.milestone_as_per_actual) : <div className="text-center">-</div>}
                        </td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px table-body">Reschedule</td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-start">
                          {i.milestone_as_per_reschedule ? formattedDateLong(i.milestone_as_per_reschedule) : <div className="text-center">-</div>}
                        </td>
                      </tr>

                    </td>
                    <td className="table-body">
                      <tr>
                        <td className="padding-bottom-5px table-body">Milestone</td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-start">
                          {i.day ? i.day : <div className="text-center">-</div>}
                        </td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px table-body">Actual</td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-start">
                          {i.actual_gap_days ? i.actual_gap_days : <div className="text-center">-</div>}
                        </td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px table-body">Reschedule</td>
                        <td style={{ padding: "0px 4px" }}>:</td>
                        <td className="text-start">
                          {i.rescedule_gap_days ? i.rescedule_gap_days : <div className="text-center">-</div>}
                        </td>
                      </tr>

                    </td>
                    {i.actual_letter || i.reschedule_letter ?
                      <td className="table-body ">

                        <tr>
                          <td className="padding-bottom-5px">
                            Actual
                          </td>
                          <td style={{ padding: "0px 4px" }}>:</td>
                          <td className="text-end" >
                            {i.actual_letter ? <AssociatedLetters id={i.actual_letter} /> : <div className="text-center">No Letter Attached</div>}
                          </td>
                        </tr>
                        <tr>
                          <td className="padding-bottom-5px">
                            Reschedule
                          </td>
                          <td style={{ padding: "0px 4px" }}>:</td>
                          <td className="text-end">
                            {i.reschedule_letter ? <AssociatedLetters id={i.reschedule_letter} /> : <div className="text-center">No Letter Attached</div>}
                          </td>
                        </tr>

                      </td> :
                      <td className="table-body ">No Letters Attached</td>
                    }

                    <td className="table-body text-center " style={{ display: 'flex', gap: '2px', justifyContent: 'center' }}>
                      <UpdateMilestone
                        toggleState={toggleState}
                        project={projectId}
                        getProjects={getProjects}
                        contractor={contractor}
                        i={i}
                        appointed_date={contractordata.commencement_date}
                      />
                      <DeleteMileStone i={i} getMilestone={getProjects} />

                    </td>

                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </table>

      </div>

    </div>
  )


}



const Progress = ({ projectId, contractor, contractor_name, site_prcode }) => {
  const [progress, setProgress] = useState([]);
  const [buffer, setBuffering] = useState(false); // Buffering logic
  const [loading, setLoading] = useState(false); // Loading logic
  const role = sessionStorage.getItem('sitemanagement_role');

  const [physicalProgress, setPhysicalProgress] = useState([]);


  const [progressdata, setProgressData] = useState([]);


  const getProgress = async () => {
    setBuffering(true); // Start buffering

    try {
      const response = await axios.get(`${BASE_URL}/siteproject/progressbyproject/${projectId}/${contractor}/`);

      setProgressData(response.data)

      const filteredData = response.data.map(item => ({
        physical_progress: item.physical_progress,
        estimated_physical_progress: item.estimated_physical_progress,
        month: item.month
      }));

      setPhysicalProgress(filteredData);


      const sortedData = response.data.sort((a, b) => {
        if (a.year === b.year) {
          return b.month - a.month;
        }
        return b.year - a.year;
      });


      setProgress(sortedData);
    } catch (err) {
      console.log("err");
      console.log(err);
      handleErrorToast(err);
    } finally {
      setBuffering(false); // End buffering
    }
  };
  console.log(projectId)
  useEffect(() => {
    getProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, contractor]);


  const handleProgressDelete = async (e, i, getProgress) => {
    e.preventDefault();


    setLoading(true); // loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); // toast logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/progressupdate/${i.id}/`);

      if (res.status === 200) {
        await getProgress();
      } else {
        alert(res);
      }
    } catch (err) {
      // toast logic
      handleErrorToast(err);
    } finally {
      setLoading(false); // loading logic
      toast.dismiss(loadingToastId);
    }
  }

  const getMonthName = (monthNumber) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[monthNumber - 1];
  };


  return (
    <>
      <div className="info-box-div-bg">
        <div>
          <div className="jd-heading-outer-flex">
            <h2 className="jd-heading-main font-weight600 font-size-heading">Site Progress</h2>
            <div className='flex-row'>

              <div className="field-cont">
                <AddProgressdetails project={projectId} contractor={contractor} getprogressDetails={getProgress} progressdata={progressdata} />
                {role === "Admin" && <UploadSiteProgressBulk getProgress={getProgress} contractor_name={contractor_name} site_prcode={site_prcode} />}
              </div>
            </div>
          </div>
          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>
          </div>
        </div>

        <div className="form-flex-wrap table-box paddingTop-0px scroll-container-table child-table-scroll">
          <table className="table-css">
            <thead className='table-heading'>
              <tr className="custom-table-head-tr">
                <th className="table-heading-text text-lefttt">S. No.</th>
                <th className="table-heading-text">Month</th>
                <th className="table-heading-text">Year</th>
                <th className="table-heading-text text-center">Submitted Progress (%)</th>
                <th className="table-heading-text text-center">Approved Progress (%)</th>
                <th className="table-heading-text text-center ">Action</th>
              </tr>
            </thead>
            <tbody>
              {progress.map((i, index) => (
                <React.Fragment key={index}>

                  <tr className="custom-table-head-td">
                    <td className="table-body text-lefttt">{index + 1}.</td>
                    <td className="table-body">{getMonthName(i.month)}</td>
                    <td className="table-body">{i.year}</td>
                    <td className="table-body width-15vw">
                      <tr>
                        <td className="padding-bottom-5px">
                          Physical Progress
                        </td>
                        <td style={{ padding: "0px 10px" }}>:</td>
                        <td className="text-end">{i.submitted_physical_progress} %</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Financial Progress
                        </td>
                        <td style={{ padding: "0px 10px" }}>:</td>
                        <td className="text-end">{i.submitted_financial_progress} %</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Progress Letter
                        </td>
                        <td style={{ padding: "0px 10px" }}>:</td>
                        <td className="text-end"><AssociatedLetters id={i.submitted_letter} /></td>
                      </tr>
                    </td>
                    <td className="table-body width-15vw">
                      <tr>
                        <td className="padding-bottom-5px">
                          Physical Progress
                        </td>
                        <td style={{ padding: "0px 10px" }}>:</td>
                        <td className="text-end">{i.physical_progress} %</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Financial Progress
                        </td>
                        <td style={{ padding: "0px 10px" }}>:</td>
                        <td className="text-end">{i.financial_progress} %</td>
                      </tr>
                      <tr>
                        <td className="padding-bottom-5px">
                          Progress Letter
                        </td>
                        <td style={{ padding: "0px 10px" }}>:</td>
                        <td className=""><AssociatedLetters id={i.approved_letter} /></td>
                      </tr>
                    </td>
                    <td className="table-body  text-center">
                      <UpdateProgressdetails i={i} project={projectId} getprogressDetails={getProgress} progressdata={progress} contractor={contractor} />
                      <button className="model-delete-button"
                        onClick={(e) => handleProgressDelete(e, i, getProgress)}
                      >
                        <DeleteDustbin />
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

      </div>
    </>
  );
};




const ProjectProgressBar = ({ data }) => {
  const renderCustomLabel = ({ x, y, width, height, value, index, dataKey }) => {
    const midX = x + width / 2;
    const midY = y + height / 2;
    const label = dataKey === 'Physical' ? 'Physical' : 'Financial';

    return (
      <text
        x={midX}
        y={midY}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
        transform={`rotate(-90, ${midX}, ${midY})`}
        className="font-size-subheading"
      >
        {label}
      </text>
    );
  };


  return (
    <div style={{ padding: 0 }} className="width-5vw">
      <ResponsiveContainer height={200} width="100%">
        <BarChart
          margin={{
            top: 15,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          barGap={15}
          barCategoryGap={30}
          data={data}>
          <CartesianGrid horizontal="true" vertical="" strokeDasharray="10 6" />
          <XAxis dataKey="month" />
          <Tooltip />
          <Bar label={{ position: 'top' }} legendType="line" dataKey="Physical" fill="#2576BC" barSize={20} radius={7} >
            <LabelList content={(props) => renderCustomLabel({ ...props, dataKey: 'Physical' })} />
          </Bar>
          <Bar label={{ position: 'top' }} legendType="line" dataKey="Financial" fill="#00dec7" barSize={20} radius={7} >
            <LabelList content={(props) => renderCustomLabel({ ...props, dataKey: 'Financial' })} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}






const LetterTracking = ({ site_prcode, contractor_name, projectId, letterBy, contractor, contractorlist }) => {
  const tableRef = useRef(null);
  const [toggleCompany, setToggleCompany] = useState("");
  const role = sessionStorage.getItem('sitemanagement_role');


  const [workperformedby, setWorkperformedby] = useState("null");

  const [lettertype, setLetterType] = useState("null");







  const [filteredData, setFilteredData] = useState([]);

  const [error, setError] = useState(null);

  const [selectedNo, setNo] = useState("");
  const [selectedFromDate, setFromDate] = useState('null');
  const [selectedToDate, setToDate] = useState('null');
  const [selectedLetterStatus, setLetterStatus] = useState('null');


  const [trackRecordList, setTrackRecordList] = useState([]);


  const [buffer, setBuffering] = useState(true); //buffering logic

  const getProjects = async () => {
    setBuffering(true); // Start Buffering
    try {


      const res = await axios.get(`${BASE_URL}/siteproject/letterrecords/filter/${selectedLetterStatus}/${selectedFromDate}/${selectedToDate}/null/${projectId}/${letterBy ? letterBy : workperformedby}/${lettertype}/null/null/${contractor}/null/`);
      setTrackRecordList(res.data);


    } catch (err) {
      // handleErrorToast(err); // You can handle any general error here if needed
    } finally {
      setBuffering(false); // End Buffering
    }
  };


  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleCompany, selectedLetterStatus, selectedFromDate, selectedToDate, workperformedby, lettertype, contractor, letterBy]);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNo, trackRecordList]);

  const applyFilters = () => {
    let filteredData = trackRecordList;
    if (selectedNo !== "") {
      const lowercaseSelectedName = selectedNo.toLowerCase();
      filteredData = filteredData.filter(employee => {
        const lid = employee.letter_num.toString().toLowerCase().includes(lowercaseSelectedName) || employee.subject.toLowerCase().includes(lowercaseSelectedName);
        return lid;
      });
    }



    filteredData = filteredData.sort((a, b) => {
      const dateA = new Date(a.letter_date);
      const dateB = new Date(b.letter_date);
      return dateB - dateA;
    });


    setFilteredData(filteredData);
  };


  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div >
        <>
          <div>
            <div className="attendance-heading  font-weight500    font-size-heading"> </div>
            <div className="attendance-subcontainer" style={{ padding: "15px 30px 15px 58px" }}>
              <div className="field-cont" style={{ maxWidth: "60vw" }}>

                <LetterStatus selectedLetterStatus={selectedLetterStatus} setLetterStatus={setLetterStatus} />

                <LetterType setLetterType={setLetterType} lettertype={lettertype} />
                <div title='Search Filter' className="field-cont-div">
                  <SearchSmall />
                  <input className="attendance-input-field  width-25vw"
                    placeholder='Letter No. / Subject / Remarks'
                    type="text"
                    value={selectedNo}
                    onChange={(e) => setNo(e.target.value)} />

                  <hr className="field-cont-hr" />
                </div>
                {letterBy === undefined &&
                  <>
                    <div title="Letter for" className="field-cont-div">
                      <CircularGrid />
                      <select
                        onChange={(e) => setWorkperformedby(e.target.value)}
                        value={workperformedby}
                        className="attendance-input-field width-10vw   date-field"
                      >
                        <option value="null">Select Option</option>
                        <option value="contractor">Contractor</option>
                        <option value="consultant">Consultant</option>

                      </select>

                      <hr className="field-cont-hr" />
                    </div>

                  </>}



                <div title="From Date Filter" className="field-cont-div">
                  <input
                    className="attendance-input-field width-10vw   date-field"
                    placeholder="From Date"
                    type="date"
                    value={selectedFromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                  <hr className="field-cont-hr" />
                </div>

                <div title="To Date Filter" className="field-cont-div ">
                  <input
                    className="attendance-input-field width-10vw   date-field"
                    placeholder="To Date"
                    type="date"
                    value={selectedToDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                  <hr className="field-cont-hr" />
                </div>

              </div>


              <div className="btn-cont width-20vw float-right" style={{ gap: '2px' }}>
                {
                  contractor === undefined ?
                    <></>
                    :
                    <>
                      {role === "BD" ? "" :
                        <>
                          {/* <AddTrackRecordDetails contractor={contractor} getProjects={getProjects} letterfor={letterBy} projectId={projectId} contractorlist={{ contractorlist }} title={letterBy === 'contractor' ? "Contractor Letter" : "Consultant Letter"} /> */}
                          <AddLetterLinked contractor={contractor} getProjects={getProjects} letterfor={letterBy} projectId={projectId} contractorlist={{ contractorlist }} title={letterBy === 'contractor' ? "Contractor Letter" : "Consultant Letter"} />
                        </>
                      }
                      {role === "Admin" && <UploadLetterBulk contractor_name={contractor_name} letterBy={letterBy} getLetters={getProjects} site_prcode={site_prcode} />}
                    </>

                }

                <DownloadTableExcel
                  filename="Site-ProjectDetails"
                  sheet="projectDetails"
                  currentTableRef={tableRef.current}
                >
                  <button className="model-button   font-weight500   model-button-print equal-hieght">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                      <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                      </g>
                    </svg>
                  </button>
                </DownloadTableExcel>

              </div>
            </div>
          </div>


          <div className="info-box-div-bg  table-box paddingTop-0px scroll-container-table child-table-scroll" style={{ marginTop: "10px" }} >

            <table ref={tableRef} className="table-css">
              <thead className='table-heading'>
                <tr className='custom-table-head-tr'>
                  <th className='table-heading-text text-lefttt'>Sno.</th>
                  <th className='table-heading-text'>Letter No</th>
                  <th className='table-heading-text'>Letter Type</th>
                  {/* <th className='table-heading-text'>Project</th> */}
                  <th className='table-heading-text'>Letter Date.</th>
                  <th className='table-heading-text text-center'>Subject</th>
                  <th className='table-heading-text'>Status</th>
                  <th className='table-heading-text text-center'>View Letters</th>
                  {role === "BD" ? "" :
                    <>
                      {/* <th className='table-heading-text'>View/Edit</th> */}
                      <th className='table-heading-text text-center'>Delete</th>
                    </>
                  }
                </tr>
              </thead>
              {buffer ? (
                <div className="spinner-small"> </div> //buffering logic 
              ) : error ? (
                <p>Error fetching data: {error.message}</p>
              ) :
                <tbody>

                  {filteredData.map((i, index) => (
                    <tr className="custom-table-head-td">
                      <td className='tablle-heading-text text-lefttt'>{`${index + 1}.`}</td>
                      <td className='tablle-heading-text'>{i.letter_num}</td>
                      <td className='tablle-heading-text'>{i.lettertype}</td>
                      {/* <td className='table-heading-text'>{i?.site_prcode}-{i.project_short_name}</td> */}
                      <td className='table-heading-text'>{i.letter_date ? formattedDateLong(i.letter_date) : "---"}</td>
                      <td className='table-heading-text text-center'>{i.subject}</td>
                      <td className='table-heading-text'>{i.status}</td>

                      <td className='table-heading-text' ><AssociatedLetters id={i.id} /></td>

                      {role === "BD" ? "" :
                        <>
                          {/* <td className='text-center'>

                            <UpdateTrackRecordDetails
                              i={i}
                              getProjects={getProjects}

                              projectId={projectId}
                            />

                          </td> */}
                          <td className='table-heading-text text-center'>
                            <DeleteTrackAndLetterDetails i={i} getLetterDetail={getProjects} />
                          </td>
                        </>}
                    </tr>
                  ))}
                </tbody>}
            </table>


          </div>
        </>
      </div >
    </>
  );
}


const MiscellaneousDocument = ({ projectId, p_id, toggleState, contractor, contractorlist, site_prcode, contractor_name }) => {
  const [docList, setDocList] = useState([]);
  const getDocument = async () => {

    try {
      let eot = await axios.get(`${BASE_URL}/siteproject/documentwithmiscellaneousget/${p_id}/`);
      setDocList(eot.data);


    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {

    }
  };
  useEffect(() => {
    getDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p_id]);
  return (

    <div className="info-box-div-bg ">
      <div >
        <div className="jd-heading-outer-flex ">
          <div className='flex-row justify-between' style={{ width: "100%" }}>
            <h2 className="jd-heading-main font-weight600  font-size-heading">Miscellaneous Documents</h2>
            <DocumentModel title={"Miscellaneous Document"} p_id={p_id} getDocument={getDocument} />
          </div>
        </div>
        <div>
          <div className="jd-heading-bottom-bold" style={{ width: "254px" }}></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <div className="form-flex-wrap table-box paddingTop-0px scroll-container-table child-table-scroll">
        <table className="table-css">
          <thead className='table-heading'>
            <tr className="custom-table-head-tr">
              <th className="table-heading-text text-lefttt">S. No.</th>
              <th className="table-heading-text text-center">Document Name </th>
              <th className="table-heading-text text-center">Document File </th>
              <th className="table-heading-text width-5vw text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            {docList?.map((i, index) => (
              <React.Fragment key={index}>

                <tr className="custom-table-head-td">
                  <td className="table-body">{index + 1}.</td>
                  <td className="table-body text-center">{i.document_name}</td>
                  <td className="table-body text-center">
                    {i.document_file ? (

                      <a href={i.document_file} target="blank">
                        <DocumentIcon />
                      </a>
                    ) : (
                      "Document Not Attached"
                    )}
                  </td>
                  <td className="table-body text-end">

                    <DeleteDocuments i={i} getDocument={getDocument} />
                  </td>


                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>


    </div>
  )


}


export {
  BillTable,
  BillingDetailsTable,
  EOTTable,
  COSTable,
  EmployeeTable,
  LetterTracking,
  // LetterTable,
  // VehiclesTable,
  MilestoneTable,
  ProjectProgressBar,
  Progress,
  ConsultancyDetails,

  ContractorDetails,
  MiscellaneousDocument
};
