import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from "react-toastify";

import usePermission from "../../config/permissions";
// import { handleErrorToast } from "../CustomFunctions";
import {
    AddwithWhiteCircle,
    CheckGreen,
    DeleteDustbin,
    DropdownArrow,
    PDFIcon,
} from "../AllSvg";

import { formattedDateLong } from "../Date";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbar";
import { formatCurrencyIndian, handleErrorToast } from "../CustomFunctions";

// ! *************** Sector Crud Models Start ***************

const AddMiscellaneous = ({ getMiscellaneous }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setFormData({
            header_name: "",
            miscellaneous_type: "ProjectbyWise",
            project: "",
            month: "",
            year: "",
            expense: "",
            document: "",
            status: "pending",
            project_details: ""
        });
        setShow(false);
    };
    const handleShow = () => {
        getProjectListData();
        setShow(true);
    };
    const months = [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
    ];

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear - i);

    const [formData, setFormData] = useState({
        header_name: "",
        // miscellaneous_type: "ProjectbyWise",
        project: "",
        month: "",
        year: "",
        expense: "",
        document: "",
        // status: "pending",
        status: "approved",
        employee: ""
    });

    // console.log(formData.miscellaneous_type)

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};

        const requiredFields = [
            "header_name", "expense", "status",
            formData.decision === 'Paid' || formData.decision === 'Salary' ? "settlement_amount" : null,
            // formData.miscellaneous_type === "Monthbywise" ? "month" : null,
            // formData.miscellaneous_type === "Monthbywise" ? "year" : null,
            // formData.miscellaneous_type === "ProjectbyWise" ? "project" : null,
            // formData.miscellaneous_type === "ProjectbyWise" ? "employee" : null,
        ].filter(Boolean);


        requiredFields.forEach((field) => {
            console.log(formData[field]);
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        console.log(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                const formDataToSend = new FormData();

                Object.keys(formData).forEach((key) => {
                    if (formData[key] && key !== "document") {
                        formDataToSend.append(key, formData[key]);
                    }
                });
                const symbols = "!@#$%^&*()_-+=";
                const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
                const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                const numbers = "0123456789";
                const now = new Date();
                const date = now
                    .toLocaleDateString("en-GB")
                    .split("/")
                    .reverse()
                    .join("-"); // Format: YYYY-MM-DD
                const time = now
                    .toLocaleTimeString("en-GB", { hour12: false })
                    .replace(/:/g, "-"); // Format: HH-MM-SS
                const allChars =
                    symbols + lowercaseLetters + uppercaseLetters + numbers;
                let randomCode = "";
                for (let i = 0; i < 8; i++) {
                    const randomIndex = Math.floor(Math.random() * allChars.length);
                    randomCode += allChars[randomIndex];
                }
                const originalFile = formData.document;
                const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
                const customFile = new File([originalFile], customFileName, {
                    type: originalFile.type,
                });
                if (formData.document) {
                    console.log(File);
                    formDataToSend.append("document", customFile);
                }
                let res = await axios.post(
                    `${BASE_URL}/siteproject/miscellaneous/`,
                    formDataToSend
                );

                if (res.status === 200) {
                    await getMiscellaneous();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Miscellaneous added successfully!");
                } else {
                    toast.dismiss(loadingToastId);
                    toast.error("Failed to add Miscellaneous!");
                }
            } catch (err) {
                //toast Logic
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        } else {

        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        // console.log(files[0])
        let newValue;
        if (type === "file") {
            console.log("file", files[0]);
            newValue = files.length > 0 ? files : null;
        } else {
            newValue = value;
        }

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });
        // if (name === "miscellaneous_type") {
        //     setFormData({
        //         ...FormData,
        //         [name]: newValue,
        //         month: "",
        //         project: "",
        //     });
        // }
        // setFormData({
        //     ...formData,
        //     [name]: newValue,
        // });
        setFormData({
            ...formData,
            [name]: newValue,
        });
    };

    const [projectListData, setProjectList] = useState([]);
    const [employeeWiseData, setemployeeWiseData] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic
    const getProjectListData = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/projectfilterbysubcompany/null/`
            );
            setProjectList(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };


    return (
        <>
            <button
                title="Add Miscellaneous"
                className="upload-svg"
                onClick={handleShow}
            >
                <AddwithWhiteCircle /> Miscellaneous
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave"
            >
                <Modal.Header
                    closeButton
                    className="justify-center task-Tab-heading font-weight500    font-size-heading "
                >
                    <Modal.Title> Add Miscellaneous</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="register-from">
                        <div className="form-flex-wrap">
                            <div className="flex-column">
                                <label
                                    htmlFor="header_name"
                                    className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    title="header_name"
                                >
                                    Name:<span className="required">*</span>
                                </label>
                                <input
                                    placeholder="Name"
                                    title="header_name"
                                    type="text"
                                    name="header_name"
                                    onChange={handleInputChange}
                                    value={formData.header_name}
                                    className={`form-input font-weight400 font-size-subheading form-input-background ${errors.header_name
                                        ? "error"
                                        : inputState.header_name
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.header_name && (
                                    <span className="error-message font-size-text">
                                        {errors.header_name}
                                    </span>
                                )}
                            </div>

                            {/* <div className="flex-column form-group-select">
                                <label
                                    htmlFor="miscellaneous_type"
                                    className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    title="miscellaneous_type"
                                >
                                    Miscellaneous Type:
                                </label>
                                <select
                                    name="miscellaneous_type"
                                    value={formData.miscellaneous_type}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.project
                                        ? "error"
                                        : inputState.project
                                            ? "success"
                                            : ""
                                        }`}
                                >
                                    <option value="ProjectbyWise">ProjectbyWise</option>
                                    <option value="Monthbywise">Monthlywise</option>
                                </select>
                                <DropdownArrow />
                                {errors.miscellaneous_type && (
                                    <span className="error-message font-size-text">
                                        {errors.miscellaneous_type}
                                    </span>
                                )}
                            </div> */}
                            {/* {console.log(formData.miscellaneous_type)} */}
                            {/* {formData.miscellaneous_type === "ProjectbyWise" ? (
                                <> */}
                            <div className="flex-column form-group-select">
                                <label className="form-labels font-weight500 font-size-subheading">
                                    Project:<span className="required">*</span>
                                </label>
                                <select
                                    name="project"
                                    value={formData.project}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.project
                                        ? "error"
                                        : inputState.project
                                            ? "success"
                                            : ""
                                        }`}
                                >
                                    <option value="">Select a Project</option>
                                    {projectListData.map((project) => (
                                        <option key={project.id} value={project.id}>
                                            {project.site_prcode}
                                            {project.project_short_name
                                                ? `-${project.project_short_name}`
                                                : null}
                                        </option>
                                    ))}
                                </select>
                                {/* <DropdownArrow />
                                {errors.project && (
                                    <span className="error-message">{errors.project}</span>
                                )}
                            </div>
                            <div className="flex-column form-group-select">
                                <label className="form-labels font-weight500 font-size-subheading">
                                    Employee Details:<span className="required">*</span>
                                </label>
                                <select
                                    name="project_details"
                                    value={formData.project_details}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.project_details
                                        ? "error"
                                        : inputState.project_details
                                            ? "success"
                                            : ""
                                        }`}
                                >
                                    <option value="">Select a Employee Details</option>
                                    {employeeWiseData.normal_employee?.map((e) => (
                                        <option key={e.employee} value={e.id}>
                                            {e.employee_name}
                                        </option>
                                    ))}
                                    {employeeWiseData.pr_project?.map((e) => (
                                        <option key={e.employee} value={e.id}>
                                            PR-{e.employee_name}
                                        </option>
                                    ))}
                                </select>
                                <DropdownArrow /> */}
                                {errors.project_details && (
                                    <span className="error-message">
                                        {errors.project_details}
                                    </span>
                                )}
                            </div>
                            {/* </>
                            ) : ( */}
                            <div className="flex-column">
                                <label className="form-labels font-weight500 font-size-subheading">
                                    Month:<span className="required">*</span>
                                </label>
                                <select
                                    name="month"
                                    value={formData.month}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.month ? "error" : inputState.month ? "success" : ""
                                        }`}
                                >
                                    <option value="">Select Month</option>
                                    {months.map((month) => (
                                        <option key={month.value} value={month.value}>
                                            {month.text}
                                        </option>
                                    ))}
                                </select>
                                {errors.month && (
                                    <div className="error-message font-size-text">
                                        {errors.month}
                                    </div>
                                )}
                            </div>
                            <div className="flex-column">
                                <label className="form-labels font-weight500 font-size-subheading">
                                    Year:<span className="required">*</span>
                                </label>
                                <select
                                    name="year"
                                    value={formData.year}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.year ? "error" : inputState.year ? "success" : ""
                                        }`}
                                >
                                    <option value="">Select Year</option>
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                                {errors.year && (
                                    <div className="error-message font-size-text">
                                        {errors.year}
                                    </div>
                                )}
                            </div>



                            <div className="flex-column">
                                <label
                                    htmlFor="expense"
                                    className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    title="expense"
                                >
                                    Expense:<span className="required">*</span>
                                </label>
                                <input
                                    id="expense"
                                    name="expense"
                                    placeholder="Expense"
                                    title="expense"
                                    type="number"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={handleInputChange}
                                    value={formData.expense}
                                    className={`form-input font-weight400 font-size-subheading form-input-background ${errors.expense
                                        ? "error"
                                        : inputState.expense
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.expense && (
                                    <span className="error-message font-size-text">
                                        {errors.expense}
                                    </span>
                                )}
                            </div>

                            <div className="flex-column">
                                <label
                                    htmlFor="document"
                                    className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                                    title="document"
                                >
                                    Document:
                                </label>
                                <input
                                    placeholder="Document"
                                    title="document"
                                    id="document"
                                    type="file"
                                    name="document"
                                    onChange={handleInputChange}
                                    // value={formData.document}
                                    className={`form-input font-weight400 font-size-subheading form-input-background ${errors.document
                                        ? "error"
                                        : inputState.document
                                            ? "success"
                                            : ""
                                        }`}
                                />
                                {errors.document && (
                                    <span className="error-message font-size-text">
                                        {errors.document}
                                    </span>
                                )}
                            </div>
                        </div>
                    </form>
                    <div className="button-models">
                        <button
                            className="model-button font-weight500 model-button-cancel"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            Add
                        </button>
                    </div>

                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const ApproveMiscellaneous = ({ i, getMiscellaneous }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.put(
                `${BASE_URL}/siteproject/miscellaneous/${i.id}/`,
                {
                    status: "approved",
                }
            );

            if (res.status === 200) {
                await getMiscellaneous();
                setShow(false);
                toast.dismiss(loadingToastId);
                toast.success("Miscellaneous approved successfully");
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button
                title="Approve Miscellaneous"
                className="model-edit-button"
                onClick={handleShow}
            >
                <CheckGreen />
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Approve Miscellaneous {i.header_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to Approve Miscellaneous {i.header_name}
                        <div className="button-models">
                            <button
                                className="model-button   font-weight500    "
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-submit"
                            >
                                Approve
                            </button>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const RejectMiscellaneous = ({ i, getMiscellaneous }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitRejected = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.put(
                `${BASE_URL}/siteproject/miscellaneous/${i.id}/`,
                {
                    status: "rejected",
                }
            );

            if (res.status === 200) {
                await getMiscellaneous();
                setShow(false);
                toast.dismiss(loadingToastId);
                toast.success("Miscellaneous rejected ");
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button
                title="Rejected Miscellaneous"
                className="model-delete-button"
                onClick={handleShow}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M13.5 4.5L4.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M4.5 4.5L13.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Rejected Miscellaneous {i.header_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to rejected Miscellaneous {i.header_name}
                        <div className="button-models">
                            <button
                                className="model-button   font-weight500    "
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitRejected}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Rejected
                            </button>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const DeleteMiscellaneous = ({ i, getMiscellaneous }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.delete(
                `${BASE_URL}/siteproject/miscellaneous/${i.id}/`
            );

            if (res.status === 200) {
                await getMiscellaneous();
                setShow(false);
                toast.dismiss(loadingToastId);
                toast.success("Miscellaneous deleted successfully");
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button
                title="Delete Miscellaneous"
                className="model-delete-button"
                onClick={handleShow}
            >
                <DeleteDustbin />
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave width-40vw"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Miscellaneous of "{i.header_name}"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete Miscellaneous ?
                        <div className="button-models">
                            <button
                                className="model-button   font-weight500    model-button-cancel "
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const Miscellaneous = () => {
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [toggleStatus, setToggleStatus] = useState("pending");
    const [sectorData, setSectorData] = useState([]);

    const getMiscellaneous = async () => {
        setBuffering(true); // Start Buffering
        let url;
        try {
            url = `${BASE_URL}/siteproject/miscellaneousfilterbystatus/${toggleStatus}/`;

            const sub = await axios.get(url);

            setSectorData(sub.data);
        } catch (err) {
            handleErrorToast(err); // Toast Logic
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getMiscellaneous();
    }, [toggleStatus]);
    const months = [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
    ];

    const role = sessionStorage.getItem("role");
    const department = sessionStorage.getItem('department')

    const [projectListData, setProjectList] = useState([]);

    const getProjectListData = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/projectfilterbysubcompany/null/`
            );
            setProjectList(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getProjectListData();
    }, []);
    return (
        <>
            <Dashboardnavbarcopy name="Miscellaneous Table" url="Miscellaneous" />

            <div className="content-tabs">
                <div className="table-css-white-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading font-weight500 font-size-heading">
                            Miscellaneous Table
                        </div>
                        <div className="field-cont">
                            <div className="table-searchh dropdown-container field-cont-div">
                                <select
                                    onChange={(e) => setToggleStatus(e.target.value)}
                                    value={toggleStatus}
                                    className="dropdown font-size-subheading  "
                                >
                                    <option value="null">All</option>
                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                            </div>

                            <AddMiscellaneous
                                getMiscellaneous={getMiscellaneous}
                                sectorList={sectorData}
                            />
                        </div>
                    </div>
                    {/* <SectorsDetailForm /> */}
                    <div className="table-box height-73vh">
                        <table className="table-css">
                            <thead className="table-heading">
                                <tr className="custom-table-head-tr ">
                                    <th className="align-left">ID</th>
                                    <th className="align-center">Heading Name</th>
                                    {/* <th className="align-center">Miscellanous Type</th> */}
                                    <th className="align-center">Project</th>
                                    {/* <th className="align-center width-5vw">Employee</th> */}
                                    <th className="align-center">Month</th>
                                    <th className="align-center">Year</th>
                                    <th className="align-center">Expense</th>
                                    <th className="align-center">Document</th>
                                    <th className="align-center width-5vw">Action</th>
                                </tr>
                            </thead>
                            {buffer ? (
                                <div className="spinner"></div>
                            ) : (
                                <tbody>
                                    {sectorData
                                        // ?.filter((e) =>
                                        //     e.status === toggleStatus
                                        // )
                                        // .sort((a, b) =>
                                        //     a.booking_date.localeCompare(b.booking_date, "en", { ignorePunctuation: true })
                                        // )
                                        .map((i, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="tr-border-bottom">
                                                    <td colSpan="6"></td>
                                                </tr>
                                                <tr className="custom-table-head-td">
                                                    <td className="align-left">{index + 1}</td>
                                                    <td className="align-center">{i.header_name}</td>
                                                    {/* <td className="align-center ">
                                                        {i.miscellaneous_type ? i.miscellaneous_type : "-"}
                                                    </td> */}
                                                    <td className="align-center form-text-trasformation-uppercase">
                                                        {i.project_name ? i.project_name : '-'}
                                                    </td>
                                                    {/* <td className="align-center form-text-trasformation-uppercase">
                                                        {i.employee_name_name ? i.employee_name_name : '-'}
                                                    </td> */}
                                                    <td className="align-center form-text-trasformation-uppercase">
                                                        {i.month
                                                            ? months.map((monthname) => {
                                                                if (monthname.value == i.month) {
                                                                    return <>{monthname?.text}</>;
                                                                }
                                                                return null;
                                                            })
                                                            : "-"}
                                                    </td>
                                                    <td className="align-center form-text-trasformation-uppercase">
                                                        {i.year ? i.year : "-"}
                                                    </td>
                                                    <td className="align-center form-text-trasformation-uppercase">
                                                        {i.expense ? i.expense : "-"}
                                                    </td>
                                                    <td className="align-center form-text-trasformation-uppercase">
                                                        {i.document ? (
                                                            <a href={i.document}>
                                                                <PDFIcon />
                                                            </a>
                                                        ) : "-"}
                                                    </td>
                                                    <td className="align-center">
                                                        {i.status === 'pending' ?
                                                            (role === "Admin" ?
                                                                <div className=" flex-row justify-evenly">
                                                                    <ApproveMiscellaneous
                                                                        i={i}
                                                                        getMiscellaneous={getMiscellaneous}
                                                                    />
                                                                    <RejectMiscellaneous
                                                                        i={i}
                                                                        getMiscellaneous={getMiscellaneous}
                                                                    />
                                                                </div>
                                                                :
                                                                <DeleteMiscellaneous
                                                                    i={i}
                                                                    getMiscellaneous={getMiscellaneous}
                                                                />)
                                                            : null

                                                        }
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
};

export default Miscellaneous;
