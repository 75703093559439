import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from "react-toastify";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import usePermission from '../../../config/permissions';
import { RenderSelectField, RenderInputField, RenderInputTextareaField, DocumentSection, toTitleCase } from '../../../components/FormdataComponent';
import { AddTrackRecordDetails, DeleteTrackAndLetterDetails, UpdateTrackRecordDetails, AssociatedLetters } from '../../../components/SiteManagement/ProjectDetailsCrudButtons';
import { formattedDateLong } from '../../../components/Date';
import axios from 'axios';
import { BASE_URL } from '../../../config/axios';
import axiosInstance from '../../../config/axiosInstance';
import Dashboardnavbar from '../../../layout/Dashboardnavbar';
import { handleErrorToast, sortProjects } from '../../../components/CustomFunctions';
import { CircularGrid, SearchBig, SearchSmall, AddwithWhiteCircle, DropdownArrow } from '../../../components/AllSvg';
import { LetterStatus, LetterType } from '../../../components/FilterDropdown';
import { Projects } from '../../../components/FilterDropdown';
import { UploadLetterBulk } from '../../../components/SiteManagement/BulkModals';
import Modal from "react-bootstrap/Modal";
import SkeletonLoader from '../../../components/Skeletonloader';
// import SkeletonLoader from "../../components/Skeletonloader";
import Select from "react-select";



const AddLetterLinked = ({
  getProjects,
  projectList,
  projectId,
  contractor,
  contractorlist,
  lettertype,
  letterfor,
  title,
}) => {
  const [oldestYear, setOldestYear] = useState("");
  const [previousprogressdata, setPreviousProgressData] = useState([]);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [inputState, setInputState] = useState({});
  const [fileNames, setFileNames] = useState([]);
  const [trackRecordList, setTrackRecordList] = useState([]);
  const [buffer, setBuffering] = useState(true);
  const [siteDepartmentList, setSiteDepartmentList] = useState([]);
  const [contractorlists, setContractorLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // For upload progress
  const [eotinputState, seteotInputState] = useState({});
  const [cosinputState, setcosInputState] = useState({});
  const [eotList, setEotList] = useState([]);
  const [mileStoneinputState, setmileStoneInputState] = useState({});
  const [progressInputState, setprogressInputState] = useState({});
  const [mileStoneList, setmileStoneList] = useState([]);
  const [progressList, setprogressList] = useState([]);
  const [mileStoneID, setmileStoneId] = useState("null");
  const [progressID, setprogressID] = useState("null");
  const [cosList, setCosList] = useState([]);
  const [eotID, setEotId] = useState("null");
  const [cosID, setCosId] = useState("null");
  const [contractorData, setContractorData] = useState({});
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format




  const months = [
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
  ];

  const handleShow = () => {
    setShow(true);
    getSiteDepartment();
  };

  const handleClose = () => {
    setFileNames("");
    setErrors("");
    setShow(false);
    setFormData({
      project: projectId ? projectId : "null",
      letter_num: "",
      reply_by: "",
      subject: "",
      letter_date: "null",
      status: "pending",
      workperformedby: letterfor ? letterfor : "null",
      lettertype: lettertype ? lettertype : "null",
      letterExchange: "null",
      associated_letters: [],
      associated_letters_status: "pending",
      letterdepartment: "",
      letter_from: "null",
      letter_to: "",
      documents: [{ document_name: "", document_file: "" }],
      contractor: contractor ? contractor : "",
      remarks: '',
      select: '',
    })

    setEOTFormData({
      project: formData.project ? formData.project : "null",
      eot_code: "",
      workperformedby: formData.workperformedby ? formData.workperformedby : "",
      approved_days: "",
      approved_date: "",
      close_letter: "",
      letter: "",
      contractor: "",
      process_initiate_date: "",
      eot_status: "pending"
    });

    setCOSFormData({
      project: formData.project ? formData.project : "null",
      cos_code: "",
      approval_date: "",
      contractor: "",
      process_initiate_date: "",
      cos_status: "pending",
      amount: "",
      workperformedby: formData.workperformedby ? formData.workperformedby : "",
      letter: "",
      approved_amount: "",
      close_letter: "",
    })

    setmileStoneFormData({
      name: "",
      milestone_date: "",
      milestone_as_per_reschedule: "",
      milestone_as_per_actual: "",
      day: "",
      rescedule_gap_days: "",
      actual_gap_days: "",
      workperformedby: "contractor",
      project: formData.project ? formData.project : "null",
      reschedule_letter: "",
      contractor: "",
      actual_letter: "",
    });

    setProgressErrors("");
  };

  const [formData, setFormData] = useState({
    project: projectId ? projectId : "null",
    letter_num: "",
    reply_by: "",
    subject: "",
    letter_date: "null",
    status: "pending",
    workperformedby: letterfor ? letterfor : "null",
    lettertype: lettertype ? lettertype : "null",
    letterExchange: "null",
    associated_letters: [],
    associated_letters_status: "pending",
    letterdepartment: "",
    letter_from: "null",
    letter_to: "",
    documents: [{ document_name: "", document_file: "" }],
    contractor: contractor ? contractor : "",
    remarks: '',
    select: '',
  });

  // const filteredData = eotList.filter(i => i.id === eotID);
  const [filteredData, setFilteredData] = useState([]);
  const [cosFilteredData, setcosFilteredData] = useState([]);
  const [mileStoneFilteredData, setmileStoneFilteredData] = useState([]);
  const [progressfilterData, setprogressfilterData] = useState([]);


  console.log("EOTcoDE")
  console.log(filteredData[0]?.eot_code);
  console.log(filteredData)

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      project: projectId
    }))
  }, [projectId]);


  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      workperformedby: letterfor
    }))
  }, [letterfor]);



  const [eotFormData, setEOTFormData] = useState({
    project: formData.project ? formData.project : "null",
    eot_code: "",
    workperformedby: "",
    approved_days: "",
    approved_date: "",
    close_letter: "",
    letter: "",
    contractor: "",
    process_initiate_date: "",
    eot_status: "pending"
  });

  const [cosFormData, setCOSFormData] = useState({
    project: formData.project ? formData.project : "null",
    cos_code: "",
    approval_date: "",
    contractor: "",
    process_initiate_date: "",
    cos_status: "pending",
    amount: "",
    workperformedby: "",
    letter: "",
    approved_amount: "",
    close_letter: "",
  })
  const [mileStoneFormData, setmileStoneFormData] = useState({
    name: "",
    milestone_date: "",
    milestone_as_per_reschedule: "",
    milestone_as_per_actual: "",
    day: "",
    rescedule_gap_days: "",
    actual_gap_days: "",
    workperformedby: "",
    project: formData.project ? formData.project : "null",
    reschedule_letter: "",
    contractor: "",
    actual_letter: "",
    mile_stone_type: "null",
  })

  const [progressData, setProgressData] = useState({
    project: formData.project ? formData.project : "null",
    submitted_financial_progress: "0",
    submitted_physical_progress: "0",
    month: "",
    year: "",
    submitted_letter: "",
    contractor,
    financial_progress: "0",
    approved_letter: "",
    physical_progress: "0",
    date: "null"
  })


  const calculateActualDays = (commencementDate, letterDate) => {
    if (!commencementDate || !letterDate) return null;
    const startDate = new Date(commencementDate);
    const endDate = new Date(letterDate);
    const timeDifference = endDate - startDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  useEffect(() => {

    setEotId("null");
    seteotInputState({});
    setFormData((prevFormData) => ({
      ...prevFormData,
      letter_date: "null",

    }));
    setErrors("");
    setEOTFormData({
      project: formData.project || "",
      eot_code: "",
      workperformedby: "",
      approved_days: "",
      approved_date: "",
      close_letter: "",
      letter: "",
      contractor: "",
      process_initiate_date: "",
      eot_status: "pending",
    });


    setCosId("null");
    setcosInputState({});

    setCOSFormData({
      project: formData.project || "",
      cos_code: "",
      approval_date: "",
      contractor: "",
      process_initiate_date: "",
      cos_status: "pending",
      amount: "",
      workperformedby: "",
      letter: "",
      approved_amount: "",
      close_letter: "",
    })

    setmileStoneId("null");
    setmileStoneInputState({});
    setmileStoneFormData({
      name: "",
      milestone_date: "",
      milestone_as_per_reschedule: "",
      milestone_as_per_actual: "",
      day: "",
      rescedule_gap_days: "",
      actual_gap_days: "",
      workperformedby: "",
      project: formData.project || "",
      reschedule_letter: "",
      contractor: "",
      actual_letter: "",
    });
    setprogressID("null");
    setprogressInputState({})
    setProgressData({
      project: formData.project || "",
      submitted_financial_progress: "0",
      submitted_physical_progress: "0",
      month: "",
      year: "",
      submitted_letter: "",
      contractor,
      financial_progress: "0",
      approved_letter: "",
      physical_progress: "0",
    })



  }, [formData.workperformedby, formData.contractor, formData.project, formData.select, formData.lettertype]);

  useEffect(() => {

    setEOTFormData({
      approved_days: "",
      eot_status: "",
    });
    setCOSFormData({
      approved_amount: "",
      cos_status: "",
    });
    setProgressData((prev) => ({
      ...prev,
      month: "",
      year: "",
    }))
    setmileStoneFormData({
      mile_stone_type: "null",
    });
    setFormData((prev) => ({
      ...prev,
      letter_date: "null",
    }));
  }, [eotID, cosID, mileStoneID, progressID]);


  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      lettertype: "null",
    }))

  }, [formData.workperformedby])

  useEffect(() => {
    if ((Array.isArray(contractorlist) && contractorlist.length < 0) ||
      (Array.isArray(contractorlists) && contractorlists.length < 0)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        workperformedby: "consultant",
        contractor: contractor ? contractor : "",
      }))
    }
    else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        workperformedby: letterfor ? letterfor : "null",
        contractor: contractor ? contractor : "",
      }))
    }
  }, [formData.project]);



  useEffect(() => {
    if (eotID !== "null") {
      const data = eotList.filter((i) => i.id === eotID);
      setFilteredData(data);
    }
    else if (cosID !== "null") {
      const data = cosList.filter((i) => i.id === cosID);
      setcosFilteredData(data);
    }
    else if (mileStoneID !== "null") {
      const data = mileStoneList.filter((i) => i.id === mileStoneID);
      setmileStoneFilteredData(data);
    }
    else if (progressID !== "null") {
      const data = progressList.filter((i) => i.id === progressID);
      setprogressfilterData(data);
    }
    else {
      setFilteredData([]);
      setcosFilteredData([]);
      setmileStoneFilteredData([]);
      setprogressfilterData([]);
    }
  }, [eotID, eotList, cosID, cosList, mileStoneID, mileStoneList, progressID, progressList]);

  console.log("filteredData", filteredData);
  console.log("cosFilteredData", cosFilteredData);


  useEffect(() => {
    const days = calculateActualDays(contractorData.commencement_date, formData.letter_date);
    if (eotID !== "null") {
      if (filteredData.length > 0) {
        const data = filteredData[0];
        setEOTFormData((prev) => ({
          ...prev,
          project: formData.project,
          workperformedby: formData.workperformedby,
          contractor: formData.contractor,
          approved_date: formData.letter_date,
          process_initiate_date: data?.process_initiate_date,
          eot_code: data?.eot_code,
          letter: data?.letter,
          close_letter: formData?.letter,
        }));
      }
    }
    if (cosID !== "null") {
      if (cosFilteredData.length > 0) {
        const data = cosFilteredData[0];
        setCOSFormData((prev) => ({
          ...prev,
          project: formData.project,
          workperformedby: formData.workperformedby,
          contractor: formData.contractor,
          approval_date: formData.letter_date,
          amount: data?.amount,
          process_initiate_date: data?.process_initiate_date,
          cos_code: data?.cos_code,
          letter: data?.letter,
          close_letter: formData?.letter,
        }));
      }
    }
    if (progressID !== "null") {
      if (progressfilterData.length > 0) {
        const data = progressfilterData[0];
        setProgressData((prev) => ({
          ...prev,
          project: formData.project,
          submitted_physical_progress: data?.submitted_physical_progress,
          submitted_financial_progress: data?.submitted_financial_progress,
          month: data?.month,
          year: data?.year,
          submitted_letter: data?.submitted_letter,
          approved_letter: formData?.letter,
          contractor: formData.contractor,
          date: formData.date


        }));
      }
    }
    if (mileStoneID !== "null" && mileStoneFormData.mile_stone_type === "actual") {
      if (mileStoneFilteredData.length > 0) {
        const data = mileStoneFilteredData[0];
        setmileStoneFormData((prev) => ({
          ...prev,
          project: formData.project,
          workperformedby: formData.workperformedby,
          contractor: formData.contractor,
          milestone_as_per_actual: formData.letter_date,
          milestone_as_per_reschedule: null,
          rescedule_gap_days: "",
          actual_gap_days: days,
          milestone_date: data?.milestone_date,
          name: data?.name,
          day: data?.day,
          // close_letter: formData?.letter,
        }));
      }
    }
    if (mileStoneID !== "null" && mileStoneFormData.mile_stone_type === "reschedule") {
      if (mileStoneFilteredData.length > 0) {
        const data = mileStoneFilteredData[0];
        setmileStoneFormData((prev) => ({
          ...prev,
          project: formData.project,
          workperformedby: formData.workperformedby,
          contractor: formData.contractor,
          milestone_as_per_reschedule: formData.letter_date,
          milestone_date: data?.milestone_date,
          rescedule_gap_days: days,
          day: data?.day,
          milestone_as_per_actual: null,
          actual_gap_days: "",
          // close_letter: formData?.letter,
        }));
      }
    }
    if (cosFormData.cos_status === "rejected") {
      setCOSFormData((prev) => ({
        ...prev,
        approved_amount: "",
      }))
    }
    if (formData.lettertype === "eot" && eotID === "null") {
      setEOTFormData((prev) => ({
        ...prev,
        contractor: formData.contractor,
        project: formData.project,
        workperformedby: formData.workperformedby,
        process_initiate_date: formData.letter_date,
        approved_date: null,
        eot_code: "",
        letter: "",
      }));
    }
    if (formData.lettertype === 'cos' && cosID === "null") {
      setCOSFormData((prev) => ({
        ...prev,
        contractor: formData.contractor,
        project: formData.project,
        workperformedby: formData.workperformedby,
        process_initiate_date: formData.letter_date,
        approval_date: null,
        cos_code: "",
        letter: "",
        amount: "",
      }))
    }
    if (formData.lettertype === 'SiteProgress' && progressID === "null") {
      setProgressData((prev) => ({
        ...prev,
        contractor: formData.contractor,
        project: formData.project,
        submitted_letter: "",
        financial_progress: "",
        physical_progress: "",
        month: "",
        year: "",
        submitted_physical_progress: "",
        submitted_financial_progress: "",
      }))
    }
  }, [formData.project, formData.workperformedby, formData.contractor, formData.letter_date, formData.select, formData.lettertype, eotID, eotFormData.eot_status, cosID, cosFormData.cos_status, mileStoneID, mileStoneFormData.mile_stone_type, contractorData.commencement_date, progressID]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      letter_date: "null",
    }));

    setmileStoneFormData((prev) => ({
      ...prev,
      actual_gap_days: "",
      rescedule_gap_days: "",
    }))
  }, [mileStoneFormData.mile_stone_type])

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      select: "",
    }));
  }, [formData.lettertype]);





  const validateForm = () => {
    const requiredFields = [
      'letter_num',
      'project',
      'subject',
      'letter_date',
      'status',
      'lettertype',
      'workperformedby',
      'letterExchange',
      'letter_to',
      // 'letterdepartment',
      // 'letter_from',
    ];
    const newErrors = requiredFields.reduce((acc, field) => {
      if (
        !formData[field] ||
        formData[field] === "null" ||
        (Array.isArray(formData[field]) && formData[field].length === 0)
      ) {
        acc[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required!`;
      }
      return acc;
    }, {});
    if (formData.workperformedby === "contractor" && !formData.contractor) {
      newErrors["contractor"] = "Contractor Name is required!";
    }
    if ((formData.lettertype === "eot" || formData.lettertype === "cos" || formData.lettertype === "Milestone" || formData.lettertype === "SiteProgress") && (!formData.select || formData.select.trim() === "")) {
      newErrors["select"] = `Select ${formData.lettertype} is required`;
    }
    if (formData.select === "new") {
      if (formData.lettertype === "eot" && (!eotFormData.eot_code || eotFormData.eot_code.trim() === "")) {
        newErrors["eot_code"] = `eot_code is required`;
      }
      if (formData.lettertype === "cos" && (!cosFormData.cos_code || cosFormData.cos_code.trim() === "")) {
        newErrors["cos_code"] = `cos_code is required`;
      }
      if (formData.lettertype === "SiteProgress" && (!progressData.month || progressData.month.trim() === "")) {
        newErrors["month"] = `Month is required`;
      }
      if (formData.lettertype === "SiteProgress" && (!progressData.year || progressData.year.trim() === "")) {
        newErrors["year"] = `Year is required`;
      }
      // if (formData.lettertype === "SiteProgress" && (!progressData.submitted_financial_progress || progressData.submitted_financial_progress.trim() === "")) {
      //   newErrors["submitted_financial_progress"] = `Submitted financial Progress is required`;
      // }
      // if (formData.lettertype === "SiteProgress" && (!progressData.submitted_physical_progress || progressData.submitted_physical_progress.trim() === "")) {
      //   newErrors["submitted_physical_progress"] = `Submitted Physcial Progress is required`;
      // }
    }

    if (formData.select === "existing") {
      if (formData.lettertype === "eot" && (eotID === "null")) {
        newErrors["eotID"] = `Existing Eot is required`;
      }
      if (formData.lettertype === "eot" && eotID !== "null" && (!eotFormData.eot_status || eotFormData.eot_status.trim() === "pending")) {
        newErrors["eot_status"] = `Eot Status is required`;
      }
      if (formData.lettertype === "eot" && eotID !== "null" && eotFormData.eot_status === "Approved" && (!eotFormData.approved_days || eotFormData.approved_days.trim() === "")) {
        newErrors["approved_days"] = `Approved Days is required`;
      }
      if (formData.lettertype === "eot" && eotID !== "null" && (!formData.associated_letters || formData.associated_letters.length === 0)) {
        newErrors["associated_letters"] = `Associated Letter  is required`;
      }
      if (formData.lettertype === "cos" && (cosID === "null")) {
        newErrors["cosID"] = `Existing COS is required`;
      }
      if (formData.lettertype === "cos" && cosID !== "null" && (!cosFormData.cos_status || cosFormData.cos_status.trim() === "pending")) {
        newErrors["cos_status"] = `COS Status is required`;
      }
      if (formData.lettertype === "cos" && cosID !== "null" && cosFormData.cos_status === "Approved" && (!cosFormData.approved_amount || cosFormData.approved_amount.trim() === "")) {
        newErrors["approved_amount"] = `Approved Amount is required`;
      }
      if (formData.lettertype === "cos" && cosID !== "null" && (!formData.associated_letters || formData.associated_letters.length === 0)) {
        newErrors["associated_letters"] = `Associated Letter  is required`;
      }
      if (formData.lettertype === "Milestone" && (mileStoneID === "null")) {
        newErrors["mileStoneID"] = `Existing Milestone is required`;
      }
      if (formData.lettertype === "Milestone" && mileStoneID !== "null" && (!mileStoneFormData.mile_stone_type || mileStoneFormData.mile_stone_type.trim() === "null")) {
        newErrors["mile_stone_type"] = `MileStone Type is required`;
      }
      if (formData.lettertype === "SiteProgress" && (progressID === "null")) {
        newErrors["progressID"] = `Existing Site Progress is required`;
      }
      // if (formData.lettertype === "SiteProgress" && progressID !== "null" && (!progressData.physical_progress || progressData.physical_progress.trim() === "0")) {
      //   newErrors["physical_progress"] = `Physical Progress is required`;
      // }
      // if (formData.lettertype === "SiteProgress" && progressID !== "null" && (!progressData.financial_progress || progressData.financial_progress.trim() === "0")) {
      //   newErrors["financial_progress"] = `Financial Progress is required`;
      // }


    }


    if (
      formData.associated_letters &&
      formData.associated_letters.length > 0 &&
      !formData.associated_letters_status
    ) {
      newErrors["associated_letters_status"] =
        "Status for associated letters is required!";
    }

    // Validate documents array
    formData.documents.forEach((doc, index) => {
      if (!doc.document_name || doc.document_name.trim() === "") {
        newErrors[`document_name_${index}`] = `Document Name is required for document ${index + 1}`;
      }
      if (!doc.document_file) {
        newErrors[`document_file_${index}`] = `Document File is required for document ${index + 1}`;
      }
    });

    setErrors(newErrors);
    setIsSubmitted(true);
    return Object.keys(newErrors).length === 0;
  };

  console.log("errors");
  console.log(errors);

  const getContractorData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/contractor/${formData.contractor}/`);
      setContractorData(res.data)
    } catch (error) {
      console.error(error);

    }
  }

  useEffect(() => {
    if (formData.contractor) {
      getContractorData();
    }
  }, [formData.contractor])

  const handleInputChange = (e) => {
    const { name, value, type, options } = e.target;

    let newValue;
    if (type === "select-multiple") {
      newValue = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else {
      newValue = value;
    }

    // Update the input state for validation feedback
    setInputState((prevState) => ({
      ...prevState,
      [name]:
        newValue && (Array.isArray(newValue) ? newValue.length > 0 : newValue)
          ? "success"
          : "",
    }));

    // Update form data with additional logic for resetting the contractor field
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: newValue,

        // Handle the letter_from logic if reply_by and letterExchange conditions are met
        ...(name === "reply_by" &&
          prevFormData.letterExchange === "send" && {
          letter_from: value,
        }),

        // Reset contractor if workperformedby is set to consultant
        ...(name === "workperformedby" &&
          value === "consultant" && {
          contractor: "",
        }),
      };

      return updatedFormData;
    });
  };

  const handleEotInputChange = (e) => {
    const { name, value } = e.target;
    seteotInputState({
      ...eotinputState,
      [name]: value.trim() ? "green" : "",
    });
    setEOTFormData({
      ...eotFormData,
      [name]: value,
    });
  };
  const handleCOSInputChange = (e) => {
    const { name, value } = e.target;
    setcosInputState({
      ...cosinputState,
      [name]: value.trim() ? "green" : "",
    });
    setCOSFormData({
      ...cosFormData,
      [name]: value,
    });
  };
  const handleMilestoneChange = (e) => {
    const { name, value } = e.target;
    setmileStoneInputState({
      ...mileStoneinputState,
      [name]: value.trim() ? "green" : "",
    });
    setmileStoneFormData({
      ...mileStoneFormData,
      [name]: value,
    });
  };

  const [progresserrors, setProgressErrors] = useState({
    submitted_physical_progress: "",
    submitted_financial_progress: "",
  });

  const handleProgressData = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    if (name === "submitted_physical_progress") {
      if (Number(value) < previousprogressdata.physical_progress || Number(value) > 100) {
        errorMessage = `Value must be between ${previousprogressdata.physical_progress}% - 100%`;
      }
    }
    if (name === "physical_progress") {
      if (Number(value) < previousprogressdata.physical_progress || Number(value) > 100) {
        errorMessage = `Value must be between ${previousprogressdata.physical_progress}% - 100%`;
      }
    }

    if (name === "financial_progress") {
      if (Number(value) < previousprogressdata.financial_progress || Number(value) > 100) {
        errorMessage = `Value must be between ${previousprogressdata.financial_progress}% - 100%`;
      }
    }
    if (name === "submitted_financial_progress") {
      if (Number(value) < previousprogressdata.financial_progress || Number(value) > 100) {
        errorMessage = `Value must be between ${previousprogressdata.financial_progress}% - 100%`;
      }
    }
    setprogressInputState({
      ...progressInputState,
      [name]: value.trim() ? "green" : "",
    });

    setProgressData({
      ...progressData,
      [name]: value,
    });

    setProgressErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };




  const handleDocInputChange = (index, e) => {
    const { name, value, files } = e.target;

    setFormData((prevFormData) => {
      const newDocuments = [...prevFormData.documents];
      newDocuments[index] = {
        ...newDocuments[index],
        [name]: files ? files[0] : value,
      };
      if (files) {
        setFileNames((prevFileNames) => {
          const newFileNames = [...prevFileNames];
          newFileNames[index] = files[0]?.name || "";
          return newFileNames;
        });
      }

      return { ...prevFormData, documents: newDocuments };
    });
  };

  const handleAddDocuments = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: [
        ...prevFormData.documents,
        { document_name: "", document_file: null },
      ],
    }));
  };

  const handleRemoveDocument = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: prevFormData.documents.filter((_, i) => i !== index),
    }));
    setFileNames((prevFileNames) =>
      prevFileNames.filter((_, i) => i !== index)
    );
  };

  const createCustomFile = (originalFile) => {
    const randomCode = generateRandomString();
    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");
    const customFileName = `Aimantra ${date} ${time}_${randomCode}_${originalFile.name}`;
    return new File([originalFile], customFileName, {
      type: originalFile.type,
    });
  };

  const generateRandomString = () => {
    const chars =
      "!@#$%^&*()_+abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    return Array.from(
      { length: 8 },
      () => chars[Math.floor(Math.random() * chars.length)]
    ).join("");
  };

  const options = trackRecordList
    .sort((a, b) => parseInt(a.letter_num) - parseInt(b.letter_num))
    .map((e) => ({
      value: e.id,
      label: `${e.letter_num} - ${e.letter_date}`,
    }));


  const handleChangecc = (selected) => {
    const associated_letters =
      selected.length > 0 ? selected.map((item) => item.value) : "";
    setFormData((prevState) => ({
      ...prevState,
      associated_letters,
    }));
  };
  const fetchAssociatedLetters = async () => {
    try {
      const res = await axios.get(
        eotID !== "null" ? `${BASE_URL}/siteproject/letterrecordsbyid/${eotFormData.letter}/` :
          progressID !== "null" ? `${BASE_URL}/siteproject/letterrecordsbyid/${progressData.submitted_letter}/` :
            cosID !== "null" ? `${BASE_URL}/siteproject/letterrecordsbyid/${cosFormData.letter}/` :
              `${BASE_URL}/siteproject/letterrecordbclosestatus/${formData.project || 'null'
              }/${formData.workperformedby || 'null'}/${formData.lettertype || 'null'}/`
      );
      setTrackRecordList(res.data);
    } catch (err) {
      console.error("Error fetching letters.", err);
    }
  };
  const getSiteDepartment = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/department/`);
      setSiteDepartmentList(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };
  const getProjectContractorDetails = async () => {
    try {
      const contractordetails = await axios.get(
        `${BASE_URL}/siteproject/contractorbyproject/${formData.project}/`
      );
      setContractorLists(contractordetails.data);
    } catch (err) {
      // handleErrorToast(err);
    } finally {
    }
  };

  const getEot = async () => {

    try {
      // let eot = await axios.get(`${BASE_URL}/siteproject/eot/`);
      let eot = await axios.get(`${BASE_URL}/siteproject/eotfilter/${formData.project}/${formData.workperformedby}/${formData.contractor ? formData.contractor : null}/pending/`);
      setEotList(eot.data);


    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {

    }
  };
  const getMilestone = async () => {

    try {
      let milestone = await axios.get(`${BASE_URL}/siteproject/milestonebycontractor/${formData.contractor}/`);
      setmileStoneList(milestone.data);


    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {

    }
  };
  const getCOS = async () => {

    try {
      // let eot = await axios.get(`${BASE_URL}/siteproject/eot/`);
      let cos = await axios.get(`${BASE_URL}/siteproject/cosfilter/${formData.project}/${formData.workperformedby}/${formData.contractor ? formData.contractor : null}/pending/`);
      setCosList(cos.data);


    } catch (err) {
      //toast Logic
      handleErrorToast(err);
    } finally {

    }
  };
  const { month, year } = progressData;


  useEffect(() => {
    if ((formData.project && formData.workperformedby) || eotID !== "null" || cosID !== "null" || progressID !== "null") {
      fetchAssociatedLetters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.project, formData.workperformedby, formData.lettertype, formData.select, eotID, eotFormData.letter, cosFormData.letter, progressID, progressData.submitted_letter]);


  useEffect(() => {
    if (!progressData.submitted_letter) {


      if (!progressData.month || !progressData.year) {
        console.error("Both month and year are required to fetch progress data.");
        return;
      }
    }

    const fetchProgressData = async () => {

      try {
        if (year < oldestYear) {
          console.warn(`Skipping loop: Year (${year}) is not greater than oldestYear (${oldestYear}).`);
          return;
        }
        let currentMonth = month - 1 === 0 ? 12 : month - 1; // Start with the previous month
        let currentYear = month - 1 === 0 ? year - 1 : year; // Adjust year if previous month is December
        let dataFound = false; // Flag to check if data is found

        while (!dataFound) {
          const response = await axios.get(
            `${BASE_URL}/siteproject/progressbyproject/${formData.project}/${formData.contractor}/${currentMonth}/${currentYear}/`
          );

          const progressData = response.data;

          if (progressData.length > 0) {
            // Assuming progressData is an array
            const { financial_progress, physical_progress } = progressData[0]; // Take the first item

            // Set the values
            setPreviousProgressData({
              financial_progress,
              physical_progress,
            });

            console.log("Financial Progress:", financial_progress);
            console.log("Physical Progress:", physical_progress);

            dataFound = true; // Exit the loop
          } else {
            console.warn(
              `No progress data found for ${currentMonth}/${currentYear}. Checking the previous month.`
            );

            // Move to the previous month
            currentMonth -= 1;
            if (currentMonth === 0) {
              currentMonth = 12;
              currentYear -= 1; // Move to the previous year
            }

            // Stop the loop if we reach an unrealistic limit (e.g., 10 years back)
            if (currentYear < year - 1) {
              console.error("No data found for the past year.");
              break;
            }
          }
        }
      } catch (error) {
        console.error("Error fetching progress data:", error);
      }
    };




    fetchProgressData();
  }, [month, year]);

  const getProgress = async () => {
    setBuffering(true); // Start buffering

    try {
      const response = await axios.get(
        `${BASE_URL}/siteproject/progressbyproject/${formData.project}/${formData.contractor}/`
      );

      setprogressList(response.data);

      // Extract and process year field
      const yearField = response.data.map(item => ({
        ...item,
        year: item.year || 0, // Default to 0 if missing
      }));



      // Find the oldest year
      const oldestYear = yearField.reduce((min, item) =>
        item.year && item.year < min ? item.year : min,
        Infinity
      );
      setOldestYear(oldestYear);

      console.log("Oldest Year:", oldestYear);

    } catch (err) {
      console.log("Error fetching progress:", err);
      handleErrorToast(err);
    } finally {
      setBuffering(false); // End buffering
    }
  };






  useEffect(() => {
    // Check if conditions are met before calling getEot
    if (formData.project !== "null"
    ) {
      if (formData.select === "existing") {
        if (formData.lettertype === "eot") {
          getEot();
        }
        if (formData.lettertype === "cos") {
          getCOS();
        }
        if (formData.contractor !== "" && formData.lettertype === "Milestone") {
          getMilestone();
        }

      }
      if (formData.contractor !== "" && formData.lettertype === "SiteProgress") {
        getProgress();
      }
    }
  }, [formData.project, formData.workperformedby, formData.contractor, formData.lettertype, formData.select, eotID, cosID, mileStoneID, progressID]);



  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");

      try {
        const formDataToSend = new FormData();

        // Append general form fields
        Object.keys(formData).forEach((key) => {
          if (key !== "documents" && formData[key]) {
            if (key === "associated_letters") {
              formData[key].forEach((value) =>
                formDataToSend.append(key, value)
              );
            } else {
              formDataToSend.append(key, formData[key]);
            }
          }
        });

        // Append documents data
        formData.documents.forEach((doc, index) => {
          if (doc.document_file) {
            const customFile = createCustomFile(doc.document_file);
            formDataToSend.append(
              `documents[${index}][document_name]`,
              doc.document_name
            );
            formDataToSend.append(
              `documents[${index}][document_file]`,
              customFile
            );
          }
        });

        const res = await axiosInstance.post(
          `/siteproject/documentbulkwithletter/`,
          formDataToSend,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            },
          }
        );

        if (res.status === 200) {

          const generatedId = res.data.id;

          if (formData.lettertype === "eot") {
            if (eotID === "null") {

              setEOTFormData((prev) => ({
                ...prev,
                letter: generatedId,
                eot_code: eotFormData.eot_code,
              }));
              const updatedEotData = {
                ...eotFormData,
                letter: generatedId,
                eot_code: eotFormData.eot_code,
              };
              const eotpost = await axios.post(`${BASE_URL}/siteproject/eot/`, updatedEotData);

              if (eotpost.status === 200) {
                await getEot();

              }
            } else if (eotID !== "null") {
              setEOTFormData((prev) => ({
                ...prev,
                close_letter: generatedId,
              }));
              const updatedEotData = {
                ...eotFormData,
                close_letter: generatedId,
              };
              const eotUpdate = await axios.put(`${BASE_URL}/siteproject/eot/${eotID}/`, updatedEotData);

              if (eotUpdate.status === 200) {
                await getEot();
              }
            }


          }
          if (formData.lettertype === "cos") {
            if (cosID === "null") {

              setCOSFormData((prev) => ({
                ...prev,
                letter: generatedId,
                cos_code: cosFormData.cos_code,
              }));
              const updatedCOSData = {
                ...cosFormData,
                letter: generatedId,
                cos_code: cosFormData.cos_code,
              };
              const cospost = await axios.post(`${BASE_URL}/siteproject/cos/`, updatedCOSData);

              if (cospost.status === 200) {
                await getCOS();

              }
            } else if (cosID !== "null") {
              setCOSFormData((prev) => ({
                ...prev,
                close_letter: generatedId,
              }));
              const updatedCOSData = {
                ...cosFormData,
                close_letter: generatedId,
              };
              const cosUpdate = await axios.put(`${BASE_URL}/siteproject/cos/${cosID}/`, updatedCOSData);

              if (cosUpdate.status === 200) {
                await getCOS();
              }
            }


          }
          if (formData.lettertype === "SiteProgress") {
            if (progressID === "null") {

              setProgressData((prev) => ({
                ...prev,
                submitted_letter: generatedId,
                month: progressData.month
              }));
              const updateProgressData = {
                ...progressData,
                submitted_letter: generatedId,
                month: progressData.month,
              };
              const progressPost = await axios.post(`${BASE_URL}/siteproject/progressget/`, updateProgressData);

              if (progressPost.status === 200) {
                await getProgress();

              }
            } else if (progressID !== "null") {
              setProgressData((prev) => ({
                ...prev,
                approved_letter: generatedId,
              }));
              const UpdatedProgressData = {
                ...progressData,
                approved_letter: generatedId,
              };
              const progressUpdate = await axios.put(`${BASE_URL}/siteproject/progressupdate/${progressID}/`, UpdatedProgressData);

              if (progressUpdate.status === 200) {
                await getProgress();
              }
            }


          }
          if (formData.lettertype === "Milestone") {
            if (mileStoneFormData.mile_stone_type === "actual") {

              setmileStoneFormData((prev) => ({
                ...prev,
                actual_letter: generatedId,
              }));
              const updatedactualMilestone = {
                ...mileStoneFormData,
                actual_letter: generatedId,
              };
              const milestoneactual = await axios.put(`${BASE_URL}/siteproject/milestone/${mileStoneID}/`, updatedactualMilestone);

              if (milestoneactual.status === 200) {
                await getMilestone();

              }
            } else if (mileStoneFormData.mile_stone_type === "reschedule") {
              setmileStoneFormData((prev) => ({
                ...prev,
                reschedule_letter: generatedId,
              }));
              const updatedRescheduleMilestone = {
                ...mileStoneFormData,
                reschedule_letter: generatedId,
              };
              const milestoneUpdate = await axios.put(`${BASE_URL}/siteproject/milestone/${mileStoneID}/`, updatedRescheduleMilestone);

              if (milestoneUpdate.status === 200) {
                await getMilestone();
              }
            }


          }

          await getProjects();

          toast.success("Letter Documents uploaded successfully!");
          handleClose();
          toast.dismiss(loadingToastId);

        } else {
          throw new Error("Document upload failed");
        }
      } catch (err) {
        toast.error("Error uploading documents");
        console.error("Error uploading documents", err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
        setUploadProgress(0);
      }
    }
  };



  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contractor: letterfor === "contractor" ? contractor : "",
    }));
  }, [contractor]);
  useEffect(() => {
    if (formData.project !== "null") {
      getProjectContractorDetails();
    }
  }, [formData.project]);

  return (
    <>
      <button
        title={`Add ${title} Details`}
        className="upload-svg equal-hieg"
        onClick={handleShow}
      >
        <AddwithWhiteCircle /> {` ${title}`}
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ourcompany-model"
      >
        <Modal.Header
          closeButton
          className="justify-center task-Tab-heading font-weight500 font-size-heading"
        >
          <Modal.Title>Add {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="register-form">
            <div className="form-flex-wrap">

              {sessionStorage.getItem('sitemanagement_role') === "SiteManagement" ? "" :

                ((projectId === 'null' || projectId === undefined || projectId === "") && (
                  <div className="form-group">
                    <RenderSelectField
                      title="Site Project"
                      name="project"
                      required={true}
                      options={projectList?.map((project) => ({
                        value: project.id,
                        label: `${project.site_prcode} - ${project.project_short_name}`,
                      }))}
                      placeholder="Select a Project"
                      formData={formData}
                      errors={errors}
                      inputState={inputState}
                      handleInputChange={handleInputChange}
                    />
                    {errors.project && (
                      <div className="error-message col">
                        {errors.project}
                      </div>
                    )}
                  </div>
                ))
              }



              <div className="form-group">
                <RenderSelectField
                  title="Letter Exchange"
                  name="letterExchange"
                  required={true}
                  options={[
                    { value: "send", label: "Send" },
                    { value: "receive", label: "Received" },
                  ]}
                  placeholder="Select an Exchange Type"
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letterExchange && (
                  <div className="error-message col">
                    {errors.letterExchange}
                  </div>
                )}
              </div>

              {formData.letterExchange === "send" && (
                <div className="form-group">
                  <RenderSelectField
                    title="Reply By"
                    name="reply_by"
                    required={true}
                    options={[
                      { value: "HO", label: "Head Office" },
                      { value: "SO", label: "Site Office" },
                    ]}
                    placeholder="Select a Reply By"
                    formData={formData}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                  {errors.reply_by && (
                    <div className="error-message col">{errors.reply_by}</div>
                  )}
                </div>
              )}

              <div className="form-group">
                <RenderInputField
                  title={`${formData.letterExchange === "null" ? "" : toTitleCase(formData.letterExchange)} Letter No.`}
                  name="letter_num"
                  required={true}
                  placeholder="Letter Number"
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letter_num && (
                  <div className="error-message col">{errors.letter_num}</div>
                )}
              </div>


              {letterfor ? <></> :

                (
                  <div className="form-group">
                    <RenderSelectField
                      title="Letter Related To"
                      name="workperformedby"
                      required={true}
                      options={[
                        ...(formData.project &&
                          ((Array.isArray(contractorlist) &&
                            contractorlist.length > 0) ||
                            (Array.isArray(contractorlists) &&
                              contractorlists.length > 0))
                          ? [{ value: "contractor", label: "Contractor" }]
                          : []),
                        { value: "consultant", label: "Consultant" },
                      ]}
                      placeholder="Select Option"
                      formData={formData}
                      errors={errors}
                      inputState={inputState}
                      handleInputChange={handleInputChange}
                    />
                    {errors.workperformedby && (
                      <div className="error-message col">
                        {errors.workperformedby}
                      </div>
                    )}
                  </div>
                )}

              {formData.workperformedby === 'contractor' ?
                <div className="form-group">
                  <div title=" Contractor Name" className="flex-column form-group-selectt">
                    <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading">
                      Contractor Name<span className="required">*</span>
                    </label>
                    <br />
                    <select
                      id="contractor"
                      name="contractor"
                      value={formData.contractor}
                      onChange={handleInputChange}
                      disabled={!!contractor}

                      className={`form-input form-group-selection ${errors.contractor ? "error" : inputState.contractor ? "success" : ""
                        }`}
                    >
                      <option value="">Select a Contractor</option>
                      {contractorlists?.sort((a, b) => a.name?.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                        <option value={i.id}>{i.name}</option>
                      </>))
                      }
                    </select>


                    <DropdownArrow />

                  </div>

                  {errors.contractor && (
                    <div className="error-message col">{errors.contractor}</div>
                  )}
                </div>
                : ""
              }

              {lettertype ?
                <></>
                :
                <>
                  <div className="form-group">
                    <RenderSelectField
                      title="Letter Type"
                      name="lettertype"
                      required={true}
                      options={[
                        { value: "show-cause", label: "Show Cause" },
                        { value: "informative", label: "Informative" },
                        { value: "eot", label: "EOT" },
                        { value: "cos", label: "COS" },
                        { value: "billing", label: "Billing" },
                        { value: "design", label: "Design" },
                        { value: "ncr", label: "NCR" },

                        { value: "Design & Drawing", label: "Design & Drawing" },
                        { value: "P&P", label: "P&P" },
                        { value: "QAP", label: "QAP" },
                        { value: "Structure", label: "Structure" },
                        { value: "Q & ME", label: "Q & ME" },
                        { value: "BBS", label: "BBS" },
                        { value: "noc", label: "NOC" },
                        { value: "Road Safety", label: "Road Safety" },
                        { value: "Work Plan", label: "Work Plan" },
                        { value: "Drain", label: "Drain" },
                        { value: "Hydrology", label: "Hydrology" },
                        { value: "Survey", label: "Survey" },
                        ...(formData.workperformedby === "contractor"
                          ? [{ value: "Milestone", label: "Milestone" }]
                          : []),
                        { value: "query", label: "Query" },
                        { value: "SiteProgress", label: "SiteProgress" },
                        { value: "other", label: "Others" }
                      ]}
                      placeholder="Select a Letter Type"
                      formData={formData}
                      errors={errors}
                      inputState={inputState}
                      handleInputChange={handleInputChange}
                    />
                    {errors.lettertype && (
                      <div className="error-message col">{errors.lettertype}</div>
                    )}
                  </div></>
              }
              {(formData.lettertype === "eot" || formData.lettertype === "cos" || formData.lettertype === "Milestone" || formData.lettertype === "SiteProgress") && formData.workperformedby !== "null" ?
                <div className="form-group">
                  <div className="flex-column form-group-selectt">
                    <label
                      htmlFor="select"
                      className="form-labels  font-weight500    font-size-subheading"
                    >
                      <span>Select  {formData.lettertype}<span className="required">*</span></span>
                    </label>
                    <br />
                    <select id="select"
                      name="select"
                      placeholder="select"
                      value={formData.select}

                      onChange={handleInputChange}
                      className={`form-input form-group-selection ${errors.select ? "error" : inputState.select ? "success" : ""
                        }`}
                    >
                      <option value="">Select</option>
                      {formData.lettertype !== "Milestone" &&
                        <>
                          <option value="new">New {formData.lettertype}</option>
                        </>
                      }
                      <option value="existing">Existing {formData.lettertype}</option>

                    </select>





                    <DropdownArrow />

                  </div>
                  {errors.select && (
                    <div className="error-message col">{errors.select}</div>
                  )}
                </div> : ""
              }


              {formData.select === "new" ?
                <>
                  {formData.lettertype === "eot" ?
                    <>
                      <div className="form-group">
                        <RenderInputField
                          title={`${formData.letterExchange === "null" ? "" : toTitleCase(formData.letterExchange)} Letter Date`}
                          name="letter_date"
                          type="date"
                          max={today}
                          required={true}
                          formData={formData}
                          errors={errors}
                          inputState={inputState}
                          handleInputChange={handleInputChange}
                        />
                        {errors.letter_date && (
                          <div className="error-message col">{errors.letter_date}</div>
                        )}
                      </div>
                      <div className="flex-column">
                        <label className="form-labels font-weight500 font-size-subheading"
                          title="EOT Code">EOT Code<span className="required">*</span></label>
                        <input
                          type="text"
                          name="eot_code"
                          placeholder="EOT Code"
                          title="EOT Code"
                          value={eotFormData.eot_code}
                          onChange={handleEotInputChange}
                          className={`form-input ${errors.eot_code ? "error" : eotinputState.eot_code ? "success" : ""}`}
                        />
                        {errors.eot_code && <span className="error-message font-size-text">{errors.eot_code}</span>}
                      </div>

                    </> : ""
                  }
                  {formData.lettertype === "cos" ?
                    <>
                      <div className="form-group">
                        <RenderInputField
                          title={`${formData.letterExchange === "null" ? "" : toTitleCase(formData.letterExchange)} Letter Date`}
                          name="letter_date"
                          type="date"
                          required={true}
                          formData={formData}
                          max={today}
                          errors={errors}
                          inputState={inputState}
                          handleInputChange={handleInputChange}
                        />
                        {errors.letter_date && (
                          <div className="error-message col">{errors.letter_date}</div>
                        )}
                      </div>
                      <div className="flex-column">
                        <label className="form-labels font-weight500 font-size-subheading"
                          title="COS Code">COS Code<span className="required">*</span></label>
                        <input
                          type="text"
                          name="cos_code"
                          placeholder="COS Code"
                          title="COS Code"
                          value={cosFormData.cos_code}
                          onChange={handleCOSInputChange}
                          className={`form-input ${errors.cos_code ? "error" : eotinputState.cos_code ? "success" : ""}`}
                        />
                        {errors.cos_code && <span className="error-message font-size-text">{errors.cos_code}</span>}
                      </div>
                      <div className="flex-column">
                        <label className="form-labels font-weight500 font-size-subheading"
                          title="COS Amount">COS Amount(Write Full Amount)<span className="required">*</span></label>
                        <input
                          type="number"
                          name="amount"
                          placeholder="COS Amount"
                          title="COS Amount"
                          value={cosFormData.amount}
                          onChange={handleCOSInputChange}
                          className={`form-input ${errors.amount ? "error" : eotinputState.amount ? "success" : ""}`}
                        />
                        {errors.amount && <span className="error-message font-size-text">{errors.amount}</span>}
                      </div>

                    </> : ""
                  }
                  {formData.lettertype === "SiteProgress" &&
                    <>
                      <div className="form-group">
                        <RenderInputField
                          title={`${formData.letterExchange === "null" ? "" : toTitleCase(formData.letterExchange)} Letter Date`}
                          name="letter_date"
                          type="date"
                          required={true}
                          max={today}
                          formData={formData}
                          errors={errors}
                          inputState={inputState}
                          handleInputChange={handleInputChange}
                        />
                        {errors.letter_date && (
                          <div className="error-message col">{errors.letter_date}</div>
                        )}
                      </div>
                      <div className="flex-column">
                        <label className="form-labels font-weight500 font-size-subheading">
                          Month<span className="required">*</span>
                        </label>
                        <select
                          name="month"
                          value={progressData.month}
                          className="form-input"
                          onChange={handleProgressData}
                        >
                          <option value=''>Select Month</option>
                          {months.map((month) => (
                            <option
                              key={month.value}
                              value={month.value}
                              disabled={progressList.some(
                                (data) => data.month === String(month.value) && data.year === String(progressData.year)
                              )}
                            >
                              {month.text}
                            </option>
                          ))}
                        </select>
                        {errors.month && <span className="error-message font-size-text">{errors.month}</span>}

                      </div>

                      {/* Frozen Year Field */}
                      <div className="flex-column">
                        <label className="form-labels font-weight500 font-size-subheading">
                          Year<span className="required">*</span>
                        </label>
                        <select
                          name="year"
                          value={progressData.year}
                          className="form-input"
                          onChange={handleProgressData}
                        >
                          <option value=''>Select Year</option>

                          {[...Array(10)].map((_, index) => {
                            const year = new Date().getFullYear() - index;
                            return (
                              <option
                                key={year}
                                value={year}
                                disabled={progressList.some(
                                  (data) => data.year === String(year) && data.month === String(progressData.month)
                                )}
                              >
                                {year}
                              </option>
                            );
                          })}
                        </select>
                        {errors.year && <span className="error-message font-size-text">{errors.year}</span>}

                      </div>
                      <>
                        {(progressData.month && progressData.year) &&
                          <>
                            <div className="flex-column">
                              <label className="form-labels font-weight500 font-size-subheading">
                                Submitted Physical Progress (%)<span className="required">*</span>
                              </label>
                              <input
                                type="number"
                                min={previousprogressdata.physical_progress}
                                placeholder={previousprogressdata.physical_progress || 0}
                                name="submitted_physical_progress"
                                onChange={handleProgressData}
                                className={`form-input ${progresserrors.submitted_physical_progress ? "input-error" : ""}`}
                              />
                              {progresserrors.submitted_physical_progress && (
                                <span className="error-message">{progresserrors.submitted_physical_progress}</span>
                              )}
                            </div>

                            {/* Submitted Financial Progress */}
                            <div className="flex-column">
                              <label className="form-labels font-weight500 font-size-subheading">
                                Submitted Financial Progress (%)<span className="required">*</span>
                              </label>
                              <input
                                type="number"
                                min={previousprogressdata.financial_progress}
                                placeholder={previousprogressdata.financial_progress || 0}
                                name="submitted_financial_progress"
                                onChange={handleProgressData}
                                className={`form-input ${progresserrors.submitted_financial_progress ? "input-error" : ""}`}
                              />
                              {progresserrors.submitted_financial_progress && (
                                <span className="error-message">{progresserrors.submitted_financial_progress}</span>
                              )}
                            </div>
                          </>
                        }
                      </>


                    </>
                  }


                </>
                :
                formData.select === "existing" ?
                  <>
                    {formData.lettertype === "eot" ?
                      <div className="form-group">
                        <div className="flex-column form-group-selectt">
                          <label
                            htmlFor="select"
                            className="form-labels  font-weight500    font-size-subheading"
                          >
                            Existing EOT<span className="required">*</span>
                          </label>
                          <br />
                          <select

                            value={eotID}
                            onChange={(e) => setEotId(e.target.value)}


                            className={`form-input form-group-selection`}
                          >
                            <option value="">Select Existing Eot</option>
                            {eotList?.sort((a, b) => a.eot_code?.localeCompare(b.eot_code, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                              <option value={i.id}>{i.eot_code}</option>
                            </>))
                            }
                          </select>
                          <DropdownArrow />
                        </div>
                        {errors.eotID && (
                          <div className="error-message col">{errors.eotID}</div>
                        )}
                      </div>
                      : ""
                    }
                    {formData.lettertype === "cos" ?
                      <div className="form-group">
                        <div className="flex-column form-group-selectt">
                          <label
                            htmlFor="select"
                            className="form-labels  font-weight500    font-size-subheading"
                          >
                            Existing COS<span className="required">*</span>
                          </label>
                          <br />
                          <select

                            value={cosID}
                            onChange={(e) => setCosId(e.target.value)}


                            className={`form-input form-group-selection`}
                          >
                            <option value="">Select Existing Cos</option>
                            {cosList?.sort((a, b) => a.cos_code?.localeCompare(b.cos_code, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                              <option value={i.id}>{i.cos_code}</option>
                            </>))
                            }
                          </select>
                          <DropdownArrow />
                        </div>
                        {errors.cosID && (
                          <div className="error-message col">{errors.cosID}</div>
                        )}
                      </div>
                      : ""
                    }
                    {formData.lettertype === "SiteProgress" ?
                      <div className="form-group">
                        <div className="flex-column form-group-selectt">
                          <label
                            htmlFor="select"
                            className="form-labels  font-weight500    font-size-subheading"
                          >
                            Existing Site Progress<span className="required">*</span>
                          </label>
                          <br />
                          <select

                            value={progressID}
                            onChange={(e) => setprogressID(e.target.value)}


                            className={`form-input form-group-selection`}
                          >
                            <option value="">Select Existing Site Progress</option>
                            {progressList?.filter(
                              (item) => !item.approved_letter // Exclude items with either actualLetter or rescheduleLetter
                            ).sort((a, b) => a.year?.localeCompare(b.year, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                              <option value={i.id}>{i.month}-{i.year}</option>
                            </>))
                            }
                          </select>
                          <DropdownArrow />
                        </div>
                        {errors.progressID && (
                          <div className="error-message col">{errors.progressID}</div>
                        )}
                      </div>
                      : ""
                    }
                    {formData.lettertype === "Milestone" ?
                      <>
                        <div className="form-group">
                          <div className="flex-column form-group-selectt">
                            <label
                              htmlFor="select"
                              className="form-labels  font-weight500    font-size-subheading"
                            >
                              Existing Milestone<span className="required">*</span>
                            </label>
                            <br />
                            <select

                              value={mileStoneID}
                              onChange={(e) => setmileStoneId(e.target.value)}


                              className={`form-input form-group-selection`}
                            >
                              <option value="">Select Existing Milestone</option>
                              {mileStoneList
                                ?.filter(
                                  (item) => !item.reschedule_letter && !item.actual_letter // Exclude items with either actualLetter or rescheduleLetter
                                )
                                .sort((a, b) => a.name?.localeCompare(b.name, 'en', { ignorePunctuation: true }))
                                .map((i) => (
                                  <option key={i.id} value={i.id}>
                                    {i.name}
                                  </option>
                                ))}

                            </select>
                            <DropdownArrow />
                          </div>
                          {errors.mileStoneID && (
                            <div className="error-message col">{errors.mileStoneID}</div>
                          )}
                        </div>
                        <div className="form-group">
                          <div className="flex-column form-group-selectt">
                            <label
                              htmlFor="mile_stone_type"
                              className="form-labels  font-weight500    font-size-subheading"
                            >
                              Milestone Type
                              <span className="required">*</span>
                            </label>
                            <br />
                            <select id="mile_stone_type"
                              name="mile_stone_type"
                              placeholder="Select"
                              value={mileStoneFormData.mile_stone_type}

                              onChange={handleMilestoneChange}
                              className={`form-input form-group-selection ${errors.select ? "error" : inputState.select ? "success" : ""
                                }`}
                            >
                              <option value="null">Select Milestone Type</option>
                              <option value="actual">Actual {formData.lettertype}</option>
                              <option value="reschedule">Reschedule {formData.lettertype}</option>

                            </select>
                            <DropdownArrow />

                          </div>
                          {errors.mile_stone_type && (
                            <div className="error-message col">{errors.mile_stone_type}</div>
                          )}
                        </div>
                      </>
                      : ""
                    }

                  </> : ""
              }
              {formData.select !== "new" &&
                <div className="form-group">
                  <RenderInputField
                    title={`${formData.letterExchange === "null" ? "" : toTitleCase(formData.letterExchange)} Letter Date`}
                    name="letter_date"
                    type="date"
                    required={true}
                    max={today}

                    formData={formData}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                  {errors.letter_date && (
                    <div className="error-message col">{errors.letter_date}</div>
                  )}
                </div>
              }


              {eotID !== "null" ?
                <>
                  <div className="form-group">
                    <div className="flex-column form-group-selectt">
                      <label className="form-labels font-weight500 font-size-subheading" title="EOT Status">EOT Status</label>
                      <br />
                      <select
                        name="eot_status"
                        title="EOT status"
                        value={eotFormData.eot_status}
                        onChange={handleEotInputChange}
                        className={`form-input form-group-selection`}
                      >
                        <option value="pending">Select Status</option>
                        <option value="Approved">Approved</option>
                        <option value="rejected">Rejected</option>

                      </select>
                      <DropdownArrow />
                    </div>
                    {errors.eot_status && <div className="error-message font-size-text">{errors.eot_status}</div>}
                  </div>

                  {
                    eotFormData.eot_status === "Approved" &&
                    <div className="flex-column">
                      <label className="form-labels font-weight500 font-size-subheading" title="Approved Days">
                        Approved Days
                      </label>
                      <input
                        type="number"
                        name="approved_days"
                        title="Approved Days"
                        placeholder="Approved Days"
                        value={eotFormData.approved_days}
                        onChange={handleEotInputChange}
                        className={`form-input ${errors.approved_days
                          ? "error"
                          : inputState.approved_days
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.approved_days && (
                        <span className="error-message font-size-text">
                          {errors.approved_days}
                        </span>
                      )}
                    </div>
                  }




                </> : <></>

              }
              {cosID !== "null" ?
                <>
                  <div className="form-group">
                    <div className="flex-column form-group-selectt">
                      <label className="form-labels font-weight500 font-size-subheading" title="Cos Status">Cos Status</label>
                      <br />
                      <select
                        name="cos_status"
                        title="Cos status"
                        value={cosFormData.cos_status}
                        onChange={handleCOSInputChange}
                        className={`form-input form-group-selection`}
                      >
                        <option value="pending">Select Status</option>
                        <option value="Approved">Approved</option>
                        <option value="rejected">Rejected</option>

                      </select>
                      <DropdownArrow />
                    </div>
                    {errors.cos_status && <div className="error-message font-size-text">{errors.cos_status}</div>}
                  </div>

                  {
                    cosFormData.cos_status === "Approved" &&
                    <div className="flex-column">
                      <label className="form-labels font-weight500 font-size-subheading" title="Approved Amount">
                        Approved Amount
                      </label>
                      <input
                        type="number"
                        name="approved_amount"
                        title="Approved Amount"
                        placeholder="Approved Amount"
                        value={cosFormData.approved_amount}
                        onChange={handleCOSInputChange}
                        className={`form-input ${errors.approved_amount
                          ? "error"
                          : cosinputState.approved_amount
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.approved_amount && (
                        <span className="error-message font-size-text">
                          {errors.approved_amount}
                        </span>
                      )}
                    </div>
                  }




                </> : <></>

              }
              {progressID !== "null" && formData.letter_date !== "null" ?
                <>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading">
                      Actual  Physical Progress (%)<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={previousprogressdata.physical_progress}
                      placeholder={previousprogressdata.physical_progress || 0}
                      name="physical_progress"
                      onChange={handleProgressData}
                      className={`form-input ${progresserrors.physical_progress ? "input-error" : ""}`}
                    />
                    {progresserrors.physical_progress && (
                      <span className="error-message">{progresserrors.physical_progress}</span>
                    )}
                  </div>

                  {/* Submitted Financial Progress */}
                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading">
                      Actual Financial Progress (%)<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min={previousprogressdata.financial_progress}
                      placeholder={previousprogressdata.financial_progress || 0}
                      name="financial_progress"
                      onChange={handleProgressData}
                      className={`form-input ${progresserrors.financial_progress ? "input-error" : ""}`}
                    />
                    {progresserrors.financial_progress && (
                      <span className="error-message">{progresserrors.financial_progress}</span>
                    )}
                  </div>





                </> : <></>

              }
              {mileStoneID !== "null" ?
                <>

                  {
                    mileStoneFormData.mile_stone_type === "actual" &&
                    <div className="flex-column">
                      <label className="form-labels font-weight500 font-size-subheading" title="Actual Gap Days">
                        Actual Gap Days<span className="required">(Read-Only)*</span>
                      </label>
                      <input
                        type="number"
                        name="actual_gap_days"
                        title="Actual Gap Days"
                        placeholder="Actual Gap Days"
                        value={mileStoneFormData.actual_gap_days}
                        onChange={handleMilestoneChange}
                        className={`form-input ${errors.actual_gap_days
                          ? "error"
                          : mileStoneinputState.actual_gap_days
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.actual_gap_days && (
                        <span className="error-message font-size-text">
                          {errors.actual_gap_days}
                        </span>
                      )}
                    </div>
                  }
                  {
                    mileStoneFormData.mile_stone_type === "reschedule" &&
                    <div className="flex-column">
                      <label className="form-labels font-weight500 font-size-subheading" title="Reschedule Gap Days">
                        Reschedule Gap Days<span className="required">(Read-Only)*</span>
                      </label>
                      <input
                        type="number"
                        name="rescedule_gap_days"
                        title="Reschedule Gap Days"
                        placeholder="Reschedule Gap Days"
                        value={mileStoneFormData.rescedule_gap_days}
                        onChange={handleMilestoneChange}
                        className={`form-input ${errors.rescedule_gap_days
                          ? "error"
                          : mileStoneinputState.rescedule_gap_days
                            ? "success"
                            : ""
                          }`}
                      />
                      {errors.rescedule_gap_days && (
                        <span className="error-message font-size-text">
                          {errors.rescedule_gap_days}
                        </span>
                      )}
                    </div>
                  }




                </> : <></>

              }




              <div className="form-group">
                <RenderInputTextareaField
                  title="Subject"
                  name="subject"
                  placeholder="Enter subject here..."
                  rows={5}
                  cols={50}
                  formData={formData}
                  errors={errors}
                  required={true}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.subject && (
                  <div className="error-message col">{errors.subject}</div>
                )}
              </div>
              <div className="form-group">
                <div title="Status" className="flex-column form-group-selectt">
                  <label
                    htmlFor="status"
                    className="form-labels  font-weight500    font-size-subheading"
                  >
                    Status<span className="required">*</span>
                  </label>
                  <br />
                  <select id="status"
                    name="status"
                    placeholder="Status"
                    value={formData.status}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.status ? "error" : inputState.status ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Status</option>
                    <option value="pending">Pending</option>
                    <option value="close">Close</option>

                  </select>
                  <DropdownArrow />
                </div>
                {errors.status && (
                  <div className="error-message col">{errors.status}</div>
                )}
              </div>

              <div className="form-group">
                <RenderSelectField
                  title="Department"
                  name="letterdepartment"
                  required={true}
                  options={siteDepartmentList
                    .map((department) => ({
                      value: department.id,
                      label: `${department.title}`,
                    }))}
                  placeholder="Select a Department"
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letterdepartment && (
                  <div className="error-message col">{errors.letterdepartment}</div>
                )}
              </div>







              {formData.letterExchange === 'receive' &&
                <div className="form-group">
                  <RenderInputField
                    title="Letter From"
                    name="letter_from"
                    placeholder="Letter From"
                    max={100}
                    formData={formData}
                    required={true}
                    errors={errors}
                    inputState={inputState}
                    handleInputChange={handleInputChange}
                  />
                  {errors.letter_from && (
                    <div className="error-message col">
                      {errors.letter_from}
                    </div>
                  )}
                </div>}

              <div className="form-group">
                <RenderInputField
                  title="Letter To"
                  name="letter_to"
                  placeholder="Letter To"
                  max={100}
                  required={true}
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.letter_to && (
                  <div className="error-message col">{errors.letter_to}</div>
                )}
              </div>
              <div className="form-group">
                <RenderInputField
                  title="Remarks"
                  name="remarks"
                  placeholder="Remarks"
                  max={100}
                  required={true}
                  formData={formData}
                  errors={errors}
                  inputState={inputState}
                  handleInputChange={handleInputChange}
                />
                {errors.remarks && (
                  <div className="error-message col">{errors.remarks}</div>
                )}
              </div>

              <div className="flex-column">
                <label
                  htmlFor="names"
                  className="form-labels font-weight500 announce-date font-weight400 font-size-heading"
                >
                  Associated Letters
                </label>
                <Select
                  options={options}
                  isMulti
                  onChange={handleChangecc}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '33vw',
                      height: '50px',
                      borderRadius: '10px',
                      border: '1px solid #e3e3e3',
                      margin: '10px 0px 20px 0px',
                      color: '#4a4a4a',
                      backgroundColor: '#f7f8fa',
                      padding: '0px 10px',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: '33vw',
                      borderRadius: '10px',
                      marginTop: '0',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                  }}
                />
                {errors.associated_letters && (
                  <span className="error-message font-size-text">
                    {errors.associated_letters}
                  </span>
                )}
              </div>

              {/* Show status options if associated letters are selected */}
              {formData.associated_letters &&
                formData.associated_letters.length > 0 && (
                  <>
                    <div className="form-column">
                      <RenderSelectField
                        title="Update Status of Associated Letters"
                        name="associated_letters_status"
                        required={true}
                        options={[
                          { value: "pending", label: "Pending" },
                          { value: "close", label: "Close" },
                        ]}
                        placeholder="Select Status"
                        formData={formData}
                        errors={errors}
                        inputState={inputState}
                        handleInputChange={handleInputChange}
                      />
                      {errors.associated_letters_status && (
                        <div className="error-message col">
                          {errors.associated_letters_status}
                        </div>
                      )}
                    </div>
                  </>
                )}
            </div>

            <DocumentSection
              formData={formData}
              handleDocInputChange={handleDocInputChange}
              handleAddDocuments={handleAddDocuments}
              handleRemoveDocument={handleRemoveDocument}
              errors={errors}
              fileNames={fileNames}
              inputState={inputState}
            />

            {/* Upload Progress Bar */}
            {uploadProgress > 0 && (
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
                <span>{uploadProgress}%</span>
              </div>
            )}

            <div className="button-models">
              <button
                onClick={handleFormSubmit}
                className="model-button font-weight500 model-button-submit"
                disabled={loading}
              >
                Submit
              </button>
            </div>
            <p className="error-message font-size-text">
              {isSubmitted && Object.keys(errors).length > 0 && (
                Object.keys(errors).length > 5 ? (
                  <h5 className="text-center">Please fill all mandatory fields!</h5>
                ) : (

                  <h6 className="text-center">
                    {Object.keys(errors).map((field) =>
                      field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ') + ' are required!'}
                  </h6>
                )
              )}
            </p>
          </form>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>


      </Modal>
    </>
  )

};


const LetterTracking = () => {
  const tableRef = useRef(null);
  const [toggleCompany, setToggleCompany] = useState("null");

  const { PERMISSION_AUTHORITY, projectID } = usePermission();
  const siteRole = sessionStorage.getItem("sitemanagement_role");

  const [project, setProject] = useState(projectID ? projectID : "null");

  const [workperformedby, setWorkperformedby] = useState("null");

  const [lettertype, setLetterType] = useState("null");

  const role = sessionStorage.getItem('sitemanagement_role');



  const [projectList, setProjectList] = useState([]);
  const [projectbyID, setProjectByID] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusExchnage, SetStatusexchange] = useState('');
  const [selectedNo, setNo] = useState("");
  const [selectedFromDate, setFromDate] = useState(null);
  const [selectedToDate, setToDate] = useState(null);
  const [selectedLetterStatus, setLetterStatus] = useState('null');
  const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);

  const [trackRecordList, setTrackRecordList] = useState([]);


  const [buffer, setBuffering] = useState(true); //buffering logic
  const [animation1, setAnimation1] = useState(true);

  const getProjectById = async () => {
    setBuffering(true);
    try {
      const project = await axios.get(`${BASE_URL}/siteproject/projectbyid/${projectID}/`);
      setProjectByID(project.data)
    } catch (error) {

    }
  }

  useEffect(() => {
    if (projectID) {
      getProjectById();
    }
    else {
      getProjects();
    }
  }, [projectID, toggleCompany]);



  const getProjects = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
      setSiteSubCompanyList(sub.data);
      // const pro = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/${toggleCompany}/`);
      const pro = await axios.get(
        sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
          `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
          : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
            `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
            : `${BASE_URL}/siteproject/projectfilterbysubcompany/${toggleCompany}/`
      );
      const sortedList = sortProjects(pro.data, "site_prcode");
      setProjectList(sortedList);

      // const res = await axios.get(`${BASE_URL}/siteproject/letterrecord/`);
      // setTrackRecordList(res.data);


    } catch (err) {
      handleErrorToast(err);
    } finally {
      // setBuffering(false);// End Buffering
    }
  };

  console.log(projectList)

  const getLetters = async () => {
    setBuffering(true);
    try {
      const res = await axios.get(`${BASE_URL}/siteproject/letterrecords/filter/${selectedLetterStatus}/${selectedFromDate}/${selectedToDate}/null/${project}/${workperformedby}/${lettertype}/null/null/null/null/`);
      setTrackRecordList(res.data.sort((a, b) => new Date(b.letter_date) - new Date(a.letter_date)));
      // setBuffering(false);
    } catch (error) {
      handleErrorToast(error);
    } finally {
      setAnimation1(false)
      setTimeout(() => {
        setBuffering(false);

      }, 1000)
      // End Buffering


      // setTimeout()

    }
  }
  // useEffect(() => {
  //   getProjects();

  // }, [toggleCompany])

  useEffect(() => {
    getLetters();

  }, [selectedLetterStatus, selectedFromDate, selectedToDate, project, workperformedby, lettertype, toggleCompany])


  useEffect(() => {
    applyFilters();
  }, [selectedNo, trackRecordList, statusExchnage]);

  const applyFilters = () => {
    let filteredData = trackRecordList;

    if (selectedNo !== "") {
      const lowercaseSelectedName = selectedNo.toLowerCase();



      filteredData = filteredData.filter(employee => {
        if (!employee || !employee.letter_num) {
          console.log("Skipping undefined or missing letter_num for employee:", employee);
          return false; // Skip entries with undefined or missing letter_num
        }

        const lid = employee?.letter_num.toString()?.toLowerCase().includes(lowercaseSelectedName) || employee?.subject?.toLowerCase()?.includes(lowercaseSelectedName) || employee?.remarks?.toLowerCase()?.includes(lowercaseSelectedName);
        return lid;
      });



    }
    if (statusExchnage !== "") {
      console.log(statusExchnage)
      const lowercaseSelectedName = statusExchnage?.toLowerCase();
      filteredData = filteredData.filter(exchange => {
        const letterExhange = exchange?.letterExchange?.toLowerCase()?.includes(lowercaseSelectedName);

        return letterExhange;
      });
    }


    setFilteredData(filteredData);
  };




  return (
    <>

      <Dashboardnavbar name={"Letter Tracking"} url="Letter Tracking" />
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className='content-tabs'>

        <div className="attendance-history-cont">

          <>
            <div>
              <div className="attendance-heading  font-weight500    font-size-heading"> </div>
              <div className="attendance-subcontainer" >
                <div className="field-cont" style={{ width: "70vw" }}>

                  {PERMISSION_AUTHORITY ?
                    <div title="Company Filter" className="field-cont-div">
                      <CircularGrid />
                      <select
                        onChange={(e) => setToggleCompany(e.target.value)}
                        value={toggleCompany}
                        className="attendance-input-field width-10vw   date-field"
                      // className="dropdown font-size-subheading  "
                      >
                        <option value="null">Sub Company</option>
                        {siteSubCompanyList.map((company) => (
                          <option value={company.id}>{company.title}</option>
                        ))}
                        ;
                      </select>

                      <hr className="field-cont-hr" />
                    </div>
                    : null
                  }
                  {siteRole === "SiteManagement" ? "" :

                    <Projects toggleProject={project} setToggleProject={setProject} subcompany={toggleCompany} />

                  }

                  <LetterType setLetterType={setLetterType} lettertype={lettertype} />
                  <div title='Search Filter' className="field-cont-div">
                    <SearchSmall />
                    <input className="attendance-input-field width-15vw"
                      placeholder='Letter no./Subject/remarks'
                      type="text"
                      value={selectedNo}
                      onChange={(e) => setNo(e.target.value)} />

                    <hr className="field-cont-hr" />
                  </div>

                  <div title="Exchange Type" className="field-cont-div">
                    <CircularGrid />
                    <select
                      onChange={(e) => SetStatusexchange(e.target.value)}
                      value={statusExchnage}
                      className="attendance-input-field width-10vw   date-field"
                    >
                      <option value="">Exchange Type</option>
                      <option value="receive">Recieve</option>
                      <option value="send">Send</option>

                    </select>

                    <hr className="field-cont-hr" />
                  </div>

                  <div title="Letter For" className="field-cont-div">
                    <CircularGrid />
                    <select
                      onChange={(e) => setWorkperformedby(e.target.value)}
                      value={workperformedby}
                      className="attendance-input-field width-10vw   date-field"
                    // className="dropdown font-size-subheading  "
                    >
                      <option className='' value="null">Letter For</option>
                      <option value="contractor">Contractor</option>
                      <option value="consultant">Consultant</option>

                    </select>

                    <hr className="field-cont-hr" />
                  </div>

                  <div title="Status Filter" className="field-cont-div">
                    <CircularGrid />
                    <select
                      id="status"
                      name="status"
                      placeholder="Status"
                      value={selectedLetterStatus}
                      onChange={(e) => setLetterStatus(e.target.value)}
                      className={`attendance-input-field width-10vw `}
                    >
                      <option value="null">Status</option>

                      <option value="pending">Pending</option>

                      <option value="in-progress">InProgress</option>
                      <option value="partially-close">Partially Close</option>

                      <option value="close">Close</option>
                    </select>
                    <hr className="field-cont-hr" />
                  </div>

                  <div title="From Date Filter" className="field-cont-div">
                    <input
                      className="attendance-input-field width-10vw   date-field"
                      placeholder="From Date"
                      type="date"
                      value={selectedFromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                    <hr className="field-cont-hr" />
                  </div>

                  <div title="To Date Filter" className="field-cont-div ">
                    <input
                      className="attendance-input-field width-10vw   date-field"
                      placeholder="To Date"
                      type="date"
                      value={selectedToDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                    <hr className="field-cont-hr" />
                  </div>

                </div>
                <div className="btn-cont">
                  <DownloadTableExcel
                    filename="Site-ProjectDetails"
                    sheet="projectDetails"
                    currentTableRef={tableRef.current}
                  >
                    <button className="model-button font-weight500   model-button-print">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                        <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                          <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                        </g>
                      </svg>
                    </button>
                  </DownloadTableExcel>
                  {siteRole === "BD" ? "" :
                    <>
                      {/* <AddTrackRecordDetails getProjects={getLetters} projectList={projectList} projectId={project} title={"Letters"} /> */}
                      <AddLetterLinked getProjects={getLetters} projectList={projectList} projectId={project} title={"Letters"} />

                      <UploadLetterBulk getLetters={getLetters} site_prcode={projectbyID.site_prcode} />

                    </>
                  }

                </div>
              </div>
            </div>
            <div className='filter-showing'>
              {/* <div>Letter Records:</div> */}
              <div></div>
              <div>Showing {trackRecordList.length} of {trackRecordList.length} </div>
            </div>

            <div className="table-css-white-background ">


              <div className='table-box  scroll-container-table'>
                <div >

                  <table ref={tableRef} className="table-css">
                    <thead className='table-heading'>
                      <tr className='custom-table-head-tr'>
                        <th className='table-heading-text text-left-sr'>S.no.</th>
                        {!projectID ? <th className='table-heading-text'>Project</th> : ""}
                        <th className='table-heading-text'>Letter No.</th>
                        <th className='table-heading-text'>Exchange</th>
                        <th className='table-heading-text'>Date</th>
                        <th className='table-heading-text'>Subject</th>
                        <th className='table-heading-text'>From</th>
                        <th className='table-heading-text'>To</th>
                        <th className='table-heading-text'>Type</th>
                        <th className='table-heading-text'>Status</th>
                        <th className='table-heading-text'>Remarks</th>
                        <th className='table-heading-text'>Associated Letter</th>
                        {siteRole === "BD" ? "" :
                          <>
                            <th className='table-heading-text'>View Letters</th>
                            {siteRole === 'Admin' ? <th className='table-heading-text text-right' style={{ justifyContent: 'right' }}>Delete</th> : ""}
                          </>
                        }
                      </tr>
                    </thead>
                    {buffer ? (

                      <div>
                        <SkeletonLoader animationfirst={animation1} />
                      </div>

                    ) : error ? (
                      <p>Error fetching data: {error.message}</p>
                    ) :
                      <tbody>
                        {/* {filteredData.sort((a,b) => b.date - a.date).map((i, index) => ( */}
                        {filteredData.sort((a, b) => new Date(b.date) - new Date(a.date)).map((i, index) => (
                          <React.Fragment key={index}>
                            <tr className="custom-table-head-td">

                              <td className='table-body text-left-sr'>{index + 1}</td>



                              {!projectID ? <td className='table-body'><div style={{ width: "120px", whiteSpace: "normal" }}>{i?.site_prcode}-{i.project_short_name}</div></td> : ""}
                              <td className='table-body form-text-trasformation-uppercase'><div style={{ width: "120px", whiteSpace: "normal" }}>{i.letter_num}</div></td>
                              <td className='table-body form-text-trasformation-uppercase'>{i.letterExchange ? i.letterExchange : "-"}</td>
                              <td className='table-body'>{formattedDateLong(i.letter_date)}</td>
                              <td className='table-body'><div style={{ width: "150px", whiteSpace: "normal" }}>{i.subject ? i.subject : <p className=''>-</p>}</div></td>
                              <td className='table-body form-text-trasformation-uppercase'><div style={{ width: "150px", whiteSpace: "normal" }}>{i.letter_from ? i.letter_from : <p className=''>-</p>}</div></td>
                              <td className='table-body form-text-trasformation-uppercase'><div style={{ width: "150px", whiteSpace: "normal" }}>{i.letter_to ? i.letter_to : <p className=''>-</p>}</div></td>
                              <td className='table-body form-text-trasformation-uppercase'>{i.lettertype}</td>
                              <td className='table-body form-text-trasformation-uppercase'>{i.status}</td>
                              <td className='table-body form-text-trasformation-uppercase w-10 '><div style={{ width: "150px", whiteSpace: "normal" }}>{i.remarks}</div></td>
                              <td className='table-body'> {i.associated_letters_get.length > 0
                                ? <AssociatedLetters id={i.id} />
                                : <p className=''>-</p>}</td>

                              {siteRole === "BD" ? "" :
                                <>
                                  <td className='table-body '>
                                    <UpdateTrackRecordDetails
                                      i={i}
                                      getProjects={getLetters}
                                      projectList={projectList}
                                      projectId={i.project}
                                      letterType={i.lettertype}
                                      workperformedby={i.workperformedby}

                                    // associatedletters={i.associated_letters_get[0].letter_num }
                                    />


                                  </td>
                                  {siteRole === 'Admin' ? <td className='table-body text-right' style={{ justifyContent: 'right' }}><DeleteTrackAndLetterDetails i={i} getLetterDetail={getLetters} /></td>
                                    : ""}

                                </>
                              }
                            </tr>
                          </React.Fragment>
                        ))}

                      </tbody>
                    }
                  </table>
                </div>
              </div>


            </div>
          </>
        </div>
      </div>
    </>
  );
}







export { LetterTracking, AddLetterLinked }
