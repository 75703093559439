import React, { useState } from 'react'
import Dashboardnavbar from '../../layout/Dashboardnavbar';
import ApprovalStagesTable from './ApprovalStages';

const Settings = () => {

    const [toggleState, setToggleState] = useState(1);


    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return 'Approval Stages';
            default:
                return 'Settings';
        }
    };


    return (
        <>
            <div>
                <Dashboardnavbar name={getSelectedTitle()} url="Settings" />
                <div className="bloc-tabss  wfm-margin">
                    <button
                        className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"}
                        onClick={() => setToggleState(1)}
                    >
                        Approval Stages
                    </button>

                </div>

                <div className="content-tabs">

                    {toggleState === 1 ? <ApprovalStagesTable /> : null}

                </div>
            </div>

        </>
    )
}

export default Settings
