import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { ToastContainer, toast } from "react-toastify";

import usePermission from "../../config/permissions";
// import { handleErrorToast } from "../CustomFunctions";
import { Eye } from "../AllSvg";
import { formatDate, formattedDateLong } from "../Date";

import Dashboardnavbar from "../../layout/Dashboardnavbar";
import { formatCurrencyIndian, handleErrorToast, formatRoundoff2D } from "../CustomFunctions";

const PnLBoard = ({ project }) => {
    const [show, setShow] = useState(false);
    const [invoice, setInvoice] = useState([]);
    const handleShow = () => {
        setShow(true);
        getInvoice();
    };
    const handleClose = () => {
        setShow(false);
    };

    const getInvoice = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/siteproject/consultant-invoiceBy-projectfilter/${project.id}/null/null/`);
            const sortedInvoices = response.data.sort((a, b) => {
                if (a.year !== b.year) {
                    return b.year - a.year; // Sort by year first
                }
                return b.month - a.month; // If years are the same, sort by month
            });
            setInvoice(sortedInvoices);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <button
                className=""
                title="View Invoice"
                onClick={handleShow}
            >
                <Eye />
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title> Invoice List </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-css-white-background">
                        <table className="table-css">
                            <thead>
                                <tr className="custom-table-head-tr-with-less-padding">
                                    <th className="table-heading-text">S.No.</th>
                                    <th className="table-heading-text text-center">Invoice Number</th>
                                    <th className="table-heading-text text-center">Month</th>
                                    <th className="table-heading-text text-center">Year</th>
                                    <th className="table-heading-text text-center">View Invoice</th>
                                    <th className="table-heading-text text-end">Payment Receipt</th>
                                    <th className="table-heading-text text-end">Delete Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoice.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="custom-table-head-td">
                                            <td className="table-body">{index + 1}</td>
                                            <td className="table-body text-center">{i.invoice_number}</td>
                                            <td className="table-body text-center">{i.month}</td>
                                            <td className="table-body text-center">{i.year}</td>



                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const PnLSheet = () => {
    const [buffer, setBuffering] = useState(true); //buffering logic
    const [toggleStatus, setToggleStatus] = useState("pending");
    const [selectedProject, setSelectedProject] = useState("e9cc1aa0-d6a9-4890-9404-31610696b3f7");
    const [timeSheetData, setTimeSheetData] = useState([]);
    const role = sessionStorage.getItem("role");

    const getTimesheet = async () => {
        setBuffering(true); // Start Buffering
        try {
            const sub = await axios.get(`${BASE_URL}/siteproject/pandlfinal/${selectedProject}/`);
            setTimeSheetData([sub.data]);
        } catch (err) {
            handleErrorToast(err); // Toast Logic
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getTimesheet();
    }, [toggleStatus, selectedProject]);
    const months = [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
    ];


    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content?.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };


    console.log("timeSheetData:", timeSheetData);

    return (
        <>
            <Dashboardnavbar name="Profit & Loss Sheet" url="P&L Sheet" />

            <div className="content-tabs">
                <div className="table-css-white-background scroll-container-table">
                    <div className="table-heading-flex">
                        {/* <div className="repo-heading font-weight500 font-size-heading">
                            Profit & Loss Sheet Table
                        </div>
                        <div className="field-cont">
                            <div className="table-searchh dropdown-container field-cont-div">
                                <select
                                    onChange={(e) => setToggleStatus(e.target.value)}
                                    value={toggleStatus}
                                    className="dropdown font-size-subheading  "
                                >
                                    <option value="null">All</option>
                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                            </div>
                        </div> */}
                    </div>
                    {/* <SectorsDetailForm /> */}
                    <div className="table-box height-73vh">
                        <table className="table-css">
                            <thead className="table-heading">
                                <tr className="custom-table-head-tr">
                                    <th className="table-heading">Sr. No.</th>
                                    {/* base */}
                                    <th className="table-heading text-center">BOQ</th>
                                    {/*  */}
                                    <th className="table-heading text-center">Invoice</th>
                                    {/*  */}
                                    <th className="table-heading text-center">Payment Received</th>
                                    {/*  */}
                                    <th className="table-heading text-center">Vehicle Billing</th>
                                    {/*  */}
                                    <th className="table-heading text-center">Office Rent</th>
                                    {/*  */}
                                    <th className="table-heading text-center">Imprest</th>
                                    {/*  */}
                                    <th className="table-heading text-center">Expense</th>
                                    {/*  */}
                                    <th className="table-heading text-center">Miscellaneous</th>
                                </tr>

                            </thead>

                            {buffer ? (
                                <div className="spinner"></div>
                            ) : (
                                <tbody>
                                    {timeSheetData.map((project, index) => (
                                        <React.Fragment key={index}>
                                            <tr className="tr-border-bottom">
                                                <td colSpan="12"></td>
                                            </tr>
                                            <tr className="custom-table-head-td">
                                                <td className="table-body">{index + 1}</td>
                                                <td title="BOQ" className="table-body">
                                                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body">Sum Contract Amounts</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(project.boq?.reduce((sum, item) => {
                                                                        return sum + parseFloat(item.contract_amount || 0);
                                                                    }, 0)) || "-"}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td title="Invoice" className="table-body">
                                                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body">Sum of Cumulative Preceding</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(project.invoice?.reduce((sum, item) => {
                                                                        return sum + parseFloat(item.cumulative_preceding || 0);
                                                                    }, 0)) || "-"}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td title="Payment Received" className="table-body">
                                                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                        <tbody>
                                                            {[
                                                                "escalation_percentage",
                                                                "escalation_amount",
                                                                "gst_amount",
                                                                "total_bill_amount",
                                                                "tds_it_percentage",
                                                                "tds_it_amount",
                                                                "tds_gst_percentage",
                                                                "tds_gst_amount",
                                                                "withheld_gst_amount",
                                                                "net_payable_amount",
                                                                "sub_total_A",
                                                                "escalation_5_percentage_ofsubtotal",
                                                                "net_ammount",
                                                                "other_deduction",
                                                                "total_deduction"
                                                            ].map((key) => (
                                                                <tr key={key}>
                                                                    <td className="table-body">Sum of {key.replace(/_/g, " ")}</td>
                                                                    <td style={{ padding: "0px 4px" }}>:</td>
                                                                    <td className="align-right">
                                                                        {formatCurrencyIndian(
                                                                            project.paymentreceived?.reduce((sum, item) => sum + parseFloat(item[key] || 0), 0)
                                                                        ) || "-"}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                </td>
                                                <td title="Vehicle Rent" className="table-body">
                                                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body">Fixed Bill Amount</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(project.vehicle_billing?.reduce((sum, item) => {
                                                                        return sum + parseFloat(item.fixed_bill_amt || 0);
                                                                    }, 0)) || "-"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body">Extra Charges</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(project.vehicle_billing?.reduce((sum, item) => {
                                                                        return sum + parseFloat(item.extra_charges || 0);
                                                                    }, 0)) || "-"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body">Fixed Bill Amount</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(
                                                                        project.vehicle_billing?.reduce((sum, item) => {
                                                                            return sum + parseFloat(item.fixed_bill_amt || 0);
                                                                        }, 0)
                                                                        +
                                                                        project.vehicle_billing?.reduce((sum, item) => {
                                                                            return sum + parseFloat(item.extra_charges || 0);
                                                                        }, 0)
                                                                    ) || "-"}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td title="Office Rent" className="table-body">
                                                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body">Sum of Rent Amount</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(project.office_rent?.reduce((sum, item) => {
                                                                        return sum + parseFloat(item.rent_amt || 0);
                                                                    }, 0)) || "-"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body">Sum of Total Amount</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(project.office_rent?.reduce((sum, item) => {
                                                                        return sum + parseFloat(item.total_amt || 0);
                                                                    }, 0)) || "-"}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td title="Imprest" className="table-body">
                                                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body">Sum of Imprests</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(
                                                                        project.imprest?.reduce((sum, item) => {
                                                                            const otherDetailsSum = item.other_details?.reduce((total, other) => {
                                                                                return total + parseFloat(other.approved_other || 0);
                                                                            }, 0) || 0;

                                                                            return sum + otherDetailsSum +
                                                                                parseFloat(item.approved_grocery || 0) +
                                                                                parseFloat(item.approved_stationary_item || 0) +
                                                                                parseFloat(item.approved_electronic_item || 0);
                                                                        }, 0)
                                                                    ) || "-"}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td title="Expense" className="table-body">
                                                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body">Sum of Submitted Expenses</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(project.expense?.reduce((sum, item) => {
                                                                        return sum + parseFloat(item.utilized_amount || 0);
                                                                    }, 0)) || "-"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="table-body">Sum of Remaining Amount</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(project.expense?.reduce((sum, item) => {
                                                                        return sum + parseFloat(item.remaining_amount || 0);
                                                                    }, 0)) || "-"}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td title="Miscellaneous" className="table-body">
                                                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td className="table-body">Sum of Misc. Expense</td>
                                                                <td style={{ padding: "0px 4px" }}>:</td>
                                                                <td className="align-right">
                                                                    {formatCurrencyIndian(project.miscellaneous?.reduce((sum, item) => {
                                                                        return sum + parseFloat(item.expense || 0);
                                                                    }, 0)) || "-"}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                {/* <td title="View details" className="table-body">
                                                </td> */}


                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            )}
                        </table>

                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
};

export default PnLSheet;
