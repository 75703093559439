import React, { useState } from 'react';

import Dashboardnavbar from '../../layout/Dashboardnavbar';
// import { SiteDesignationTable, SiteSubcompanyTable, SiteEmployeeDetails, ConsultantAccountDetails, SiteDepartment } from './WorkforceTable';
import usePermission from '../../config/permissions';
// import usePermission from '../../config/permissions';
import OfficeRent from './OfficeRent';
import OfficeBillingDetails from './OfficeBillingdetails';
const OfficeManagement = () => {


    const siteRole = sessionStorage.getItem("sitemanagement_role")

    const [toggleState, setToggleState] = useState(1);
    const getSelectedTitle = () => {
        switch (toggleState) {


            case 1:
                return 'Office Rent';
            case 2:
                return 'Office Billing Details'

            default:
                return 'Office Rent';
        }
    };


    return (
        <>
            <Dashboardnavbar name={getSelectedTitle()} url="Office  Management" />
            {(siteRole !== "SiteManagement" && siteRole === "Admin") &&
                (

                    <div className='content-tabs'>
                        <div className="toggle-button-area" style={{ padding: "34px 30px" }}>




                            <button
                                className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                                onClick={() => setToggleState(1)}
                            >
                                Office Rent
                            </button>
                            <button
                                className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                                onClick={() => setToggleState(2)}
                            >
                                Office Billing Details
                            </button>
                        </div>
                    </div>
                )
            }

            <div className="content-tabs">
                {toggleState === 1 ? <OfficeRent /> : null}
                {toggleState === 2 ? <OfficeBillingDetails /> : null}


            </div>
        </>
    );
};





export default OfficeManagement;
