import React, { useEffect, useRef, useState } from 'react';
import { BASE_URL } from '../../../config/axios';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { AddTransportationInvoice, DutyTravelInvoice, OfficeRentInvoice, OfficeSuppliesInvoice, OfficeFurnitureInvoice, DeleteDutyTravelInvoice, UpdateTransportationInvoice, UpdateDutyTravelInvoice, DeleteTransportationInvoice, ReportsPrinttInvoice, RoadSurveyEquipmentInvoice, UpdateOfficeRentInvoice, DeleteOfficeRent, UpdateOfficeSuppliesInvoice, DeleteOfficeSuppliesInvoice, UpdateFurnitureInvoice, DeleteFurnitureInvoice, UpdateReportsPrinttInvoice, DeleteReportsPrinttInvoice, UpdateRoadSurveyEquipmentInvoice, DeleteRoadSurveyEquipmentInvoice, AddEmployeeInvoice, AddContingenciesinvoice, UpdateContingenciestinvoice, DeleteUpdateContingenciestinvoice } from './ConsultancyInvoiceComponents';
import ReactToPrint from 'react-to-print';
import { calculateTotal, formatCurrencyIndian, getMonthName } from '../../CustomFunctions';
import { PaymentReceipt } from './PaymentReceipts';
import usePermission from '../../../config/permissions';

const consultant = [
    {
        "position": "Office Manger",
        "name": "Manoj Kumar",
        "rate": "35000",
        "const": "18.0",
        "maint": "-",
        "staff_month": "18.0",
        "total_amount": "630000",
        "month": "1",
        "amount": "335877",
        "description": "Innova/ Scropio",
        "descriptions": "Office rent",
        "descriptionss": "Monthly report",
        "nos_ofvehicle": "2",
        "trip": "5",
        "trips": "Site to NHAIHQ",
        "copies": "52",
        "copie_rate": "1500",
        "km": "20"
    },
]

const ConsultantInvoiceTable = ({ client, p_id, invoice_id, month, year, invoice_num }) => {
    const [consultantInvoiceData, setConsultantInvoiceData] = useState(null);

    const getConsultantInvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoice-summary-stats/${p_id}/${invoice_id}/`
            );
            setConsultantInvoiceData(res.data);
        } catch (err) {
            console.error("Error fetching consultant invoice data:", err);
        }
    };

    useEffect(() => {
        getConsultantInvoice();
    }, [p_id, invoice_id]);

    if (!consultantInvoiceData) {
        return <div>Loading...</div>;
    }

    const getDescription = (model) => {
        const descriptions = {
            "Employee": "Remuneration for Local Professional Staff",
            "Transportation": "Transportation",
            "DutyTravel": "Duty Travel to Site",
            "OfficeRent": "Office Rent",
            "OfficeSupplies": "Office Supplies, Utilities and Communication",
            "OfficeFurnitureAndEquipment": "Office Furniture and Equipment",
            "ReportsAndDocumentPrinting": "Report and Document Printing",
            "RoadSurveyEquipment": "Road Survey Equipment",
            "Contingencies": "Contingencies"
        };
        return descriptions[model] || model;  // Default to model name if description not found
    };

    const renderInvoiceRows = () => {
        return consultantInvoiceData.map((item, index) => (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{getDescription(item.model)}</td>
                <td>{item.contract_amount.toLocaleString()}</td>
                <td>{item.cumulative_preceding.toLocaleString()}</td>
                <td>{item.this_period.toLocaleString()}</td>
                <td>{item.cumulative_including_current.toLocaleString()}</td>
                <td>{item.remaining_amount.toLocaleString()}</td>
            </tr>
        ));
    };

    return (
        <>
            {/* <br /> */}
            <div className='table-css-backgrounds'>
                <div className='print-center show-on-print'>
                    <div className='heading-conslt'>

                        <div className='consultant-heading'>
                            <h5>Client Name: {client}</h5>
                            <h5>Consultant (AE):</h5>
                            <h5>GSTIN No.:</h5>
                            <h5>PAN No.:</h5>
                            <h5>Ref. No.:</h5>
                            <h5>Approval Date:</h5>
                        </div>
                        <div className='consultant-heading'>
                            <h5>Last date of bid submission:</h5>
                            <h5>Date: {getMonthName(month)}-{year}</h5>
                            <h5>Invoice Number: {invoice_num}</h5>
                        </div>
                    </div>
                </div>
                <div className="table-css-background">
                    <div className='invoice-heading'>
                        <h6>Final Summary Of Consultant Invoice</h6>
                    </div>
                    <table className="nested-table-lightblue">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Description</th>
                                <th>Contract Amount</th>
                                <th>Cumulated Total of Preceding Months</th>
                                <th>This Period</th>
                                <th>Cumulated Total including current Invoice</th>
                                <th>Remaining amount against contract value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderInvoiceRows()}

                            <tr className='total-amount'>
                                <td></td>
                                <td>Total Amount</td>
                                <td>{consultantInvoiceData.reduce((total, item) => total + item.contract_amount, 0).toLocaleString()}</td>
                                <td>{consultantInvoiceData.reduce((total, item) => total + item.cumulative_preceding, 0).toLocaleString()}</td>
                                <td>{consultantInvoiceData.reduce((total, item) => total + item.this_period, 0).toLocaleString()}</td>
                                <td>{consultantInvoiceData.reduce((total, item) => total + item.cumulative_including_current, 0).toLocaleString()}</td>
                                <td>{consultantInvoiceData.reduce((total, item) => total + item.remaining_amount, 0).toLocaleString()}</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    {/* <div>
                        <h6>Account Details:-</h6>
                    </div>
                    <table className="nested-table-lightblue width-50vw">
                        <tbody>
                            <tr>
                                <td>Name of Account Holder</td>
                                <td>Dhruv Consultancy</td>
                            </tr>
                            <tr>
                                <td>Account No.</td>
                                <td>50200000212287</td>
                            </tr>
                            <tr>
                                <td>Type of Account</td>
                                <td>Current Account</td>
                            </tr>
                            <tr>
                                <td>Name of Bank</td>
                                <td>HDFC Bank LTD.</td>
                            </tr>
                            <tr>
                                <td>IFSC Code</td>
                                <td>HDFC0000830</td>
                            </tr>
                        </tbody>
                    </table> */}
                </div>
            </div>
        </>
    );
};






const ProfessionalStaff = ({ p_id, invoice_id, year, month, invoice_num }) => {
    const [employees, setEmployees] = useState([]);

    const getEmployeeInvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoiceemployeebyprojectstatus/${p_id}/${month}/${year}/`
            );
            setEmployees(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getEmployeeInvoice();
    }, [p_id, month, year]);

    const renderEmployeeRows = (type) => {
        return employees
            .filter(employee => employee.employee_details.professional_type === type)
            .map((employee, index) => {
                const { designation_name, rate, MM_construction_period, MM_OM_period } = employee.employee_details;
                const totalMM = MM_construction_period + MM_OM_period;
                const totalAmount = rate * totalMM;
                const prevInvoiceTotal = employee.previous_invoice_total || 0;
                const currentInvoiceAmount = employee.current_invoice_amount || 0;
                const cumulativeTotal = (Number(prevInvoiceTotal) || 0) + (Number(currentInvoiceAmount) || 0);

                const remainingAmount = employee.remaining_amount || 0;

                return (
                    <tr key={employee.id}>
                        <td>{index + 1}</td>
                        <td>{designation_name}</td>
                        <td>{employee.employee_details.employee_name_details.map(ed => ed.name).join(", ")}</td>
                        <td>{rate.toLocaleString()}</td>
                        <td>{MM_construction_period}</td>
                        <td>{MM_OM_period}</td>
                        <td>{totalMM}</td>
                        <td>{totalAmount.toLocaleString()}</td>
                        <td>{employee.actual_month_total_previous}</td>
                        <td>{prevInvoiceTotal.toLocaleString()}</td>
                        <td>{employee.actual_month}</td>
                        <td>{currentInvoiceAmount.toLocaleString()}</td>
                        <td>{employee.actual_month_cumulative_total}</td>
                        <td>{cumulativeTotal.toLocaleString()}</td>
                        <td>{employee.actual_month_remaining}</td>
                        <td>{remainingAmount.toLocaleString()}</td>
                    </tr>
                );
            });
    };

    return (
        <div className='table-css-backgrounds'>
            <div className='print-center'>

                <div className="table-css-background show-on-print">
                    <div className='heading-conslt'>
                        <div className='consultant-heading'>
                            <h5>Client Name:</h5>
                            <h5>Consultant (AE):</h5>
                            <h5>PAN No.:</h5>
                        </div>
                        <div className='consultant-heading'>
                            <h5>Date: {getMonthName(month)}-{year}</h5>
                            <h5>Invoice Number: {invoice_num}</h5>
                        </div>
                    </div>
                    <div className='heads-div'>
                        <h6>I- Remuneration for Local Professional Staff</h6>
                        <div className="hide-on-print">
                            <AddEmployeeInvoice getEmployeeInvoice={getEmployeeInvoice} invoice_id={invoice_id} month={month} year={year} p_id={p_id} />
                        </div>
                    </div>
                    <table className=" nested-table-lightblue width-50vw">
                        <thead>
                            <tr>
                                <th rowSpan="2">S.No.</th>
                                <th rowSpan="2">Position</th>
                                <th rowSpan="2">Name</th>
                                <th colSpan="5">Contract Amount</th>
                                <th colSpan="2">Services up to Previous Invoice</th>
                                <th colSpan="2">Current Invoice</th>
                                <th colSpan="2">Cumulative Amount up to This</th>
                                <th colSpan="2">Remaining Amount</th>
                            </tr>
                            <tr>
                                <th>Rate</th>
                                <th>Const</th>
                                <th>Maint</th>
                                <th>Const & Maint</th>
                                <th>Total Amount</th>
                                <th>Month</th>
                                <th>Amount</th>
                                <th>Month</th>
                                <th>Amount</th>
                                <th>Month</th>
                                <th>Amount</th>
                                <th>Month</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="16" className="section-header">Key Professional Staff (A)</td>
                            </tr>
                            {renderEmployeeRows("Key Professional")}
                            <tr>
                                <td colSpan="16" className="section-header">Sub Professional Staff (B)</td>
                            </tr>
                            {renderEmployeeRows("Sub Professional")}
                            <tr>
                                <td colSpan="16" className="section-header">Support Staff (C)</td>
                            </tr>
                            {renderEmployeeRows("Support Staff")}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};



const Transportation = ({ client, p_id, invoice_id, invoice_num, month, year }) => {

    const currentDate = new Date();
    const isFirstWeek = currentDate.getDate() <= 7;
    const [transportations, setTransportations] = useState([]);

    const role = sessionStorage.getItem('sitemanagement_role');

    const getTransportationInvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoicetransportbyinvoicestatus/${invoice_id}/`);
            setTransportations(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getTransportationInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (
        <div className='table-css-backgrounds'>
            <div className='print-center'>

                <div className="table-css-background show-on-print ">
                    <div className='heading-conslt'>
                        <div className='consultant-headings'>
                            <h5>Client Name: {client}</h5>
                            <h5>Consultant (AE):</h5>
                            <h5>PAN No.:</h5>
                        </div>
                        <div className='consultant-headings'>
                            <h5>Date:{getMonthName(month)}-{year}</h5>
                            <h5>Invoice Number: {invoice_num}</h5>
                        </div>
                    </div>
                    <div className='heads-div'>
                        <h5 className='title-heading'>3- Transportation(Fixed rate on rental basis) <br /></h5>
                        {role === "Admin" ?
                            <>
                                <div className="hide-on-print">
                                    <AddTransportationInvoice p_id={p_id} invoice_id={invoice_id} getTransportationInvoice={getTransportationInvoice} month={month} year={year} />
                                </div>
                            </> :
                            <>
                                {isFirstWeek ? (
                                    <div className="hide-on-print">
                                        <AddTransportationInvoice p_id={p_id} invoice_id={invoice_id} getTransportationInvoice={getTransportationInvoice} month={month} year={year} />
                                    </div>
                                )
                                    :
                                    <>
                                        <div className='hide-on-print'>
                                            Take Approval for Add Invoice
                                        </div>
                                    </>
                                }
                            </>
                        }


                    </div>
                    <div className='flex-row jutify-center'>
                        <table className=" nested-table-lightblue  ">
                            <thead className=''>
                                <tr className='theading'>
                                    <th className='table-heading-text text-center' rowSpan="2">S.No</th>
                                    <th className='table-heading-text text-center' rowSpan="2">Description of Vehicles</th>
                                    <th className='table-heading-text text-center' colSpan="5" >Contract Amount</th>
                                    <th className='table-heading-text text-center' colSpan="2" >Claim upto Previous Invoice</th>
                                    <th className='table-heading-text text-center' colSpan="2" >Current Invoice</th>
                                    <th className='table-heading-text text-center' colSpan="2" >Cumulative Amount upto This</th>
                                    <th className='table-heading-text text-center' colSpan="2" >Remaining Amount</th>
                                    <th className='table-heading-text text-center hide-on-print' rowSpan="2" >Action</th>
                                </tr>
                                <tr className='nested-heading'>
                                    {/* <th>No. of Vehicle</th> */}
                                    <th className='table-heading-text'>Rate</th>
                                    <th className='table-heading-text'>Const</th>
                                    <th className='table-heading-text'>Maint</th>
                                    <th className='table-heading-text'>Const & Maint</th>
                                    <th className='table-heading-text'>Total Amount</th>
                                    <th className='table-heading-text'>Month</th>
                                    <th className='table-heading-text'>Amount</th>
                                    <th className='table-heading-text'>Month</th>
                                    <th className='table-heading-text'>Amount</th>
                                    <th className='table-heading-text'>Month</th>
                                    <th className='table-heading-text'>Amount</th>
                                    <th className='table-heading-text'>Month</th>
                                    <th className='table-heading-text'>Amount</th>


                                </tr>
                            </thead>
                            <tbody>
                                {transportations.map((item, index) => (

                                    <tr className='tbody' key={item.id}>
                                        <td className='table-body'>{index + 1}.</td>
                                        <td className='table-body'><div className='whitespace-normal' style={{ width: "100px" }}>{item.transport_details.vehicle_description}</div></td>
                                        {/* <td className='align-right'>1</td> */}
                                        <td className='table-body text-end'>₹ {formatCurrencyIndian(Number(item.transport_details.rate))}</td>
                                        <td className='table-body text-end'>{item.transport_details.during_construction_period}</td>
                                        <td className='table-body text-end'>{item.transport_details.during_dlp_OM_period}</td>
                                        <td className='table-body text-end'>{Number(item.transport_details.during_construction_period) + Number(item.transport_details.during_dlp_OM_period)}</td>
                                        <td className='table-body text-end'>₹ {formatCurrencyIndian(Number(item.transport_details.rate) * (Number(item.transport_details.during_construction_period) + Number(item.transport_details.during_dlp_OM_period)))}</td>
                                        <td className='table-body text-end'>{item.actual_month_total_previous}</td>
                                        {/* <td className='table-body text-end'>₹ {formatCurrencyIndian(Number(item.previous_invoice_total))}</td> */}
                                        <td className='table-body text-end'>₹ {formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                        <td className='table-body text-end'>{Number(item.actual_month)}</td>
                                        <td className='table-body text-end'>₹ {formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                        <td className='table-body text-end'>{item.actual_month_cumulative_total}</td>
                                        <td className='table-body text-end'>₹ {formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                        <td className='table-body text-end'>{item.actual_month_remaining}</td>
                                        <td className='table-body text-end'>₹ {formatCurrencyIndian(Number(item.remaining_amount))}</td>
                                        <td className="hide-on-print  table-body">
                                            {/* <UpdateTransportationInvoice i={item} p_id={p_id} invoice_id={invoice_id} getTransportationInvoice={getTransportationInvoice} /> */}
                                            <DeleteTransportationInvoice i={item} getTransportationInvoice={getTransportationInvoice} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr className='tbody'>
                                    <td></td>
                                    <td colSpan="" className="total-label">Total:</td>
                                    {/* <td>{transportation.reduce(
                                (total, item) => total + (item.during_construction_period + item.during_dlp_OM_period) * item.rate,
                                0
                            )}</td> */}
                                    <td className='align-right'>₹{formatCurrencyIndian(calculateTotal(transportations, (item) => item.transport_details.rate))}</td>
                                    <td className='align-right'>{(calculateTotal(transportations, (item) => item.transport_details.during_construction_period))}</td>
                                    <td className='align-right'>{(calculateTotal(transportations, (item) => item.transport_details.during_dlp_OM_period))}</td>
                                    <td className='align-right'>
                                        {formatCurrencyIndian(calculateTotal(transportations, (item) => Number(item.transport_details.during_construction_period) + Number(item.transport_details.during_dlp_OM_period)))}
                                    </td>
                                    <td className='align-right'>₹
                                        {formatCurrencyIndian(
                                            transportations.reduce((total, item) => total + Number(item.transport_details.rate) * (Number(item.transport_details.during_construction_period) + Number(item.transport_details.during_dlp_OM_period)), 0)
                                        )}
                                    </td>
                                    <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.actual_month_total_previous))}</td>
                                    {/* <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.previous_invoice_total))}</td> */}
                                    <td className='align-right'>₹{formatCurrencyIndian(calculateTotal(transportations, (item) => item.previous_invoice_total))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.actual_month))}</td>
                                    <td className='align-right'>₹{formatCurrencyIndian(calculateTotal(transportations, (item) => item.current_invoice_amount))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.actual_month_cumulative_total))}</td>
                                    <td className='align-right'>₹{formatCurrencyIndian(calculateTotal(transportations, (item) => item.cumulative_invoice_total))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(calculateTotal(transportations, (item) => item.actual_month_remaining))}</td>
                                    <td className='align-right'>₹{formatCurrencyIndian(calculateTotal(transportations, (item) => item.remaining_amount))}</td>
                                    <td className='hide-on-print'></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    );
};

const EmployeeInvoice = ({ p_id, invoice_id, invoice_num, month, year }) => {


    const [employee, setEmployee] = useState([]);

    const getEmployeeInvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoicetransportbyprojectstatus/${p_id}/${month}/${year}/`);
            setEmployee(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getEmployeeInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (
        <div className='table-css-backgrounds'>
            <div className='print-center'>

                <div className="table-css-background show-on-print">
                    <div className='heading-conslt'>
                        <div className='consultant-headings'>
                            <h5>Client Name:</h5>
                            <h5>Consultant (AE):</h5>
                            <h5>PAN No.:</h5>
                        </div>
                        <div className='consultant-headings'>
                            <h5>Date:{getMonthName(month)}-{year}</h5>
                            <h5>Invoice Number: {invoice_num}</h5>
                        </div>
                    </div>
                    <div className='heads-div'>
                        <h5>4. Employee Invoice <br /></h5>
                        <div className="hide-on-print">
                            <AddEmployeeInvoice p_id={p_id} invoice_id={invoice_id} getEmployeeInvoice={getEmployeeInvoice} month={month} year={year} />
                        </div>
                    </div>
                    <table className=" nested-table-lightblue">
                        <thead>
                            <tr>
                                <th rowSpan="2">S.No</th>
                                <th rowSpan="2">Description</th>
                                <th colSpan="5" className='align-center'>Contract Amount</th>
                                <th colSpan="2" className='align-center'>Claim upto Previous Invoice</th>
                                <th colSpan="2" className='align-center'>Current Invoice</th>
                                <th colSpan="2" className='align-center'>Cumulative Amount upto This</th>
                                <th colSpan="2" className='align-center'>Remaining Amount</th>
                                <th rowSpan="2" className="hide-on-print">Action</th>
                            </tr>
                            <tr>
                                {/* <th>No. of Vehicle</th> */}
                                <th>Rate</th>
                                <th>Const</th>
                                <th>Maint</th>
                                <th>Const & <br />Maint</th>
                                <th>Total Amount</th>
                                <th>Month</th>
                                <th>Amount</th>
                                <th>Month</th>
                                <th>Amount</th>
                                <th>Month</th>
                                <th>Amount</th>
                                <th>Month</th>
                                <th>Amount</th>


                            </tr>
                        </thead>
                        <tbody>
                            {employee.map((item, index) => (

                                <tr key={item.id}>
                                    <td className='align-right'>{index + 1}</td>
                                    <td className='align-left'>{item.transport_details.vehicle_description}</td>
                                    {/* <td className='align-right'>1</td> */}
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.transport_details.rate))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.during_construction_period_sum))}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.during_dlp_OM_period_sum))}</td>
                                    <td className='align-right'>{Number(item.during_construction_period_sum) + Number(item.during_dlp_OM_period_sum)}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.transport_details.rate) * (Number(item.during_construction_period_sum) + Number(item.during_dlp_OM_period_sum)))}</td>
                                    <td className='align-right'>{item.actual_month_total_previous}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                    <td className='align-right'>{Number(item.actual_month)}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                    <td className='align-right'>{item.actual_month_cumulative_total}</td>
                                    <td className='align-right'>{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                    <td className='align-right'>{item.actual_month_remaining}</td>

                                    <td className='align-right'>{formatCurrencyIndian(Number(item.remaining_amount))}</td>
                                    <td></td>
                                    {/* <td>
                                    // <UpdateTransportationInvoice i={item} p_id={p_id} invoice_id={invoice_id} getTransportationInvoice={getTransportationInvoice} />
                                    <DeleteTransportationInvoice i={item} getTransportationInvoice={getTransportationInvoice} />
                                </td> */}
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td colSpan="" className="total-label">Total:</td>
                                {/* <td>{transportation.reduce(
                                (total, item) => total + (item.during_construction_period + item.during_dlp_OM_period) * item.rate,
                                0
                            )}</td> */}
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.transport_details.rate))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.during_construction_period_sum))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.during_dlp_OM_period_sum))}</td>
                                <td className='align-right'>
                                    {formatCurrencyIndian(calculateTotal(employee, (item) => Number(item.during_construction_period_sum) + Number(item.during_dlp_OM_period_sum)))}
                                </td>
                                <td className='align-right'>
                                    {formatCurrencyIndian(
                                        employee.reduce((total, item) => total + Number(item.transport_details.rate) * (Number(item.during_construction_period_sum) + Number(item.during_dlp_OM_period_sum)), 0)
                                    )}
                                </td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.actual_month_total_previous))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.previous_invoice_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.actual_month))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.current_invoice_amount))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.actual_month_cumulative_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.cumulative_invoice_total))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.actual_month_remaining))}</td>
                                <td className='align-right'>{formatCurrencyIndian(calculateTotal(employee, (item) => item.remaining_amount))}</td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

        </div>
    );
};


const DutyTravel = ({ client, p_id, invoice_id, i, invoice_num, year, month }) => {

    // let { id } = useParams();
    const currentDate = new Date();
    const isFirstWeek = currentDate.getDate() <= 7;

    const role = sessionStorage.getItem('sitemanagement_role');

    const [dutyTravelinvoice, setDutyTravelinvoice] = useState([]);

    const getDutyTravelinvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoicedutybyprojectstatus/${p_id}/${month}/${year}/`);
            setDutyTravelinvoice(res.data);
        } catch (err) { }
    };



    useEffect(() => {

        // getdocument();
        getDutyTravelinvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    console.log(dutyTravelinvoice)


    return (
        <div className='table-css-backgrounds'>
            <div className='print-center'>

                <div className="table-css-background show-on-print">
                    <div className='heading-conslt'>
                        <div className='consultant-headings'>
                            <h5>Client Name: {client}</h5>
                            <h5>Consultant (AE):</h5>
                            <h5>PAN No.:</h5>
                        </div>
                        <div className='consultant-headings'>
                            <h5>Date:{getMonthName(month)}-{year}</h5>
                            <h5>Invoice Number: {invoice_num}</h5>
                        </div>
                    </div>
                    <div className='heads-div'>
                        <h5>4-Duty Travel to Site(Fixed Costs)(For all lienght of project):<br />
                            Professional and sub-professional Staff
                        </h5>
                        {
                            role === 'Admin' ?
                                <>
                                    <div className='hide-on-print'>
                                        <DutyTravelInvoice p_id={p_id} invoice_id={invoice_id} getDutyTravelinvoice={getDutyTravelinvoice} month={month} year={year} />
                                    </div>
                                </> :
                                <>
                                    {isFirstWeek ?
                                        (
                                            <div className='hide-on-print'>
                                                <DutyTravelInvoice p_id={p_id} invoice_id={invoice_id} getDutyTravelinvoice={getDutyTravelinvoice} month={month} year={year} />
                                            </div>
                                        ) :
                                        <>
                                            <div className='hide-on-print'>
                                                Take Approval for Add Invoice
                                            </div>
                                        </>}
                                </>
                        }
                    </div>
                    <div className='flex-row justify-center'>
                        <table className=" nested-table-lightblue ">
                            <thead>
                                <tr className='theading'>
                                    <th className='table-heading-text text-center' rowSpan="2">S.No</th>
                                    <th className='table-heading-text text-center' rowSpan="2">Trips</th>
                                    <th className='table-heading-text text-center' colSpan="3" >Contract Amount</th>
                                    <th className='table-heading-text text-center' colSpan="2" >Claim upto Previous Invoice</th>
                                    <th className='table-heading-text text-center' colSpan="2" >Current Invoice</th>
                                    <th className='table-heading-text text-center' colSpan="2" >Cumulative Amount upto This</th>
                                    <th className='table-heading-text text-center' colSpan="2" >Remaining Amount</th>
                                    <th className='table-heading-text text-center hide-on-print' rowSpan="2">Action</th>
                                </tr>
                                <tr className='nested-heading'>
                                    <th className='table-heading-text'>Rate</th>
                                    <th className='table-heading-text'>Trips</th>
                                    <th className='table-heading-text'>Total Amount</th>
                                    <th className='table-heading-text'>Trip</th>
                                    <th className='table-heading-text'>Amount</th>
                                    <th className='table-heading-text'>Trip</th>
                                    <th className='table-heading-text'>Amount</th>
                                    <th className='table-heading-text'>Trip</th>
                                    <th className='table-heading-text'>Amount</th>
                                    <th className='table-heading-text'>Trip</th>
                                    <th className='table-heading-text'>Amount</th>


                                </tr>
                            </thead>
                            <tbody>
                                {dutyTravelinvoice.map((item, index) => (

                                    <tr className='tbody' key={item.id}>
                                        <td className='table-body'>{index + 1}.</td>
                                        <td className='table-body '><div className='whitespace-normal'>{item.duty_travel_details.trips}</div></td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.duty_travel_details.rate_per_trip))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.duty_travel_details.num_of_min_trip))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.duty_travel_details.rate_per_trip) * Number(item.duty_travel_details.num_of_min_trip))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_total_previous))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_trip))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_cumulative_total))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_remaining))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.remaining_amount))}</td>

                                        <td className="table-body hide-on-print">
                                            {/* <UpdateDutyTravelInvoice i={item} p_id={p_id} invoice_id={invoice_id} getDutyTravelinvoice={getDutyTravelinvoice} /> */}
                                            <DeleteDutyTravelInvoice i={item} getDutyTravelinvoice={getDutyTravelinvoice} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr className='tbody'>

                                    <td></td>
                                    <td colSpan="" className="total-label">Total:</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.duty_travel_details.rate_per_trip)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.duty_travel_details.num_of_min_trip)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.duty_travel_details.rate_per_trip) * Number(item.duty_travel_details.num_of_min_trip)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.actual_month_total_previous)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.previous_invoice_total)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.actual_trip)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.current_invoice_amount)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.actual_month_cumulative_total)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.cumulative_invoice_total)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.actual_month_remaining)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(dutyTravelinvoice, (item) => Number(item.remaining_amount)))}</td>
                                    <td className="hide-on-print"></td>

                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div >

        </div >
    );
};

const OfficeRent = ({ client, p_id, invoice_id, invoice_num, month, year }) => {

    const role = sessionStorage.getItem('sitemanagement_role');
    const currentDate = new Date();
    const isFirstWeek = currentDate.getDate() <= 7;

    const [officeRentinvoice, setOfficeRentinvoice] = useState([]);

    const getOfficeRentinvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoiceofficerentbyprojectstatus/${p_id}/${month}/${year}/`);
            setOfficeRentinvoice(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getOfficeRentinvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='table-css-backgrounds'>
            <div className='print-center'>

                <div className="table-css-background show-on-print">
                    <div className='heading-conslt'>
                        <div className='consultant-headings'>
                            <h5>Client Name: {client}</h5>
                            <h5>Consultant (AE):</h5>
                            <h5>PAN No.:</h5>
                        </div>
                        <div className='consultant-heading'>
                            <h5>Date:{getMonthName(month)}-{year}</h5>
                            <h5>Invoice Number: {invoice_num}</h5>

                        </div>
                    </div>

                    <div className='heads-div'>
                        <h5>5- Office Rent(Fixed Cost)-(Minimum 200 sqm area of offic shall be rented. )
                        </h5>

                        {
                            role === "Admin" ?
                                <>
                                    <div className='hide-on-print'>
                                        <OfficeRentInvoice p_id={p_id} invoice_id={invoice_id} getOfficeRentinvoice={getOfficeRentinvoice} month={month} year={year} />
                                    </div>
                                </> :
                                <>
                                    {isFirstWeek ?
                                        (
                                            <div className='hide-on-print'>
                                                <OfficeRentInvoice p_id={p_id} invoice_id={invoice_id} getOfficeRentinvoice={getOfficeRentinvoice} month={month} year={year} />
                                            </div>
                                        ) :
                                        <>
                                            <div className='hide-on-print'>
                                                Take Approval for Add Invoice
                                            </div>
                                        </>}
                                </>
                        }
                    </div>
                    <div className='flex-row justify-center'>
                        <table className=" nested-table-lightblue">
                            <thead>
                                <tr className='theading'>
                                    <th className='table-heading-text' rowSpan="2">S.No</th>

                                    <th colSpan="3" className='table-heading-text text-center'>As Per Contract </th>
                                    <th colSpan="2" className='table-heading-text text-center'>Claim upto Previous Invoice</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Current Invoice</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Cumulative Amount</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Remaining Amount</th>
                                    <th rowSpan="4" className='table-heading-text text-center hide-on-print'>Action</th>
                                </tr>
                                <tr className='nested-heading'>
                                    <th className='table-heading-text'>Months</th>
                                    <th className='table-heading-text'>Rate</th>
                                    {/*  <th className='table-heading-text'>Const</th>
                                 <th className='table-heading-text'>Mint</th>
                                 <th className='table-heading-text'>Total MM</th> */}
                                    <th className='table-heading-text'>Total Amount</th>
                                    <th className='table-heading-text'>Month</th>
                                    <th className='table-heading-text'>Amount</th>
                                    <th className='table-heading-text'>Month</th>
                                    <th className='table-heading-text'>Amount</th>
                                    <th className='table-heading-text'>Month</th>
                                    <th className='table-heading-text'>Amount</th>
                                    <th className='table-heading-text'>Month</th>
                                    <th className='table-heading-text'>Amount</th>


                                </tr>
                            </thead>
                            <tbody>
                                {officeRentinvoice.map((item, index) => (

                                    <tr className='tbody' key={item.id}>
                                        <td className='table-body '>{index + 1}.</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.officerent_details.num_of_months))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.officerent_details.rate_per_month))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.officerent_details.rate_per_month) * Number(item.officerent_details.num_of_months))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_total_previous))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_cumulative_total))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_remaining))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.remaining_amount))}</td>
                                        <td colSpan={4} className='table-body text-center hide-on-print'>
                                            {/* <UpdateOfficeRentInvoice i={item} p_id={p_id} invoice_id={invoice_id} getOfficeRentinvoice={getOfficeRentinvoice} /> */}
                                            <DeleteOfficeRent i={item} getOfficeRentinvoice={getOfficeRentinvoice} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr className='tbody'>
                                    <td className="total-label table-body">Total:</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.officerent_details.num_of_months)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.officerent_details.rate_per_month)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.officerent_details.rate_per_month) * Number(item.officerent_details.num_of_months)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.actual_month_total_previous)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.previous_invoice_total)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.actual_month)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.current_invoice_amount)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.actual_month_cumulative_total)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.cumulative_invoice_total)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.actual_month_remaining)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeRentinvoice, (item) => Number(item.remaining_amount)))}</td>
                                    <td className="hide-on-print"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    );
};

const OfficeSupplies = ({ client, p_id, invoice_id, month, year, invoice_num }) => {

    // let { id } = useParams();

    const role = sessionStorage.getItem('sitemanagement_role');

    const [officeSuppliesinvoice, setOfficeSuppliesinvoice] = useState([]);

    const currentDate = new Date();
    const isFirstWeek = currentDate.getDate() <= 7;

    const getOfficeSuppliesinvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoiceofficesuppliesbyprojectstatus/${p_id}/${month}/${year}/`);
            setOfficeSuppliesinvoice(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getOfficeSuppliesinvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='table-css-backgrounds'>
            <div className='print-center'>

                <div className="table-css-background show-on-print">
                    <div className='heading-conslt'>
                        <div className='consultant-headings'>
                            <h5>Client Name:{client}</h5>
                            <h5>Consultant (AE):</h5>
                            <h5>PAN No.:</h5>
                        </div>
                        <div className='consultant-headings'>
                            <h5>Date:{getMonthName(month)}-{year}</h5>
                            <h5>Invoice Number: {invoice_num}</h5>
                        </div>
                    </div>
                    <div className='heads-div'>
                        <h5>6- Office Supplies, Utilities and Communication(Fixed Cost)<br /></h5>
                        {role === "Admin" ?
                            <>
                                <div className='hide-on-print'>
                                    <OfficeSuppliesInvoice p_id={p_id} invoice_id={invoice_id} getOfficeSuppliesinvoice={getOfficeSuppliesinvoice} month={month} year={year} />
                                </div>
                            </> :
                            <>
                                {isFirstWeek ? (
                                    <div className='hide-on-print'>
                                        <OfficeSuppliesInvoice p_id={p_id} invoice_id={invoice_id} getOfficeSuppliesinvoice={getOfficeSuppliesinvoice} month={month} year={year} />
                                    </div>
                                )
                                    :
                                    <>
                                        <div className='hide-on-print'>
                                            Take Approval for Add Invoice
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                    <div className='flex-row justify-center'>
                        <table className="nested-table-lightblue" >
                            <thead>
                                <tr className='theading'>
                                    <th rowSpan="2">S.No</th>
                                    <th rowSpan="2">Item</th>
                                    <th colSpan="3" className='table-heading-text text-center'>As Per Contract</th>
                                    <th colSpan="2" className='table-heading-text text-center'> Previous Invoice</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Current Invoice</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Cumulative Amount</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Remaining Amount</th>
                                    <th rowSpan="2" className='table-heading-text text-center hide-on-print'>Action</th>
                                </tr>
                                <tr className='nested-heading'>
                                    <th className='table-heading-text'>Period (Month)</th>
                                    <th className='table-heading-text'>Rate</th>
                                    <th className='table-heading-text'>Total Amount</th>
                                    <th className='table-heading-text'>Month</th>
                                    <th className='table-heading-text'>Amount</th>
                                    <th className='table-heading-text'>Month</th>
                                    <th className='table-heading-text'>Amount</th>
                                    <th className='table-heading-text'>Month</th>
                                    <th className='table-heading-text'>Amount</th>
                                    <th className='table-heading-text'>Month</th>
                                    <th className='table-heading-text'>Amount</th>


                                </tr>
                            </thead>
                            <tbody>
                                {officeSuppliesinvoice.map((item, index) => (

                                    <tr className='tbody' key={item.id}>
                                        <td className='table-body'> {index + 1}.</td>
                                        <td className='table-body'><div className="whitespace-normal">{item.officesupplies_details.item}</div></td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.officesupplies_details.months))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.officesupplies_details.monthly_rate))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.officesupplies_details.monthly_rate) * Number(item.officesupplies_details.months))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_total_previous))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_cumulative_total))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_remaining))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.remaining_amount))}</td>
                                        <td className="table body text-center   hide-on-print">
                                            {/* <UpdateOfficeSuppliesInvoice i={item} p_id={p_id} invoice_id={invoice_id} getOfficeSuppliesinvoice={getOfficeSuppliesinvoice} /> */}
                                            <DeleteOfficeSuppliesInvoice i={item} getOfficeSuppliesinvoice={getOfficeSuppliesinvoice} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr className='tbody'>
                                    <td></td>
                                    <td className="total-label table-body">Total:</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.officesupplies_details.months)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.officesupplies_details.monthly_rate)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.officesupplies_details.monthly_rate) * Number(item.officesupplies_details.months)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.actual_month_total_previous)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.previous_invoice_total)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.actual_month)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.current_invoice_amount)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.actual_month_cumulative_total)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.cumulative_invoice_total)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.actual_month_remaining)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeSuppliesinvoice, (item) => Number(item.remaining_amount)))}</td>
                                    <td className="hide-on-print"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div >

        </div >
    );
};

const OfficeFurniture = ({ client, p_id, invoice_id, month, year, invoice_num }) => {

    // let { id } = useParams();

    const [officeFurnitureinvoice, setOfficeFurnitureinvoice] = useState([]);
    const currentDate = new Date();
    const isFirstWeek = currentDate.getDate() <= 7;

    const role = sessionStorage.getItem('sitemanagement_role');
    const getOfficeFurnitureinvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoiceofficefurnitureandequipmentstatusbyproject/${p_id}/${month}/${year}/`);
            setOfficeFurnitureinvoice(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getOfficeFurnitureinvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='table-css-backgrounds'>
            <div className='print-center'>

                <div className="table-css-background show-on-print">
                    <div className='heading-conslt'>
                        <div className='consultant-headings'>
                            <h5>Client Name: {client}</h5>
                            <h5>Consultant (AE):</h5>
                            <h5>PAN No.:</h5>
                        </div>
                        <div className='consultant-headings'>
                            <h5>Date:{getMonthName(month)}-{year}</h5>
                            <h5>Invoice Number: {invoice_num}</h5>
                        </div>
                    </div>
                    <div className='heads-div'>
                        <h5>7. Office Furniture and Equipment(Rental)<br /></h5>
                        {role === "Admin" ?
                            <>
                                <div className='hide-on-print'>
                                    <OfficeFurnitureInvoice p_id={p_id} invoice_id={invoice_id} getOfficeFurnitureinvoice={getOfficeFurnitureinvoice} month={month} year={year} />
                                </div>
                            </>
                            :
                            <>
                                {isFirstWeek ? (
                                    <div className='hide-on-print'>
                                        <OfficeFurnitureInvoice p_id={p_id} invoice_id={invoice_id} getOfficeFurnitureinvoice={getOfficeFurnitureinvoice} month={month} year={year} />
                                    </div>
                                )
                                    :
                                    <>
                                        <div className='hide-on-print'>
                                            Take Approval for Add Invoice
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                    <div className='flex-row justify-center'>
                        <table className=" nested-table-lightblue">
                            <thead>
                                <tr className='theading'>
                                    <th rowSpan="2">S.No</th>
                                    {/* <th rowSpan="2">Description</th>₹ */}
                                    <th colSpan="3" className='table-heading-text text-center'>As per Contract</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Previous Invoice</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Current Invoice</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Cumulative Amount</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Remaining Amount</th>
                                    <th rowSpan="2" className='table-heading-text text-center hide-on-print'>Action</th>
                                </tr>
                                <tr className='nested-heading'>
                                    <th className='text-heading-text'>Period (Month)</th>
                                    <th className='text-heading-text'>Rate</th>
                                    <th className='text-heading-text'>Total Amount</th>
                                    <th className='text-heading-text'>Month</th>
                                    <th className='text-heading-text'>Amount</th>
                                    <th className='text-heading-text'>Month</th>
                                    <th className='text-heading-text'>Amount</th>
                                    <th className='text-heading-text'>Month</th>
                                    <th className='text-heading-text'>Amount</th>
                                    <th className='text-heading-text'>Month</th>
                                    <th className='text-heading-text'>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {officeFurnitureinvoice.map((item, index) => (

                                    <tr className='tbody' key={item.id}>
                                        <td className='table-body text-end'>{index + 1}</td>
                                        {/* <td className='table-body text-end'><div className="whitespace-normal">{item.officefurniture_details.description}</div></td> */}
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.officefurniture_details.num_of_months))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.officefurniture_details.rate_per_month))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.officefurniture_details.rate_per_month) * Number(item.officefurniture_details.num_of_months))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_total_previous))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_cumulative_total))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_remaining))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.remaining_amount))}</td>
                                        <td className="table-body text-center hide-on-print">
                                            {/* <UpdateFurnitureInvoice i={item} p_id={p_id} invoice_id={invoice_id} getOfficeFurnitureinvoice={getOfficeFurnitureinvoice} /> */}
                                            <DeleteFurnitureInvoice i={item} getOfficeFurnitureinvoice={getOfficeFurnitureinvoice} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr className='tbody'>

                                    <td className="total-label">Total:</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.officefurniture_details.num_of_months)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.officefurniture_details.rate_per_month)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.officefurniture_details.rate_per_month) * Number(item.officefurniture_details.num_of_months)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.actual_month_total_previous)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.previous_invoice_total)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.actual_month)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.current_invoice_amount)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.actual_month_cumulative_total)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.cumulative_invoice_total)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.actual_month_remaining)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(officeFurnitureinvoice, (item) => Number(item.remaining_amount)))}</td>
                                    <td className="table-body text-end hide-on-print"></td>

                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    );
};

const ReportsPrintt = ({ client, p_id, invoice_id, month, year, invoice_num }) => {

    const currentDate = new Date();
    const isFirstWeek = currentDate.getDate() <= 7;
    const [reportsPrinttinvoice, setReportsPrinttinvoice] = useState([]);

    const role = sessionStorage.getItem('sitemanagement_role');

    const getReportsPrinttinvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoicereportsanddocprintstatusbyproject/${p_id}/${month}/${year}/`);
            setReportsPrinttinvoice(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getReportsPrinttinvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className='table-css-backgrounds'>
            <div className='print-center'>

                <div className="table-css-background show-on-print">
                    <div className='heading-conslt'>
                        <div className='consultant-headings'>
                            <h5>Client Name:{client}</h5>
                            <h5>Consultant (AE):</h5>
                            <h5>PAN No.:</h5>
                        </div>
                        <div className='consultant-headings'>
                            <h5>Date:{getMonthName(month)}-{year}</h5>
                            <h5>Invoice Number: {invoice_num}</h5>
                        </div>
                    </div>
                    <div className='heads-div'>
                        <h5>8-Report and Document Printing<br /></h5>
                        {role === "Admin" ?
                            <>
                                <div className="hide-on-print">
                                    <ReportsPrinttInvoice p_id={p_id} invoice_id={invoice_id} getReportsPrinttinvoice={getReportsPrinttinvoice} month={month} year={year} />
                                </div>
                            </>
                            :
                            <>
                                {isFirstWeek ? (
                                    <div className="hide-on-print">
                                        <ReportsPrinttInvoice p_id={p_id} invoice_id={invoice_id} getReportsPrinttinvoice={getReportsPrinttinvoice} month={month} year={year} />
                                    </div>
                                )
                                    :
                                    <>
                                        <div className='hide-on-print'>
                                            Take Approval for Add Invoice
                                        </div>
                                    </>
                                }
                            </>
                        }



                    </div>
                    <div className='flex-row '>
                        <table className=" nested-table-lightblue" style={{ width: "70%" }}>
                            <thead>
                                <tr className="theading">
                                    <th rowSpan="2">S.No</th>
                                    <th rowSpan="2">Description</th>
                                    <th colSpan="5" className='table-heading-text text-center'>Contract Amount</th>
                                    <th colSpan="2" className='table-heading-text text-center'> Previous Invoice</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Current Invoice</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Cumulative Amount</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Remaining Amount</th>
                                    <th rowSpan="2" className='table-heading-text text-center hide-on-print'>Action</th>
                                </tr>
                                <tr className="nested-heading">
                                    <th className="table-heading-text">No. of Reports</th>
                                    <th className="table-heading-text">No. of Copies <br /> per Report</th>
                                    <th className="table-heading-text">Total Copies</th>
                                    <th className="table-heading-text">Rate per <br /> Copy(Rs)</th>
                                    <th className="table-heading-text">Total Amount</th>
                                    <th className="table-heading-text">Total  Copies</th>
                                    <th className="table-heading-text">Amount</th>
                                    <th className="table-heading-text">Total Copies</th>
                                    <th className="table-heading-text">Amount</th>
                                    <th className="table-heading-text">Total Copies</th>
                                    <th className="table-heading-text">Amount</th>
                                    <th className="table-heading-text">Total Copies</th>
                                    <th className="table-heading-text">Amount</th>


                                </tr>
                            </thead>
                            <tbody>
                                {reportsPrinttinvoice.map((item, index) => (

                                    <tr className="tbody" key={item.id}>
                                        <td className="table-body">{index + 1}</td>
                                        <td className="table-body"><div className='whitespace-normal'>{item.reportanddoc_details.description}</div></td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.reportanddoc_details.num_of_reports))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.reportanddoc_details.num_of_copies_per_report))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.reportanddoc_details.num_of_reports) * Number(item.reportanddoc_details.num_of_copies_per_report))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.reportanddoc_details.rate_per_copy))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.reportanddoc_details.rate_per_copy) * Number(item.reportanddoc_details.num_of_reports) * Number(item.reportanddoc_details.num_of_copies_per_report))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_total_previous))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_cumulative_total))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_remaining))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.remaining_amount))}</td>
                                        <td className='flex-row hide-on-print'>
                                            <UpdateReportsPrinttInvoice i={item} p_id={p_id} invoice_id={invoice_id} getReportsPrinttinvoice={getReportsPrinttinvoice} />
                                            <DeleteReportsPrinttInvoice i={item} getReportsPrinttinvoice={getReportsPrinttinvoice} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr className="tbody">
                                    <td></td>
                                    <td className="total-label">Total:</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.reportanddoc_details.num_of_reports)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.reportanddoc_details.num_of_copies_per_report)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.reportanddoc_details.num_of_reports) * Number(item.reportanddoc_details.num_of_copies_per_report)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.reportanddoc_details.rate_per_copy)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.reportanddoc_details.rate_per_copy) * Number(item.reportanddoc_details.num_of_reports) * Number(item.reportanddoc_details.num_of_copies_per_report)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.actual_month_total_previous)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.previous_invoice_total)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.actual_month)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.current_invoice_amount)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.actual_month_cumulative_total)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.cumulative_invoice_total)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.actual_month_remaining)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(reportsPrinttinvoice, (item) => Number(item.remaining_amount)))}</td>
                                    <td className="hide-on-print"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    );
};

const RoadSurvey = ({ client, p_id, invoice_id, year, month, invoice_num }) => {

    const [roadSurveyinvoice, setRoadSurveyinvoice] = useState([]);

    const role = sessionStorage.getItem('sitemanagement_role');

    const currentDate = new Date();
    const isFirstWeek = currentDate.getDate() <= 7;

    const getRoadSurveyinvoice = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoiceroadsurveyequipmentStatusbyproject/${p_id}/${month}/${year}/`);
            setRoadSurveyinvoice(res.data);
        } catch (err) { }
    };

    useEffect(() => {

        // getdocument();
        getRoadSurveyinvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='table-css-backgrounds'>
            <div className='print-center'>

                <div className="table-css-background show-on-print">
                    <div className='heading-conslt'>
                        <div className='consultant-headings'>
                            <h5>Client Name: {client}</h5>
                            <h5>Consultant (AE):</h5>
                            <h5>PAN No.:</h5>
                        </div>
                        <div className='consultant-headings'>
                            <h5>Date:{getMonthName(month)}-{year}</h5>
                            <h5>Invoice Number: {invoice_num}</h5>
                        </div>
                    </div>
                    <div className='heads-div'>
                        <h5>9- Road Survey Equipment <br /></h5>
                        {role === "Admin" ?
                            <>
                                <div className="hide-on-print">
                                    <RoadSurveyEquipmentInvoice p_id={p_id} invoice_id={invoice_id} getRoadSurveyinvoice={getRoadSurveyinvoice} month={month} year={year} />
                                </div>
                            </> :
                            <>
                                {isFirstWeek ? (
                                    <div className="hide-on-print">
                                        <RoadSurveyEquipmentInvoice p_id={p_id} invoice_id={invoice_id} getRoadSurveyinvoice={getRoadSurveyinvoice} month={month} year={year} />
                                    </div>
                                )
                                    :
                                    <>
                                        <div className='hide-on-print'>
                                            Take Approval for Add Invoice
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                    <div className='flex-row justify-center'>
                        <table className=" nested-table-lightblue">
                            <thead>
                                <tr className="theading">
                                    <th className='text-heading-text' rowSpan="2">S.No</th>
                                    <th className='text-heading-text' rowSpan="2">Item</th>
                                    <th colSpan="4" className='table-heading-text text-center'>Contract Amount</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Previous Invoice</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Current Invoice</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Cumulative Amount</th>
                                    <th colSpan="2" className='table-heading-text text-center'>Remaining Amount</th>
                                    <th rowSpan="2" className='table-heading-text text-center hide-on-print'>Action</th>
                                </tr>
                                <tr className='nested-heading'>
                                    <th className='text-heading-text'>KM</th>
                                    <th className='text-heading-text'>Rate Per KM</th>
                                    <th className='text-heading-text'>No. of times survey to be concluded</th>
                                    <th className='text-heading-text'>Total Amount</th>
                                    <th className='text-heading-text'>No. of times survey to be concluded</th>
                                    <th className='text-heading-text'>Amount</th>
                                    <th className='text-heading-text'>Month</th>
                                    <th className='text-heading-text'>Amount</th>
                                    <th className='text-heading-text'>No. of times survey to be concluded</th>
                                    <th className='text-heading-text'>Amount</th>
                                    <th className='text-heading-text'>No. of times survey to be concluded</th>
                                    <th className='text-heading-text'>Amount</th>
                                </tr>

                            </thead>
                            <tbody>
                                {roadSurveyinvoice.map((item, index) => (

                                    <tr className="tbody" key={item.id}>
                                        <td className="table-body" >{index + 1}</td>
                                        <td className="table-body"> {item.roadsurvey_details.description}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.roadsurvey_details.KM))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.roadsurvey_details.rate_per_km_times))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.roadsurvey_details.num_of_times_survey_concluded))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.roadsurvey_details.KM) * Number(item.roadsurvey_details.rate_per_km_times) * Number(item.roadsurvey_details.num_of_times_survey_concluded))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_total_previous))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.previous_invoice_total))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.current_invoice_amount))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_cumulative_total))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.cumulative_invoice_total))}</td>
                                        <td className='table-body text-end'>{formatCurrencyIndian(Number(item.actual_month_remaining))}</td>
                                        <td className='table-body text-end'>₹{formatCurrencyIndian(Number(item.remaining_amount))}</td>

                                        <td className='table-body flex-row hide-on-print'>
                                            <UpdateRoadSurveyEquipmentInvoice i={item} p_id={p_id} invoice_id={invoice_id} getRoadSurveyinvoice={getRoadSurveyinvoice} />
                                            <DeleteRoadSurveyEquipmentInvoice i={item} getRoadSurveyinvoice={getRoadSurveyinvoice} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr className="tbody">

                                    <td></td>
                                    <td className="total-label">Total:</td>
                                    <td className='table-body text-end'>
                                        {formatCurrencyIndian(
                                            calculateTotal(roadSurveyinvoice, (item) => Number(item.roadsurvey_details.KM))
                                                .toFixed(2) // Ensure two decimal places
                                        )}
                                    </td>

                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.roadsurvey_details.rate_per_km_times)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.roadsurvey_details.num_of_times_survey_concluded)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.roadsurvey_details.KM) * Number(item.roadsurvey_details.rate_per_km_times) * Number(item.roadsurvey_details.num_of_times_survey_concluded)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.actual_month_total_previous)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.previous_invoice_total)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.actual_month)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.current_invoice_amount)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.actual_month_cumulative_total)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.cumulative_invoice_total)))}</td>
                                    <td className='table-body text-end'>{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.actual_month_remaining)))}</td>
                                    <td className='table-body text-end'>₹{formatCurrencyIndian(calculateTotal(roadSurveyinvoice, (item) => Number(item.remaining_amount)))}</td>
                                    <td className="hide-on-print"></td>

                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    );
};

const ConsultancyInvoiceContingency = ({ client, p_id, invoice_id, year, month, invoice_num }) => {

    const role = sessionStorage.getItem('sitemanagement_role');
    const [projectContingencies, setProjectContingencies] = useState(null);
    // const [projectdata, setProjectdata] = useState([]);
    const getProjectContingencies = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/boqcontingenciesbyproject/${p_id}/`
            );
            setProjectContingencies(res.data);
        } catch (err) {
            console.error("Error fetching consultant invoice data:", err);
        }
    };
    const [contigency, setContigency] = useState([]);

    const getContigency = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/invoicecontingenciesbyproject/${p_id}/`
            );
            setContigency(res.data);
        } catch (err) {
            console.error("Error fetching consultant invoice data:", err);
        }
    };

    useEffect(() => {
        getProjectContingencies();

    }, [p_id, invoice_id]);

    useEffect(() => {
        getContigency();
    }, [p_id])

    return (
        <div className="table-css-background">
            <div className='heads-div'>
                <h2>10. Contingencies</h2>
                {contigency?.length === 0 ? (
                    <div className="hide-on-print">
                        <AddContingenciesinvoice
                            p_id={p_id}
                            invoice_id={invoice_id}
                            getContingencies={getContigency}
                            dataInvoice={projectContingencies}
                            month={month}
                            year={year}
                        />
                    </div>
                ) : null}

            </div>
            <p>
                A fixed amount of Indian Rupees ONE MILLION shall be included in the Financial Proposal. The provisions of Contingency shall be operated with the specific approval from the Authority.
            </p>
            <table className="nested-table-lightblue">
                <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Month</th>
                        <th>Actual Month</th>
                        <th>Current Invoice Amount (Rs.)</th>
                        <th className="hide-on-print align-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {contigency?.map((item, index) => (
                        <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.month}</td>
                            <td>{item.actual_month}</td>
                            <td className="align-right">{formatCurrencyIndian(item.current_invoice_amount)}</td>
                            <td className="hide-on-print flex-row justify-center">
                                {/* <UpdateContingenciestinvoice i={item} getContigency={getContigency} /> */}
                                {role === "BD" || role === "Account" ? "" :
                                    <DeleteUpdateContingenciestinvoice i={item} getContingencies={getContigency} />
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        {/* <td colSpan="3" className="total-label">Total:</td> */}
                        {/* <td className="align-right">
                            {formatCurrencyIndian(contingencies.reduce((total, item) => total + parseFloat(item.ammount || 0), 0))}
                        </td> */}
                        {/* <td className="hide-on-print"></td> */}
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};
const PrintPaymentInvoiceFormat = React.forwardRef((props, ref) => {

    const location = useLocation();
    const p_id = location.state ? location.state.p_id : "";
    const invoice_id = location.state ? location.state.invoice_id : "";
    const month = location.state ? location.state.month : "";
    const year = location.state ? location.state.year : "";
    const invoice_num = location.state ? location.state.invoice_number : "";

    return (
        <div className='printSlip-container' ref={ref} >

            <PaymentReceipt p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />


        </div>
    )
})
const ConsultancyInvoiceFormat = React.forwardRef((props, ref) => {

    const [project, setProject] = useState([]);



    const location = useLocation();
    const p_id = location.state ? location.state.p_id : "";
    const invoice_id = location.state ? location.state.invoice_id : "";
    const month = location.state ? location.state.month : "";
    const year = location.state ? location.state.year : "";
    const invoice_num = location.state ? location.state.invoice_number : "";
    const getProject = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/projectbyid/${p_id}/`);
            setProject(res.data);
        } catch (error) {

        }
    }

    useEffect(() => {
        getProject();
    }, [p_id]);

    return (
        <div className='printSlip-container' ref={ref} >
            <ConsultantInvoiceTable client={project.client_name} p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            {/* <Invoice p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} /> */}
            <ProfessionalStaff p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            {/* <SupportStaff p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} /> */}

            <Transportation p_id={p_id} client={project.client_name} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            {/* <EmployeeInvoice p_id={p_id} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num}/> */}
            <DutyTravel p_id={p_id} client={project.client_name} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            <OfficeRent p_id={p_id} client={project.client_name} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            <OfficeSupplies p_id={p_id} client={project.client_name} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            <OfficeFurniture p_id={p_id} client={project.client_name} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            <ReportsPrintt p_id={p_id} client={project.client_name} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            <RoadSurvey p_id={p_id} client={project.client_name} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
            <ConsultancyInvoiceContingency p_id={p_id} client={project.client_name} invoice_id={invoice_id} month={month} year={year} invoice_num={invoice_num} />
        </div>
    )
})


const ConsultancyInvoice = (i) => {
    const ref = useRef();
    const [isPrinting, setIsPrinting] = useState(false);

    const handleBeforePrint = () => {
        setIsPrinting(true);
    };

    const handleAfterPrint = () => {
        setIsPrinting(false);
    };


    return (

        // <div className='flex-column flex-center  slip-container'>
        <div className=''>
            <div className='printSlip-width flex-row justify-evenly button-margin'>
                < ReactToPrint
                    bodyClass="print-agreement"
                    content={() => ref.current}
                    onBeforeGetContent={handleBeforePrint}
                    onAfterPrint={handleAfterPrint}
                    trigger={() => (
                        <button className="model-button model-button-black">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
                                <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
                            </svg> Print
                        </button>
                    )}
                />
            </div >
            <ConsultancyInvoiceFormat ref={ref} isPrinting={isPrinting} />
        </div >
    );
}
const PrintPayment = (i) => {
    const ref = useRef();
    const [isPrinting, setIsPrinting] = useState(false);

    const handleBeforePrint = () => {
        setIsPrinting(true);
    };

    const handleAfterPrint = () => {
        setIsPrinting(false);
    };


    return (
        // <div>
        // <div>
        <div className='flex-column flex-center  slip-container'>
            <div className='printSlip-width flex-row justify-evenly button-margin'>
                {/* <button className="model-button  font-weight500    model-button-cancel font-size-heading" onClick={(e) => { backroute(firsttoggle, secondtoggle, accfirsttoggle) }}>Back</button> */}
                <ReactToPrint
                    bodyClass="print-agreement"
                    content={() => ref.current}
                    onBeforeGetContent={handleBeforePrint}
                    onAfterPrint={handleAfterPrint}
                    trigger={() => (
                        <button className="model-button model-button-black">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
                                <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
                            </svg> Print
                        </button>
                    )}
                />
            </div>
            <PrintPaymentInvoiceFormat ref={ref} isPrinting={isPrinting} />
        </div>
    );
}




export { ConsultancyInvoice, PrintPayment }
