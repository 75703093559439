import axios from "axios";
import React, { useEffect, useState } from "react";
import { AUTH, AUTHH, BASE_URL, IMAGE_URL } from "../../config/axios";
import { toast, ToastContainer } from "react-toastify";
import { formatDate, formattedDateLong } from "../Date";
import { Modal } from "react-bootstrap";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import { useNavigate } from "react-router-dom";
import {
    formatCurrencyIndian,
    formatDateTime,
    formatRoundoff2D,
    handleErrorToast,
    ViewRequestRemarks,
    ViewRequestStatus,
    getMonthName,
} from "../CustomFunctions";
import usePermission from "../../config/permissions";
import {
    CheckGreen,
    WarnYellow,
    Eye,
    Filter,
    Office,
    CrossRed,
    Attachment,
    Employee,
} from "../AllSvg";
import { Projects } from "../FilterDropdown";
import {
    ApproveRequestVehicleBillingChecker,
    RejectRequestVehicleBillingChecker,

    ApproveRequestVehicleBillingAuthority,
    RejectRequestVehicleBillingAuthority,

    ApproveRequestVehicleBillingAccStage1,
    RejectRequestVehicleBillingAccStage1,
    ApprovedVehicleBillingAdminC1,
    ApprovedVehicleBillingAdminC2,
    ApprovedVehicleBillingAdminC3,
    RejectVehicleBillingAdminC1,
    RejectVehicleBillingAdminC2,
    RejectVehicleBillingAdminC3,

    ApproveRequestVehicleBillingAccStage2,
    RejectRequestVehicleBillingAccStage2,
} from "./VehicleBillingActionButton";

const RequestVehicleBillingChecker = () => {
    const { PERMISSION_AUTHORITY } = usePermission();
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [officeBillings, setOfficeBillings] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("sitemanagement_project_sub_company") || null}`
    );

    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getofficebillingdata = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/vehiclerentbillingAPIBycheckerfilter/${selectedStatus}/${fromdate}/${selectedProject}/`
            );
            setOfficeBillings(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getofficebillingdata();
        getSubCompany();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    //! *********** Employee Details Api End ***********

    return (
        <>
            <Dashboardnavbar
                name={"Vehicle Billing Checker"}
                url="Vehicle Billing Checker"
            />
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading">
                    {" "}
                </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) =>
                                        setFromDate(e.target.value === "" ? "null" : e.target.value)
                                    }
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        {/* // <div className="field-cont-div">
                        //     <div className="date-uppercase ">
                        //         <input
                        //             className="attendance-input-field width-10vw   date-field date-field-filter"
                        //             placeholder="From Date"
                        //             type="date"
                        //             value={todate}
                        //             onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                        //         />
                        //     </div>
                        //     <hr className="field-cont-hr" />
                        // </div> */}
                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Office />
                            <select
                                className="attendance-input-field width-10vw date-field"
                                type="text"
                                value={selectedDivision}
                                onChange={(e) => setDivision(e.target.value)}
                            >
                                <option value="null">All Sub Companies</option>
                                {SubCompany.sort((a, b) => a.title - b.title).map(
                                    (i, index) => (
                                        <option value={i.id}>{i.title}</option>
                                    )
                                )}
                                ;
                            </select>

                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Projects
                                toggleProject={selectedProject}
                                setToggleProject={setProject}
                                subcompany={selectedDivision}
                            />
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background scroll-container-table">
                    {/* <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Expense</div>
                        <div className="flex-row">
                        </div>
                    </div> */}
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-left-sr">S.No.</th>
                                <th className="table-heading-text">Project Name</th>
                                <th className="table-heading-text">Vehicle Name</th>
                                <th className="table-heading-text">Vehicle Number</th>
                                <th className="table-heading-text">Date</th>
                                <th className="table-heading-text">Days</th>
                                <th className="table-heading-text">Months</th>
                                <th className="table-heading-text">Year</th>
                                <th className="table-heading-text">Fixed Kilometer</th>
                                <th className="table-heading-text">Fixed Bill Amount</th>
                                <th className="table-heading-text text-center ">
                                    Extra Amount Per KM <br /> After Fixed KM
                                </th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text">Remarks</th>
                                <th className="table-heading-text">Actions</th>
                            </tr>
                        </thead>
                        {buffer ? (
                            <div className="spinner"></div>
                        ) : (
                            <tbody>
                                {officeBillings.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="custom-table-head-td">
                                            <td className="table-body text-lefttt">{index + 1}</td>
                                            <td className="table-body">
                                                {i.vehicle_details.project_short_name}
                                            </td>
                                            <td className="table-body">{i.vehicle_name}</td>
                                            <td className="table-body">{i.vehicle_num}</td>
                                            <td className="table-body">{i.date ? formatDate(i.date) : "-"}</td>
                                            <td className="table-body">{i.days}</td>
                                            <td className="table-body">{getMonthName(i.month)}</td>
                                            <td className="table-body">{i.year}</td>
                                            <td className="table-body text-center">{i.fixed_km}</td>
                                            <td className="table-body">
                                                {formatCurrencyIndian(i.fixed_bill_amt)}
                                            </td>
                                            <td className="table-body">
                                                {formatCurrencyIndian(i.extra_amt_above_fix_km)}
                                            </td>
                                            <ViewRequestStatus i={i} />

                                            <ViewRequestRemarks i={i} />
                                            <td className="table-body">
                                                <div className="flex-row ">
                                                    <>
                                                        {i.checker_approval_status === "approved" ? (
                                                            <></>
                                                        ) : (
                                                            <ApproveRequestVehicleBillingChecker
                                                                i={i}
                                                                getofficebillingdata={getofficebillingdata}
                                                                documentlist={i.vehicle_details.documents}
                                                            />
                                                        )}
                                                    </>
                                                    <>
                                                        {
                                                            i.checker_approval_status === "rejected" ? (
                                                                <></>
                                                            ) : (
                                                                <RejectRequestVehicleBillingChecker
                                                                    i={i}
                                                                    getofficebillingdata={getofficebillingdata}
                                                                    documentlist={i.vehicle_details.documents}
                                                                />
                                                            )
                                                        }
                                                    </>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
};

const RequestVehicleBillingAuthority = () => {
    const { PERMISSION_AUTHORITY } = usePermission();
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [officeBillings, setOfficeBillings] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("sitemanagement_project_sub_company") || null}`
    );

    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getofficebillingdata = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/vehiclerentbillingAPIByauthorityfilter/${selectedStatus}/${fromdate}/${selectedProject}/`
            );
            setOfficeBillings(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getofficebillingdata();
        getSubCompany();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    //! *********** Employee Details Api End ***********

    return (
        <>
            <Dashboardnavbar
                name={"Authority Vehicle Billing"}
                url="Authority Vehicle Billing"
            />
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="content-tabs">
                <div className="attendance-history-cont">
                    <div className="attendance-heading  font-weight500    font-size-heading">
                        {" "}
                    </div>
                    <div className="attendance-subcont">
                        <div className="field-cont">
                            <div className="field-cont-div">
                                <div className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw   date-field date-field-filter"
                                        placeholder="From Date"
                                        type="date"
                                        value={fromdate}
                                        onChange={(e) =>
                                            setFromDate(
                                                e.target.value === "" ? "null" : e.target.value
                                            )
                                        }
                                    />
                                </div>

                                <hr className="field-cont-hr" />
                            </div>

                            {/* // <div className="field-cont-div">
                        //     <div className="date-uppercase ">
                        //         <input
                        //             className="attendance-input-field width-10vw   date-field date-field-filter"
                        //             placeholder="From Date"
                        //             type="date"
                        //             value={todate}
                        //             onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                        //         />
                        //     </div>
                        //     <hr className="field-cont-hr" />
                        // </div> */}
                            <div className="field-cont-div">
                                <Filter />
                                <select
                                    className="attendance-input-field width-10vw   date-field"
                                    type="text"
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e.target.value)}
                                >
                                    <option value="null">All</option>

                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                                <hr className="field-cont-hr" />
                            </div>
                            <div className="field-cont-div">
                                <Office />
                                <select
                                    className="attendance-input-field width-10vw date-field"
                                    type="text"
                                    value={selectedDivision}
                                    onChange={(e) => setDivision(e.target.value)}
                                >
                                    <option value="null">All Sub Companies</option>
                                    {SubCompany.sort((a, b) => a.title - b.title).map(
                                        (i, index) => (
                                            <option value={i.id}>{i.title}</option>
                                        )
                                    )}
                                    ;
                                </select>

                                <hr className="field-cont-hr" />
                            </div>
                            <div className="field-cont-div">
                                <Projects
                                    toggleProject={selectedProject}
                                    setToggleProject={setProject}
                                    subcompany={selectedDivision}
                                />
                            </div>
                        </div>
                        <div className="btn-cont"></div>
                    </div>

                    <div className="table-css-white-background scroll-container-table">
                        {/* <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Expense</div>
                        <div className="flex-row">
                        </div>
                    </div> */}
                        <table className="table-css">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="table-heading-text text-left-sr">S.No.</th>
                                    <th className="table-heading-text">Project Name</th>
                                    <th className="table-heading-text">Vehicle Name</th>
                                    <th className="table-heading-text">Vehicle Number</th>
                                    <th className="table-heading-text">Date</th>
                                    <th className="table-heading-text">Days</th>
                                    <th className="table-heading-text">Months</th>
                                    <th className="table-heading-text">Year</th>
                                    <th className="table-heading-text">Fixed Kilometer</th>
                                    <th className="table-heading-text">Fixed Bill Amount</th>
                                    <th className="table-heading-text text-center ">
                                        Extra Amount Per KM <br /> After Fixed KM
                                    </th>
                                    <th className="table-heading-text">Status</th>
                                    <th className="table-heading-text">Remarks</th>
                                    <th className="table-heading-text">Actions</th>
                                </tr>
                            </thead>
                            {buffer ? (
                                <div className="spinner"></div>
                            ) : (
                                <tbody>
                                    {officeBillings.map((i, index) => (
                                        <React.Fragment key={index}>
                                            <tr className="custom-table-head-td">
                                                <td className="table-body text-lefttt">{index + 1}</td>
                                                <td className="table-body">
                                                    {i.vehicle_details.project_short_name}
                                                </td>
                                                <td className="table-body">{i.vehicle_name}</td>
                                                <td className="table-body">{i.vehicle_num}</td>
                                                <td className="table-body">{i.date ? formatDate(i.date) : "-"}</td>
                                                <td className="table-body">{i.days}</td>
                                                <td className="table-body">{getMonthName(i.month)}</td>
                                                <td className="table-body">{i.year}</td>
                                                <td className="table-body text-center">{i.fixed_km}</td>
                                                <td className="table-body">
                                                    {formatCurrencyIndian(i.fixed_bill_amt)}
                                                </td>
                                                <td className="table-body">
                                                    {formatCurrencyIndian(i.extra_amt_above_fix_km)}
                                                </td>
                                                <ViewRequestStatus i={i} />

                                                <ViewRequestRemarks i={i} />
                                                <td className="table-body">
                                                    <div className="flex-row ">
                                                        <>
                                                            {i.authority_approval_status === "approved" ? (
                                                                <></>
                                                            ) : (
                                                                <ApproveRequestVehicleBillingAuthority
                                                                    i={i}
                                                                    getofficebillingdata={getofficebillingdata}
                                                                    documentlist={i.vehicle_details.documents}
                                                                />
                                                            )}
                                                        </>
                                                        <>
                                                            {
                                                                i.authority_approval_status === "rejected" ? (
                                                                    <></>
                                                                ) : (
                                                                    <RejectRequestVehicleBillingAuthority
                                                                        i={i}
                                                                        getofficebillingdata={getofficebillingdata}
                                                                        documentlist={i.vehicle_details.documents}
                                                                    />
                                                                )
                                                            }
                                                        </>
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

const RequestVehicleBillingAdmin = () => {
    const { PERMISSION_AUTHORITY } = usePermission();
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [officeBillings, setOfficeBillings] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("sitemanagement_project_sub_company") || null}`
    );

    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getofficebillingdata = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/vehiclerentbillingAPIByadminfilter/${selectedStatus}/${fromdate}/${selectedProject}/`
            );
            setOfficeBillings(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getofficebillingdata();
        getSubCompany();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    //! *********** Employee Details Api End ***********

    return (
        <>
            <Dashboardnavbar
                name={"Admin Vehicle Billing"}
                url="Admin Vehicle Billing"
            />
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="content-tabs">
                <div className="attendance-history-cont">
                    <div className="attendance-heading  font-weight500    font-size-heading">
                        {" "}
                    </div>
                    <div className="attendance-subcont">
                        <div className="field-cont">
                            <div className="field-cont-div">
                                <div className="date-uppercase ">
                                    <input
                                        className="attendance-input-field width-10vw   date-field date-field-filter"
                                        placeholder="From Date"
                                        type="date"
                                        value={fromdate}
                                        onChange={(e) =>
                                            setFromDate(
                                                e.target.value === "" ? "null" : e.target.value
                                            )
                                        }
                                    />
                                </div>

                                <hr className="field-cont-hr" />
                            </div>

                            {/* // <div className="field-cont-div">
                        //     <div className="date-uppercase ">
                        //         <input
                        //             className="attendance-input-field width-10vw   date-field date-field-filter"
                        //             placeholder="From Date"
                        //             type="date"
                        //             value={todate}
                        //             onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                        //         />
                        //     </div>
                        //     <hr className="field-cont-hr" />
                        // </div> */}
                            <div className="field-cont-div">
                                <Filter />
                                <select
                                    className="attendance-input-field width-10vw   date-field"
                                    type="text"
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e.target.value)}
                                >
                                    <option value="null">All</option>

                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                                <hr className="field-cont-hr" />
                            </div>
                            <div className="field-cont-div">
                                <Office />
                                <select
                                    className="attendance-input-field width-10vw date-field"
                                    type="text"
                                    value={selectedDivision}
                                    onChange={(e) => setDivision(e.target.value)}
                                >
                                    <option value="null">All Sub Companies</option>
                                    {SubCompany.sort((a, b) => a.title - b.title).map(
                                        (i, index) => (
                                            <option value={i.id}>{i.title}</option>
                                        )
                                    )}
                                    ;
                                </select>

                                <hr className="field-cont-hr" />
                            </div>
                            <div className="field-cont-div">
                                <Projects
                                    toggleProject={selectedProject}
                                    setToggleProject={setProject}
                                    subcompany={selectedDivision}
                                />
                            </div>
                        </div>
                        <div className="btn-cont"></div>
                    </div>

                    <div className="table-css-white-background scroll-container-table">
                        <table className="table-css">
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="table-heading-text text-left-sr">S.No.</th>
                                    <th className="table-heading-text">Project Name</th>
                                    <th className="table-heading-text">Vehicle Name</th>
                                    <th className="table-heading-text">Vehicle Number</th>
                                    <th className="table-heading-text">Date</th>
                                    <th className="table-heading-text">Days</th>
                                    <th className="table-heading-text">Months</th>
                                    <th className="table-heading-text">Year</th>
                                    <th className="table-heading-text">Fixed Kilometer</th>
                                    <th className="table-heading-text">Fixed Bill Amount</th>
                                    <th className="table-heading-text text-center ">
                                        Extra Amount Per KM <br /> After Fixed KM
                                    </th>
                                    <th className="table-heading-text">Status</th>
                                    <th className="table-heading-text">Remarks</th>
                                    <th className="table-heading-text">Actions</th>
                                </tr>
                            </thead>
                            {buffer ? (
                                <div className="spinner"></div>
                            ) : (
                                <tbody>
                                    {officeBillings.map((i, index) => (
                                        <React.Fragment key={index}>
                                            <tr className="custom-table-head-td">
                                                <td className="table-body text-lefttt">{index + 1}</td>
                                                <td className="table-body">
                                                    {i.vehicle_details.project_short_name}
                                                </td>
                                                <td className="table-body">{i.vehicle_name}</td>
                                                <td className="table-body">{i.vehicle_num}</td>
                                                <td className="table-body">{i.date ? formatDate(i.date) : "-"}</td>
                                                <td className="table-body">{i.days}</td>
                                                <td className="table-body">{getMonthName(i.month)}</td>
                                                <td className="table-body">{i.year}</td>
                                                <td className="table-body text-center">{i.fixed_km}</td>
                                                <td className="table-body">
                                                    {formatCurrencyIndian(i.fixed_bill_amt)}
                                                </td>
                                                <td className="table-body">
                                                    {formatCurrencyIndian(i.extra_amt_above_fix_km)}
                                                </td>
                                                <ViewRequestStatus i={i} />

                                                <ViewRequestRemarks i={i} />
                                                <td className="table-body">
                                                    <div className="flex-row " style={{ justifyContent: 'center' }}>
                                                        {sessionStorage.getItem('email') === 'manoj.rathi@saptagon.in' ?
                                                            <div className='flex-row'>
                                                                {i.admin_approval_status_c1 === 'approved' ? null :
                                                                    <ApprovedVehicleBillingAdminC1
                                                                        i={i}
                                                                        getofficebillingdata={getofficebillingdata}
                                                                        documentlist={i.office_rent.documents}
                                                                    />}
                                                                {i.admin_approval_status_c1 === 'rejected' ? null :
                                                                    <RejectVehicleBillingAdminC1
                                                                        i={i}
                                                                        getofficebillingdata={getofficebillingdata}
                                                                    />}
                                                            </div>

                                                            : ''}
                                                        {sessionStorage.getItem('email') === 'megha.garg@g-eng.in' || sessionStorage.getItem('email') === 'abhishekprakash489@gmail.com' ?
                                                            i.admin_approval_status_c2 === 'approved' ?
                                                                <div className='flex-row'>
                                                                    {/* {i.admin_approval_status_c3 === 'approved' ? null :
                                                                        <ApprovedVehicleBillingAdminC3
                                                                            i={i}
                                                                            getofficebillingdata={getofficebillingdata}
                                                                        />}
                                                                    {i.admin_approval_status_c3 === 'approved' ? null :
                                                                        <RejectVehicleBillingAdminC3
                                                                            i={i}
                                                                            getofficebillingdata={getofficebillingdata}
                                                                        />} */}
                                                                </div>
                                                                :

                                                                <div className='flex-row'>
                                                                    {i.admin_approval_status_c2 === 'approved' ? null :
                                                                        <ApprovedVehicleBillingAdminC2
                                                                            i={i}
                                                                            getofficebillingdata={getofficebillingdata}
                                                                            documentlist={i.office_rent.documents}
                                                                        />}
                                                                    {i.admin_approval_status_c2 === 'approved' ? null :
                                                                        <RejectVehicleBillingAdminC2
                                                                            i={i}
                                                                            getofficebillingdata={getofficebillingdata}
                                                                        />}
                                                                </div>

                                                            : ''}
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

const VehicleBillingAccManagement = () => {
    const [toggleState, setToggleState] = useState(1);

    const gettitles = () => {
        switch (toggleState) {
            case 1:
                return "Vehicle Billing AccStage1";
            case 2:
                return "Vehicle Billing AccStage2";

            default:
                return "Vehicle Billing AccStage1";
        }
    };

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Dashboardnavbar name={gettitles()} url="Admin Requests Management" />
            <div className="content-tabs">
                {/* <TeamRequestManagment secondtoggle={secondtoggle} /> */}
                <>
                    <div>
                        <div className="bloc-tabs-with-white-bg">
                            <button
                                className={
                                    toggleState === 1
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(1)}
                            >
                                Vehicle Billing AccStage1
                            </button>

                            <button
                                className={
                                    toggleState === 2
                                        ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                                        : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
                                }
                                onClick={() => setToggleState(2)}
                            >
                                Vehicle Billing AccStage2
                            </button>
                        </div>

                        <div className="content-tabs-without-margin">
                            {toggleState === 1 ? <RequestVehicleBillingAccStage1 /> : null}
                            {toggleState === 2 ? <RequestVehicleBillingAccStage2 /> : null}
                        </div>
                    </div>
                </>
            </div>
        </>
    );
};

const RequestVehicleBillingAccStage1 = () => {
    const { PERMISSION_AUTHORITY } = usePermission();
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [officeBillings, setOfficeBillings] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("sitemanagement_project_sub_company") || null}`
    );

    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getofficebillingdata = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/vehiclerentbillingAPIByaccountstatusAfilter/${selectedStatus}/${fromdate}/${selectedProject}/`
            );
            setOfficeBillings(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getofficebillingdata();
        getSubCompany();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    //! *********** Employee Details Api End ***********

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading">
                    {" "}
                </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) =>
                                        setFromDate(e.target.value === "" ? "null" : e.target.value)
                                    }
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        {/* // <div className="field-cont-div">
                        //     <div className="date-uppercase ">
                        //         <input
                        //             className="attendance-input-field width-10vw   date-field date-field-filter"
                        //             placeholder="From Date"
                        //             type="date"
                        //             value={todate}
                        //             onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                        //         />
                        //     </div>
                        //     <hr className="field-cont-hr" />
                        // </div> */}
                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Office />
                            <select
                                className="attendance-input-field width-10vw date-field"
                                type="text"
                                value={selectedDivision}
                                onChange={(e) => setDivision(e.target.value)}
                            >
                                <option value="null">All Sub Companies</option>
                                {SubCompany.sort((a, b) => a.title - b.title).map(
                                    (i, index) => (
                                        <option value={i.id}>{i.title}</option>
                                    )
                                )}
                                ;
                            </select>

                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Projects
                                toggleProject={selectedProject}
                                setToggleProject={setProject}
                                subcompany={selectedDivision}
                            />
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background scroll-container-table">
                    {/* <div className="table-heading-flex">
                        <div className="repo-heading font-weight500    font-size-heading">Expense</div>
                        <div className="flex-row">
                        </div>
                    </div> */}
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-left-sr">S.No.</th>
                                <th className="table-heading-text">Project Name</th>
                                <th className="table-heading-text">Vehicle Name</th>
                                <th className="table-heading-text">Vehicle Number</th>
                                <th className="table-heading-text">Date</th>
                                <th className="table-heading-text">Days</th>
                                <th className="table-heading-text">Months</th>
                                <th className="table-heading-text">Year</th>
                                <th className="table-heading-text">Fixed Kilometer</th>
                                <th className="table-heading-text">Fixed Bill Amount</th>
                                <th className="table-heading-text text-center ">
                                    Extra Amount Per KM <br /> After Fixed KM
                                </th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text">Remarks</th>
                                <th className="table-heading-text">Actions</th>
                            </tr>
                        </thead>
                        {buffer ? (
                            <div className="spinner"></div>
                        ) : (
                            <tbody>
                                {officeBillings.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="custom-table-head-td">
                                            <td className="table-body text-lefttt">{index + 1}</td>
                                            <td className="table-body">
                                                {i.vehicle_details.project_short_name}
                                            </td>
                                            <td className="table-body">{i.vehicle_name}</td>
                                            <td className="table-body">{i.vehicle_num}</td>
                                            <td className="table-body">{i.date ? formatDate(i.date) : "-"}</td>
                                            <td className="table-body">{i.days}</td>
                                            <td className="table-body">{getMonthName(i.month)}</td>
                                            <td className="table-body">{i.year}</td>
                                            <td className="table-body text-center">{i.fixed_km}</td>
                                            <td className="table-body">
                                                {formatCurrencyIndian(i.fixed_bill_amt)}
                                            </td>
                                            <td className="table-body">
                                                {formatCurrencyIndian(i.extra_amt_above_fix_km)}
                                            </td>
                                            <ViewRequestStatus i={i} />

                                            <ViewRequestRemarks i={i} />
                                            <td className="table-body">
                                                <div className="flex-row ">
                                                    <>
                                                        {i.account_status_a === "approved" ? (
                                                            <></>
                                                        ) : (
                                                            <ApproveRequestVehicleBillingAccStage1
                                                                i={i}
                                                                getofficebillingdata={getofficebillingdata}
                                                                documentlist={i.vehicle_details.documents}
                                                            />
                                                        )}
                                                    </>
                                                    <>

                                                        {i.account_status_a === "rejected" ? (
                                                            <></>
                                                        ) : (
                                                            <RejectRequestVehicleBillingAccStage1
                                                                i={i}
                                                                getofficebillingdata={getofficebillingdata}
                                                                documentlist={i.vehicle_details.documents}
                                                            />
                                                        )}

                                                    </>
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
};

const RequestVehicleBillingAccStage2 = () => {
    const { PERMISSION_AUTHORITY } = usePermission();
    // *********** Employee Details Api Start ***********
    const [allemployee, setAllemployee] = useState([]);
    const [officeBillings, setOfficeBillings] = useState([]);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedProject, setProject] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");

    const [selectedDivision, setDivision] = useState(
        `${sessionStorage.getItem("sitemanagement_project_sub_company") || null}`
    );

    const [SubCompany, setSubCompany] = useState([]);

    const getSubCompany = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompany(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    //! ******************************API start***************************//
    const getofficebillingdata = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/vehiclerentbillingAPIByaccountstatusBfilter/${selectedStatus}/${fromdate}/${selectedProject}/`
            );
            setOfficeBillings(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getofficebillingdata();
        getSubCompany();
    }, [fromdate, todate, selectedStatus, selectedProject]);

    const getEmpcode = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/sitemanagement-fromemp/`
            );
            setAllemployee(res.data);
        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);
    //! *********** Employee Details Api End ***********

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading">
                    {" "}
                </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="field-cont-div">
                            <div className="date-uppercase ">
                                <input
                                    className="attendance-input-field width-10vw   date-field date-field-filter"
                                    placeholder="From Date"
                                    type="date"
                                    value={fromdate}
                                    onChange={(e) =>
                                        setFromDate(e.target.value === "" ? "null" : e.target.value)
                                    }
                                />
                            </div>

                            <hr className="field-cont-hr" />
                        </div>

                        {/* // <div className="field-cont-div">
                        //     <div className="date-uppercase ">
                        //         <input
                        //             className="attendance-input-field width-10vw   date-field date-field-filter"
                        //             placeholder="From Date"
                        //             type="date"
                        //             value={todate}
                        //             onChange={(e) => setToDate(e.target.value === "" ? "null" : e.target.value)}
                        //         />
                        //     </div>
                        //     <hr className="field-cont-hr" />
                        // </div> */}
                        <div className="field-cont-div">
                            <Filter />
                            <select
                                className="attendance-input-field width-10vw   date-field"
                                type="text"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="null">All</option>

                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Office />
                            <select
                                className="attendance-input-field width-10vw date-field"
                                type="text"
                                value={selectedDivision}
                                onChange={(e) => setDivision(e.target.value)}
                            >
                                <option value="null">All Sub Companies</option>
                                {SubCompany.sort((a, b) => a.title - b.title).map(
                                    (i, index) => (
                                        <option value={i.id}>{i.title}</option>
                                    )
                                )}
                                ;
                            </select>

                            <hr className="field-cont-hr" />
                        </div>
                        <div className="field-cont-div">
                            <Projects
                                toggleProject={selectedProject}
                                setToggleProject={setProject}
                                subcompany={selectedDivision}
                            />
                        </div>
                    </div>
                    <div className="btn-cont"></div>
                </div>

                <div className="table-css-white-background scroll-container-table">
                    <table className="table-css">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-left-sr">S.No.</th>
                                <th className="table-heading-text">Project Name</th>
                                <th className="table-heading-text">Vehicle Name</th>
                                <th className="table-heading-text">Vehicle Number</th>
                                <th className="table-heading-text">Days</th>
                                <th className="table-heading-text">Months</th>
                                <th className="table-heading-text">Year</th>
                                <th className="table-heading-text">Fixed Kilometer</th>
                                <th className="table-heading-text">Fixed Bill Amount</th>
                                <th className="table-heading-text text-center ">
                                    Extra Amount Per KM <br /> After Fixed KM
                                </th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text">Remarks</th>
                                <th className="table-heading-text">Actions</th>
                            </tr>
                        </thead>
                        {buffer ? (
                            <div className="spinner"></div>
                        ) : (
                            <tbody>
                                {officeBillings.map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="custom-table-head-td">
                                            <td className="table-body text-lefttt">{index + 1}</td>
                                            <td className="table-body">
                                                {i.vehicle_details.project_short_name}
                                            </td>
                                            <td className="table-body">{i.vehicle_name}</td>
                                            <td className="table-body">{i.vehicle_num}</td>
                                            <td className="table-body">{i.days}</td>
                                            <td className="table-body">{getMonthName(i.month)}</td>
                                            <td className="table-body">{i.year}</td>
                                            <td className="table-body text-center">{i.fixed_km}</td>
                                            <td className="table-body">
                                                {formatCurrencyIndian(i.fixed_bill_amt)}
                                            </td>
                                            <td className="table-body">
                                                {formatCurrencyIndian(i.extra_amt_above_fix_km)}
                                            </td>
                                            <ViewRequestStatus i={i} />

                                            <ViewRequestRemarks i={i} />
                                            <td className="table-body">
                                                <div className="flex-row ">
                                                    <>
                                                        {i.account_status_b === "approved" ? (
                                                            <></>
                                                        ) : (
                                                            <ApproveRequestVehicleBillingAccStage2
                                                                i={i}
                                                                getofficebillingdata={getofficebillingdata}
                                                                documentlist={i.vehicle_details.documents}
                                                            />
                                                        )}
                                                    </>
                                                    {/* <>

                                                        {i.account_status_b === "rejected" ? (
                                                            <></>
                                                        ) : (
                                                            <RejectRequestVehicleBillingAccStage2
                                                                i={i}
                                                                getofficebillingdata={getofficebillingdata}
                                                                documentlist={i.vehicle_details.documents}
                                                            />
                                                        )}

                                                    </> */}
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        )}
                    </table>

                </div>
            </div>
        </>
    );
};

export {
    RequestVehicleBillingChecker,
    RequestVehicleBillingAuthority,
    RequestVehicleBillingAdmin,
    VehicleBillingAccManagement,
    RequestVehicleBillingAccStage1,
    RequestVehicleBillingAccStage2,
};
