import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import { BASE_URL } from '../../../config/axios';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AddwithBlueCircle, AddwithWhiteCircle, Eye, Reciept, UpdatePencil } from '../../AllSvg';
import { ViewFile, ViewReciept } from '../../CustomFunctions';
import { AddPaymentReceipt } from './PaymentReceipts';
import { DeleteInvoice } from "../ProjectDetailsCrudButtons";

const InvoiceList = ({ project }) => {
  const [show, setShow] = useState(false);
  const [invoice, setInvoice] = useState([]);
  const handleShow = () => {
    setShow(true);
    getInvoice();
  };
  const handleClose = () => {
    setShow(false);
  };

  const getInvoice = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/siteproject/consultant-invoiceBy-projectfilter/${project.id}/null/null/`);
      const sortedInvoices = response.data.sort((a, b) => {
        if (a.year !== b.year) {
          return b.year - a.year; // Sort by year first
        }
        return b.month - a.month; // If years are the same, sort by month
      });
      setInvoice(sortedInvoices);
    } catch (error) {
      console.error(error);
    }
  };
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const navigate = useNavigate();
  const p_id = project.id;

  const ViewInvoice = (invoice_id, month, invoice_number, year) => {
    navigate(`/${urlParts[3]}/ConsultancyInvoice/${p_id}/${invoice_id}/`, { state: { invoice_id, p_id, month, year, invoice_number } });
  };

  const ViewPaymentReceipt = (invoice_id, month, invoice_number, year) => {
    navigate(`/${urlParts[3]}/PrintInvoice/${p_id}/${invoice_id}/`, { state: { invoice_id, p_id, month, year, invoice_number } });
  };

  return (
    <>
      <button
        className=""
        title="View Invoice"
        onClick={handleShow}
      >
        <Eye />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title> Invoice List </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-css-white-background">
            <table className="table-css">
              <thead>
                <tr className="custom-table-head-tr-with-less-padding">
                  <th className="table-heading-text">S.No.</th>
                  <th className="table-heading-text text-center">Invoice Number</th>
                  <th className="table-heading-text text-center">Month</th>
                  <th className="table-heading-text text-center">Year</th>
                  <th className="table-heading-text text-center">View Invoice</th>
                  <th className="table-heading-text text-end">Payment Receipt</th>
                  <th className="table-heading-text text-end">Delete Invoice</th>
                </tr>
              </thead>
              <tbody>
                {invoice.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="custom-table-head-td">
                      <td className="table-body">{index + 1}</td>
                      <td className="table-body text-center">{i.invoice_number}</td>
                      <td className="table-body text-center">{i.month}</td>
                      <td className="table-body text-center">{i.year}</td>
                      <td className="table-body text-center">
                        <button
                          onClick={() => ViewInvoice(i.id, i.month, i.invoice_number, i.year)}
                          title="View BOQ Details"
                        >
                          <Eye />
                        </button>
                      </td>
                      <td className="table-body text-center width-5vw  justify-between">
                        <div style={{ display: 'flex', gap: '20px' }}>
                          <button
                            onClick={() => ViewPaymentReceipt(i.id, i.month, i.invoice_number, i.year)}
                            title="View BOQ Details"
                            className=""
                          >
                            <Reciept />
                          </button>
                          <ViewReciept filename={i.invoice_number} filesrc={i.invoice_number} />
                        </div>
                      </td>
                      <td className="table-body text-center width-5vw  justify-between">
                        <DeleteInvoice i={i} getInvoice={getInvoice} />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvoiceList;