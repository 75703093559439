import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FRONTEND_URL } from "../config/axios";
import Applymodels from "../pages/Applymodels";
import { useNavigate } from "react-router-dom";
import { SearchBig } from "../components/AllSvg";
import { Notification } from "../components/AllSvg";
import { routingData } from "../routes/routing";
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';

const Dashboardnavbar = ({ name, url }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const data = [
    // {
    //   "id": 1001,
    //   "emp_code": 101,
    //   "message": "Your leave request has been approved.",
    //   "is_read": "Unread",
    //   "created_at": "2024-09-25T10:00:00Z",
    //   "rh_assigned": 102
    // },
    // {
    //   "id": 1001,
    //   "emp_code": 103,
    //   "message": "New company policy update available.",
    //   "is_read": "Read",
    //   "created_at": "2024-09-25T09:30:00Z",
    //   "rh_assigned": 104
    // },
    // {
    //   "id": 1002,
    //   "emp_code": 105,
    //   "message": "Reminder: Submit your timesheet by end of the day.",
    //   "is_read": "Unread",
    //   "created_at": "2024-09-24T18:45:00Z",
    //   "rh_assigned": 106
    // },
    // {
    //   "id": 1003,
    //   "emp_code": 101,
    //   "message": "Your leave request has been approved.",
    //   "is_read": "Unread",
    //   "created_at": "2024-09-25T10:00:00Z",
    //   "rh_assigned": 102
    // },
    // {
    //   "id": 1004,
    //   "emp_code": 103,
    //   "message": "New company policy update available.",
    //   "is_read": "Read",
    //   "created_at": "2024-09-25T09:30:00Z",
    //   "rh_assigned": 104
    // },
    // {
    //   "id": 1005,
    //   "emp_code": 105,
    //   "message": "Reminder: Submit your timesheet by end of the day.",
    //   "is_read": "Unread",
    //   "created_at": "2024-09-24T18:45:00Z",
    //   "rh_assigned": 106
    // }
  ];

  const fetchData = async () => {
    const allNotifications = data;
    setNotifications(allNotifications.filter((n) => n.is_read === 'Unread'));
    setUnreadCount(allNotifications.filter((n) => n.is_read === 'Unread').length);
    handleShowNotifications();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleShowNotifications = () => {
    notifications.forEach((notification) => {
      if (notification.is_read === "Unread") {
        toast(notification.message, {
          onClose: () => markAsRead(notification.emp_code)
        });
      }
    });
  };

  const markAsRead = (id) => {
    //Switch with Update API
    console.log("Marking as read for ID:", id); // Debugging line
    const updatedNotifications = notifications.map((notification) =>
      notification.id === id
        ? { ...notification, is_read: "Read" }
        : notification
    );
    console.log("Updated Notifications:", updatedNotifications);
    // setNotifications(updatedNotifications);
    setNotifications(updatedNotifications.filter((n) => n.is_read === 'Unread'));
    setUnreadCount(updatedNotifications.filter((n) => n.is_read === 'Unread').length);
    console.log("new Notifications:", notifications);

    // fetchData();
  };

  const clearAllNotifications = () => {
    // Mark all notifications as read
    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      is_read: 'Read',
    }));

    // Update the state with all notifications marked as read
    setNotifications(updatedNotifications.filter((n) => n.is_read === 'Unread')); // Should result in an empty list of unread notifications
    setUnreadCount(0); // Set unread count to 0 as all notifications are marked read
  };


  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);



  const flattenRoutes = (routes) => {
    let flatRoutes = [];

    const flatten = (routeList, parentPath = "") => {
      routeList.forEach((route) => {
        const fullPath = parentPath ? `${parentPath}/${route.path}` : route.path;
        // Remove dynamic segments (e.g., /:id or /:email)
        if (!fullPath.includes("/:")) {  // Exclude routes with dynamic segments
          // Clean the path by removing the 'admin/' prefix
          const cleanedPath = fullPath.replace(/^admin\//, '');  // Remove "admin/" from the beginning
          flatRoutes.push({ ...route, path: cleanedPath });
        }

        // Recursively flatten nested routes
        if (route.routes && Array.isArray(route.routes)) {
          flatten(route.routes, fullPath); // Handle nested routes recursively
        }
      });
    };

    flatten(routes);
    return flatRoutes;
  };


  // Effect to fetch available routes based on user role
  useEffect(() => {
    const userRole = sessionStorage.getItem("role") || "";
    const roleData = routingData.find((route) => route.role === userRole);

    if (roleData) {
      // Flatten nested routes if they exist
      if (roleData.nestedRoutes && Array.isArray(roleData.nestedRoutes)) {
        const flattenedRoutes = flattenRoutes(roleData.nestedRoutes);
        setAvailableRoutes(flattenedRoutes);
      } else {
        setAvailableRoutes([]); // No routes available if nestedRoutes is empty
      }
    } else {
      setAvailableRoutes([]); // No routes available if roleData not found
    }
  }, []);


  console.log(availableRoutes)

  // Handle input change for search
  const handleSearchChange = (e) => {
    const input = e.target.value.trim();
    setSearchInput(input);

    if (input) {
      // Filter the routes by the user input
      const filtered = availableRoutes.filter((route) =>
        (route.label || route.path)
          .toLowerCase()
          .includes(input.toLowerCase())
      );

      console.log(filtered)

      setSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };


  console.log(suggestions)

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");

  console.log(urlParts[3])

  // Handle suggestion click (navigate to selected route)
  const handleSuggestionClick = (path) => {
    setSearchInput("");
    setSuggestions([]);
    setShowSuggestions(false); // Close suggestions after selecting one
    const staticPath = path.split("/:")[0]; // Remove dynamic parameters
    navigate(`/${urlParts[3]}/${staticPath}`); // Navigate to the selected route
  };

  // Close suggestions if clicked outside
  const handleClickOutside = (e) => {
    if (!e.target.closest(".navbar-search")) {
      setShowSuggestions(false);
    }
  };

  // Listen for click outside to close suggestions
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const role = sessionStorage.getItem('sitemanagement_role');

  return (
    <div className="navbar-section-main">
      <div className="navbar-margin-left">
        <div className="navbar-subheading font-weight500 font-size-text">
          Pages / {url}
        </div>
        <div className="navbar-heading font-weight700">{name}</div>
      </div>
      <div className="navbar-subsection navbar-margin-right">
        {role !== "CertificationAccess" &&
          (
            <div className="applymodel">
              <Applymodels />
            </div>
          )
        }


        <div className="navbar-subsection-b">
          <div className="navbar-search">
            <div>
              <SearchBig />
            </div>
            <div>
              <input
                placeholder="Search"
                className="navbar-inputt font-weight400 font-size-text"
                value={searchInput}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          {showSuggestions && (
            <div className="search-suggestions-popup">
              {suggestions.length > 0 ? (
                suggestions.map((route) => (
                  <div
                    key={route.path}
                    className="search-suggestion-item"
                    onClick={() => handleSuggestionClick(route.path)}
                  >
                    {route.label || route.path}
                  </div>
                ))
              ) : (
                <div className="search-suggestion-item no-results">No results found</div>
              )}
            </div>
          )}


          <button className="" onClick={handleShow}>
            <div
              title="Notification Bell"
              className={`navbar-svg navbar-bell ${unreadCount > 0 ? 'bell-highlight' : ''}`} // Add conditional class if there are unread notifications
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="bi-box-arrow-right"
              >
                <g clipPath="url(#clip0_1297_50061)">
                  <path
                    d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z"
                    fill="#A3AED0"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1297_50061">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {unreadCount > 0 && (
                <span className="notification-count">{unreadCount}</span>
              )}
            </div>
          </button>
          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="alert-notifications width-50vw"
          >
            <Modal.Header closeButton>
              <Modal.Title>Your Notifications</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className='notification-container' >
                <div className="align-center justfy-center">
                  {notifications.length > 0 ?
                    notifications.map((notification, index) => (
                      <div key={index} className={`notification-item ${notification.is_read === 'Unread' ? 'unread' : 'read'}`} style={{ position: 'relative', padding: '10px', borderBottom: '1px solid #e0e0e0' }}>
                        <button
                          onClick={() => markAsRead(notification.id)}
                          style={{ position: 'absolute', top: '10px', right: '10px' }} // Positioning the button at the top right
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#707070"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15 9L9 15"
                              stroke="#707070"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9 9L15 15"
                              stroke="#707070"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <p><strong>Message:</strong> {notification.message}</p>
                        <p><strong>Received At:</strong> {new Date(notification.created_at).toLocaleString()}</p>
                        <p><strong>Status:</strong> {notification.is_read}</p>
                        <p><strong>RH Assigned:</strong> {notification.rh_assigned}</p>
                      </div>
                    ))
                    // : <div className='align-center justify-center ' style={{ alignSelf: 'center' }}>- No New Notifications -</div>
                    : <div className='align-center justify-center ' style={{ alignSelf: 'center' }}>- All Cleared Up 👍 -</div>
                  }

                </div>
              </div>
              {notifications.length > 0 ? <button
                className='align-center file-clear ' style={{ float: 'right' }}
                onClick={clearAllNotifications}
              >
                Clear
              </button>
                : null}
            </Modal.Body>
          </Modal>


          <div className="navbar-logo-mobile">
            <button
              title="logout"
              className="navbar-svg"
              onClick={() => {
                toast.info("Logging Out...");
                sessionStorage.clear();
                window.location = FRONTEND_URL;
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi  bi-box-arrow-right" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" fill="#A3AED0" />
                <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" fill="#A3AED0" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>



  );
};

export default Dashboardnavbar;
