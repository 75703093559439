import React, { useState } from "react";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import { BankFormat, EmployeeDetails, MonthlySalary, PayRun, PrintFormat, SalaryDeductionPolicyTable, SalaryStructureHeadingTable } from "./PayrollTables";



const ManagePayroll = () => {
  const [toggleStateTab, setToggleStateTab] = useState(1);
  const [toggleState, setToggleState] = useState(1);


  const getSelectedURL = () => {
    switch (toggleStateTab) {
      case 1:
        return "Pay Run Management";
      case 2:
        return "Pay Roll Settings Management";

      default:
        return "Pay Run Management";
    }
  };

  const getSelectedTitle = () => {
    switch (toggleState) {

      case 1:
        return "Pay Run";
      case 2:
        return "Monthly Salary";
      case 3:
        return "Employee Details";
      case 4:
        return "Bank Format";
      case 5:
        return "Salary Structure Headings";
      case 6:
        return "Salary Structure Policies";

      default:
        return "Pay Run";
    }
  };


  return (
    <>
      <div>
        <div>
          <Dashboardnavbar name={getSelectedTitle()} url={getSelectedURL()} />

          {/* Tabs 1*/}
          <div className="content-tabs bloc-tabs-with-white-bg">
            <button
              className={
                toggleStateTab === 1
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => {
                setToggleStateTab(1)
                setToggleState(1)
              }}
            >
              Pay Run
            </button>
            <button
              className={
                toggleStateTab === 2
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   white-tab"
              }
              onClick={() => {
                setToggleStateTab(2)
                setToggleState(5)
              }}
            >
              Pay Roll Settings
            </button>
          </div>

          {/* Tabs 2*/}
          {toggleStateTab === 1 ?
            < div className="bloc-tabss  wfm-margin">
              <button
                className={
                  toggleState === 1
                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs"
                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
                }
                onClick={() => setToggleState(1)}
              >
                Pay Run
              </button>
              <button
                className={
                  toggleState === 2
                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs"
                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
                }
                onClick={() => setToggleState(2)}
              >
                Monthly Salary
              </button>
              {/* <button
                className={
                  toggleState === 3
                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs"
                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
                }
                onClick={() => setToggleState(3)}
              >
                Employee Details
              </button>
              <button
                className={
                  toggleState === 4
                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs"
                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
                }
                onClick={() => setToggleState(4)}
              >
                Bank Format
              </button> */}
            </div>
            : null
          }
          {toggleStateTab === 2 ?
            < div className="bloc-tabss  wfm-margin">
              <button
                className={
                  toggleState === 5
                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs"
                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
                }
                onClick={() => setToggleState(5)}
              >
                Salary Structure Headings
              </button>
              {/* <button
                className={
                  toggleState === 6
                    ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs"
                    : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
                }
                onClick={() => setToggleState(6)}
              >
                Salary Deduction Policies
              </button> */}
            </div>
            : null
          }
        </div>

        <div className="content-tabs">
          <>
            {toggleState === 1 ? <PayRun /> : null}
            {toggleState === 2 ? <MonthlySalary /> : null}
            {toggleState === 3 ? <EmployeeDetails /> : null}
            {toggleState === 4 ? <BankFormat /> : null}
          </>
          <>
            {toggleState === 5 ? <SalaryStructureHeadingTable /> : null}
            {toggleState === 6 ? <SalaryDeductionPolicyTable /> : null}

          </>
        </div>
      </div >
    </>
  );
};

export default ManagePayroll;
