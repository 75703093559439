import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL } from '../../config/axios';

import Dashboardnavbar from '../../layout/Dashboardnavbar';
import * as XLSX from 'xlsx';
import { Modal } from 'react-bootstrap';
import { DownloadTableExcel } from 'react-export-table-to-excel';

import { formatCurrencyIndian, getMonthName, handleErrorToast, sortProjects, ViewRequestStatus, ViewRequestRemarks } from '../CustomFunctions';

import { AddwithWhiteCircle, Calender, CircularGrid, DeleteDustbin, DropdownArrow, Location, UpdatePencil, HourGlass, Filter, SearchBig, AddwithBlueCircle, DropdownArrowOption, DownloadIcon, UploadIcon, Attachment, Refresh } from '../AllSvg';

import usePermission from '../../config/permissions';
import { Projects } from '../FilterDropdown';
import { formatDate, formattedDateLong } from '../Date';
import { UploadOfficeRentBulk } from '../SiteManagement/BulkModals';

import { DocumentModel } from '../SiteManagement/ProjectDetailsCrudButtons';





const AddOfficeBillDetails = ({ getOfficeBill, projectList }) => {
    const [show, setShow] = useState(false);
    const [buffer, setBuffering] = useState(false); //buffering logic

    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

    const handleClose = () => {
        setFormData({
            project_id: "null",

            bill_date: "null",

        })
        setErrors({});
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        project_id: "null",
        bill_date: "null",

    });


    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["project_id", "bill_date"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);
        setIsSubmitted(true);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            if (value) {
                delete updatedErrors[name];
            }


            return updatedErrors;
        });
    };


    // ?  ************** Validation End **************  ! //

    const [loading, setLoading] = useState(false); //loading logic
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            setLoading(true); //loading logic
            try {
                let res = await axios.post(`${BASE_URL}/siteproject/generatevehiclebillsbyproject/`, formData);

                if (res.status === 200) {
                    await getOfficeBill();
                    handleClose();
                    toast.dismiss(loadingToastId);
                    toast.success("Bill Details Added Successfully");
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };


    return (
        <>

            <button title="Add Office Rent Bill" className="upload-svg" onClick={handleShow}>
                <AddwithWhiteCircle />
                {" "} Office Bill
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton className=" task-Tab-heading font-weight500    font-size-heading ">
                    <Modal.Title>Add Office Rent Bill</Modal.Title>
                </Modal.Header>

                <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

                <Modal.Body>
                    <div className="parent-div">
                        <div className="bdy-div">
                            <div className="flex-column form-group-selectt">
                                <label htmlFor="project" className="form-labels  font-weight500    font-size-subheading" title="Sub Company">
                                    Project<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="project"
                                    name="project_id"
                                    title="Project "
                                    value={formData.project_id}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.project_id ? "error" : inputState.project_id ? "success" : ""
                                        }`}
                                >
                                    <option value="">Select Project </option>
                                    {projectList.map(project => (<option value={project.id}>{project.site_prcode}-{project.project_short_name}</option>))};
                                </select>
                                <div className="form-group-selection-arrow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="25"
                                        fill="currentColor"
                                        class="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </div>



                                {errors.project && (
                                    <span className="error-message font-size-text ">{errors.project}</span>
                                )}
                            </div>


                            <div title="Bill Date" className="flex-column">
                                <label htmlFor="bill_date" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                                    Bill Date<span className="required">*</span>
                                </label>
                                <input
                                    id="bill_date"
                                    type="date"
                                    name="bill_date"
                                    placeholder="date"
                                    onChange={handleInputChange}
                                    value={formData.bill_date}
                                    max={today}
                                    className={`form-input ${errors.bill_date ? "error" : inputState.bill_date ? "success" : ""
                                        }`}
                                />
                                {errors.bill_date && (
                                    <span className="error-message font-size-text ">{errors.bill_date}</span>
                                )}
                            </div>




                            <div className="button-models">
                                <button className="model-button   font-weight500  model-button-cancel " type="button" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button
                                    //   type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    onClick={handleFormSubmit}
                                    disabled={loading}
                                >
                                    Add
                                </button>
                            </div>
                            <p className="error-message font-size-text">
                                {isSubmitted && Object.keys(errors).length > 0 && (
                                    Object.keys(errors).length > 5 ? (
                                        <h5 className="text-center">Please fill all mandatory fields!</h5>
                                    ) : (

                                        <h6 className="text-center">
                                            {Object.keys(errors).map((field) =>
                                                field.charAt(0).toUpperCase() + field.slice(1)
                                            ).join(', ') + ' are required!'}
                                        </h6>
                                    )
                                )}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
const DeleteOfficeRentBill = ({ i, getOfficeBill }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const handleFormSubmitDelete = async (e) => {
        e.preventDefault();
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
            let res = await axios.delete(`${BASE_URL}/siteproject/officerentbillingupdate/${i.id}/`);

            if (res.status === 200) {
                await getOfficeBill();
                handleClose();
                toast.dismiss(loadingToastId);
                toast.success("Data deleted successfully!");
            } else {
                alert(res);
            }
        } catch (err) {
            //toast Logic
            handleErrorToast(err)
        } finally {
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <>
            <button title="Delete" className="model-delete-button" onClick={handleShow}>
                <DeleteDustbin />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Office Billing Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        Are You Sure You Want to delete?
                        <div className="button-models">
                            <button className="model-button   font-weight500   model-button-cancel " onClick={handleClose}>
                                Cancel
                            </button>
                            <button
                                onClick={handleFormSubmitDelete}
                                className="model-button   font-weight500    model-button-delete"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                </Modal.Body>
            </Modal>
        </>
    );
};


const OfficeBillingDetails = (i) => {
    const [OfficeBillingDetails, setOfficeBillingDetails] = useState([]);
    const [buffer, setBuffering] = useState(false); //buffering logic
    const [project, setProject] = useState("null");
    const [search, setSearch] = useState("")
    const [projectList, setProjectList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [Office, setOffice] = useState("null");
    const [OfficeList, setOfficeList] = useState([]);


    const today = new Date();
    today.setDate(today.getDate() - 1);
    const year = today.getFullYear();
    const month = today.getMonth() + 1;

    const [filterMonth, setFilterMonth] = useState(`${month}`);
    const [filterYear, setFilterYear] = useState(`${year}`);
    const siteRole = sessionStorage.getItem("sitemanagement_role");
    const getProjects = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            // const pro = await axios.get(`${BASE_URL}/siteproject/project/`);
            const pro = await axios.get(
                sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
                    `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                    : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
                        `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                        : `${BASE_URL}/siteproject/project/`
            );
            const sortedProjects = sortProjects(pro.data, 'site_prcode');
            setProjectList(sortedProjects);

        } catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    };
    const getOfficeBillingDetails = async () => {
        setBuffering(true);
        try {
            const response = await axios.get(`${BASE_URL}/siteproject/officerentbilling/`);
            setOfficeBillingDetails(response.data);

        }
        catch (err) {
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    }
    useEffect(() => {
        getOfficeBillingDetails();

    }, []);
    useEffect(() => {
        getProjects();
        // getOffice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project, Office, filterMonth, filterYear]);

    const monthOptions = [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" }
    ];
    const getMonthName = (monthNumber) => {
        const month = monthOptions.find(m => m.id.toString() === monthNumber);
        return month ? month.name : '';
    };

    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 20; // starting 20 years ago
    const endYear = currentYear + 20; // up to 20 years in the future
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);



    const getOffice = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const veh = await axios.get(`${BASE_URL}/siteproject/officerentbilling/`);
            setOfficeList(veh.data);

        } catch (err) {
            //toast Logic
            if (err.response) {
                const errorData = err.response.data;

                if (typeof errorData.error === 'string') {
                    // Single error message
                    toast.error(`Error: ${errorData.error}`);
                } else if (typeof errorData === 'object' && errorData !== null) {
                    // Multiple error messages
                    Object.entries(errorData).forEach(([field, messages]) => {
                        messages.forEach(message => toast.error(`"${field}": ${message}`));
                    });
                } else {
                    toast.error('Error:- Failed to Process!');
                }
            } else {
                toast.error('Error processing your request.');
            }
        } finally {
            setBuffering(false);// End Buffering
        }
    }


    useEffect(() => {
        getOffice();
    }, []);


    const role = sessionStorage.getItem('role');


    console.log(OfficeBillingDetails)

    return (
        <div>
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            {role === "SiteManagement" ? ("") : (

                <div className="attendance-subcontainer margin">
                    <div className="field-cont width-100vw justify-between">
                        <div className="flex-row">
                            <div title='Month Filter' className="field-cont-div">
                                <CircularGrid />

                                <select
                                    className="attendance-input-field width-10vw   date-field"
                                    type="text"
                                    value={filterMonth}
                                    onChange={(e) => setFilterMonth(e.target.value)}
                                >
                                    <option value="">Select Month</option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>

                                <hr className="field-cont-hr" />
                            </div>

                            <div title='Year Filter' className="field-cont-div">
                                <CircularGrid />

                                <select
                                    className="attendance-input-field width-10vw   date-field"
                                    type="text"
                                    value={filterYear}
                                    onChange={(e) => setFilterYear(e.target.value)}
                                >
                                    <option value="">Select Year</option>
                                    {years.reverse().map((year) => (
                                        <option key={year} value={year}>{year}</option>
                                    ))}
                                </select>

                                <hr className="field-cont-hr" />
                            </div>

                            <Projects toggleProject={project} setToggleProject={setProject} subcompany={"null"} />

                            <div title="Office Filter" className="field-cont-div ">
                                <CircularGrid />
                                <select
                                    id="Office"
                                    name="Office"
                                    placeholder="Office"
                                    value={Office}
                                    onChange={(e) => setOffice(e.target.value)}
                                    className={`attendance-input-field width-10vw`}
                                >

                                    <option value="null">Select Office</option>
                                    {OfficeList?.sort((a, b) => a.name?.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (
                                        <option key={index} value={i.id}>{i.name}-{i.Office_num}</option>
                                    ))}
                                </select>


                            </div>
                        </div>

                        <div>
                            <AddOfficeBillDetails getOfficeBill={getOfficeBillingDetails} projectList={projectList} />
                            {/* <GenerateOfficeBillingDetails getOfficeBillingDetails={getOfficeBillingDetails} OfficeList={OfficeList} projectList={projectList} /> */}

                        </div>
                    </div>
                </div>
            )}
            <div className="table-css-white-background overflow-hidden flex-column ">
                {/* {role === "SiteManagement" ? (
            <div className='flex-row justify-end'>
              <AddOfficeBillingDetails projectid={projectID} getOfficeBillingDetails={getOfficeBillingDetails} />
            </div>
          ) : ("")
          }
  
          */}
                <div className='scroll-container-table'>
                    <table className="table-css ">
                        <thead className="table-heading">
                            <tr className="custom-table-head-tr">
                                {/* <th className='table-heading-text'>S.no.</th>
                                <th className='table-heading-text'>Project Name</th>
                                <th className='table-heading-text text-center'>Office Rent</th>
                                <th className='table-heading-text text-center'>Owner Name</th>
                                <th className='table-heading-text text-center'>Start Date</th>
                                <th className='table-heading-text text-center'>End Date</th>
                                <th className='table-heading-text text-center'>No. of Days</th>
                                <th className='table-heading-text text-center'>Month</th>
                                <th className='table-heading-text text-center'>Year</th>
                                <th className='table-heading-text text-center' >Action</th> */}
                                <th className="table-heading-text text-lefttt">S. No.</th>
                                <th className="table-heading-text">Date</th>
                                <th className="table-heading-text">Project Name</th>
                                <th className="table-heading-text">Owner Name</th>
                                <th className="table-heading-text">Days</th>
                                <th className="table-heading-text">Months</th>
                                <th className="table-heading-text">Year</th>
                                <th className="table-heading-text text-center">Office Rent Amount</th>
                                <th className="table-heading-text">Status</th>
                                <th className="table-heading-text">Remarks</th>
                                <th className="table-heading-text text-center">Actions</th>
                            </tr>
                        </thead>
                        {buffer ? (
                            <div className='spinner'></div>
                        ) : (

                            <tbody>
                                {OfficeBillingDetails.map((i, index) => (
                                    <React.Fragment key={index}>
                                        {/* <tr className='custom-table-head-td'>
                                            <td className="table-body text-left">{monthIndex + 1}.</td>
                                            <td className="table-body text-left">{index.project_short_name}</td>
                                            <td className="table-body text-center">{index.rent_amt}</td>
                                            <td className="table-body text-center">{index.owner_name}</td>
                                            <td className="table-body text-center">{index.date}</td>
                                            <td className="table-body flex-row  text-right" style={{ justifyContent: 'right' }} >
                                                <UpdateOfficeRent i={i} getRent={getRent} />
                                                <DeleteOfficeRentBill i={index} getOfficeBill={getOfficeBillingDetails} />
                                            </td>
                                            <td className="table-body text-center">{index.end_date}</td>
                                            <td className="table-body text-center">{index.days}</td>
                                            <td className="table-body text-center">{getMonthName(index.month)}</td>
                                            <td className="table-body text-center">{index.year}</td>



                                        </tr> */}
                                        <tr className="custom-table-head-td">
                                            <td className="table-body text-lefttt">{index + 1}</td>
                                            <td className="table-body">{formatDate(i.date)}</td>
                                            <td className="table-body">{i.project_short_name}</td>
                                            <td className="table-body">{i.owner_name}</td>
                                            <td className="table-body">{i.days}</td>
                                            <td className="table-body">{getMonthName(i.month)}</td>
                                            <td className="table-body">{i.year}</td>
                                            <td className="table-body">
                                                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Basic Amount</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {formatCurrencyIndian(i.rent_amt || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>TDS</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {formatCurrencyIndian(i.tds_amt || 0)} ({i.tds_percentage}%)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="table-body" style={{ paddingBottom: "5px" }}>Total Amount</td>
                                                            <td style={{ padding: "0px 4px" }}>:</td>
                                                            <td className="align-right">
                                                                {formatCurrencyIndian(i.total_amt || 0)}
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </td>

                                            <ViewRequestStatus i={i} />

                                            <ViewRequestRemarks i={i} />
                                            <td className="table-body text-center" >
                                                {/* <UpdateOfficeRent i={i} getRent={getRent} /> */}
                                                <DeleteOfficeRentBill i={index} getOfficeBill={getOfficeBillingDetails} />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </div>
    )

}

export default OfficeBillingDetails;