import React, { useEffect, useState } from "react";
import usePermission from "../../config/permissions";
import axios from "axios";
import { BASE_URL, role } from "../../config/axios";
import { ToastContainer } from "react-toastify";
import { AddConsultantAccount, UpdateSiteEmployee, UpdateSiteEmployeeDesignation } from "./WorkforceCrudButton"
import { Eye, DocumentIcon, DownloadIcon } from '../AllSvg';
import Modal from "react-bootstrap/Modal";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';



import {
    AddDepartment,
    AddSiteDepartment,
    AddSiteSubcompanys,
    DeleteSiteDepartment,
    UpdateDepartment,
    DeleteDepartment,
    DeleteSiteSubcompany,
    UpdateSiteDepartment,
    UpdateConsultantAccount,
    DeleteAccountDetails,
    UpdateSiteSubcompanyupdate,


    AddSiteEmployee, UploadSiteEmployee
    , EmployeeDetailCard


} from "./WorkforceCrudButton"

import { handleErrorToast, sortProjects } from "../CustomFunctions";
import { CircularGrid, Location, SearchBig, SearchSmall } from "../AllSvg";
import { useLocation } from "react-router-dom";
import { AddSiteAttendence } from "../Attendance";
import { Projects } from "../FilterDropdown";
import { UploadDepartment, UploadDesignation } from "../SiteManagement/BulkModals";




const SiteDesignationTable = () => {
    const [siteDepartmentList, setSiteDepartmentList] = useState([]);
    const [toggleCompany, setToggleCompany] = useState("null");
    const [buffer, setBuffering] = useState(true); //buffering logic
    const { PERMISSION_AUTHORITY } = usePermission();
    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
    const role = sessionStorage.getItem('sitemanagement_role');

    const getSiteDepartment = async () => {
        setBuffering(true); // Start Buffering
        try {
            const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSiteSubCompanyList(sub.data);

            // const res = await axios.get(`${BASE_URL}/siteproject/designation/`);
            const res = await axios.get(
                `${BASE_URL}/siteproject/designationfilterbysubcompanycount/${toggleCompany}/active/`
            );
            setSiteDepartmentList(res.data);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false); // End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        getSiteDepartment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleCompany]);

    return (
        <>
            <div className="attendance-subcontainer margin">
                <div className="field-cont flex-row justify-between" style={{ width: "100%" }}>

                    <div className="flex-row">
                        {PERMISSION_AUTHORITY ? (
                            <div className=" field-cont-div">
                                <CircularGrid />

                                <select
                                    onChange={(e) => setToggleCompany(e.target.value)}
                                    value={toggleCompany}
                                    className="attendance-input-field width-10vw "
                                >
                                    <option value="null"> Sub Company</option>
                                    {siteSubCompanyList.map((company) => (
                                        <option value={company.id}>{company.title}</option>
                                    ))}
                                    ;
                                </select>
                                <hr className="field-cont-hr" />

                            </div>
                        ) : null}
                        <div className="field-cont-div">

                            <SearchBig />

                            <input
                                placeholder="Search Designation"
                                className="attendance-input-field "
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <hr className="field-cont-hr" />

                        </div>
                    </div>
                    <div className="flex-row">
                        <AddSiteDepartment
                            getSiteDepartment={getSiteDepartment}
                            siteDepartmentList={siteDepartmentList}
                            siteSubCompanyList={siteSubCompanyList}
                        />
                        {role === "Admin" &&
                            <UploadDesignation
                                getDesignation={getSiteDepartment}
                            />
                        }
                    </div>

                </div>
            </div>
            <div className="table-css-white-background">
                <div className="table-box scroll-container-table" >
                    <table className="table-css">
                        <thead className="table-heading">
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-left-sr">Sr.no</th>
                                <th className="table-heading-text text-left">Designation</th>
                                <th className="table-heading-text text-center">Sub Company</th>
                                <th className="table-heading-text text-center">Employee Count</th>
                                <th className="table-heading-text text-center">Employee View</th>
                                <th className="table-heading-text text-right">Action</th>
                            </tr>
                        </thead>
                        {buffer ? (
                            <div className="spinner"></div>
                        ) : (
                            <tbody>
                                {siteDepartmentList
                                    ?.filter((e) =>
                                        e.title.toLowerCase().includes(searchQuery.toLowerCase())
                                    )
                                    .sort((a, b) =>
                                        a.title.localeCompare(b.title, "en", {
                                            ignorePunctuation: true,
                                        })
                                    )
                                    .map((i, index) => (
                                        <React.Fragment key={index}>

                                            <tr className="custom-table-head-td">
                                                <td className="table-body text-left-sr">{index + 1}</td>
                                                <td className="table-body text-left"><div style={{ whiteSpace: "normal" }}>{i.title}</div></td>
                                                <td className="table-body text-center">{i.sub_company_name}</td>

                                                <td className="table-body text-center">{i?.employee_count}</td>
                                                {/* <td className="table-body">View </td> */}
                                                <td className="table-body text-center">
                                                    <EmployeebySiteDesignationTable designationId={i.id} designation={i} siteSubCompanyList={siteSubCompanyList} getSiteDepartment={getSiteDepartment} />{" "}
                                                </td>
                                                <td className="table-body text-right" style={{ justifyContent: 'right' }}>
                                                    <UpdateSiteDepartment
                                                        i={i}
                                                        getSiteDepartment={getSiteDepartment}
                                                        siteDepartmentList={siteDepartmentList}
                                                        siteSubCompanyList={siteSubCompanyList}

                                                    />
                                                    {PERMISSION_AUTHORITY ? (
                                                        <DeleteSiteDepartment
                                                            i={i}
                                                            getSiteDepartment={getSiteDepartment}
                                                        />
                                                    ) : null}


                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                            </tbody>
                        )}
                    </table>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </>
    );
};

const EmployeebySiteDesignationTable = ({ designationId, designation, siteSubCompanyList, getSiteDepartment }) => {
    const [show, setShow] = useState(false);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const handleClose = () => {
        setShow(false);
        getSiteDepartment()
    }
    const handleShow = () => {
        setShow(true);
        getSiteDepartmentEmployees();
    };

    const [departmentEmployees, setDepartmentEmployees] = useState([]);

    const getSiteDepartmentEmployees = async (designation) => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/employeedetailsbydesignation/${designationId}/active/`
            );
            const getAlphaNumericParts = (code) => {
                const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
                if (match) {
                    return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
                } else {
                    return { alphaPart: "", numericPart: 0 };
                }
            };

            const sortedList = res.data.sort((a, b) => {
                const { alphaPart: alphaA, numericPart: numericA } =
                    getAlphaNumericParts(a.emp_code);
                const { alphaPart: alphaB, numericPart: numericB } =
                    getAlphaNumericParts(b.emp_code);

                if (alphaA !== alphaB) {
                    return alphaA.localeCompare(alphaB); // Sort alphabetically
                } else {
                    return numericA - numericB; // Then sort numerically
                }
            });
            setDepartmentEmployees(sortedList);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };


    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };




    return (
        <>
            <button
                className=""
                title="View Employees"
                style={{ paddingLeft: "5px", color: "white", fontSize: "14px", display: 'inline-block' }}
                onClick={handleShow}
            >
                <Eye />
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="modal-addwidth width-60vw"
            >
                <Modal.Body>
                    <>
                        {/* <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"></div>
              </div> */}
                        <div className="table-css-white-background">
                            <div className="table-heading-flex">
                                <div className="repo-heading font-weight500    font-size-heading">
                                    Employees with : <br />
                                    {designation.title}
                                </div>
                                <div className="flex-row">
                                    <div className="table-searchh">
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="11"
                                                height="12"
                                                viewBox="0 0 11 12"
                                                fill="none"
                                            >
                                                <circle
                                                    cx="5"
                                                    cy="5"
                                                    r="4.3"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                />
                                                <line
                                                    x1="10.0101"
                                                    y1="11"
                                                    x2="8"
                                                    y2="8.98995"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </div>
                                        <div>
                                            <input
                                                placeholder="Search"
                                                className="navbar-inputt font-weight400  font-size-text"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table-css">
                                <thead>
                                    <tr className="custom-table-head-tr">
                                        <th className="table-heading-text text-left-sr">ID</th>
                                        <th className="table-heading-text text-center">Emp Code</th>
                                        <th className="table-heading-text text-center"> Name </th>
                                        <th className="table-heading-text text-right"> Action </th>

                                    </tr>
                                </thead>
                                {buffer ? (
                                    <div className="spinner"></div> //buffering logic
                                ) : (
                                    <tbody>
                                        {departmentEmployees
                                            ?.filter(
                                                (e) =>
                                                    e.name
                                                        .toLowerCase()
                                                        .includes(searchQuery.toLowerCase()) ||
                                                    e.emp_code
                                                        .toString()
                                                        .includes(searchQuery.toLowerCase())
                                            )
                                            .sort((a, b) => a.emp_code - b.emp_code)
                                            .map((i, index) => (
                                                <React.Fragment key={index}>

                                                    <tr className="custom-table-head-td">
                                                        <td className="table-body text-left-sr">{index + 1}</td>
                                                        <td className="table-body text-center">{i.emp_code}</td>
                                                        <td className="table-body text-center">{i.name}</td>
                                                        <td className="table-body text-right" style={{ justifyContent: 'right' }}>
                                                            <UpdateSiteEmployeeDesignation
                                                                i={i}
                                                                getSiteDepartmentEmployees={getSiteDepartmentEmployees}
                                                                siteSubCompanyList={siteSubCompanyList}
                                                            /></td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

const SiteSubcompanyTable = () => {
    const { PERMISSION_AUTHORITY } = usePermission(); // Use the custom hook
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [subCompanyList, setSubCompanyList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);

    const getSiteSubcompanies = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSiteSubCompanyList(sub.data);


            const res = await axios.get(
                // `${BASE_URL}/siteproject/subcompanyfilterbysubcompanycount/${toggleCompany}/active/`
                `${BASE_URL}/siteproject/subcompanyfilterbysubcompanycount/null/active/`
                // `${BASE_URL}/siteproject/subcompany`
            );
            setSubCompanyList(res.data);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    useEffect(() => {
        getSiteSubcompanies();
    }, []);

    console.log(siteSubCompanyList)


    return (
        <>
            <div className="attendance-subcontainer margin">

                <div className="field-cont flex-row justify-between" style={{ width: "100%" }}>

                    <div className="field-cont-div">
                        <SearchBig />
                        <input
                            placeholder="Search Sub Company"
                            className="attendance-input-field "
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <hr className="field-cont-hr" />

                    </div>

                    {PERMISSION_AUTHORITY ? (
                        <AddSiteSubcompanys getSiteSubcompanies={getSiteSubcompanies} />
                    ) : null}
                </div>
            </div>
            <div className="table-css-white-background">
                <div className="table-box  scroll-container-table" >
                    <table className="table-css">
                        <thead className="table-heading">
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-left-sr">Sr.no</th>
                                <th className="table-heading-text text-center">Company Name</th>
                                <th className="table-heading-text text-center">Employee Count</th>
                                <th className="table-heading-text text-center">Employees</th>
                                <th className="table-heading-text text-right" style={{ justifyContent: 'right' }}>Action</th>
                            </tr>
                        </thead>
                        {buffer ? (
                            <div className="spinner"></div> //buffering logic
                        ) : (
                            <tbody>
                                {subCompanyList
                                    ?.filter((e) =>
                                        e.title.toLowerCase().includes(searchQuery.toLowerCase())
                                    )
                                    .sort((a, b) =>
                                        a.title.localeCompare(b.title, "en", {
                                            ignorePunctuation: true,
                                        })
                                    )
                                    .map((i, index) => (
                                        <React.Fragment key={index}>

                                            <tr className="custom-table-head-td">
                                                <td className="table-body text-left-sr">{index + 1}</td>
                                                <td className="table-body text-center">{i.title}</td>
                                                <td className="table-body text-center">{i.employee_count}</td>
                                                <td className="table-body text-center">
                                                    <EmployeebySiteSubCompanyTable subcompany={i} siteSubCompanyList={siteSubCompanyList} designationId={i.id} />{" "}
                                                </td>
                                                <td className="table-body   text-right" style={{ justifyContent: 'right' }}>
                                                    <UpdateSiteSubcompanyupdate
                                                        i={i} designationId={i.id}
                                                        getSiteSubcompanies={getSiteSubcompanies}


                                                    />

                                                    {PERMISSION_AUTHORITY ? (
                                                        <DeleteSiteSubcompany
                                                            i={i}
                                                            getSiteSubcompanies={getSiteSubcompanies}
                                                        />
                                                    ) : null}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                            </tbody>
                        )}
                    </table>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </>
    );
};
const SiteDepartment = () => {
    const { PERMISSION_AUTHORITY } = usePermission(); // Use the custom hook
    const [buffer, setBuffering] = useState(true); //buffering logic
    const [toggleCompany, setToggleCompany] = useState("null");

    const [siteSubCompanyList, setSiteSubCompanyList] = useState([]);
    const role = sessionStorage.getItem('sitemanagement_role');
    const [siteDepartmentList, setSiteDepartmentList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const getSiteDepartment = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const sub = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSiteSubCompanyList(sub.data);
            const res = await axios.get(`${BASE_URL}/siteproject/department/`);
            setSiteDepartmentList(res.data);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };



    useEffect(() => {
        getSiteDepartment();
    }, []);

    return (
        <>
            <div className="attendance-subcontainer margin">
                <div className="field-cont flex-row justify-between" style={{ width: "100%" }}>

                    <div className="flex-row">
                        {PERMISSION_AUTHORITY ? (
                            <>
                                <div className=" field-cont-div">
                                    <CircularGrid />

                                    <select
                                        onChange={(e) => setToggleCompany(e.target.value)}
                                        value={toggleCompany}
                                        className="attendance-input-field width-10vw "
                                    >
                                        <option value="null"> Sub Company</option>
                                        {siteSubCompanyList.map((company) => (
                                            <option value={company.id}>{company.title}</option>
                                        ))}
                                        ;
                                    </select>
                                    <hr className="field-cont-hr" />

                                </div>
                                <div className="field-cont-div">
                                    <SearchBig />
                                    <input
                                        placeholder="Search Department"
                                        className="attendance-input-field "
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </div>


                            </>
                        ) : null}


                    </div>
                    <div>

                        <AddDepartment getSiteDepartment={getSiteDepartment} />
                        {role === "Admin" &&
                            <UploadDepartment getdepartment={getSiteDepartment} />
                        }
                    </div>
                </div>
            </div>
            <div className="table-css-white-background">

                <div className="table-box scroll-container-table">
                    <table className="table-css">
                        <thead className="table-heading">
                            <tr className="custom-table-head-tr">
                                <th className="table-heading-text text-left-sr">Sr.no</th>
                                <th className="table-heading-text text-left">Department Name</th>
                                <th className="table-heading-text text-center">Company</th>
                                <th className="table-heading-text text-right" style={{ justifyContent: 'right' }}>Action</th>
                            </tr>
                        </thead>
                        {buffer ? (
                            <div className="spinner"></div> //buffering logic
                        ) : (
                            <tbody>
                                {siteDepartmentList
                                    ?.filter((e) =>
                                        e.title.toLowerCase().includes(searchQuery.toLowerCase())
                                    )
                                    .sort((a, b) =>
                                        a.title.localeCompare(b.title, "en", {
                                            ignorePunctuation: true,
                                        })
                                    )
                                    .map((i, index) => (
                                        <React.Fragment key={index}>

                                            <tr className="custom-table-head-td">
                                                <td className="table-body text-left-sr">{index + 1}</td>
                                                <td className="table-body  text-left">{i.title}</td>
                                                <td className="table-body text-center">{i.sub_company_title}</td>
                                                <td className="table-body text-right" style={{ justifyContent: 'right' }}>
                                                    <UpdateDepartment
                                                        i={i}
                                                        getSiteDepartment={getSiteDepartment}
                                                    />

                                                    {PERMISSION_AUTHORITY ? (
                                                        <DeleteDepartment
                                                            i={i}
                                                            getSiteDepartment={getSiteDepartment}
                                                        />
                                                    ) : null}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                            </tbody>
                        )}
                    </table>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </>
    );
};

const EmployeebySiteSubCompanyTable = ({ designationId, subcompany, siteSubCompanyList }) => {
    const [show, setShow] = useState(false);
    const [buffer, setBuffering] = useState(true); //buffering logic

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        getSubCompanyEmployees(subcompany.id);
    };

    const [subcompanyEmployees, setSubCompanyEmployees] = useState([]);
    const [departmentEmployees, setDepartmentEmployees] = useState([]);

    const getSiteDepartmentEmployees = async (designation) => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/employeedetailsbydesignation/${designationId}/active/`
            );
            const getAlphaNumericParts = (code) => {
                const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
                if (match) {
                    return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
                } else {
                    return { alphaPart: "", numericPart: 0 };
                }
            };

            const sortedList = res.data.sort((a, b) => {
                const { alphaPart: alphaA, numericPart: numericA } =
                    getAlphaNumericParts(a.emp_code);
                const { alphaPart: alphaB, numericPart: numericB } =
                    getAlphaNumericParts(b.emp_code);

                if (alphaA !== alphaB) {
                    return alphaA.localeCompare(alphaB); // Sort alphabetically
                } else {
                    return numericA - numericB; // Then sort numerically
                }
            });
            setDepartmentEmployees(sortedList);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    const getSubCompanyEmployees = async (subcompanyId) => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(
                `${BASE_URL}/siteproject/employeedetailsbysubcompany/${subcompanyId}/active/`
            );
            const getAlphaNumericParts = (code) => {
                const match = code.match(/^([A-Za-z]+)(\d+)$/); // Match alphabetic and numeric parts
                if (match) {
                    return { alphaPart: match[1], numericPart: parseInt(match[2], 10) };
                } else {
                    return { alphaPart: "", numericPart: 0 };
                }
            };

            const sortedList = res.data.sort((a, b) => {
                const { alphaPart: alphaA, numericPart: numericA } =
                    getAlphaNumericParts(a.emp_code);
                const { alphaPart: alphaB, numericPart: numericB } =
                    getAlphaNumericParts(b.emp_code);

                if (alphaA !== alphaB) {
                    return alphaA.localeCompare(alphaB); // Sort alphabetically
                } else {
                    return numericA - numericB; // Then sort numerically
                }
            });
            setSubCompanyEmployees(sortedList);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false); //buffering logic // End Buffering
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // useEffect(() => {
    //   getSubCompanyEmployees();
    // }, []);


    return (
        <>
            <button
                className=""
                title="View Employees"
                style={{ paddingLeft: "5px", color: "white", fontSize: "14px", display: "inline-block" }}
                onClick={handleShow}
            >
                <Eye />
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="modal-addwidth width-60vw"
            >
                <Modal.Body>
                    <>
                        {/* <div className="attendance-history-cont">
                <div className="attendance-heading  font-weight500    font-size-heading"></div>
              </div> */}
                        <div className="table-css-white-background">
                            <div className="table-heading-flex">
                                <div className="repo-heading font-weight500    font-size-heading">
                                    Employees in : <br />
                                    {subcompany.title}
                                </div>
                                <div className="flex-row">
                                    <div className="table-searchh">
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="11"
                                                height="12"
                                                viewBox="0 0 11 12"
                                                fill="none"
                                            >
                                                <circle
                                                    cx="5"
                                                    cy="5"
                                                    r="4.3"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                />
                                                <line
                                                    x1="10.0101"
                                                    y1="11"
                                                    x2="8"
                                                    y2="8.98995"
                                                    stroke="#2B3674"
                                                    strokeWidth="1.4"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </div>
                                        <div>
                                            <input
                                                placeholder="Search"
                                                className="navbar-inputt font-weight400  font-size-text"
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table-css">
                                <thead>
                                    <tr className="custom-table-head-tr">
                                        <th className="table-heading-text text-left">ID</th>
                                        <th className="table-heading-text text-center">Emp Code</th>
                                        <th className="table-heading-text text-center"> Name </th>
                                        <th className="table-heading-text text-center"> Designation </th>
                                        <th className="table-heading-text text-right" style={{ justifyContent: 'right' }}> Action</th>
                                    </tr>
                                </thead>
                                {buffer ? (
                                    <div className="spinner"></div> //buffering logic
                                ) : (
                                    <tbody>
                                        {subcompanyEmployees
                                            ?.filter(
                                                (e) =>
                                                    e.emp_code.includes(searchQuery.toLowerCase()) ||
                                                    e.name
                                                        .toLowerCase()
                                                        .includes(searchQuery.toLowerCase())
                                            )
                                            .sort((a, b) => a.emp_code - b.emp_code)
                                            .map((i, index) => (
                                                <React.Fragment key={index}>

                                                    <tr className="custom-table-head-td">
                                                        <td className="table-body text-left">{index + 1}</td>
                                                        <td className="table-body text-center">{i.emp_code}</td>
                                                        <td className="table-body text-center">{i.name}</td>
                                                        <td className="table-body text-center">
                                                            {i.designation_name}
                                                        </td>
                                                        <td className="table-body text-right" style={{ justifyContent: 'right' }}>
                                                            <UpdateSiteEmployeeDesignation
                                                                i={i}
                                                                getSiteDepartmentEmployees={getSiteDepartmentEmployees}
                                                                siteSubCompanyList={siteSubCompanyList}
                                                            /></td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </>
                    <ToastContainer
                        position="top-center"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};



const SiteEmployeeDetails = () => {

    const companyId = sessionStorage.getItem("sitemanagement_project_sub_company");
    const projectID = sessionStorage.getItem("sitemanagement_project")

    const [project, setProject] = useState(projectID ? projectID : null);
    const location = useLocation();

    const [projectList, setProjectList] = useState([]);

    const [subCompany, setSubCompany] = useState(companyId ? companyId : null);
    const [subcompanyList, setSubCompanyList] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [employeeRole, setEmployeeRole] = useState("all");

    const [profession, setProfession] = useState("null");
    const [designation, setDesignation] = useState(null);
    const [designationList, setDesignationList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("null");
    const [buffer, setBuffering] = useState(false); //buffering logic
    const projectIdd = projectID ? projectID : location.state?.project?.id;

    const getSubCompany = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompanyList(response.data);
        } catch (error) {
            alert(error);
        }
    }

    const getproject = async () => {
        try {
            // const response = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/${subCompany}`);
            const response = await axios.get(
                sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' ?
                    `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                    : sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
                        `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem('sitemanagement_emp_id')}/`
                        : `${BASE_URL}/siteproject/projectfilterbysubcompany/${subCompany}/`
            );
            const sortedList = sortProjects(response.data, "site_prcode");
            // setProjectList(response.data)
            setProjectList(sortedList)

        } catch (error) {
            alert(error);
        }
    }

    const getDesignation = async () => {
        try {
            const responsee = await axios.get(`${BASE_URL}/siteproject/designationfilterbysubcompany/${subCompany}/`)
            setDesignationList(responsee.data);
        } catch (error) {

        }
    }

    useEffect(() => {

        getSubCompany();



    }, []);

    useEffect(() => {


        if (projectID === null) {
            getproject();
        }
        getproject();

        getDesignation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subCompany])

    const getEmployee = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const ress = await axios.get(`${BASE_URL}/siteproject/employeebyfilterss/${project}/${profession}/${selectedStatus}/${subCompany}/${designation}/${employeeRole}/`);

            setEmployeeData(ress.data)


        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setBuffering(false);// End Buffering
        }
    }

    useEffect(() => {
        getEmployee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project, profession, selectedStatus, subCompany, designation, employeeRole]);
    const [selectedNo, setNo] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        applyFilters();
    }, [selectedNo, employeeData]);

    const applyFilters = () => {
        let filteredData = employeeData;

        if (selectedNo !== "") {
            const lowercaseSelectedName = selectedNo?.toLowerCase();

            // Log to verify data

            filteredData = filteredData.filter(employee => {
                const lid = employee.emp_code?.toString()?.toLowerCase()?.includes(lowercaseSelectedName?.toString()) || employee?.name?.toLowerCase()?.includes(lowercaseSelectedName) || employee?.email?.toLowerCase()?.includes(lowercaseSelectedName);
                return lid;
            });
        }


        setFilteredData(filteredData);
    };

    const siteRole = sessionStorage.getItem("sitemanagement_role")


    const handleClickDownload = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'EmployeeData');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(data, 'employee_data.xlsx');
    };

    return (
        <div>
            <div className="attendance-subcont">
                <div className="field-cont" style={{ maxWidth: '70vw' }}>
                    {siteRole === "SiteManagement" ? "" :
                        <div title="Company Filter" className="field-cont-div">
                            <CircularGrid />
                            <select
                                onChange={(e) => setSubCompany(e.target.value)}
                                value={subCompany}
                                className="attendance-input-field width-10vw   date-field"
                            // className="dropdown font-size-subheading  "
                            >
                                <option value="null">Sub Company</option>
                                {subcompanyList.map((company) => (
                                    <option value={company.id}>{company.title}</option>
                                ))}
                                ;
                            </select>

                            <hr className="field-cont-hr" />
                        </div>

                    }
                    {siteRole === "SiteManagement" ? "" :
                        <Projects toggleProject={project} setToggleProject={setProject} subcompany={subCompany} />
                    }

                    <div className="field-cont-div">
                        <Location />
                        <select className="attendance-input-field  date-field" type="text"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value="null">All</option>

                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>



                        </select>
                        <hr className="field-cont-hr" />
                    </div>


                    <div className="field-cont-div">
                        <CircularGrid />
                        <select
                            className="attendance-input-field width-10vw date-field"
                            value={employeeRole}
                            onChange={(e) => setEmployeeRole(e.target.value)}
                        >

                            <option value="all">Select Employee Role </option>
                            <option value="Admin">Admin</option>
                            <option value="AuthorityEngineer">Authority Engineer</option>
                            <option value="DataEntryOperator">Coordinator</option>
                            <option value="Account">Account</option>
                            <option value="Hr">HR</option>
                            <option value="BD">BD</option>
                            <option value="Checker">Checker</option>
                            <option value="SiteManagement">Site Access Account</option>
                            <option value="Employee">Employee</option>
                            <option value="null">Site Persons </option>


                        </select>
                        <hr className="field-cont-hr" />
                    </div>




                    <div title='Search Filter' className="field-cont-div">
                        <SearchSmall />
                        <input className="attendance-input-field width-25vw"
                            placeholder='Employee Name/Emp code/ Email'
                            type="text"
                            value={selectedNo}
                            onChange={(e) => setNo(e.target.value)} />

                        <hr className="field-cont-hr" />
                    </div>

                    <div className="field-cont-div">
                        <CircularGrid />


                        <select
                            className="attendance-input-field width-10vw   date-field"
                            type="text"
                            value={profession}
                            onChange={(e) => setProfession(e.target.value)}
                        >
                            <option value="null"> Professional </option>
                            <option value="Key Professional">Key Professional</option>
                            <option value="Sub Professional">Sub Professional</option>
                            <option value="Support Staff">Support Staff</option>
                        </select>
                        <hr className="field-cont-hr" />
                    </div>

                    <div title="Designation" className="field-cont-div">
                        <CircularGrid />

                        <select
                            onChange={(e) => setDesignation(e.target.value)}
                            value={designation}
                            className="attendance-input-field width-10vw   date-field"
                        >
                            <option value="null">Designation</option>
                            {designationList.map((designation) => (
                                <option value={designation.id}>{designation.title}</option>
                            ))}
                            ;
                        </select>

                        <hr className="field-cont-hr" />
                    </div>
                </div>
                <div className="btn-cont">
                    <button className=" model-button-print" onClick={handleClickDownload}>
                        <DownloadIcon />
                    </button>
                    <AddSiteEmployee
                        projectId={project}
                        getSiteEmployee={getEmployee}
                        siteProjectList={projectList}
                        siteDepartmentList={designationList}
                        siteSubCompanyList={subcompanyList}
                        companyId={subCompany}

                    />
                    <UploadSiteEmployee getSiteEmployee={getEmployee} designation={designationList.title} sub_company={subcompanyList.title} />

                </div>
            </div>
            <div style={{ maxHeight: '73vh' }}>
                {
                    buffer ? <div className="spinner"></div> :
                        <EmployeeDetailCard
                            data={filteredData}
                            getSiteEmployee={getEmployee}
                            siteDepartmentList={designationList}
                            siteProjectList={projectList}
                            siteSubCompanyList={subcompanyList} />}
            </div>
        </div>
    )

}

const ConsultantAccountDetails = (i) => {
    const [project, setProject] = useState("null");
    const [buffer, setBuffering] = useState(false); //buffering logic
    const [loading, setLoading] = useState(false); //loading logic
    const [subCompanyList, setSubCompanyList] = useState([]);
    const [subCompany, setSubCompany] = useState("null")
    const [subbuffer, setSubBuffering] = useState(true); //buffering logic
    const [projectList, setProjectList] = useState([]);
    const { projectID } = usePermission();
    const [selectedName, setName] = useState("");
    const [selectedFromDate, setFromDate] = useState('null');
    const [selectedToDate, setToDate] = useState('null');
    const [accountList, setAccountList] = useState([]);

    useEffect(() => {
        getSubCompany();
        getAccountDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectID, project]);
    useEffect(() => {
        getproject();
    }, [subCompany])

    const getAccountDetails = async () => {
        setBuffering(true); // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/consultantaccountdetails/`);
            setAccountList(res.data);

        } catch (err) {
            handleErrorToast(err);

        } finally {
            setBuffering(false);// End Buffering
        }
    };
    const getproject = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/siteproject/projectfilterbysubcompany/${subCompany}/`);

            const splitCodeParts = (code) => {
                const parts = code.trim().split("/");
                const firstPart = parts.slice(0, -1).join("/");
                const lastPart = parts.pop();
                const match = lastPart.match(/^(\d+)?([A-Za-z]+)$/);
                if (match) {
                    return {
                        firstPart,
                        numericPart: match[1] ? parseInt(match[1], 10) : 0,
                        alphaPart: match[2]
                    };
                }
                return { firstPart, numericPart: 0, alphaPart: "" };
            };

            const sortedList = response.data.sort((a, b) => {
                const aParts = splitCodeParts(a.site_prcode);
                const bParts = splitCodeParts(b.site_prcode);
                if (aParts.firstPart !== bParts.firstPart) {
                    return aParts.firstPart.localeCompare(bParts.firstPart);
                }
                if (aParts.alphaPart !== bParts.alphaPart) {
                    return aParts.alphaPart.localeCompare(bParts.alphaPart);
                }
                return aParts.numericPart - bParts.numericPart;
            });

            // setProjectList(response.data)
            setProjectList(sortedList)

        } catch (error) {
            alert(error);
        }
    }
    const getSubCompany = async () => {
        setSubBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/siteproject/subcompany/`);
            setSubCompanyList(res.data);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);
        } finally {
            setSubBuffering(false);// End Buffering
        }
    };


    return (
        <>

            <div className="table-css-white-background">

                <div className="form-flex-wrap" style={{ overflow: "scroll" }}>
                    <div className="table-heading-flex justify-between" style={{ width: "100%" }}>
                        <div className="repo-heading font-weight500    font-size-heading">
                            Consultant Account Details
                        </div>
                        <AddConsultantAccount getConsultant={getAccountDetails} />
                    </div>
                    <div className='table-box  scroll-container-table' style={{ width: "100%" }}>
                        <table className="table-css" >
                            <thead>
                                <tr className="custom-table-head-tr">
                                    <th className="table-heading-text text-left-sr">S.no.</th>
                                    {/* <th className="table-heading-text text-center">Company Name</th> */}
                                    <th className="table-heading-text">Bank Name</th>
                                    <th className="table-heading-text">IFSC Code</th>
                                    <th className="table-heading-text">Account number</th>
                                    <th className="table-heading-text">PAN Number</th>
                                    <th className="table-heading-text">GST Number</th>
                                    <th className="table-heading-text">Address</th>
                                    <th className="table-heading-text">Gst Certificates</th>
                                    <th className="table-heading-text text-right" style={{ justifyContent: 'right' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accountList.map((i, index) => (
                                    <tr key={index} className="custom-table-head-td">
                                        <td className="table-body text-left-sr">{index + 1}</td>
                                        {/* <td className="table-body">{i.company_name}</td> */}
                                        <td className="table-body">{i.bank_name}</td>
                                        <td className="table-body">{i.ifsc_code}</td>
                                        <td className="table-body">{i.account_number}</td>
                                        <td className="table-body">{i.pan_no}</td>
                                        <td className="table-body">{i.gst_no}</td>
                                        <td className="table-body">{i.address}</td>
                                        <td className="table-body text-center">
                                            <a href={i.document} target="blank" className="text-center width-5vw">
                                                <DocumentIcon />
                                            </a>
                                        </td>
                                        <td className="table-body  text-right" style={{ justifyContent: 'right' }} >
                                            <UpdateConsultantAccount i={i} getConsultant={getAccountDetails} />
                                            <DeleteAccountDetails i={i} getConsultant={getAccountDetails} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)

}







export {
    SiteDesignationTable,
    SiteSubcompanyTable,
    SiteDepartment,
    SiteEmployeeDetails,
    ConsultantAccountDetails,

};
