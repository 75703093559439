import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../config/axios";
import { handleErrorToast, customSortByKey } from "../CustomFunctions";
import { AddwithWhiteCircle, DeleteDustbin, SearchSmall, UpdatePencil, DropdownArrowOption, AddwithBlueCircle, Eye } from "../AllSvg";
import usePermission from "../../config/permissions";
import Select from "react-select";
import * as XLSX from 'xlsx';
import { Attachment, DownloadIcon, UploadIcon, Office } from '../AllSvg'


const UploadSalaryDataBulk = ({ id, getPayrunlist }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    getAllEmployeeData();
    setShow(true)
  };
  const { SUB_COMPANIES } = usePermission();
  const [toggleCompany, setToggleCompany] = useState('null');

  const [fileName, setFileName] = useState("");
  const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
  const [docErrors, setDocErrors] = useState({});
  const [docInputState, setDocInputState] = useState({});

  const [jsonData, setJsonData] = useState(null);


  const [salaryStructureHeadings, setSalaryStructureHeadings] = useState([]);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [buffer1, setBuffering1] = useState(true);
  const [buffer2, setBuffering2] = useState(true);

  useEffect(() => {
    getAllEmployeeData()
    getAllSalaryRelatedData();
  }, [toggleCompany]);



  const getAllSalaryRelatedData = async (emp_code) => {
    setBuffering1(true); //buffering logic
    try {
      const headings = await axios.get(`${BASE_URL}/siteproject/salaryHeadingbysubcompany/${toggleCompany}/`);
      setSalaryStructureHeadings(headings.data);

    } catch (err) {
      console.error("Error fetching salary data", err);
    } finally {
      setBuffering1(false); //buffering logic
    }
  };

  let getAllEmployeeData = async () => {
    setBuffering2(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/siteproject/employeefilterbysubcompany/null/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");

      setAllEmployeeList(sortedList);
      // setAllEmployeeList(res.data);
    } catch (err) {
      //toast Logic
      handleErrorToast(err);

    } finally {
      setBuffering2(false); //buffering logic
    }
  };

  const bulktableRef = useRef(null);
  const handleDownload = () => {
    const table = bulktableRef.current;
    const wb = XLSX.utils.table_to_book(table, { sheet: "Salary Sheet" });
    const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

    const blob = new Blob([s2ab(wbout)], {
      type: "application/vnd.ms-excel",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Salary Sheet Format.xls";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };


  const handleClose = () => {
    setFileName("")
    setExcelJson(null)
    setDocErrors({})
    setShow(false)
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);

        const workbook = XLSX.read(data, { type: 'array' });

        console.log("workbook")
        console.log(workbook)

        // Assuming the sheet with attendance data is named "Attendance"
        const worksheet = workbook.Sheets['Attendance Sheet'];

        const rawData = XLSX.utils.sheet_to_json(worksheet);
        console.log("rawData")
        console.log(rawData)
        // Transform rawData into the required format for uploading

        setJsonData(rawData);
      };
      reader.readAsArrayBuffer(file);
      setFileName(file.name);
    }
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleSubmit = async () => {
    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    if (!jsonData) {
      alert("Please upload an Excel file first!");
      return;
    }
    try {
      const response = await axios.post(`${BASE_URL}/wfm/bulkcustomdeductionupload/`, jsonData);
      // alert('Attendance uploaded successfully!');
      toast.success('Attendance uploaded successfully!');
      await getPayrunlist()
      handleClose()
    } catch (error) {
      console.error('Error uploading attendance:', error);
      toast.err('Error uploading Slary Structures.');
      // alert('Error uploading Slary Structures.');
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };




  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  const tableRef = useRef(null);





  return (
    <>
      <button
        title="Bulk Upload Salary Data"
        className="model-button font-weight500 model-button-print"
        onClick={handleShow}
        style={{ margin: "2px" }}
      >
        <UploadIcon />
      </button>

      <Modal show={show} dialogClassName="ourcompany-model">
        <Modal.Header className="task-Tab-heading font-weight500 font-size-heading ">

          <Modal.Title>Upload Bulk Salary Data in Format:</Modal.Title>

          <div className="attendance-subcont">
            <div className="field-cont">
              <div className="table-searchh dropdown-container">
                <Office />
                <select onChange={(e) => setToggleCompany(e.target.value)} value={toggleCompany} className="dropdown font-size-subheading  ">
                  <option value="null">Select SubCompany</option>
                  {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};

                </select>
              </div>
              {toggleCompany !== "null" ?
                <button onClick={handleDownload} className="model-button-download model-button font-weight500 field-cont-div" style={{ marginLeft: "35vw" }}>
                  <DownloadIcon />
                  {"  "} Format
                </button> : null}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>


          <div className="table-css-white-background" style={{ display: "none" }}>
            <table
              ref={bulktableRef}
              id="my-table"
              style={{
                borderColor: "#323232",
                borderRadius: "5px",
                padding: "0px 5px",
                borderWidth: "1px",
                // marginLeft: "10px",
                // zoom: "70%",
              }}
            >
              <thead                            >
                <tr>
                  <th className="summary-excel-tab br-5">emp_code</th>
                  <th className="summary-excel-tab br-5">name</th>
                  {salaryStructureHeadings.sort((a, b) => a.sorting_var - b.sorting_var).map((heading) => (
                    <React.Fragment key={heading.id}>
                      <th>{heading.title} fixed_amount</th>
                      <th>{heading.title} percentage</th>
                      <th>{heading.title} percentage_of</th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              {
                // buffer ? <div className="spinner"></div> :
                <tbody>
                  {allEmployeeList
                    .sort((a, b) => a.emp_code - b.emp_code)
                    .map((data, index) => {
                      const inactivename = data.employee_status === "inactive" ? "#ff1212" : "black";
                      return (
                        <>
                          <tr
                            style={{
                              backgroundColor:
                                index === selectedIndex ? "yellow" : "white",
                              color: inactivename,
                            }}
                            onClick={() => handleClick(index)}
                          >
                            <td className="summary-excel-tab br-5">{data.emp_code}</td>
                            <td className="summary-excel-tab br-5">{data.name}</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              }
            </table>

          </div>



          <div className="flex-column field-cont-div">
            <label htmlFor="document_file" className="form-labels font-weight500 font-size-heading">
              Upload File
            </label>
            <label
              htmlFor="document_file"
              className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                }`}
            >
              <div className={`align-center pdf-input  ${docErrors.document_file ? 'error' : ''}`}>
                <Attachment />
              </div>
            </label>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              id="document_file"
              name="document_file"
              className="file-inputs align-center"
              style={{ display: "none", position: "relative" }} />
            {fileName && <p className="file-name align-center">{fileName}</p>}
            <br />
            {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
          </div>

          <div className="button-models">
            <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
              Cancel
            </button>
            <button className="model-button-download model-button font-weight500" onClick={handleSubmit} disabled={loading}>
              <UploadIcon />
              {"  "} {loading ? "Uploading..." : "Upload"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-center" autoClose={10000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};


const RunPayRoll = ({ month, year }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false); //loading logic
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic
    try {
      let res = await axios.post(
        `${BASE_URL}/siteproject/salaryslipallpost/${year}/${month}/`
      );

      if (res.status === 200) {
        handleClose();
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false); //loading logic
    }
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[month - 1];

  return (
    <>
      <button
        className="model-view-button model-add"
        title="Run Pay Roll"
        onClick={handleShow}
      >
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          id="payroll"
        >
          <g>
            <path d="M72.36,492.088a13.5,13.5,0,0,0,14.3-1.8l32.079-26.753,38.644,31.19.093.072a13.438,13.438,0,0,0,16.669-.039l.095-.076,38.2-31.17,38.2,31.17.094.076a13.438,13.438,0,0,0,16.668.039l.092-.072,38.64-31.191,32.072,26.753h0a13.608,13.608,0,0,0,14.371,1.8A12.526,12.526,0,0,0,360,480.775V428.44l21.848,18.291a13.246,13.246,0,0,0,8.516,3.074,12.576,12.576,0,0,0,5.517-1.275A12.248,12.248,0,0,0,403,437.217V384.883l22.127,18.291a13.623,13.623,0,0,0,8.656,3.074,13.839,13.839,0,0,0,5.807-1.275A12.519,12.519,0,0,0,447,393.66V49.843C447,30.264,429.723,14,408.542,14H190.573C169.392,14,152,30.264,152,49.843V58h-4.984C125.834,58,109,73.821,109,93.4V101h-5.542C82.277,101,65,117.378,65,136.958V480.775A12.473,12.473,0,0,0,72.36,492.088ZM168,49.843C168,39.086,178.214,30,190.573,30H408.542C420.9,30,431,39.086,431,49.843V387.034l-28-22.986V93.4C403,73.821,386.166,58,364.984,58H168ZM125,93.4c0-10.757,9.657-19.4,22.016-19.4H364.984C377.343,74,387,82.643,387,93.4V430.592L360,407.6V136.958C360,117.378,342.608,101,321.427,101H125ZM81,136.958C81,126.2,91.1,117,103.458,117H321.427C333.786,117,344,126.2,344,136.958V474.149l-29.193-24.285c-.033-.027-.105-.054-.138-.081a13.441,13.441,0,0,0-16.791-.125c-.031.025-.072.049-.1.073l-38.665,31.207L220.9,449.76l-.1-.077a13.428,13.428,0,0,0-16.716,0l-.095.077-38.21,31.178-38.66-31.207-.092-.073a13.411,13.411,0,0,0-16.771.125c-.033.027-.088.054-.121.081L81,474.149Z"></path>
            <path d="M149.586 404H275.3a8 8 0 0 0 0-16H149.586a8 8 0 0 0 0 16zM212.443 314.73a28 28 0 0 1-27.967-27.966 8 8 0 0 0-16 0A44.038 44.038 0 0 0 202 329.467V344a8 8 0 0 0 16 0V330.367a43.961 43.961 0 0 0-5.557-87.57 27.964 27.964 0 0 1-2.621-55.806c.06 0 .118.009.178.009a7.989 7.989 0 0 0 1.236-.1c.4-.017.8-.031 1.207-.031a28 28 0 0 1 27.966 27.967 8 8 0 1 0 16 0A44.023 44.023 0 0 0 218 171.228V159a8 8 0 0 0-16 0v13.128A43.963 43.963 0 0 0 212.443 258.8a27.967 27.967 0 0 1 0 55.933z"></path>
          </g>
        </svg>
      </button>

      <Modal show={show} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h3 className="task-Tab-heading  font-weight500    font-size-heading">
                Run Pay Rolls for "{monthName}-{year}"
              </h3>
              <p style={{ textAlign: "center" }}>
                Confirm Running Pay Roll for the year: {year}, month:{" "}
                {monthName}
              </p>

              <div className="button-models">
                <button
                  className="model-button  font-weight500   "
                  onClick={handleClose}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button  font-weight500    model-button-leave font-weight500    font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Run
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};




// ! ***************  SalaryStructureHeading Crud Models Start ***************

const SalaryStructureHeadingAdd = ({ salaryStructureHeadings, getSalaryStructureHeadings }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [salaryslipView, setSalarySlipView] = useState(true);
  const [finalAmount, setFinalAmount] = useState(false);

  const handleClose = () => {
    setFormData({
      title: "",
      sub_company: sessionStorage.getItem('sitemanagement_project_sub_company' || null),
      sorting_var: "",
      salary_type: "",
    });
    setSalarySlipView(false);
    setFinalAmount(false);
    setShow(false);
  };

  const handleShow = () => {
    setSalarySlipView(true);
    setShow(true)
  };

  const [formData, setFormData] = useState({
    title: "",
    sub_company: sessionStorage.getItem('sitemanagement_project_sub_company' || null),
    sorting_var: "",
    salary_type: "",
  });
  const [suggestion, setSuggestPosition] = useState("")

  useEffect(() => {
    const getNextSortingPosition = () => {
      const maxSortingVar = Math.max(
        ...salaryStructureHeadings.map((heading) => Number(heading.sorting_var)),
        1
      );
      setSuggestPosition(maxSortingVar + 1);
    };

    getNextSortingPosition();
  }, [salaryStructureHeadings]);

  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "title",
      "sub_company",
      "sorting_var",
      "salary_type",
    ];


    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    // // Add more validation for numerical fields as needed
    // if (formData.epf_criteria && isNaN(formData.epf_criteria)) {
    //   newErrors.epf_criteria = 'EPF criteria must be a valid number!';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      salaryslip: salaryslipView,
      final_salary: finalAmount,
    };
    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/siteproject/salaryheading/`, updatedFormData);
        if (res.status === 200) {
          await getSalaryStructureHeadings();
          handleClose();
          // toast.success('Salary Policy created successfully!');
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" title="Add Salary Heading" onClick={handleShow}>
        <AddwithBlueCircle />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton className=" task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Add Salary Heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Heading Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
              </div>


              <div className="flex-column form-group-selectt">
                <label htmlFor="salary_type " className="form-labels  font-weight500    font-size-subheading">
                  Salary Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="salary_type"
                  name="salary_type"
                  value={formData.salary_type}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.salary_type ? "error" : inputState.salary_type ? "success" : ""
                    }`}
                >
                  <option value="">Select Salary Type</option>
                  <option value="allowance">Allowance</option>
                  <option value="deduction">Deduction</option>
                  <option value="total">Total</option>
                  <option value="difference">Difference</option>
                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                {errors.salary_type && (
                  <span className="error-message font-size-text ">{errors.salary_type}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="sorting_var" className="form-labels  font-weight500    font-size-subheading">
                  Heading Sort Position (1 is Top)<span className="required">*</span>
                </label>
                <input
                  id="sorting_var"
                  name="sorting_var"
                  type="number"
                  min={1}
                  maxLength={10}
                  placeholder={`Heading Position (Suggestion: ${suggestion})`}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.sorting_var}
                  className={`form-input ${errors.sorting_var ? "error" : inputState.sorting_var ? "success" : ""
                    }`}
                />
                {errors.sorting_var && (
                  <span className="error-message font-size-text ">{errors.sorting_var}</span>
                )}
              </div>

              {PERMISSION_AUTHORITY ?
                <div className="flex-column form-group-selectt">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Sub Company<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    <option value="">Select Site Company</option>
                    {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                  </select>
                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                  {errors.sub_company && (
                    <span className="error-message font-size-text ">{errors.sub_company}</span>
                  )}
                </div>
                : null
              }
              {/* <div className="flex-row justify-between"> */}
              <div className="flex-row ">
                <div className="checkbox-toggle">
                  <input
                    type="checkbox"
                    id="toggle1"
                    checked={salaryslipView}
                    onChange={() => setSalarySlipView(!salaryslipView)}
                  />
                  <label htmlFor="toggle1"></label>
                </div>
                <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                  {salaryslipView ? "View" : "Hide"} in Salary Slip {" "}
                </label>
              </div>
              <div className="flex-row ">
                <div className="checkbox-toggle">
                  <input
                    type="checkbox"
                    id="toggle2"
                    checked={finalAmount}
                    onChange={() => setFinalAmount(!finalAmount)}
                  />
                  <label htmlFor="toggle2"></label>
                </div>
                <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                  Final Salary Amount {" "}
                </label>
              </div>
              {/* </div> */}
              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const SalaryStructureHeadingUpdate = ({ i, salaryStructureHeadings, getSalaryStructureHeadings }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [salaryslipView, setSalarySlipView] = useState(i.salaryslip || true);
  const [finalAmount, setFinalAmount] = useState(i.final_salary || false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
  };

  const [formData, setFormData] = useState({
    title: "",
    sub_company: sessionStorage.getItem('sitemanagement_project_sub_company' || null),
    sorting_var: "",
    salary_type: "",
  });

  const [suggestion, setSuggestPosition] = useState("")

  useEffect(() => {
    const getNextSortingPosition = () => {
      const maxSortingVar = Math.max(
        ...salaryStructureHeadings.map((heading) => Number(heading.sorting_var)),
        1
      );
      setSuggestPosition(maxSortingVar + 1);
    };

    getNextSortingPosition();
  }, [salaryStructureHeadings]);

  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "title",
      "sub_company",
      "sorting_var",
      "salary_type",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });



    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      salaryslip: salaryslipView,
      final_salary: finalAmount,
    };
    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.put(`${BASE_URL}/siteproject/salaryheading/${i.id}/`, updatedFormData);
        if (res.status === 200) {
          await getSalaryStructureHeadings();
          setShow(false);
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Update Salary Heading" onClick={handleShow}>
        <UpdatePencil />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton className=" task-Tab-heading font-weight500    font-size-heading ">
          <Modal.Title>Update Salary Heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-column">
                <label htmlFor="title" className="form-labels  font-weight500    font-size-subheading">
                  Title<span className="required">*</span>
                </label>
                <input
                  maxLength={50}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Salary Heading Title"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message font-size-text ">{errors.title}</span>
                )}
              </div>

              <div className="flex-column form-group-selectt">
                <label htmlFor="salary_type " className="form-labels  font-weight500    font-size-subheading">
                  Salary Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="salary_type"
                  name="salary_type"
                  value={formData.salary_type}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.salary_type ? "error" : inputState.salary_type ? "success" : ""
                    }`}
                >
                  <option value="">Select Salary Type</option>
                  <option value="allowance">Allowance</option>
                  <option value="deduction">Deduction</option>
                  <option value="total">Total</option>
                  <option value="difference">Difference</option>
                </select>
                <div className="form-group-selection-arrow">
                  <DropdownArrowOption />
                </div>
                {errors.salary_type && (
                  <span className="error-message font-size-text ">{errors.salary_type}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="sorting_var" className="form-labels  font-weight500    font-size-subheading">
                  Heading Sort Position (1 is Top)<span className="required">*</span>
                </label>
                <input
                  id="sorting_var"
                  name="sorting_var"
                  type="number"
                  min={1}
                  maxLength={10}
                  placeholder={`Heading Position (Suggestion: ${suggestion})`}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.sorting_var}
                  className={`form-input ${errors.sorting_var ? "error" : inputState.sorting_var ? "success" : ""
                    }`}
                />
                {errors.sorting_var && (
                  <span className="error-message font-size-text ">{errors.sorting_var}</span>
                )}
              </div>

              {PERMISSION_AUTHORITY ?
                <div className="flex-column form-group-selectt">
                  <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                    Sub Company<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="sub_company"
                    name="sub_company"
                    value={formData.sub_company}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                      }`}
                  >
                    <option value="">Select Site Company  </option>
                    {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                  </select>
                  <div className="form-group-selection-arrow">
                    <DropdownArrowOption />
                  </div>
                  {errors.sub_company && (
                    <span className="error-message font-size-text ">{errors.sub_company}</span>
                  )}
                </div>
                : null
              }
              <div className="flex-row ">
                <div className="checkbox-toggle">
                  <input
                    type="checkbox"
                    id="toggle1"
                    checked={salaryslipView}
                    onChange={() => setSalarySlipView(!salaryslipView)}
                  />
                  <label htmlFor="toggle1"></label>
                </div>
                <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                  {salaryslipView ? "View" : "Hide"} in Salary Slip {" "}
                </label>
              </div>
              <div className="flex-row ">
                <div className="checkbox-toggle">
                  <input
                    type="checkbox"
                    id="toggle2"
                    checked={finalAmount}
                    onChange={() => setFinalAmount(!finalAmount)}
                  />
                  <label htmlFor="toggle2"></label>
                </div>
                <hr style={{ marginTop: '10px' }} className="field-cont-hr" />
                <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                  Final Salary Amount {" "}
                </label>
              </div>
              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const SalaryStructureHeadingDelete = ({ i, getSalaryStructureHeadings }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/salaryheading/${i.id}/`);

      if (res.status === 200) {
        await getSalaryStructureHeadings();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err, loadingToastId)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete Salary Heading" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Salary Heading: {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Salary Salary Heading: "{i.title}"
            <div className="button-models">
              <button className="model-button model-button-cancel  font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


// ? *************** SalaryStructureHeading Crud Models End ***************



const EmployeeSalaryStructure = ({
  code,
  sub_company,
  getSalaryData,
  getSalaryStructure,
}) => {

  const [show, setShow] = useState(false);
  const [customData, setCustomData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = async () => {
    getAllSalaryRelatedData(code)
    setShow(true);
  };


  const [salaryData, setSalaryData] = useState({});
  const [deductionData, setDeductionData] = useState([]);
  // const [salaryDeductionPolicies, setSalaryDeductionPolicy] = useState([]);
  const [salaryStructureHeadings, setSalaryStructureHeadings] = useState([]);

  console.log("sub_company")
  console.log(sub_company)


  const getAllSalaryRelatedData = async (emp_code) => {
    try {
      const salary = await axios.get(
        `${BASE_URL}/siteproject/salarystructurebyemp/${emp_code}/`
      );
      setSalaryData(salary.data);
      // const salary = "null"

      const deduction = await axios.get(`${BASE_URL}/siteproject/customdeductionbysalary_structure/${salary.data.id}/`);
      // const deduction = await axios.get(`${BASE_URL}/siteproject/customdeductionbysalary_structure/${salary}/`);
      setDeductionData(deduction.data);

      const headings = await axios.get(`${BASE_URL}/siteproject/salaryHeadingbysubcompany/${sub_company}/`);
      setSalaryStructureHeadings(headings.data);
      if (Array.isArray(headings.data)) {
        setSalaryStructureHeadings(headings.data);
        const customData = headings.data.map(item => ({
          name: item.id,
          salary_structure: salary.data.id,
          salary_type: item.salary_type,
          sorting_var: item.sorting_var,
          percentage: '',
          percentage_of: [],
          fixed_amount: '',
        }));
        setCustomData(customData);
      } else {
        console.error("Error: Expected a list of Salary Headings");
      }
    } catch (err) {
      // handleAllError(err)
    } finally {
    }
  };

  return (
    <>
      <button
        className="model-view-button model-add"
        title="Add Salary Details"
        onClick={handleShow}
      >
        <svg
          width="24"
          height="25"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          id="saving">
          <path d="M28,15V12a3,3,0,0,0-3-3H24c0-.07,0-.13,0-.2A5.8,5.8,0,0,0,18.2,3,5.71,5.71,0,0,0,15,4a5.69,5.69,0,0,0-3.2-1A5.8,5.8,0,0,0,6,8.8c0,.07,0,.13,0,.2H5a3,3,0,0,0-3,3V26a3,3,0,0,0,3,3H25a3,3,0,0,0,3-3V23a2,2,0,0,0,2-2V17A2,2,0,0,0,28,15ZM22,8.8c0,.07,0,.13,0,.2h-4.4c0-.07,0-.13,0-.2a5.8,5.8,0,0,0-1.09-3.38A3.76,3.76,0,0,1,22,8.8ZM8,8.8a3.8,3.8,0,0,1,7.6,0c0,.07,0,.13,0,.2H8C8,8.93,8,8.87,8,8.8ZM26,26a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H25a1,1,0,0,1,1,1v3H21a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h5Zm-5-5a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1h7v4Z"></path>
        </svg>
      </button >
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="four-fifth-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Salary Structure of : "{code}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div ">
            <div className="bdy-div">
              <UserCustomDeductions
                code={code}
                deductionData={deductionData}
                customData={customData}
                salaryData={salaryData}
                salaryStructureHeadings={salaryStructureHeadings}
                // salaryDeductionPolicies={salaryDeductionPolicies}
                getSalaryData={getSalaryData}
                // getSalaryStructure={getSalaryStructure}
                getAllSalaryRelatedData={getAllSalaryRelatedData}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};

const UserCustomDeductions = ({
  handleClose,
  code,
  salaryData,
  customData,
  deductionData,
  salaryDeductionPolicies,
  salaryStructureHeadings,
  getAllSalaryRelatedData,
}) => {
  const [formData, setFormData] = useState(customData || []);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  // console.log("deductionData")
  // console.log(deductionData)
  useEffect(() => {
    setFormData(customData);
  }, [customData]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    // Update formData and clear dependent fields as needed
    const updatedFormData = formData.map((entry, i) => {
      if (i === index) {
        return {
          ...entry,
          [name]: value,
          ...(name === "fixed_amount" ? { percentage: "", percentage_of: [] } : {}),
          ...(name === "percentage" ? { fixed_amount: "" } : {}),
        };
      }
      return entry;
    });

    setFormData(updatedFormData);
  };

  console.log("customData")
  console.log(customData)

  const handleSelectChange = (selectedOptions, index) => {
    // Update percentage_of with selected values
    const updatedFormData = formData.map((entry, i) => {
      if (i === index) {
        return {
          ...entry,
          percentage_of: selectedOptions ? selectedOptions.map(option => option.value) : [],
        };
      }
      return entry;
    });

    setFormData(updatedFormData);
  };

  const validateForm = () => {
    const newErrors = {};
    formData.forEach((entry, index) => {
      const hasFixedAmount = !!entry.fixed_amount;
      const hasPercentage = !!entry.percentage;
      const hasRelatedTo = entry.percentage_of && entry.percentage_of.length > 0;

      if (hasFixedAmount && (hasPercentage || hasRelatedTo)) {
        newErrors[index] = "Specify either Fixed Amount or Percentage with Related To, not both.";
      } else if (hasPercentage && !hasRelatedTo) {
        newErrors[index] = "Related To is required when Percentage is specified.";
      }
      else if (!hasPercentage && hasRelatedTo) {
        newErrors[index] = "Percentage is required when Related To is specified.";
      }
      // } else if (!hasFixedAmount && (!hasPercentage || !hasRelatedTo)) {
      //     newErrors[index] = "Either Fixed Amount or both Percentage and Related To are required.";
      // }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitCustomDeduction = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    const loadingToastId = toast.loading("Loading: Please wait...");
    const validData = formData.filter(doc =>
      (doc.fixed_amount || (doc.percentage && doc.percentage_of.length > 0))
    );
    try {
      const res = await axios.post(`${BASE_URL}/siteproject/bulkcustomdeduction/`, validData);
      if (res.status === 200 || res.status === 201) {
        await getAllSalaryRelatedData(code);
        toast.success("Deductions saved successfully.");
      }
    } catch (err) {
      await getAllSalaryRelatedData(code);
    } finally {
      setLoading(false);
      toast.dismiss(loadingToastId);
    }
  };

  const deductionIds = deductionData.map(item => item.name);

  // Format options for react-select
  const relatedToOptions = salaryStructureHeadings
    .filter(i => !deductionIds.includes(i.id))
    .sort((a, b) => a.sorting_var - b.sorting_var)
    .map(i => ({ value: i.id, label: i.title }));

  const relatedToHeadingOptions = [
    { value: 'Gross', label: 'Gross' },
    ...salaryStructureHeadings
      .filter(i => !deductionIds.includes(i.id))
      .sort((a, b) => a.sorting_var - b.sorting_var)
      .map(i => ({ value: i.id, label: i.title })),
  ];

  return (
    <div>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
      <table className="table-css">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left width-5vw">Heading</th>
            <th className="align-center width-5vw">Type</th>
            <th className="align-center width-5vw">Amount</th>
            <th className="align-center" style={{ width: '0.5vw' }}>OR</th>
            <th className="align-center width-5vw">Percentage</th>
            <th className="align-center width-5vw">Related To</th>
            {deductionData.length > 0 && <th className="align-center width-5vw">Action</th>}
          </tr>
        </thead>
        <tbody>
          <>
            {
              // deductionData.length > 0 ?
              <>
                {deductionData
                  .sort((a, b) => a.name_sorting_var - b.name_sorting_var)
                  .map((entry, index) => (
                    <tr className="custom-table-head-td" key={index}>
                      <td className="align-left">{entry.name_title}</td>
                      <td className="align-left form-text-trasformation-uppercase">{entry.name_type}</td>

                      <td className="align-center">{entry.fixed_amount || '-'}</td>
                      <td className="align-center">
                        <div style={{ width: '1vw' }} className="align-center width-5vw ">OR</div>
                        {/* <hr className="field-cont-hr" /> */}
                      </td>
                      <td className="align-center">
                        {entry.percentage ?
                          `${parseFloat(entry.percentage) % 1 === 0 ?
                            parseFloat(entry.percentage).toFixed(0)
                            : parseFloat(entry.percentage).toFixed(2)}%`
                          : '-'}
                      </td>
                      <td className="align-leftt width-10vw">
                        {entry.percentage_of.length === 0 ?
                          <div className="align-center">-</div> :
                          // entry.percentage_of?.join(", ") || '-'
                          <ul style={{ paddingLeft: '0' }}>
                            {entry.percentage_of.map((data, index) => (
                              <li key={index}>
                                <span className="form-text-trasformation-uppercase">
                                  {data.name}
                                </span>
                              </li>
                            ))}
                          </ul>
                        }
                      </td>
                      <td className="align-center">
                        <div className="flex-row ">
                          <UpdateCustomDeductions
                            i={entry}
                            code={code}
                            deductionData={deductionData}
                            salaryStructureHeadings={salaryStructureHeadings}
                            getAllSalaryRelatedData={getAllSalaryRelatedData}
                          />
                          {/* {entry.fixed_amount ?
                                                        <UpdateCustomDeductionFixedAmount
                                                            i={entry}
                                                            code={code}
                                                            deductionData={deductionData}
                                                            salaryStructureHeadings={salaryStructureHeadings}
                                                            getAllSalaryRelatedData={getAllSalaryRelatedData}
                                                        />
                                                        : null} */}
                          <DeleteCustomDeductions
                            i={entry}
                            code={code}
                            deductionData={deductionData}
                            salaryStructureHeadings={salaryStructureHeadings}
                            getAllSalaryRelatedData={getAllSalaryRelatedData}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </>
              // :
              // <>
              //   {formData
              //     .sort((a, b) => a.sorting_var - b.sorting_var)
              //     .map((entry, index) => (
              //       <>
              //         <tr className="custom-table-head-td" key={index}>
              //           <td className="align-left">{salaryStructureHeadings.find(t => t.id === entry.name)?.title || "Loading..."}</td>
              //           <td className="align-center form-text-trasformation-uppercase">{salaryStructureHeadings.find(t => t.id === entry.name)?.salary_type || "Loading..."}</td>
              //           <td className="align-center">
              //             <input
              //               type="number"
              //               name="fixed_amount"
              //               min={0}
              //               placeholder="Fixed Amount"
              //               value={entry.fixed_amount || ''}
              //               onWheel={(e) => e.target.blur()}
              //               onChange={(e) => handleInputChange(e, index)}
              //               className={`form-input-date ${errors[index] ? 'is-invalid' : ''}`}
              //               disabled={!!entry.percentage} // Disable if percentage is provided
              //             />
              //           </td>
              //           <td className="align-center">
              //             <div style={{ width: '1vw' }} className="align-center width-5vw ">OR</div>
              //           </td>
              //           <td className="align-center">
              //             <input
              //               type="number"
              //               name="percentage"
              //               min={0}
              //               placeholder="Percentage"
              //               value={entry.percentage || ''}
              //               onWheel={(e) => e.target.blur()}
              //               onChange={(e) => handleInputChange(e, index)}
              //               className={`form-input-date ${errors[index] ? 'is-invalid' : ''}`}
              //               disabled={!!entry.fixed_amount} // Disable if fixed amount is provided
              //             />
              //           </td>
              //           <td className="align-leftt width-10vw">
              //             <Select
              //               isMulti
              //               name="percentage_of"
              //               value={relatedToOptions.filter(option => entry.percentage_of?.includes(option.value))}
              //               onChange={(selectedOptions) => handleSelectChange(selectedOptions, index)}
              //               options={relatedToOptions}
              //               className={` width-15vw ${errors[index] ? 'is-invalid' : ''}`}
              //               classNamePrefix="select"
              //               placeholder="Select Related To"
              //               isDisabled={!!entry.fixed_amount} // Disable if fixed amount is provided
              //               styles={{
              //                 control: (provided) => ({
              //                   ...provided,
              //                   // width: '33vw',
              //                   // height: '50px',
              //                   borderRadius: '10px',
              //                   border: '1px solid #e3e3e3',
              //                   margin: '10px 0px 20px 0px',
              //                   color: '#4a4a4a',
              //                   backgroundColor: '#f7f8fa',
              //                   padding: '0px 10px',
              //                 }),
              //                 placeholder: (provided) => ({
              //                   ...provided,
              //                   color: '#4a4a4a',
              //                 }),
              //                 menu: (provided) => ({
              //                   ...provided,
              //                   borderRadius: '10px',
              //                   marginTop: '0',
              //                 }),
              //                 input: (provided) => ({
              //                   ...provided,
              //                   color: '#4a4a4a',
              //                 }),
              //                 singleValue: (provided) => ({
              //                   ...provided,
              //                   color: '#4a4a4a',
              //                 }),
              //               }}
              //             />
              //           </td>
              //         </tr>
              //         <tr>
              //           {errors[index] && <td colSpan='6' className="align-center error-message font-size-text">{errors[index]}</td>}
              //         </tr>
              //       </>
              //     ))}
              // </>
            }
          </>
        </tbody>
      </table>

      <div className="button-models">
        {/* {
                  (salaryStructureHeadings?.filter(i => !deductionIds.includes(i.id)).length > 0 ?
                      <AddCustomDeductions
                          code={code}
                          deductionData={deductionData}
                          salaryData={salaryData}
                          salaryStructureHeadings={salaryStructureHeadings}
                          getAllSalaryRelatedData={getAllSalaryRelatedData}
                      /> :
                      null
                  )
              } */}
        {
          // deductionData.length > 0 ?
          (salaryStructureHeadings?.filter(i => !deductionIds.includes(i.id)).length > 0 ?
            <AddCustomDeductions
              code={code}
              deductionData={deductionData}
              salaryData={salaryData}
              salaryStructureHeadings={salaryStructureHeadings}
              salaryDeductionPolicies={salaryDeductionPolicies}
              getAllSalaryRelatedData={getAllSalaryRelatedData}
            /> :
            null
          )
          // :
          // <button
          //   onClick={handleSubmitCustomDeduction}
          //   className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
          //   disabled={loading}
          // >
          //   {loading ? 'Saving...' : 'Save Deductions'}
          // </button>
        }
      </div>
    </div >
  );
};

const AddCustomDeductions = ({ code, salaryData, deductionData, salaryStructureHeadings, getAllSalaryRelatedData, }) => {

  const [formData, setFormData] = useState({
    emp_code: code,
    name: "",
    salary_structure: salaryData.id,
    salary_type: "",
    percentage: '',
    percentage_of: [], // Initialize as an array for multiple selections
    fixed_amount: '',
  });
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  const validateForm = () => {
    const newErrors = {};
    const isSalaryHeadingSelected = !!formData.name;
    const hasFixedAmount = formData.fixed_amount !== '';
    const hasPercentage = formData.percentage !== '' && formData.percentage_of.length > 0;

    // Validate Salary Heading
    if (!isSalaryHeadingSelected) {
      newErrors.name = 'Salary Heading is required!';
    }

    // Validation for Fixed Amount or Percentage
    if (!hasFixedAmount && !hasPercentage) {
      newErrors.mixed = 'Either Fixed Amount or Percentage with Related To is required!';
    }

    // Additional validation for Fixed Amount if it's entered
    if (hasFixedAmount && !formData.fixed_amount) {
      newErrors.fixed_amount = 'Fixed Amount is required!';
    }

    // Additional validation for Percentage if it's entered
    if (hasPercentage) {
      if (!formData.percentage) {
        newErrors.percentage = 'Percentage of Amount is required!';
      }
      if (formData.percentage_of.length === 0) {
        newErrors.percentage_of = 'Related To is required!';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Handle multiple selections for 'percentage_of'
    if (name === "percentage_of") {
      const options = Array.from(e.target.selectedOptions, option => option.value);
      setFormData({ ...formData, percentage_of: options });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    setInputState({ ...inputState, [name]: value.trim() ? "green" : "" });
  };

  const handleHeadingChange = (e) => {
    const { value } = e.target;
    const selectedHeading = salaryStructureHeadings.find(heading => heading.id === value);

    setFormData({
      ...formData,
      name: selectedHeading ? selectedHeading.id : "",
      salary_type: selectedHeading ? selectedHeading.salary_type : "",
    });

    setInputState({
      ...inputState,
      name: value.trim() ? "green" : "",
    });
  };
  const handleSelectChange = (selectedOptions) => {
    setFormData({
      ...formData,
      percentage_of: selectedOptions ? selectedOptions.map(option => option.value) : [],
    });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const formattedString = formData.percentage_of.join(", ");
    const formattedFormData = {
      ...formData,
      percentage_of: formattedString,
    };

    setLoading(true);
    // const loadingToastId = toast.loading("Loading: Please wait...");

    try {
      const res = await axios.post(`${BASE_URL}/siteproject/customdeduction/`, formData);

      if (res.status === 200 || res.status === 201) {
        // toast.success("Adding Deduction");
        await getAllSalaryRelatedData(code);
        handleClose();
      } else {
        // toast.error("Error adding deduction");
        console.log("Error adding deduction");
      }
    } catch (err) {
      // toast.error("An error occurred while adding the deduction");
      console.log("An error occurred while adding the deduction");
      // handleAllError(err);
    } finally {
      setLoading(false);
      // toast.dismiss(loadingToastId);
      // await getAllSalaryRelatedData(code);
      // handleClose();
    }
  };
  const deductionIds = deductionData.map(item => item.name);
  const relatedToOptions = salaryStructureHeadings
    .filter(i => deductionIds.includes(i.id))
    .sort((a, b) => a.sorting_var - b.sorting_var)
    .map(i => ({ value: i.title, label: i.title }));

  const headingOptions = salaryStructureHeadings
    // .filter(i => deductionIds.includes(i.id))
    .sort((a, b) => a.sorting_var - b.sorting_var)
    .map(i => ({ value: i.id, label: i.title }));

  const relatedToHeadingOptions = [
    { value: 'Gross', label: 'Gross' },
    ...salaryStructureHeadings
      // .filter(i => !deductionIds.includes(i.id))
      .sort((a, b) => a.sorting_var - b.sorting_var)
      .map(i => ({ value: i.id, label: i.title })),
  ];

  return (
    <div>
      <button title='Add Deduction' className="model-button model-button-leave" onClick={handleShow}>
        Add
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Add Custom Deduction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
          <form className="register-form" onSubmit={handleFormSubmit}>
            <div className="form-flex-wrap">
              <div title="Salary Structure Heading" className="flex-column form-group-selectt">
                <label htmlFor="name" className="form-labels font-weight500 font-size-subheading">
                  Salary Structure Heading<span className="required">*</span>
                </label>
                <br />
                <select
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleHeadingChange}
                  className={`form-input form-group-selection ${errors.name ? "error" : inputState.name ? "success" : ""}`}
                >
                  <option value="">Select Salary Heading</option>
                  {salaryStructureHeadings
                    ?.filter(i => !deductionIds.includes(i.id))
                    .map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.title}
                      </option>
                    ))}
                </select>
                {/* <br /> */}
                <div>
                  {errors.name && <span className="error-message font-size-text">{errors.name}</span>}
                </div>
              </div>

              {formData.percentage || formData.percentage_of.length > 0 ? null : (
                <div className="flex-column">
                  <label className="form-labels font-weight500 font-size-subheading">
                    Fixed Amount<span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    name="fixed_amount"
                    placeholder="Fixed Amount"
                    min={0}
                    value={formData.fixed_amount}
                    onChange={handleInputChange}
                    className={`form-input ${errors.fixed_amount ? 'is-invalid' : ''}`}
                  />
                  {errors.fixed_amount && <div className="error-message font-size-text">{errors.fixed_amount}</div>}
                </div>
              )}
              {formData.fixed_amount ? null : (
                <>
                  <div className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading">
                      Percentage of Amount<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      name="percentage"
                      placeholder="Percentage of Amount"
                      min={0}
                      step={0.01}
                      value={formData.percentage}
                      onChange={handleInputChange}
                      className={`form-input ${errors.percentage ? 'is-invalid' : ''}`}
                    />
                    {errors.percentage && <div className="error-message font-size-text">{errors.percentage}</div>}
                  </div>

                  <div title="Related To" className="flex-column">
                    <label className="form-labels font-weight500 font-size-subheading">
                      Related To<span className="required">*</span>
                    </label>
                    <Select
                      isMulti
                      name="percentage_of"
                      value={relatedToOptions.filter(option => formData.percentage_of?.includes(option.value))}
                      onChange={(selectedOptions) => handleSelectChange(selectedOptions)}
                      options={relatedToOptions}
                      className={`width-15vw ${errors.percentage_of ? 'is-invalid' : ''}`}
                      classNamePrefix="select"
                      placeholder="Select Related To"
                      isDisabled={!!formData.fixed_amount} // Disable if fixed amount is provided
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '33vw',
                          minHeight: '50px',
                          borderRadius: '10px',
                          border: '1px solid #e3e3e3',
                          margin: '10px 0px 20px 0px',
                          color: '#4a4a4a',
                          backgroundColor: '#f7f8fa',
                          padding: '0px 10px',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: '#4a4a4a',
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: '33vw',
                          borderRadius: '10px',
                          marginTop: '0',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: '#4a4a4a',
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: '#4a4a4a',
                        }),
                      }}
                    />
                    {errors.percentage_of && <div className="error-message font-size-text">{errors.percentage_of}</div>}
                  </div>
                </>
              )}
            </div>
            {errors.mixed && <div className="error-message font-size-text">{errors.mixed}</div>}
            <div className="button-models">
              <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button className="model-button font-weight500 model-button-leave font-size-heading" disabled={loading}>
                {loading ? 'Adding...' : 'Add'}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const UpdateCustomDeductions = ({ i, code, deductionData, salaryStructureHeadings, getAllSalaryRelatedData }) => {

  const [formData, setFormData] = useState({
    name: i.id,
    salary_structure: "",
    salary_type: i.salary_type,
    percentage: '',
    percentage_of: [],
    fixed_amount: '',
  })
  const [show, setShow] = useState(false);
  const [selectedPercentageOf, setSelectedPercentageOf] = useState([]);

  const handleShow = () => {
    const transformedData = {
      ...i,
      percentage_of: Array.isArray(i.percentage_of) ? i.percentage_of.map(item => ({
        value: item.id || item.value,
        label: item.name || item.label,
      })) : [],
    };

    setFormData(transformedData);
    setSelectedPercentageOf(transformedData.percentage_of); // Set selected items for multi-select
    // setFormData(is);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  }

  const [inputState, setInputState] = useState({});
  const [errors, setErrors] = useState({});


  const validateForm = () => {
    const newErrors = {};
    const isSalaryHeadingSelected = !!formData.name;
    const hasFixedAmount = formData.fixed_amount !== '';
    const hasPercentage = formData.percentage !== '' && formData.percentage_of.length > 0;

    // Validate Salary Heading
    if (!isSalaryHeadingSelected) {
      newErrors.name = 'Salary Heading is required!';
    }

    // Validation for Fixed Amount or Percentage
    if (!hasFixedAmount && !hasPercentage) {
      newErrors.mixed = 'Either Fixed Amount or Percentage with Related To is required!';
    }

    // Additional validation for Fixed Amount if it's entered
    if (hasFixedAmount && !formData.fixed_amount) {
      newErrors.fixed_amount = 'Fixed Amount is required!';
    }

    // Additional validation for Percentage if it's entered
    if (hasPercentage) {
      if (!formData.percentage) {
        newErrors.percentage = 'Percentage of Amount is required!';
      }
      if (formData.percentage_of.length === 0) {
        newErrors.percentage_of = 'Related To is required!';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedPercentageOf(selectedOptions);
    setFormData({
      ...formData,
      percentage_of: selectedOptions ? selectedOptions.map(option => ({ value: option.value, label: option.label })) : [],
    });
  };
  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // if (!validateForm()) return;
    setLoading(true); //loading logic

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    let sucessToastId;
    const listFormData = [
      formData
    ]
    const formattedString = formData.percentage_of.join(", ");
    const formattedFormData = {
      ...formData,
      percentage_of: formattedString,
    };
    const listformattedFormData = [
      formattedFormData
    ];

    const updatedFormData = {
      ...formData,
      percentage_of: formData.percentage_of.map(item => item.label), // Only include labels in the array
    };


    console.log("updatedFormData")
    console.log(updatedFormData)


    try {
      let ress = await axios.put(`${BASE_URL}/siteproject/customdeduction/${i.id}/`, updatedFormData);

      if (ress.status === 200 || ress.status === 201) {
        toast.success("Updated Deduction");
        await getAllSalaryRelatedData(code);
        handleClose();

      }
      //  else {
      //     alert(ress);
      // }
    } catch (err) {
      //toast Logic
      handleErrorToast(err);
      // toast.error("An error occurred while Updating the deduction");
    } finally {
      setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
      toast.dismiss(loadingToastId);
    }

  };
  const deductionIds = deductionData.map(item => item.name);
  const relatedToOptions = salaryStructureHeadings
    .filter(i => deductionIds.includes(i.id))
    .sort((a, b) => a.sorting_var - b.sorting_var)
    .map(i => ({ value: i.title, label: i.title }));

  const headingOptions = salaryStructureHeadings
    // .filter(i => deductionIds.includes(i.id))
    .sort((a, b) => a.sorting_var - b.sorting_var)
    .map(i => ({ value: i.id, label: i.title }));

  const relatedToHeadingOptions = [
    { value: 'Gross', label: 'Gross' },
    ...salaryStructureHeadings
      // .filter(i => !deductionIds.includes(i.id))
      .sort((a, b) => a.sorting_var - b.sorting_var)
      .map(i => ({ value: i.id, label: i.title })),
  ];

  console.log("formData")
  console.log(formData)
  return (
    <div>
      <>
        <button title='Update Deduction' className='model-edit-button' onClick={handleShow}>
          <UpdatePencil />
        </button>
        <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
          <Modal.Header closeButton>
            <Modal.Title>Update Deduction of : "{salaryStructureHeadings?.find(t => t.id === formData.name)?.title} ({salaryStructureHeadings?.find(t => t.id === formData.name)?.salary_type})"</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <form className="register-form">
              <div className="">
                {formData.percentage || formData.percentage_of.length > 0 ? "" :
                  <div className="flex-column">
                    <label className="form-labels  font-weight500 font-size-subheading">Fixed Amount<span className="required">*</span></label>
                    <input
                      type="number"
                      name="fixed_amount"
                      placeholder="Fixed Amount"
                      min={0}
                      value={formData.fixed_amount}
                      onChange={handleInputChange}
                      className={`form-input ${errors.fixed_amount ? 'is-invalid' : ''}`}
                    />
                    {errors.fixed_amount && <div className="error-message font-size-text">{errors.fixed_amount}</div>}
                  </div>
                }
                {formData.fixed_amount ? "" :
                  <>
                    <div className="flex-column">
                      <label className="form-labels  font-weight500 font-size-subheading">Percentage of Amount<span className="required">*</span></label>
                      <input
                        type="number"
                        name="percentage"
                        placeholder="Percentage of Amount"
                        min={0}
                        value={formData.percentage}
                        onChange={handleInputChange}
                        className={`form-input ${errors.percentage ? 'is-invalid' : ''}`}
                      />
                      {errors.percentage && <div className="error-message font-size-text">{errors.percentage}</div>}
                    </div>
                    <div title="Related To" className="flex-column ">
                      <label className="form-labels  font-weight500 font-size-subheading">Related To<span className="required">*</span></label>

                      <Select
                        isMulti
                        name="percentage_of"
                        value={selectedPercentageOf}
                        onChange={handleSelectChange}
                        options={relatedToOptions}
                        className={`width-15vw ${errors.percentage_of ? 'is-invalid' : ''}`}
                        classNamePrefix="select"
                        placeholder="Select Related To"
                        isDisabled={!!formData.fixed_amount}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '33vw',
                            minHeight: '50px',
                            borderRadius: '10px',
                            border: '1px solid #e3e3e3',
                            margin: '10px 0px 20px 0px',
                            color: '#4a4a4a',
                            backgroundColor: '#f7f8fa',
                            padding: '0px 10px',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            width: '33vw',
                            borderRadius: '10px',
                            marginTop: '0',
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                          input: (provided) => ({
                            ...provided,
                            color: '#4a4a4a',
                          }),
                        }}
                      />
                      {errors.percentage_of && <div className="error-message font-size-text">{errors.percentage_of}</div>}
                    </div>
                  </>
                }
              </div>
            </form>
            {errors.mixed && <div className="error-message font-size-text">{errors.mixed}</div>}
            <div className="button-models">
              <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button className="model-button  font-weight500  model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                {loading ? 'Updating...' : 'Update'}
              </button>
            </div>

          </Modal.Body>
        </Modal>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>

    </div>
  )

}

const DeleteCustomDeductions = ({ i, code, deductionData, salaryStructureHeadings, getAllSalaryRelatedData }) => {

  const [formData, setFormData] = useState({
    name: i.id,
    salary_structure: "",
    salary_type: i.salary_type,
    percentage: '',
    percentage_of: [],
    fixed_amount: '',
  })
  const [show, setShow] = useState(false);
  const [selectedPercentageOf, setSelectedPercentageOf] = useState([]);

  const handleShow = () => {
    const transformedData = {
      ...i,
      percentage_of: Array.isArray(i.percentage_of) ? i.percentage_of.map(item => ({
        value: item.id || item.value,
        label: item.name || item.label,
      })) : [],
    };

    setFormData(transformedData);
    setSelectedPercentageOf(transformedData.percentage_of); // Set selected items for multi-select
    // setFormData(is);
    setShow(true);


  };

  const handleClose = () => {
    setShow(false);
  }

  const [inputState, setInputState] = useState({});
  const [errors, setErrors] = useState({});



  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // if (!validateForm()) return;
    setLoading(true); //loading logic

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    let sucessToastId;
    const listFormData = [
      formData
    ]
    const formattedString = formData.percentage_of.join(", ");
    const formattedFormData = {
      ...formData,
      percentage_of: formattedString,
    };
    const listformattedFormData = [
      formattedFormData
    ];

    const updatedFormData = {
      ...formData,
      percentage_of: formData.percentage_of.map(item => item.lable), // Only include labels in the array
    };

    try {
      let ress = await axios.delete(`${BASE_URL}/siteproject/customdeduction/${i.id}/`);

      if (ress.status === 200 || ress.status === 201) {
        toast.success("Deleted Deduction Successfully");
        await getAllSalaryRelatedData(code);
        handleClose();
      }
      //  else {
      //     alert(ress);
      // }
    } catch (err) {
      //toast Logic
      // handleAllError(err);
      // toast.error("An error occurred while Updating the deduction");
    } finally {
      setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
      toast.dismiss(loadingToastId);
    }

  };
  const deductionIds = deductionData.map(item => item.name);
  const relatedToOptions = salaryStructureHeadings
    .filter(i => deductionIds.includes(i.id))
    .sort((a, b) => a.sorting_var - b.sorting_var)
    .map(i => ({ value: i.title, label: i.title }));


  return (
    <div>
      <>
        <button title='Delete Deduction' className='model-delete-button' onClick={handleShow}>
          <DeleteDustbin />
        </button>
        <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
          <Modal.Header closeButton>
            <Modal.Title>Delete Deduction of : "{salaryStructureHeadings?.find(t => t.id === formData.name)?.title} ({salaryStructureHeadings?.find(t => t.id === formData.name)?.salary_type})"</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <form className="register-form">
              <div className="">
                {formData.percentage || formData.percentage_of.length > 0 ? "" :

                  <div className="flex-column">
                    <label className="form-labels  font-weight500 font-size-subheading">Amount :</label>

                    <div className='form-labels  justify-center'>
                      {formData?.fixed_amount}
                    </div>
                  </div>
                  // <div className="flex-column">
                  //         <label className="form-labels  font-weight500 font-size-subheading">Fixed Amount<span className="required">*</span></label>
                  //         <input
                  //             type="number"
                  //             name="fixed_amount"
                  //             placeholder="Fixed Amount"
                  //             min={0}
                  //             value={formData.fixed_amount}
                  //             className={`form-input ${errors.fixed_amount ? 'is-invalid' : ''}`}
                  //         />
                  //         {errors.fixed_amount && <div className="error-message font-size-text">{errors.fixed_amount}</div>}
                  //     </div>
                }
                {formData.fixed_amount ? "" :
                  <>
                    <div className="flex-column">
                      <label className="form-labels  font-weight500 font-size-subheading">Percentage:</label>

                      <div className='form-labels  justify-center'>
                        {formData?.percentage}   %
                      </div>
                    </div>
                    <div title="Related To" className="flex-column ">
                      <label className="form-labels  font-weight500 font-size-subheading">Related To :</label>

                      <ul>
                        {formData?.percentage_of ?
                          formData?.percentage_of.map((item, index) => {

                            return (
                              <li key={index}>
                                {item?.label}
                              </li>)
                          }) :
                          null
                        }
                      </ul>
                      {errors.percentage_of && <div className="error-message font-size-text">{errors.percentage_of}</div>}
                    </div>
                  </>
                }
              </div>
            </form>
            {errors.mixed && <div className="error-message font-size-text">{errors.mixed}</div>}
            <div className="button-models">
              <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button className="model-button  font-weight500  model-button-delete font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                {loading ? 'Deleting...' : 'Delete'}
              </button>
            </div>

          </Modal.Body>
        </Modal>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    </div>
  )
}



// ! ***************  SalaryDeductionPolicy Crud Models Start ***************

const SalaryDeductionPolicyAdd = ({ getSalaryDeductionPolicyList }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: '',
    epf: false,
    epf_criteria: '',
    epf_employee: false,
    epf_employer: false,
    epf_employee_rate: '',
    epf_employer_rate: '',
    esi: false,
    esi_criteria: '',
    esi_employee: false,
    esi_employer: false,
    esi_employee_rate: '',
    esi_employer_rate: '',
    sub_company: '',
  });

  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'name',
      'epf_criteria',
      'epf_employee_rate',
      'epf_employer_rate',
      'esi_criteria',
      'esi_employee_rate',
      'esi_employer_rate',
      'sub_company',
    ];


    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    // // Add more validation for numerical fields as needed
    // if (formData.epf_criteria && isNaN(formData.epf_criteria)) {
    //   newErrors.epf_criteria = 'EPF criteria must be a valid number!';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/siteproject/salarypolicy/`, formData);
        if (res.status === 200) {
          await getSalaryDeductionPolicyList();
          setShow(false);
          // toast.success('Salary Policy created successfully!');
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="upload-svg" title="Add Salary Deduction Policy" onClick={handleShow}>
        <AddwithWhiteCircle />
        {" "} Salary Deduction Policy
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Add Salary Deduction Policy</Modal.Title></h1>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading" title="Name">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  title="Name"
                  name="name"
                  placeholder="Salary Deduction Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading" title="Sub Company">
                  Sub Company<span className="required">*</span>
                </label>
                <select
                  id="sub_company"
                  name="sub_company"
                  title="Sub Company"
                  value={formData.sub_company}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                    }`}
                >
                  <option value='' title="Select Sub Company">Select Sub Company</option>
                  {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                </select>
                {errors.sub_company && (
                  <span className="error-message font-size-text ">{errors.sub_company}</span>
                )}
              </div>

              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading" title="EPF">
                  EPF
                </label>
                <input
                  type="checkbox"
                  name="epf"
                  title="EPF"
                  onChange={handleInputChange}
                  checked={formData.epf}
                  className={`inputform-checkbox`}
                />
              </div>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading" title="EPF Criteria">
                  EPF Criteria
                </label>
                <input
                  id="epf_criteria"
                  name="epf_criteria"
                  title="EPF Criteria"
                  value={formData.epf_criteria}
                  placeholder="00000.00"
                  type="number"
                  min={0}
                  maxLength={5}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                    if (regex.test(value) || value === "") {
                      handleInputChange(e); // Only update if the input matches the pattern
                    }
                  }}
                  // onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.epf_criteria ? "error" : inputState.epf_criteria ? "success" : ""
                    }`}
                >
                </input>
                {errors.epf_criteria && (
                  <span className="error-message font-size-text ">{errors.epf_criteria}</span>
                )}
              </div>
              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading" title="Employee Contribution">
                      Employee Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="epf_employee"
                      title="Employee Contribution"
                      onChange={handleInputChange}
                      checked={formData.epf_employee}
                      className={`inputform-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading" title="Employee Rate">
                        Employee Rate
                      </label>
                      <input
                        id="epf_employee_rate"
                        name="epf_employee_rate"
                        title="Employee Rate"
                        value={formData.epf_employee_rate}
                        placeholder="0.00"
                        type="number"
                        min={0}
                        maxLength={5}
                        step="0.0001"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d{0,5}(\.\d{0,4})?$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange(e); // Only update if the input matches the pattern
                          }
                        }}
                        // onChange={handleInputChange}
                        className={`multi-day-input1 form-input-background`}
                      />
                      <br />
                      {errors.epf_employee_rate && (
                        <span className="error-message font-size-text ">{errors.epf_employee_rate}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading" title="Employer Contribution">
                      Employer Contribution
                    </label>
                    <input
                      type="checkbox"
                      title="Employer Contribution"
                      name="epf_employer"
                      checked={formData.epf_employer}
                      onChange={handleInputChange}
                      className={`inputform-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="epf_employer_rate" className="form-labels  font-weight500    font-size-subheading" title="Employer Rate">
                      Employer  Rate
                    </label>
                    <input
                      id="epf_employer_rate"
                      name="epf_employer_rate"
                      title="Employer Rate"
                      value={formData.epf_employer_rate}
                      placeholder="0.00"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.0001"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d{0,5}(\.\d{0,4})?$/;
                        if (regex.test(value) || value === "") {
                          handleInputChange(e); // Only update if the input matches the pattern
                        }
                      }}
                      // onChange={handleInputChange}
                      className={`multi-day-input1 form-input-background`}
                    />
                    <br />
                    {errors.epf_employer_rate && (
                      <span className="error-message font-size-text ">{errors.epf_employer_rate}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading" title="ESI">
                  ESI
                </label>
                <input
                  type="checkbox"
                  name="esi"
                  title="ESI"
                  onChange={handleInputChange}
                  checked={formData.esi}
                  className={`inputform-checkbox`}
                />
              </div>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading" title="ESI Creteria">
                  ESI Criteria
                </label>
                <input
                  id="esi_criteria"
                  name="esi_criteria"
                  title="ESI Criteria"
                  value={formData.esi_criteria}
                  placeholder="00000.00"
                  type="number"
                  min={0}
                  maxLength={5}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                    if (regex.test(value) || value === "") {
                      handleInputChange(e); // Only update if the input matches the pattern
                    }
                  }}
                  // onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.esi_criteria ? "error" : inputState.esi_criteria ? "success" : ""
                    }`}
                >
                </input>
                {errors.esi_criteria && (
                  <span className="error-message font-size-text ">{errors.esi_criteria}</span>
                )}
              </div>
              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading" title="Employee Contribution">
                      Employee Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="esi_employee"
                      title="Employee Contribution"
                      onChange={handleInputChange}
                      checked={formData.esi_employee}
                      className={`inputform-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading" title="Employee Rate">
                        Employee Rate
                      </label>
                      <input
                        id="esi_employee_rate"
                        name="esi_employee_rate"
                        title="Employee Rate"
                        value={formData.esi_employee_rate}
                        placeholder="0.00"
                        type="number"
                        min={0}
                        maxLength={5}
                        step="0.0001"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d{0,5}(\.\d{0,4})?$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange(e); // Only update if the input matches the pattern
                          }
                        }}
                        // onChange={handleInputChange}
                        className={`multi-day-input1 form-input-background`}
                      />
                      <br />
                      {errors.esi_employee_rate && (
                        <span className="error-message font-size-text ">{errors.esi_employee_rate}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading" title="Employer Contribution">
                      Employer Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="esi_employer"
                      title="Employer Contribution"
                      onChange={handleInputChange}
                      checked={formData.esi_employer}
                      className={`inputform-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="esi_employer_rate" className="form-labels  font-weight500    font-size-subheading" title="Employer Rate">
                      Employer Rate
                    </label>
                    <input
                      id="esi_employer_rate"
                      name="esi_employer_rate"
                      title="Employer Rate"
                      value={formData.esi_employer_rate}
                      placeholder="0.00"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.0001"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d{0,5}(\.\d{0,4})?$/;
                        if (regex.test(value) || value === "") {
                          handleInputChange(e); // Only update if the input matches the pattern
                        }
                      }}
                      // onChange={handleInputChange}
                      className={`multi-day-input1 form-input-background`}
                    />
                    <br />
                    {errors.esi_employer_rate && (
                      <span className="error-message font-size-text ">{errors.esi_employer_rate}</span>
                    )}
                  </div>
                </div>
              </div>


              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


const SalaryDeductionPolicyUpdate = ({ i, getSalaryDeductionPolicyList }) => {
  const [show, setShow] = useState(false);
  const { SUB_COMPANIES } = usePermission();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
    console.log(SUB_COMPANIES)
  };

  const [formData, setFormData] = useState({
    name: '',
    epf: false,
    epf_criteria: '',
    epf_employee: false,
    epf_employer: false,
    epf_employee_rate: '',
    epf_employer_rate: '',
    esi: false,
    esi_criteria: '',
    esi_employee: false,
    esi_employer: false,
    esi_employee_rate: '',
    esi_employer_rate: '',
    sub_company: '',
  });
  console.log("formData")
  console.log(formData)
  // Validation
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'name',
      'epf_criteria',
      'epf_employee_rate',
      'epf_employer_rate',
      'esi_criteria',
      'esi_employee_rate',
      'esi_employer_rate',
      'sub_company',
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });



    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const loadingToastId = toast.loading('Loading: Please wait...');
      setLoading(true); //loading logic
      try {
        let res = await axios.put(
          `${BASE_URL}/siteproject/salarypolicy/${i.id}/`,
          formData
        );
        if (res.status === 200) {
          await getSalaryDeductionPolicyList();
          setShow(false);
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Update Salary Deduction Policy" onClick={handleShow}>
        <UpdatePencil />
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">

        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Update Salary Deduction Policy: <br />{i.name}</Modal.Title></h1>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Salary Deduction Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message font-size-text ">{errors.name}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="sub_company" className="form-labels  font-weight500    font-size-subheading">
                  Sub Company<span className="required">*</span>
                </label>
                <select
                  id="sub_company"
                  name="sub_company"
                  value={formData.sub_company}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.sub_company ? "error" : inputState.sub_company ? "success" : ""
                    }`}
                >
                  <option value=''>Select Sub Company</option>
                  {SUB_COMPANIES.map(company => (<option value={company.id}>{company.title}</option>))};
                </select>
                {errors.sub_company && (
                  <span className="error-message font-size-text ">{errors.sub_company}</span>
                )}
              </div>

              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  EPF
                </label>
                <input
                  type="checkbox"
                  name="epf"
                  onChange={handleInputChange}
                  checked={formData.epf}
                  className={`inputform-checkbox`}
                />
              </div>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  EPF Criteria
                </label>
                <input
                  id="epf_criteria"
                  name="epf_criteria"
                  value={formData.epf_criteria}
                  placeholder="00000.00"
                  type="number"
                  min={0}
                  maxLength={5}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                    if (regex.test(value) || value === "") {
                      handleInputChange(e); // Only update if the input matches the pattern
                    }
                  }}
                  // onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.epf_criteria ? "error" : inputState.epf_criteria ? "success" : ""
                    }`}
                >
                </input>
                {errors.epf_criteria && (
                  <span className="error-message font-size-text ">{errors.epf_criteria}</span>
                )}
              </div>
              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employee Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="epf_employee"
                      onChange={handleInputChange}
                      checked={formData.epf_employee}
                      className={`inputform-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        Employee Rate
                      </label>
                      <input
                        id="epf_employee_rate"
                        name="epf_employee_rate"
                        value={formData.epf_employee_rate}
                        placeholder="0.00"
                        type="number"
                        min={0}
                        maxLength={5}
                        step="0.0001"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d{0,5}(\.\d{0,4})?$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange(e); // Only update if the input matches the pattern
                          }
                        }}
                        // onChange={handleInputChange}
                        className={`multi-day-input1 form-input-background`}
                      />
                      <br />
                      {errors.epf_employee_rate && (
                        <span className="error-message font-size-text ">{errors.epf_employee_rate}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employer Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="epf_employer"
                      checked={formData.epf_employer}
                      onChange={handleInputChange}
                      className={`inputform-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="epf_employer_rate" className="form-labels  font-weight500    font-size-subheading">
                      Employer  Rate
                    </label>
                    <input
                      id="epf_employer_rate"
                      name="epf_employer_rate"
                      value={formData.epf_employer_rate}
                      placeholder="0.00"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.0001"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d{0,5}(\.\d{0,4})?$/;
                        if (regex.test(value) || value === "") {
                          handleInputChange(e); // Only update if the input matches the pattern
                        }
                      }}
                      // onChange={handleInputChange}
                      className={`multi-day-input1 form-input-background`}
                    />
                    <br />
                    {errors.epf_employer_rate && (
                      <span className="error-message font-size-text ">{errors.epf_employer_rate}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  ESI
                </label>
                <input
                  type="checkbox"
                  name="esi"
                  onChange={handleInputChange}
                  checked={formData.esi}
                  className={`inputform-checkbox`}
                />
              </div>
              <div className="flex-column">
                <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                  ESI Criteria
                </label>
                <input
                  id="esi_criteria"
                  name="esi_criteria"
                  value={formData.esi_criteria}
                  placeholder="00000.00"
                  type="number"
                  min={0}
                  maxLength={5}
                  step="0.01"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                    if (regex.test(value) || value === "") {
                      handleInputChange(e); // Only update if the input matches the pattern
                    }
                  }}
                  // onChange={handleInputChange}
                  className={`form-input form-group-selection ${errors.esi_criteria ? "error" : inputState.esi_criteria ? "success" : ""
                    }`}
                >
                </input>
                {errors.esi_criteria && (
                  <span className="error-message font-size-text ">{errors.esi_criteria}</span>
                )}
              </div>
              <div className="flex-row justify-between">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employee Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="esi_employee"
                      onChange={handleInputChange}
                      checked={formData.esi_employee}
                      className={`inputform-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input width-15vw">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">
                        Employee Rate
                      </label>
                      <input
                        id="esi_employee_rate"
                        name="esi_employee_rate"
                        value={formData.esi_employee_rate}
                        placeholder="0.00"
                        type="number"
                        min={0}
                        maxLength={5}
                        step="0.0001"
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d{0,5}(\.\d{0,4})?$/;
                          if (regex.test(value) || value === "") {
                            handleInputChange(e); // Only update if the input matches the pattern
                          }
                        }}
                        // onChange={handleInputChange}
                        className={`multi-day-input1 form-input-background`}
                      />
                      <br />
                      {errors.esi_employee_rate && (
                        <span className="error-message font-size-text ">{errors.esi_employee_rate}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels  font-weight500    font-size-subheading">
                      Employer Contribution
                    </label>
                    <input
                      type="checkbox"
                      name="esi_employer"
                      onChange={handleInputChange}
                      checked={formData.esi_employer}
                      className={`inputform-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="esi_employer_rate" className="form-labels  font-weight500    font-size-subheading">
                      Employer Rate
                    </label>
                    <input
                      id="esi_employer_rate"
                      name="esi_employer_rate"
                      value={formData.esi_employer_rate}
                      placeholder="0.00"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.0001"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^\d{0,5}(\.\d{0,4})?$/;
                        if (regex.test(value) || value === "") {
                          handleInputChange(e); // Only update if the input matches the pattern
                        }
                      }}
                      // onChange={handleInputChange}
                      className={`multi-day-input1 form-input-background`}
                    />
                    <br />
                    {errors.esi_employer_rate && (
                      <span className="error-message font-size-text ">{errors.esi_employer_rate}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};


const SalaryDeductionPolicyDelete = ({ i, getSalaryDeductionPolicyList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/salarypolicy/${i.id}/`);

      if (res.status === 200) {
        await getSalaryDeductionPolicyList();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleErrorToast(err, loadingToastId)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete Salary Deduction Policy" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Salary Deduction Policy : {i.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Salary Deduction Policy : {i.name}
            <div className="button-models">
              <button className="model-button   font-weight500    " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button   font-weight500    model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** SalaryDeductionPolicy Crud Models End ***************



export {
  UploadSalaryDataBulk,
  RunPayRoll,

  SalaryStructureHeadingAdd,
  SalaryStructureHeadingUpdate,
  SalaryStructureHeadingDelete,

  EmployeeSalaryStructure,
  UserCustomDeductions,
  AddCustomDeductions,
  DeleteCustomDeductions,

  SalaryDeductionPolicyAdd,
  SalaryDeductionPolicyUpdate,
  SalaryDeductionPolicyDelete,
};
