import React, { useEffect, useState, useRef } from "react";
import usePermission from "../../config/permissions";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { toast, ToastContainer } from "react-toastify";


import { formatDate, formattedDateLong } from "../Date";
import { AddProjectDetails } from "./ProjectDetailsCrudButtons";

import { useNavigate } from "react-router-dom";
import { ProjectProgressBar } from "./SiteProjectManagementComponents";
import Dashboardnavbar from "../../layout/Dashboardnavbar";
import { formatCurrencyIndian, formatRoundoff, formatRoundoffCrores, handleErrorToast, sortProjects } from "../CustomFunctions";
import { CircularGrid, DeleteDustbin, Eye, SearchBig, SearchSmall, Sort } from "../AllSvg";
import { Modal } from "react-bootstrap";
import { SubCompany } from "../FilterDropdown";
import { UploadSiteProject } from "./BulkModals";
import { Progressreport } from "../Wfm/DetailPage";
import { EOTDetails } from "../Wfm/DetailPage";
import { COSDetails } from "../Wfm/DetailPage";
import { MilestoneDetails } from "../Wfm/DetailPage";
import { BillingDetailsProgress } from "../Wfm/DetailPage";
import { BGDetails } from "../Wfm/DetailPage";
import { InsuranceDetails } from "../Wfm/DetailPage";



const DeleteProject = ({ i, fetchContractorProjects }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.delete(`${BASE_URL}/siteproject/project/${i.id}/`);

      if (res.status === 200) {
        await fetchContractorProjects();
        handleClose();
        toast.dismiss(loadingToastId);
        toast.success("Data deleted successfully.");

      } else {
        toast.dismiss(loadingToastId);
        toast.error("Failed to delete data!");
      }
    } catch (err) {
      //toast Logic
      handleErrorToast(err)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <button title="Delete Project" className="model-delete-button" onClick={handleShow}>
        <DeleteDustbin />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Delete Project {i.project_short_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Project {i.project_short_name}
            <div className="button-models">
              <button className="model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button font-weight500  model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
};



const SiteProjectTable = () => {

  const [toggleCompany, setToggleCompany] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [selectedCC, setSelectedCC] = useState('contractor');
  const [sorting, setSorting] = useState("asc_pr");
  const [ProjectList, setProjectList] = useState([]);
  const role = sessionStorage.getItem('sitemanagement_role');
  const [contractorProject, setcontractorProject] = useState([]);
  const [filteredProject, setFilteredProject] = useState([]);
  const [buffer, setBuffering] = useState(true);


  const getProjects = async () => {
    const role = sessionStorage.getItem("sitemanagement_role");
    const empId = sessionStorage.getItem("sitemanagement_emp_id");

    let endpoint = "";

    if (role === "Admin") {
      endpoint = `${BASE_URL}/siteproject/projectdashboard/${selectedCC}/${toggleCompany || "null"}/`;
    } else if (role === "AuthorityEngineer") {
      endpoint = `${BASE_URL}/siteproject/projectdashboardauth/${selectedCC}/${empId}/`;
    } else if (role === "DataEntryOperator") {
      endpoint = `${BASE_URL}/siteproject/projectdashboardcomp/${selectedCC}/${empId}/`;
    }



    const pro = await axios.get(endpoint);
    setProjectList(pro.data);
  }
  // Function to fetch projects for contractors
  const fetchContractorProjects = async () => {
    setBuffering(true);
    const role = sessionStorage.getItem("sitemanagement_role");
    const empId = sessionStorage.getItem("sitemanagement_emp_id");

    let endpoint = "";

    if (role === "Admin") {
      endpoint = `${BASE_URL}/siteproject/projectdashboard/${selectedCC}/${toggleCompany || "null"}/`;
    } else if (role === "AuthorityEngineer") {
      endpoint = `${BASE_URL}/siteproject/projectdashboardauth/${selectedCC}/${empId}/`;
    } else if (role === "DataEntryOperator") {
      endpoint = `${BASE_URL}/siteproject/projectdashboardcomp/${selectedCC}/${empId}/`;
    }

    try {

      // const response = await axios.get(`${BASE_URL}/siteproject/projectdashboard/contractor/`);
      const response = await axios.get(endpoint);

      let filteredProjects = response.data;

      // Apply sub-company filter
      if (toggleCompany && toggleCompany !== 'null') {
        filteredProjects = filteredProjects.filter(
          (project) => project.sub_company === toggleCompany
        );
      }

      if (selectedName) {
        const lowerCaseName = selectedName.toLowerCase();
        filteredProjects = filteredProjects.filter(
          (project) =>
            project.site_prcode.toString().toLowerCase().includes(lowerCaseName) ||
            project.project_name.toLowerCase().includes(lowerCaseName) ||
            project.project_short_name.toLowerCase().includes(lowerCaseName)
        );
      }

      switch (sorting) {
        case "asc_pr":
          filteredProjects = sortProjects(filteredProjects, "site_prcode");
          break;
        case "desc_pr":
          filteredProjects = sortProjects(filteredProjects, "site_prcode").reverse();
          break;
        case "asc_short_name":
          filteredProjects = filteredProjects.sort((a, b) =>
            a.project_short_name.localeCompare(b.project_short_name)
          );
          break;
        case "desc_short_name":
          filteredProjects = filteredProjects.sort((a, b) =>
            b.project_short_name.localeCompare(a.project_short_name)
          );
          break;
        default:
          break;
      }

      setcontractorProject(filteredProjects);
      setFilteredProject(filteredProjects);
    } catch (error) {
      console.error('Error fetching contractor projects:', error);
    } finally {
      setBuffering(false);
    }
  };

  useEffect(() => {
    fetchContractorProjects();
  }, [toggleCompany, sorting]); // Refetch projects when filters change

  const applyFilters = () => {

    let filteredProjects = contractorProject;

    if (selectedName !== "") {
      const lowerCaseName = selectedName.toLowerCase();
      filteredProjects = filteredProjects.filter(
        (project) =>
          project.site_prcode.toString().toLowerCase().includes(lowerCaseName) ||
          project.project_name.toLowerCase().includes(lowerCaseName) ||
          project.project_short_name.toLowerCase().includes(lowerCaseName)
      );
    }

    setFilteredProject(filteredProjects);
  };


  useEffect(() => {
    getProjects();
  }, []);


  const [projectListfilter, setProjectListfilter] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          sessionStorage.getItem("sitemanagement_role") === "AuthorityEngineer"
            ? `${BASE_URL}/siteproject/projectfilterbyauthority_engineer/${sessionStorage.getItem("sitemanagement_emp_id")}/`
            : sessionStorage.getItem("sitemanagement_role") === "DataEntryOperator"
              ? `${BASE_URL}/siteproject/projectfilterbycom_operator/${sessionStorage.getItem("sitemanagement_emp_id")}/`
              : `${BASE_URL}/siteproject/projectfilterbysubcompany/${toggleCompany}/`
        );

        const sortedProjects = sortProjects(response.data, "site_prcode");
        setProjectListfilter(sortedProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, [toggleCompany]);


  // Event handlers
  const handleSortChange = (e) => setSorting(e.target.value);
  const handleCCChange = (e) => setSelectedCC(e.target.value);

  const handleNameChange = (e) => {
    const value = e.target.value;
    setSelectedName(value);

    if (value.trim() === "") {
      setFilteredProjects([]);
      setShowSuggestions(false);
      return;
    }

    const suggestions = projectListfilter.filter(project =>
      project.site_prcode?.toLowerCase()?.includes(value?.toLowerCase()) ||
      project.project_short_name?.toLowerCase()?.includes(value?.toLowerCase())
    );

    setFilteredProjects(suggestions);
    setShowSuggestions(true);
  };

  const handleSelect = (project) => {
    setSelectedName(project.project_short_name);
    setShowSuggestions(false);
  };


  useEffect(() => {
    applyFilters();
  }, [selectedName]);

  console.log(selectedName)

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dashboardnavbar name="All Projects" url="Projects" />

      <div className="content-tabs">
        <div>
          <div className="attendance-subcont">
            <div className="field-cont">
              <SubCompany toggleCompany={toggleCompany} setToggleCompany={setToggleCompany} />

              <div title="Sort Filter" className="field-cont-div">
                <Sort />
                <select
                  className="attendance-input-field width-15vw   date-field"
                  type="text"
                  value={sorting}
                  onChange={handleSortChange}
                >
                  <option value="asc_pr">Asc. Pr Code</option>
                  <option value="desc_pr">Desc. Pr Code</option>
                  <option value="asc_short_name">Asc. Project Name</option>
                  <option value="desc_short_name">Desc. Project Name</option>
                </select>
                <hr className="field-cont-hr" />
              </div>


              <div title="CC Filter" className="field-cont-div">
                <CircularGrid />
                <select
                  className="attendance-input-field width-10vw date-field"
                  type="text"
                  value={selectedCC}
                  onChange={handleCCChange}
                >
                  <option value="contractor">Contractor</option>
                  <option value="consultant">Consultant</option>
                </select>
                <hr className="field-cont-hr" />
              </div>

              <div className="field-cont-div">
                <SearchSmall />
                <input
                  className="attendance-input-field width-15vw"
                  placeholder=" Code / Project Name"
                  type="text"
                  value={selectedName}
                  onChange={handleNameChange}
                />



                {showSuggestions && filteredProjects.length > 0 && (


                  <select
                    className="attendance-input-field width-10vw date-field"
                    type="text"

                  >
                    {filteredProjects.map((project) => (
                      <option key={project.site_prcode} onClick={() => handleSelect(project)}>
                        {project.project_short_name} ({project.site_prcode})
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            {role === "Hr" || role === "Account" ? "" :
              <div className="btn-cont">
                <AddProjectDetails getProjects={getProjects} />
                {role === "Admin" &&
                  <UploadSiteProject getProject={getProjects} ProjectList={ProjectList} getContractorProjects={fetchContractorProjects} />
                }
              </div>
            }
          </div>
        </div>

        {/* Conditionally render the views based on selectedCC */}
        {selectedCC === 'contractor' ? (
          <ProjectContractorView
            projectList={filteredProject}
            fetchContractorProjects={fetchContractorProjects}
            buffer={buffer}
          />
        ) : (
          <ProjectConsultantView
            subCompany={toggleCompany}
            searchName={selectedName}
            searchSort={sorting}
          />
        )}
      </div>
    </>
  );
};


const Detailspage = () => {


  const siteRole = sessionStorage.getItem("sitemanagement_role")

  const [toggleState, setToggleState] = useState(1);
  const getSelectedTitle = () => {
    switch (toggleState) {

      case 1:
        return 'Site Progress';
      case 2:
        return 'Extension of Time';
      case 3:
        return 'Change of Scope';
      case 4:
        return 'Milestone Details';
      case 5:
        return 'Billing Details'
      default:
        return 'Project Progress';
    }
  };


  return (
    <>
      <Dashboardnavbar name={getSelectedTitle()} url="Details Page" />


      <div className='content-tabs'>
        <div className="toggle-button-area" style={{ padding: "15px 30px" }}>
          <button
            className={toggleState === 1 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
            onClick={() => setToggleState(1)}
          >
            Progress
          </button>
          {
            siteRole === "BD" ? "" :
              <>
                <button
                  className={toggleState === 2 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                  onClick={() => setToggleState(2)}
                >
                  EOT
                </button>


                <button
                  className={toggleState === 3 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
                  onClick={() => setToggleState(3)}
                >
                  COS
                </button>
              </>
          }
          <button
            className={toggleState === 4 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
            onClick={() => setToggleState(4)}
          >
            Milestone
          </button>
          <button
            className={toggleState === 5 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
            onClick={() => setToggleState(5)}
          >
            Billing
          </button>
          <button
            className={toggleState === 6 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
            onClick={() => setToggleState(6)}
          >
            Bank Guarantee
          </button>
          <button
            className={toggleState === 7 ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
            onClick={() => setToggleState(7)}
          >
            Insurance
          </button>
        </div>
      </div>

      <div className="content-tabs">
        {toggleState === 1 ? <Progressreport /> : null}
        {toggleState === 2 ? <EOTDetails /> : null}
        {toggleState === 3 ? <COSDetails /> : null}
        {toggleState === 4 ? <MilestoneDetails /> : null}
        {toggleState === 5 ? <BillingDetailsProgress /> : null}
        {toggleState === 6 ? <BGDetails /> : null}
        {toggleState === 7 ? <InsuranceDetails /> : null}
      </div>
    </>
  );
};




const ProjectContractorView = ({ projectList, fetchContractorProjects, buffer }) => {



  const navigate = useNavigate();
  const role = sessionStorage.getItem('sitemanagement_role');


  // // Function to fetch projects for contractors
  // const fetchContractorProjects = async () => {
  //   setBuffering(true);
  //   try {
  //     // const response = await axios.get(`${BASE_URL}/siteproject/projectdashboard/contractor/`);
  //     const response = await axios.get(
  //       sessionStorage.getItem('sitemanagement_role') === 'AuthorityEngineer' || sessionStorage.getItem('sitemanagement_role') === 'DataEntryOperator' ?
  //         `${BASE_URL}/siteproject/projectdashboard/contractor/${sessionStorage.getItem('sitemanagement_emp_id')}/`
  //         : `${BASE_URL}/siteproject/projectdashboard/contractor/`
  //     );

  //     let filteredProjects = response.data;

  //     // Apply sub-company filter
  //     if (subCompany && subCompany !== 'null') {
  //       filteredProjects = filteredProjects.filter(
  //         (project) => project.sub_company === subCompany
  //       );
  //     }


  //     if (searchName) {
  //       const lowerCaseName = searchName.toLowerCase();
  //       filteredProjects = filteredProjects.filter(
  //         (project) =>
  //           project.site_prcode.toString().toLowerCase().includes(lowerCaseName) ||
  //           project.project_name.toLowerCase().includes(lowerCaseName) ||
  //           project.project_short_name.toLowerCase().includes(lowerCaseName)
  //       );
  //     }

  //     switch (searchSort) {
  //       case "asc_pr":
  //         filteredProjects = sortProjects(filteredProjects, "site_prcode");
  //         break;
  //       case "desc_pr":
  //         filteredProjects = sortProjects(filteredProjects, "site_prcode").reverse();
  //         break;
  //       case "asc_short_name":
  //         filteredProjects = filteredProjects.sort((a, b) =>
  //           a.project_short_name.localeCompare(b.project_short_name)
  //         );
  //         break;
  //       case "desc_short_name":
  //         filteredProjects = filteredProjects.sort((a, b) =>
  //           b.project_short_name.localeCompare(a.project_short_name)
  //         );
  //         break;
  //       default:
  //         break;
  //     }

  //     setProjectsList(filteredProjects);
  //   } catch (error) {
  //     console.error('Error fetching contractor projects:', error);
  //   } finally {
  //     setBuffering(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchContractorProjects();
  // }, [subCompany, searchName, searchSort]); // Refetch projects when filters change

  const tableRef = useRef(null);

  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const toggleshow = "Contractor";

  const ViewAllProjectDetails = (project) => {
    navigate(`/${urlParts[3]}/ProjectAllDetails/`, {
      state: { project, toggleshow },
    });
  };











  return (
    <>


      <div className=" table-css-white-background  table-box scroll-container-table" style={{ paddingTop: "0px" }}>

        <table ref={tableRef} className="table-css">
          <thead className="table-heading">
            <tr className="custom-table-head-tr">
              <th className="table-heading-text ">S.no.</th>
              <th className="table-heading-text " style={{ width: "100px" }}>Project</th>
              <th className="table-heading-text" style={{ width: "0px" }}>Progress(%)</th>
              <th className="table-heading-text text-center">Project Details</th>
              {role === "Hr" ? "" :
                <th className="table-heading-text text-center">Overall Billing Summary</th>
              }
              <th className="table-heading-text text-center">Change of Scope</th>
              <th className="table-heading-text text-center">Extension of Time</th>
              <th className="table-heading-text  ">View Details</th>
              {role === "Hr" || role === "BD" || role === "Account" ? "" :
                <th className="table-heading-text ">Delete</th>
              }

              {/* <th className="table-heading-text width-5vw ">BOQ</th> */}

            </tr>
          </thead>
          {buffer ? <div className="spinner-z"></div> : null}
          <tbody>
            {projectList?.sort((a, b) => {
              if (a.year === b.year) { return b.month - a.month; }
              return b.year - a.year;
            }).map((project, index) => {
              // const progressData = [
              //   {
              //     month: project?.latest_progress?.month,
              //     Physical: project?.latest_progress?.physical_progress,
              //     Financial: project?.latest_progress?.financial_progress,
              //   },
              // ];
              const contractorCount = project?.contractors?.length || 1;

              return (
                <React.Fragment key={index}>
                  {project?.contractors?.length > 0 ? (
                    project?.contractors?.map((contractor, contractorIndex) => {
                      const progressData = [
                        {
                          month: contractor?.latest_progress?.month,
                          year: contractor?.latest_progress?.year,
                          Physical: contractor?.latest_progress?.physical_progress,
                          Financial: contractor?.latest_progress?.financial_progress,
                        },
                      ];

                      return (

                        <tr key={project.id} className="custom-table-head-td">
                          {contractorIndex === 0 && (
                            <>
                              <td rowSpan={contractorCount} className="justify-center" style={{ verticalAlign: contractorCount <= 1 ? "top" : "middle" }}>{index + 1}.</td>
                              <td rowSpan={contractorCount} className="justify-center"
                                style={{ verticalAlign: contractorCount <= 1 ? "top" : "middle" }}
                                title={`Project Name: \n${project?.project_name}`}
                              >
                                {project?.site_prcode} : <br />
                                <div style={{ width: "120px", whiteSpace: "normal" }}>
                                  {project?.project_short_name}
                                </div>
                              </td>

                            </>
                          )}
                          <>
                            <td className="table-body vertical-align" colSpan={1}>
                              {contractor.latest_progress ?
                                <div className="">
                                  {contractor?.latest_progress?.physical_progress !== null
                                    && contractor?.latest_progress?.financial_progress !== null
                                    && contractor?.latest_progress?.month !== null
                                    && contractor?.latest_progress?.year !== null ?

                                    <ProjectProgressBar data={progressData} />
                                    : <p className="text-center">-</p>}
                                  {/* <ProjectProgressBar data={project.progress[0]} /> */}
                                </div>
                                :
                                <div className="text-center">
                                  <p>-</p>
                                </div>
                              }
                            </td>

                            <td className="table-body vertical-align" colSpan={1}>
                              {contractor.loa_award_date || contractor.name || contractor.agreement_date || contractor.schedule_construction_completion_date || contractor?.construction_cost ? (
                                <div className="employee-card-image table-body">
                                  <table style={{ borderCollapse: "collapse", width: "100%" }}>

                                    <tbody>
                                      <tr className="flex-column">
                                        <td className="padding-bottom-5px ">Contractor Name</td>
                                        {/* <td style={{ padding: "0px 4px" }}>:</td> */}
                                        <td className="align-right" style={{ textAlign: "start" }}>
                                          <div style={{ width: "200px", whiteSpace: "normal" }}>
                                            {contractor?.name
                                              ? contractor?.name
                                              : "-"}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr className="flex-column">

                                        <td className="padding-bottom-5px">Contractor Award</td>
                                        {/* <td style={{ padding: "0px 4px" }}>:</td> */}

                                        <td className="align-right" style={{ textAlign: "start" }}>
                                          {contractor?.loa_award_date
                                            ? formatDate(contractor?.loa_award_date)
                                            : "-"}
                                        </td>
                                      </tr>
                                      <tr className="flex-column">

                                        <td className="padding-bottom-5px">Agreement Date</td>
                                        {/* <td style={{ padding: "0px 4px" }}>:</td> */}

                                        <td className="align-right" style={{ textAlign: "start" }}>
                                          {contractor?.agreement_date
                                            ? formatDate(contractor?.agreement_date)
                                            : "-"}
                                        </td>
                                      </tr>
                                      <tr className="flex-column">

                                        <td className="padding-bottom-5px">Sch. Const. Completion Date</td>
                                        {/* <td style={{ padding: "0px 4px" }}>:</td> */}

                                        <td className="align-right" style={{ textAlign: "start" }}>
                                          {contractor?.schedule_construction_completion_date
                                            ? formatDate(
                                              contractor?.schedule_construction_completion_date
                                            )
                                            : "-"}
                                        </td>
                                      </tr>

                                      <tr className="flex-column">

                                        <td className="padding-bottom-5px">Construction Cost (CR)</td>
                                        {/* <td style={{ padding: "0px 4px" }}>:</td> */}

                                        <td className="align-right" style={{ textAlign: "start" }}>
                                          {contractor?.cost
                                            ? `₹ ${formatRoundoffCrores(
                                              contractor?.cost
                                            )}`
                                            : "-"}
                                        </td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center">
                                  <p>-</p>
                                </div>
                              )}
                            </td>

                            <td className="table-body vertical-align" colSpan={1}>
                              {contractor?.billing_details[0].bill_submitted_total
                                || contractor?.billing_details[0].recommended_bill_amount
                                || contractor?.billing_details[0].recommended_bill_amount
                                ? (
                                  <div className="employee-card-image">
                                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                      <tbody>
                                        <tr>
                                          <td className="padding-bottom-5px">Bill Submitted</td>
                                          <td style={{ padding: "0px 4px" }}>:</td>
                                          <td className="align-right">
                                            {contractor?.billing_details[0].bill_submitted_total
                                              ? `₹ ${formatRoundoffCrores(Number(contractor.billing_details[0].bill_submitted_total))}`
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr>

                                          <td className="padding-bottom-5px">Site Bill Recommended</td>
                                          <td
                                            style={{ padding: "0px 4px" }}>:</td>
                                          <td className="align-right">
                                            {contractor?.billing_details[0].site_recommended_bill_amount
                                              ? `₹ ${formatRoundoffCrores(Number(contractor.billing_details[0].site_recommended_bill_amount))}`
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr>


                                          <td className="padding-bottom-5px">Bill Recommended </td>
                                          <td style={{ padding: "0px 4px" }}>:</td>
                                          <td className="align-right">
                                            {contractor?.billing_details[0].recommended_bill_amount
                                              ? `₹ ${formatRoundoffCrores(Number(contractor.billing_details[0].recommended_bill_amount))}`
                                              : "-"}
                                          </td>
                                        </tr>

                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  <div className="text-center">
                                    <p>-</p>
                                  </div>
                                )}
                            </td>

                            <td className="table-body vertical-align" colSpan={1}>
                              {contractor.coss?.length > 0 ? (
                                <div className="employee-card-image">
                                  <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                    <thead>
                                      <tr>
                                        <th className="padding-bottom-5px">COS</th>
                                        <th style={{ padding: "0px 4px" }}>:</th>
                                        <th className="padding-bottom-5px">Approved Date</th>
                                        <th style={{ padding: "0px 4px" }}>:</th>
                                        <th className=" align-right padding-bottom-5px">Amount</th>

                                      </tr>
                                    </thead>
                                    <tbody>
                                      {contractor.coss.map((cosDetails) => (
                                        <tr>
                                          <td className="padding-bottom-5px">{cosDetails.cos_code}</td>
                                          <td style={{ padding: "0px 4px" }}>:</td>
                                          <td className=" text-center">
                                            {formatDate(cosDetails.approval_date)}
                                          </td>
                                          <td style={{ padding: "0px 4px" }}>:</td>
                                          <td className="align-right">₹  {formatRoundoff(Number(cosDetails.amount))}</td>

                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center">
                                  <p>-</p>
                                </div>
                              )}
                            </td>

                            <td className="table-body vertical-align" colSpan={1}>
                              {contractor.eots.length > 0 ? (
                                <div className="employee-card-image">
                                  <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                    <thead>
                                      <tr>
                                        <th className="padding-bottom-5px">EOT</th>
                                        <th style={{ padding: "0px 4px" }}>:</th>
                                        <th className="padding-bottom-5px">Approved Date</th>
                                        <th style={{ padding: "0px 4px" }}>:</th>
                                        <th className=" align-right padding-bottom-5px">Approved Days</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {contractor.eots.map((eotDetails) => (
                                        <tr>
                                          <td className="padding-bottom-5px">{eotDetails.eot_code}</td>
                                          <td style={{ padding: "0px 4px" }}>:</td>
                                          <td className=" text-center">
                                            {formatDate(eotDetails.approved_date)}
                                          </td>
                                          <td style={{ padding: "0px 4px" }}>:</td>
                                          <td className="align-right">{eotDetails.approved_days}</td>

                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center">
                                  <p>-</p>
                                </div>
                              )}
                            </td>
                          </>


                          {contractorIndex === 0 && (
                            <>
                              <td rowSpan={contractorCount} className="justify-center" style={{ verticalAlign: contractorCount <= 1 ? "top" : "middle" }}>

                                <button
                                  onClick={() => ViewAllProjectDetails(project)}
                                  title="View Project Details"
                                  className=""
                                >
                                  <Eye />
                                </button>
                              </td>

                              {
                                role === "Hr" || role === "BD" || role === "Account" ? "" :
                                  <td rowSpan={contractorCount} className="justify-center" style={{ verticalAlign: contractorCount <= 1 ? "top" : "middle" }}>

                                    <DeleteProject i={project} fetchContractorProjects={fetchContractorProjects} />
                                  </td>
                              }
                            </>
                          )}








                        </tr>

                      )
                    })) :
                    <tr key={project.id} className="custom-table-head-td">
                      <td rowSpan={contractorCount} className="table-body" >{index + 1}.</td>
                      <td rowSpan={contractorCount} className="table-body" title={`Project Name: \n${project?.project_name}`} >
                        {project?.site_prcode} : <br /><div style={{ width: "120px", whiteSpace: "normal" }}>{project?.project_short_name}</div>
                      </td>
                      <td colSpan={5} className="align-center">Contractor Details not exist, Please Add First</td>

                      <td className="table-body">

                        <button
                          onClick={() => ViewAllProjectDetails(project)}
                          title="View Project Details"
                          className=""
                        >
                          <Eye />
                        </button>
                      </td>

                      {
                        role === "Hr" || role === "BD" || role === "Account" ? "" :
                          <td className="table-body ">

                            <DeleteProject i={project} fetchContractorProjects={fetchContractorProjects} />
                          </td>
                      }

                    </tr>
                  }
                </React.Fragment>
              );
            })}
          </tbody>
        </table>

      </div>
    </>
  );
};

const ProjectConsultantView = ({ subCompany, searchName, searchSort }) => {


  const [projectsList, setProjectsList] = useState([]);
  const [filteredProject, setFilteredProject] = useState([]);
  const [buffer, setBuffering] = useState(true);
  const navigate = useNavigate();

  // Function to fetch projects for consultants
  const fetchConsultantProjects = async () => {
    setBuffering(true);

    const role = sessionStorage.getItem("sitemanagement_role");
    const empId = sessionStorage.getItem("sitemanagement_emp_id");

    let endpoint = "";

    if (role === "Admin") {
      endpoint = `${BASE_URL}/siteproject/projectdashboard/consultant/${subCompany || "null"}/`;
    } else if (role === "AuthorityEngineer") {
      endpoint = `${BASE_URL}/siteproject/projectdashboardauth/consultant/${empId}/`;
    } else if (role === "DataEntryOperator") {
      endpoint = `${BASE_URL}/siteproject/projectdashboardcomp/consultant/${empId}/`;
    }


    try {
      const response = await axios.get(endpoint);


      let filteredProjects = response.data;

      // // Apply sub-company filter
      // if (subCompany && subCompany !== 'null') {
      //   filteredProjects = filteredProjects.filter(
      //     (project) => project.sub_company === subCompany
      //   );
      // }

      // Apply search name filter
      if (searchName) {
        const lowerCaseName = searchName.toLowerCase();
        filteredProjects = filteredProjects.filter(
          (project) =>
            project.site_prcode.toString().toLowerCase().includes(lowerCaseName) ||
            project.project_name.toLowerCase().includes(lowerCaseName) ||
            project.project_short_name.toLowerCase().includes(lowerCaseName)
        );
      }

      switch (searchSort) {
        case "asc_pr":
          filteredProjects = sortProjects(filteredProjects, "site_prcode");
          break;
        case "desc_pr":
          filteredProjects = sortProjects(filteredProjects, "site_prcode").reverse();
          break;
        case "asc_short_name":
          filteredProjects = filteredProjects.sort((a, b) =>
            a.project_short_name.localeCompare(b.project_short_name)
          );
          break;
        case "desc_short_name":
          filteredProjects = filteredProjects.sort((a, b) =>
            b.project_short_name.localeCompare(a.project_short_name)
          );
          break;
        default:
          break;
      }

      // const sortingdata = sortProjects(filteredProjects)

      setProjectsList(filteredProjects);
      setFilteredProject(filteredProjects);

    } catch (error) {
      console.error('Error fetching consultant projects:', error);
    } finally {
      setBuffering(false);
    }
  };

  useEffect(() => {
    fetchConsultantProjects();
  }, [subCompany, searchSort]); // Refetch projects when filters change


  const applyFilters = () => {

    let filteredProjects = projectsList;

    if (searchName !== "") {
      const lowerCaseName = searchName.toLowerCase();
      filteredProjects = filteredProjects.filter(
        (project) =>
          project.site_prcode.toString().toLowerCase().includes(lowerCaseName) ||
          project.project_name.toLowerCase().includes(lowerCaseName) ||
          project.project_short_name.toLowerCase().includes(lowerCaseName)
      );
    }

    setFilteredProject(filteredProjects);
  };

  useEffect(() => {
    applyFilters();
  }, [searchName]);


  const [viewMode, setViewMode] = useState('inactive'); // employees or inactive
  const tableRef = useRef(null);


  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const toggleshow = "Consultant";

  const ViewAllProjectDetails = (project) => {
    navigate(`/${urlParts[3]}/ProjectAllDetails/`, {
      state: { project, toggleshow },
    });
  };

  // Helper function to split an array into two equal parts
  const splitArray = (array) => {
    const midIndex = Math.ceil(array.length / 2);
    return [array.slice(0, midIndex), array.slice(midIndex)];
  };

  const handleViewToggle = (mode) => {
    setViewMode(mode);
  };

  return (
    <>
      <div className="filter-showing">
        <div>Consultant details:</div>
        <div>
          Showing {filteredProject.length} of {projectsList.length}
        </div>
      </div>

      {/* Toggle between Employees and Inactive Designations */}
      <div className="bloc-tabss2  wfm-margin" >
        <button
          className={viewMode === "inactive" ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}

          onClick={() => handleViewToggle('inactive')}
        >
          Vacant Positions
        </button>
        <button
          className={viewMode === "employees" ? "dashboardcomponent-tabs  font-weight400  font-size-subheading    dashboardcomponent-active-tabs" : "dashboardcomponent-tabs  font-weight400  font-size-subheading   "}
          onClick={() => handleViewToggle('employees')}
        >
          View Employees
        </button>

      </div>

      <div className=" table-css-white-background table-box scroll-container-table" style={{ paddingTop: "0px" }}>
        <table ref={tableRef} className="table-css">
          <thead className="table-heading">
            <tr className="custom-table-head-th">
              <th className="table-heading-text">S.no.</th>
              <th className="table-heading-text">Project</th>
              <th className="table-heading-text text-center">Project Details</th>
              <th className="table-heading-text text-center">Key Professionals</th>
              <th className="table-heading-text text-center">Sub Professionals</th>
              <th className="table-heading-text text-center">Support Staff</th>
              <th className="table-heading-text">View Details</th>
              <th className="table-heading-text">Delete</th>

            </tr>
          </thead>
          {buffer ? <div className="spinner-z"></div> : null}
          <tbody>
            {filteredProject?.map((project, index) => (
              <React.Fragment key={index}>
                <tr key={project.id} className="custom-table-head-td">
                  {/* Project Name and Short Description */}
                  <td className="table-body">{index + 1} </td>
                  <td className="table-body" title={`Project Name: \n${project.project_name}`} >
                    {project.site_prcode} : <br /><div style={{ width: "120px", whiteSpace: "normal" }}>{project.project_short_name}</div>
                  </td>

                  {/* Project Details */}
                  <td className="table-body">
                    {project.project_name ? (
                      <div className="employee-card-image">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <tbody>
                            <tr>
                              <td className="padding-bottom-5px">Consultant LOA</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">{project?.loa_award_date ? formattedDateLong(project?.loa_award_date) : "-"}</td>
                            </tr>
                            <tr>
                              <td className="padding-bottom-5px">Completion Date</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">{project?.project_completion_date ? formattedDateLong(project?.project_completion_date) : "-"}</td>
                            </tr>
                            <tr>
                              <td className="padding-bottom-5px">Consultancy Fees</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">{formatRoundoff(project?.consultancy_fees) || "-"}</td>
                            </tr>
                            <tr>
                              <td className="padding-bottom-5px">Contract Mode</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">{project?.contract_mode || "-"}</td>
                            </tr>
                            <tr>
                              <td className="padding-bottom-5px">Lead</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right"> <div style={{ whiteSpace: "normal", paddingBottom: "5px" }}>{project?.lead || "-"}</div></td>
                            </tr>
                            <tr>
                              <td className="padding-bottom-5px">JV</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right "  ><div style={{ whiteSpace: "normal", paddingBottom: "5px" }}>{project?.JV || "-"}</div></td>
                            </tr>
                            <tr>
                              <td className="padding-bottom-5px">Association</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right"><div style={{ whiteSpace: "normal", paddingBottom: "5px" }}>{project?.association || "-"}</div></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="table-body ">
                        <p className="text-center">-</p>
                      </div>
                    )}
                  </td>

                  {/* Key Professionals */}
                  <td className="table-body">
                    {viewMode === 'employees' && project.employees?.key_professionals?.length > 0 ? (
                      <div className="employee-card-image">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <thead>
                            <tr>
                              <th className="padding-bottom-5px">Name</th>
                              <th style={{ padding: "0px 4px" }}>:</th>
                              <th className="padding-bottom-5px">Designation</th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.employees?.key_professionals?.map((keyProfessional) => (
                              <tr key={keyProfessional.id}>
                                <td className="padding-bottom-5px">{keyProfessional.name}</td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="padding-bottom-5px">{keyProfessional.designation}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : viewMode === 'inactive' && project.inactive_or_no_employee_designations.key_professionals?.length > 0 ? (
                      <div className="employee-card-image">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <thead>
                            <tr>
                              <th className="padding-bottom-5px">Designation</th>
                              <th style={{ padding: "0px 4px" }}>:</th>

                              <th className="padding-bottom-5px text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.inactive_or_no_employee_designations.key_professionals.map((designation, index) => (
                              <tr key={index}>
                                <td className="padding-bottom-5px">{designation.designation}</td>
                                <td style={{ padding: "0px 4px" }}>:</td>

                                <td className="padding-bottom-5px">{designation.status}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="align-center ">
                        <p className="text-center">-</p>
                      </div>
                    )}
                  </td>

                  {/* Sub Professionals */}
                  <td className="table-body">
                    {viewMode === 'employees' && project.employees.sub_professionals?.length > 0 ? (
                      <div className="employee-card-image">
                        {splitArray(project.employees.sub_professionals).map((split, index) => (

                          <table style={{ borderCollapse: "collapse", width: "100%" }}>
                            <thead>
                              <tr>
                                <th className="padding-bottom-5px">Name</th>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <th className="padding-bottom-5px">Designation</th>
                              </tr>
                            </thead>
                            <tbody>
                              {split.map((subProfessional) => (
                                <tr key={subProfessional.id}>
                                  <td className="padding-bottom-5px"> {subProfessional.name}</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>

                                  <td className="padding-bottom-5px">{subProfessional.designation}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ))}
                      </div>
                    ) : viewMode === 'inactive' && project.inactive_or_no_employee_designations.sub_professionals?.length > 0 ? (
                      <div className="employee-card-image">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <thead>
                            <tr>
                              <th className="padding-bottom-5px">Designation</th>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <th className="padding-bottom-5px">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.inactive_or_no_employee_designations.sub_professionals.map((designation, index) => (
                              <tr key={index}>
                                <td className="padding-bottom-5px">{designation.designation}</td>
                                <td style={{ padding: "0px 4px" }}>:</td>

                                <td className="padding-bottom-5px">{designation.status}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="align-center ">
                        <p className="text-center">-</p>
                      </div>
                    )}
                  </td>

                  {/* Support Staff */}
                  <td className="table-body">
                    {viewMode === 'employees' && project.employees.support_staff?.length > 0 ? (
                      <div className="employee-card-image">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <thead>
                            <tr>
                              <th className="padding-bottom-5px">Name</th>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <th className="padding-bottom-5px">Designation</th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.employees.support_staff.map((supportStaff) => (
                              <tr key={supportStaff.id}>
                                <td className="padding-bottom-5px">{supportStaff.name}</td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="padding-bottom-5px">{supportStaff.designation}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : viewMode === 'inactive' && project.inactive_or_no_employee_designations.support_staff?.length > 0 ? (
                      <div className="employee-card-image">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <thead>
                            <tr>
                              <th className="padding-bottom-5px">Designation</th>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <th className="padding-bottom-5px">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.inactive_or_no_employee_designations.support_staff.map((designation, index) => (
                              <tr key={index}>
                                <td className="padding-bottom-5px">{designation.designation}</td>
                                <td style={{ padding: "0px 4px" }}>:</td>
                                <td className="padding-bottom-5px">{designation.status}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="align-center ">
                        <p className="text-center">-</p>
                      </div>
                    )}
                  </td>

                  {/* View Details Button */}
                  <td className="table-body">
                    <button onClick={() => ViewAllProjectDetails(project)} title="View Project Details" className="">
                      <Eye />
                    </button>
                  </td>



                  <td className="table-body">

                    <DeleteProject i={project} fetchContractorProjects={fetchConsultantProjects} />
                  </td>

                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );

};




export {

  SiteProjectTable,
  Detailspage
};
